import { Visibility } from './visibility';

export function initVisibility() {
  this.visibility = new Visibility();
}

export function initSaveButtons() {
  initDraftButton();
  initPublishButton();
  initUnlockButton();
}

function initDraftButton() {
  const button = document.getElementById('save-draft');
  button.addEventListener('click', () => {
    button.classList.add('disabled');
    submitForm(false);
  });
}

function initPublishButton() {
  const button = document.getElementById('publish-contribution');
  button && button.addEventListener('click', () => {
    button.classList.add('disabled');
    submitForm(true);
  });
}

function initUnlockButton() {
  const button = document.getElementById('unlock-contribution');
  button && button.addEventListener('click', () => {
    button.classList.add('disabled');
    submitUnlockForm();
  });
}

function submitForm(publish = false) {
  const form = document.querySelector('.content-form');
  const publishField = createPublishField(publish);
  form.appendChild(createLocationField());
  form.appendChild(publishField);
  form.submit();
}

function submitUnlockForm() {
  const form = document.getElementById('unlock-form');
  form && form.submit();
}

export function createPublishField(value) {
  let publishField = document.createElement('input');
  publishField.type = 'hidden';
  publishField.name = 'publish';
  publishField.value = value;
  return publishField;
}

export function createLocationField() {
  let locationField = document.createElement('input');
  locationField.type = 'hidden';
  locationField.name = 'location';
  locationField.value = window.location.hash.length > 0 ? window.location.hash : null;
  return locationField;
}
