<template>
<div>
  <text-area
    name="questionTitleInput"
    ref="questionTitleInput"
    v-model="title"
    :rows="3"
    :validate="{ required: true, max: 300 }"
    :label="$t('course.question_title')"></text-area>

  <text-area
    name="questionHintInput"
    ref="questionHintInput"
    v-model="hint"
    :rows="3"
    :validate="{ max: 300 }"
    :label="$t('course.question_hint')"></text-area>

  <div class="question-time-picker">
    <label class="control-label">{{ $t('course.display_time') }}</label>
    <div class="question-time">
      <span v-show="this.time === null">{{ $t('course.question_end') }}</span>
      <div v-if="this.time !== null">
        {{timeM}}:{{timeS}}<span class="millis">.{{timeMS}}</span>
      </div>
    </div>

    <button v-on:click="setQuestionTime" type="button" class="btn btn-primary btn-flat">{{ $t('course.set_question_time', { minutes: currentM, seconds: currentS }) }}</button>
    <button v-show="this.time !== null" v-on:click="resetQuestionTime" type="button" class="btn btn-primary btn-flat">{{ $t('course.reset_question_time') }}</button>
  </div>

  <h3>{{ $tc('course.input_answers_and_select_correct_ones', optionComponent === 'radio-option' ? 1 : 2) }}</h3>

  <div class="row question-settings">
    <div class="col-lg-6 col-md-12">
      <div class="form-group">
        <div class="checkbox">
          <label>
            <input type="checkbox" v-model="allowMultiple"> {{ $t('course.allow_multiple_select') }}
          </label>
        </div>
        <p class="help-block">{{ $t('course.allow_multiple_select_help') }}</p>
      </div>
    </div>

    <div class="col-lg-6 col-md-12">
      <div class="form-group">
        <div class="checkbox">
          <label>
            <input type="checkbox" v-model="randomize"> {{ $t('course.random_answer_order') }}
          </label>
        </div>
        <p class="help-block">{{ $t('course.random_answer_order_help') }}</p>
      </div>
    </div>
  </div>

  <draggable ref="questionOptions" v-model="options" class="question-options" :options="dragOptions">
    <transition-group name="slide-fade">
      <component
        v-for="(option, index) in options"
        name="question_options"
        :correct="correctOptionIds"
        :is="optionComponent"
        :key="option.id"
        :option="option"
        :number="index + 1"
        @checked="updateCorrectOptions"
        @change="(value) => option.title = value"
        @remove="removeOption"></component>
    </transition-group>
  </draggable>

  <button v-on:click="addOption" type="button" class="btn btn-default btn-flat add-option">{{ $t('course.add_option') }}</button>

  <div class="actions">
    <button type="button" @click="openPreview" class="btn btn-primary btn-flat">{{ $t('course.preview') }}</button>
    <button type="button" v-on:click="this.resetQuestionChanges" class="btn btn-primary btn-flat">{{ $t('course.cancel') }}</button>
    
    <div v-if="!currentQuestion.editing" class="create-another-checkbox">
      <input v-model="createAnother" type="checkbox" id="create-another-check" class="k-checkbox">
      <label class="k-checkbox-label" for="create-another-check">{{ $t('course.create_another') }}</label>
    </div>

    <button v-on:click="saveChanges" :class="{ disabled: errors.any() }" type="button" class="btn btn-primary">{{ $t('course.apply') }}</button>
  </div>

  <question-preview
    v-if="showPreview"
    :sources="lessonEditorSubject.sources"
    :questions="[this.previewQuestionAttributes()]"
    @close="onPreviewClosed"
    @ready="onPreviewReady">
  </question-preview>
</div>
</template>

<script>
import { v4 as uuid } from 'uuid';
import draggable from 'vuedraggable';
import { createNamespacedHelpers } from 'vuex';

import { TextInput, TextArea } from '../form';
import RadioOption from './radio_option.vue';
import CheckboxOption from './checkbox_option.vue';
import QuestionPreview from './preview.vue';
import { noticeMessage } from '../../../../mixins/flash_message';

const { mapGetters, mapActions } = createNamespacedHelpers('course');

export default {
  name: 'question-form',
  components: {
    TextArea,
    TextInput,
    RadioOption,
    CheckboxOption,
    QuestionPreview,
    draggable
  },
  $_veeValidate: {
    validator: 'new'
  },
  data() {
    return {
      title: '',
      hint: '',
      randomize: true,
      time: null,
      type: '',
      allowMultiple: false,
      options: [],
      showPreview: false,
      createAnother: false
    };
  },
  computed: {
    ...mapGetters([
      'currentQuestion',
      'mediaData',
      'lessonEditorSubject'
    ]),
    dragOptions() {
      return {
        handle: '.draghandle',
        animation: 100
      }
    },
    questionAttributes() {
      let options = [];

      for(let i = 0; i < this.options.length; i++) {
        options.push({ ...this.options[i], position: i + 1 });
      }

      this.currentQuestion.options.forEach(option => {
        if (!option.isNew && options.findIndex(o => o.id === option.id) < 0) {
          options.push({
            id: option.id,
            deleted: true
          });
        }
      })

      return {
        title: this.title,
        hint: this.hint,
        randomize: this.randomize,
        time: this.time,
        type: this.type,
        options: options
      }
    },
    optionComponent: function() {
      return this.type === 'FilmQuestion::SingleSelect' ? 'radio-option' : 'checkbox-option';
    },
    correctOptionIds() {
      return this.options.filter((q) => q.correct).map((q) => q.id);
    },
    currentM() {
      return parseInt(this.mediaData.currentTime / 60, 10);
    },
    currentS() {
      const seconds = parseInt(this.mediaData.currentTime % 60, 10);
      return `0${seconds}`.slice(-2);
    },
    timeM() {
      return parseInt(this.time / 60, 10);
    },
    timeS() {
      const seconds = parseInt(this.time % 60, 10);
      return `0${seconds}`.slice(-2);
    },
    timeMS() {
      const millis = Math.floor((this.time - parseInt(this.time, 10)) * 1000);
      return `00${millis}`.slice(-3);
    }
  },
  methods: {
    ...mapActions([
      'resetQuestionChanges',
      'saveQuestion',
      'changeCurrentQuestionType',
      'newQuestion'
    ]),
    saveChanges() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.saveQuestion(this.questionAttributes);

          if(this.createAnother) {
            this.newQuestion('FilmQuestion::SingleSelect');
          }

          noticeMessage(this.$i18n.t('course.question_added'));
        }
      });
    },
    updateCorrectOptions(correctIds) {
      this.options.forEach((option) => {
        option.correct = correctIds.indexOf(option.id) >= 0;
      });
    },
    addOption() {
      this.options.push({
        id: uuid(),
        correct: this.options.length === 0,
        title: '',
        isNew: true
      })
    },
    removeOption(option) {
      this.options.splice(this.options.indexOf(option), 1);

      if (this.type === 'FilmQuestion::SingleSelect' && this.options.length && option.correct) {
        this.options[0].correct = true;
      }
    },
    setQuestionTime() {
      this.time = this.mediaData ? this.mediaData.currentTime : null;
    },
    resetQuestionTime() {
      this.time = null;
    },
    previewQuestionAttributes() {
      return { ...this.questionAttributes };
    },
    openPreview() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.showPreview = true;
        }
      });
    },
    onPreviewReady(videoPlayer) {
      const time = this.time === null ? this.mediaData.duration : this.time;
      const startTime = time - 2 >= 0 ? time - 2 : 0;
      videoPlayer.currentTime(startTime)
      videoPlayer.play();
    },
    onPreviewClosed() {
      this.showPreview = false;
    }
  },
  mounted() {
    $.material.init();
    this.$refs.questionTitleInput.focus();
  },
  watch: {
    currentQuestion: {
      handler: function(question) {
        if (question) {
          this.title = question.title;
          this.hint = question.hint;
          this.randomize = question.randomize;
          this.time = question.time;
          this.type = question.type;
          this.allowMultiple = question.type === 'FilmQuestion::MultipleSelect';

          // Clone options
          this.options = [];
          question.options
            .filter(option => !option.deleted)
            .sort((a, b) => a.position - b.position)
            .forEach(option => this.options.push({ ...option }));
        }
      },
      immediate: true
    },
    allowMultiple(isAllowed) {
      this.type = isAllowed ? 'FilmQuestion::MultipleSelect' : 'FilmQuestion::SingleSelect';

      // Make sure there is only one correct option for single select
      if (!isAllowed) {
        let correctOption = this.options.find(option => option.correct) || this.options[0];

        this.options.forEach(option => {
          option.correct = correctOption === option;
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.actions {
  display: flex;
  margin-top: 50px;
  justify-content: flex-end;
  align-items: center;
}

.question-settings {
  margin-bottom: 15px;
}

.form-group {
  .checkbox {
    margin-bottom: 24px;

    label {
      font-size: 16px;
      color: $black-54-opacity;
    }
  }

  &.is-focused {
    .checkbox label:hover {
      color: $black-87-opacity;
    }

    .help-block {
      display: block;
    }
  }

  .help-block {
    position: absolute;
    display: none;
    bottom: 0;
    left: 0;
    height: 12px;
    line-height: 12px;
  }
}

.add-option {
  display: block;
  margin: 0 auto;
}

::v-deep .question-option {
  display: flex;
  position: relative;
  border: 1px solid $color-light-grey-2;
  border-radius: 3px;
  padding: 10px;
  margin: 10px 0;
  align-items: center;
  background-color: $color-white;
  transition: border-color .15s linear;

  &.slide-fade-enter-active {
    transition: all .3s ease;
  }

  &.slide-fade-leave,
  &.slide-fade-leave-active {
    display: none;
  }

  &.slide-fade-enter,
  &.slide-fade-leave-to {
    transform: translateX(24px);
    opacity: 0;
  }

  &.error {
    border-color: $color-error-red;

    textarea::placeholder {
      color:  $color-error-red;
    }
  }

  .draghandle {
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    align-items: center;
    font-size: 24px;
    color: $black-12-opacity;
    padding: 0 10px;
    cursor: pointer;
  }

  .question-option-title {
    flex: 1 auto;
  }

  .radio, .checkbox {
    margin: 0 0 0 34px;
  }

  .radio {
    height: 20px;
  }

  .checkbox {
    height: 26px;
    padding-right: 10px;
  }
  
  textarea {
    display: block;
    overflow: hidden;
    resize: none;
    width: 100%;
    border: 0;
    outline: 0;
    padding: 0;
    font-size: 14px;
    line-height: 20px;
  }

  .question-answer-actions {
    display: flex;
    align-items: center;
    padding-right: 11px;

    > a {
      font-size: 20px;
      padding: 10px;
      color: $black-54-opacity;

      &:hover,
      &:focus,
      &:active {
        color: $black-87-opacity;
      }

      &.disabled {
        opacity: .2;
        cursor: default;
        pointer-events: none;
      }
    }
  }
}

.question-time-picker {
  padding-top: 15px;

  .control-label {
    font-weight: 400;
    color: $black-87-opacity;
  }

  .question-time {
    font-size: 16px;

    .millis {
      color: $black-54-opacity;
      font-size: .8em;
    }
  }
}

.create-another-checkbox {
  margin: 0 15px;
}
</style>
