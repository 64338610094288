<template>
  <div class="staffbase-integration">
    <div class="staffbase-settings">
      <p class="integration-details">{{ $t('integrations.thirdparty_staffbase_details') }}</p>

      <div class="form-group label-static">
        <label for="thirdparty_staffbase_entity_id" class="control-label">{{ $t('integrations.thirdparty_staffbase_entity_id') }}</label>
        <input v-model="staffbase_entity_id" type="text" placeholder="https://de-plugin-saml.staffbase.com/app/metadata.php?pid=" class="form-control" id="thirdparty_staffbase_entity_id">
        <p class="help-block">{{ $t('integrations.thirdparty_staffbase_entity_id_help') }}</p>
      </div>

      <div class="form-group label-static">
        <label for="thirdparty_staffbase_certificate" class="control-label">{{ $t('integrations.thirdparty_staffbase_certificate') }}</label>
        <textarea v-model="staffbase_certificate" placeholder="-----BEGIN CERTIFICATE-----" rows="5" class="form-control" id="thirdparty_staffbase_certificate"></textarea>
        <p class="help-block">{{ $t('integrations.thirdparty_staffbase_certificate_help') }}</p>
      </div>

      <div class="deeplinks-control">
        <div class="checkbox">
          <label>
            <input type="checkbox" v-model="staffbase_enable_deeplinks">
          </label>
        </div>

        <ValidationProvider
          :name="$t('integrations.thirdparty_staffbase_deeplink_base_url')"
          :rules="{
            'required': staffbase_enable_deeplinks,
            'url': staffbase_enable_deeplinks ? { require_protocol: true } : false
          }"
          class="deeplink-url"
          v-slot="v"
        >
          <div class="form-group" :class="{ 'has-error': v.valid === false }">
            <div class="form-group label-static">
              <label for="thirdparty_staffbase_deeplinks" class="control-label">{{ $t('integrations.thirdparty_staffbase_deeplinks') }}</label>
              <input :disabled="!staffbase_enable_deeplinks" v-model="staffbase_deeplink_base_url" type="text" class="form-control" id="thirdparty_staffbase_deeplinks" placeholder="https://app.staffbase.com/content/saml/628c3055dea94f1b6d6def55">
              <p v-if="v.valid !== false" class="help-block">{{ $t('integrations.thirdparty_staffbase_deeplinks_help') }}</p>
              <p v-else class="help-block">{{ v.errors[0] }}</p>
            </div>
          </div>
        </ValidationProvider>
      </div>

      <div class="form-group label-static">
        <label for="thirdparty_staffbase_email_text" class="control-label">{{ $t('integrations.thirdparty_staffbase_email_text') }}</label>
        <textarea v-model="staffbase_email_text" :placeholder="$t('integrations.thirdparty_staffbase_email_text_placeholder')" rows="3" class="form-control" id="thirdparty_staffbase_email_text"></textarea>
        <p class="help-block">{{ $t('integrations.thirdparty_staffbase_email_text_help') }}</p>
      </div>

      <slot/>
    </div>

    <div class="staffbase-help">
      <h3>{{ $t('integrations.thirdparty_staffbase_help_howto_configure') }}</h3>
      <ol>
        <li>
          <p v-html="$t('integrations.thirdparty_staffbase_help_step1')"></p>
          <p>{{ $t('integrations.thirdparty_staffbase_help_step1_enable_saml') }}</p>
          <a :href="staffbaseGuideUrl" target="blank">{{ $t('integrations.thirdparty_staffbase_help_step1_guide') }}</a>
        </li>
        <li>
          <p v-html="$t('integrations.thirdparty_staffbase_help_step2_service_provider')"></p>
          <pre>{{ serviceProviderUrl }}</pre>
          <p v-html="$t('integrations.thirdparty_staffbase_help_step2_audience_url')"></p>
          <pre>{{ audienceUrl }}</pre>
        </li>
        <li>
          <p v-html="$t('integrations.thirdparty_staffbase_help_step3')"></p>
          <ul>
            <li v-html="$t('integrations.thirdparty_staffbase_help_step3_endpoint')"></li>
            <li v-html="$t('integrations.thirdparty_staffbase_help_step3_certificate')"></li>
          </ul>
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex';
  import { ValidationProvider } from 'vee-validate';
  const { mapGetters } = createNamespacedHelpers('integrations');

  export default {
    components: {
      ValidationProvider
    },
    data() {
      return {
        staffbase_entity_id: null,
        staffbase_certificate: null,
        staffbase_enable_deeplinks: false,
        staffbase_deeplink_base_url: '',
        staffbase_email_text: ''
      }
    },
    computed: {
      ...mapGetters([
        'integrationById',
        'baseUrl'
      ]),
      integration() {
        return this.integrationById('thirdparty_staffbase');
      },
      settings() {
        return {
          staffbase_entity_id: this.staffbase_entity_id,
          staffbase_certificate: this.staffbase_certificate,
          staffbase_enable_deeplinks: this.staffbase_enable_deeplinks,
          staffbase_deeplink_base_url: this.staffbase_deeplink_base_url,
          staffbase_email_text: this.staffbase_email_text
        }
      },
      serviceProviderUrl() {
        return `${_app.info.rootUrl}${_app.info.site}/${_app.info.currentLocale}/embed`;
      },
      audienceUrl() {
        return `${_app.info.rootUrl}users/auth/saml/metadata`;
      },
      staffbaseGuideUrl() {
        if (_app.info.currentLocale === 'de') {
          return 'https://support.staffbase.com/hc/de/articles/360011597259-SSO-Login-f%C3%BCr-Services-mit-dem-SAML-Integrationen-Plugin-einrichten';
        }

        return 'https://support.staffbase.com/hc/en-us/articles/360011597259-Setting-Up-an-SSO-Login-for-Services-with-the-SAML-Integrations-Plugin';
      }
    },
    methods: {
      load() {
        const settings = this.integration.settings;
        this.staffbase_entity_id = settings.staffbase_entity_id;
        this.staffbase_certificate = settings.staffbase_certificate;
        this.staffbase_enable_deeplinks = settings.staffbase_enable_deeplinks;
        this.staffbase_deeplink_base_url = settings.staffbase_deeplink_base_url;
        this.staffbase_email_text = settings.staffbase_email_text;
      }
    },
    mounted() {
      this.load();
      $.material.init();
    },
  }
</script>

<style lang="scss" scoped>
  .staffbase-integration {
    display: flex;
  }

  .integration-details {
    font-size: 14px;
    color: $black-54-opacity;
    margin-bottom: 48px;
  }

  .staffbase-settings {
    flex: 1 0;
    align-self: flex-start;
    min-width: 400px;
    max-width: 800px;
    background-color: $color-white;
    border-radius: 3px;
    padding: 30px;
  }

  .staffbase-help {
    font-size: 14px;
    padding: 0 30px;

    img {
      display: block;
      margin-top: 10px;
      border-radius: 3px;
    }

    ol > li {
      margin-bottom: 24px;
    }

    pre {
      display: inline-block;
    }
  }

  h3 {
    font-size: 14px;
    font-weight: 500;
  }

  .deeplinks-control {
    display: flex;
    align-items: center;

    > .deeplink-url {
      flex: 1 0 auto;
    }
  }
</style>
