import BaseWidget from './base_widget';

export default class HeroSliderWidget extends BaseWidget {
  constructor(options) {
    super(options);

    this.type = 'HeroSlider';
    this.slider = null;
  }

  static get defaultParams() {
    return {
      title: '',
      show_title: false,
      hide_viewbutton: false,
      filter_query: '',
      filter_types: [],
      filter_topic_area: [],
      filter_metadata: [],
      filter_tags: [],
      order_field: 'views',
      order_direction: 'desc',
      limit: 3,
      timespan: 'total',
      static_contents: false,
      static_content_list: []
    };
  }

  resized(e) {
    if (this.slider) {
      $(this.slider).trigger('refresh.owl.carousel');
    }
  }

  initialize() {
    super.initialize();
    this.slider = this.element.querySelector('.owl-carousel');

    $(this.slider).owlCarousel({
      items: 1,
      loop: true,
      mouseDrag: false,
      dots: true,
      autoplay: true,
      autoplayTimeout: 10000,
      autoplayHoverPause: true
    });
  }
}
