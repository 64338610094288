import { Answer, Question } from "../entities";
import { AnswerType } from "../entities/answer";

export class AnswerSerializer {

  protected serializedAnswer = {};

  constructor(protected answer: Answer, protected question: Question) {
    this.serializedAnswer = {
      id: typeof answer.id === 'string' ? null : answer.id,
      _destroy: answer.deleted,
      is_solution: answer.isSolution,
      solution: answer.solution,
      points: (answer.type === AnswerType.single) ? question.points : answer.points,
      text: answer.text,
      type: answer.type
    }
  }

  public getSerialized() {
    return this.serializedAnswer;
  }
}
