import { nanoid } from 'nanoid';

export interface Notification {
  id: string,
  title?: string,
  content: {
    text: string,
    expandable?: boolean,
    newBlock?: boolean,
    // renders text as link and makes it clickable
    onClick?: () => void;
  }[]
  autoClose?: boolean,
  closeCallback?: () => void,

  expanded?: boolean,
}

type AddEvent = { type: 'ADD', notification: Notification };
type RemoveEvent = { type: 'REMOVE', id: Notification['id'] };
export type Event = AddEvent | RemoveEvent
type EventListener = (event: Event) => void;

const listener: EventListener[] = [];

function addEventListener(cb: EventListener): () => void {
  listener.push(cb);

  return () => {
    const index = listener.indexOf(cb);

    if (index > -1) {
      listener.splice(index);
    }
  };
}

function create(notification: Omit<Notification, 'id'>): string {
  const id = nanoid();

  const notificationWithId: Notification = {
    ...notification,
    id,
  };

  listener.forEach(async (cb) => {
    try {
      cb({
        type: 'ADD',
        notification: notificationWithId,
      });
    } catch (e) {
      console.error('[notification_service:add_listener]', e);
    }
  });

  return id;
}

function remove(id: Notification['id']): void {
  listener.forEach(async (cb) => {
    try {
      cb({
        type: 'REMOVE',
        id,
      });
    } catch (e) {
      console.error('[notification_service:remove_listener]', e);
    }
  });
}

export default {
  addEventListener,
  create,
  remove,
};
