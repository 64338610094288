// Videojs Plugin for In-Video Quizzes

const Plugin = videojs.getPlugin('plugin');

export class CoursePlugin extends Plugin {
  constructor(player, options) {
    super(player, options);

    this.player = player;
    this.questionMarkers = {};
    this.initialized = false;

    this.player.ready(() => {
      this.markers = this.createMarkersWrapper();
    });

    this.player.on('loadedmetadata', () => {
      if (this.initialized) {
        return;
      }

      this.markers = this.createMarkersWrapper();
      this.duration = this.player.duration();

      if (options.questions) {
        options.questions.forEach(question => {
          if (question.time === null) {
            question.time = this.duration;
          }

          this.createQuestionMarker(question);
        });
      }

      this.initialized = true;
    });

    this.enableQuestion = (id) => {
      const marker = this.markerFromQuestionId(id);

      if (marker) {
        marker.classList.remove('vjs-question-marker-disabled');
      }
    };

    this.disableQuestion = (id) => {
      const marker = this.markerFromQuestionId(id);

      if (marker) {
        marker.classList.add('vjs-question-marker-disabled');
      }
    };
  }

  get questionTimes() {
    return Object.keys(this.questionMarkers);
  }

  markerFromQuestionId(id) {
    return Array.from(this.markers.children).find(marker => parseInt(marker.dataset.id, 10) === id);
  }

  createMarkersWrapper() {
    if (this.markers) {
      return this.markers;
    }

    const markersWrapper = document.createElement('div');
    markersWrapper.className = 'vjs-question-markers';

    const QuestionMarkersComponent = {
      name: () => {
        return 'QuestionMarker';
      },
      el: () => {
        return markersWrapper;
      }
    };

    this.player.controlBar.progressControl.addChild(QuestionMarkersComponent);

    return markersWrapper;
  }

  createQuestionMarker({ id, time }) {
    const marker = document.createElement('div');
    const relativePosition = (parseFloat(time) / this.duration) * 100;
    marker.className = 'vjs-question-marker vjs-question-marker-disabled';
    marker.style.left = `${relativePosition}%`;
    marker.dataset.id = id;

    // Layer overlapping questions
    const overlaps = this.questionTimes.filter(markerTime => markerTime === time).length;
    marker.style.marginTop = `${-0.8 * overlaps}em`;

    marker.addEventListener('click', (e) => {
      e.preventDefault();

      if (!marker.classList.contains('vjs-question-marker-disabled')) {
        this.player.trigger('question', id);
      }
    });

    this.questionMarkers[time] = marker;
    this.markers.appendChild(marker);
  }
}
