import axios from 'axios';

export default {
  setUploadUrl({ commit }, url) {
    commit('SET_UPLOAD_URL', url);
  },

  setStep({ commit }, step) {
    // Reset store state
    switch(step) {
      case 'upload':
        commit('RESET_UPLOAD_STATE');
      case 'settings':
        commit('RESET_SETTINGS_STATE');
    }

    commit('SET_STEP', step);
  },
  
  uploadFile({ commit, state }, file) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('column_seperator', state.columnSeperator);
    commit('SAVING', true);

    axios.post(state.uploadUrl, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(response => {
      if (response.data.status === 'uploaded') {
        commit('UPLOAD_VALID', response.data);
      } else {
        commit('UPLOAD_INVALID', ['invalid_file']);
      }
    }).catch(error => {
      console.error(error.response);

      if (error.response.status === 400) {
        commit('UPLOAD_INVALID', error.response.data.errors);
      } else {
        commit('GENERIC_ERROR');
      }
    }).finally(() => commit('SAVING', false));
  },

  saveSettings({ commit, state }) {
    commit('SAVING', true);

    axios.post(state.settingsUrl, {
      import_action: state.importAction,
      contains_headings: state.containsHeadings
    }).then(response => {
      commit('SETTINGS_SAVED', response.data);
    }).catch(error => {
      console.error(error.response);

      if (error.response.status === 400) {
        commit('SETTINGS_INVALID', error.response.data.errors);
      } else {
        commit('GENERIC_ERROR');
      }
    }).finally(() => commit('SAVING', false));
  },

  updateMapping({ commit }, { field, column }) {
    commit('UPDATE_MAPPING', { field, column });
  },

  saveMappings({ commit, state }) {
    let mappingsData = [];
    commit('SAVING', true);

    state.mappings.forEach(mapping => {
      if (mapping.column > 0) {
        mappingsData.push({
          field: mapping.field,
          column_id: mapping.column
        });
      }
    });

    axios.post(state.mappingsUrl, { mappings_attributes: mappingsData })
      .then(response => {
        state.step = 'importing';
      })
      .finally(() => commit('SAVING', false));
  }
};
