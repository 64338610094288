import {  MutationTree } from 'vuex';
import { TwoFactorState } from './index';

export enum MutationTypes {
  SET_OTP_SECRET = 'setOtpSecret',
  SET_ACTIVATE_URL = 'setActivateUrl',
  SET_VERIFICATION_METHOD = 'setVerificationMethod',
  SET_BACKUP_CODES = 'setBackupCodes',
  SET_REDIRECT_URL = 'setRedirectUrl',
  SET_SEND_OTP_URL = 'setSendOtpUrl',
  SET_EMAIL = 'setEmail'
}

export const mutations: MutationTree<TwoFactorState> = {
  setOtpSecret(state, otpSecret) {
    state.otpSecret = otpSecret;
  },
  setActivateUrl(state, activateUrl) {
    state.activateUrl = activateUrl;
  },
  setVerificationMethod(state, verificationMethod) {
    state.verificationMethod = verificationMethod;
  },
  setBackupCodes(state, backupCodes) {
    state.backupCodes = backupCodes;
  },
  setRedirectUrl(state, redirectUrl) {
    state.redirectUrl = redirectUrl;
  },
  setSendOtpUrl(state, sendOtpUrl) {
    state.sendOtpUrl = sendOtpUrl;
  },
  setEmail(state, email) {
    state.email = email;
  }
}
