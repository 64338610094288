import { ThumbnailPreview } from './thumbnail_preview';

export let preview_elements = [];

export function init() {
  let thumbnail_elements = document.querySelectorAll('.show-media-preview');

  [].forEach.call(thumbnail_elements, element => {
    preview_elements.push(new ThumbnailPreview(element));
  });
}
