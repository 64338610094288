<template>
  <div id="app-integrations">
    <fade-transition mode="out-in" :duration="150">
      <router-view v-if="isLoaded"/>
    </fade-transition>
  </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex';
  const { mapGetters, mapActions } = createNamespacedHelpers('integrations');

  import { FadeTransition } from 'vue2-transitions';

  export default {
    components: {
      FadeTransition
    },
    computed: mapGetters(['isLoaded']),
    methods: mapActions(['loadSettings']),
    created() {
      this.loadSettings();
    }
  }
</script>
