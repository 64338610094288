import Vue from 'vue';
import WorkflowLink from './vue-components/workflow-link.vue';
import WorkflowSublink from './vue-components/workflow-sublink.vue';
import WorkflowStepper from './vue-components/workflow-stepper.vue';
import store from '../store';
import workflowStore from './store';
import { AdditionalLinkAction } from './interfaces';

export class ContributionWorkflow {

  private workflowApp: Vue;
  private vueWorkflowSelector: string = '#v-contribution-workflow';

  constructor() {
    this.workflowApp = this.initVueApp();
    this.initFilmStepper();
  }

  private initVueApp(): Vue {
    const element = document.querySelector(this.vueWorkflowSelector);

    if (element) {
      store.registerModule('contributionWorkflow', workflowStore);

      return new Vue({
        name: 'ContributionWorkflow',
        store: store,
        el: this.vueWorkflowSelector,
        components: {
          'workflow-link': WorkflowLink,
          'workflow-sublink': WorkflowSublink,
          'workflow-stepper': WorkflowStepper
        },
        created() {
          const emitActivatedEvent = (step, previousStep) => {
            if (step !== null) {
              this.$nextTick(() => {
                this.$emit('activated', step, previousStep);
                this.$emit(step.uid + '-activated');
              });
            }
          };

          store.watch(() => store.getters['contributionWorkflow/activeStep'], emitActivatedEvent);
          store.watch(() => store.getters['contributionWorkflow/activeSubstep'], emitActivatedEvent);
        },
        mounted() {
          // in case no step was activated
          const activeSteps = store.getters["contributionWorkflow/getActiveSteps"];

          if(!activeSteps.step) {
            store.dispatch('contributionWorkflow/activateFirst');
          }

          // Add mounted class in order to display workflow only when all children have been mounted
          this.$nextTick(function() {
            this.$el.classList.add('mounted');
          });
        }
      });
    } else {
      return null;
    }
  }

  public on(event: string | string[], callback: Function) {
    if (this.workflowApp) {
      return this.workflowApp.$on(event, callback);
    }
  }

  public off(event: string | string[], callback?: Function) {
    if (this.workflowApp) {
      this.workflowApp.$off(event);
    }
  }

  /**
   * Listens for event exact one time and triggers callback
   * @param {string | string[]} event Event Name(s) to listen 
   * @param {function} callback Callback-Function that will be triggert 
   */
  public once(event: string | string[], callback: Function) {
    if (this.workflowApp) {
      return this.workflowApp.$once(event, callback);
    }
  }

  public enableWorkflowStep(uid: string) {
    store.dispatch('contributionWorkflow/enableStep', { uid: uid });
  }

  public disableWorkflowStep(uid: string) {
    store.dispatch('contributionWorkflow/disableStep', { uid: uid });
  }

  public setActiveWorkflowStep(uid: string) {
    store.dispatch('contributionWorkflow/activateStep', { uid: uid });
  }

  public setActiveSubWorkflowStep(uid: string) {
    store.dispatch('contributionWorkflow/activateSubstep', { uid: uid });
  }

  public getActiveSteps() {
    return store.getters["contributionWorkflow/getActiveSteps"]
  }

  public hasStep(uid: String) {
    const step = store.getters['contributionWorkflow/getByUid'](uid);

    if (step) {
      return true;
    } else {
      return false;
    }
  }

  public setErrorStepState(uid: string, error: boolean) {
    store.dispatch('contributionWorkflow/setErrorStepState', { uid: uid, error: error });
  }

  public setPendingStepState(uid: string, pending: boolean|string) {
    store.dispatch('contributionWorkflow/setPendingStepState', { uid: uid, pending: pending });
  }

  // Workaround for Film Stepper
  public initFilmStepper() {
    $('.film-stepper[data-toggle="remotetab"]').click(function(e) {
      e.preventDefault();
      const element = $(this);
      const payload = { uid: element.data('uid') };
      store.dispatch('contributionWorkflow/activateSubstep', payload );
    });
  }

  public activateRemoteTab(element) {
    if ($(element).data('disabled') || $(element).parent().hasClass('disabled')) { return false; }
    $(element).parent().children('.step-active[data-toggle="remotetab"]').trigger('hide.bs.tab');

    var url  = $(element).data('url'),
        href = element.hash,
        pane = $(element),
        wrap = $('.page-content-wrapper');
    kendo.ui.progress(wrap, true);

    $(href).load(url, function(result) {
      kendo.ui.progress(wrap, false);
      (pane as any).tab('show');
      ($ as any).material.init();
      $(document).trigger( "remotetabLoaded", [href] );
    });
  }

  public addSubstep(parent: string, uid: string, title: string) {
    const parentComponent = this.getLinkComponent(parent);
    parentComponent.dynamicSublinks.push({ uid: uid, title: title });
  }

  public removeSubstep(parent: string, uid: string) {
    const parentComponent = this.getLinkComponent(parent);
    const index = parentComponent.dynamicSublinks.findIndex(sublink => sublink.uid === uid);
    parentComponent.dynamicSublinks.splice(index, 1);
  }

  public setCustomAction(uid: string, action: AdditionalLinkAction) {
    const component = this.getLinkComponent(uid);
    component.setCustomAction(action);
  }

  public getLinkComponent(uid: string): any {
    return this.workflowApp.$children.find((child: any) => child.uid === uid);
  }

  public activateFirstWithError(): void {
    store.dispatch('contributionWorkflow/activateFirstWithError');
  }
}
