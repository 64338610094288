/**
  * The Action class is a representation of the actions a user performs during the editing of a collaborative content
  * It is described by the user who performed the actions and is the super class for all different kind of actions (UIAction, ChangeAction etc.)
  */
export class Action {
  /**
   * @constructor
   * @param {String} tabId  - TabId of the tab the action originates from
   * @param {number} user - UserId of the chat room owner
   */
  constructor(tabId, user) {
    this.tabId = tabId;
    this.user = user;
    /** Check if inheriting class implements execute() method */
    if (this.execute === undefined) {
      throw new TypeError("Must implement execute");
    }
  }
  /** Get current tabId */
  static getTabId() {
    let activeSteps = VERSTEHE.ContributionWorkflow.workflow.getActiveSteps();
    return activeSteps.subStep ? activeSteps.subStep : activeSteps.step;
  }
}
