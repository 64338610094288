var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',[_c('v-slide-group',{staticClass:"pr-2",attrs:{"show-arrows":""},model:{value:(_vm.selectedBackground),callback:function ($$v) {_vm.selectedBackground=$$v},expression:"selectedBackground"}},[_c('v-slide-item',{attrs:{"value":_vm.DISABLE_VALUE,"width":"56","height":"56"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var toggle = ref.toggle;
var active = ref.active;
return [_c('v-tooltip',{attrs:{"top":"","max-width":"400","open-delay":"150"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-badge',{staticClass:"ml-2 mb-2 mt-2",attrs:{"overlap":"","left":"","bottom":"","value":active,"icon":"check"}},[_c('v-card',{staticClass:"d-flex justify-center align-center",attrs:{"disabled":_vm.disabled,"outlined":"","flat":"","width":"48","height":"48"},on:{"click":toggle}},[_c('v-icon',[_vm._v("block")])],1)],1)],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('common.visualEffectsSettings.backgroundReplacement.tooltip.disable')))])])]}}])}),_vm._v(" "),_c('v-slide-item',{attrs:{"value":"TRANSPARENT","width":"56","height":"56"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var toggle = ref.toggle;
var active = ref.active;
return [_c('v-tooltip',{attrs:{"top":"","max-width":"400","open-delay":"150"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-badge',{staticClass:"ml-2 mb-2 mt-2",attrs:{"overlap":"","left":"","bottom":"","value":active,"icon":"check"}},[_c('v-card',{staticClass:"d-flex justify-center align-center",attrs:{"disabled":_vm.disabled,"outlined":"","flat":"","width":"48","height":"48"},on:{"click":toggle}},[_c('v-icon',{staticClass:"capture-icons"},[_vm._v("transparent")])],1)],1)],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('common.visualEffectsSettings.backgroundReplacement.tooltip.transparent')))])])]}}])}),_vm._v(" "),_vm._l((['BLUR_SOFT', 'BLUR']),function(value){return _c('v-slide-item',{key:value,attrs:{"value":value,"width":"56","height":"56"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var toggle = ref.toggle;
var active = ref.active;
return [_c('v-tooltip',{attrs:{"top":"","max-width":"400","open-delay":"150"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-badge',{staticClass:"ml-2 mb-2 mt-2",attrs:{"overlap":"","left":"","bottom":"","value":active,"icon":"check"}},[_c('v-card',{staticClass:"d-flex justify-center align-center",class:{ disabled: !_vm.canEnableBlur },attrs:{"disabled":_vm.disabled,"outlined":"","flat":"","width":"48","height":"48"},on:{"click":toggle}},[(value === 'BLUR_SOFT')?_c('v-icon',{staticClass:"capture-icons"},[_vm._v("blur_soft")]):_vm._e(),_vm._v(" "),(value === 'BLUR')?_c('v-icon',{staticClass:"capture-icons"},[_vm._v("blur")]):_vm._e()],1)],1)],1)]}}],null,true)},[_vm._v(" "),(!_vm.canEnableBlur)?_c('span',[_vm._v(_vm._s(_vm.$t('common.visualEffectsSettings.backgroundReplacement.tooltip.blurBlocked')))]):(value === 'BLUR_SOFT')?_c('span',[_vm._v(_vm._s(_vm.$t('common.visualEffectsSettings.backgroundReplacement.tooltip.blurSoft')))]):_c('span',[_vm._v(_vm._s(_vm.$t('common.visualEffectsSettings.backgroundReplacement.tooltip.blur')))])])]}}],null,true)})}),_vm._v(" "),_c('v-slide-item',{key:"ADD_CUSTOM_BACKGROUND",attrs:{"width":"56","height":"56"}},[_c('v-tooltip',{attrs:{"top":"","max-width":"400","open-delay":"150"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-card',{staticClass:"d-flex justify-center align-center ml-2 mb-2 mt-2",attrs:{"disabled":_vm.disabled,"outlined":"","flat":"","width":"48","height":"48"},on:{"click":_vm.uploadCustomBackgroundImage}},[_c('v-icon',{staticClass:"capture-icons"},[_vm._v("plus_square")])],1)],1)]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('common.visualEffectsSettings.backgroundReplacement.tooltip.uploadCustomImage')))])])],1),_vm._v(" "),_vm._l((_vm.customBackgroundImageIds),function(image){return _c('v-slide-item',{key:image,attrs:{"value":image,"width":"56","height":"56"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-hover',{attrs:{"value":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-badge',{staticClass:"ml-2 mb-2 mt-2",attrs:{"overlap":"","left":"","bottom":"","value":active,"icon":"check"}},[_c('v-tooltip',{attrs:{"top":"","max-width":"400","open-delay":"150"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('fade-transition',{attrs:{"mode":"out-in","duration":150}},[_c('div',_vm._g({directives:[{name:"show",rawName:"v-show",value:(hover && !_vm.disabled),expression:"hover && !disabled"}],staticClass:"delete-badge"},on),[_c('v-icon',{staticClass:"delete-badge__icon capture-icons",on:{"click":function($event){return _vm.removeCustomBackgroundImage(image)}}},[_vm._v("\n                    delete\n                  ")])],1)])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('common.visualEffectsSettings.backgroundReplacement.tooltip.removeCustomImage')))])]),_vm._v(" "),_c('v-card',{attrs:{"disabled":_vm.disabled,"outlined":"","flat":"","width":"48","height":"48"},on:{"click":toggle}},[_c('v-img',{attrs:{"src":_vm.customBackgroundImageUrl(image),"width":"46","height":"46"}})],1)],1)]}}],null,true)})]}}],null,true)})}),_vm._v(" "),_vm._l((_vm.backgroundImages),function(image){return _c('v-slide-item',{key:image,attrs:{"value":image,"width":"56","height":"56"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-badge',{staticClass:"ml-2 mb-2 mt-2",attrs:{"overlap":"","left":"","bottom":"","value":active,"icon":"check"}},[_c('v-card',{attrs:{"disabled":_vm.disabled,"outlined":"","flat":"","width":"48","height":"48"},on:{"click":toggle}},[_c('v-img',{attrs:{"src":_vm.backgroundImageUrl(image),"width":"46","height":"46"}})],1)],1)]}}],null,true)})})],2),_vm._v(" "),_c('input',{ref:"uploadInput",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/jpeg, image/png, image/webp"},on:{"change":_vm.onInputChange}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }