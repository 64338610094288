import { schema } from 'normalizr';

export interface ChapterItem {
  id: EntityId;
  chapterId: EntityId;
  courseId: number;
  courseTitle: string;
  courseDescription: string;
  position: number;
  thumbnail: string;
  createdAt: string;
  updatedAt: string;
  deleted?: boolean;
}

export const chapterItemSchema = new schema.Entity('chapterItems');
