export default async function resizeImage(img: Blob, height: number) {
  return new Promise<Blob | null>(async (resolve) => {
    const imageBitmap = await createImageBitmap(img, {
      premultiplyAlpha: 'none',
      colorSpaceConversion: 'none',
    });

    const canvas = document.createElement('canvas');

    canvas.height = height;
    canvas.width = imageBitmap.width / (imageBitmap.height / height);

    const ctx = canvas.getContext('2d')!;
    ctx.drawImage(imageBitmap, 0, 0, canvas.width, canvas.height);

    canvas.toBlob((blob) => resolve(blob));
  });
}
