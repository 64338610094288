import Vue from 'vue';
import Generation from './generation.vue';

export function init() {
  const generationNode = document.getElementById("v-scorm-generation");
  const initialData = JSON.parse(generationNode.getAttribute("data-initial"));
  let standalonePackage = JSON.parse(generationNode.getAttribute('data-standalone'));
  let dependentPackage = JSON.parse(generationNode.getAttribute('data-dependent'));

  this.app = new Vue({
    el: generationNode,
    i18n: VERSTEHE.vueI18n,
    render: h => h(Generation, { 
      props: { 
        generateUrl: initialData.generateUrl,
        contentToken: initialData.contentToken,
        packages: {
          standalone: standalonePackage,
          dependent: dependentPackage
        } 
      } 
    })
  });
}
