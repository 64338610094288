export function init() {
  const el = document.querySelector('.course-certificate-select');
  
  if (el) {
    initializeColorPickers();
    setActiveTemplateClass();

    const activeRadios = el.querySelectorAll('input[type="radio"]');
    for (let i = 0; i < activeRadios.length; i++) {
      activeRadios[i].addEventListener('change', setActiveTemplateClass);
    }

    // Submit form
    const submitBtn = document.getElementById('save-course-certificates-btn');
    const form = document.getElementById('course-certificates-form');
    submitBtn.addEventListener('click', () => form.submit());
  }
}

function initializeColorPickers() {
  const colorInputs = document.querySelectorAll('.course-certificate-color-input');

  for (let i = 0; i < colorInputs.length; i++) {
    $(colorInputs[i]).kendoColorPicker({
      buttons: false,
      clearButton: true,
      change: function(e) {
        if (e.value === null) {
          // Reset color
          this.close();
          this.value(colorInputs[i].dataset.default);
        }
      }
    });
  }
}

function setActiveTemplateClass() {
  const items = document.querySelectorAll('.course-certificate-item');

  for (let i = 0; i < items.length; i++) {
    const isActive = items[i].querySelector('input[type="radio"]:checked');

    if (isActive) {
      items[i].classList.add('active');
    } else {
      items[i].classList.remove('active');
    }
  }
}
