import { BaseDataSource } from '../../grid/base_data_source';

export class DataSource extends BaseDataSource {
  constructor (options) {
    super(options);

  }

  initSchema () {
    let schema = {
      schema: {
        data: 'mats',
        model: {
          id: 'id',
          fields: {
            thumbnail: { type: 'string' },
            user: { type: 'object' },
            title: { type: 'string' },
            display_title: { type: 'string' },
            is_active: { type: 'boolean' },
            is_public: { type: 'boolean' },
            is_film_default: { type: 'boolean' },
            is_clip_default: { type: 'boolean' },
            updated_at: { type: 'date' },
            created_at: { type: 'date' }
          }
        },
        total: 'total'
      }
    };

    return schema;
  }

  initGeneralOptions () {
    return {
      serverSorting: true,
      serverPaging: true,
      serverFiltering: true,
      sort: this.grid.gridSort(),
      filter: this.grid.gridFilter(),
      page: 1,
      pageSize: 10
    };
  }

  setFilter (filter) {
    this.dataSource.filter = filter;
  }

  destroy(options) {
    let url = options.data.actions.delete;

    $.ajax({
      url: url,
      dataType: 'json',
      method: 'DELETE',
      success: options.success,
      error: (response) => {
        console.log(response);
        this.dataSource.cancelChanges();
        options.error(response);
      }
    });
  }
}
