<template>
  <div ref="group" class="form-group label-floating" :class="{ 'has-error': errors.has(name), 'is-empty':value.length === 0 }">
    <label v-if="label" class="control-label">{{ label }}</label>

    <span class="input-validator-wrap">
      <textarea
        class="form-control"
        :rows="rows"
        :name="name"
        ref="textarea"
        v-validate="validate"
        v-bind:value="value"
        @input="$emit('input', $event.target.value)"></textarea>

      <p class="help-block">{{ errors.first(name) }}</p>
      <span v-if="maxLength > 0" class="input-char-count" :class="{ invalid: value.length > maxLength }">{{value.length}} / {{maxLength}}</span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'text-area',
  inject: ['$validator'], // inject parent validator
  props: {
    name: {
      type: String,
      required: true
    },
    label: String,
    value: String,
    rows: {
      type: Number,
      default: 5
    },
    validate: Object
  },
  data() {
    return {
      maxLength: 0
    }
  },
  mounted() {
    if (this.validate && this.validate.max > 0) {
      this.maxLength = this.validate.max;
    }

    if (!this.value || this.value.length === 0) {
      this.$refs.group.classList.add('is-empty');
    }
  },
  methods: {
    focus() {
      this.$refs.textarea.focus();
    }
  }
}
</script>
