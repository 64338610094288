import { t } from './localization';

export function init () {
   this.component = new ThumbnailSelect;
}

export class ThumbnailSelect {
  constructor (selector) {
    this.selector = selector;
    this.wrapper = document.querySelector('.thumbnail-capture');
    this.preview = this.wrapper.querySelector('.thumbnail-preview');
    this.setButtons();
    this.fileInput = this.wrapper.querySelector('.image-upload-input');
    this.removeInput = this.wrapper.querySelector('.image-upload-delete-input');
    this.customThumbnailFlag = this.wrapper.querySelector('.custom-image-field');
    this.deleteFlag = document.querySelector('.image-upload-delete-input');
    this.modal = document.getElementById('default_info_modal_lg');

    if (this.preview.getAttribute('data-img').length > 0) {
      this.preview.style.backgroundImage = `url(${this.preview.getAttribute('data-img')})`;
    }

    this.bindListeners();
  }

  enableButton(type) {
    let hasScreenplay = VERSTEHE.ContributionWorkflow.workflow.hasStep("medium.screenplay");
    this.setButtons(true);
    if (type === 'film' || type === 'clip') {
      this.videoButton.classList.remove('disabled');
      this.videoButton.classList.remove('hidden');
    }
    if (type === 'slideshow' && !hasScreenplay) {
      this.slideshowButton.classList.remove('disabled');
      this.slideshowButton.classList.remove('hidden');
    }

    this.bindCaptureButtonListener();
  }

  disableButton() {
    if (this.videoButton) {
      this.videoButton.classList.add('disabled');
      this.videoButton.classList.add('hidden');
    }
    if (this.slideshowButton) {
      this.slideshowButton.classList.add('disabled');
      this.slideshowButton.classList.add('hidden');
    }

    this.unbindCaptureButtonListener();
  }

  setButtons(disabled) {
    if(disabled) {
      this.videoButton = this.wrapper.querySelector('.thumbnail-select-actions .video-action');
      this.slideshowButton = this.wrapper.querySelector('.thumbnail-select-actions .slideshow-action');
    } else {
      this.videoButton = this.wrapper.querySelector('.thumbnail-select-actions .video-action:not(.disabled)');
      this.slideshowButton = this.wrapper.querySelector('.thumbnail-select-actions .slideshow-action:not(.disabled)');
    }

    this.uploadButton = this.wrapper.querySelector('.thumbnail-select-actions .upload-action');
    this.removeButton = this.wrapper.querySelector('.remove-thumbnail');
  }

  captureDialog(content) {
    const self = this;

    return new Promise((resolve, reject) => {
      const options = {
        width: '850px',
        closable: true,
        title: t('capture_dialog_title'),
        content: content,
        modal: true,
        buttonLayout: 'normal',
        actions: [{
          text: t('dialog_confirm_cancel'),
          action: reject
        },{
          text: t('capture_dialog_ok'),
          action: resolve,
          primary: true
        }],
        show: (e) => {
          this.initThumbnailCapture(e);
        },
        close: function() {
          self.destroyPlayer();
          this.destroy();
        }
      };

      $('<div/>').kendoDialog(options).data('kendoDialog').open();
    });
  }

  initThumbnailCapture(e) {
    this.film = VERSTEHE.media.films.find(film => film.player_id === 'thumbnail-select-player');
    this.clip = VERSTEHE.media.clips.find(clip => clip.player_id === 'player_thumbnail-select-player');
    this.slideshow = VERSTEHE.media.slideshows.find(slideshow => slideshow.slides_id === 'thumbnail-select-player');
    this.format = 'png';
    let wrapper = e.sender.element[0];
    this.submitButton = wrapper.parentNode.querySelector('.k-primary');

    if (this.slideshow) {
      this.type = 'slide';
    } else if (this.film) {
      this.type = 'video';
      this.player = this.film.player;
      this.disableSubmitButton();
    } else if (this.clip) {
      this.type = 'video';
      this.player = this.clip.player;
      this.disableSubmitButton();
    }
  }

  disableSubmitButton() {
    this.submitButton.disabled = true;

    this.player.on('play', () => {
      this.submitButton.disabled = false;
    });
  }

  generateThumbnail () {
    if (this.type === 'video') {
      this.generateThumbnailfromVideo();
    } else {
      this.generateThumbnailFromSlide();
    }

    let dataUri = this.canvas.toDataURL('image/' + this.format);
    let fileInput = document.querySelector('.image-upload-input-base64');

    if (fileInput) {
      fileInput.setAttribute('value', dataUri);

      if (this.customThumbnailFlag) {
        this.customThumbnailFlag.value = true;
      }

      if (this.deleteFlag) {
        this.deleteFlag.value = 0;
      }

      this.refreshPreview(dataUri);
      if(this.wrapper.matches('[data-collaborative-action*="ImageUploadAction"]')){
        VERSTEHE.ImageUploadAction.ImageUploadAction.createAction($(fileInput), dataUri, "upload");
      }
    }

    this.destroyPlayer();
  }

  destroyPlayer () {
    if (this.film) { VERSTEHE.media.films.pop(); }
    if (this.slideshow) { VERSTEHE.media.slideshows.pop(); }
  }

  generateThumbnailFromSlide () {
    let activeSlide = this.slideshow.slider.get('currentSlide');

    let imageElement = document.createElement('img');
    imageElement.src = activeSlide.getAttribute('data-src');
    imageElement.height = activeSlide.getAttribute('data-actual-height');
    imageElement.width = activeSlide.getAttribute('data-actual-width');

    this.createCanvas(imageElement);
    this.canvas.getContext('2d').drawImage(imageElement, 0, 0,  this.canvas.width, this.canvas.height);
  }

  generateThumbnailfromVideo () {
    this.createCanvas();
    this.canvas.getContext('2d').drawImage(this.player.tech('html5').el(), 0, 0, this.canvas.width, this.canvas.height);
  }

  refreshPreview (image) {
    this.preview.style.backgroundImage = "";
    this.preview.setAttribute('style', `background-image: url(${image});`);
    this.wrapper.classList.add('thumbnail-set');
  }


  createCanvas (imageElement) {
    this.canvas = document.createElement('canvas');

    if (this.type === 'video') {
      this.canvas.height = this.player.videoHeight();
      this.canvas.width = this.player.videoWidth();
    } else {
      this.canvas.height = imageElement.height;
      this.canvas.width = imageElement.width;
    }
  }

  loadModalBody() {
    let url = this.wrapper.getAttribute('data-url');

    $.ajax({
      url: url,
      success: (response) => {
        this.captureDialog(response).then(() => {
          this.generateThumbnail();
        }, () => {});
      }
    });

  }

  bindCaptureButtonListener () {
    this.unbindCaptureButtonListener();
    this.captureButtonListener = () => this.loadModalBody();

    if (this.videoButton) {
      this.videoButton.addEventListener('click', this.captureButtonListener);
    }

    if (this.slideshowButton) {
      this.slideshowButton.addEventListener('click', this.captureButtonListener);
    }
  }

  unbindCaptureButtonListener () {
    if (this.captureButtonListener) {
      if (this.videoButton) {
        this.videoButton.removeEventListener('click', this.captureButtonListener);
      }

      if (this.slideshowButton) {
        this.slideshowButton.removeEventListener('click', this.captureButtonListener);
      }
    }
  }

  bindListeners () {
    this.bindCaptureButtonListener();

    if(this.removeButton) {
      this.removeButton.addEventListener('click', () => {
        this.removeInput.value = '1';
        this.fileInput.value = '';
        this.customThumbnailFlag.value = false;
        this.wrapper.classList.remove('thumbnail-set');
        this.preview.setAttribute('style','');
      });
    }

    if (this.uploadButton) {
      this.uploadButton.addEventListener('click', () => {
        this.fileInput.click();
      });

      this.fileInput.addEventListener('change', () => {
        if (this.fileInput.files && this.fileInput.files[0]) {
          let wrapper = this.wrapper;
          let reader = new FileReader();
          let preview = this.preview;

          reader.onload = function(e) {
            preview.style.backgroundImage = "";
            preview.style.backgroundImage = `url(${e.target.result})`;
            wrapper.classList.add('thumbnail-set');

            if (wrapper.matches('[data-collaborative-action*="ImageUploadAction"]')) {
              let input = document.querySelector('.image-upload-input-base64');
              VERSTEHE.ImageUploadAction.ImageUploadAction.createAction($(input), e.target.result, "upload");
            }
          };

          reader.readAsDataURL(this.fileInput.files[0]);

          if (this.customThumbnailFlag) {
            this.customThumbnailFlag.value = true;
          }

          if (this.deleteFlag) {
            this.deleteFlag.value = 0;
          }
        }
      });
    }
  }
}
