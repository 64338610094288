export class InputValidator {
  constructor(element, validators = []) {
    this.validators = validators;
    this.inputElement = element;
    this.parentElement = element.parentNode;
    this.wrapInput();
    this.initMaxchars();
  }

  wrapInput() {
    this.wrapper = document.createElement('span');
    this.wrapper.classList.add('input-validator-wrap');
    this.parentElement.insertBefore(this.wrapper, this.inputElement);
    this.wrapper.appendChild(this.inputElement);
  }

  initMaxchars() {
    if (this.validators.includes('max-chars')) {
      this.maxChars = this.inputElement.getAttribute('data-maxchars');
      this.charCount = document.createElement('span');
      this.charCount.classList.add('input-char-count');
      this.wrapper.appendChild(this.charCount);
      this.inputElement.addEventListener('input', (e) => this.updateMaxChars(e));
      this.updateMaxChars();
    }
  }

  updateMaxChars(e) {
    this.charCount.innerHTML = `${this.inputElement.value.length} / ${this.maxChars}`;

    if (this.isValid()) {
      this.inputElement.setCustomValidity("");
    } else {
      this.inputElement.setCustomValidity("Invalid");
    }
  }

  validateMaxChars() {
    const valid = this.inputElement.value.length <= this.maxChars;
    if (valid) {
      this.charCount.classList.remove('invalid');
    } else {
      this.charCount.classList.add('invalid');
    }

    return  valid;
  }

  isValid() {
    let isValid = true;

    if (this.maxChars && !this.validateMaxChars()) {
      isValid = false;
    }

    return isValid;
  }
}
