import { ActionTree } from "vuex";
import { RootState } from "../../../store/types";
import axios from 'axios';
import { State } from '@uppy/core';

export const actions: ActionTree<State, RootState> = {
  setState({ commit }, payload) {
    commit('setState', payload);
  },
  setPausedState({ commit }, paused: boolean) {
    commit('setPausedState', paused);
  }
}
