import { AnswerSerializer } from "./answer_serializer";
import { Answer, Question } from "../entities";

export class ImagemapAnswerSerializer extends AnswerSerializer {
  constructor(protected answer: Answer, protected question: Question) {
    super(answer, question);

    if (answer.imageUpload) {
      this.serializedAnswer['image'] = answer.image;
      this.serializedAnswer['image_file_name'] = answer.imageFileName;
    }
  }
}
