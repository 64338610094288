import { mapActions } from 'vuex';
import { Actions } from '../store/actions';

export default {
  props: ['valid', 'questionId'],
  methods: {
    ...mapActions({
      updateAttributes: Actions.SET_QUESTION_ATTRIBUTES
    })
  },
  watch: {
    valid: function(value: boolean) {
      if (value !== null) {
        this.updateAttributes({ silent: true, id: this.questionId, attributes: { valid: value }});
      }
    }
  },
  render: h => h()
}
