export const emojis = {
  ':D'            : '&#128513;',
  ':\\)'          : '&#128513;',
  ':o'            : '&#128558;',
  ':\\('          : '&#128543;',
  ':I'            : '&#128528;',
  'xD'            : '&#128518;',
  ';\\)'          : '&#128521;',
  ':neutral_face:': '&#128528;',
  ':wink:'        : '&#128521;',
  ':smile:'       : '&#128513;',
  ':innocent:'    : '&#128519;',
  ':smiling_imp:' : '&#128520;',
  ':rage:'        : '&#128545;',
  ':worried:'     : '&#128543;',
  ':thumbsup:'    : '&#128077;',
  ':thumbsdown:'  : '&#128078;'
};
