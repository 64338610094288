import { ChangeAction } from "../system_actions/change_action";

let localization = {
  'en': {
    'saved': 'A co author saved this contribution. The page will be refreshed',
    'published': 'A co author published this contribution. The page will be refreshed',
    'sent_to_review': 'A co author handed this contribution in for review. It is locked until the review is finished.'
  },
  'de': {
    'saved': 'Ein Koautor hat diesen Beitrag gespeichert. Die Seite wird neu geladen.',
    'published': 'Ein Koautor hat diesen Beitrag veröffentlicht. Die Seite wird neu geladen.',
    'sent_to_review': 'Ein Koautor hat diesen Beitrag zur Rezension vorgelegt. Er ist gesperrt bis der Prozess abgeschlossen ist. '
  },
  'es': {
    'saved': 'Un coautor guardó esta contribución. La página se actualizará',
    'published': 'Un coautor ha publicado este artículo. La página se recarga.',
    'sent_to_review': 'Un coautor ha presentado este artículo para su revisión. Se bloquea hasta que finaliza el proceso.'
  }
};

/**
 * A SavePublishAction represents the publishing of a topic
 * Depending on whether the topic is published directly or sent to review the other authors have to refresh the page or are redirected to the topic overview
 */
export class SavePublishAction extends ChangeAction {
  /**
   * @constructor
   * @param {string} tabId  - tabId of the tab the action originates from
   * @param {number} user - userId of the chat room owner
   * @param {String} element - element thats subject of the ChangeAction
   * @param {String} publishType - Whether the Topic will be published directly or is sent to review
   */
  constructor(tabId, user, element, publishType) {
    super(tabId, user, element);
    this.publishType = publishType;
    this.actionType = "SavePublishAction";
  }

  execute() {
    VERSTEHE.cable.disconnect();
    if (this.publishType === "save") {
      VERSTEHE.utils.showLoadingIndicator();
      setTimeout(function() {
        window.alert(localization[_app.info.currentLocale].saved);
        window.location.reload();
      }, 500);
    } else if (this.publishType === "review") {
      VERSTEHE.utils.showLoadingIndicator();
      setTimeout(function() {
        window.alert(localization[_app.info.currentLocale].sent_to_review);
        let link = $('a.back-button').prop("href");
        window.location.href = link;
      }, 500);
    } else if (this.publishType === "publish") {
      VERSTEHE.utils.showLoadingIndicator();
      setTimeout(function() {
        window.alert(localization[_app.info.currentLocale].published);
        window.location.replace($("[href='#publish']").prop("href"));
        window.location.reload();
      }, 500);
    }
  }

  static fromJson(json) {
    let action = JSON.parse(json);
    return new SavePublishAction(action.tabId, action.user, action.elementLocation, action.publishType);
  }
}
