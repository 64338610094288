import BaseWidget from './base_widget';

export default class ContributionGridWidget extends BaseWidget {
  constructor(options) {
    super(options);

    this.type = 'ContributionGrid';
  }

  static get defaultParams() {
    return {
      title: '',
      show_title: true,
      filter_query: '',
      filter_types: [],
      filter_topic_area: [],
      filter_metadata: [],
      filter_tags: [],
      order_field: 'views',
      order_direction: 'desc',
      limit: 10,
      timespan: 'total',
      static_contents: false,
      static_content_list: []
    };
  }
}
