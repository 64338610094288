export function init() {
  initElementContainer();
}

function initElementContainer() {
  var pl_wrapper = document.querySelector('.pl-index-element-wrapper');
  var expand_btn = document.querySelector('.expand-playlist');
  var expand_less_btn = document.querySelector('.expand-less-playlist');

  if(pl_wrapper) {
    if (expand_btn) {
      expand_btn.onclick = function(event) {
        pl_wrapper.classList.remove('pl-folded');
        expand_btn.classList.add('hide');
        expand_less_btn.classList.remove('hide');
      };
    }

    if (expand_less_btn) {
      expand_less_btn.onclick = function(event) {
        pl_wrapper.classList.add('pl-folded');
        expand_btn.classList.remove('hide');
        expand_less_btn.classList.add('hide');
      };
    }
  }
}
