/* eslint no-console:0 */
// This file is automatically compiled by Webpack; along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Fetch API Polyfill
import 'whatwg-fetch';

import '@babel/polyfill';
import 'navigator.sendbeacon';

import '@uppy/core/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import '@webcomputing/jquery-mentions-input';
import '@webcomputing/jquery-mentions-input/jquery.mentionsInput.scss';
import 'videojs-seek-buttons/dist/videojs-seek-buttons.css';

import '../stylesheets/course_evaluation.scss';

//Ahoy events
import ahoy from "ahoy.js";

import * as SelectizeCustomizing from '../javascript/selectize/customizing.js';
import * as Taxonomy from '../javascript/taxonomy/component';
import * as ActionManager from '../javascript/collaborative_authoring/action_manager';
import * as CollabCenter from '../javascript/collaborative_authoring/collab_center';
import * as CollaborativeAuthoring from '../javascript/collaborative_authoring/component';
import * as Config from '../javascript/config/component';
import * as ContributionAnalytics from '../javascript/analytics/contribution';
import * as ContributionValidity from '../javascript/contributions/validity';
import * as EditUserRegistration from '../javascript/edit_user_registration/component';
import * as ElementFinder from '../javascript/collaborative_authoring/element_finder';
import * as CollaborativeActions from '../javascript/collaborative_authoring/actions/index';
import * as LandingPage from '../javascript/landing_pages/landing_page';
import * as ManageUser from '../javascript/manage_user/component';
import * as MediumChangeAction from '../javascript/collaborative_authoring/actions/content_actions/topic_actions/medium_change_action';
import * as Navigation from '../javascript/navigation/component';
import * as PlaylistsExplore from '../javascript/playlists/explore';
import * as Preferences from '../javascript/preferences/component';
import * as QuestionsManage from '../javascript/questions/manage';
import * as Queue from '../javascript/collaborative_authoring/component';
import * as Report from '../javascript/knowledge_reporting/report';
import * as StatusAction from '../javascript/collaborative_authoring/actions/system_actions/status_action';
import * as ImageUploadAction from '../javascript/collaborative_authoring/actions/content_actions/topic_actions/publish_actions/image_upload_action';
import * as SystemStatus from '../javascript/system_status/system_statuses';
import * as ThumbnailPreview from '../javascript/thumbnail_preview/component';
import * as TrainerGridConfiguration from '../javascript/trainer_grid_configuration/component';
import * as AutoReassignSettings from '../javascript/sites/auto_reassign_contributions/component';
import * as TransitUserContent from '../javascript/transit_user_content/component';
import * as adHoc from '../javascript/knowledge_reporting/ad-hoc/initilizer';
import * as chartembed from '../javascript/knowledge_reporting/ad-hoc/embed_initilizer';
import * as common from '../javascript/common/component';
import * as media from '../javascript/media/component';
import * as tts from '../javascript/tts/component';
import * as user from '../javascript/user/component';
import * as ContributionGrid from '../javascript/contribution_grid/component';
import * as ContributionPublishment from '../javascript/contributions/publishment';
import * as TopicManage from '../javascript/topics/manage';
import * as Globals from '../javascript/globals/component';
import * as Recording from '../javascript/recording/app';
import * as VideoCreator from '../javascript/video_creator/component';
import * as ManageSidebar from '../javascript/manage_sidebar/component';
import * as ContributionWorkflow from '../javascript/contribution_workflow/component';
import * as NewTopic from '../javascript/topics/new_topic';
import * as ReviewGrid from '../javascript/review_grid/component';
import * as MediaLibrary from '../javascript/media_library/component';
import * as Course from '../javascript/course/component';
import * as CoursePlanManage from '../javascript/course_plan/manage/component';
import * as ThumbnailSelect from '../javascript/common/thumbnail_select';
import * as Screenplay from '../javascript/screenplay/component';
import * as ChangeHistory from '../javascript/change_history/component';
import * as QuizGrid from '../javascript/quizzes/component';
import * as FilmProduction from '../javascript/film_production/component';
import * as FilmQuestions from '../javascript/film_questions';
import * as UserGrid from '../javascript/user_grid/component';
import * as Integrations from '../javascript/integrations';
import * as Tutorials from '../javascript/tutorials';
import * as GettingStarted from '../javascript/getting_started';
import * as EducationRollout from '../javascript/education_rollout/component';
import * as Branding from '../javascript/branding/component';
import * as PlaylistManage from '../javascript/playlists/manage';
import * as CommentMentions from '../javascript/comment/comment';
import * as NewFeatures from '../javascript/new_features/new_features';
import { QRCodeModal } from '../javascript/contributions/qr_code_modal';
import { CopyToClipboardInput } from '../javascript/globals/copy_to_clipboard_input';
import * as Embed from '../javascript/embed/component';
import { PrivateSelect as UserGroupSelect } from '../javascript/contributions/private_select';
import QuizManage from '../javascript/quizzes/manage/quiz_manage';
import FilmTranscript from '../javascript/film_transcript/film_transcript';
import Vue from 'vue';
import { SearchMatchTranscript } from '../javascript/globals/search_match_transcript';
import ContributionGeneral from '../javascript/contributions/general';
import * as Readmore from '../javascript/globals/readmore';
import * as CourseCertificates from '../javascript/course_certificates';
import * as UserImport from '../javascript/user_import';
import * as UserOnboarding from '../javascript/user_onboarding';
import { SiteGeneral, SiteFooter, SiteSettings } from '../javascript/config/site';
import * as MediaAssemblingTemplateGrid from '../javascript/media_assembling_templates/grid/component';
import MediaAssemblingTemplateManage from '../javascript/media_assembling_templates/manage/media_assembling_template';
import * as ScormGeneration from '../javascript/scorm/generation/index';
import * as WebConference from '../javascript/web_conferences';
import * as FilmSubtitles from '../javascript/film_subtitles/index';
import * as ApiTokens from '../javascript/api_tokens';
import * as QuizPerform from '../javascript/quizzes/perform';
import * as TwoFactorSetup from '../javascript/two_factor_setup';
import * as TwoFactor from '../javascript/two_factor';
import * as ExportGrid from '../javascript/grid/export_grid';

// Vue Extensions
import VeeValidate from 'vee-validate';
import VueI18n from 'vue-i18n';
import VueRouter from 'vue-router';
import InfiniteLoading from 'vue-infinite-loading';

// Kendo UI Vue Wrappers
import { DropdownsInstaller } from '@progress/kendo-dropdowns-vue-wrapper';
import { DataSourceInstaller } from '@progress/kendo-datasource-vue-wrapper';
import { DialogInstaller } from '@progress/kendo-dialog-vue-wrapper';
import { WindowInstaller } from '@progress/kendo-window-vue-wrapper';
import { LayoutInstaller } from '@progress/kendo-layout-vue-wrapper';

// Vue Translations
import validationMessagesEn from 'vee-validate/dist/locale/en';
import validationMessagesDe from 'vee-validate/dist/locale/de';
import validationMessagesEs from 'vee-validate/dist/locale/es';

import locales from '../locales';
import { datetimeFormats } from '../locales/formats';
import axios from 'axios';

import cssVars from 'css-vars-ponyfill';

// Check for CSS Vars support
// IE11 does not!
const hasCSSVarSupport = typeof window !== 'undefined' && window.CSS && window.CSS.supports && window.CSS.supports('(--a: 0)');

if (!hasCSSVarSupport) {
  // We hide the body for as long as it takes to process
  // the stylesheets
  const style = document.createElement('style');
  const head = document.head || document.getElementsByTagName('head')[0];

  style.type = 'text/css';
  head.appendChild(style);
  style.appendChild(document.createTextNode('body { opacity: 0 !important; }'));

  cssVars({
    preserveStatic: false,
    updateURLs: false,
    onComplete: () => {
      head.removeChild(style);
    }
  });
}

// Set Vue Filters
import vueFilters from '../javascript/vue/filters';
vueFilters();

import { ValidationProvider, ValidationObserver } from 'vee-validate';

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

// Vue setup
Vue.use(VueI18n);

const vueI18n = new VueI18n({
  locale: _app.info.currentLocale,
  messages: locales,
  dateTimeFormats: datetimeFormats
});

Vue.use(VeeValidate, {
  validity: true,
  inject: false,
  i18nRootKey: 'validations',
  i18n: vueI18n,
  dictionary: {
    en: validationMessagesEn,
    de: validationMessagesDe,
    es: validationMessagesEs
  }
});

Vue.use(DropdownsInstaller);
Vue.use(DataSourceInstaller);
Vue.use(DialogInstaller);
Vue.use(WindowInstaller);
Vue.use(LayoutInstaller);
Vue.use(VueRouter);
Vue.use(InfiniteLoading, {
  slots: {
    noMore: '',
    noResults: ''
  }
});

// axios csrf setup
const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';

// Register VideoJS Plugins
media.registerVjsPlugins();

VERSTEHE = {
  ...VERSTEHE,
  csrfToken: csrfToken,
  SelectizeCustomizing,
  ActionManager,
  CollabCenter,
  CollaborativeAuthoring,
  Config,
  ContributionAnalytics,
  ContributionValidity,
  EditUserRegistration,
  ElementFinder,
  CollaborativeActions,
  LandingPage,
  ManageUser,
  MediumChangeAction,
  Navigation,
  PlaylistsExplore,
  Preferences,
  QuestionsManage,
  Queue,
  Report,
  StatusAction,
  SystemStatus,
  ImageUploadAction,
  Taxonomy,
  ThumbnailPreview,
  TrainerGridConfiguration,
  AutoReassignSettings,
  TransitUserContent,
  adHoc,
  chartembed,
  common,
  media,
  tts,
  user,
  ContributionGrid,
  ContributionPublishment,
  TopicManage,
  Globals,
  Recording,
  VideoCreator,
  ManageSidebar,
  ContributionWorkflow,
  NewTopic,
  ReviewGrid,
  MediaLibrary,
  Course,
  ThumbnailSelect,
  vueI18n,
  Screenplay,
  ChangeHistory,
  QuizGrid,
  FilmProduction,
  FilmQuestions,
  UserGrid,
  QRCodeModal,
  Embed,
  UserGroupSelect,
  CopyToClipboardInput,
  Integrations,
  axios,
  CoursePlanManage,
  Tutorials,
  EducationRollout,
  QuizManage,
  GettingStarted,
  Branding,
  SearchMatchTranscript,
  ContributionGeneral,
  FilmTranscript,
  PlaylistManage,
  CommentMentions,
  Readmore,
  CourseCertificates,
  UserImport,
  UserOnboarding,
  SiteGeneral,
  SiteFooter,
  SiteSettings,
  MediaAssemblingTemplateGrid,
  MediaAssemblingTemplateManage,
  ScormGeneration,
  WebConference,
  FilmSubtitles,
  ApiTokens,
  QuizPerform,
  TwoFactorSetup,
  TwoFactor,
  NewFeatures,
  ExportGrid
};
