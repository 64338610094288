<template>
  <div class="workflow-steps">
    <a class="workflow-step" @click.prevent="clickStep($event, 'upload')" :class="{ active: step === 'upload', done: status === 'uploaded' }">
      <div class="workflow-step-number">
        <span v-if="status === 'uploaded'" class="material-icons">check</span>
        <span v-else>1</span>
      </div>
      <div class="workflow-step-label">{{ $t('user_import.upload_file') }}</div>
    </a>

    <div class="workflow-step-seperator"/>

    <a class="workflow-step" @click.prevent="clickStep($event, 'settings')" :class="{ active: step === 'settings', done: step === 'mappings' || step === 'importing' }">
      <div class="workflow-step-number">
        <span v-if="step === 'mappings' || step === 'importing'" class="material-icons">check</span>
        <span v-else>2</span>
      </div>
      <div class="workflow-step-label">{{ $t('user_import.import_settings') }}</div>
    </a>

    <div class="workflow-step-seperator"/>

    <a class="workflow-step" @click.prevent="clickStep($event, 'mappings')" :class="{ active: step === 'mappings', done: step === 'importing' }">
      <div class="workflow-step-number">
        <span v-if="step === 'importing'" class="material-icons">check</span>
        <span v-else>3</span>
      </div>
      <div class="workflow-step-label">{{ $t('user_import.field_mappings') }}</div>
    </a>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      status: 'userImport/status',
      step: 'userImport/step'
    })
  },
  methods: {
    ...mapActions({
      setStep: 'userImport/setStep'
    }),

    clickStep(e, step) {
      if (e.currentTarget.classList.contains('done') && this.step !== 'importing') {
        this.setStep(step);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.workflow-steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 -24px 24px -24px;
}

.workflow-step {
  display: flex;
  padding: 0 24px;
  height: 72px;
  align-items: center;
  transition: background-color .1s linear;
  cursor: default;
  color: $black-87-opacity;

  &.done {
    cursor: pointer;
  }
  
  &:hover,
  &:focus,
  &:active {
    color: $black-87-opacity;
    background-color: $black-006-opacity;
  }

  &.active .workflow-step-label {
    font-weight: 500;
  }

  &.done .workflow-step-number,
  &.active .workflow-step-number {
    background-color: var(--primary-color);
    color: RGB(var(--primary-foreground-color));
  }
}

.workflow-step-seperator {
  flex-grow: 1;
  height: 1px;
  margin: 0 -8px;
  background-color: rgba(0, 0, 0, .12);
}

.workflow-step-label {
  font-size: 14px;
}

.workflow-step-number {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: $black-38-opacity;
  color: $color-white;
  text-align: center;
  line-height: 24px;
  margin-right: 8px;
}
</style>
