import { UAParser } from 'ua-parser-js';

const ua = UAParser(navigator.userAgent);
const { name } = ua.browser;

export const isFirefox = () => name === 'Firefox';


// ToDo: check if ua.os.name is an alternative
export const isMac = () => (navigator?.userAgentData?.platform || navigator?.platform || '').toLowerCase().includes('mac');

export const isFirefoxIncognito = () => isFirefox() && navigator.serviceWorker === undefined;