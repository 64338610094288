interface ModalInstance {
  type: ModalTypes,
  text: string, 
  headline: string, 
  layout: ModalLayout,
}

enum ModalTypes {
  CONNECTION_ERROR,
  RECORDING_ERROR,
  RECORDING_PERMISSION,
  RECONNECTING,
  CHAT_ERROR,
  ALLOW_AUDIO,
  BREAKOUT_ERROR,
  BROWSER_PERMISSION,
  INACTIVE
}

enum ModalLayout {
  ACCEPT,
  ACCEPT_DECLINE,
  SPINNER
}

export { ModalInstance, ModalTypes, ModalLayout };