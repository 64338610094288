import { MutationTree } from 'vuex';
import { FilmTranscriptState } from './state';
import Vue from 'vue';
import { Phrase } from '../interfaces';

export const Mutations = {
  SET_INITIAL: 'SET_INITIAL',
  SET_CURRENT_TIME: 'SET_CURRENT_TIME',
  SET_FILTER_VALUE: 'SET_FILTER_VALUE',
  SET_AUTOSCROLL_VALUE: 'SET_AUTOSCROLL_VALUE',
  UPDATE_PHRASE: 'UPDATE_PHRASE',
  ADD_SAVING: 'ADD_SAVING',
  REMOVE_SAVING: 'REMOVE_SAVING',
  ADD_ERROR: 'ADD_ERROR',
  REMOVE_ERROR: 'REMOVE_ERROR',
  SET_TRANSCRIPT: 'SET_TRANSCRIPT',
  REMOVE_PHRASE: 'REMOVE_PHRASE'
}

export const mutations: MutationTree<FilmTranscriptState> = {
  [Mutations.SET_INITIAL] (state, payload) {
    Object.assign(state, payload);
  },
  [Mutations.SET_CURRENT_TIME] (state, currentTime: number) {
    state.currentTime = currentTime;
  },
  [Mutations.SET_FILTER_VALUE] (state, value: string) {
    state.filterValue = value;
  },
  [Mutations.SET_AUTOSCROLL_VALUE] (state, value: boolean) {
    state.autoscrollValue = value;
  },
  [Mutations.ADD_SAVING] (state, id: number) {
    state.phrasesSaving.push(id);
  },
  [Mutations.REMOVE_SAVING] (state, id: number) {
    state.phrasesSaving.splice(state.phrasesSaving.indexOf(id), 1);
  },
  [Mutations.ADD_ERROR] (state, payload: { id: number, message: string }) {
    state.savingErrors.push(payload);
  },
  [Mutations.REMOVE_ERROR] (state, id: number) {
    const index = state.savingErrors.findIndex(element => element.id === id);

    if (index >= 0) {
      state.savingErrors.splice(index, 1);
    }
  },
  [Mutations.UPDATE_PHRASE] (state, payload: { id: number, text: string }) {
    let phrase = state.phrases.find(phrase => phrase.id === payload.id);
    phrase.text = payload.text;
  },
  [Mutations.SET_TRANSCRIPT] (state, transcript: Array<Phrase>) {
    state.phrases = transcript;
  },
  [Mutations.REMOVE_PHRASE] (state, phraseId: number) {
    state.phrases.splice(state.phrases.findIndex(phrase => phrase.id === phraseId), 1);
  }
}
