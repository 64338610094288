<template>
  <div class="tutorials-navigation">
    <div class="tutorials-search">
      <i class="material-icons">search</i>
      <input v-model="query" type="text" :placeholder="$t('tutorials.search_placeholder')" />
      <a href="javascript:void(0)" @click="clearQuery" v-if="query && query.length > 0" class="btn btn-default btn-fab btn-fab-mini">
        <i class="material-icons">clear</i>
      </a>
    </div>

    <h2>{{ $t('tutorials.categories') }}</h2>
    <ul>
      <li>
        <router-link to="/c/all">
          {{ $t('tutorials.show_all') }}
        </router-link>
      </li>
      <li v-for="category in categories" :key="category.id">
        <router-link :to="'/c/' + category.id">
          {{ category.name }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('tutorials');

export default {
  methods: {
    ...mapActions(['setSearchQuery']),
    clearQuery() {
      this.$router.replace('/c/all');
    }
  },
  computed: {
    ...mapGetters([
      'categories',
      'query'
    ]),
    query: {
      set(query) {
        if (this.$router.currentRoute.path !== '/search') {
          this.$router.push('/search');
        }

        this.$router.replace({
          name: 'search',
          query: { q: query }
        });

        return this.setSearchQuery(query);
      },
      get() {
        return this.$store.state.tutorials.searchQuery;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .tutorials-navigation {
    background-color: $color-grey-10;
    height: 100%;
    width: 300px;
    overflow-y: auto;
    flex-shrink: 0;
  }

  .tutorials-search {
    display: flex;
    align-items: center;
    padding: 0 10px 0 30px;
    border-bottom: 1px solid $black-12-opacity;

    > i {
      color: $black-54-opacity;
      font-size: 24px;
      margin-right: 10px;
    }

    > input {
      flex-grow: 1;
      background: none;
      border: 0;
      outline: 0;
      height: 50px;
      font-size: 14px;
      overflow: hidden;
    }

    .btn-fab {
      background-color: transparent;
      box-shadow: none;
      margin-left: 10px;

      > i {
        font-size: 24px;
        color: $black-54-opacity;
      }
    }
  }

  h2 {
    font-size: 14px;
    font-weight: 500;
    color: $black-54-opacity;
    line-height: 40px;
    margin: 24px 0 0 0;
    padding: 0 5px 0 30px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    a {
      display: block;
      font-size: 14px;
      color: $black-87-opacity;
      line-height: 40px;
      padding: 0 5px 0 30px;

      &:hover,
      &.router-link-active {
        background-color: $translucent-black-light;
      }
    }
  }
</style>
