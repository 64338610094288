import { ActionTree } from "vuex";
import { CoursePlanManageState, Entities } from "./state";
import { RootState } from "../../../store/types";
import { Chapter, ChapterItem } from "../entities";
import { Mutations } from './mutations';
import { v4 as uuid } from 'uuid';

export const Actions = {
  UPDATE_ENTITIES: 'coursePlanManage/updateEntities',
  SET_CHAPTER_LIST: 'coursePlanManage/setChapterList',
  SET_CHAPTER_ITEM_LIST: 'coursePlanManage/setChapterItemList',
  REMOVE_CHAPTER: 'coursePlanManage/removeChapter',
  REMOVE_CHAPTER_ITEM: 'coursePlanManage/removeChapterItem',
  SET_CHAPTER_TITLE: 'coursePlanManage/setChapterTitle',
  NEW_CHAPTER: 'coursePlanManage/newChapter',
  SET_ADD_TO_CHAPTER: 'coursePlanManage/setAddToChapter',
  SET_SEARCH_URL: 'coursePlanManage/setSearchUrl',
  ADD_CHAPTER_ITEM: 'coursePlanManage/addChapterItem',
  REFRESH_POSITIONS: 'coursePlanManage/refreshPositions',
  PROCESS_CHAPTER_SWITCH_FOR_ITEM: 'coursePlanManage/processChapterSwitchForItem',
  ADD_DELETED_CHAPTER_ITEM: 'coursePlanManage/addDeletedChapterItem'
}

export const actions: ActionTree<CoursePlanManageState, RootState> = {
  updateEntities({ commit }, entities: Entities) {
    commit(Mutations.UPDATE_ENTITIES, entities);
  },
  setChapterList({ commit }, chapters: EntityId[]) {
    commit(Mutations.SET_CHAPTER_LIST, chapters);
  },
  setChapterItemList({ commit }, payload: { chapterId: EntityId, chapterItemIds: EntityId[] }) {
    commit(Mutations.SET_CHAPTER_ITEM_LIST, payload);
  },
  removeChapter({ commit }, chapterId: EntityId) {
    commit(Mutations.REMOVE_CHAPTER, chapterId);
  },
  removeChapterItem({ commit }, chapterItemid: EntityId) {
    commit(Mutations.REMOVE_CHAPTER_ITEM, chapterItemid);
  },
  setChapterTitle({ commit }, payload: { chapterId: EntityId, title: string }) {
    commit(Mutations.SET_CHAPTER_TITLE, payload);
  },
  newChapter({ commit }, insertAfterId: EntityId = null) {
    const newChapter: Chapter = {
      id: uuid(),
      position: null,
      title: null,
      createdAt: null,
      updatedAt: null,
      chapterItems: [],
      deleted: false
    }

    commit(Mutations.ADD_CHAPTER, { chapter: newChapter, insertAfter: insertAfterId });
  },
  setAddToChapter({ commit }, chapterId: EntityId) {
    commit(Mutations.SET_ADD_TO_CHAPTER, chapterId);
  },
  setSearchUrl({ commit }, url: string) {
    commit(Mutations.SET_SEARCH_URL, url);
  },
  addChapterItem({ commit }, chapterItem: ChapterItem) {
    commit(Mutations.ADD_CHAPTER_ITEM, chapterItem);
  },
  refreshPositions({ commit }) {
    commit(Mutations.REFRESH_POSITIONS);
  },
  processChapterSwitchForItem({ commit }, payload: { chapterId: EntityId, chapterItemId: EntityId }) {
    commit(Mutations.PROCESS_CHAPTER_SWITCH_FOR_ITEM, payload);
  },
  addDeletedChapterItem({ commit }, payload: { chapterId: EntityId, chapterItemId: EntityId }) {
    commit(Mutations.ADD_DELETED_CHAPTER_ITEM, payload);
  }
}
