<template>
<div class="lesson-editor-form">
  <div class="lesson-details">
    <text-input
      name="titleInput"
      v-model="title"
      :validate="{ required: true, max: 75 }"
      :label="$t('course.lesson_title')"></text-input>

    <text-area
      name="descriptionInput"
      v-model="description"
      :validate="{ max: 300 }"
      :label="$t('course.lesson_description')"></text-area>

    <text-input
      name="lessonExternalUrlInput"
      v-model="url"
      :validate="{ required: true, url: true }"
      :label="$t('course.lesson_external_url')"></text-input>

    <div class="actions">
      <button v-on:click="setUiState('lessonList')" type="button" class="btn btn-primary btn-flat">{{ $t('course.cancel') }}</button>
      <button v-on:click="saveChanges" :class="{ disabled: errors.any() }" type="button" class="btn btn-primary">{{ $t('course.apply_changes') }}</button>
    </div>
  </div>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { TextInput, TextArea } from './form';

export default {
  name: 'external-lesson-form',
  inject: ['$validator'],
  data() {
    return {
      title: '',
      description: '',
      url: ''
    }
  },
  components: {
    TextInput,
    TextArea
  },
  computed: {
    ...mapGetters({
      subject: 'course/lessonEditorSubject',
      lesson: 'course/lessonEditorObject',
      lessonType: 'course/lessonEditorType'
    }),
    lessonAttributes() {
      return {
        title: this.title,
        description: this.description,
        type: this.lessonType,
        subject: {
          id: this.subject.id,
          type: this.subject.type,
          description: this.subject.description,
          url: this.url
        }
      }
    }
  },
  methods: {
    ...mapActions({
      setUiState: 'course/setUiState',
      saveEditorLesson: 'course/saveEditorLesson'
    }),
    saveChanges() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.saveEditorLesson(this.lessonAttributes);
        }
      });
    }
  },
  watch: {
    lesson: {
      handler: function(lesson) {
        if (lesson.title && lesson.title.length > 0) {
          this.title = lesson.title;
        }

        this.description = lesson.description || '';

        if (lesson.subject) {
          this.url = lesson.subject.url;
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.lesson-editor-form {
  max-width: 800px;
  margin: 0 auto;
}

.actions {
  text-align: right;
  margin-top: 50px;
}
</style>
