import Vue from 'vue';
import App from './components/app.vue';
import { FilmProductionModule } from './store';
import store from '../store';
import { Actions } from './store/actions';
import RecordingService from '../recording/recorder';
import { state } from '../media_library/store/state';
import { Getters } from './store/getters';
import { ConvertJob, EtaState, ProductionJobProgress } from './interfaces';

export let app: Vue = null;
export let watcher = null;

const recorder = RecordingService.recorderInstance;

export function init() {
  const element = document.getElementById('film-production');
  const progressData = JSON.parse(element.dataset.progress);
  const templateData = JSON.parse(element.dataset.template);
  const productionErrors = JSON.parse(element.dataset.errors);
  const absData = JSON.parse(element.dataset.absData);

  store.registerModule('filmProduction', FilmProductionModule);

  store.dispatch(Actions.SET_API_URLS, {
    abortProduction: element.dataset.abort
  });

  store.dispatch(Actions.SET_TEMPLATE_DATA, templateData);

  if (progressData) {
    store.dispatch(Actions.SET_PROGRESS_DATA, progressData);
  }
  
  if (absData) {
    store.dispatch(Actions.SET_ABS_DATA, { enabled: absData.enabled, progress: absData.progress });
  }

  store.dispatch(Actions.SET_ERRORS, productionErrors);

  if (Object.keys(productionErrors).length > 0) {
    VERSTEHE.ContributionWorkflow.workflow.enableWorkflowStep('medium-production');
    VERSTEHE.ContributionWorkflow.workflow.setErrorStepState('medium-production', true);
    VERSTEHE.ContributionWorkflow.workflow.setActiveSubWorkflowStep('medium-production');
  }

  app = new Vue({
    name: 'FilmProduction',
    el: element,
    store: store,
    i18n: VERSTEHE.vueI18n,
    provide: {
      recorder: recorder
    },
    render: h => h(App)
  });

  if (progressData && !progressData.finished) {
    watchJob(progressData.id);

    if (absData) {
      watchAbsJob(absData.job_id);
    }
  }
}

export function watchAbsJob(jobId) {
  VERSTEHE.cable.subscriptions.create({
    channel: 'ProductionChannel',
    job_id: jobId
  }, {
    received: (data) => {
      store.dispatch(Actions.SET_ABS_DATA, data);
    }
  });
}

export function watchJob(jobId) {
  VERSTEHE.cable.subscriptions.create({
    channel: 'ProductionChannel',
    job_id: jobId
  }, {
    received: (data) => {
      // get the old eta from the store and write it to data
      data = setEtaInData(store.getters[Getters.PROGRESS], data);

      if (data.state === 'thumbnail_generated') {
        VERSTEHE.ThumbnailSelect.component.refreshPreview(data.thumbnail);
        return;
      }
      
      store.dispatch(Actions.SET_PROGRESS_DATA, data);

      if(data.state === 'merged' && data.merged.exists) {
        VERSTEHE.ThumbnailSelect.component.enableButton('film');
      }

      if (data.finished) {
        // Update workflow navigation
        VERSTEHE.TopicManage.handleProductionResult(data.state === 'produced');
      }
    }
  })
}

function setEtaInData(oldData: any, newData: ProductionJobProgress): any {
  // if newData has no eta state for merge, use the old eta state
  if (newData.merge_eta_state === undefined && oldData.merge_eta_state) {
    let etaState: EtaState = {
      conversion_tuple: oldData.merge_eta_state.conversion_tuple,
      current_step: oldData.merge_eta_state.current_step,
      eta: oldData.merge_eta_state.eta,
      work_type: oldData.merge_eta_state.work_type,
      work_status: oldData.merge_eta_state.work_status
    }
    newData.merge_eta_state = etaState;
  }

  // if newData has no eta states for the transcodes, use the old eta states
  if (newData.converting_jobs) {
    for (let i = 0; i < newData.converting_jobs.length; i++) {
      let newJob: ConvertJob = newData.converting_jobs[i];
      let oldJob: ConvertJob = oldData.converting_jobs[i];
  
      if (newJob.eta_state === undefined && oldJob.eta_state) {
        let etaState: EtaState = {
          conversion_tuple: oldJob.eta_state?.conversion_tuple,
          current_step: oldJob.eta_state?.current_step,
          eta: oldJob.eta_state?.eta,
          work_type: oldData.eta_state?.work_type,
          work_status: oldData.eta_state?.work_status
        }
        newJob.eta_state = etaState;
      }
    }
  }

  return newData;
}
