import { GetterTree } from "vuex";
import { RootState } from "../../../store/types";
import { CustomState } from './state';
import { UppyUtils } from "../../utils";

export const getters: GetterTree<CustomState, RootState> = {
  state: state => state,
  incompleteUploads: state => Object.keys(state.files).map(key => state.files[key]).filter(file => !file.progress.uploadComplete),
  totalCount: state => Object.keys(state.files).length,
  completedCount: state => Object.keys(state.files).map(key => state.files[key]).filter(file => file.progress.uploadComplete).length,
  paused: state => state.paused,
  totalProgress: state => state.totalProgress,
  hasFiles: state => Object.keys(state.files).length > 0,
  hasUploads: state => Object.keys(state.currentUploads).length > 0,
  hasErrors: state => Object.keys(state.files).map(key => state.files[key]).filter(file => typeof (file as any).error !== 'undefined').length > 0,
  totalETA: state => UppyUtils.getTotalETA(Object.keys(state.files).map(key => state.files[key]))
};
