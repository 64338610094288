<template>
<div class="lesson-playlist">
  <div :class="['lesson-media', { 'no-detail': !detail }]">
    <video-player
      ref="videoPlayer"
      v-if="currentElement.mediaType === 'film'"
      :options="videoOptions"
      :screenplay="screenplay"
      :thumbnails="thumbnails"
      :tracking="tracking"
      @progress="onProgress($event)"
      @loadedmetadata="onLoadedmetadata"
      :sources="currentElement.sources"></video-player>

    <no-access v-if="!currentElement.hasAccess"/>

    <video-player
      ref="videoPlayer"
      v-if="currentElement.mediaType === 'clip'"
      :options="videoOptions"
      :sources="currentElement.sources"></video-player>

    <slideshow-player
      ref="slideshowPlayer"
      v-if="currentElement.mediaType === 'slideshow'"
      :tracking="currentElement.progressUrl"
      :slides="currentElement.sources"
      @progress="onProgress($event)"></slideshow-player>
  </div>

  <div class="playlist-element-control">
    <a href="#" @click="previousElement" :class="{ disabled: isPlaylistStart }"><i class="material-icons">chevron_left</i></a>
    <div class="playlist-current-element">
      <span class="playlist-current-index">{{ this.currentIndex + 1 }} / {{ this.totalElements }}</span>
      {{ currentElement.title }}
    </div>
    <a href="#" @click="nextElement" :class="{ disabled: isPlaylistEnd }"><i class="material-icons">chevron_right</i></a>
  </div>

  <div class="lesson-detail" v-if="detail">
    <p class="lesson-description">{{ currentLesson.description }}</p>

    <div class="lesson-subject">
      <h3 class="lesson-subject-title">{{ lessonDetail.subject.title }}</h3>
      <a :href="lessonDetail.subject.authorUrl" class="lesson-subject-author">
        <div class="lesson-subject-author-avatar" :style="{ backgroundImage: `url(${lessonDetail.subject.authorAvatar})` }"></div>
        <div class="lesson-subject-author-name">{{ lessonDetail.subject.author }}</div>
      </a>
      <p class="lesson-subject-description">{{ lessonDetail.subject.description }}</p>
    </div>
  </div>
</div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('coursePerform');

import VideoPlayer from '../../../../globals/vue/video_player';
import SlideshowPlayer from '../../../../globals/vue/slideshow_player.vue';
import NoAccess from './no_access.vue';

export default {
  name: 'playlist-lesson',
  props: {
    detail: {
      default: true
    },
    embedMode: {
      default: false
    }
  },
  components: {
    VideoPlayer,
    SlideshowPlayer,
    NoAccess
  },
  computed: {
    ...mapGetters({
      currentLesson: 'currentLesson',
      currentElement: 'currentPlaylistElement'
    }),
    lessonDetail() {
      return this.currentLesson.detail;
    },
    videoOptions() {
      return {
        fluid: false,
        autoplay: true,
        playbackRates: [0.5, 1, 1.25, 1.5, 2]
      }
    },
    mediaType() {
      return this.lessonDetail.subject.mediaType;
    },
    sources() {
      return this.lessonDetail.subject.sources;
    },
    currentIndex() {
      return this.playlistElements.indexOf(this.currentElement);
    },
    totalElements() {
      return this.playlistElements.length;
    },
    isPlaylistStart() {
      return this.currentIndex <= 0;
    },
    isPlaylistEnd() {
      return this.currentIndex === this.playlistElements.length - 1;
    },
    playlistElements() {
      return this.lessonDetail.subject.elements;
    },
    thumbnails() {
      return this.embedMode ? null : this.currentElement.thumbnails;
    },
    screenplay() {
      return this.currentElement.screenplay;
    },
    tracking() {
      return this.embedMode ? null : this.currentElement.progressUrl;
    }
  },
  methods: {
    ...mapActions([
      'setCurrentPlaylistElement',
      'setCurrentPlaylistElementProgress'
    ]),
    onProgress(progress) {
      this.setCurrentPlaylistElementProgress(progress);
    },
    nextElement() {
      if (!this.isPlaylistEnd) {
        this.setCurrentPlaylistElement(this.playlistElements[this.currentIndex + 1]);
      }
    },
    previousElement() {
      if (!this.isPlaylistStart) {
        this.setCurrentPlaylistElement(this.playlistElements[this.currentIndex - 1]);
      }
    },
    onLoadedmetadata() {
      if (this.currentElement.progress > 0 && this.currentElement.progress < 1) {
        const time = this.$refs.videoPlayer.duration * this.currentElement.progress;
        this.$refs.videoPlayer.setInitialTime(time);
      }
    }
  },
  created() {
    this.setCurrentPlaylistElement(this.playlistElements[0]);
  },
  destroyed() {
    this.setCurrentPlaylistElement(null);
  }
}
</script>

<style lang="scss" scoped>
.lesson-media {
  height: calc(100vh - 120px);
}

.lesson-media.no-detail {
  height: calc(100vh - 60px);
}

.playlist-element-control {
  display: flex;
  height: 60px;
  justify-content: space-between;
  align-items: center;
  background-color: $color-grey-10;

  > a {
    font-size: 30px;
    padding: 15px;
    color: $black-54-opacity;

    &:hover {
      color: $black-87-opacity;
    }
  }

  > a.disabled {
    color: $black-12-opacity;
    cursor: default;
  }
}

.embed .playlist-element-control {
  position: absolute;
}

.playlist-current-index {
  color: $black-54-opacity;
  font-size: .8em;
}

.playlist-current-element {
  font-size: 16px;
}

::v-deep .video-player {
  height: 100%;

  .video-js {
    width: 100%;
    height: 100%;
    padding: 0;
  }
}

::v-deep .document-player {
  height: 100%;

  .slides {
    // Need to override height from Ideal-Image-Slider-JS
    height: calc(100% - 36px) !important;
  }
}
</style>
