import { Take, Slide, Image, ApiUrls, Section, ScreenplayStatus, TranscriptionLanguage } from "../../screenplay/interfaces";

export interface ScreenplayState {
  apiUrls: ApiUrls;
  workspace: Array<Take | Slide | Image>;
  screenplay: Array<Section>;
  transcriptionLanguageRequired: boolean;
  transcriptionLanguage: TranscriptionLanguage;
  valid: boolean;
  filmId: number;
  needsProduction: boolean;
  sceneDragging: boolean;
  screenplayStatus: ScreenplayStatus;
  screenplayErrors: any;
  deletedScenes: Array<{ section: any, scene: any }>;
  updatedAt: Date;
  workspaceDragging: boolean;
  availableVoices: Array<{ id: any, name: string, lang: string, engine: string }>;
  noSpanishTranscription: boolean;
}

export const state: ScreenplayState = {
  apiUrls: null,
  filmId: null,
  workspace: [],
  screenplay: [],
  transcriptionLanguageRequired: false,
  transcriptionLanguage: null,
  screenplayStatus: ScreenplayStatus.SAVED,
  screenplayErrors: {},
  valid: false,
  sceneDragging: false,
  needsProduction: true,
  deletedScenes: [],
  updatedAt: null,
  workspaceDragging: false,
  availableVoices: [],
  noSpanishTranscription: true
}
