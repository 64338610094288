<template>
  <li @click="editQuestion(question)">
    <div class="question-type-icon coovi-icon">{{ questionTypeIcon(question.type) }}</div>
    <div class="question-time">{{ formattedTime(question.time) }}</div>
    <div class="question-title">{{ question.title }}</div>
    <div class="question-actions">
      <a v-on:click.prevent.stop="removeQuestion(question)" href="#" class="material-icons">delete</a>
    </div>
  </li>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Actions } from '../store';

export default {
  props: ['question'],
  methods: {
    ...mapActions({
      removeQuestion: Actions.REMOVE_QUESTION,
      editQuestion: Actions.EDIT_QUESTION
    }),
    questionTypeIcon(questionType) {
      return questionType === 'FilmQuestion::SingleSelect' ? 'question_boolean' : 'question_multiple';
    },
    formattedTime(time) {
      if (time === null) {
        return ''
      }

      time = parseFloat(time);

      const minutes = parseInt(time / 60, 10);
      const seconds = parseInt(time % 60, 10);

      // Zero padded seconds
      const paddedSeconds = `0${seconds}`.slice(-2);

      return `${minutes}:${paddedSeconds}`;
    }
  }
}
</script>

<style lang="scss" scoped>
li {
  display: flex;
  position: relative;
  border: 1px solid $color-light-grey-2;
  border-radius: 3px;
  height: 60px;
  padding: 10px;
  margin: 10px 0;
  align-items: center;
  cursor: pointer;
  transition: border-color 100ms ease-in;

  &:hover {
    border-color: $color-link-grey;
  }

  &.active {
    border-color: var(--primary-color);
  }

  .question-type-icon {
    color: var(--primary-color);
    font-size: 30px;
    padding-left: 10px;
  }

  .question-time {
    flex-shrink: 0;
    color: $black-54-opacity;
    width: 100px;
    padding: 0 24px;
    text-align: right;
  }

  .question-title {
    flex: 1 auto;
    line-height: 20px;
    max-height: 40px;
    overflow: hidden;
  }
}

.question-actions {
  display: flex;
  align-items: center;

  > a {
    font-size: 20px;
    padding: 10px;
    color: $black-54-opacity;

    &:hover,
    &:focus,
    &:active {
      color: $black-87-opacity;
    }
  }
}
</style>
