import Vue from 'vue';
import Vuex from 'vuex';
import { state } from './state';
import { actions } from './actions';
import { mutations } from './mutations';
import { getters } from './getters';
import SettingsPlugin from './settings_plugin';
import CustomBackgroundImagePlugin from './custom_background_images_plugin';

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  actions,
  mutations,
  getters,
  plugins: [
    SettingsPlugin,
    CustomBackgroundImagePlugin,
  ],
});
