import { Busy } from '../../globals/busy';

export class ListFigure {
  constructor(element, container) {
    this.element = element;
    this.id = this.element.getAttribute('id');
    this.container = container;
    this.currentValue = null;
    this.params = { "page": 1, "initial": true };
    this.detailUrl = null;
    this.detailTokens = null;
    this.treeList = null;
    this.treeListDataSource = null;
    this.busy = new Busy().loader;

    this.displayInterval();
  }

  load(data) {
    this.currentValue = data;

    if (typeof data === "string") {
      this.element.innerHTML = this.currentValue;
      this.setDetailParams();
    } else {
      this.setDataSource();
      this.initTreeList();
    }

    this.bindListener();
  }

  setDataSource() {
    this.treeListDataSource = new kendo.data.TreeListDataSource({
      data: this.currentValue.data
    });
  }

  setDetailParams() {
    let url = this.element.querySelector('.list-widget-items').getAttribute('data-detail-url');
    let tokens = this.element.querySelector('.list-widget-items').getAttribute('data-content-tokens');

    if( url && tokens) {
      this.detailUrl = url;
      this.detailTokens = tokens.split(",");
    }
  }

  displayInterval() {
    let interval = this.container.dashboard.getLocatedTimeInterval();
    let from = this.container.container.parentNode.querySelector('.interval-from');
    let till = this.container.container.parentNode.querySelector('.interval-till');
    let unlimited = this.container.container.parentNode.querySelector('.unlimited');

    if (interval["from"] === "Invalid date" || interval["till"] === "Invalid date") {
      this.dipslayUnlimited(from, till, unlimited);
    } else {
      this.showInterval(from, till, unlimited, interval);
    }
  }

  showInterval(from, till, unlimeted, interval) {
    // set the current interval as label
    from.innerHTML = interval["from"];
    till.innerHTML = interval["till"];

    // display the interval labe add hide unlimeted
    if (unlimeted) {
      from.classList.remove('hidden');
      till.classList.remove('hidden');
      unlimeted.classList.add('hidden');
    }
  }

  dipslayUnlimited(from, till, unlimeted) {
    // set the interval labels to unlimited
    // fallback if non unlimeted label field exists
    from.innerHTML = "Unlimited";
    till.innerHTML = "Unlimited";

    // Hide interval label fields and display unlimited
    if (unlimeted) {
      from.classList.add('hidden');
      till.classList.add('hidden');
      unlimeted.classList.remove('hidden');
    }
  }

  initTreeList() {
    if (this.treeList) {
      this.treeList.setDataSource(this.treeListDataSource);
      this.treeList.refresh();
    } else {
      this.treeList = $(this.element).find('.treelist').kendoTreeList({
        dataSource: this.treeListDataSource,
        height: "410",
        width: "100%",
        columns: this.currentValue.columns
      }).data('kendoTreeList');
    }
  }

  buildParam() {
    return this.params;
  }

  buildDetailParams() {
    return { "tokens": this.detailTokens, "widget": this.id };
  }

  loadDetailView(page, paginate) {
    document.querySelector('#full_size_detail').querySelector('.modal-content').appendChild(this.busy);
    let params = this.buildDetailParams();
    params["page"] = page;
    params["paginate"] = paginate;

    $.get(this.detailUrl, params, (response) => {
      let modalContent = document.querySelector('#full_size_detail').querySelector('.modal-content');
      let modalTitle = document.querySelector('#full_size_detail').querySelector('.modal-title');
      modalTitle.innerHTML = this.container.readContainerTitle();
      modalContent.querySelector('.modal-body').innerHTML = response;
      document.querySelector('#full_size_detail').querySelector('.modal-content').removeChild(this.busy);
      this.bindDetailPaginationListener();
    });
  }

  bindListener() {
    // Bind click listener to the pagination, so we only replace the widget content
    let pageLinks = this.element.querySelectorAll('.list-figure-widget-pagination li:not(.active):not(.disabled) a');

    for (let i = 0; i < pageLinks.length; i++) {
      pageLinks[i].addEventListener('click', (e) => {
        e.preventDefault();
        // set new endpoint and rerender the list figure
        this.params["page"] = pageLinks[i].getAttribute('data-page');
        this.params["initial"] = false;
        this.container.loadWidgets();
        this.params["initial"] = true;
      });
    }

    document.addEventListener('intervalChanges', e => {
      this.displayInterval();
    });

    if (this.detailUrl && this.detailTokens) {
      this.element.querySelector('.list-widget-items').addEventListener('click', e => {
        e.preventDefault();
        $('#full_size_detail').modal();
        this.loadDetailView(1, false);
      });
    }

  }

  bindDetailPaginationListener() {
    let modal = document.querySelector('#full_size_detail');
    let pageLinks = modal.querySelectorAll('.contribution-detail-pagination li:not(.active):not(.disabled) a');

    for (let i = 0; i < pageLinks.length; i++) {
      pageLinks[i].addEventListener('click', (e) => {
        e.preventDefault();
        // set new endpoint and rerender the list figure
        this.loadDetailView(pageLinks[i].getAttribute('data-page'), true);
      });
    }
  }
}
