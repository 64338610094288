import { ChangeAction } from "../../../system_actions/change_action";

/**
 * SlideshowActions can be SlideActions, SaveSlidesAction and allSlidesActions
 * The superclass SlideshowAction exists so SaveSlidesAction can replace all of the inheriting actions
 */
export class SlideshowAction extends ChangeAction {
  /**
   * @constructor
   * @param {String} tabId  - TabId of the tab the action originates from
   * @param {number} user - UserId of the chat room owner
   * @param {String} element - Element thats subject of the UIAction
   */
  constructor(tabId, user, element) {
    super(tabId, user, element);
    /** Check if inheriting class implements execute() method */
    if (this.execute === undefined) {
      throw new TypeError("Must implement execute");
    }
  }
}
