<template>
  <div class="film-questions-app">
    <select-pane/>
    <form-pane/>
  </div>
</template>

<script>
import SelectPane from './select_pane.vue';
import FormPane from './form_pane.vue';

export default {
  components: {
    SelectPane,
    FormPane
  }
}
</script>

<style lang="scss" scoped>
.film-questions-app {
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
</style>
