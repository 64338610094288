import App from './components/app.vue';
import { ScreenplayModule } from './store';
import store from '../store';
import Vue from 'vue';
import { Actions } from '../screenplay/store/actions';
import { Actions as LibraryActions } from '../media_library/store/actions';
import RecordingService from '../recording/recorder';

const recorder = RecordingService.recorderInstance;

export let screenplayApp: Vue = null;
export let unsavedChanges: boolean = false;

export function init() {
  const element = document.getElementById('v-screenplay');
  const initialData = JSON.parse(element.dataset.initial);

  store.registerModule('screenplay', ScreenplayModule);
  store.dispatch(Actions.SET_INITIAL_DATA, initialData);

  // Switch to Screenplay on screenplay validation errors
  if (Object.keys(initialData.screenplay_errors).length > 0) {
    VERSTEHE.ContributionWorkflow.workflow.setActiveSubWorkflowStep('medium-screenplay');
  }

  screenplayApp = new Vue ({
    name: 'ScreenplayApp',
    el: '#v-screenplay',
    store: store,
    i18n: VERSTEHE.vueI18n,
    provide: {
      recorder: recorder,
      user: _app.info.user,
    },
    render: h => h(App)
  });

  initRecorderEvents();
}

function initRecorderEvents() {
  recorder.on('refresh-amor', onRefreshAmor);
  recorder.on('convert-status', onConvertStatus);
  recorder.on('upload-status', onUploadStatus);
  recorder.on('upload-finished', onUploadFinished);
  recorder.on('slide-audio-record-success', onSlideAudioRecorderSuccess);
  recorder.on('split-amor-success', onSplitAmorSuccess);
}

function onSplitAmorSuccess(message) {
  if (recorder.context === 'screenplay') {
    store.dispatch(Actions.ADD_TO_WORKSPACE, { slide_ids: [], take_ids: [message.take.id], image_ids: [], import_to_screenplay: false });
  }
}

function onRefreshAmor(message) {
  const payload =  {
    id: message.take.id,
    thumbnail_url: message.amor.thumbnail_url,
    duration: message.take.duration,
    height: message.amor.height,
    width: message.amor.width,
    state: message.amor.state,
    source: message.amor.source,
    is_locked: message.amor.is_locked,
    last_locking_user: message.amor.last_locking_user,
    last_locking_user_id: message.amor.last_locking_user_id,
    uploadlock_user_id: message.amor.uploadlock_user_id
  }

  store.dispatch(Actions.REFRESH_AMOR_ACTION, payload);
}

function onConvertStatus(amor_id, progress) {
  const payload = {
    amor_id: amor_id,
    values: {
      uploading: false,
      converting: true,
      convert_progress: progress
    }
  }
  store.dispatch(Actions.UPDATE_TAKE, payload);
}

function onUploadStatus(amor_id, progress) {
  const payload = {
    amor_id: amor_id,
    values: {
      uploading: true,
      converting: false,
      upload_progress: progress
    }
  }
  store.dispatch(Actions.UPDATE_TAKE, payload);
}

function onUploadFinished(amor_id) {
  const payload = {
    amor_id: amor_id,
    values: {
      uploading: false,
      converting: false,
      state: 'uploaded'
    }
  }
  store.dispatch(Actions.UPDATE_TAKE, payload);
  store.dispatch(Actions.SET_NEEDS_PRODUCTION, true);
}

function onSlideAudioRecorderSuccess(result) {
  if(result.origin === 'screenplay') {
    store.dispatch(Actions.ADD_TO_WORKSPACE, { slide_ids: [], take_ids: [result.take.id], image_ids: [] });
  }
}

export function addToWorkspace(media_ids: { slide_ids: Array<number>, take_ids: Array<number>, image_ids: Array<number> }) {
  store.dispatch(Actions.ADD_TO_WORKSPACE, media_ids)
}

export function validateScreenplay(): Promise<boolean> {
  return (screenplayApp.$children[0] as any).validator.validate();
}

export function saveScreenplay(): Promise<any> {
  return store.dispatch(Actions.SAVE_SCREENPLAY);
}

export function setUnsavedChanges(value) {
  unsavedChanges = value;
}

export function localStorageKey() {
  return 'film_' + store.state.screenplay.filmId;
}
