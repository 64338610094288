<template>
  <div class="api-token-item">
    <div class="api-token-item-description">
      <div class="cell-wrap">{{ token.description }}</div>
    </div>
    <div class="api-token-item-prefix">
      <div class="cell-wrap">
        <button v-if="!token.obfuscated" class="btn btn-flat btn-xxs copy-token" type="button" @click="copyToken">
          <i class="material-icons">content_copy</i>
        </button>
        {{ token.token }}
      </div>
    </div>
    <div class="api-token-item-access-type">
      <div class="cell-wrap">
        <span class="label label-primary">{{ $t('api_tokens.read_access') }}</span>
        <span v-if="token.access_type === 'write_access' || token.access_type === 'login_access'" class="label label-primary">{{ $t('api_tokens.write_access') }}</span>
        <span v-if="token.access_type === 'login_access'" class="label label-primary">{{ $t('api_tokens.login_access') }}</span>
      </div>
    </div>
    <div class="api-token-item-actions">
      <div class="cell-wrap">
        <a href="#" @click.prevent="openForm(token.id)">
          <i class="material-icons">edit</i>
        </a>
        <a href="#" @click.prevent="deleteToken(token)">
          <i class="material-icons">delete</i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { successMessage, errorMessage } from '../../mixins/flash_message';

export default {
  props: ['token'],
  methods: {
    ...mapActions({
      openForm: 'apiTokens/OPEN_FORM',
      deleteToken: 'apiTokens/DELETE_API_TOKEN'
    }),
    async copyToken() {
      try {
        await navigator.clipboard.writeText(this.token.token);
        successMessage(this.$t('api_tokens.clipboard_success'))
      } catch {
        errorMessage(this.$t('api_tokens.clipboard_failed'))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.api-token-item {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 52px;
  grid-gap: 20px;
  align-items: stretch;
  border: 1px solid $color-light-grey-2;
  border-radius: 3px;
  padding: 10px;
  margin: 10px;
  height: 60px;
}

.api-token-item-description,
.api-token-item-prefix,
.api-token-item-access-type,
.api-token-item-actions {
  display: grid;
  justify-content: start;
  align-items: center;
  white-space: nowrap;
}

.api-token-item-actions a {
  display: inline-block;
  color: $black-54-opacity;
  font-size: 20px;
  line-height: 20px;
  width: 20px;
  height: 20px;
  margin-left: 4px;

  &:focus,
  &:hover,
  &:active {
    color: $black-87-opacity;
  }
}

.cell-wrap {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
  justify-self: start;
}

.copy-token {
  margin: 0 5px 0 0;
}
</style>
