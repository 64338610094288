<template>
<div class="course-nav">
  <i18n path="course.completed_at" tag="div" class="course-completed" v-if="completedCourse">
    <span :title="$d(completedAt, 'long')">{{ $d(completedAt, 'short') }}</span>
  </i18n>

  <div class="course-nav-actions">
    <button
      v-if="needConfirmation"
      type="button"
      class="btn btn-flat btn-default open-confirm-dialog"
      @click="onConfirmCourseButton">{{ $t('course.confirm_completion') }}</button>
    <router-link
      v-if="canEvaluate"
      tag="button"
      to="/feedback"
      class="btn btn-flat btn-default"
      type="button">{{ $t('course.give_feedback') }}</router-link>
  </div>

  <lesson-group
    v-for="(lessonGroup, index) in course.lessonGroups"
    :key="lessonGroup.id"
    :number="index + 1"
    :lessonGroup="lessonGroup">
  </lesson-group>

  <div v-if="hasQuiz" class="lesson-group course-explore" :class="{ 'lesson-group-completed': isCompletedQuiz }">
    <div class="lesson-group-head">
      <div class="lesson-group-title">
        <h2>{{ $t('course.check_your_knowledge') }}</h2>
      </div>

      <div class="lesson-group-progress">
        {{ isCompletedQuiz ? 1 : 0 }} / 1
      </div>
    </div>

    <ul class="lesson-group-lessons">
      <li :class="{ active: isQuizActive }">
        <router-link to="/knowledge_check" class="lesson">
          <div class="lesson-type">
            <i class="coovi-icon">quiz_lesson</i>
          </div>
          <div class="lesson-title">{{ course.quiz.title }}</div>
          <div class="lesson-duration">{{ $tc('course.questions_count', course.quiz.questionsCount) }}</div>
          <div class="lesson-progress">
            <i v-if="isCompletedQuiz" class="material-icons">check</i>
          </div>
        </router-link>
      </li>
    </ul>
  </div>

  <kendo-dialog
    ref="confirmationDialog"
    v-if="needConfirmation"
    :title="$t('course.confirmation')"
    :content="$t('course.confirmation_needed')">
    <kendo-dialog-action
      :primary="true"
      :text="$t('course.confirm_now')"
      :action="confirmCourse"></kendo-dialog-action>
  </kendo-dialog>
</div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('coursePerform');

import { Dialog } from '@progress/kendo-dialog-vue-wrapper'
import LessonGroup from './lesson_group.vue';

export default {
  name: 'lesson-navigation',
  components: {
    Dialog,
    LessonGroup
  },
  computed: {
    ...mapGetters([
      'parameters',
      'course',
      'completedCourse',
      'needConfirmation',
      'canEvaluate'
    ]),
    hasQuiz() {
      return this.course.quiz && this.course.quiz.hasQuiz;
    },
    isCompletedQuiz() {
      return this.course.quiz.isCompleted;
    },
    isQuizActive() {
      return this.$route.name === 'quiz';
    },
    completedAt() {
      if (this.completedCourse) {
        return new Date(this.completedCourse);
      }
    }
  },
  methods: {
    ...mapActions([
      'confirmCourse'
    ]),

    onConfirmCourseButton(e) {
      e.target.classList.add('disabled');
      this.confirmCourse();
    }
  }
}
</script>

<style lang="scss" scoped>
.course-completed {
  margin-bottom: 15px;
  text-align: center;
}

.course-nav-actions {
  text-align: center;

  > .btn {
    margin-top: 0;
  }
}

::v-deep .lesson-group {
  background-color: $color-white;
}
</style>
