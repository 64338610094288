import { localization } from './locals';
import { getURLParameter } from '../common/helpers';
import { applyHtml } from './mixins/form_helper';
import { errorMessage, noticeMessage } from '../mixins/flash_message';

export class ConfigTree {
  constructor() {
    this.tree = null;
    this.localization = localization[_app.info.currentLocale];
    this.initialManageTree = $("#manage-tree")[0] ? $("#manage-tree")[0].outerHTML : null;
    this.selectedFieldId = null;
    this.newlyAddedElements = {};
    this.initialized = false;
    this.initFieldForm();

    // Check if field_id was passed and trigger a click on the corresponding list element
    // Select topic_area if field_id was not passed
    let field_id = getURLParameter('field_id');
    let field_selector = $(`#manage-taxonomy-view .edit-model-wrapper .dropdown-menu [data-id='${field_id}']`);

    if (field_id > 0 && field_selector.length) {
      field_selector.trigger('click');
    } else {
      $("#manage-taxonomy-view .edit-model-wrapper .dropdown-menu a:first").trigger('click');
    }
  }

  reloadCurrent() {
    $(`#manage-taxonomy-view .edit-model-wrapper .dropdown-menu a[data-id='${this.selectedFieldId}']`).trigger('click');
  }

  // Does the base initializing: Ensures that:
  // (a) there is no search error text
  // (b) #manage-tree is visible
  // (c) .create-taxonomy-root-node is visible
  showTreeWindow() {
    $("#no-search-hint").remove();
    $("#manage-tree, .create-taxonomy-root-node").show();
  }

  // Hides tree window, taxonomy-root-button and field hints
  hideTreeWindow() {
    $("#manage-tree, .create-taxonomy-root-node").hide();
    $(".field-hint").hide();
  }

  initFieldForm() {
    // On changing element type..
    let _this = this;
    let onFieldSelect = function() {
      let value_text = $(this).text(),
      id = $(this).data('id');

      // .. change form ..
      $.get(_this.getPath('get-field', id), (html) => {
        applyHtml(_this, html, value_text);
      }, "html");

      // .. and build tree for this field.
      _this.loadAndBuildTree(id);
    };

    // Listen to each exisiting fields
    $("#manage-taxonomy-view .edit-model-wrapper .dropdown-menu a").click(onFieldSelect);

    // Add a "add new" button with an own listener: pasting the "new" form
    let new_field = $("<a></a>").attr("href", '#').text(this.localization['new_field']).on('click', () => {
      $.get(this.getPath('new-field'), (html) => {
        applyHtml(this, html, '');
        $("#manage-taxonomy-view .current-edit").html(this.localization['new_field']);

        // Hide tree interface because of 'new' field
        this.hideTreeWindow();
      }, "html");
    });
    $("#manage-taxonomy-view .edit-model-wrapper .dropdown-menu").append($("<li></li>").addClass("divider"));
    $("#manage-taxonomy-view .edit-model-wrapper .dropdown-menu").append($("<li></li>").html(new_field));

    // Ajax success / error listeners
    $(document).on('ajax:error', '.field-form form', (e, xhr) => {
      xhr.responseJSON.errors.map((message) => {
        errorMessage(message);
      });
    });
    $(document).on('ajax:success', '.field-form form', (e, xhr) => {
      noticeMessage(xhr.success, 4000);

      if (xhr.field_info) {
        let link = $("<a></a>").attr("href", "#").data('id', xhr.field_info.id).text(xhr.field_info.title).click(onFieldSelect);
        $("#manage-taxonomy-view .edit-model-wrapper .dropdown-menu li.divider").before($("<li></li>").html(link));

        link.trigger('click'); // Switch to edit site, and load tree etc
      }
    });
  }

  // Gets a path to backend based on the path constant as it is
  // stated in $("#manage-taxonomy-view"). If you pass an id, it will
  // be used in the path string (instead of 'ID_PALCEHOLDER')
  getPath(path_constant, id) {
    return $("#manage-taxonomy-view").data(`${path_constant}-path`).replace('ID_PLACEHOLDER', id);
  }

  loadAndBuildTree(field_id) {
    // first, load field metadata: is hierarchy supported? What's the localized title?
    this.loadFieldInfo(field_id, (field_info) => {
      $("#manage-tree").replaceWith(this.initialManageTree);

      this.parseTreeInDOM(field_info["elements"], field_info["localized_title"], field_info["field_type"] === 'topic_area', field_info["supports_hierarchy"]);

      $("#manage-taxonomy-view .field-hint").hide();
      $(`#manage-taxonomy-view .${field_info['field_type']}-hint`).show();

      this.selectedFieldId = field_id;

      this.bindTreeListeners();
      if (!this.initialized) {
        this.bindSiteListeners();
        this.initialized = true;
      }
    });
  }

  loadFieldInfo(id, info_callback) {
    $.get(this.getPath('get-field-info', id), (json) => {
      // Adds a dummy type: 'metadata' entry to each element (needed for displaying)
      json.elements = json.elements.map((currentValue) => $.extend(currentValue, { type: 'metadata' }));
      info_callback(json);
    }, "json");
  }

  parseTreeInDOM(tree_elements, field_name, topic_area_functionalities, children_allowed) {
    let _this = this;
    this.tree = $("#manage-tree").jstree({
      core: {
        themes: {
          name: 'default-dark',
          responsive: false
        },
        check_callback: (operation, node) => operation !== 'move_node' ? true : (node.original.id ? true : false),
        data: tree_elements
      },
      search: {
        show_only_matches: true,
        show_only_matches_children: true
      },
      dnd: {
        drag_selection: false
      },
      checkbox: {
        keep_selected_style: false
      },
      types: {
        '#': {
          valid_children: ['metadata'],
          max_depth: children_allowed ? -1 : 1
        },
        'metadata': {
          icon: 'material-icon-library-books',
          valid_children: ['metadata'],
          max_depth: children_allowed ? -1 : 1
        }
      },
      contextmenu: {
        show_at_node: false,
        select_node: false,
        items: (node, cb) => {
          let items = {
            "new_element": {
              "separator_before": false,
              "icon": false,
              "separator_after": false,
              "label": this.localization['new_element_prefix'] +
              field_name + this.localization['new_element_suffix'],
              "icon": "material-icon-library-books",
              "_disabled": false,
              "action": (data) => {
                let inst = $.jstree.reference(data.reference),
                  obj = inst.get_node(data.reference);
                inst.create_node((children_allowed ? obj : '#'), { text: '', type: 'metadata' }, 'last', (new_node) => {
                  setTimeout(() => inst.edit(new_node, null, this.createNode.bind(this)), 0);
                });
              }
            }
          };

          function treeTemplateItem({ action, icon, label, _disabled = false, separator_after = false, separator_before = false}) {
            return { separator_before, separator_after, _disabled, label, icon, action };
          }

          let inst = this.tree.jstree(true);
          let parent = node.parents[0];

          // Only offer user selection for root nodes or nodes, which do have siblings
          let allowUserAssignment = parent === '#' || inst.get_node(parent).children.length > 1;

          if (topic_area_functionalities) {
            items = $.extend(items, {
              "edit": treeTemplateItem({
                label: this.localization['edit'],
                icon: "material-icon-launch",
                action: (data) => {
                  let inst = $.jstree.reference(data.reference);
                  window.location.href = this.getPath('edit-topic-area', _this.getIdByNode(node));
                }
              }),
              "change_editors": treeTemplateItem({
                "label": this.localization['change_editors'],
                "icon": 'material-icon-person',
                "_disabled": !allowUserAssignment,
                "action": (data) => {
                  let inst = $.jstree.reference(data.reference),
                    obj = inst.get_node(data.reference);
                  _this.openUserSelect('add_editors', [_this.getIdByNode(obj)]);
                }
              }),
              "change_reviewers": treeTemplateItem({
                "label": this.localization['change_reviewers'],
                "icon": 'material-icon-assignment-ind',
                "_disabled": !allowUserAssignment,
                "action": (data) => {
                  let inst = $.jstree.reference(data.reference);
                  _this.openUserSelect('add_reviewers', [_this.getIdByNode(inst.get_node(data.reference))]);
                }
              })
            });
          }

          items = $.extend(items, {
            "rename": treeTemplateItem({
              "label": this.localization['rename'],
              "icon": "material-icon-mode-edit",
              "action": (data) => {
                let inst = $.jstree.reference(data.reference), obj = inst.get_node(data.reference);
                inst.edit(obj);
              }
            }),
            "remove": treeTemplateItem({
              "separator_before": true,
              "icon": "material-icon-delete",
              "label": this.localization['delete'],
              "action": (data) => {
                let inst = $.jstree.reference(data.reference), node = inst.get_node(data.reference);
                _this.deleteNode(node, (data) => inst.delete_node(node));
              }
            })
          });

          return items;
        }
      },
      plugins: ['dnd', 'search', 'types', 'checkbox', 'contextmenu']
    }).jstree('open_all');

    $(".tree-action-only-topic-areas").hide();
    if (topic_area_functionalities) {
      $(".tree-action-only-topic-areas").show();
    }
  }

  openUserSelect(action, ids, empty) {
    let inst = this.tree.jstree(true);
    let url = `${this.getPath('base-topic-areas')}/${action}`;

    if (typeof empty === 'undefined') { empty = false; }

    $.ajax({
      url: url,
      method: 'GET',
      data: { empty, ids },
      success: (html) => {
        $('#taxonomy_add_users_modal .modal-content').html(html);
        $('#taxonomy_add_users_modal').modal('show');
      }, error: function (xhr) {
        if (xhr.status === 400) {
          errorMessage(xhr.responseJSON.error);
        }
      }
    });
  }

  // callback is triggered when a new node is about to get added
  createNode(node, status) {
    let inst = this.tree.jstree(true),
      url = this.getPath('base-elements');

    if (node.text.length === 0) {
      inst.delete_node(node);
    } else {
      let title = node.text,
        parent_id = this.getIdByNode(document.getElementById(node.parent)),
        params = { element: { title, parent_id } ,field_id: this.selectedFieldId };

      $.ajax({
        url,
        method: 'POST',
        dataType: 'json',
        data: params,
        success: (data) => {
          node.original.id = data.id;
          this.newlyAddedElements[node.id] = `element_${data.id}`;
        },
        error: (xhr, status) => {
          if (xhr.status === 400) {
            // display errors as flash
            xhr.responseJSON.errors.map((message) => {
              errorMessage(message);
            });
          }

          inst.delete_node(node);
        }
      });
    }
  };

  deleteNode(node, success) {
    let url = `${this.getPath('base-elements')}/${this.getIdByNode(node)}` ,
      data = { element: null, field_id: this.selectedFieldId};

    $.ajax({
      url,
      method: 'DELETE',
      dataType: 'json',
      data,
      success,
      error: (xhr, status) => {
        if (xhr.status === 400) {
          let response = xhr.responseJSON;
          // display errors as flash
          response.errors.map((message) => {
            errorMessage(message);
          });

          // show modal to move contributions
          if (!response.deletable) {
            let inst = this.tree.jstree(true),
              url = $('.tree-batch-action > .reassign-contributions').attr('href'),
              ids = new Array;

            ids.push(this.getIdByNode(node));
            node.children_d.map((node_id) => {
              let n = inst.get_node(node_id);
              ids.push(this.getIdByNode(n));
            });

            $.ajax({
              url,
              method: 'GET',
              data: $.extend(data, {
                ids: ids.join(','),
                id: this.getIdByNode(node),
                delete_after: true
              }),
              beforeSend: () => {
                VERSTEHE.utils.showLoadingIndicator({ opacity: 0.5 });
              },
              success: (html) => {
                $('#reassign_contributions_modal .modal-content').html(html);
                $('#reassign_contributions_modal').modal('show');
              },
              error: (xhr) => {
                if (xhr.status === 400) {
                  errorMessage(xhr.responseJSON.error);
                }
              },
              complete: () => VERSTEHE.utils.hideLoadingIndicator()
            });
          }
        } else {
          errorMessage(VERSTEHE.vueI18n.t('globals.could_not_delete_object'));
        }
      }
    });
  };

  getIdByNode(node) {
    let $node = $(node);

    // The given node has to exist and either is newly added or it's id has to start with 'element_'
    if ($node.length === 0 || ($node.attr('id').lastIndexOf('element_', 0) !== 0 && typeof (this.newlyAddedElements[$node.attr('id')]) === 'undefined')) {
      return null;
    }

    // we cannot set the id attribute of new elements since jstree is using it internally.
    // But for each new element, we have an intern my.newlyAddedElements hashmap.
    let id_container = $node.attr('id');

    if (id_container.lastIndexOf('element_', 0) !== 0) {
      id_container = this.newlyAddedElements[$node.attr('id')];
    }

    return id_container.replace('element_', '');
  };

  bindTreeListeners() {
    // This is a little bit dirty but needed since jstree forces to use links as container of tree elements
    this.tree.delegate('.tree-action-regular-link', 'click', function (e) {
      e.preventDefault();

      if ($(this).hasClass("material-icons") && $(this).text() === 'open_in_new') {
        window.open($(this).attr("href"));
      } else {
        window.location.href = ($(this).attr("href"));
      }
    });

    // Update parent node and position
    this.tree.on('move_node.jstree', (e, data) => {
      if (!data.node.original.id || (data.parent === data.old_parent && data.position === data.old_position)) {
        return;
      }
      let parent_id = this.getIdByNode(document.getElementById(data.parent)),
        id = this.getIdByNode(data.node),
        position = data.position + 1,
        inst = this.tree.jstree(true);

      if (position > 0) {
        let url = `${this.getPath('base-elements')}/${id}/move`,
          params = { element: { parent_id, position }, field_id: this.selectedFieldId };

        $.ajax({
          url,
          method: 'PATCH',
          data: params,
          error: (xhr, status) => {
            if (xhr.status === 400) {
              // display errors as flash
              xhr.responseJSON.errors.map((message) => {
                errorMessage(message);
              });
            }

            inst.move_node(data.node, data.old_parent, data.old_position);
          }
        });
      }
    });

    this.tree.on('rename_node.jstree', (e, data) => {
      let id = this.getIdByNode(data.node),
        inst = this.tree.jstree(true),
        url = this.getPath('base-elements') + '/' + id;

      if (Number(id) > 0) {
        let url = `${this.getPath('base-elements') }/${id}`,
          params = { element: { title: data.text }, field_id: this.selectedFieldId };

        $.ajax({
          url,
          method: 'PATCH',
          dataType: 'json',
          data: params,
          error: (xhr, status) => {
            if (xhr.status === 400) {
              // display errors as flash
              xhr.responseJSON.errors.map((message) => {
                errorMessage(message);
              });
            }

            inst.set_text(data.node, data.old);
          }
        });
      }
    });

    this.tree.on('select_node.jstree deselect_node.jstree check_multiple_selector', (e, data) => {
      let inst = this.tree.jstree(true);

      if (inst.get_selected().length > 0) {
        $('.tree-batch-action').show();
        $('#tree-batch-multiple-selector').text('check_box');
      } else {
        $('.tree-batch-action').hide();
        $('#tree-batch-multiple-selector').text('check_box_outline_blank');
      }
    });
  };

  // Searches the tree based query  using the search module of jsTree.
  // If there are no matches, displays "no elements found" message.
  searchTree(query) {
    let notFoundMessage = this.localization['search_no_elements'];

    // Ensure inital state, tree with all of it's options is shown.
    this.showTreeWindow();

    // Does the search. See https://www.jstree.com/api/#/?q=$.jstree.defaults.search&f=$.jstree.defaults.search.search_callback
    this.tree.jstree(true).search(query);

    // A match is a js tree node with class '.jstree-search'
    // So, to check if there are any matches, check for the existance of such a node
    if (query.length > 0 && $("#manage-tree .jstree-search").length === 0) {
      // There are no matches, so display no-search-hint instead of tree
      this.hideTreeWindow();
      $("#manage-tree").before(`<span id='no-search-hint'>${notFoundMessage}</span>`);
    }
  };

  bindSiteListeners() {
    let _this = this;

    $(document).on('click', '#manage-taxonomy-view .field-hint a', (e) => {
      if (confirm(this.localization['are_you_sure'])) {
        $.post(this.getPath('convert-field', this.selectedFieldId), (json) => {
          noticeMessage(json.success, 4000);
          this.reloadCurrent();
        }, "json");
      }
    });

    $(document).on('click', '#tree-batch-multiple-selector', function (e) {
      e.preventDefault();

      if ($(this).hasClass('material-icons') && $(this).text() === 'check_box') {
        _this.tree.jstree(true).deselect_all();
      } else {
        _this.tree.jstree(true).select_all();
      }

      _this.tree.trigger('check_multiple_selector');
    });

    $(document).on('click', '.tree-batch-action > .reassign-contributions', function (e) {
      e.preventDefault();
      let inst = _this.tree.jstree(true);

      let ids = new Array;
      inst.get_checked(true).map((node) => {
        if (node.type === 'metadata') {
          ids.push(_this.getIdByNode(node));
        }
      });

      $.ajax({
        url: $(this).attr('href'),
        method: 'GET',
        data: { ids: ids.join(','), field_id: _this.selectedFieldId },
        beforeSend: () => VERSTEHE.utils.showLoadingIndicator({ opacity: 0.5 }),
        success: (html) => {
          $('#reassign_contributions_modal .modal-content').html(html);
          $('#reassign_contributions_modal').modal('show');
        },
        error: (xhr) => {
          if (xhr.status === 400) {
            errorMessage(xhr.responseJSON.error);
          }
        },
        complete: () => VERSTEHE.utils.hideLoadingIndicator()
      });
    });

    $(document).on('click', '.tree-batch-action > .add-reviewers', (e) => {
      e.preventDefault();

      let inst = this.tree.jstree(true);

      let ids = new Array();
      inst.get_top_checked(true).map((node) => {
        ids.push(this.getIdByNode(node));
      });

      this.openUserSelect('add_reviewers', ids, true);
    });

    $(document).on('click', '.tree-batch-action > .add-editors', (e) => {
      e.preventDefault();

      let inst = this.tree.jstree(true);

      let ids = new Array();
      inst.get_bottom_checked(true).map((node) => {
        if (node.type === 'metadata') { ids.push(this.getIdByNode(node)); }
      });

      this.openUserSelect('add_editors', ids, true);
    });

    $(document).on('ajax:success', '#taxonomy_add_users_modal form', (e, data) => {
      $('#taxonomy_add_users_modal').modal('hide');
    });

    $(document).on('ajax:success', '#reassign_contributions_modal form', (e, data) => {
      $('#reassign_contributions_modal').modal('hide');

      // remove node from tree, if successfully deleted
      if (data.deleted) {
        let inst = this.tree.jstree(true);

        $(`#manage-tree #element_${data.id}`).each(function () {
          let node = inst.get_node(this);
          if (node.type === data.type) { inst.delete_node(node); }
        });
      }
    });

    $(document).on('click', '.create-taxonomy-root-node', () => {
      let inst = this.tree.jstree(true);
      inst.create_node('#', { text: '', type: 'metadata' }, 'last', (new_node) => {
        setTimeout(() => inst.edit(new_node, null, this.createNode.bind(this)), 0);
      });
    });

    $(document).on('ajax:error', '#reassign_contributions_modal form', (e, xhr) => {
      // display errors as flash
      xhr.responseJSON.errors.map((message) => {
        errorMessage(message);
      });
    });

    $('[data-trigger="tree-search"]').donetyping(function(e) {
      _this.searchTree(this.value);
    }).on('keydown', function(e) {
      if (e.keyCode === 13) {
        _this.searchTree(this.value);
      }
    });

    $('#reassign_contributions_modal').on('hide.bs.modal', function () {
      $('body > .selectize-dropdown').remove();
    });

    $(document).on('change', '#select-element-individually', function () {
      let globalSelect = $('.new-element')[0].selectize;
      if (this.checked) {
        globalSelect.disable();
        globalSelect.setValue(null);
        $('.contribution-target').fadeIn(400);
      } else {
        globalSelect.enable();
        $('.contribution-target').fadeOut(400);
      }
    });
  };
}
