import { HighlightAction } from "./actions/content_actions/general_actions/highlight_action";
import { QueueAction } from "./actions/system_actions/queue_action";
import { SelectizeAction } from "./actions/content_actions/general_actions/selectize_action";
import { TextAction } from "./actions/content_actions/general_actions/text_action";
import { TabAction } from "./actions/system_actions/tab_action";
import * as index from "./actions/index";

/** Class representing Queue that stores Actions*/
export class Queue {
  /**
   * @constructor
   * @param {String} queueName  - Name for the Queue
   */
  constructor(queueName) {
    this.actions = [];
    this.state = "init";
    this.queueName = queueName;
  };

  /**
   * Function to add new Actions to the Queue
   * @param {Action} newAction
   **/
  push(newAction) {
    // Checks if that Action should be pushed to the Queue
    if (Queue.isQueueable(newAction)) {
      // Checks if that Action has implemented the compare and reduce method
      if (Queue.isCompAndRedAction(newAction)) {
        // console.log(newAction.tabId)
        // Array of all Actions that are the same type as the newAction and are considered "comparable" (method implemented by each Action)
        let comparableActions = this.actions.filter(function(queuedAction) {
          return newAction.isComparableTo(queuedAction);
        });
        // Check if there are Actions to be reduced with
        if (comparableActions.length > 0) {
          // make the queue accessible in the forEach loop
          let queue = this;
          // iterate through all comparable Actions (There should only be one?)
          comparableActions.forEach(function(queuedAction, index, comparableActions) {
            // "reduce" the queued Action with the new one. It will return an Action that replaces the old one or it will return no action at all (depending of "reduce"-method implementation of the Action)
            let reducedAction;
            if (index === comparableActions.length - 1) {
              reducedAction = newAction.reduce(queuedAction, true);
            } else {
              reducedAction = newAction.reduce(queuedAction);
            }
            // delete the old Action from the Queue
            queue.delete(queuedAction);
            // add the reduced Action to the queue if it's not null
            if (reducedAction) {
              queue.actions.push(reducedAction);
            }
          });
        } else {
          // if there are not Actions the newAction can be reduced with, it can be added right away
          this.actions.push(newAction);
        }
      } else {
        this.actions.push(newAction);
      }
      // Output to see the current Queue
      // console.log(this.queueName + ":");
      // console.log(this.actions);
    }
  };

  /**
   * Function to check if that Action has implemented the compare and reduce method
   * @param {Action} action
   **/
  static isCompAndRedAction(action) {
    let is = false;
    index.COMPARE_AND_REDUCE_ACTIONS.forEach(function(compAndRedAction) {
      if (action instanceof compAndRedAction || is) {
        is = true;
      }
    });
    return is;
  };

  /**
   * Function to check if that Action should be pushed to the Queue
   * @param {Action} action
   **/
  static isQueueable(action) {
    let is = true;
    index.NOT_QUEUEABLE_ACTIONS.forEach(function(notQueueableAction) {
      if (action instanceof notQueueableAction || !is) {
        is = false;
      }
    });
    if (action.tabId === "#publish" && !(action instanceof HighlightAction) && !(action instanceof TextAction) && !(action instanceof TabAction) && !(action instanceof SelectizeAction)) {
      is = false;
    }
    return is;
  };

  /**
   * Function to delete an Action from the Queue
   * @param {Action} findAction
   **/
  delete(findAction) {
    let actionIndex = this.actions.findIndex(function(action) {
      return action === findAction;
    });
    this.actions.splice(actionIndex, 1);
  };

  /**
   * Function send the whole Queue as a QueueAction to another user
   * so the other user can execute the QueueAction and reproduce all actions contained in it
   * @param {number} recipient - userId of the user the Queue is intended for
   **/
  send(recipient) {
    let newActions = [];
    // prevents "Converting circular structure to JSON"-Errors from SelectizeActions
    this.actions.forEach(function(oldAction) {
      let newAction;
      if (oldAction instanceof SelectizeAction) {
        newAction = Object.assign({}, oldAction);
        Object.setPrototypeOf(newAction, SelectizeAction.prototype);
        newAction.element = null;
      } else {
        newAction = oldAction;
      }
      newActions.push(newAction);
    });
    let action = new QueueAction(null, _app.info.user.userId, newActions, recipient, this.queueName);
    VERSTEHE.content_channel.send_message(action);
  };
}
