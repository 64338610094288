import Vue from 'vue';
import App from './app.vue';
import { SubtitleLanguage } from '../interfaces';

export class TranslationProgress {
  public app: Vue = null;
  public vueWrapper = HTMLDivElement = null;
  public progressWrapper: HTMLDivElement = null;
  public currentLanguage: SubtitleLanguage = null;
  public cableConnected: boolean = false;

  public init(progressWrapper: HTMLDivElement, languageObject: SubtitleLanguage) {
    this.currentLanguage = languageObject;
    this.progressWrapper = progressWrapper;
    this.progressWrapper.innerHTML = '';
    this.vueWrapper = document.createElement('div');
    this.progressWrapper.appendChild(this.vueWrapper);

    this.app = new Vue({
      el: this.vueWrapper,
      i18n: VERSTEHE.vueI18n,
      provide: {
        languageObject: this.currentLanguage
      },
      render: h => h(App)
    });
  }
}

export default new TranslationProgress();
