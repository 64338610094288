import { Autoplay } from '../mixins/autoplay';

export class Video {

  constructor(player_id) {
    this.upNextInterval = null;
    this.overlays = null;
  }

  initThumbnails() {
    let thumbs = this.element.getElementsByTagName('thumbs')[0];

    if(typeof thumbs !== 'undefined') {
      let thumbnailMap = thumbs.getAttribute('src');
      let thumbnailBaseUrl = thumbs.getAttribute('data-base');

      if(typeof thumbnailMap !== 'undefined') {
        $.getJSON(thumbnailMap, data => {

          // generate random int for image request, so we don't retrieve it from browser cache
          var rnd = Math.floor(Math.random() * 1000);

          for (var k in data.sprites) {
            data.sprites[k] = {
              style: {
                width: data.width + 'px',
                height: data.height + 'px',
                backgroundImage: 'url("' + thumbnailBaseUrl + '/' + data.sprites[k].file + '?' + rnd + '")',
                backgroundPosition: -data.sprites[k].x + 'px ' + -data.sprites[k].y + 'px'
              }
            };
          }

          this.player.thumbnails(data.sprites);
        });
      }
    }
  }

  initOverlay() {
    if (this.player.overlay) {
      this.overlays = this.player.overlay({
        overlays: [{
          content: document.getElementById('videojs-endwall'),
          align: 'bottom',
          debug: false,
          start: 'ended',
          end: 'playing'
        },{
          content: document.getElementById('next_play_overlay'),
          align: 'center',
          debug: false,
          start: 'ended',
          end: 'playing'
        }]
      });
    }
  }

  bindListener() {
    this.player.on('ended', e => {
      if (document.getElementById('next_play_overlay') !== null) {
        new Autoplay();
      }
    });
  }


}
