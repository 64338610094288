<template>
<div>
  <h3>{{ $t('course.questions') }}</h3>
  <ul class="lesson-question-list">
    <transition-group name="slide-fade">
      <li v-for="question in lessonEditorQuestions" :key="question.id">
        <div class="question-type-icon coovi-icon">{{ questionTypeIcon(question.type) }}</div>
        <div class="question-time">{{ formattedTime(question.time) }}</div>
        <div class="question-title">{{ question.title }}</div>
        <div class="question-actions">
          <a v-on:click.prevent="editQuestion(question)" href="#" class="material-icons">edit</a>
          <a v-on:click.prevent="removeQuestion(question)" href="#" class="material-icons">delete</a>
        </div>
      </li>
    </transition-group>
  </ul>
  <button v-on:click="newQuestion('FilmQuestion::SingleSelect')" type="button" class="btn btn-default btn-flat add-question">{{ $t('course.add_question') }}</button>
</div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('course');

export default {
  name: 'question-list',
  computed: {
    ...mapGetters([
      'lessonEditorSubject',
      'lessonEditorQuestions'
    ])
  },
  methods: {
    ...mapActions([
      'changeLessonType',
      'setUiState',
      'editQuestion',
      'removeQuestion',
      'newQuestion'
    ]),
    questionTypeIcon(questionType) {
      if (questionType === 'FilmQuestion::SingleSelect') {
        return 'question_boolean'
      } else if (questionType === 'FilmQuestion::MultipleSelect') {
        return 'question_multiple';
      }
    },
    formattedTime(time) {
      if (time === null) {
        return ''
      }

      time = parseFloat(time);

      const minutes = parseInt(time / 60, 10);
      const seconds = parseInt(time % 60, 10);

      // Zero padded seconds
      const paddedSeconds = `0${seconds}`.slice(-2);

      return `${minutes}:${paddedSeconds}`;
    }
  },
  watch: {
    questions(questions) {
      if (questions.length > 0) {
        this.changeLessonType('Course::Lesson::TopicQuizLesson');
      } else {
        this.changeLessonType('Course::Lesson::TopicLesson');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.lesson-question-list {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: flex;
    position: relative;
    border: 1px solid $color-light-grey-2;
    border-radius: 3px;
    height: 60px;
    padding: 10px;
    margin: 10px 0;
    align-items: center;

    .question-type-icon {
      color: var(--primary-color);
      font-size: 30px;
      padding-left: 10px;
    }

    .question-time {
      flex-shrink: 0;
      color: $black-54-opacity;
      width: 100px;
      padding: 0 24px;
      text-align: right;
    }

    .question-title {
      flex: 1 auto;
      line-height: 20px;
      max-height: 40px;
      overflow: hidden;
    }
  }
}

.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-leave-active {
  transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(24px);
  opacity: 0;
}

.add-question {
  display: block;
  margin: 0 auto;
}

.question-actions {
  display: flex;
  align-items: center;

  > a {
    font-size: 20px;
    padding: 10px;
    color: $black-54-opacity;

    &:hover,
    &:focus,
    &:active {
      color: $black-87-opacity;
    }
  }
}
</style>
