import { ChangeAction } from "../../../system_actions/change_action";
import { confirmDialog } from '../../../../../common/dialog';

let localization = {
  'en': {
    'production_started': 'A co author started the production of this film. It can no longer be edited.',
    'production_started_title': 'Production started'
  },
  'de': {
    'production_started': 'Ein Koautor hat die Produktion dieses Films gestartet. Er kann nicht mehr bearbeitet werden.',
    'production_started_title': 'Produktion wurde gestartet'
  },
  'es': {
    'production_started': 'Un coautor ha comenzado la producción de esta película. Ya no se puede editar.',
    'production_started_title': 'Inicio de la producción'
  }
};

/**
 * A ProduceScreenplayAction represents the Production of film
 * Redirects other authors to Production step
 */
export class ProduceScreenplayAction extends ChangeAction {
  /**
   * @constructor
   * @param {string} tabId  - tabId of the tab the action originates from
   * @param {number} user - userId of the chat room owner
   * @param {String} element - element thats subject of the ChangeAction
   */
  constructor(tabId, user, element) {
    super(tabId, user, element);
    this.actionType = "ProduceScreenplayAction";
  }

  execute() {
    VERSTEHE.utils.showLoadingIndicator();
    confirmDialog({
      content: localization[_app.info.currentLocale].production_started,
      title: localization[_app.info.currentLocale].production_started_title
    }).then(() => {
      VERSTEHE.Screenplay.setUnsavedChanges(false);
      window.location.replace($("[href='#medium-production']").prop("href"));
      window.location.reload();
    });
  }

  static fromJson(json) {
    let action = JSON.parse(json);
    return new ProduceScreenplayAction(action.tabId, action.user, action.elementLocation);
  }
}
