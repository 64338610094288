import AutoReassignSettingsModal from './auto_reassign_settings_modal_app.vue';
import Vue from 'vue';

export class AutoReassignSettings {

  readonly containerId: string;
  readonly userUrl: string;
  readonly paramOptions: string;
  readonly submitUrl: string;
  readonly vueApp: Vue;

  constructor(containerId: string, onSettingsUpdated: Function) {
    this.containerId = containerId;
    const container = <HTMLDivElement> document.querySelector(containerId);
    if (container) {
      this.userUrl = container.dataset.userUrl;
      this.submitUrl = container.dataset.submitUrl;
      this.paramOptions = container.dataset.options;
      this.vueApp = this.initVueApp();
      this.vueApp.$root.$on('auto-reassign-updated', onSettingsUpdated);
    }
  }

  private initVueApp() : Vue {
    return new Vue({
      el: this.containerId,
      provide: {userUrl: this.userUrl, submitUrl: this.submitUrl, paramOptions: this.paramOptions},
      i18n: VERSTEHE.vueI18n,
      render: h => h(AutoReassignSettingsModal),
    });
  }
}
