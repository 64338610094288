import { LessonSerializer } from './lesson_serializer';

export class ExternalLessonSerializer extends LessonSerializer {
  serialize(lesson) {
    let attributes = super.serialize(lesson);

    attributes.subject_attributes = {
      url: lesson.subject.url
    };

    if (lesson.subject.id) {
      attributes.subject_attributes.id = lesson.subject.id;
    }

    return attributes;
  }
}

export default function serializeExternalLesson(lesson) {
  const serializer = new ExternalLessonSerializer();
  return serializer.serialize(lesson);
}
