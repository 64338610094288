export function flashMessage(content, style = 'success', timeout = 3000) {
  $.snackbar({
    content,
    style,
    timeout
  });
}

export function errorMessage(content, timeout = 8000) {
  flashMessage(content, 'error', timeout);
} 


export function successMessage(content, timeout = 8000) {
  flashMessage(content, 'success', timeout);
}

export function noticeMessage(content, timeout = 8000) {
  flashMessage(content, 'notice', timeout);
}