export const Getters = {
  ELEMENTS: 'playlist/elements',
  SHOW_COMPONENT: 'playlist/showComponent',
  SEARCH_URL: 'playlist/searchUrl',
  DELETED_ELEMENTS: 'playlist/deletedElements',
  TOTAL_DURATION: 'playlist/totalDuration'
};

export const getters = {
  elements: (state) => state.elements.filter(element => !element.deleted),
  showComponent: (state) => state.showComponent,
  searchUrl: (state) => state.searchUrl,
  deletedElements: (state) => state.deletedElements.filter(element => typeof element.id === "number"),
  totalDuration: (state) => {
    let time = 0;
    state.elements.forEach(element => time += element.duration);
    return time;
  }
};

