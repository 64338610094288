import { ChangeAction } from "../../system_actions/change_action";
import { switchcase } from '../../../../common/helpers';

let localization = {
  'en': {
    'postEditingLocked': 'The tab is locked because another author is currently working in post processing.'
  },
  'de': {
    'postEditingLocked': 'Der Tab ist gesperrt, da ein anderer Autor in der Nachbearbeitung tätig ist.'
  },
  'es': {
    'postEditingLocked': 'La ficha se bloquea porque otro autor está trabajando en el tratamiento posterior.'
  }
};

/**
 * HighlightActions produce a "Highlight" around an element when other users edit it
 * HighlightActions regarding the same element even out when the method (in/out) is different
 */
export class HighlightAction extends ChangeAction {
  /**
   * @constructor
   * @param {String} tabId  - TabId of the tab the action originates from
   * @param {number} user - UserId of the chat room owner
   * @param {String} element - Element thats subject of the UIAction
   * @param {String} method - Method of this HighlightAction (in, out)
   */
  constructor(tabId, user, element, method) {
    super(tabId, user, element);
    this.method = method;
    this.actionType = "HighlightAction";
    let userAvatar = $(`.communication-user-avatar[data-user_id="${this.user}"]`);
    if (this.element == null) {
      this.authorId = userAvatar.length > 0 ? userAvatar.attr("id") : `editor_user_${this.user}`;
      this.element = $(`.${this.authorId}_highlight_element`);
    }
  }

  static get HIGHLIGHT_DISTANCE() {
    return 5;
  }

  execute() {
    let userAvatar = $(`.communication-user-avatar[data-user_id="${this.user}"]`);
    this.authorId = userAvatar.length > 0 ? userAvatar.attr("id") : `editor_user_${this.user}`;
    let [highlightType, highlightElement] = this.checkElement();
    this.highlightMethodToggle(highlightType, highlightElement, this.method === "in");
  }

  /**
   * Check DOM Elements and select highlightType and highlightElement
   * @return [highlightType, highlightElement]
   */
  // eslint-disable-next-line complexity
  checkElement() {
    if (this.element.is('[data-role="datepicker"]')) {
      return ["datepicker", this.element.parent()];
    }else if (this.element.is('[data-role="multiselect"]')) {
      return ["multiselect", this.element.parent()];
    } else if (this.element.parent().hasClass("selectize-input")) {
      this.element = this.element.parent().children("input");
      return ["selectize", this.element.parent().parent(".selectize-control")];
    } else if (this.element.siblings().hasClass("tree-select-items") || this.element.parent().siblings().hasClass("tree-select-items")) {
      return ["jstree", this.element.closest(".form-group")];
    } else if (this.element.hasClass("scene-actions")) {
      return ["tts", this.element.children(".tts-button")];
    } else if (this.element.is('select')) {
      return ["select", this.element];
    } else if (this.element.is(":input")) {
      return ["input", this.element];
    } else if (this.element.is('[data-target="#edit_document_modal"]')) {
      return ["attachment", this.element.parent().parent().parent()];
    } else if (this.element.is('[data-uid="medium-postprocess"]')) {
      return ["stepTab", this.element.parent().siblings(".icon").find(".inner")];
    } else if (this.element.is(".k-editor")) {
      return ["editor", this.element];
    } else if (this.element.hasClass("production-template")) {
      return ['mat-search', this.element];
    }
    return [null, this.element];
  }

  /**
   * Toggles HighlightElements, method In/Out
   * @param {*} highlightType
   * @param {*} highlightElement
   * @param {*} shouldShow
   */
  highlightMethodToggle(highlightType, highlightElement, shouldShow = false) {
    this.toggleElementAccess(highlightType, highlightElement, shouldShow);
    this.toggleHighlightElement(highlightElement, shouldShow);
  }

  /**
   * Check which element is selected and toggle the enable/disable behaviour
   * @param {*} highlightType
   * @param {*} highlightElement
   * @param {*} isEnabled
   */
  toggleElementAccess(highlightType, highlightElement, isEnabled = false) {
    switchcase({
      'multiselect': () => { this.element.data("kendoMultiSelect").enable(!isEnabled); },
      'datepicker': () => { this.element.data("kendoDatePicker").enable(!isEnabled); },
      'editor': () => {
        let id = highlightElement[0].querySelector(".k-editor-toolbar").getAttribute("aria-controls");
        $($(`#${id}`).data("kendoEditor").body).attr('contenteditable', !isEnabled);

        if (isEnabled) {
          $(`#${id}`).data("kendoEditor").wrapper[0].classList.add('disable-user-input');
        } else {
          $(`#${id}`).data("kendoEditor").wrapper[0].classList.remove('disable-user-input');
        }
      },
      'jstree': () => { this.element.closest("button").toggleClass("disabled"); },
      'tts': () => { this.element.children("a").toggleClass("disabled"); },
      'selectize': () => {
        let selectize = highlightElement.siblings("select").selectize()[0].selectize;
        isEnabled === true ? selectize.disable() : selectize.enable();
      },
      'select': () => { this.element.prop('disabled', isEnabled); },
      'input': () => { this.element.prop('disabled', isEnabled); },
      'attachment': () => { this.element.closest(".document-actions").children().toggleClass("disabled"); },
      'stepTab': () => { this.toggleStepTab(isEnabled); },
      'mat-search': () => this.element.toggleClass('collab-locked')
    })()(highlightType)();
  }

  toggleStepTab(enabled) {
    if (enabled) {
      VERSTEHE.ContributionWorkflow.workflow.disableWorkflowStep("medium-postprocess");
      this.element.attr({
            'data-hover': 'tooltip',
            'data-placement': 'top',
            'data-original-title': localization[_app.info.currentLocale].postEditingLocked
          });
      this.element.tooltip();
    } else {
      VERSTEHE.ContributionWorkflow.workflow.enableWorkflowStep("medium-postprocess");
      this.element.removeAttr('data-hover data-placement data-original-title');
    }
  }

  /**
   * Toggle the DOM HighlightElement.
   * Show or hide boxShadow on elements where a remote user has access.
   * @param {*} element
   * @param {*} shouldShow
   */
  toggleHighlightElement(element, shouldShow = true) {
    let chat = collabCenter.chat;
    let color = this.user === chat.owner ? chat.options.ownerColor : chat.options.colors[this.user % 20];
    element.toggleClass(`${this.authorId}_highlight_element highlight_element`);
    element.css({ boxShadow: shouldShow ? `0px 0px 3pt 2pt ${color}` : ''});
  }

  isComparableTo(other) {
    return this.actionType === other.actionType ? this.element.is(other.element) : false;
  }

  reduce(other) {
    return this.method === other.method ? this :
      this.method === "out" ? null : this;
  }

  static fromJson(json) {
    let action = JSON.parse(json);
    return new HighlightAction(action.tabId, action.user, action.elementLocation, action.method);
  }

  static registerToElement() {
    HighlightAction.registerToInputAndSelectize();
    HighlightAction.registerToJsTree();
    HighlightAction.registerToTTS();
    HighlightAction.registerToAttachment();
    HighlightAction.registerToStepTab();
    HighlightAction.registerToMultiselect();
    HighlightAction.registerToMatSearch();
  }

  static registerToInputAndSelectize() {
    $(document).on('focusin', '[data-collaborative-action*="HighlightAction"]input, [data-collaborative-action*="HighlightAction"]textarea, [data-collaborative-action*="HighlightAction"]select, [data-collaborative-action*="HighlightAction"] > * > * > input', function(event) {
      HighlightAction.createAction(HighlightAction.getTabId(), _app.info.user.userId, event.target, "in");
    });
    $(document).on('focusout', '[data-collaborative-action*="HighlightAction"]input, [data-collaborative-action*="HighlightAction"]textarea, [data-collaborative-action*="HighlightAction"]select, [data-collaborative-action*="HighlightAction"] > * > * > input', function(event) {
      HighlightAction.createAction(HighlightAction.getTabId(), _app.info.user.userId, event.target, "out");
    });
  }
  static registerToWYSIWYG(editor) {
    $(editor.body).focus(function(){
      HighlightAction.createAction(HighlightAction.getTabId(), _app.info.user.userId, editor.wrapper, "in");
    });
    $(editor.body).blur(function(){
      HighlightAction.createAction(HighlightAction.getTabId(), _app.info.user.userId, editor.wrapper, "out");
    });
  }

  static registerToJsTree() {
    $(".tree-select-items").parent().find("button").on('click', function(event) {
      HighlightAction.createAction(HighlightAction.getTabId(), _app.info.user.userId, event.target, "in");
    });
    $(".tree-select-items").parent().find("button").each(function() {
      let thisElement = $(this);
      $($(this).data("target")).on('hidden.bs.modal', function() {
        HighlightAction.createAction(HighlightAction.getTabId(), _app.info.user.userId, thisElement, "out");
      });
    });
  }

  static registerToTTS() {
    $(document).on('open-tts-modal', '[data-collaborative-action*="HighlightAction"].scene-actions', function(event) {
      HighlightAction.createAction(HighlightAction.getTabId(), _app.info.user.userId, event.target, "in");
    });
    $(document).on('close-tts-modal', '[data-collaborative-action*="HighlightAction"].scene-actions', function(event) {
      HighlightAction.createAction(HighlightAction.getTabId(), _app.info.user.userId, event.target, "out");
    });
  }

  static registerToMatSearch() {
    $(document).on('open-mat-search', '[data-collaborative-action*="HighlightAction"].production-template', function(event) {
      HighlightAction.createAction(HighlightAction.getTabId(), _app.info.user.userId, event.target, "in");
    });
    $(document).on('close-mat-search', '[data-collaborative-action*="HighlightAction"].production-template', function(event) {
      HighlightAction.createAction(HighlightAction.getTabId(), _app.info.user.userId, event.target, "out");
    });
  }

  static registerToMultiselect() {
    $('[data-collaborative-action*="HighlightAction"][data-role="multiselect"]').each(function() {
      var multiselect = $(this).data("kendoMultiSelect");
      multiselect.bind("open", (e) => {
        HighlightAction.createAction(HighlightAction.getTabId(), _app.info.user.userId, $(this), "in");
      });
      multiselect.bind("close", (e) => {
        HighlightAction.createAction(HighlightAction.getTabId(), _app.info.user.userId, $(this), "out");
      });
    });
  }
  static registerToAttachment() {
    $('.document-list').on('click', '[data-target="#edit_document_modal"]', function(event) {
      HighlightAction.createAction(HighlightAction.getTabId(), _app.info.user.userId, event.target, "in");
    });
    $(document).on('hidden.bs.modal', $($($('.document-list').find('[data-target="#edit_document_modal"]')[0]).data("target")), function() {
      if ($($(this)[0].activeElement).is('[data-target="#edit_document_modal"]')) {
        HighlightAction.createAction(HighlightAction.getTabId(), _app.info.user.userId, $(this)[0].activeElement, "out");
      }
    });
  }

  static registerToStepTab() {
    VERSTEHE.ContributionWorkflow.workflow.on('activated', (step, previousStep) => {
      if (step.uid === 'medium-postprocess') {
        HighlightAction.createAction(null, _app.info.user.userId, $('[data-uid="medium-postprocess"].title'), "in");
      }

      if (previousStep && previousStep.uid === 'medium-postprocess') {
        HighlightAction.createAction(null, _app.info.user.userId, $('[data-uid="medium-postprocess"].title'), "out");
      }
    });
  }

  releaseElements() {
    let [highlightType, highlightElement] = this.checkElement();
    this.highlightMethodToggle(highlightType, highlightElement, false);
  }

  static releaseAllElementsInQueue(queueName) {
    return new Promise((resolve, reject) => {
      VERSTEHE.CollaborativeAuthoring.Queues.find(q => q.queueName === queueName).actions
        .filter(a => a.actionType === "HighlightAction")
        .forEach(a => a.releaseElements());
      resolve();
    });

  }
  static createAction(tabId, user, element, method) {
    let action = new HighlightAction(tabId, user, element, method);
    VERSTEHE.content_channel.send_message(action);
  }
}
