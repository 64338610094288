import { Datepicker } from '../globals/datepicker';

export function init() {
  if(document.getElementById('valid-until-container') !== null) {
    new ContributionValidity();
  }
}

class ContributionValidity {
  constructor() {
    this.container = document.getElementById('valid-until-container');
    this.uuid = this.container.getAttribute('data-uuid');
    this.pickerField = $(this.container).find(`.${this.uuid}-datepicker`);
    this.initValue = this.pickerField.attr('value');
    this.maxValidity = this.pickerField.data('maxvalidity');
    this.datepicker = new Datepicker(this.uuid, this.container, { "max": this.getMaxDate(), "min": this.getMinDate(), "noValueField": true, "value": this.getValue(), "minToDayDiff": true });
  }

  getMinDate() {
    let minDate = new Date().setHours(0,0,0,0);

    if(this.initValue !== '' && this.initValue != '0') {
      let validUntil = new Date(this.initValue);

      if (validUntil < minDate) {
        minDate = validUntil;
      } 
    }

    return new Date(minDate);
  }

  getValue() {
    let value = null;

    if(this.initValue !== null && this.initValue !== undefined) {
      value = new Date(this.initValue);
    }

    return value;
  }

  getMaxDate() {
    let max = null;

    if(this.maxValidity !== '' && this.maxValidity != '0') {
      max = moment(new Date()).add(this.maxValidity, 's').toDate();
    }

    return max;
  }
}
