import { ChangeAction } from "../../system_actions/change_action";


/**
 * TreeActions handle all changes to the state of elements of the Kendo UI Tree-Plugin
 * Because the whole tree state is delivered in every Action, TreeActions regarding the same tree always replace each other
 */
export class TreeAction extends ChangeAction {
  /**
   * @constructor
   * @param {String} tabId  - TabId of the tab the action originates from
   * @param {number} user - UserId of the chat room owner
   * @param {String} element - Element thats subject of the UIAction
   * @param {String} inputId - Id of the input, which holds the ids
   * @param {object} treeValues - Selected values (ids)
   * @param {number} primaryValue - PrimaryValue of the tree
   */
  constructor(tabId, user, element, inputId, treeValues, primaryValue) {
    super(tabId, user, element);
    this.inputId = inputId;
    this.treeValues = treeValues;
    this.primaryValue = primaryValue;
    this.actionType = "TreeAction";
  }

  execute() {
    let tree = VERSTEHE.Taxonomy.trees.filter(e => e._select.id === this.inputId)[0];

    let setTreeState = () => {
      tree.setChecked(this.treeValues);

      if (tree.constructor.name === 'TopicAreaTree') {
        tree.setPrimaryTopicAreaId(this.primaryValue);
        tree.onChecked();
      }

      tree.setSelectedElementsHelper(tree.getChecked());
      tree.toggleTemporaryState();
    };

    if (tree) {
      if (tree.ready) {
        setTreeState();
      } else {
        tree.onready = setTreeState;
      }
    }
  }

  isComparableTo(other) {
    return (this.inputId === other.inputId);
  }

  reduce(other) {
    return this;
  }

  static fromJson(json) {
    let action = JSON.parse(json);
    return new TreeAction(action.tabId, action.user, null, action.inputId, action.treeValues, action.primaryValue);
  }

  static registerToElement() {
    $(document).on('click', '[data-collaborative-action*="TreeAction"]', function(event) {
      let treeId = $(this).parent().parent().find(".modal-body .k-treeview").data('uuid');
      let treeElement = VERSTEHE.Taxonomy.trees.filter(e => e.uuid === treeId)[0];

      if (treeElement) {
        let inputId = treeElement._select.id;
        let treeValues = treeElement.getChecked().map(e => e.id);
        let primaryValue = null;

        if (treeElement.constructor.name === 'TopicAreaTree') {
          primaryValue = treeElement.getPrimaryTopicAreaId();
        }

        let action = new TreeAction(TreeAction.getTabId(), _app.info.user.userId, null, inputId, treeValues, primaryValue);
        VERSTEHE.content_channel.send_message(action);
      }
    });
  }
}
