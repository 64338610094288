import Vue from 'vue';
import App from './app.vue';

export let vueApps: Vue[] = [];

export function createRolloutApp(elementId = 'v-education-rollout') {
  const rolloutRoot = document.getElementById(elementId);

  if (rolloutRoot) {
    const initialData = JSON.parse(rolloutRoot.getAttribute('data-initial'));

    let vueApp = new Vue({
      name: 'education-rollout',
      el: '#' + rolloutRoot.id,
      i18n: VERSTEHE.vueI18n,
      data: {
        contentType: initialData.content_type,
        contentId: initialData.content_id
      },
      render: h => h(App, {
        props: {
          rolloutUrl: initialData.rollout_url,
          affectedUsersUrl: initialData.rollout_affected_users_url,
          contentType: initialData.content_type,
          contentTypeName: initialData.content_type_localized,
          rolloutFields: initialData.rolloutable_fields,
          recentRollouts: initialData.recent_rollouts
        }
      })
    });

    $.material.init();

    vueApps.push(vueApp);
    bindListeners(vueApp, elementId);
  }
}

function bindListeners(vueApp: Vue, appId: string) {
  const submitButton = document.getElementById(`submit-rollout_${appId}`);

  submitButton.addEventListener('click', e => {
    e.preventDefault();
    vueApp.$emit('submit');
  });

  $('#rollout_modal').on('shown.bs.modal', function() {
    document.body.classList.add('rollout-modal-open');
  });

  $('#rollout_modal').on('hidden.bs.modal', function() {
    document.body.classList.remove('rollout-modal-open');
  });

  // Update rollouts for all vue apps with same content-type and id
  // Rollout App could be present multiple times on same page (e. g. Share Modal & PublishTab)
  vueApp.$on('rollout-success', rollout => {
    const modal = document.getElementById('rollout_modal') || document.getElementById('share_content_modal');

    vueApps.filter((app: any) => (app.contentId === (vueApp as any).contentId) &&
      (app.contentType === (vueApp as any).contentType)).forEach(app => app.$emit('add-rollout', rollout));

    if (modal) {
      $(modal).modal('hide');
    }
  });
}
