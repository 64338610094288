import { localization } from './locals';
import { Busy } from '../globals/busy';
import EventEmitter from '../common/event_emitter';

export class BaseTree extends EventEmitter {
   /**
   * @constructor
   * @param {string} uuid - Unique identifier for tree instance
   * @param {string} type - Type of the tree select
   */
  constructor (uuid, type, fieldId = '', treeType = '') {
    super();
    this.uuid = uuid;
    this.type = type;
    this.treeType = treeType;
    if (!this.uuid) { return; }
    // DOM Elements
    this._dialog = document.querySelector(`#dialog_${uuid}`);
    this._wrapper = document.querySelector(`#wrapper_${uuid}`);
    this._filterControl = this._dialog.querySelector('.filter-tree-control');
    this._treeControl = document.querySelector(`#tree_${uuid}`);
    this.fetchUrl = this._treeControl.getAttribute('data-url');
    this.isMultiSelect = this._treeControl.getAttribute('data-multiple') === 'true';
    this._childrenSwitch = document.querySelector(`#include_children_switch-${uuid}`);
    this.checkboxValue = false;
    this.localization = localization[_app.info.currentLocale];
    this.typingTimeout = null;
    this.temporaryState = false;
    this._loading = false;
    this.ready = false;
    this.fieldId = fieldId;

    // Build tree
    this.treeView = this.initializeTree();
    this.initializeFilter();
    this.initializeTreeActions();
    this.initializeApplyTreeButton();
  }

  set loading (value) {
    this._loading = value;

    if (value) {
      this._loadingIndicator = new Busy();
      this._treeControl.parentNode.appendChild(this._loadingIndicator.loader);
    } else {
      this._loadingIndicator.remove();
      this._loadingIndicator = null;
    }
  }

  get loading () {
    return this._loading;
  }

  /**
   * Initialize the kendoUI TreeView widget
   * @returns {Object} KendoUI Reference
   */
  initializeTree () {
    this.dataSource = new kendo.data.HierarchicalDataSource({
      transport: {
        read: (options) => {
          $.ajax({
            url: this.fetchUrl,
            dataType: 'json',
            success: (response) => {
              this.data = response;
              options.success(response);
              this.trigger("dataLoaded");
            },
            error: options.error
          });
        }
      },
      schema: {
        model: {
          id: 'id',
          children: 'items'
        }
      }
    });

    return $(this._treeControl).kendoTreeView({
      loadOnDemand: true,
      checkboxes: {
        checkChildren: this.isMultiSelect && this.checkboxValue
      },
      dataSource: this.dataSource,
      dataTextField: 'title',
      dataBound: this.initialState.bind(this),
      check: this.onChecked.bind(this),
      select: this.onSelectNode.bind(this)
    }).data('kendoTreeView');
  }

  onSelectNode(e) {
    e.preventDefault();

    if (!this.isMultiSelect) {
      this.uncheckAll();
    }

    const dataItem = this.treeView.dataItem(e.node);
    dataItem.set('checked', !(dataItem.checked === true));
  }

  getPathById (id, data = { items: this.data }) {
    if (data.id === id) {
      return [];
    } else if (data.items) {
      for (let i = 0; i < data.items.length; i++) {
        let path = this.getPathById(id, data.items[i]);

        if (path !== null) {
          path.unshift(data.items[i].id);
          return path;
        }
      }
    }

    return null;
  }

  getItemById (id, data = { items: this.data }) {
    if (data.id === id) {
      return data;
    } else if (data.items) {
      let result;
      for (let i = 0; !result && i < data.items.length; i++) {
        result = this.getItemById(id, data.items[i]);
      }
      return result;
    }
  }

  findNodeIds (query, data = { items: this.data }) {
    let nodes = [];
    query = query.toLowerCase();

    if (data.items) {
      for (let i = 0; i < data.items.length; i++) {
        if (data.items[i].title.toLowerCase().indexOf(query) !== -1) {
          nodes.push(data.items[i].id);
        }

        nodes = nodes.concat(this.findNodeIds(query, data.items[i]));
      }
    }

    return nodes;
  }

  initializeFilter () {
    this._filterControl.addEventListener('keyup', (e) => {
      clearTimeout(this.typingTimeout);

      this.typingTimeout = setTimeout( () => {
        let filterText = this._filterControl.value;
        this.loading = true;

        setTimeout(() => {
          this.filter(filterText);
          this.loading = false;
        }, 50);
      }, 800);
    });
  }

  // sets "hidden" field on items matching query
  filter(query) {
    let nodeIds = this.findNodeIds(query);
    let pathIds = [];
    nodeIds.forEach(nodeId => {
      let path = this.getPathById(nodeId);
      pathIds = pathIds.concat(path);
      this.treeView.expandPath(path);
    });

    this.traverse(this.dataSource.data(), (node) => {
      node.hidden = !pathIds.includes(node.id);
    });

    if (query && query.length > 0) {
      // Disable tri-state checkboxes for filtered view
      this.treeView.setOptions({ checkboxes: { checkChildren: false }});
    } else {
      let checkedNodes = this.getChecked();
      this.treeView.setOptions({ checkboxes: { checkChildren: this.isMultiSelect }});

      // Re-check nodes, to achieve tri-state, if enabled
      setTimeout(() => {
        checkedNodes.forEach(node => {
          node.set('checked', false);
          node.set('checked', true);
        });
      }, 0);
    }

    this.dataSource.filter({ field: 'hidden', operator: 'neq', value: true });
  }

  initializeTreeActions () {
    this._dialog.querySelector('.btn.open-all').addEventListener('click', (e) => {
      e.target.blur();
      this.expandAll();
    });

    this._dialog.querySelector('.btn.close-all').addEventListener('click', (e) => {
      e.target.blur();
      this.collapseAll();
    });

    if(this._childrenSwitch && !$(this._childrenSwitch).data("kendoSwitch")) {
      $(this._childrenSwitch).kendoSwitch({
        change: this.includeChildrenToggle.bind(this)
      });
    }
  }

  includeChildrenToggle(e) {
    this.checkboxValue = e.checked ? true : false;
    this.treeView.setOptions({ checkboxes: { checkChildren: this.isMultiSelect && this.checkboxValue }});
  }

  initializeApplyTreeButton() {
    this._dialog.querySelector('.apply-tree').addEventListener('click', (e) => {
      let selectedElements = this.getChecked(null);
      this.setSelectedElementsHelper(selectedElements);
      this.toggleTemporaryState();
    });
  }

  /**
   * Traverse nodes from kendo.data.HierarchicalDataSource
   * @param {Element[]} nodes - List of data nodes to traverse
   * @returns {Function} callback - Will be called for every node whilst traversing
   */
  traverse (nodes, callback) {
    for (let i = 0; i < nodes.length; i++) {
      let node = nodes[i];
      callback(node);
      node.hasChildren && this.traverse(node.children.data(), callback);
    }
  }

  initialState (e) {
    // If no data set, then display no data message
    if (this.dataSource.total() === 0) {
      this.noDataMessage();
    } else {
      this.removeNoDataMessages();
    }
  }

  removeNoDataMessages () {
    if (this._treeControl.querySelector('#tree_no_data_' + this.uuid) !== null) {
      this._treeControl.removeChild(this._treeControl.querySelector('#tree_no_data_' + this.uuid));
    }
  }

  noDataMessage () {
    if (this._treeControl.querySelector('#tree_no_data_' + this.uuid) === null) {
      let span = document.createElement('span');
      span.innerHTML = this.localization['search_no_elements'];
      span.setAttribute('id', 'tree_no_data_' + this.uuid);
      this._treeControl.insertAdjacentElement('beforeend', span);
    }
  }

  onChecked (e) {
    if (!this.isMultiSelect) {
      // Make sure it's only one item selected
      let dataItem = this.treeView.dataItem(e.node);
      let rootNodes = this.dataSource.data();

      this.traverse(rootNodes, (node) => {
        if (node !== dataItem) {
          node.set('checked', false);
        }
      });
    }
  }

  setChecked (itemIds) {
    this.uncheckAll();

    itemIds.forEach(itemId => {
      let path = this.getPathById(itemId);
      this.treeView.expandPath(path);
      let node = this.dataSource.get(itemId);

      if (node) {
        node.set('checked', true);
      }
    });
  }

  expandAll () {
    if (this.treeView.options.loadOnDemand) {
      this.loading = true;
      setTimeout(() => {
        this.treeView.setOptions({ loadOnDemand: false });

        this.dataSource.fetch().then(() => {
          this.expandAll();
          this.loading = false;
        });
      }, 50);
    } else {
      this.treeView.expand('.k-item');
    }
  }

  collapseAll () {
    this.treeView.collapse('.k-item');
  }

  uncheckAll() {
    this.traverse(this.dataSource.data(), (node) => {
      node.set('checked', false);
    });
  }

  getChecked (items) {
    items = items || this.dataSource.data();
    let checked = [];

    for (let i = 0; i < items.length; i++) {
      if(items[i].checked) {
        checked.push(items[i]);
      }

      if (items[i].hasChildren) {
        let checkedChildren = this.getChecked(items[i].children.data());
        if(checkedChildren && checkedChildren.length > 0) {
          checked.push(...checkedChildren);
        }
      }
    }

    return checked;
  }

  setSelectedElementsHelper(elements) {
    this.ids = elements.map(v => v.id);
    this.removeUncheckedLabels();
    this.uncheckSelectOptions();
  }

  toggleTemporaryState() {
    this.temporaryState = !this.temporaryState;
  }
}
