<template>
  <div class="lessons-wrapper">
    <button
      v-show="lessonGroups.length === 0"
      type="button"
      v-on:click="addLessonGroup"
      class="btn new-lesson-group btn-default btn-flat">{{$t('course.add_lesson_group')}}</button>

    <slide-x-right-transition mode="out-in" :duration="200">
      <draggable key="lessonList" v-if="uiState === 'lessonList'" v-model="lessonGroups" class="lesson-groups" v-bind="dragOptions">
        <transition-group name="slide-fade">
          <lesson-group
            v-validate="'valid_lesson_group'"
            v-for="lessonGroup in lessonGroups"
            :key="lessonGroup.id"
            :lessonGroup="lessonGroup"></lesson-group>
        </transition-group>
      </draggable>

      <lesson-selector key="lessonSelect" v-if="uiState === 'lessonSelect'"></lesson-selector>
      <lesson-editor key="lessonEditor" v-if="uiState === 'lessonEditor'"></lesson-editor>
    </slide-x-right-transition>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import draggable from 'vuedraggable';
import { SlideXRightTransition } from 'vue2-transitions';

import LessonGroup from './lesson_group.vue';
import LessonSelector from './lesson_selector.vue';
import LessonEditor from './lesson_editor.vue';
import { v4 as uuid } from 'uuid';

const { mapGetters, mapActions } = createNamespacedHelpers('course');

export default {
  inject: ['$validator'],
  components: {
    LessonGroup,
    LessonSelector,
    draggable,
    SlideXRightTransition,
    LessonEditor
  },
  methods: {
    ...mapActions([
      'updateLessonGroupPosition',
      'updateLessonGroups',
      'createLessonGroup'
    ]),
    addLessonGroup() {
      this.createLessonGroup({ position: 1, id: uuid()});
    }
  },
  computed: {
    ...mapGetters([
      'uiState',
      'isAnythingLocked'
    ]),
    dragOptions() {
      return {
        disabled: this.isAnythingLocked,
        handle: '.draghandle',
        animation: 100,
        group: { name: 'lessons-groups', pull: false, put: false },
      }
    },
    lessonGroups: {
      get() {
        return this.$store.getters['course/lessonGroups'];
      },
      set(lessonGroups) {
        lessonGroups.forEach((lessonGroup, index) => {
          this.updateLessonGroupPosition({ lessonGroup, position: index + 1 });
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.lesson-groups {
  max-width: 800px;
  margin: 0 auto;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(24px);
  opacity: 0;
}

.new-lesson-group {
  display: block;
  margin: 0 auto;
}
</style>
