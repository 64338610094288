import {
  ParticipantEvent,
  RoomEvent,
  Track,
  Room,
  Participant,
  RemoteParticipant,
  LocalParticipant,
  RemoteTrackPublication,
  DataPacket_Kind,
  MediaDeviceFailure,
} from 'livekit-client';
import { state } from '../store/state';
import { v4 as uuid } from 'uuid';
import { DataPayload, DataType } from '../entities/dataPayload';
import { EventEmitter } from 'events';
import ConferenceService from './ConferenceService';
import { ModalInstance, ModalLayout, ModalTypes } from '../entities/modalInstance';
import { ConferenceLayout } from '../entities/ConferenceLayout';
import { Notification } from '../entities/notification';
import type SelfieSegmentationStream from '../../common/background_replacement/selfie_segmentation_stream';

export class RoomService {
  private currentRoom: Room;
  private textDecoder = new TextDecoder;
  private eventEmitter = new EventEmitter();
  private enc = new TextEncoder();
  private timeOut = null;
  private manualDisconnect = false;
  private eventAudio: HTMLAudioElement = null;

  constructor(private selfieSegmentationStream: SelfieSegmentationStream) {
  }

  public playAudio() {
    this.currentRoom.startAudio();
  }

  public setRoomListeners(room: Room) {
    this.currentRoom = room;
    room.on(RoomEvent.ParticipantConnected, this.participantConnected);
    room.on(RoomEvent.ParticipantDisconnected, this.participantDisconnected);
    room.on(RoomEvent.ActiveSpeakersChanged, this.handleSpeakerChanged);
    room.on(RoomEvent.Disconnected, this.handleRoomDisconnect.bind(this));
    room.on(RoomEvent.Reconnecting, this.handleReconnecting);
    room.on(RoomEvent.Reconnected, this.handleReconnected);
    room.on(RoomEvent.TrackSubscribed, this.handelNewTrackOnRoom);
    room.on(RoomEvent.TrackUnsubscribed, this.handleRemoveTrackOnRoom);
    room.on(RoomEvent.TrackUnpublished, this.handleTrackUnpublished);
    room.on(RoomEvent.DataReceived, this.handleDataReceived);
    room.on(RoomEvent.MediaDevicesError, this.handleMediaDeviceError);
    room.on(RoomEvent.AudioPlaybackStatusChanged, this.handleAudioPlaybackStatusChanged);
    room.localParticipant.on(ParticipantEvent.MetadataChanged, this.handleParticipantMetadataChanged);
    room.localParticipant.on(ParticipantEvent.TrackMuted, this.handleTrackMuted);

    state.sharedScreenParticipant = null;
    state.roomName = room.name;
    state.localParticipant = room.localParticipant;
    state.participants = Array.from(room.participants.values());
    this.setSharedScreenIfShared();
  }

  public async disconnect() {
    if (this.manualDisconnect == false) {
      this.manualDisconnect = true;
      await this.shareData(DataType.DISCONNECTED, '');
      this.currentRoom.disconnect();
    }
  }

  public async shareData(type: DataType, message: string) {
    let messageObject: DataPayload = {
      id: uuid(),
      messageType: type,
      message: message,
      participant: state.localParticipant.identity,
      participant_metadata: state.localParticipant.metadata,
      created_at: new Date(),
    };

    try {
      await state.localParticipant.publishData(
        this.enc.encode(JSON.stringify(messageObject)),
        DataPacket_Kind.RELIABLE,
      );
    } catch (error) {
      let modal = {
        type: ModalTypes.CHAT_ERROR,
        text: VERSTEHE.vueI18n.t('web_conference.error.chat_share_error'),
        headline: VERSTEHE.vueI18n.t('web_conference.error.chat_error_headline'),
        layout: ModalLayout.ACCEPT,
      };
      state.modals.push(modal);
    }

    if (type === DataType.CHAT || type === DataType.SHARESCREEN ||
      type === DataType.RAISEDHAND || type === DataType.JOINEDROOM ||
      type === DataType.JOINENQUIRY || type === DataType.STARTRECORDING ||
      type === DataType.ENDRECORDING || type === DataType.DISCONNECTED) {
      if (!state.isBreakout && state.details.save_chat_messages) {
        //Save as message if not breakout-Session && conference allows saving
        try {
          await ConferenceService.saveMessage(messageObject);
        } catch (error) {
          let modal = {
            type: ModalTypes.CHAT_ERROR,
            text: VERSTEHE.vueI18n.t('web_conference.error.chat_save_error'),
            headline: VERSTEHE.vueI18n.t('web_conference.error.chat_error_headline'),
            layout: ModalLayout.ACCEPT,
          };
          state.modals.push(modal);
        }
      }
    }
    return messageObject;
  }

  public toOwnNotification(message, type) {
    let obj: DataPayload = {
      id: uuid(),
      messageType: type,
      message: message,
      participant: '',
      created_at: new Date(),
    };
    this.inNotification(obj, false);
  }

  public leaveBreakout() {
    this.eventEmitter.emit('switchToMainRoom');
  }

  private participantConnected = (participant: RemoteParticipant) => {
    state.participants = Array.from(this.currentRoom.participants.values());
    clearTimeout(this.timeOut);
  };

  private participantDisconnected = (participant: RemoteParticipant) => {
    if (participant === state.sharedScreenParticipant) {
      state.sharedScreenParticipant = null;
    }

    if (this.currentRoom.participants.size === 0) {
      this.timeOut = setTimeout(() => {
        let modal = {
          type: ModalTypes.INACTIVE,
          text: VERSTEHE.vueI18n.t('web_conference.inactive'),
          headline: VERSTEHE.vueI18n.t('web_conference.inactive_headline'),
          layout: ModalLayout.ACCEPT,
        };
        state.modals.push(modal);
      }, 180000);
    }

    state.participants = Array.from(this.currentRoom.participants.values());
    this.removeRaisedHand(participant);
  };

  private handleParticipantMetadataChanged = (meta) => {
    /*When Participant leaves waitingRoom and enters the conference,
    he has to subscribe to all tracks in the room which already exist.*/
    const oldMeta = JSON.parse(meta);
    const newMeta = JSON.parse(state.localParticipant.metadata);

    if (!oldMeta.inWaitingRoom) {
      return;
    }

    if (newMeta.inWaitingRoom) {
      return;
    }

    for (let participant of state.participants) {
      participant.getTracks().forEach((track) => {
        (track as RemoteTrackPublication).setSubscribed(true);
      });
    }
  };

  private handleTrackMuted = (publication) => {
    if (publication.kind === Track.Kind.Audio) {
      state.micActivated = false;
    }
  };

  private handleAudioPlaybackStatusChanged = () => {
    state.modals.push({
      type: ModalTypes.ALLOW_AUDIO,
      text: VERSTEHE.vueI18n.t('web_conference.error.allow_audio'),
      headline: VERSTEHE.vueI18n.t('web_conference.error.allow_audio_headline'),
      layout: ModalLayout.ACCEPT,
    });
  };

  handleRoomDisconnect = () => {
    if (!state.isBreakout && !this.manualDisconnect) {
      let modal: ModalInstance = {
        type: ModalTypes.CONNECTION_ERROR,
        text: VERSTEHE.vueI18n.t('web_conference.error.disconnect'),
        headline: VERSTEHE.vueI18n.t('web_conference.error.disconnect_headline'),
        layout: ModalLayout.ACCEPT,
      };
      state.modals.push(modal);
      this.manualDisconnect = false;
    }

    this.selfieSegmentationStream.stop();
  };

  private handleSpeakerChanged = (speakers: Participant[]) => {
    for (let speaker of speakers) {
      let amountParticipantsShowed;

      switch (state.userSettings.conferenceLayout) {
        case ConferenceLayout.Standard:
          amountParticipantsShowed = 5;
          break;
        case ConferenceLayout.DoubleColumn:
          amountParticipantsShowed = 10;
          break;
      }
      let found = state.participants.slice(0, amountParticipantsShowed).filter(participant => participant.identity === speaker.identity || state.localParticipant.identity === speaker.identity);
      if (!found.length) {
        this.sortParticipants(state.participants);
        break;
      }
    }
  };

  private handelNewTrackOnRoom = async (track, publication, participant) => {
    if (track.kind === Track.Kind.Video) {
      this.sortParticipants(state.participants);
    }

    if (track && track.source === 'screen_share') {
      this.eventEmitter.emit('screenFromOtherParticipant');
      state.sharedScreenParticipant = participant;
    }
  };

  private handleRemoveTrackOnRoom = (publication, participant) => {
    this.sortParticipants(state.participants);
  };

  private handleTrackUnpublished = (track, participant) => {
    if (track.source === 'screen_share') {
      if (state.sharedScreenParticipant.identity != state.localParticipant.identity) {
        state.sharedScreenParticipant = null;
      }
    }
  };

  private handleMediaDeviceError = (error) => {
    MediaDeviceFailure.getFailure(error);
  };

  private handleDataReceived = (payload, participant, kind) => {
    //We have to check if it is possible to share corrupted files
    let payloadJSON: DataPayload = JSON.parse(this.textDecoder.decode(payload as any));

    switch (payloadJSON.messageType) {
      case DataType.CHAT:
        this.inChatMessages(payloadJSON);
        break;
      case DataType.SHARESCREEN:
        this.inChatMessages(payloadJSON);
        this.inNotification(payloadJSON, false);
        this.playEventAudio();
        break;
      case DataType.RAISEDHAND:
        this.inChatMessages(payloadJSON);
        state.raiseHandNotifications.push(payloadJSON);
        this.inNotification(payloadJSON, false);
        this.playEventAudio();
        break;
      case DataType.DELETERAISEDHAND:
        this.removeRaisedHand(participant);
        break;
      case DataType.JOINEDROOM:
        this.inChatMessages(payloadJSON);
        this.inNotification(payloadJSON, false);
        this.playEventAudio();
        break;
      case DataType.JOINENQUIRY:
        this.inChatMessages(payloadJSON);
        this.inNotification(payloadJSON, true);
        break;
      case DataType.ISPRESENTATOR:
        this.inChatMessages(payloadJSON);
        this.inNotification(payloadJSON, false);
        break;
      case DataType.ENDRECORDING:
        state.isRecording = false;
        this.inChatMessages(payloadJSON);
        this.inNotification(payloadJSON, false);
        break;
      case DataType.STARTRECORDING:
        state.isRecording = true;
        this.inChatMessages(payloadJSON);
        this.inNotification(payloadJSON, false);
        break;
      case DataType.BREAKOUTSTARTED:
        this.eventEmitter.emit('switchToBreakout', payloadJSON.message);
        break;
      case DataType.BREAKOUTSTOPED:
        this.eventEmitter.emit('switchToMainRoom');
        break;
      case DataType.DISCONNECTED:
        this.inChatMessages(payloadJSON);
        this.inNotification(payloadJSON, false);
        break;
    }
  };

  private inChatMessages = (message) => {
    message.seen = false;
    state.chatMessages.push(message);
  };

  private inNotification = (notification, ableToAccept) => {
    notification.ableToAccept = ableToAccept;
    state.notifications.push(notification);
  };

  private removeRaisedHand = (participant) => {
    for (let i = 0; i < state.raiseHandNotifications.length; i++) {
      if (state.raiseHandNotifications[i].participant === participant.identity) {
        state.raiseHandNotifications.splice(i, 1);
      }
    }
  };

  private handleReconnecting = () => {
    let modal: ModalInstance = {
      type: ModalTypes.RECONNECTING,
      text: VERSTEHE.vueI18n.t('web_conference.reconnecting'),
      headline: VERSTEHE.vueI18n.t('web_conference.reconnecting_header'),
      layout: ModalLayout.SPINNER,
    };
    state.modals.push(modal);
  };

  private handleReconnected = () => {
    const removeIndex = state.modals.findIndex(modal => modal.type === ModalTypes.RECONNECTING);
    state.modals.splice(removeIndex, 1);
  };

  private setSharedScreenIfShared = () => {
    this.currentRoom.participants.forEach((participant) => {
      participant.videoTracks.forEach((track) => {
        if (track.trackName === 'screen') {
          state.sharedScreenParticipant = participant;
        }
      });
    });
  };

  private sortParticipants = (participants: Participant[], localParticipant?: LocalParticipant) => {
    participants.sort((a, b) => {
      // loudest speaker first
      if (a.isSpeaking && b.isSpeaking) {
        return b.audioLevel - a.audioLevel;
      }

      // speaker goes first
      if (a.isSpeaking !== b.isSpeaking) {
        if (a.isSpeaking) {
          return -1;
        } else {
          return 1;
        }
      }

      // last active speaker first
      if (a.lastSpokeAt !== b.lastSpokeAt) {
        const aLast = a.lastSpokeAt?.getTime() ?? 0;
        const bLast = b.lastSpokeAt?.getTime() ?? 0;
        return bLast - aLast;
      }

      // video on
      const aVideo = a.videoTracks.size > 0;
      const bVideo = b.videoTracks.size > 0;
      if (aVideo !== bVideo) {
        if (aVideo) {
          return -1;
        } else {
          return 1;
        }
      }
      // joinedAt
      return (a.joinedAt?.getTime() ?? 0) - (b.joinedAt?.getTime() ?? 0);
    });

    if (localParticipant) {
      const localIdx = participants.indexOf(localParticipant);
      if (localIdx >= 0) {
        participants.splice(localIdx, 1);
        if (participants.length > 0) {
          participants.splice(1, 0, localParticipant);
        } else {
          participants.push(localParticipant);
        }
      }
    }
  };

  private playEventAudio = () => {
    if (state.userSettings.playEventAudio) {
      if (this.eventAudio === null) {
        this.eventAudio = new Audio('/static/audio/conference_jingle.mp3');
        this.eventAudio.volume = 0.2;
      }
      this.eventAudio.load();
      this.eventAudio.play();
    }
  };
}
