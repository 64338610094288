import { Module } from 'vuex';
import { state, LibraryState } from './state';
import { actions } from './actions';
import { mutations } from './mutations';
import { getters } from './getters';
import { RootState } from '../../store/types';

export const LibraryModule: Module<LibraryState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
