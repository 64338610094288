import { Tree } from './tree';
import { TopicAreaTree } from './topic_area_tree';
import { ConfigTree } from './taxonomy';

const TREE_TYPES = {
  Tree,
  TopicAreaTree,
  ConfigTree
};

export let trees = [];

export function init() {
  let taxonomySelects = document.querySelectorAll('.taxonomy-select-tree');

  for (let i = 0; i < taxonomySelects.length; i++) {
    let uuid = taxonomySelects[i].getAttribute('data-uuid');
    let type = taxonomySelects[i].getAttribute('data-type') || '';
    let fieldId = taxonomySelects[i].getAttribute('data-field-id');

    let tree = new TREE_TYPES[`${type}Tree`](uuid, type, fieldId);
    trees.push(tree);
  }
}

export function getByFieldId(fieldId) {
  return trees.find(tree => tree.fieldId == fieldId);
}
