import Vue from 'vue';
import store from '../store';
import App from './manage/components/app.vue';
import { PlaylistManageModule } from './store';
import { Actions } from './store/actions';

export function init() {
  const baseElement = document.getElementById('v-playlist-elements');
  const initialData = JSON.parse(baseElement.getAttribute('data-initial'));
  store.registerModule('playlist', PlaylistManageModule);
  store.dispatch(Actions.SET_STATE, initialData);

  this.playlistComponent = new Vue({
    name: 'ManagePlaylist',
    el: baseElement,
    i18n: VERSTEHE.vueI18n,
    render: h => h(App),
    store: store
  });
}
