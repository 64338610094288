import CourseManageModule from './manage';
import CoursePerformModule from './perform';
import { QuizApp } from './manage/quiz_app';

export function init() {
  const manageRoot = document.getElementById('course_lessons');
  const quizRoot = document.getElementById('v-quiz-component');
  const performRoot = document.getElementById('course_perform');

  if (manageRoot) {
    this.manage = new CourseManageModule(manageRoot);
    this.quiz = new QuizApp(quizRoot);
  }

  if (performRoot) {
    this.perform = new CoursePerformModule(performRoot);
  }
}
