// Do not update the keys, they are used to save the selected background.
// The urls can be edited.
export const BACKGROUND_IMAGE_URLS = {
  LIVINGROOM: {
    original: '/static/background-replacement/livingroom_1920_1080.jpg',
    thumbnail: '/static/background-replacement/livingroom_85_48.jpg',
  },
  OFFICE: {
    original: '/static/background-replacement/office_1920_1080.jpg',
    thumbnail: '/static/background-replacement/office_85_48.jpg',
  },
  BEACH: {
    original: '/static/background-replacement/beach_1920_1080.jpg',
    thumbnail: '/static/background-replacement/beach_85_48.jpg',
  },
  CITY: {
    original: '/static/background-replacement/city_1920_1080.jpg',
    thumbnail: '/static/background-replacement/city_85_48.jpg',
  },
  SHOWROOM: {
    original: '/static/background-replacement/showroom_1920_1080.jpg',
    thumbnail: '/static/background-replacement/showroom_85_48.jpg',
  },
  COOVI1: {
    original: '/static/background-replacement/coovi_1_1920_1080.jpg',
    thumbnail: '/static/background-replacement/coovi_1_85_48.jpg',
  },
  COOVI2: {
    original: '/static/background-replacement/coovi_2_1920_1080.jpg',
    thumbnail: '/static/background-replacement/coovi_2_85_48.jpg',
  },
  COOVI3: {
    original: '/static/background-replacement/coovi_3_1920_1080.jpg',
    thumbnail: '/static/background-replacement/coovi_3_85_48.jpg',
  },
} as const;

export type BackgroundImage = keyof typeof BACKGROUND_IMAGE_URLS;
export const BACKGROUND_IMAGES = Object.keys(BACKGROUND_IMAGE_URLS) as BackgroundImage[];
