import { t, tt } from './localization';
import { BaseBulkActions } from '../../grid/actions/base_bulk_actions';

export class BulkActions extends BaseBulkActions {
  constructor (grid) {
    super(grid);
  
    this.assignAction = this.wrapper.querySelector('.bulk-action-assign-manual');
    this.editorWrappers = {
      assign_reviewer: this.wrapper.querySelector('.bulk-action-edit[data-attribute="assign_reviewer"]')
    };

    this.initAssignAction();
    this.bindCustomListeners();
  }

  initAssignAction () {
    let wrapper = this.editorWrappers.assign_reviewer;
    let select = wrapper.querySelector('select');

    this._assignSelect = $(select).kendoMultiSelect({
      filter: 'contains',
      minLength: 2,
      maxSelectedItems: 2,
      enforceMinLength: true,
      dataTextField: 'username',
      dataValueField: 'id',
      noDataTemplate: t('find_users'),
      dataSource: {
        serverFiltering: true,
        transport: {
          read: (options) => {
            let filter = '';
            let url = select.getAttribute('data-url');

            if (options.data.filter && options.data.filter.filters.length > 0) {
              filter = options.data.filter.filters[0].value;
            }

            if (filter.length > 1) {
              $.ajax({
                url: url,
                data: {
                  q: filter,
                  role: "reviewer"
                },
                success: options.success,
                error: options.error
              });
            } else {
              options.success([]);
            }
          }
        }
      }
    }).data('kendoMultiSelect');
  }

  bulkAssignReviewer () {
    return new Promise((resolve, reject) => {
      let url = this.editorWrappers.assign_reviewer.getAttribute('data-url');
      let userIds = this._assignSelect.value();

      if (userIds && userIds.length > 0) {
        $.ajax({
          url: url,
          method: 'POST',
          data: {
            ids: this.selected(),
            assign: {
              user_ids: userIds
            }
          },
          success: resolve,
          error: reject
        });
      } else {
        reject();
      }
    });
  }

  bindCustomListeners () {
    this.assignAction.addEventListener('click', e => {
      e.preventDefault();
      this.bulkAssignReviewer();
    });
  }
}
