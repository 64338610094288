export const locals = {
  de: {
    action_edit: 'Bearbeiten',
    action_adopt: 'Auftrag annehmen',
    action_reject: 'Auftrag ablehnen',
    action_show: 'Ansehen',
    and: 'und',
    author: 'Erstellt von',
    bulk_change_message: '#= affected # # if (affected == 1) {#Eintrag wurde geändert.#} else {#Einträge wurden geändert#} #',
    change: 'Ändern',
    contains: 'Beinhaltet',
    contribution: 'Beitrag',
    created_at: 'Eingereicht am',
    eq: 'ist',
    neq: 'ist nicht',
    is_after_or_equal_to: 'gleich oder später ist',
    is_before_or_equal_to: 'gleich oder früher ist',
    is_equal_to: 'gleich ist',
    updated_at: 'Aktualisiert am'
  },
  en: {
    action_edit: 'Edit',
    action_adopt: 'Accept order',
    action_reject: 'Refuse an order',
    action_show: 'Show',
    and: 'and',
    author: 'Created by',
    bulk_change_message: '#= affected # # if (affected == 1) {#Entry was changed.#} else {#Entries were changed.#} #',
    change: 'Change',
    contains: 'Contains',
    contribution: 'Contribution',
    created_at: 'Submitted on',
    eq: 'equals',
    neq: 'is not equal',
    is_after_or_equal_to: 'Is after or equal to',
    is_before_or_equal_to: 'Is before or equal to',
    is_equal_to: 'Is equal to',
    inactive_contribution: 'Inactive contribution',
    updated_at: 'Updated at'
  },
  es: {
    action_edit: 'Corregir',
    action_adopt: 'Aceptar un pedido',
    action_reject: 'Rechazar un pedido',
    action_show: 'Mostrar',
    and: 'y',
    author: 'Creado por',
    bulk_change_message: '#= affected # # if (affected == 1) {#Se ha modificado la entrada.#} else {#Se han modificado las entradas.#} #',
    change: 'Cambiar',
    contains: 'Contiene',
    contribution: 'Contribución',
    created_at: 'Presentado el',
    eq: 'inguales',
    neq: 'no es igual',
    is_after_or_equal_to: 'Es igual o posterior a',
    is_before_or_equal_to: 'Es anterior o igual a',
    is_equal_to: 'Es igual a',
    inactive_contribution: 'Contribución inactiva',
    updated_at: 'Actualizado en'
  }
};

export function t(key) {
  return locals[_app.info.currentLocale][key] || key;
}

export function tt(key, variables = {}) {
  let template = kendo.template(t(key));
  return template(variables);
}
