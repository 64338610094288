<template>
  <div>
    <div class="integration-category">
      <h2>{{ $t('integrations.identity_provider') }}</h2>
      <div class="integration-category-list">
        <integration-card id="identity_okta" />
        <integration-card id="identity_saml" />
      </div>

      <template v-if="hasCategory('ai_provider')">
        <h2>{{ $t('integrations.ai_provider') }}</h2>

        <div class="integration-category-list">
          <integration-card v-if="hasIntegration('ai_aws')" id="ai_aws" />
          <integration-card v-if="hasIntegration('ai_azure')" id="ai_azure" />
          <integration-card v-if="hasIntegration('ai_gcp')" id="ai_gcp" />
        </div>
      </template>

      <h2>{{ $t('integrations.thirdparty_applications') }}</h2>
      <div class="integration-category-list">
        <integration-card id="thirdparty_staffbase" />
      </div>

      <h2>{{ $t('integrations.cdn') }}</h2>
      <div class="integration-category-list">
        <integration-card id="cdn_azure" />
        <integration-card id="cdn_qumu" />
      </div>

      <h2>{{ $t('integrations.livestream') }}</h2>
      <div class="integration-category-list">
        <integration-card id="livestream_aws" />
        <integration-card id="livestream_azure" />
      </div>
    </div>
  </div>
</template>

<script>
  import IntegrationCard from './integration_card.vue';
  import { createNamespacedHelpers } from 'vuex';
  const { mapGetters } = createNamespacedHelpers('integrations');

  export default {
    components: {
      IntegrationCard
    },
    computed: {
      ...mapGetters([
        'hasCategory',
        'hasIntegration'
      ])
    }
  }
</script>

<style scoped>
  h2 {
    font-size: 20px;
    font-weight: 300;
    margin: 30px 0 15px;
  }

  .integration-category-list {
    display: flex;
    flex-wrap: wrap;
  }
</style>
