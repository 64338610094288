import { t } from '../configurations/localization';

/**
 * activates the deleted user filter and sets the correct
 * filter params for the server request
 * @param {*} filter the kendoUi grid filter object
 */
export function userIsDeleted (filter) {
  let column = filter.getFilterColumn('is_deleted');

  return filter.buildFieldItem({ title: t('filter_user_is_deleted') }, null, () => {
    let filterContraint = {
      field: 'is_deleted',
      operator: 'contains',
      value: 'true',
      readOnly: true
    };

    column.filterMenu.filter({ filters: [filterContraint] });
    filter.fieldSelect.close();
  });
}

export function unconfirmedUsers(filter) {
  const column = filter.getFilterColumn('confirmed_at');

  return filter.buildFieldItem({ title: t('filter_unconfirmed_users') }, null, () => {
    const filterConstraint = {
      field: 'confirmed_at',
      operator: 'isnull',
      readOnly: true
    };

    column.filterMenu.filter({ filters: [filterConstraint] });
    filter.fieldSelect.close();
  });
}

export function usersWithAccess(filter) {
  let column = filter.getFilterColumn('has_access');

  return filter.buildFieldItem({ title: t('filter_with_access') }, null, () => {
    const filterConstraint = {
      field: 'has_access',
      operator: 'contains',
      value: 'true',
      readOnly: true
    };

    column.filterMenu.filter({ filters: [filterConstraint] });
    filter.fieldSelect.close();
  });
}

export function usersWithoutAccess(filter) {
  let column = filter.getFilterColumn('has_access');

  return filter.buildFieldItem({ title: t('filter_without_access') }, null, () => {
    const filterConstraint = {
      field: 'has_access',
      operator: 'contains',
      value: 'false',
      readOnly: true
    };

    column.filterMenu.filter({ filters: [filterConstraint] });
    filter.fieldSelect.close();
  });
}
