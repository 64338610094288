import { Widget } from './widgets';
import { WidgetEditor } from './widget_editor';

const logger = Logger.get('WidgetGrid');

const i18n = {
  'de': {
    'unsavedChanges': 'Die von Ihnen vorgenommenen Änderungen werden möglicherweise nicht gespeichert.'
  },
  'en': {
    'unsavedChanges': 'You have unsaved changes on this page. Do you want to leave this page and discard your changes or stay on this page?'
  },
  'es': {
    'unsavedChanges': 'You have unsaved changes on this page. Do you want to leave this page and discard your changes or stay on this page?'
  }
};

/** Class representing the Widget Grid */
export default class WidgetGrid {
  /**
   * @constructor
   * @param {DOMNode} element - DOM Node for the widget grid
   */
  constructor(element) {
    this.element = element;
    this.widgets = [];
    this.removedWidgets = [];
    this._dirty = false;
    this._lockDirty = false;
    this.editable = element.classList.contains('landing-page-widget-grid-editable');
    this.initGridstack();
    this.initializedTrees = null;

    if (this.editable) {
      this.widgetEditor = new WidgetEditor(this);

      window.addEventListener('beforeunload', (e) => {
        if (this.dirty) {
          e.returnValue = i18n[_app.info.currentLocale].unsavedChanges;
          return i18n[_app.info.currentLocale].unsavedChanges;
        }
      });
    }
  }

  /**
   * Setter for the dirty state.
   * We enable/disable the submit button depending on this state
   * @param {Boolean} value - Dirty state
   */
  set dirty(value) {
    if (this._lockDirty) {
      value = this._dirty;
    }

    let submitButton = document.querySelector('.submit-widget-grid');

    if (value) {
      submitButton.classList.remove('disabled');
      submitButton.innerHTML = submitButton.getAttribute('data-save-text');
    } else {
      submitButton.classList.add('disabled');
      submitButton.innerHTML = submitButton.getAttribute('data-saved-text');
    }

    this._dirty = value;
  }

  /**
   * Get the current dirty state
   */
  get dirty() {
    return this._dirty;
  }

  /**
   * Initialize the grid with gridstack library
   */
  initGridstack() {
    let _this = this;

    // clear event handlers from previous instance, if there was
    $(this.element).off('dropover dropout drop');
    $('.landing-page-popover .landing-page-widget-selector-item').off('drag');

    $(this.element).gridstack({
      acceptWidgets: true,
      width: 12,
      float: false,
      verticalMargin: 15,
      cellHeight: 40,
      animate: this.editable,
      staticGrid: !this.editable,
      resizable: this.editable,
      draggable: this.editable
    });

    this.gridstack = $(this.element).data('gridstack');

    // we need to override gridstack's default behavior, because
    // we do not want the draggable element to be removed
    $(this.element).off('drop')
      .on('drop', (e, ui) => {
        this.gridstack.placeholder.detach();
        let node = $(ui.draggable).data('_gridstack_node');

        // sometimes it happens that jquery ui's draggable widget
        // does not fire the dragover event and gridstack messes
        // up, not displaying a preview node and placing the widget
        // at 0,0 which may overlay existing widgets
        if (!node._added) {
          return;
        }

        // reset gridstack node cache
        $(ui.draggable).removeData('_gridstack_node');
        $(ui.draggable).removeData('_gridstack_node_orig');

        node._grid = this.gridstack;
        node.minWidth = $(ui.draggable).data('min-width');
        node.maxWidth = $(ui.draggable).data('max-width');
        node.minHeight = $(ui.draggable).data('min-height');
        node.maxHeight = $(ui.draggable).data('max-height');

        let widgetOptions = {
          grid: this,
          endpoint: $(ui.draggable).data('endpoint'),
          editable: true,
          params: {
            title: $(ui.draggable).data('default-title')
          }
        };

        let widget = new Widget($(ui.draggable).data('widget'), widgetOptions);

        let el = $(widget.wrapper);
        node.el = el;
        el.data('_gridstack_node', node);
        this.gridstack.placeholder.hide();
        el
          .attr('data-gs-x', node.x)
          .attr('data-gs-y', node.y)
          .attr('data-gs-width', node.width)
          .attr('data-gs-height', node.height)
          .attr('data-gs-min-width', $(ui.draggable).data('min-width'))
          .attr('data-gs-max-width', $(ui.draggable).data('max-width'))
          .attr('data-gs-min-height', $(ui.draggable).data('min-height'))
          .attr('data-gs-max-height', $(ui.draggable).data('max-height'))
          .addClass('grid-stack-item');

        this.gridstack.container.append(el);
        this.gridstack._prepareElementsByNode(el, node);
        this.gridstack._updateContainerHeight();
        this.gridstack.grid._addedNodes.push(node);
        this.gridstack._triggerAddEvent();
        this.gridstack._triggerChangeEvent();
        this.gridstack.grid.endUpdate();

        widget.render();

        this.widgets.push(widget);
        this.dirty = true;
      });

    // Re-/Load existing widgets
    this.loadWidgets();

    // Pass resized event to widget
    $(this.element).on('gsresizestop', (e, ui) => {
      let element = ui.querySelector('.landing-page-widget');
      let widget  = _this.findWidget(element);

      if (widget) {
        widget.resized(e);
      }
    });

    $(this.element).on('change', () => {
      this.dirty = true;
    });
  }

  /**
   * Get the current widgets on the grid
   * @return {Object[]} Serialized Widgets
   */
  serialize() {
    let serializedWidgets = [];

    for (let i = 0; i < this.widgets.length; i++) {
      let widget = this.widgets[i];

      serializedWidgets.push({
        id: widget.id,
        widget_type: widget.type,
        x: widget.x,
        y: widget.y,
        width: widget.width,
        height: widget.height,
        parameters: widget.params
      });
    }

    for (let i = 0; i < this.removedWidgets.length; i++) {
      let widget = this.removedWidgets[i];

      serializedWidgets.push({
        id: widget.id,
        _destroy: 1
      });
    }

    return serializedWidgets;
  }

  /**
   * Save the widget grid server-side
   */
  submit() {
    let serializedGrid = this.serialize();
    logger.debug('Saving landing page with widgets: ', serializedGrid);
    let form = document.querySelector('.edit_landing_page');
    let url = form.getAttribute('action');

    $.ajax({
      url: url,
      method: 'PATCH',
      dataType: 'json',
      data: {
        landing_page: {
          widgets_attributes: serializedGrid
        }
      },
      success: (response) => {
        logger.debug('Successfully saved landing page, response: ', response);
        this.dirty = false;
        this.widgets = [];
        this.removedWidgets = [];

        this._lockDirty = true;
        this.reinitializeGrid(response.widgets);
        this._lockDirty = false;
      },
      error: (xhr, status, error) => {
        logger.error('Failed to save landing page: ', status, xhr);
      }
    });
  }

  /**
   * Remove all active widgets and reload the widget grid with
   * a given set of widgets.
   * Used when we submit the WidgetGrid and recieve the actual
   * list from the server, in order to keep synced.
   * @param {Object[]} widgets - Serialized widgets
   */
  reinitializeGrid(widgets) {
    this.gridstack.destroy(false);
    this.element.innerHTML = "";

    for (let i = 0; i < widgets.length; i++) {
      let widget = widgets[i];
      let widgetWrapper = document.createElement('div');
      let widgetContent = document.createElement('div');

      widgetWrapper.className = 'grid-stack-item';
      widgetContent.className = 'grid-stack-item-content';

      widgetWrapper.setAttribute('data-widget', widget.type);
      widgetWrapper.setAttribute('data-id', widget.id);
      widgetWrapper.setAttribute('data-endpoint', widget.endpoint);
      widgetWrapper.setAttribute('data-gs-x', widget.x);
      widgetWrapper.setAttribute('data-gs-y', widget.y);
      widgetWrapper.setAttribute('data-parameters', JSON.stringify(widget.parameters));
      widgetWrapper.setAttribute('data-gs-width', widget.width);
      widgetWrapper.setAttribute('data-gs-height', widget.height);
      widgetWrapper.setAttribute('data-gs-min-width', widget.min_width);
      widgetWrapper.setAttribute('data-gs-max-width', widget.max_width);
      widgetWrapper.setAttribute('data-gs-min-height', widget.min_height);
      widgetWrapper.setAttribute('data-gs-max-height', widget.max_height);

      widgetWrapper.appendChild(widgetContent);
      this.element.appendChild(widgetWrapper);
    }

    this.initGridstack();
  }

  /**
   * Transform the DOMNode representations of the widgets
   * to actual `BaseWidget` classes.
   */
  loadWidgets() {
    let items = this.element.querySelectorAll('.grid-stack-item');
    logger.debug('Loading widgets: ', items);

    for (let i = 0; i < items.length; i++) {
      let parameters = {};

      try {
        parameters = JSON.parse(items[i].getAttribute('data-parameters'));
      } catch(e) {}

      let widgetOptions = {
        grid: this,
        endpoint: items[i].getAttribute('data-endpoint'),
        editable: this.editable,
        params: parameters
      };

      let widget = new Widget(items[i].getAttribute('data-widget'), widgetOptions);
      widget.wrapper = items[i];

      if (this.editable) {
        widget.render();
      } else {
        // if we load widgets in explore perspective, the widgets will already be rendered
        // so we do not need to load them via AJAX
        widget.initialize();
      }

      this.widgets.push(widget);
    }
  }

  /**
   * Find a specific widget for a DOMNode
   * @param {DOMNode} element - grid stack wrapper node
   * @return {BaseWidget} Associated widget instance
   */
  findWidget(element) {
    return this.widgets.find((widget) => {
      return widget.element === element;
    });
  }

  /**
   * Add a new widget
   * @param {DOMNode} element - Element from widget selector
   */
  add(element) {
    let widgetOptions = {
      grid: this,
      endpoint: element.getAttribute('data-endpoint'),
      editable: true,
      params: {
        title: element.getAttribute('data-default-title')
      }
    };

    let widget = new Widget(element.getAttribute('data-widget'), widgetOptions);
    logger.debug('Widget added: ', widget);

    // add widget to grid
    this.gridstack.addWidget(
      widget.wrapper, 0, 0,
      element.getAttribute('data-default-width'),
      element.getAttribute('data-default-height'),
      true,
      element.getAttribute('data-min-width'),
      element.getAttribute('data-max-width'),
      element.getAttribute('data-min-height'),
      element.getAttribute('data-max-height')
    );

    // render widget (asynchronously)
    widget.render();

    this.widgets.push(widget);
    this.dirty = true;
  }

  /**
   * Remove a widget from the grid
   * @param {BaseWidget} widget - Widget to remove
   */
  remove(widget) {
    // remove gridstack element
    this.gridstack.removeWidget(widget.wrapper);

    // remove from widget list
    let index = this.widgets.indexOf(widget);
    this.widgets.splice(index, 1);

    widget.destroy();

    // add to removed widgets if we have an id (delete server-side)
    if (widget.id) {
      this.removedWidgets.push(widget);
    }

    this.dirty = true;
    logger.debug('Widget removed: ', widget);

    return widget;
  }

  /**
   * Enable grid interactivity
   */
  enable() {
    this.gridstack.enable();
  }

  /**
   * Disable grid interactivity
   */
  disable() {
    this.gridstack.disable();
  }
}
