import { Module } from 'vuex';
import { state } from './state';
import { actions } from './actions';
import { mutations } from './mutations';
import { getters } from './getters';
import { RootState } from '../../../store/types';
import { State } from '@uppy/core';

export const UppyModule: Module<State, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
