import { BaseFilter } from '../grid/filter/base_filter';
import { t } from './localization';

function onlyMyOwnFilter (filter) {
  let column = filter.getFilterColumn('author');

  return filter.buildFieldItem({ title: t('filter_only_my_own') }, null, () => {
    let filterContraint = {
      field: 'author',
      operator: 'eq',
      value: _app.info.user.name,
      readOnly: true
    };

    column.filterMenu.filter({ filters: [filterContraint] });
    filter.fieldSelect.close();
  });
}

export class Filter extends BaseFilter {
  buildPresetFilters(dropdown) {
    super.buildPresetFilters(dropdown);

    const onlyMyOwn = onlyMyOwnFilter(this);
    dropdown.appendChild(onlyMyOwn);
  }
}
