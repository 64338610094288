import { t } from './localization';

export function onlyMyOwnFilter (filter) {
  let column = filter.getFilterColumn('author');
  return filter.buildFieldItem({ title: t('filter_only_my_own') }, null, () => {
    let filterContraint = {
      field: 'author',
      operator: 'eq',
      value: _app.info.user.name,
      readOnly: true
    };

    column.filterMenu.filter({ filters: [filterContraint] });
    filter.fieldSelect.close();
  });
}

export function onlyDeleted(filter) {
  let column = filter.getFilterColumn('is_deleted');

  return filter.buildFieldItem({ title: t('deleted_contributions') }, null, () => {
    let filterContraint = {
      field: 'is_deleted',
      operator: 'contains',
      value: 'true',
      readOnly: true
    };

    column.filterMenu.filter({ filters: [filterContraint] });
    filter.fieldSelect.close();
  });
}
