import axios from 'axios';

export let qualificationForm: QualificationForm = null;

export function initQualificationForm() {
  qualificationForm = new QualificationForm();
}

class QualificationForm {
  private input: HTMLInputElement = null;
  private button: HTMLButtonElement = null;
  private saveQualificationButton: HTMLButtonElement = null;
  private formElement: HTMLFormElement = null;
  private formUrl: string = null;
  private selectize: any = null;

  constructor() {
    this.initSelectize('#course_plan_course_qualification_id');
    this.initButton('#create_qualification');
    this.initModalClose();
  }

  private initSelectize(selector: string) {
    this.input = document.querySelector(selector);
    const selectize = $(this.input).selectize({ plugins: ['clear_button'] });
    this.selectize = selectize[0].selectize;
  }

  private initButton(selector: string) {
    this.button = document.querySelector(selector);
    this.formUrl = this.button.getAttribute('href') + '.js';
    this.button.addEventListener('click', this.onOpenQualificationModal.bind(this));
  }

  private onOpenQualificationModal(e: Event) {
    e.preventDefault();

    const wrapper = document.querySelector('#course_qualification_modal .modal-content');
    this.button.classList.add('disbaled');

    $('#course_qualification_modal').modal('show');

    axios.get(this.formUrl, { responseType: 'text' }).then(response => {
      this.button.classList.remove('disabled');
      wrapper.innerHTML = response.data;
      $.material.init();
      document.getElementById('course_qualification_remote_errors').style.display = 'none';
      this.formElement =  wrapper.querySelector('form');
      this.saveQualificationButton = document.getElementById('save-course-qualification') as HTMLButtonElement;
      this.saveQualificationButton.onclick = (e) => this.onSaveQualification(e);
    });
  }

  private onSaveQualification(e: Event) {
    e.preventDefault();
    const data = new FormData(this.formElement);
    const url = this.formElement.getAttribute('action');

    this.saveQualificationButton.classList.add('disabled');

    axios({ url: url, method: 'post', data: data })
      .then(response => this.onSaveSuccess(response))
      .catch(error => this.onSaveError(error));
  }

  private onSaveSuccess(response) {
    this.saveQualificationButton.classList.remove('disabled');
    const data = response.data;

    this.selectize.updateOption(data.id, {
          value: data.id,
          text: data.full_title,
          path: data.path,
          write: data.write
    });

    this.selectize.addOption({
          value: data.id,
          text: data.full_title,
          path: data.path,
          write: data.write
    }); // only adds a new option if none with the same value exists

    this.selectize.refreshOptions(false); // refresh select field, false -> do not pop dropdown menu
    this.selectize.setValue(data.id); // set quiz to recently modified/created one

    $('#course_qualification_modal').modal('hide');
  }

  private onSaveError(error) {
    this.saveQualificationButton.classList.remove('disabled');
    const errors = error.response.data;

    let errorTags = "<ul>";

    for (let i = 0; i < errors.length; i++) {
      errorTags += '<li>'  + errors[i] + '</li>';
    }

    errorTags += '</ul>';

    const errorsContainer = document.getElementById('course_qualification_remote_errors');
    errorsContainer.innerHTML = errorTags;
    errorsContainer.style.display = 'block';
    document.body.scrollTop = 0;
  }

  private initModalClose() {
    $(document).on('hidden.bs.modal', '#course_qualification_modal', (e) => {
      document.querySelector('#course_qualification_modal .modal-content').innerHTML = '';
    });
  }
}
