import TextArea from '../../../../globals/vue/inputs/text_area.vue';
import InputField from '../../../../globals/vue/inputs/input_field.vue';
import ImageInput from '../../../../globals/vue/inputs/image_input.vue';
import SliderInput from '../../../../globals/vue/inputs/slider_input.vue';
import CheckBox from '../../../../globals/vue/inputs/check_box.vue';
import PointsInput from './points_input.vue';
import AnswerOptionInput from './answer_option_input.vue';
import TimeRange from './time_range.vue';

export { TextArea, InputField, ImageInput, SliderInput, CheckBox, PointsInput, AnswerOptionInput, TimeRange };
