export class Autoplay {

  constructor () {
    this.overlay = document.getElementById('next_play_overlay');
    this.url = null;
    this.circleElement = null;
    this.time = 10;
    this.initialOffset = '211';
    this.i = 1;
    this.upNextInterval = null;
    this.initAutoplay();
  }

  initAutoplay () {
    if (this.overlay === null) {
      return;
    }
    // IE11 non function
    //document.getElementById('next_play_overlay').style['display'] = null;
    $('#next_play_overlay').css({ 'display': '' });

    setTimeout(function() {
      this.url = this.overlay.getAttribute('data-element-url');
      this.circleElement = this.overlay.getElementsByClassName('circle-animation')[0];
      this.bindAutoplayListener();
      /* Need initial run as interval hasn't yet occured... */
      this.circleElement.style["stroke-dashoffset"] = this.initialOffset - (1 * (this.initialOffset / this.time ));
      this.upNextInterval = setInterval(function() {
          if (this.i === this.time) {
            clearInterval(this.upNextInterval);
            window.location = this.url;
            return;
          }
          this.circleElement.style["stroke-dashoffset"] = this.initialOffset - ((this.i + 1) * (this.initialOffset / this.time ));
          this.i++;
      }.bind(this), 1000);
    }.bind(this));
  }

  bindAutoplayListener() {
    this.overlay.getElementsByClassName('upnext-cancel-button')[0].addEventListener('click', e => {
      clearInterval(this.upNextInterval);
      this.circleElement.style["stroke-dashoffset"] = null;
      this.overlay.style.display = "none";
    });

    this.overlay.getElementsByClassName('upnext-autoplay-icon')[0].addEventListener('click', e => {
      clearInterval(this.upNextInterval);
      window.location = this.url;
    });
  }
}


