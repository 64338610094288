import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  namespaced: true,
  state: {
    categories: {
      byId: {},
      allIds: []
    },
    videos: {
      byId: {},
      allIds: []
    },
    currentVideo: null,
    searchQuery: null,
    searchResults: [],
    filterCategory: 'all',
    filterRole: ['guest'],
    hiddenCategories: []
  },
  mutations,
  actions,
  getters
};
