import { ActionTree } from "vuex";
import { QuizManageState } from "./state";
import { RootState } from "../../../store/types";
import { Mutations } from './mutations';
import { Entities } from './state';
import { Answer, Question } from "../entities";
import { AnswerType } from "../entities/answer";
import { QuestionType } from "../entities/question";
import { MatchingOption } from "../entities/matching_option";
import { DragAndDropOption  } from "../entities/drag_and_drop_option";
import { FillTheBlankOption } from "../entities/fill_the_blank_options";

export const Actions = {
  UPDATE_ENTITIES: 'quizManage/updateEntities',
  SET_QUIZ: 'quizManage/setQuiz',
  SET_QUESTION_IDS: 'quizManage/setQuestionIds',
  SET_CURRENT_QUESTION: 'quizManage/setCurrentQuestion',
  CLEAR_QUESTION_IMG: 'quizManage/clearQuestionImg',
  SET_QUESTION_IMG: 'quizManage/setQuestionImg',
  SET_ANSWER_IMG: 'quizManage/setAnswerImg',
  SET_QUESTION_ATTRIBUTES: 'quizManage/setQuestionAttributes',
  SET_ANSWER_ATTRIBUTES: 'quizManage/setAnswerAttributes',
  SET_MATCHING_ANSWER_ATTRIBUTES: 'quizManage/setMatchingAnswerAttributes',
  SET_GENERAL_ATTRIBUTES: 'quizManage/setGeneralAttributes',
  SET_ANSWER_IDS: 'quizManage/setAnswerIds',
  ADD_STATEMENT_ANSWER: 'quizManage/addStatementAnswer',
  ADD_SINGLE_CHOICE_ANSWER: 'quizManage/addSingleChoiceAnswer',
  ADD_ARRANGEMENT_ANSWER: 'quizManage/addArrangementAnswer',
  DELETE_ANSWER: 'quizManage/deleteAnswer',
  DELETE_MATCHING_ANSWER: 'quizManage/deleteMatchingAnswer',
  ADD_QUESTION: 'quizManage/addQuestion',
  DELETE_QUESTION: 'quizManage/deleteQuestion',
  SET_MODE: 'quizManage/setMode',
  SET_GENERAL_IMG: 'quizManage/setGeneralImg',
  SET_QUESTION_EDITORS: 'quizManage/setQuestionEditors',
  SET_MATCHING_OPTION: 'quizManage/setMatchingOption',
  ADD_MATCHING_ANSWER: 'quizManage/addMatchingAnswer',
  REFRESH_QUESTION_POSITIONS: 'quizManage/refreshQuestionPositions',
  SET_STATE: 'quizManage/setState',
  INITIALIZE: 'quizManage/initialize',
  SET_DRAG_AND_DROP_ACTIONS: 'quizManage/setDragAndDropOptions',
  UPDATE_DRAG_AND_DROP_OPTION: 'quizManage/updateDragAndDropOption',
  ADD_BLANK_OPTION_ANSWER: 'quizManage/addBlankOptionAnswer',
  ADD_BLANK_OPTION: 'quizManage/addBlankOption',
  ADD_WRONG_BLANK_OPTION: 'quizManage/addWrongBlankOption',
  DELETE_WRONG_BLANK_OPTION: 'quizManage/deleteWrongBlankOption',

}

export const actions: ActionTree<QuizManageState, RootState> = {
  updateEntities({ commit }, entities: Entities) {
    commit(Mutations.UPDATE_ENTITIES, entities);
  },

  setQuiz({ commit }, payload) {
    commit(Mutations.SET_QUIZ, payload);
  },

  setQuestionIds({ commit }, payload) {
    commit(Mutations.SET_QUESTION_IDS, payload);
  },

  setCurrentQuestion({ commit }, questionId) {
    commit(Mutations.SET_CURRENT_QUESTION, questionId);
  },

  setQuestionEditors({ commit }, payload) {
    commit(Mutations.SET_QUESTION_EDITORS, payload);
  },

  clearQuestionImg({ commit }, questionId) {
    commit(Mutations.CLEAR_QUESTION_IMG, questionId);
  },

  setQuestionImg({ commit }, payload) {
    commit(Mutations.SET_QUESTION_IMG, payload);
  },

  setAnswerImg({ commit }, payload) {
    commit(Mutations.SET_ANSWER_IMG, payload);
  },

  setQuestionAttributes({ commit }, payload) {
    commit(Mutations.SET_QUESTION_ATTRIBUTES, payload);
  },

  setAnswerAttributes({ commit }, payload) {
    commit(Mutations.SET_ANSWER_ATTRIBUTES, payload);
  },

  setMatchingAnswerAttributes({ commit }, payload) {
    commit(Mutations.SET_MATCHING_ANSWER_ATTRIBUTES, payload);
  },

  setAnswerIds({ commit, getters }, payload) {
    const question: Question = getters.question(payload.questionId);

    if (question.type === 'matching') {
      commit(Mutations.SET_ANSWER_IDS, { ...payload, schema: 'matchingAnswers' });
    } else {
      commit(Mutations.SET_ANSWER_IDS, { ...payload, schema: 'answers' });
    }

    if (question.type === 'arrangement') {
      commit(Mutations.UPDATE_ARRANGEMENT_SOLUTIONS, payload.questionId);
    }
  },

  addStatementAnswer({ commit }, payload) {
    const answer = new Answer({
      type: AnswerType.statement,
      points: 0,
      isSolution: true,
      questionId: payload.questionId,
      id: payload.id
    });

    commit(Mutations.ADD_ANSWER, answer);
  },

  addSingleChoiceAnswer({ commit, getters }, payload) {
    const isFirst = getters.answerIds(payload.questionId).length === 0;

    const answer = new Answer({
      type: AnswerType.single,
      points: 0,
      isSolution: isFirst ? true : false,
      questionId: payload.questionId,
      id: payload.id
    })

    commit(Mutations.ADD_ANSWER, answer);
  },

  addArrangementAnswer({ commit }, payload) {
    const answer = new Answer({
      type: AnswerType.arrangement,
      points: 0,
      questionId: payload.questionId,
      id: payload.id
    });

    commit(Mutations.ADD_ANSWER, answer);
    commit(Mutations.UPDATE_ARRANGEMENT_SOLUTIONS, payload.questionId);
  },

  addBlankOptionAnswer({commit}, payload){
    const answer = new Answer({
      type: AnswerType.fill_the_blank,
      points: 0,
      questionId: payload.questionId,
      id: payload.id,
      options: payload.blankOptions
    });
    commit(Mutations.ADD_ANSWER, answer);
  },

  addBlankOption({commit}, payload) {
    const blankOption = new FillTheBlankOption({
      id: payload.id,
      points: payload.blankOptions.points,
      rightAnswer: payload.blankOptions.rightAnswer,
      wrongAnswers: payload.blankOptions.wrongAnswers
    });
    commit(Mutations.ADD_BLANK_ANSWER_OPTION, {questionId: payload.questionId, answerId: payload.answerId, blankOption: blankOption});
  },

  addWrongBlankOption({commit}, payload) {
    commit(Mutations.ADD_WRONG_BLANK_OPTION, payload);
  },
  
  deleteWrongBlankOption({commit}, payload) {
    commit(Mutations.DELETE_WRONG_BLANK_OPTION, payload);
  },

  addMatchingAnswer({ commit }, payload) {
    const matchingOptionArgs = 'matchingOptionId' in payload ? { id: payload.matchingOptionId } : {};
    const matchingOption = new MatchingOption(matchingOptionArgs);
    const answer = new Answer({
      type: AnswerType.matching,
      points: 0,
      questionId: payload.questionId,
      id: payload.id,
      matchingOption: matchingOption.id
    });

    commit(Mutations.ADD_MATCHING_OPTION, matchingOption);
    commit(Mutations.ADD_MATCHING_ANSWER, answer);
  },

  setMatchingOption({ commit }, payload) {
    commit(Mutations.SET_MATCHING_OPTION, payload);
  },

  addQuestion({ commit }, payload: { id: EntityId,  actionPayload: {questionType: QuestionType, answerAttributes: Answer, answerCount: number }, silent: Boolean}) {
    const question = new Question({ id: payload.id, type: payload.actionPayload.questionType });
    commit(Mutations.ADD_QUESTION, question);

    for(let i = 0; i < payload.actionPayload.answerCount; i++) {
      let answerAttributes = Object.assign({id: payload.id + "_" + i, questionId: question.id }, payload.actionPayload.answerAttributes);
      // Set radio value to first answer initially
      if (payload.actionPayload.questionType === QuestionType.single && i === 0) {
        answerAttributes.isSolution = true;
      }

      if (answerAttributes.type === AnswerType.matching) {
        const matchingOption = new MatchingOption();
        commit(Mutations.ADD_MATCHING_OPTION, matchingOption);
        answerAttributes['matchingOption'] = matchingOption.id;
        commit(Mutations.ADD_MATCHING_ANSWER, new Answer(answerAttributes));
      }
      else {
        commit(Mutations.ADD_ANSWER, new Answer(answerAttributes));
      }
    }

    if (payload.actionPayload.questionType == QuestionType.arrangement) {
      commit(Mutations.UPDATE_ARRANGEMENT_SOLUTIONS, question.id);
    }

    if(!payload.silent){
      commit(Mutations.SET_CURRENT_QUESTION, question.id);
    }
  },

  deleteAnswer({ commit }, payload) {
    if (typeof payload === 'object') {
      payload = payload.id;
    }
    commit(Mutations.DELETE_ANSWER, payload);
  },

  deleteMatchingAnswer({ commit }, payload) {
    if (typeof payload === 'object') {
      payload = payload.id;
    }
    commit(Mutations.DELETE_MATCHING_ANSWER, payload);
  },

  deleteQuestion({ state, commit, getters }, payload) {
    if (typeof payload === 'object') {
      payload = payload.id;
    }
    commit(Mutations.DELETE_QUESTION, payload);

    if (state.currentQuestion === payload) {
      const firstQuestion = getters.questionIds[0];
      commit(Mutations.SET_CURRENT_QUESTION, typeof firstQuestion === 'undefined' ? null : firstQuestion);
    }
  },

  setMode({ commit }, mode) {
    commit(Mutations.SET_MODE, mode);
  },

  setGeneralAttributes({ commit }, payload) {
    commit(Mutations.SET_GENERAL_ATTRIBUTES, payload);
  },

  setGeneralImg({ commit }, file) {
    commit(Mutations.SET_GENERAL_IMG, file);
  },

  refreshQuestionPositions({ commit, getters }) {
    commit(Mutations.REFRESH_QUESTION_POSITIONS, getters.questionIds);
  },

  setState({ commit }, payload) {
    commit(Mutations.SET_STATE, payload);
  },

  initialize({ commit }, payload) {
    commit(Mutations.INITIALIZE, payload);
  },

  setDragAndDropOptions({ commit }, payload: { answerId: EntityId, options: DragAndDropOption[] }) {
    commit(Mutations.SET_DRAG_AND_DROP_OPTIONS, payload);
  },

  updateDragAndDropOption({ commit }, payload) {
    commit(Mutations.UPDATE_DRAG_AND_DROP_OPTION, payload);
  },
}
