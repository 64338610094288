<template>
<div class="question-overlay">
  <div ref="responsiveWrapper" class="question-responsive-wrapper">
    <zoom-center-transition mode="out-in" :duration="150">
      <div key="questionElement" ref="questionElement" class="question" v-if="question && !isAnsweredCorrect">
        <h2>{{question.title}}</h2>
        <slide-y-up-transition>
          <div v-if="isAnsweredWrong">
            <div class="question-wrong">
              <i class="material-icons">close</i>
              <div class="question-wrong-text">
                <div class="question-tryagain">{{ $t('course.question_answered_wrong') }}</div>
                <div v-if="question.hint && question.hint.length > 0" class="question-hint">{{ question.hint }}</div>
              </div>
            </div>
          </div>
        </slide-y-up-transition>

        <component
          ref="answer"
          :is="answerComponent"
          :options="orderedOptions">
        </component>

        <div class="actions">
          <button v-show="!onlySolution" type="button" class="btn btn-primary btn-flat pull-left" @click="onRewatch"><i class="material-icons">replay</i> {{ $t('course.rewatch') }}</button>
          <button v-show="isAnsweredWrong && !isSolutionShown" type="button" class="btn btn-primary btn-flat" @click="onShowSolution">{{ $t('course.show_solution') }}</button>
          <button v-show="!onlySolution" type="button" class="btn btn-primary" @click="onSubmit">{{ $t('course.submit') }}</button>
          <button v-show="onlySolution" type="button" class="btn btn-primary" @click="onClose">{{ $t('course.close') }}</button>
        </div>
      </div>
      <div key="questionCorrect" v-if="question && isAnsweredCorrect" class="question-correct">
        <i class="material-icons">done</i>
        <p>{{ $t('course.question_answered_correct') }}</p>
        <button type="button" class="btn btn-primary" @click="onContinue">{{ $t('course.continue') }}</button>
      </div>
    </zoom-center-transition>
  </div>
</div>
</template>

<script>
import { ZoomCenterTransition, SlideYUpTransition } from 'vue2-transitions'
import SingleSelect from './single_select.vue';
import MultipleSelect from './multiple_select.vue';

export default {
  name: 'question-overlay',
  props: ['question'],
  components: {
    ZoomCenterTransition,
    SlideYUpTransition,
    SingleSelect,
    MultipleSelect
  },
  data() {
    return {
      isAnswered: false,
      wrong: false,
      isSolutionShown: false,
      onlySolution: false
    }
  },
  methods: {
    updateSizing() {
      // Update the font-size to achieve consistent sizing of the question
      // Using a default of 14px at 800px height
      const availableHeight = this.$el.clientHeight;
      const scaleFactor = availableHeight > 600 ? 1 : availableHeight / 600;

      this.$refs.responsiveWrapper.style.transform = `scale(${scaleFactor})`;
    },
    onRewatch() {
      this.$emit('rewatch');
    },
    onSubmit() {
      this.isAnswered = false;
      this.correct = false;

      if (this.$refs.answer.isCorrect()) {
        this.correct = true;
      }

      this.$nextTick(() => this.isAnswered = true);
    },
    onClose() {
      this.$emit('completed');
    },
    onContinue() {
      if (this.isAnsweredCorrect) {
        this.$emit('completed');
      }
    },
    onShowSolution() {
      this.isSolutionShown = true;
      this.$refs.answer.showSolution();
    },
    showOnlySolution() {
      this.isSolutionShown = true;
      this.$refs.answer.showSolution();
      this.onlySolution = true;
    }
  },
  computed: {
    orderedOptions() {
      if (this.question.randomize) {
        // Random order (Fisher-Yates)
        let options = [ ... this.question.options ];

        for (let i = options.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [options[i], options[j]] = [options[j], options[i]];
        }

        return options;
      } else {
        return this.question.options.sort((a, b) => a.position - b.position);
      }
    },
    answerComponent() {
      return this.question.type === 'FilmQuestion::SingleSelect' ? 'single-select' : 'multiple-select';
    },
    isAnsweredCorrect() {
      return this.isAnswered && this.correct;
    },
    isAnsweredWrong() {
      return this.isAnswered && !this.correct;
    }
  },
  created() {
    window.addEventListener('resize', this.updateSizing);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateSizing);
  },
  watch: {
    question(question) {
      // Reset answer state
      this.isAnswered = false;
      this.correct = false;
      this.isSolutionShown = false;
      this.onlySolution = false;

      this.$nextTick(() => {
        this.updateSizing();
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.question-overlay {
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: $black-87-opacity;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: $color-white;
  overflow-y: auto;
  align-items: center;
  justify-content: center;
  z-index: 50;
}

.question-responsive-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}

.question {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 600px;
  border-radius: 5px;
  padding: 15px;
}

.btn {
  line-height: 20px;
  text-transform: uppercase;
}

h2 {
  font-size: 24px;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 30px;
  text-align: center;
}

.actions {
  text-align: right;
}

.question-wrong {
  padding-bottom: 15px;
  display: flex;
  align-items: center;

  .question-wrong-text {
    flex: 1 auto;
  }

  i {
    color: $color-error-red;
    font-size: 30px;
    padding: 0 15px;
  }

  .question-tryagain {
    color: $color-error-red;
  }
}

.question-hint {
  margin-top: 10px;
}

.question-correct {
  text-align: center;

  i {
    color: var(--primary-color);
    font-size: 5em;
  }

  p {
    font-size: 16px;
    margin: 15px 0 30px;
  }
}
</style>
