import { ActionTree } from "vuex";
import { FilmTranscriptState } from "./state";
import { RootState } from "../../store/types";
import { Mutations } from './mutations';
import axios, { AxiosResponse } from 'axios';

export const Actions = {
  SET_INITIAL: 'filmTranscript/setInitial',
  SET_CURRENT_TIME: 'filmTranscript/setCurrentTime',
  SET_FILTER_VALUE: 'filmTranscript/setFilterValue',
  SET_AUTOSCROLL_VALUE: 'filmTranscript/setAutoscrollValue',
  SAVE_PHRASE: 'filmTranscript/savePhrase',
  RESET_TRANSCRIPT: 'filmTranscript/resetTranscript',
  DELETE_PHRASE: 'filmTranscript/deletePhrase'
}

export const actions: ActionTree<FilmTranscriptState, RootState> = {
  setInitial({ commit }, payload) {
    commit(Mutations.SET_INITIAL, payload);
  },
  setCurrentTime({ commit }, currentTime: number) {
    commit(Mutations.SET_CURRENT_TIME, currentTime);
  },
  setFilterValue({ commit }, filterValue: string) {
    commit(Mutations.SET_FILTER_VALUE, filterValue);
  },
  setAutoscrollValue({ commit }, autoscrollValue: boolean) {
    commit(Mutations.SET_AUTOSCROLL_VALUE, autoscrollValue);
  },
  async savePhrase({ commit, state }, payload: { id: number, text: string }): Promise<boolean> {
    commit(Mutations.ADD_SAVING, payload.id);

    try {
      await axios.post(state.urls.updatePhraseUrl, { id: payload.id, text: payload.text });
      commit(Mutations.REMOVE_ERROR, payload.id);
      commit(Mutations.REMOVE_SAVING, payload.id);
      commit(Mutations.UPDATE_PHRASE, payload);
      return true;

    } catch (error) {
      commit(Mutations.REMOVE_SAVING, payload.id);
      commit(Mutations.ADD_ERROR, { id: payload.id, message: error })
      return Promise.reject(false);
    }
  },
  async resetTranscript({ commit, state }): Promise<boolean> {
    try {
      const response = await axios.post(state.urls.resetUrl);
      commit(Mutations.SET_TRANSCRIPT, response.data.phrases);
      return true;

    } catch (error) {
      console.error(error);
      return false;
    }
  },
  async deletePhrase({ commit, state }, phraseId: number): Promise<AxiosResponse> {
    try {
      const response = await axios.post(state.urls.deletePhraseUrl, { id: phraseId });
      commit(Mutations.REMOVE_PHRASE, phraseId);
      return response;

    } catch (error) {
      console.error(error);
      return error;
    }
  }
}
