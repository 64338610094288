<template>
  <div class="playlist-element">
    <input v-if="typeof element.id === 'number'" type="hidden" :name="`playlist[playlist_elements_attributes][][id]`" :value="element.id">
    <input type="hidden" :name="`playlist[playlist_elements_attributes][][element_id]`" :value="element.elementId">
    <input type="hidden" :name="`playlist[playlist_elements_attributes][][element_type]`" :value="element.elementType">
    <input type="hidden" :name="`playlist[playlist_elements_attributes][][position]`" :value="position">
    <i class="material-icons draghandle">drag_indicator</i>
    <thumbnail-component class="element-thumbnail" :tagValue="element.duration" tagType="time" :url="element.thumbnailUrl"></thumbnail-component>
    <div class="element-position">{{ formattedPosition }}</div>
    <div class="title-wrap">
      <div class="element-title">{{ element.title }}</div>
      <div class="element-description">{{ element.author }} - {{ element.updated }} - {{ element.displaytype }}</div>
    </div>
    <div class="element-action">
      <a @click.prevent="deleteElement()" href="#" class="material-icons">delete</a>
    </div>
  </div>
</template>

<script>
import ThumbnailComponent from '../../../globals/vue/thumbnail_component.vue';
import { Actions } from '../../store/actions';

export default {
  props: [ 'element', 'position' ],
  components: {
    ThumbnailComponent
  },
  methods: {
    deleteElement() {
      this.$store.dispatch(Actions.REMOVE_ELEMENT, this.element.id);
    } 
  },
  computed: {
    formattedPosition: function () {
      return `0${this.position}`.slice(-2);
    },
    elementId: function () {
      return typeof this.element.id === 'string' ? "" : this.element.id; 
    }
  }
}
</script>

<style lang="scss" scoped>
.element-thumbnail {
    width: 102px;
    height: 58px;
    margin: 0 15px 0 0;
}

.playlist-element {
  display: flex;
  position: relative;
  border: 1px solid $color-light-grey-2;
  border-radius: 3px;
  height: 60px;
  padding: 10px;
  margin: 10px 0;
  align-items: center;
  background-color: $color-white;
  transition: border-color .15s linear;
}

.draghandle {
  font-size: 24px;
  line-height: 60px;
  color: $black-12-opacity;
  padding: 0 10px;
  margin-left: -10px;
  cursor: move;
}

.element-position {
  padding: 0 30px 0 15px;
}

.element-title {
  font-size: 16px;
  padding: 0;
  margin: 0;
  line-height: 24px;

}

.element-description {
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, .54);

}
.element-action .material-icons {
  font-size: 20px;
  padding: 10px;
  color: rgba(0, 0, 0, .54);
}

.title-wrap {
  flex-grow: 1;
}
</style>
