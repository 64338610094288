export class Chart {
  constructor(element, container) {
    this.element = element;
    this.id = element.getAttribute('id');
    this.container = container;
    this.currentValue = null;
    this.chartConfig = {};
    this.chart = this.element.getAttribute('data-has-chart');
    this.chartArea = this.element.querySelector('#chart-area');
    this.params = { "value": null };
    this.active = true;
    this.setChartConfig();
  }

  load(data) {
    this.currentValue = data;
    if ( data.chart.data.length < 1) {
      this.displayMessage();
    } else if (this.chart === 'pie') {
      this.removeDisplayMessage();
      this.loadBasicPieChart();
    } else {
      this.removeDisplayMessage();
      this.loadBasicLineChart();
    }
  }

  loadBasicLineChart() {
    let chartConfig = this.chartConfig;
    chartConfig["series"][0]["data"] = this.currentValue.chart.data;
    chartConfig["series"][0]["name"] = this.currentValue.chart.tooltip;
    chartConfig["xAxis"] = { "categories": this.currentValue.chart.xAxis };

    Highcharts.chart(this.chartArea, chartConfig);
  }

  loadBasicPieChart() {
    let chartConfig = this.chartConfig;
    chartConfig["series"][0]["data"] = this.currentValue.chart.data;
    Highcharts.chart(this.chartArea, chartConfig);
  }

  buildParam() {
    return this.params;
  }

  setChartConfig() {
    this.defaultChartConfig();
  }

  defaultChartConfig() {
    this.chartConfig["chart"] = { "type": this.chart };
    this.chartConfig["title"] = { "text": "" };
    this.chartConfig["exporting"] = { "enabled": false };
    this.chartConfig["credits"] = { "enabled": false };
    this.chartConfig["tooltip"] = this.tooltipConfig();
    this.chartConfig["legend"] = { "enabled": false };
    this.chartConfig["yAxis"] = this.yAxisConfig();
    this.chartConfig["series"] = [{ "label": { "enabled": false } }];

    if (this.chart === 'pie') { this.pieChartConfig(); }
  }

  tooltipConfig() {
    if (this.element.getAttribute('data-y-axis-type') === 'time') {
      return {
        formatter: function () {
          let label = "";
          let duration = moment.duration(this.y, 'seconds');
          
          if (duration.asHours >= 1) {
            label = moment.utc(duration.asMilliseconds()).format("hh:mm:ss");
          } else {
            label = moment.utc(duration.asMilliseconds()).format("mm:ss");
          }
          return this.series.name + ': <b>' + label + '</b><br/>';
        }
      };
    } else {
      return { "enabled": true, "pointFormat": `{series.name}: <b>{point.y}</b><br/>` };
    }
    
  }

  yAxisConfig() {
    if (this.element.getAttribute('data-y-axis-type') === 'time') {
      return this.yAxisTimeType();
    } else {
      return { "title": { "text": null } };
    }
  }

  yAxisTimeType() {
    return {labels: {
      formatter: function () {
          let label = "";
          let duration = moment.duration(this.value, 'seconds');
          
          if (duration.asHours >= 1) {
            label = moment.utc(duration.asMilliseconds()).format("hh:mm:ss");
          } else {
            label = moment.utc(duration.asMilliseconds()).format("mm:ss");
          }

          return label;
      }
    },
    title: { text: null }};
  }

  pieChartConfig() {
    this.chartConfig["tooltip"] = { "enabled": true, "pointFormat": `<b>{point.y}</b><br/>` };
  }

  displayMessage() {
    if (!this.element.querySelector('#noResults')) {
      let $message = `<div class="no-results" id="noResults"><div class="message"> ${this.locals()[_app.info.currentLocale].noResults}</div></div>`;
      this.chartArea.querySelector('.highcharts-container').remove();
      this.chartArea.innerHTML = $message;
    }

  }

  removeDisplayMessage() {
    if (this.element.querySelector('#noResults')) {
      this.element.querySelector('#noResults').remove();
    }
  }

  locals() {
    return {
      'de': { 'noResults': 'Es wurden keine passenden Daten gefunden!' },
      'en': { 'noResults': 'No suitable data was found!' },
      'es': { 'noResults': 'No se encontraron datos adecuados!' }
    };
  }

  renderFullSizeChart() {
    let modal = document.querySelector('#full_size_chart');
    let chartArea = modal.querySelector('.full-size-chart');
    if (this.currentValue.chart.data.length < 1) {
      this.displayMessage();
    } else {
      let chartConfig = Object.assign({}, this.chartConfig);
      chartConfig["exporting"] = { "enabled": true };
      chartConfig["title"] = { "text": this.container.readContainerTitle() };
      chartConfig["series"][0]["data"] = this.currentValue.chart.data;
      Highcharts.chart(chartArea, chartConfig);
    }

  }
}
