export class ThumbnailPreview {

  constructor(element) {
    this.element = element;
    this.preview_url = this.element.getAttribute("data-preview-url");
    this.video_tag = null;
    this.registerHoverEvent();
    this.spinner = this.buildSpinner();
  }

  buildVideoTag() {
    let width = this.element.offsetWidth;
    let height = this.element.offsetHeight;
    let video = document.createElement('video');
    
    video.style.display = 'none';
    video.setAttribute('width', width);
    video.setAttribute('height', height);
    video.setAttribute('autoplay', true);
    video.muted = true;
    video.innerHTML = `<source src="${this.preview_url}" type="video/mp4">`;
    
    return video;
  }

  registerHoverEvent() {
    this.element.onmouseleave = this.mouseleave.bind(this);
    this.element.onmouseenter = this.mouseenter.bind(this);
  }

  mouseenter() {
    if(this.element.onmouseleave !== null) {
      if(!this.video_tag) {
        this.video_tag = this.buildVideoTag();
        this.registerVideoEvents();
        this.element.appendChild(this.spinner);
      }
  
      if(!this.element.contains(this.video_tag)) {
        this.element.appendChild(this.video_tag);
      }
    
      if(this.video_tag.paused) {
        this.video_tag.play();
      }
    }
  }

  mouseleave() {
    if(this.video_tag) {
      this.video_tag.pause();
      this.video_tag.currentTime = 0;
      this.element.removeChild(this.video_tag);
    }
  }

  registerVideoEvents() {
    // Wait for video to be ready to prevent black background
    this.video_tag.oncanplay = e => { 
      if (this.spinner !== null && this.element.contains(this.spinner)) {
        this.element.removeChild(this.spinner);
        this.video_tag.style.display = 'block';
        this.spinner = null;
      }
    };

    // Replay on ended
    this.video_tag.onended = e => {
      this.video_tag.currentTime = 0;
      this.video_tag.play();
    };
  }

  buildSpinner() {
    let spinner = document.createElement('div');
    spinner.classList.add('busy');
    spinner.innerHTML = '<div class="loader"><div class="circle"></div><div class="circle"></div><div class="circle"></div><div class="circle"></div></div></div>';
    return spinner;
  }
}
