import Vue from 'vue';
import App from './components/app.vue';

export let app: Vue = null;

export function init() {
  const el: HTMLElement = document.getElementById('v-two-factor');

  if (!el) {
    return;
  }

  app = new Vue({
    el,
    i18n: VERSTEHE.vueI18n,
    render: h => h(App, {
      props: {
        forced: el.dataset.forced === 'true',
        enabled: el.dataset.enabled === 'true',
        method: el.dataset.method,
        enableUrl: el.dataset.enableUrl,
        disableUrl: el.dataset.disableUrl,
        regenerateUrl: el.dataset.regenerateUrl,
        backupLeft: parseInt(el.dataset.backupLeft, 10)
      }
    })
  });
}
