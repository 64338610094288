import Vue from 'vue';
import Component from 'vue-class-component'
import { Watch, Prop } from 'vue-property-decorator';
import { MapGetter } from '../../vue/decorators';
import { Getters } from '../store/getters';
import { Slideshow, Take, SelectionMode } from '../interfaces';
import { Actions } from '../store/actions';

@Component
export class SlideshowMixin extends Vue {
  @MapGetter(Getters.SELECTED_SLIDES) selectedSlides;
  @MapGetter(Getters.SELECTION_MODE) selectionMode: SelectionMode;

  item: Slideshow

  mounted() {
    this.onChangeSelected();
  }
  
  get selectionActive() {
    return this.selectionMode !== SelectionMode.None;
  }

  /*
  * Change default checkbox behaviour for slideshows (tri-state)
  */
  onSlideshowCheck(event) {
    const item = (this.item as Slideshow);
    const slideIds = item.slides.map(slide => slide.id);
    
    if (this.selectedIntersection(slideIds).length === item.slides.length) {
      this.$store.dispatch(Actions.UNSELECT_SLIDES, slideIds);
    } else {
      this.$store.dispatch(Actions.SELECT_SLIDES, slideIds);
    }
  }

  private selectedIntersection(slideIds: Array<number>): Array<number> {
    return slideIds.filter(slide => this.selectedSlides.indexOf(slide) !== -1);
  }

  @Watch('selectedSlides')
  private onChangeSelected() {
    if (this.item.type === 'slideshow') {
      const slideshow = this.item as Slideshow;
      const slideIds = slideshow.slides.map(slide => slide.id);
      const intersection = this.selectedIntersection(slideIds);
      const checkbox = this.$refs.slideCheck as HTMLInputElement;

      if (!checkbox) {
        return;
      }

      if (slideIds.length > 0 && intersection.length === slideIds.length) {
        checkbox.checked = true;
        checkbox.indeterminate = false;
      } else if (intersection.length > 0) {
        checkbox.checked = false;
        checkbox.indeterminate = true;
      } else {
        checkbox.checked = false;
        checkbox.indeterminate = false;
      }
    }
  }
}
