export let blocks = [];

export function init() {
  const elements = document.querySelectorAll('.contribution-description.read-more-block');

  elements.forEach(block => {
    blocks.push(new Readmore(block));
  });
}

export class Readmore {
  constructor(element) {
    this.element = element;
    this.element.classList.add('collapsed');
    this.initReadMore();
    this.initReadLess();
    this.updateOverflow();

    window.addEventListener('resize', () => this.updateOverflow());
  }

  initReadMore() {
    this.readMoreBtn = document.createElement('a');
    this.readMoreBtn.innerText = _app.locales.readMoreLink;
    this.readMoreBtn.classList.add('read-more-btn');
    this.element.appendChild(this.readMoreBtn);

    this.readMoreBtn.addEventListener('click', () => this.expand());
  }

  initReadLess() {
    this.readLessBtn = document.createElement('a');
    this.readLessBtn.innerText = _app.locales.readLessLink;
    this.readLessBtn.classList.add('read-less-btn');
    this.element.appendChild(this.readLessBtn);

    this.readLessBtn.addEventListener('click', () => this.collapse());
  }

  expand() {
    this.element.classList.add('expanded');
    this.element.classList.remove('collapsed');
  }

  collapse() {
    this.element.classList.add('collapsed');
    this.element.classList.remove('expanded');
  }

  updateOverflow() {
    const wasExpanded = this.element.classList.contains('expanded');
    this.collapse();

    if (this.element.scrollHeight > this.element.clientHeight) {
      this.setOverflow();
    } else {
      this.unsetOverflow();
    }

    if (wasExpanded) {
      this.expand();
    }
  }

  setOverflow() {
    this.element.classList.add('has-overflow');
  }

  unsetOverflow() {
    this.element.classList.remove('has-overflow');
  }
}
