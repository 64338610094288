<template>
  <div class="getting-started-task-list">
    <img :src="require('images/get_started_rocket.svg')" width="300" height="200">

    <div class="getting-started-task-scope">
      <h3>{{ $t('getting_started.lets_get_started') }}</h3>
      <div class="getting-started-task-scope-select" v-if="hasRole('trainer')">
        <dropdown-component :label="scopeLabel" icon="arrow_drop_down" direction="right">
          <li v-if="hasRole('author')" @click="changeScope('author')">{{ $t('getting_started.author') }}</li>
          <li v-if="!hideTrainerTasks && hasRole('trainer')" @click="changeScope('trainer')">{{ $t('getting_started.trainer') }}</li>
          <li v-if="hasRole('publisher')" @click="changeScope('publisher')">{{ $t('getting_started.publisher') }}</li>
        </dropdown-component>
      </div>
    </div>

    <div class="getting-started-tasks">
      <slide-x-right-transition group>
        <task-entry
          v-for="task in scopeTasks"
          :id="task"
          :key="task" />
      </slide-x-right-transition>
    </div>

    <div class="getting-started-progress">
      <div class="getting-started-progress-label">
        <h3>{{ $t('getting_started.progress') }}</h3>
        <span>{{ scopeCompletedTasks.length }} / {{ scopeTasks.length }}</span>
      </div>
      <div class="progress">
        <div class="progress-bar" :style="{ width: Math.round(scopeProgress * 100) + '%'}"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import DropdownComponent from '../../globals/vue/dropdown_component.vue'
  import { SlideXRightTransition } from 'vue2-transitions';
  import {
    AUTHOR_TASKS,
    TRAINER_TASKS,
    PUBLISHER_TASKS,
    TaskEntry
  } from './tasks';

  export default {
    components: {
      DropdownComponent,
      TaskEntry,
      SlideXRightTransition
    },
    data: () => {
      return {
        scope: 'author'
      }
    },
    computed: {
      ...mapGetters({
        completedTasks: 'gettingStarted/completedTasks',
        hasRole: 'gettingStarted/hasRole',
        hideTrainerTasks: 'gettingStarted/hideTrainerTasks'
      }),
      scopeLabel() {
        return this.$t(`getting_started.${this.scope}`)
      },
      scopeTasks() {
        if (this.scope === 'publisher') {
          return PUBLISHER_TASKS;
        } else if (this.scope === 'trainer') {
          return TRAINER_TASKS;
        } else {
          return AUTHOR_TASKS;
        }
      },
      scopeProgress() {
        return this.scopeCompletedTasks.length / this.scopeTasks.length;
      },
      scopeCompletedTasks() {
        return this.scopeTasks.filter(task => this.completedTasks.includes(task));
      }
    },
    methods: {
      changeScope(scope) {
        this.scope = scope;
      },
      completedInScope(scope) {
        if (this.scope === 'publisher') {
          return PUBLISHER_TASKS | this.completedTasks;
        } else if (this.scope === 'trainer') {
          return TRAINER_TASKS | this.completedTasks;
        } else {
          return AUTHOR_TASKS | this.completedTasks;
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .getting-started-task-list {
    display: flex;
    flex-direction: column;
    border-right: 1px solid $black-12-opacity;
    width: 300px;
    box-sizing: content-box;
  }

  .getting-started-task-scope {
    display: flex;
    align-items: center;
    padding: 10px;

    > h3 {
      flex-grow: 1;
      margin: 0;
      font-size: 16px;
      font-weight: 400;
    }
  }

  .getting-started-tasks {
    flex-grow: 1;
  }

  .getting-started-progress {
    padding: 10px;
    border-top: 1px solid $black-12-opacity;

    .progress {
      margin: 0;
    }
  }

  .getting-started-progress-label {
    display: flex;
    margin-bottom: 6px;

    > h3 {
      flex-grow: 1;
      margin: 0;
      font-size: 16px;
    }
  }
</style>
