<template>
  <div class="api-tokens">
    <api-token-list class="api-token-list"/>
    <div class="api-documentation">
      <h3>{{ $t('api_tokens.api_documentation') }}</h3>
      <p>{{ $t('api_tokens.api_documentation_text') }}</p>
      <a :href="documentationUrl" class="btn btn-default btn-flat" target="_blank">
        <i class="material-icons">open_in_new</i>
        <span>{{ $t('api_tokens.api_documentation_open') }}</span>
      </a>
    </div>
  </div>
</template>

<script>
import ApiTokenList from './api_token_list.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    ApiTokenList
  },
  computed: {
    ...mapGetters({
      documentationUrl: 'apiTokens/documentationUrl'
    })
  }
}
</script>

<style lang="scss" scoped>
.api-tokens {
  display: flex;
  align-items: flex-start;
  max-width: 1600px;
}

.api-token-list {
  flex-grow: 1;
}

.api-documentation {
  background-color: $color-grey-10;
  padding: 24px;
  margin-top: 58px;

  h3 {
    font-size: 20px;
    font-weight: 400;
    margin: 0 0 10px 0;
    text-transform: uppercase;
  }

  .btn {
    display: block;
    margin: 0;
  }
}
</style>
