import { t } from '../localization';
export class Multiselect {
  constructor (filterbar, element) {
    this.filterbar = filterbar;
    this.type = element.id;
    this.title = element.title;
  }

  addQuickLink(quick, link) {
    this.initilizePopup(quick);
    this.initilizeKendoMultiSelect(this.fieldSelectWrapper.getElementsByTagName('select')[0], quick);
    link.addEventListener('click', () => {
      this.filterbar.closePopup();
      this.fieldSelect.open();
    });

    this.bindGeneralListener();
    return link;
  }

  initilizePopup(quick) {
    // create Dropdown div
    this.fieldSelectWrapper = document.createElement('div');
    this.fieldSelectWrapper.className = 'analytics-bar-filter';

    this.fieldSelectWrapper.appendChild(this.createSelectContainer(quick));
    this.fieldSelectWrapper.appendChild(this.createDropDownButtons());
    //initialize kendo ui dropdown
    this.fieldSelect = $(this.fieldSelectWrapper).kendoPopup({
      anchor: this.filterbar.element
    }).data('kendoPopup');
  }

  /**
   * creates the container element for
   * the select field
   * @param {Hash} quick 
   */
  createSelectContainer (quick) {
    let container = document.createElement('div');
    container.classList.add('analytics-multiselect-wrapper');
    container.appendChild(this.createHeaderContainer());
    container.appendChild(this.createSelectElement(quick.id));

    return container;
  }

  /**
   * creates the select HTMLElement
   * @param {String} id 
   */
  createSelectElement (id) {
    let select = document.createElement('select');
    select.id = id;

    return select;
  }

  /**
   * creates the container for the popup buttons
   * add the buttons
   */
  createDropDownButtons () {
    let wrapper = document.createElement('div');
    wrapper.classList.add('accept-filter-container');

    wrapper.appendChild(this.createFilterButton());
    
    return wrapper;
  }

  /**
   * creates the container for the popup title
   */
  createHeaderContainer () {
    let wrapper = document.createElement('div');
    wrapper.classList.add('multiselect-header');
    wrapper.appendChild(this.createTitle());

    return wrapper;
  }

  /**
   * creates the HTMLElement for the Popup Title
   */
  createTitle () {
    let header = document.createElement('h4');
    header.innerHTML = t(this.type);

    return header;
  }

  // set css class to filter button an bind click listener
  createFilterButton () {
    // create button element
    let filterButton = document.createElement('button');

    // add some css classes and field attributes
    filterButton.classList.add('k-button');
    filterButton.classList.add('k-primary');
    filterButton.classList.add('accept-filter');
    filterButton.innerHTML = t('acceptFilter');
    filterButton.setAttribute('type', 'submit');

    // add on click listener
    filterButton.addEventListener('click', (e) => {
      this.fieldSelect.close();
    });

    return filterButton;
  }

  /**
   * creates for each selected item the active label
   * removes labels of unselected items
   */
  onPopupClose () {
    let items = this.multiselect.dataItems();
    let labels = [];
    let checkedIds = [];
    items.forEach(item => {
      checkedIds.push(item.id);
      if (document.getElementById(item.uid)) { return; }
      labels.push(this.filterbar.label.createLabelforMultiSelect(item, this.type, this));    
    });
    this.removeUncheckedLabels(checkedIds);
    this.filterbar.addActiveFilters(labels);
  }

  /**
   * Removed the filter labels of uncheck multiselect elements
   * @param {Collection} ids Collection of selected ids
   */
  removeUncheckedLabels(ids) {
    let labels = this.filterbar.activeFilterWrapper.querySelectorAll(`.analytics-filter-label.${this.type}`);
    let mustRemoved = [];
    if (labels.length === 0) { return; }

    mustRemoved = [...labels].filter(label => ids.indexOf(parseInt(label.getAttribute('data-id'), 10)) === -1);

    if (mustRemoved.length > 0) {
      this.filterbar.removeActiveFilters(mustRemoved, false);
    }
  }

  /**
   * unselect option on selectfield by id
   * @param {*} id 
   */
  deactivateFilter(id) {
    let items = this.multiselect.value();
    let index = null;
    items.forEach( item => {
      if (item === id) {
        index = items.indexOf(item);
        return;
      }
    });

    items.splice(index, 1);
    this.multiselect.value(items);
  }

  /**
   * Start point for search function
   * @param {String} searchString 
   */
  search (searchString) {
    return this.findElements(searchString);
  }

  /**
   * find elements by title
   * is necessary for the search function
   * @param {String} query search string
   * @param {Hash} data the Datasource 
   */
  findElements (query, data = { items: this.data }) {
    let nodes = [];
    query = query.toLowerCase();

    if (data.items) {
      for (let i = 0; i < data.items.length; i++) {
        if (data.items[i].title.toLowerCase().indexOf(query) !== -1 &&
          !this.elementIsActive(data.items[i])) {
          nodes.push({ id: data.items[i].id, title: data.items[i].title, source: this });
        }
      }
    }

    return nodes;
  }

  /**
   * check has the selected multiselect option an active 
   * fillter label
   * @param {Hash} element 
   */
  elementIsActive (element) {
    let active = false;
    this.multiselect.value().forEach(value => {
      if (element.id === value) { active = true; }
    });

    return active;
  }

  /**
   * Bind the add Listener for search results
   * @param {HTMLElement} element Quicklink element in dropdown
   */
  bindSearchResultClickListener (element) {
    element.addEventListener('click', (e) => {
      let node = this.multiselect.dataSource.get(e.target.getAttribute('data-id'));
      let label = this.filterbar.label.createLabelforSearchResult(e.target, this.type, this, node.uid);
      let activeElements = this.multiselect.value();
      activeElements.push(node.id);
      this.multiselect.value(activeElements);
      this.filterbar.addActiveFilters([label]);
      this.filterbar.element.value = "";
      this.filterbar.fieldSelect.close();
    });

  }

  /**
   * Initialize the kendo ui multi select and
   * preload the data
   * @param {HTMLElement} select the created select field
   * @param {Hash} quick filter properties
   */
  initilizeKendoMultiSelect (select, quick) {
    this.multiselect = $(select).kendoMultiSelect({
      dataTextField: "title",
      dataValueField: "id",
      placeholder: t('topicarea_multi_placeholder'),
      autoBind: false,
      dataSource: {
        transport: {
          read: (options) => {
            $.ajax({
              url: quick.source,
              dataType: 'json',
              success: (response) => {
                this.data = response;
                options.success(response);
              },
              error: options.error
            });
          }
        }
      }
    }).data("kendoMultiSelect");

    // preload of the data
    this.multiselect.dataSource.read();
  }

  /**
   * Bind general listener for the multiselect
   */
  bindGeneralListener () {
    // tasks to do on popup close
    this.fieldSelect.bind('close', () => {
      this.onPopupClose();
    });
  }
}
