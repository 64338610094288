import { t } from './localization';
import { simpleContainsFilterUi, datepickerFilterUi } from '../grid/configurations/base_column_configuration';

function dateColumnTemplate (fieldName) {
  return `# if (${fieldName}) { #` +
    `<span title="#: kendo.format('{0:F}', ${fieldName}) #">#: kendo.format('{0:D}', ${fieldName}) #</span>` +
  `# } #`;
}

export const columnConfiguration = [
  {
    field: 'title',
    title: t('title'),
    attributes: {
      'class': 'grid-title'
    },
    template:
      `<a href="#: actions.edit #">#: title #</a>` +
      `<p>#: description #</p>`,
    filterable: {
      operators: {
        string: {
          contains: t('contains')
        }
      },
      ui: simpleContainsFilterUi
    },
    hideable: false
  },
  {
    field: 'author',
    title: t('author'),
    attributes: {
      'class': 'grid-author'
    },
    template:
      `<div class="grid-author-wrapper">` +
        `<div class="user-profile-picture" style="background-image: url(#: user.avatar #)"></div>` +
        `<div class="user-info">` +
          `<a title="#: user.username #" href="#: user.show_url #">#: user.username #</a>` +
        `</div>` +
      `</div>`,
    filterable: {  
      operators: {
        string: {
          contains: t('contains')
        }
      },
      ui: simpleContainsFilterUi
    },
    hideable: true,
    media: '(min-width: 800px)'
  },
  {
    field: 'created_at',
    title: t('created_at'),
    width: 250,
    attributes: {
      'class': 'grid-updated'
    },
    format: '{0:D}',
    filterable: {
      extra: false,
      operators: {
        date: {
          eq: t('is_equal_to'),
          gte: t('is_after_or_equal_to'),
          lte: t('is_before_or_equal_to')
        }
      },
      ui: datepickerFilterUi
    },
    hidden: false,
    hideable: true,
    template: dateColumnTemplate('created_at'),
    media: '(min-width: 1200px)'
  },
  {
    field: 'updated_at',
    title: t('updated_at'),
    width: 250,
    attributes: {
      'class': 'grid-updated'
    },
    format: '{0:D}',
    filterable: {
      extra: false,
      operators: {
        date: {
          eq: t('is_equal_to'),
          gte: t('is_after_or_equal_to'),
          lte: t('is_before_or_equal_to')
        }
      },
      ui: datepickerFilterUi
    },
    hidden: false,
    hideable: true,
    template: dateColumnTemplate('updated_at'),
    media: '(min-width: 1200px)'
  },
  {
    field: 'actions',
    title: ' ',
    attributes: {
      'class': 'grid-actions'
    },
    sortable: false,
    filterable: false,
    hideable: false,
    template:
      `<a class="grid-action-show" href="#: actions.show #"><i class="material-icons">open_in_new</i> ${t('action_show')}</a>` +
      `<a class="grid-action-export" href="#: actions.export #"><i class="material-icons">file_download</i> ${t('action_export')}</a>` +
      `<a class="grid-action-edit" href="#: actions.edit #"><i class="material-icons">edit</i> ${t('action_edit')}</a>` +
      `<a class="grid-action-feedback" href="#: actions.feedback #"><i class="material-icons">announcement</i> ${t('action_feedback')}</a>` +
      `<a class="grid-action-delete" href="\\#"><i class="material-icons">delete</i> ${t('action_delete')}</a>`
  }
];
