import { ActionTree } from "vuex";
import { ScreenplayState } from "./state";
import { RootState } from "../../store/types";
import { Mutations } from './mutations';
import axios from 'axios';
import { Section, Scene, ScreenplayStatus, Transition } from "../interfaces";
import Utils from "../utils";
import { successMessage, errorMessage } from '../../mixins/flash_message';
import { Take, Slide } from "../../media_library/interfaces";

export const Actions = {
  SET_INITIAL_DATA: "screenplay/initialData",
  FETCH_WORKSPACE_MEDIA: "screenplay/fetchWorkspaceMedia",
  SET_WORKSPACE_MEDIA_SILENT: "screenplay/setWorkspaceMediaSilent",
  FETCH_SCREENPLAY: "screenplay/fetchScreenplay",
  ADD_TO_WORKSPACE: "screenplay/addToWorkspace",
  REMOVE_FROM_WORKSPACE: "screenplay/removeFromWorkspace",
  SET_SCREENPLAY: "screenplay/setScreenplay",
  SET_SCREENPLAY_SILENT: "screenplay/setScreenplaySilent",
  SET_SCENES: "screenplay/setScenes",
  DELETE_SCENE: "screenplay/deleteScene",
  DELETE_SECTION: "screenplay/deleteSection",
  CREATE_SECTION: "screenplay/createSection",
  CREATE_SCENE: "screenplay/createScene",
  SET_SECTION_TITLE: "screenplay/setSectionTitle",
  SET_SCENE_TITLE: "screenplay/setSceneTitle",
  REPLACE_SCENE_MEDIUM: "screenplay/replaceSceneMedium",
  UPDATE_POSITIONS: "screenplay/updatePositions",
  SAVE_SCREENPLAY: "screenplay/saveScreenplay",
  SET_SCENE_DRAGGING: "screenplay/setSceneDragging",
  SET_WORKSPACE_DRAGGING: "screenplay/setWorkspaceDragging",
  UPDATE_TTS: "screenplay/updateTTS",
  SET_SCREENPLAY_STATUS: "screenplay/setScreenplayStatus",
  SET_SCREENPLAY_STATUS_SILENT: "screenplay/setScreenplayStatusSilent",
  UPDATE_TAKE: "screenplay/updateTake",
  REFRESH_AMOR_ACTION: "screenplay/refreshAmorAction",
  SET_SCREENPLAY_VALID: "screenplay/setScreenplayValid",
  SET_NEEDS_PRODUCTION: "screenplay/setNeedsProduction",
  ADD_DELETED_SCENE: "screenplay/addDeletedScene",
  PROCESS_SECTION_SWTICH_FOR_SCENE: "screenplay/processSectionSwitchForScene",
  SET_TRANSCRIPTION_LANGUAGE: "screenplay/setTranscriptionLanguage",
  SET_SPEAKER_FOR_ALL: "screenplay/setSpeakerForAll",
  REFRESH_TAKE: "screenplay/refreshTake",
  SET_TRANSITION: "screenplay/setTransition"
}

export const actions: ActionTree<ScreenplayState, RootState> = {
  initialData({ commit }, data: {}) {
    commit(Mutations.SET_INITIAL_DATA, data);
  },

  fetchWorkspaceMedia({ state, commit, dispatch }) {
    axios.get(state.apiUrls.getWorkspace)
    .then(response => {
      commit(Mutations.SET_WORKSPACE_MEDIA_SILENT, response.data);

      response.data.forEach(medium => {
        if (medium.type === 'take' && medium.state === 'converting') {
          subscribeProductionJob(dispatch, medium.convert_job);
        }
      });
    })
    .catch(error => {
      console.error(error);
    });
  },

  refreshTake({ state, commit }, takeId: number) {
    axios.get(`${state.apiUrls.takeUrl}?take_id=${takeId}`).then(response => {
      commit(Mutations.UPDATE_TAKE, { amor_id: response.data.amor_id, values: response.data });
    });
  },

  async fetchScreenplay({ state, commit }) {
    await axios.get(state.apiUrls.getScreenplay)
    .then(response => {
      // set screenplay only if it has not been set by CollaborativeAuthoring Action
      commit(Mutations.SET_SCREENPLAY_SILENT, response.data.sections);
      commit(Mutations.SET_TRANSCRIPTION_LANGUAGE, response.data.transcription_language);
      // create section if loaded screenplay is empty
      if (state.screenplay.length === 0) {
        commit(Mutations.CREATE_SECTION);
      }
    })
    .catch(error => {
      console.error(error);
    });
  },

  setScreenplay({ commit }, sections: Array<Section>) {
    commit(Mutations.SET_SCREENPLAY, sections);
  },

  setScreenplaySilent({ commit }, sections: Array<Section>) {
    commit(Mutations.SET_SCREENPLAY_SILENT, sections);
  },

  setWorkspaceMediaSilent({ commit }, sections: Array<Section>) {
    commit(Mutations.SET_WORKSPACE_MEDIA_SILENT, sections);
  },

  setScenes({ commit }, payload: { section_id: number, scenes: Array<Scene> }) {
    commit(Mutations.SET_SCENES, payload);
  },

  addToWorkspace({ state, commit, getters, dispatch }, payload: { slide_ids: Array<number>, take_ids: Array<number>, image_ids: Array<number>, import_to_screenplay: boolean }) {
    axios.post(state.apiUrls.addWorkspaceMedia, {
      slide_ids: payload.slide_ids,
      take_ids: payload.take_ids,
      image_ids: payload.image_ids
    }).then(response => {
      commit(Mutations.ADD_MEDIA, response.data);

      response.data.forEach(medium => {
        if (medium.type === 'take' && medium.state === 'converting') {
          subscribeProductionJob(dispatch, medium.convert_job);
        }
      });

      if (payload.import_to_screenplay) {
        let sectionId = null;

        if (state.screenplay.filter(section => section.deleted !== true).length === 1 && state.screenplay[0].scenes.filter(scene => scene.deleted !== true).length === 0) {
          sectionId = state.screenplay[0].id;
        } else {
          commit(Mutations.CREATE_SECTION);
          sectionId = state.screenplay.slice(-1)[0].id;
        }

        payload.slide_ids.forEach(slideId => {
          const scene = getters.sceneFromWorkspaceItem('slide', slideId);
          commit(Mutations.ADD_SCENE_TO_SECTION, { section_id: sectionId, scene: scene });
        });

        payload.take_ids.forEach(takeId => {
          const scene = getters.sceneFromWorkspaceItem('take', takeId);
          commit(Mutations.ADD_SCENE_TO_SECTION, { section_id: sectionId, scene: scene });
        });

        payload.image_ids?.forEach(imageId => {
          const scene = getters.sceneFromWorkspaceItem('image', imageId);
          commit(Mutations.ADD_SCENE_TO_SECTION, { section_id: sectionId, scene: scene });
        });
      }
      successMessage(VERSTEHE.vueI18n.t('screenplay.videoSuccessfullyAdded'));
    }).catch(error => {
      console.error(error)
      errorMessage(VERSTEHE.vueI18n.t('screenplay.videoAddedFailure'));
    });
  },

  removeFromWorkspace({ state, commit }, payload: { medium_id: number, medium_type: string}) {
    axios.delete(state.apiUrls.removeWorkspaceMedium, {
      params: {
        medium_id: payload.medium_id,
        medium_type: payload.medium_type
      },
      validateStatus: (status): boolean => {
        // Handle 404 as success, since workspace medium is not found
        return (status >= 200 && status < 300) || status === 404;
      },
    }).then(() => {
      commit(Mutations.REMOVE_MEDIUM, { medium_id: payload.medium_id, type: payload.medium_type });
      commit(Mutations.REMOVE_MEDIA_FROM_SCREENPLAY, { medium_id: payload.medium_id, type: payload.medium_type })
    }).catch(error => console.error(error));
  },

  deleteScene({ commit }, payload: { sectionId: number, sceneId: number }) {
    commit(Mutations.DELETE_SCENE, payload);
  },

  deleteSection({ commit }, sectionId: number | string) {
    commit(Mutations.DELETE_SECTION, sectionId);
  },

  createSection({ commit, state }, index: number = null) {
    commit(Mutations.CREATE_SECTION, index);

    // Add empty scene
    const sectionId = index ? state.screenplay[index + 1].id : state.screenplay.slice(-1)[0].id;
    commit(Mutations.CREATE_SCENE, sectionId);
  },

  createScene({ commit }, sectionId) {
    commit(Mutations.CREATE_SCENE, sectionId);
  },

  setSectionTitle({ commit }, payload: { id: number | string, title: string }) {
    commit(Mutations.SET_SECTION_TITLE, payload);
  },

  setSceneTitle({ commit }, payload: {
    sectionId: number | string, sceneId: number | string, title: string }) {
    commit(Mutations.SET_SCENE_TITLE, payload);
  },

  replaceSceneMedium({ commit }, payload) {
    commit(Mutations.REPLACE_SCENE_MEDIUM, payload);
  },

  updatePositions({ commit }) {
    commit(Mutations.UPDATE_POSITIONS);
  },

  async saveScreenplay({ commit, state }) {
    commit(Mutations.UPDATE_POSITIONS);
    commit(Mutations.SET_SCREENPLAY_STATUS, ScreenplayStatus.SAVING);

    return new Promise((resolve, reject) => {
      const serialized = Utils.serializeScreenplay(state.filmId, state.screenplay, state.deletedScenes);

      if (state.transcriptionLanguageRequired) {
        serialized.media_film['transcription_language'] = state.transcriptionLanguage;
      }

      axios.patch(state.apiUrls.updateScreenplay, serialized)
      .then(response => {
        commit(Mutations.SET_SCREENPLAY_ERRORS, {});
        localStorage.removeItem(VERSTEHE.Screenplay.localStorageKey());
        resolve(true);
      })
      .catch(error => {
        if (error.status === 422) {
          commit(Mutations.SET_SCREENPLAY_STATUS, ScreenplayStatus.FAILED);
          commit(Mutations.SET_SCREENPLAY_ERRORS, error.response.data.errors);
        }

        reject();
      });
    });
  },

  setSceneDragging({ commit }, value: boolean) {
    commit(Mutations.SET_SCENE_DRAGGING, value);
  },

  setWorkspaceDragging({ commit }, value: boolean) {
    commit(Mutations.SET_WORKSPACE_DRAGGING, value);
  },

  updateTTS({ commit }, payload) {
    commit(Mutations.UPDATE_TTS, payload);
  },

  setScreenplayStatus({ commit }, value) {
    commit(Mutations.SET_SCREENPLAY_STATUS, value);
  },

  setScreenplayStatusSilent({ commit }, value) {
    commit(Mutations.SET_SCREENPLAY_STATUS_SILENT, value);
  },

  refreshAmorAction({ commit }, payload) {
    commit(Mutations.REFRESH_TAKE, payload);
  },

  updateTake({ commit }, payload) {
    commit(Mutations.UPDATE_TAKE, payload)
  },

  setScreenplayValid({ commit }, value: boolean) {
    commit(Mutations.SET_SCREENPLAY_VALID, value);
  },

  setNeedsProduction({ commit }, value: boolean) {
    commit(Mutations.SET_NEEDS_PRODUCTION, value);
  },

  addDeletedScene({ commit }, payload: { sectionId: number, sceneId: number }) {
    commit(Mutations.ADD_DELETED_SCENE, payload);
  },

  processSectionSwitchForScene({ commit }, payload: { sectionId: EntityId, sceneId: EntityId }) {
    commit(Mutations.PROCESS_SECTION_SWTICH_FOR_SCENE, payload);
  },

  setTranscriptionLanguage({ commit }, language) {
    commit(Mutations.SET_TRANSCRIPTION_LANGUAGE, language);
  },

  setSpeakerForAll({ commit }, speaker) {
    commit(Mutations.SET_SPEAKER_FOR_ALL, speaker);
  },

  setTransition({ commit }, payload: { sectionId: EntityId, transition: Transition}) {
    commit(Mutations.SET_TRANSITION_TO_SECTION, payload);
  }
}

function subscribeProductionJob(dispatch, jobId) {
  VERSTEHE.cable.subscriptions.create({
    channel: 'ProductionChannel',
    job_id: jobId
  },
  {
    received: function(data) {
      if ((data.medium_type === 'Upload::Video') && (data.finished === true)) {
        dispatch('refreshTake', data.take_id);
      }
    }
  });
}
