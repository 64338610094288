// IE does not support modern throwing events. Therefore, a soft one is implemented here
export function fireEvent(element, event) {
  if (document.createEventObject){
    // dispatch for IE
    let evt = document.createEventObject();
    return element.fireEvent(event, evt);
  } else {
    // dispatch for firefox + others
    let evt = document.createEvent("HTMLEvents");
    evt.initEvent(event, true, true ); // event type,bubbling,cancelable
    return !element.dispatchEvent(evt);
  }
}
