import { Film } from './media_types/film';
import { Clip } from './media_types/clip';
import { Slideshow } from './media_types/slideshow';
import { Livestream } from './media_types/livestream';
import { ScreenplayPlugin } from './videojs-plugins/screenplay-plugin';
import { VideoSourcesPlugin } from './videojs-plugins/video-sources-plugin';
import { CoursePlugin } from './videojs-plugins/course-plugin';
import { DownloadPlugin } from './videojs-plugins/download-plugin';
import { SceneMarkerPlugin } from './videojs-plugins/scene-marker-plugin';
// import { QumuSourceHandlerPlugin } from './videojs-plugins/qumu-source-handler';
import { QumuHandler, QumuSourceHandler } from './videojs-plugins/qumu-source-handler';

export let films = [];
export let clips = [];
export let slideshows = [];
export let livestreams = [];

export function registerVjsPlugins() {
  videojs.registerPlugin('screenplay', ScreenplayPlugin);
  videojs.registerPlugin('setupVideoSources', VideoSourcesPlugin);
  videojs.registerPlugin('coursePlugin', CoursePlugin);
  videojs.registerPlugin('downloadPlugin', DownloadPlugin);
  videojs.registerPlugin('sceneMarkerPlugin', SceneMarkerPlugin);

  // Register source handler with vjs Html5 tech
  videojs.getTech('Html5').registerSourceHandler(QumuSourceHandler, 0);

  // Reference to handlers
  videojs.QumuHandler = QumuHandler;
  videojs.QumuSourceHandler = QumuSourceHandler;
}

export function disposePlayer(player_id) {
  $.each(videojs.getPlayers(), function (key, player) {
    if (key === player_id && player !== null) { player.dispose(); }
  });
}

export function initFilm(player_id = 'video-player') {
  let film = new Film(player_id);
  films.push(film);
  return film;
}

export function initClip(player_id = 'video-player') {
  let clip = new Clip(player_id);
  clips.push(clip);
  return clip;
}

export function initSlideshow(slider_id = 'slide-show', options = {}) {
  let slideshow = new Slideshow(slider_id, options);
  slideshows.push(slideshow);
  return slideshow;
}

export function initLivestream(id) {
  const livestream = new Livestream(id);
  livestreams.push(livestream);
  return livestream;
}

export function refreshClip(container_id) {
  // check for update every 30 seconds
  let container_element = document.querySelector(`.clip-viewing[data-clip="${container_id}"]`);
  let url = container_element.getAttribute('data-check-url');

  let refresh = window.setInterval(function() {
    $.ajax({
      url: url,
      type: 'get',
      success: function(result) {
        var $res = $(result);

        if ($res.find('video').length === 1) {
          $(container_element).hide(400).replaceWith($res).show(400);
          window.clearInterval(refresh);
        }
      }
    });
  }, 30000);
}
