<template>
<ol class="playlist-element-list" v-if="isLessonLoaded">
  <li
    v-for="element in playlistElements"
    :key="element.id"
    :class="{ current: currentPlaylistElement === element }"
    @click="setCurrentPlaylistElement(element)">
    <span>{{ element.title }}</span>
  </li>
</ol>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('coursePerform');

export default {
  name: 'playlist-elements',
  props: ['lesson'],
  computed: {
    ...mapGetters([
      'currentPlaylistElement'
    ]),
    isLessonLoaded() {
      return this.lesson.loaded === true;
    },
    playlistElements() {
      return this.lesson.detail.subject.elements;
    }
  },
  methods: {
    ...mapActions(['setCurrentPlaylistElement'])
  }
}
</script>

<style lang="scss">
.playlist-element-list {
  padding: 0 40px;
  background-color: $color-white;
  list-style: decimal inside;

  > li {
    padding: 8px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;

    &.current {
      font-weight: 400;
    }

    > span {
      padding-left: 15px;
    }
  }
}
</style>
