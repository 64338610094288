import Vue from 'vue';
import QuizComponent from './components/quiz_component.vue';
import store from '../../store';
import { QuizManageApp } from '../../quizzes/manage/quiz_manage';
import { Validator } from 'vee-validate';
import { imagemapAreaValidator } from '../../quizzes/manage/validators/imagemap_area';
import { AdditionalLinkAction } from '../../contribution_workflow/interfaces';
import { Getters } from '../../quizzes/manage/store/getters';
import axios from 'axios';
import { serializeQuiz } from '../../quizzes/manage/serializer';
import { flashMessage } from '../../mixins/flash_message';
import { QuizAction } from '../../collaborative_authoring/actions/index'
import { quizSchema } from '../../quizzes/manage/entities';
import { normalize } from 'normalizr';
import { Actions } from '../../quizzes/manage/store/actions';

export class QuizApp {
  public vueApp: Vue;
  public substepRenderer: Vue;
  private rootElement: HTMLElement;
  private searchUrl: string;
  private quizUrl: string;

  constructor(root: HTMLElement) {
    Validator.extend('imagemap', imagemapAreaValidator);
    this.rootElement = root;
    const hasInitial = root.getAttribute('data-has-initial');
    this.searchUrl = root.getAttribute('data-search-quiz-url');
    this.quizUrl = root.getAttribute('data-quiz-url');

    if (hasInitial === 'true') {
      const initialData = JSON.parse(root.getAttribute('data-initial-quiz'));
      this.setQuiz(initialData);
    }

    if (store.state.course.isCollaborative) {
      QuizAction.registerToElement();
    }
    
    this.initVueApp();
  }

  setQuiz(quizData, silent = false) {
    QuizManageApp.initStore(quizData);
    VERSTEHE.ContributionWorkflow.workflow.addSubstep('quiz', 'quiz.general', VERSTEHE.vueI18n.t('quiz.tab_general'));
    VERSTEHE.ContributionWorkflow.workflow.addSubstep('quiz', 'quiz.questions', VERSTEHE.vueI18n.t('quiz.tab_questions'));
    const action: AdditionalLinkAction = { type: 'event', event: 'reset-quiz', icon: 'clear', confirm: null };
    VERSTEHE.ContributionWorkflow.workflow.setCustomAction('quiz', action);
    VERSTEHE.ContributionWorkflow.workflow.on('reset-quiz', this.resetQuiz);
  }

  resetQuiz(silent = false) {
    if (!silent) {
      QuizAction.createAction(QuizAction.getTabId(), _app.info.user.userId, null, null, "resetQuiz");
    }
    VERSTEHE.ContributionWorkflow.workflow.off('reset-quiz', this.resetQuiz);
    VERSTEHE.ContributionWorkflow.workflow.removeSubstep('quiz', 'quiz.general');
    VERSTEHE.ContributionWorkflow.workflow.removeSubstep('quiz', 'quiz.questions');
    store.unregisterModule('quizManage');
    VERSTEHE.ContributionWorkflow.workflow.setCustomAction('quiz', null);
  }

  async saveQuiz() {
    this.vueApp.$root.$emit('validate_observer');
    const generalInvalid = await (this.vueApp.$children[0] as any).generalFormComponent.observer.validate() === false;
    const quizInvalid = store.getters[Getters.QUIZ_INVALID];
    const isNew = store.state.quizManage.isNew;

    if (quizInvalid || generalInvalid) {
      VERSTEHE.ContributionWorkflow.workflow.setErrorStepState('quiz.general', generalInvalid);
      VERSTEHE.ContributionWorkflow.workflow.setErrorStepState('quiz.questions', quizInvalid);
      VERSTEHE.ContributionWorkflow.workflow.activateFirstWithError();

      flashMessage(VERSTEHE.vueI18n.t('quiz.quiz_save_failed'));
      return { success: false }
    } else {
      const url = isNew ? store.state.quizManage.urls.createUrl : store.state.quizManage.urls.updateUrl;

      try {
        const response = await axios({
          method: isNew ? 'post' : 'patch',
          url: url,
          data: serializeQuiz()
        });

        const normalized = normalize(response.data.questions, quizSchema);
        store.dispatch(Actions.UPDATE_ENTITIES, normalized.entities);
        store.dispatch(Actions.SET_QUESTION_IDS, normalized.result);
        store.dispatch(Actions.SET_CURRENT_QUESTION, normalized.result[0]);

        return { success: true, quizId: response.data.id };

      } catch(e) {
        this.vueApp.$root.$emit('validate_observer');
        console.log(e);
        flashMessage(VERSTEHE.vueI18n.t('quiz.quiz_save_failed'));
        return { success: false }
      }
    }
  }

  initVueApp() {
    this.vueApp = new Vue({
      name: 'QuizComponent',
      el: this.rootElement,
      store: store,
      provide: {
        searchUrl: this.searchUrl,
        quizUrl: this.quizUrl
      },
      i18n: VERSTEHE.vueI18n,
      render: h => h(QuizComponent)
    });
  }
}
