export function defer<T = void>(): Deferred<T> {
  let res: Deferred<T>['resolve'];
  let rej: Deferred<T>['reject'];

  const deferred = new Promise((resolve, reject) => {
    res = resolve;
    rej = reject;
  }) as Deferred<T>;

  deferred.resolve = res;
  deferred.reject = rej;

  return deferred;
}