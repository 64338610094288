export default {
  integrationById: (state) => (id) => {
    return state.integrations.find(integration => integration.id === id);
  },
  isLoaded(state) {
    return state.settingsLoaded;
  },
  baseUrl(state) {
    return state.baseUrl;
  },
  hasCategory: (state) => (category) => {
    return state.integrations.some((integration) => integration.category === category);
  },
  hasIntegration: (state) => (id) => {
    return state.integrations.some((integration) => integration.id === id);
  }
};
