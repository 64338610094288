export default {
  status(state) {
    return state.status;
  },

  step(state) {
    return state.step;
  },

  id(state) {
    return state.id;
  },

  info(state) {
    return state.info;
  },

  saving(state) {
    return state.saving;
  },

  containsHeadings(state) {
    return state.containsHeadings;
  },

  importAction(state) {
    return state.importAction;
  },
  
  mappings(state) {
    return state.mappings;
  },

  columnSeperator(state) {
    return state.columnSeperator;
  },
  
  reportUrl(state) {
    return state.reportUrl;
  }
};
