export const locals = {
  de: {
    dialog_confirm_title: 'Sind Sie sicher?',
    dialog_confirm_text: 'Nicht gespeicherte Änderungen werden verworfen.',
    dialog_confirm_ok: 'OK',
    dialog_confirm_cancel: 'Abbrechen',
    dialog_alert_title: 'Fehler',
    dialog_alert_text: 'Es ist ein Fehler aufgetreten',
    dialog_alert_ok: 'OK',
    capture_dialog_title: 'Wählen Sie ein Vorschaubild',
    capture_dialog_ok: 'Aktuelles Bild wählen',
    generic_error: 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.'
  },
  en: {
    dialog_confirm_title: 'Are you sure?',
    dialog_confirm_text: 'Unsaved changes are discarded.',
    dialog_confirm_ok: 'OK',
    dialog_confirm_cancel: 'Cancel',
    dialog_alert_title: 'Error',
    dialog_alert_text: 'An error has occurred.',
    dialog_alert_ok: 'OK',
    capture_dialog_title: 'Select a thumbnail',
    capture_dialog_ok: 'Select current image',
    generic_error: 'Something went wrong. Please try again.'
  },
  es: {
    dialog_confirm_title: '¿Estás seguro de eso?',
    dialog_confirm_text: 'Los cambios no guardados se descartan.',
    dialog_confirm_ok: 'OK',
    dialog_confirm_cancel: 'Cancelar',
    dialog_alert_title: 'Error',
    dialog_alert_text: 'Se ha producido un error.',
    dialog_alert_ok: 'OK',
    capture_dialog_title: 'Seleccione una miniatura',
    capture_dialog_ok: 'Seleccione la imagen actual',
    generic_error: 'Algo ha ido mal. Por favor, inténtelo de nuevo.'
  }
};

export function t(key) {
  return locals[_app.info.currentLocale][key] || key;
}
