export const Mutations = {
  SET_STATE: 'SET_STATE',
  SET_ELEMENTS: 'SET_ELEMENTS',
  SHOW_COMPONENT: 'SHOW_COMPONENT',
  ADD_ELEMENT: 'ADD_ELEMENT',
  REMOVE_ELEMENT: 'REMOVE_ELEMENT',

};


export const mutations = {
  [Mutations.SET_STATE] (state, values) {
    Object.assign(state, values);
  },
  [Mutations.SET_ELEMENTS] (state, values) {
    state.elements = values;
  },
  [Mutations.SHOW_COMPONENT] (state, value) {
    state.showComponent = value;
  },
  [Mutations.ADD_ELEMENT] (state, element) {
    state.elements.push(element);
  },
  [Mutations.REMOVE_ELEMENT] (state, elementId) {
    let element = state.elements.find(element => element.id === elementId);
    element.deleted = true;
    state.elements.splice(state.elements.indexOf(element), 1);
    state.deletedElements.push(element);
  }
};
