<template>
<div class="course-side">
  <div class="course-header navbar top-navbar">
    <div class="navbar-header">
      <a :href="parameters.siteUrl" class="navbar-brand">
        <img height="40" :src="parameters.logoUrl">
      </a>
    </div>

    <a :href="course.overviewUrl" class="course-back">
      <i class="material-icons">chevron_left</i>
      <div class="course-info">
        <div class="course-title" :title="course.title">{{ course.title }}</div>
        <span class="course-back-label">{{ $t('course.back') }}</span>
      </div>
    </a>
  </div>

  <lesson-navigation></lesson-navigation>
</div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('coursePerform');

import LessonNavigation from './lesson_navigation';

export default {
  name: 'course-sidebar',
  components: {
    LessonNavigation
  },
  computed: {
    ...mapGetters([
      'parameters',
      'course'
    ])
  },
}
</script>

<style lang="scss" scoped>
.course-side {
  width: 460px;
  background-color: $color-grey-10;
  overflow-x: hidden;
  overflow-y: auto;
}

.course-header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: auto;
}

.navbar-header {
  float: none;
}

.course-back {
  display: flex;
  position: relative;
  height: 60px;
  flex: 1;
  align-items: center;
  color: #{'RGBA(var(--navbar-foreground-color), .7)'};
  padding: 8px 15px;
  min-width: 300px;
  border-top: 1px solid #{'RGBA(var(--navbar-foreground-color), .15)'};
  margin-top: -1px;

  &:hover {
    color: #{'RGB(var(--navbar-foreground-color))'};
  }

  > i {
    font-size: 32px;
    margin-right: 6px;
  }

  .course-info {
    display: flex;
    flex: 1 0;
    flex-direction: column;
  }

  .course-title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    max-height: 32px;
    overflow: hidden;
  }

  .course-back-label {
    font-size: 12px;
    line-height: 1;
  }
}

.course-nav {
  padding: 15px 6px;

  .lesson-group {
    background-color: $color-white;
  }
}
</style>
