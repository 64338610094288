import { BaseTree } from '../../taxonomy/base_tree';

/**
 * This class represents the tree filter for the custom meta data
 * in the coovi grid component
 * Note, the topic area filter have a separate class.
 */
export class BaseCustomMetaDataTree extends BaseTree {
  /**
   * To initalize the class pass the params uuif, type and grid to the constructor
   * @param {String} uuid find on the html tree container as data-uuid
   * @param {String} type find on the html tree container as data-type
   * @param {Grid} grid the initilazied grid component
   */
  constructor (uuid, type, grid, treeType) {
    super(uuid, type, null, treeType);
    this.grid = grid;
    this.gridField = type;
  }

  /**
   * Sets the active filters on the datasource after a reload
   * @param {*} elements The selected items on the tree
   */
  setSelectedElementsHelper(elements) {
    this.ids = elements.map(v => v.id);

    let activeFilters = [];

    if (this.grid.grid.dataSource.filter()) {
      activeFilters = this.grid.grid.dataSource.filter().filters.filter((filter) => {
        return filter.field !== this.gridField;
      });
    }

    if(this.ids.length > 0) {
      activeFilters.push({
        field: this.gridField,
        operator: "contains",
        value: this.ids.join(",")
      });
    }

    this.grid.grid.dataSource.filter(activeFilters);
  }

  initializeTreeActions () {
    if (this.treeType !== 'Metadata::SetField') {
      super.initializeTreeActions();
    }
  }
}
