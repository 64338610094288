import { v4 as uuid } from 'uuid';
import { State } from '@uppy/core';

export interface CustomState extends State {
  paused: boolean;
}

export const state: CustomState = {
  currentUploads: {},
  files: {},
  totalProgress: 0,
  paused: false
}
