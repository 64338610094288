export class SearchMatchTranscript {
  private containerElement: HTMLDivElement;
  private buttonElement: HTMLAnchorElement;
  private hitsContainerElement: HTMLDivElement;
  private showMoreText: HTMLSpanElement;
  private showLessText: HTMLSpanElement;
  private expanded: boolean = false;
  
  constructor(selector: string) {
    this.containerElement = document.querySelector(selector);
    this.hitsContainerElement = this.containerElement.querySelector('.hits');
    this.buttonElement = this.containerElement.querySelector('.show-more-button');
    this.showMoreText = this.buttonElement.querySelector('.more');
    this.showLessText = this.buttonElement.querySelector('.less');
    this.initializeExpandButton();
  }

  private initializeExpandButton(): void {
    this.buttonElement.addEventListener('click', (e) => {
      e.preventDefault();

      this.expanded = !this.expanded;

      if (this.expanded) {
        this.hitsContainerElement.classList.remove('hidden');
        this.showMoreText.classList.add('hidden');
        this.showLessText.classList.remove('hidden');
      } else {
        this.hitsContainerElement.classList.add('hidden');
        this.showLessText.classList.add('hidden');
        this.showMoreText.classList.remove('hidden');
      }
    });
  }
}
