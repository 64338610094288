import { nanoid } from 'nanoid';
import type { IDBPDatabase } from 'idb';
import type BackupStoreDB from './types/backup_store_db';
import store from './store';
import logger from '../logger/logger';
import { ResourceType } from '../common/resource_enum';

export default class Backup {
  private static readonly HEARTBEAT_INTERVAL = 3000;

  public readonly id: string;
  private readonly db: IDBPDatabase<BackupStoreDB>;
  private intervalHeartbeat?: number;
  private isStopped = false;

  public static async createInDB(db: IDBPDatabase<BackupStoreDB>, recordingType: ResourceType): Promise<Backup> {
    const recordingId = nanoid();

    await db.add('recordings', {
      id: recordingId,
      heartbeat: Date.now(),
      userId: store.state.appData!.userId,
      siteId: store.state.appData!.siteId,
      recordingType,
      duration: 0,
    });

    return new Backup(recordingId, db);
  }

  constructor(id: string, db: IDBPDatabase<BackupStoreDB>) {
    this.id = id;
    this.db = db;

    this.startHeartbeat();
  }

  private async update(changes: Partial<BackupStoreDB['recordings']['value']>): Promise<void> {
    try {
      const tx = this.db.transaction('recordings', 'readwrite');
      const recoding = await tx.store.get(this.id);
      await tx.store.put({ ...recoding, ...changes } as BackupStoreDB['recordings']['value']);
      await tx.done;
    } catch (e) {
      logger.error({
        message: 'Failed to update backup',
        error: e as Error,
        section: 'backup:update',
        data: {
          recordingId: this.id,
        },
      });
    }
  }

  private startHeartbeat(): void {
    const updateHeartbeat = async () => this.update({ heartbeat: Date.now() });

    updateHeartbeat();

    this.intervalHeartbeat = window.setInterval(updateHeartbeat, Backup.HEARTBEAT_INTERVAL);
  }

  public async addChunk(data: Blob, currentDuration: number): Promise<void> {
    if (this.isStopped) {
      return;
    }

    try {
      await this.db.add('chunks', { recordingId: this.id, data });

      await this.update({
        duration: currentDuration,
      });
    } catch (e) {
      if (e instanceof Error) {
        if (e.name === 'QuotaExceededError') {
          const quota = await navigator.storage.estimate();

          logger.warn({
            message: 'backup stopped: no free disc space',
            error: e as Error,
            section: 'backup:update',
            data: {
              quota,
            },
          });

          this.isStopped = true;
        } else {
          logger.error({
            message: 'Failed to add chunk to backup',
            error: e as Error,
            section: 'backup:addChunk',
            data: {
              recordingId: this.id,
              hasData: !!data,
              currentDuration,
            },
          });
        }
      }
    }
  }

  public getChunksCount(): Promise<number | null> {
    try {
      return this.db.countFromIndex('chunks', 'byRecordingId', this.id);
    } catch (e) {
      logger.error({
        message: 'Failed to load chunks count',
        error: e as Error,
        section: 'backup:getChunksCount',
        data: {
          recordingId: this.id,
        },
      });

      return Promise.resolve(null);
    }
  }

  public async getChunks(): Promise<Blob[] | null> {
    try {
      const chunks = await this.db.getAllFromIndex('chunks', 'byRecordingId', this.id);

      return chunks.map((entry) => entry.data);
    } catch (e) {
      logger.error({
        message: 'Failed to load chunks',
        error: e as Error,
        section: 'backup:getChunks',
        data: {
          recordingId: this.id,
        },
      });

      return null;
    }
  }

  public async getRecordingType(): Promise<ResourceType> {
    try {
      const backup = await this.db.get('recordings', this.id);

      return backup?.recordingType ?? ResourceType.Unknown;
    } catch (e) {
      logger.error({
        message: 'Failed to load recordingType',
        error: e as Error,
        section: 'backup:getRecordingType',
        data: {
          recordingId: this.id,
        },
      });

      return ResourceType.Unknown;
    }
  }

  public async getDuration(): Promise<number> {
    try {
      const backup = await this.db.get('recordings', this.id);

      return backup?.duration ?? 0;
    } catch (e) {
      logger.error({
        message: 'Failed to load duration',
        error: e as Error,
        section: 'backup:getDuration',
        data: {
          recordingId: this.id,
        },
      });

      return 0;
    }
  }

  public async setUploadFileName(uploadFileName: string): Promise<void> {
    await this.update({ uploadFileName });
  }

  public async getUploadFileName(): Promise<string | null> {
    try {
      const backup = await this.db.get('recordings', this.id);

      return backup?.uploadFileName || null;
    } catch (e) {
      logger.error({
        message: 'Failed to load uploadFileName',
        error: e as Error,
        section: 'backup:getUploadFileName',
        data: {
          recordingId: this.id,
        },
      });

      return null;
    }
  }

  public async destroy(): Promise<void> {
    try {
      window.clearInterval(this.intervalHeartbeat);

      await this.db.delete('recordings', this.id);

      const { store } = this.db.transaction('chunks', 'readwrite');
      const index = store.index('byRecordingId');

      let cursor = await index.openCursor(this.id);
      while (cursor) {
        cursor.delete();
        cursor = await cursor.continue();
      }
    } catch (e) {
      logger.error({
        message: 'Failed to destroy backup',
        error: e as Error,
        section: 'backup:destroy',
        data: {
          recordingId: this.id,
        },
      });
    }
  }
}
