import { ChangeAction } from "../../../system_actions/change_action";

let screenplayChangeWhitelist = [
  "screenplay/SET_SCENE_TITLE",
  "screenplay/SET_SCREENPLAY",
  "screenplay/CREATE_SCENE",
  "screenplay/SET_SCENES",
  "screenplay/DELETE_SCENE",
  "screenplay/CREATE_SECTION",
  "screenplay/DELETE_SECTION",
  "screenplay/SET_SECTION_TITLE",
  "screenplay/REPLACE_SCENE_MEDIUM",
  "screenplay/UPDATE_POSITIONS",
  "screenplay/UPDATE_TTS"
];

let screenplaySaveWhitelist = [
  "screenplay/SET_SCREENPLAY_STATUS"
];

let workspaceFetchWhitelist = [
  "screenplay/ADD_MEDIA",
  "screenplay/REMOVE_MEDIUM"
];

let workspaceChangeWhitelist = [
  "screenplay/UPDATE_TAKE",
  "screenplay/REFRESH_TAKE"
];

/**
 * ScreenplayActions handle tall changes that occur in the screenplay step
 */
export class ScreenplayAction extends ChangeAction {
  /**
   * @constructor
   * @param {string} tabId  - tabId of the tab the action originates from
   * @param {number} user - userId of the chat room owner
   * @param {String} element - element thats subject of the ChangeAction
   * @param {number} data - data (state of the vue sore or other payload)
   * @param {String} type - type of the ScreenplayAction ("screenplay/change", "screenplay/save", "workspace/fetch" and "workspace/change")
   */
  constructor(tabId, user, element, data, type) {
    super(tabId, user, element);
    this.data = data;
    this.type = type;
    this.actionType = "ScreenplayAction";
  }

  execute() {
    if (this.type === "screenplay/change") {
      VERSTEHE.Screenplay.screenplayApp.$store.dispatch("screenplay/setScreenplaySilent", this.data);
    }
    if (this.type === "screenplay/save") {
      VERSTEHE.Screenplay.screenplayApp.$store.dispatch("screenplay/setScreenplayStatusSilent", this.data);
    }
    if (this.type === "workspace/fetch") {
      VERSTEHE.Screenplay.screenplayApp.$store.dispatch("screenplay/fetchWorkspaceMedia", this.data);
    }
    if (this.type === "workspace/change") {
      VERSTEHE.Screenplay.screenplayApp.$store.dispatch("screenplay/setWorkspaceMediaSilent", this.data);
    }
  }

  isComparableTo(other) {
    return this.type === other.type;
  }

  reduce(other) {
    return this;
  }

  static fromJson(json) {
    let action = JSON.parse(json);
    return new ScreenplayAction(action.tabId, action.user, action.elementLocation, action.data, action.type);
  }

  static registerToElement() {
    if(VERSTEHE.Screenplay.screenplayApp) {
      VERSTEHE.Screenplay.screenplayApp.$store.subscribe((mutation, state) => {
        if (screenplayChangeWhitelist.includes(mutation.type)) {
          ScreenplayAction.createAction(ScreenplayAction.getTabId(), _app.info.user.userId, null, state.screenplay.screenplay, "screenplay/change");
        }
        if (screenplaySaveWhitelist.includes(mutation.type) && mutation.payload !== "UNSAVED") {
          ScreenplayAction.createAction(ScreenplayAction.getTabId(), _app.info.user.userId, null, mutation.payload, "screenplay/save");
        }
        if (workspaceFetchWhitelist.includes(mutation.type)) {
          ScreenplayAction.createAction(ScreenplayAction.getTabId(), _app.info.user.userId, null, null, "workspace/fetch");
        }
        if (workspaceChangeWhitelist.includes(mutation.type)) {
          ScreenplayAction.createAction(ScreenplayAction.getTabId(), _app.info.user.userId, null, state.screenplay.workspace, "workspace/change");
        }
      });
    }
  }

  static createAction(tabId, user, element, data, type) {
    let action = new ScreenplayAction(tabId, user, element, data, type);
    VERSTEHE.content_channel.send_message(action);
  }
}
