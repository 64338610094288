export class DragAndDropHelper {
  private focusOptionId: EntityId = null;

  constructor() { }

  getFocusOptionId(): EntityId {
    return this.focusOptionId;
  }

  setFocusOptionId(value: EntityId): void {
    this.focusOptionId = value;
  }
}
