import { TargetGroupSelect } from './target_group_select';

export class ContributionGeneral {
  targetGroupSelect: TargetGroupSelect;
  public metadataSetFields: {} = {};
  
  init() {
    this.targetGroupSelect = new TargetGroupSelect();
  }

  addSetField(fieldId, select) {
    this.metadataSetFields[fieldId] = select;
  }

  getSetFieldById(fieldId) {
    return this.metadataSetFields[fieldId];
  }
}

export default new ContributionGeneral();
