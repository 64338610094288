export class TransitUserContent {
  constructor() {
    this.targetUser = null;

    $(document).on('show.bs.modal', '#transit-content-modal', (event) => {   
      this.registerEventListeners();
      $.material.init();
    });

    $(document).on('hidden.bs.modal', '#transit-content-modal', (event) => {
      this.unbindEventListeners();

    });
  }

  // ------ Getter ------
  /**
   * Get all selected ContentTypes from Modal as an Array
   */
  get selectedContentTypes() {
    return $('#transit-content-modal .checkbox input:checked').map((i, el) => $(el).data('selected-content-type')).toArray();
  }

  /**
   * Get all offered ContentTypes as an Array
   */
  get offeredContentTypes() {
    return $('#transit-content-modal .checkbox input').map((i, el) => $(el).data('selected-content-type')).toArray();
  } 

  /**
   * Get the selected target User
   * @return email
   */
  get selectedUser() {
    return this.targetUser;
  }

  /**
   * Get the source User of the ContentTypes
   * @return user_id
   */
  get user() {
    return $('#transit-content-modal').data('user');
  }

  /**
   * Check if the selection is valid
   * @return boolean
   */
  get isValid() {
    return this.selectedContentTypes.length > 0 && this.selectedUser !== null;
  }

  /**
   * Retrun the default Selectize user options
   */
  get defaultOptions() {
    return [
      {
        id: _app.info.user.userId,
        email: _app.info.user.userEmail,
        username: _app.info.user.userName
      }
    ];
  }

  // ------ EventListeners ------
  /**
   * EventListener registration function
   */
  registerEventListeners() {
    if (this.offeredContentTypes.length > 0) {
      this.registerSubmitAction();
      this.registerSelectUser();
      this.registerContentCahange();  
    }
  }

  /**
   * Cancle all EventListeners
   */
  unbindEventListeners() {
    $(document).off('click', '#submit-transit-content');
    $(document).off('change', '#transit-content-modal .checkbox input, #select_user');
  }

  /**
   * Register the EventListener for Form submit
   * If the Form are valid an ajax PATCH request are called for submitting the data
   */
  registerSubmitAction() {
    $(document).on('click', '#submit-transit-content', (e) => {
      e.preventDefault();
      if (this.isValid) {
        $('#submit-transit-content').addClass('disabled');
        $.ajax({
          type: "PATCH",
          url: $('#transit-content-modal').data('post-url'),
          data: {
            content_type: this.selectedContentTypes,
            target_user: this.selectedUser
          },
          success: (data) => {
            $('#submit-transit-content').removeClass('disabled');
            $('#transit-content-modal').modal('hide');
          }
        });
      }
    });
  }

  /**
   * Register the EventListener for checking if the form is valid and enabling/disabling the submit button
   */
  registerContentCahange() {
    $('#transit-content-modal .checkbox input, #select_user').change(() => {
      if (this.isValid) {
        $('#submit-transit-content').removeClass('disabled');
      } else {
        if (!$('#submit-transit-content').hasClass('disabled')) {
          $('#submit-transit-content').addClass('disabled');
        }
      }
    });
  }

  /**
   * Initialize User selectize
   */
  registerSelectUser() {
    let select = $('#select_user').selectize({
      persist: false,
      plugins: ['remove_button', 'clear_button'],
      valueField: 'email',
      maxItems: 1,
      labelField: 'username',
      searchField: ['username', 'email'],
      onChange: (value) => this.targetUser = value,
      options: this.defaultOptions,
      load: (q, callback) => {
        !q.length ? callback() : 
          $.ajax({
            url: $('#select_user').data('url'),
            type: 'GET',
            dataType: 'json',
            data: { q },
            error: () => callback(),
            success: (res) => {
              res = res.filter((data) => data.id !== this.user);
              callback(res);
            }
          });
      }
    });
    if (this.user === _app.info.user.userId) {
      $('#select_user')[0].selectize.clearOptions();
    } else {
      $('#select_user')[0].selectize.setValue([_app.info.user.userEmail]);
    }
  }
}
