type Log = {
  level: 'WARN' | 'ERROR',
  error?: Error,
  message: string,
  section?: string,

  siteId?: number,
  userId?: number,
  data?: { [key: string]: unknown }
}
/**
 * source: the source of the logs (app/models/exception_log.rb -> :source)
 * catchGlobalErrors: [default: true] enables logging of global errors and promise rejections
 * logToConsole: [default: false] enables logging copies of all logs to the console
 */
type Config = {
  source: 'coovi_capture' | 'coovi_web_conferences',
  catchGlobalErrors?: boolean,
  logToConsole?: boolean,
  defaults?: Omit<Log, 'level' | 'message' | 'error'>
}

let config: Config | undefined = undefined;

function init(configuration: Config) {
  config = {
    catchGlobalErrors: true,
    logToConsole: false,
    defaults: {},
    ...configuration,
  };

  if (config.catchGlobalErrors) {
    setGlobalListener();
  }
}

function setDefaults(defaults: Config['defaults']): void {
  if (config === undefined) {
    console.error('[LOGGER] logger not initialized');
    return;
  }

  config.defaults = defaults;
}

async function warn(log: Omit<Log, 'level'>): Promise<boolean> {
  return send({
    level: 'WARN',
    ...log,
  });
}

async function error(log: Omit<Log, 'level'>): Promise<boolean> {
  return send({
    level: 'ERROR',
    ...log,
  });
}

async function send(log: Log): Promise<boolean> {
  try {
    if (config === undefined) {
      console.error('[LOGGER] logger not initialized');
      return false;
    }

    const data = {
      ...config.defaults,
      ...log,
      userAgent: navigator.userAgent,
      url: window.location.href,
      error: {
        name: log.error?.name,
        message: log.error?.toString(),
        stack: log.error?.stack,
      },
      date: Date.now(),
    };

    if (config.defaults.data && log.data) {
      data.data = {
        ...config.defaults.data,
        ...log.data,
      };
    }

    if (config.logToConsole && data.level === 'WARN') {
      console.warn('[LOGGER]: ', data);
    } else if (config.logToConsole && data.level === 'ERROR') {
      console.error('[LOGGER]: ', data);
    }

    const message = JSON.stringify(data, null, 2);

    const logfile = new Blob([message], { type: 'text/plain' });
    const formData: FormData = new FormData();
    formData.set('file', logfile, 'log.txt');
    formData.set('source', config.source);

    const res = await fetch(`https://${window.location.host}/exception_logs`, {
      method: 'POST',
      body: formData,
    });

    return res.ok;
  } catch (e) {
    console.error('[SEND_LOG] ', e);
    return false;
  }
}

function setGlobalListener() {
  window.addEventListener('error', (event) => {
    error({
      message: 'Caught global error',
      error: event.error,
      section: 'index:onerror',
    });
  });

  window.addEventListener('unhandledrejection', (event) => {
    error({
      message: 'Caught global error ' + event.reason,
      error: event.reason instanceof Error ? event.reason : undefined,
      section: 'index:onunhandledrejection',
    });
  });
}

export default {
  init,
  setDefaults,
  warn,
  error,
};