<template>
  <div>
     <div class="alert alert-notice">
      <i class="notice-icon material-icons">info_outline</i>
      <div>
        <strong>{{ $t('user_import.import_conditions') }}</strong>
        <ul>
          <li>{{ $t('user_import.import_conditions_file_format') }}</li>
          <li>{{ $t('user_import.import_conditions_required_fields') }}</li>
          <li>{{ $t('user_import.import_conditions_user_confirmation') }}</li>
          <li>{{ $t('user_import.import_conditions_maximum_rows') }}</li>
        </ul>
      </div>
    </div>
    <div class="form-group" :class="{ 'has-error': status === 'invalid' }">
      <label for="user_import_file" class="control-label">{{ $t('user_import.file') }}</label>
      <div>
        <input type="text" readonly="" class="form-control" :placeholder="$t('user_import.select_file')">
        <input type="file" id="user_import_file" @change="onChange">
      </div>
      <p v-if="status === 'invalid'" class="help-block">{{ $t('user_import.invalid_file') }}</p>
    </div>

    <div v-show="isCSV">
      <label class="control-label">{{ $t('user_import.column_seperator') }}</label>
      <select ref="columnSeperatorSelect" class="user-import-csv-seperator" :value="columnSeperator">
        <option value="semicolon">{{ $t('user_import.column_seperator_semicolon') }}</option>
        <option value="comma">{{ $t('user_import.column_seperator_comma') }}</option>
        <option value="tab">{{ $t('user_import.column_seperator_tabulator') }}</option>
      </select>
    </div>

    <button class="btn btn-primary" :class="{ disabled: !file || saving }" @click="upload">{{ $t('user_import.upload') }}</button>
  </div>
</template>

<script>
import { Actions } from '../store';
import { mapGetters, mapActions } from 'vuex';

export default {
  data: () => {
    return {
      file: null
    }
  },
  computed: {
    ...mapGetters({
      status: 'userImport/status',
      saving: 'userImport/saving',
      columnSeperator: 'userImport/columnSeperator'
    }),
    isCSV() {
      return this.file && /\.csv$/.test(this.file.name);
    }
  },
  methods: {
    ...mapActions({
      uploadFile: Actions.UPLOAD_FILE
    }),
    onChange(e) {
      this.file = e.target.files[0];
    },
    upload() {
      this.uploadFile(this.file);
    }
  },
  mounted() {
    $.material.init();

    $(this.$refs.columnSeperatorSelect).kendoDropDownList({
      change: (event) => {
        const value = event.sender.value();
        this.$store.commit('userImport/UPDATE_COLUMN_SEPERATOR', value);
      }
    });
  }
}
</script>

<style lang="scss" scoped>
.alert-notice {
  display: flex;

  > .notice-icon {
    flex-shrink: 1;
  }

  ul {
    margin: 8px 0 0;
    padding: 0 0 0 15px;
  }

  li {
    margin-bottom: 4px;
  }
}

.control-label {
  display: block;
}
</style>
