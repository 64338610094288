export default {
  parameters: (state) => state.parameters,
  course: (state) => state.course,
  currentLesson: (state, getters, rootState) => {
    const currentLessonId = rootState.route.params.lessonId;
    return getters.lessonById(currentLessonId);
  },
  lessonGroups: (state) => state.course.lessonGroups,
  lessonById: (state) => (lessonId) => {
    lessonId = parseInt(lessonId, 10);

    for (let i = 0; i < state.course.lessonGroups.length; i++) {
      const lessonGroup = state.course.lessonGroups[i];

      for (let x = 0; x < lessonGroup.lessons.length; x++) {
        if (lessonGroup.lessons[x].id === lessonId) {
          return lessonGroup.lessons[x];
        }
      }
    }
  },
  currentPlaylistElement: (state) => state.currentPlaylistElement,
  lessonProgress: (state) => (lesson) => {
    if (lesson.type === 'Course::Lesson::PlaylistLesson' && lesson.loaded) {
      const playlistElements = lesson.detail.subject.elements;
      const totalProgress = playlistElements.map(e => e.progress).reduce((acc, cur) => acc + cur);

      return totalProgress / playlistElements.length;
    } else {
      return lesson.progress;
    }
  },
  completedCourse: (state) => state.course.completedAt,
  needConfirmation: (state) => {
    return state.course.needsConfirmation && state.course.completedAt && !state.course.confirmedAt;
  },
  canEvaluate: (state) => {
    return state.course.completedAt && state.course.evaluation && !state.course.evaluationFinished;
  }
};
