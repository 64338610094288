import { BaseTree } from '../../taxonomy/base_tree';

export class TopicAreaTree extends BaseTree {
  setSelectedElementsHelper(elements) {
    this.ids = elements.map(v => v.id);

    let activeFilters = [];

    if (VERSTEHE.ContributionGrid.grid.dataSource.filter()) {
      activeFilters = VERSTEHE.ContributionGrid.grid.dataSource.filter().filters.filter(function (filter) {
        return filter.field !== "topic_areas";
      });
    }

    if(this.ids.length > 0) {
      activeFilters.push({
        field: "topic_areas",
        operator: "contains",
        value: this.ids.join(",")
      });
    }

    VERSTEHE.ContributionGrid.grid.dataSource.filter(activeFilters);
  }
}
