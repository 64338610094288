import { Busy } from '../../globals/busy';
import { Renderer } from './renderer';
import { Messager } from './messager';

export class Embed {
  constructor() {
    this.chartArea = document.querySelector(".chart-area");
    this.url = this.chartArea.getAttribute('data-url');
    this.chartId = this.chartArea.getAttribute('data-id');
    this.chartType = this.chartArea.getAttribute('data-type');
    this.from = this.chartArea.getAttribute('data-from');
    this.height = this.chartArea.getAttribute('data-height');
    this.width = this.chartArea.getAttribute('data-width');
    this.loader = new Busy();
    this.activeLoader = false;
    this.renderer = new Renderer({'type': this.chartType, 'selector': "chart-area", 'width': this.width, 'height': this.height});
    this.messager = new Messager(this.chartArea);
  }

  initialize() {
    this.addLoader();
    this.dataQuery();
  }

  addLoader() {
    if(!this.activeLoader) {
      this.chartArea.append(this.loader.loader);
      this.activeLoader = true;
    }
  }

  removeLoader() {
    if(this.activeLoader) {
      this.chartArea.removeChild(this.loader.loader);
      this.activeLoader = false;
    }
  }

  dataQuery() {
    $.ajax({
      url: this.url,
      type: 'GET',
      data: {
        id: this.chartId,
        from: this.from
      }
    })
    .done(function(data) {
      this.removeLoader();
      if (data === null) {
        this.messager.renderMessage('noResults');
      } else if (data["disabled"] === true) {
        this.messager.renderMessage('disabled');
      } else {
        this.renderer.render(data);
      }     
    }.bind(this))
    .fail(function() {
      this.removeLoader();
      this.messager.renderMessage('errorMessage');
    }.bind(this));
  }
}
