<template>
  <div>
    <h3>{{ categoryName }}</h3>
    <div class="tutorials-video-list">
      <tutorial-video
        v-for="video in videos"
        :key="video.id"
        :video="video">
      </tutorial-video>
    </div>
  </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex';
  const { mapActions, mapGetters } = createNamespacedHelpers('tutorials');

  import TutorialVideo from './tutorial_video.vue';

  export default {
    components: {
      TutorialVideo
    },
    props: ['id'],
    computed: {
      ...mapGetters([
        'categoryById',
        'videosByCategoryId',
        'isCategoryVisible'
      ]),
      categoryName() {
        const category = this.categoryById(this.id);

        if (category) {
          return category.name;
        }
      },
      videos() {
        if (this.isCategoryVisible(this.id)) {
          return this.videosByCategoryId(this.id);
        }

        return [];
      }
    }
  }
</script>

<style lang="scss" scoped>
  .tutorials-video-list {
    display: flex;
    flex-wrap: wrap;
    max-width: 1010px; /* max 5 videos per row */
  }
</style>
