import Vue from 'vue';
import App from './components/app.vue';
import store from '../store';
import userImport from './store';
import { Actions } from './store';

export let app = null;
export let dialog = null;

export function init() {
  const button = document.getElementById('import-users');

  if (button) {
    const el = document.createElement('div');
    const uploadUrl = button.dataset.upload;
    const modalTitle = button.textContent;

    store.registerModule('userImport', userImport);
    store.dispatch(Actions.SET_UPLOAD_URL, uploadUrl);

    app = new Vue({
      store,
      el,
      i18n: VERSTEHE.vueI18n,
      render: h => h(App)
    });

    // Initialize dialog
    dialog = $(app.$el).kendoDialog({
      width: '750px',
      closable: true,
      modal: true,
      title: modalTitle,
      actions: []
    }).data('kendoDialog');

    button.addEventListener('click', () => {
      dialog.open();
    });
  }
}
