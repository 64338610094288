// Apply a given html as field html
import { localization } from '../locals';

export let applyHtml = (_this, html, field_title) => {
  _this.showTreeWindow();
  $("#manage-taxonomy-view .field-form").html(html);
  $("#manage-taxonomy-view .current-edit").html(localization[_app.info.currentLocale]['edit_headline'] + field_title);
  $('#metadata_contribution_types').selectize({ plugins: ['remove_button', 'clear_button'] });

  $.material.checkbox();
};