const Plugin = videojs.getPlugin('plugin');

export class VideoSourcesPlugin extends Plugin {
  constructor(player, options) {
    super(player, options);

    let sources = [], playableSources = [];

    try {
      sources = JSON.parse(this.player.el().getAttribute('data-sources'));
    } catch(e) {}


    for (let i = 0; i < sources.length; i++) {
      if (this.player.canPlayType && this.player.canPlayType(sources[i].type)) {
        playableSources.push(sources[i]);
      }
    }

    // Sort by resolution, then codec
    const typeOrder = ['video/mp4', 'video/webm', 'video/ogv'];

    playableSources = playableSources.sort((a, b) => {
      if (a.res === b.res) {
        return typeOrder.indexOf(a.type) - typeOrder.indexOf(b.type);
      }

      return b.res - a.res;
    });

    // Only use one codec per resolution
    playableSources = playableSources.filter((element, index) => {
      return playableSources.findIndex(s => s.res === element.res) === index;
    });

    if (playableSources.length > 0) {
      // Select the first source by default
      playableSources[0].selected = true;
    }

    player.src(playableSources);
    player.load();
  }
}
