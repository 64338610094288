const Plugin = videojs.getPlugin('plugin');
const Button = videojs.getComponent('Button');

const buttonLabel = {
  de: 'Video herunterladen',
  en: 'Download video',
  es: 'Descargar video'
};

export class DownloadPlugin extends Plugin {
  constructor(player, options) {
    super(player, options);

    videojs.registerComponent('DownloadBtn', DownloadBtn);

    // Place download button in ControlBar
    const index = player.controlBar.children_.length - 3;
    this.button = player.controlBar.addChild('DownloadBtn', { parent_plugin: this }, index);
  }
}

class DownloadBtn extends Button {
  constructor(player, options) {
    super(player, options);
    this.plugin = options.parent_plugin;

    this.el().innerHTML = '<i class="material-icons">file_download</i>';
    this.el().classList.add('vjs-download-button');

    this.controlText(buttonLabel[_app.info.currentLocale]);
  }

  downloadFile(url, filename, mimeType) {
    this.el().classList.add('vjs-disabled');

    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';

    xhr.onload = (e) => {
      const blob = new Blob([e.target.response], { type: mimeType });
      const dataUrl = URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = dataUrl;
      anchor.setAttribute('download', filename);
      anchor.style.display = 'none';
      document.body.appendChild(anchor);

      setTimeout(() => {
        anchor.click();
        document.body.removeChild(anchor);
        setTimeout(() => URL.revokeObjectURL(anchor.href));
        this.el().classList.remove('vjs-disabled');
      }, 0);
    };

    xhr.send();
  };

  handleClick() {
    if (this.el().classList.contains('vjs-disabled')) {
      return;
    }

    let videoSource = this.player().currentSource();

    // Set another available source if "Auto" Quality is selected.
    if (['application/dash+xml', 'application/x-mpegURL'].indexOf(videoSource.type) > -1) {
      videoSource = this.player().currentSources()[0];
    }

    const extension = videoSource.type.split('/')[1];
    let file = videoSource.src.split('/').pop().split('?')[0];

    if (file.indexOf(extension) < 0) {
      file += `.${extension}`;
    }

    // Trigger download of video source via JavaScript
    this.downloadFile(videoSource.src, file, videoSource.type);
  }
}
