import { ChangeAction } from "../../system_actions/change_action";

/**
 * MultiSelectActions track the changes of Kendo UI Multiselect
 * Multiple MultiSelectAction can be reduced to one when they regard the same element
 */
export class MultiSelectAction extends ChangeAction {
  // /**
  //  * @constructor
  //  * @param {String} tabId  - TabId of the tab the action originates from
  //  * @param {number} user - UserId of the chat room owner
  //  * @param {String} element - Element thats subject of the UIAction
  //  * @param {array} items - data of the selected options of the multiselect (name, image etc.)
  //  * @param {array} value - selected options of the multiselect
  //  */
  constructor(tabId, user, element, items, value) {
    super(tabId, user, element);
    this.items = items;
    this.value = value;
    this.actionType = "MultiSelectAction";
  }

  execute() {
    let multiselectElement = $(this.element).data("kendoMultiSelect");
    multiselectElement.dataSource.data(this.items);
    multiselectElement.value(this.value);
    multiselectElement.trigger("manualChange");
  }

  isComparableTo(other) {
    return this.element.is(other.element);
  }

  reduce(other, last) {
    return this;
  }

  static fromJson(json) {
    let action = JSON.parse(json);
    return new MultiSelectAction(action.tabId, action.user, action.elementLocation, action.items, action.value);
  }

  static registerToElement(wrapper) {
    let selector = ((!wrapper) ? '[data-collaborative-action*="MultiSelectAction"]:not(#publish [data-collaborative-action*="MultiSelectAction"])' : wrapper + ' [data-collaborative-action*="MultiSelectAction"]');
    $(selector).each(function() {
      var multiselect = $(this).data("kendoMultiSelect");
      multiselect.bind("change", (e) => {
        let selectedItems = $(e.sender.element).data("kendoMultiSelect").dataItems();
        let value = e.sender.value();
        MultiSelectAction.createAction(MultiSelectAction.getTabId(), _app.info.user.userId, $(this), selectedItems, value);
      });
    });
  }

  static createAction(tabId, user, element, items, value) {
    let action = new MultiSelectAction(tabId, user, element, items, value);
    action.element = null;
    VERSTEHE.content_channel.send_message(action);
  }

}
