import Vue from 'vue';
import { MetadataField } from '../../globals/interfaces/metadata';
import FieldSelect from '../../globals/vue/metadata_field_select.vue';

export class AnalyseCustomFilter {
  openModalButton: HTMLLIElement;
  customTargetGroupWindow: HTMLDivElement;
  vueApp: Vue;
  metadataFields: Array<MetadataField>;
  analyseUrl = null;
  formFields = {};
  buttonDisabled = false;
  
  constructor() {
    this.openModalButton = document.querySelector('#analyse-custom-target-group');
    this.customTargetGroupWindow = document.querySelector('#custom-target-group-window');
    this.analyseUrl = document.querySelector('#v-custom-target-group-select').getAttribute('data-url');
    this.metadataFields = JSON.parse(document.querySelector('#v-custom-target-group-select').getAttribute('data-fields'));

    $(this.customTargetGroupWindow).kendoWindow({
      draggable: false,
      resizable: false,
      width: "500px",
      title: VERSTEHE.vueI18n.t('course_plan.custom_target_group'),
      visible: false
    });

    this.openModalButton.addEventListener('click', (e) => {
      e.preventDefault();
      $(this.customTargetGroupWindow).data("kendoWindow").open();
      $(this.customTargetGroupWindow).data("kendoWindow").center();
    });

    this.initVueApp();
  }

  initVueApp() {
    this.vueApp = new Vue ({
      name: 'AnalysCustomGroup',
      el: '#v-custom-target-group-select',
      i18n: VERSTEHE.vueI18n,
      data: {
        metadataFields: this.metadataFields,
        buttonDisabled: this.buttonDisabled
      },
      components: {
        FieldSelect
      },
      provide: {
        formFields: this.formFields
      },
      template: `
        <div>
          <field-select class="field-select" v-for="field in metadataFields" :key="field.id" :field="field" :autoclose="true"></field-select>
          <button class="btn btn-primary" @click="apply()">{{ $t('globals.apply') }}</button>
        </div>
      `,
      methods: {
        apply: () => {
          if (Object.values(this.formFields).length > 0) {
            const elements = <Array<number>> Object.values(this.formFields).reduce((acc, curr) => [...<any>acc, ...<any>curr]);
            this.buttonDisabled = true;
            window.location.href = this.analyseUrl + '&elements=' + elements.join(',');
          }
        }
      }
    });
  }
}
