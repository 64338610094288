<template>
<div>
  <search-component v-if="!isLessonEditorSubjectSelected" :options="searchOptions" @close="contributionSelectCancelled()" @selectresult="contributionSelected($event)"></search-component>

  <div class="lesson-editor-form" v-if="isLessonEditorSubjectSelected">
    <div class="lesson-preview" :class="{ expanded: currentQuestion }">
      <loading-spinner v-if="!previewLoaded"></loading-spinner>

      <div v-if="previewLoaded">
        <lesson-subject-preview></lesson-subject-preview>

        <h3 class="lesson-preview-title">{{ this.lessonEditorSubject.title }}</h3>
        <a :href="lessonEditorSubject.authorUrl" class="lesson-preview-author">
          <div class="lesson-preview-author-avatar" :style="{ backgroundImage: `url(${this.lessonEditorSubject.authorAvatar})` }"></div>
          <div class="lesson-preview-author-name">{{ this.lessonEditorSubject.author }}</div>
        </a>
        <p class="lesson-preview-description">{{ this.lessonEditorSubject.description }}</p>
      
        <button v-show="!currentQuestion" @click="resetLessonEditorSubject" type="button" class="btn btn-primary btn-flat">{{ $t(`course.replace_${this.contributionType.toLowerCase()}`) }}</button>
      </div>
    </div>

    <div class="lesson-details">
      <slide-x-right-transition mode="out-in" :duration="200">
        <div key="lessonDetailForm" v-if="!this.currentQuestion">
          <text-input
            name="titleInput"
            v-model="title"
            :validate="{ required: true, max: 75 }"
            :label="$t('course.lesson_title')"></text-input>

          <text-area
            name="descriptionInput"
            v-model="description"
            :validate="{ max: 300 }"
            :label="$t('course.lesson_description')"></text-area>

          <question-list v-if="this.contributionType === 'Topic' && this.lessonEditorSubject.mediaType === 'film'"></question-list>

          <div class="actions">
            <button v-show="lessonEditorQuestions.length > 0" type="button" @click="openPreview" class="btn btn-primary btn-flat">{{ $t('course.preview') }}</button>
            <button v-on:click="cancel()" type="button" class="btn btn-primary btn-flat">{{ $t('course.cancel') }}</button>
            <button v-on:click="saveChanges" :class="{ disabled: errors.any() }" type="button" class="btn btn-primary">{{ $t('course.apply_changes') }}</button>
          </div>
        </div>

        <question-form key="questionForm" v-if="this.currentQuestion"></question-form>
      </slide-x-right-transition>
    </div>
  </div>

  <question-preview
    v-if="showPreview"
    :sources="lessonEditorSubject.sources"
    :questions="this.previewQuestions()"
    @close="onPreviewClosed"
    @ready="onPreviewReady">
  </question-preview>
</div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { checkFetchStatus } from '../../utils';
import { SlideXRightTransition } from 'vue2-transitions';
import { confirmDialog } from '../../../common/dialog';
import LessonSubjectPreview from './lesson_subject_preview.vue';
import { TextInput, TextArea } from './form';
import QuestionList from './questions/question_list.vue';
import QuestionForm from './questions/question_form.vue';
import LoadingSpinner from '../../../globals/loading_spinner.vue';
import QuestionPreview from './questions/preview.vue';
import SearchComponent from '../../../search_component/search_component.vue';

const { mapGetters, mapActions } = createNamespacedHelpers('course');

export default {
  name: 'contribution-lesson-form',
  inject: ['$validator'],
  props: ['contributionType'],
  data() {
    return {
      title: '',
      description: '',
      previewLoaded: false,
      showPreview: false
    }
  },
  components: {
    SlideXRightTransition,
    LessonSubjectPreview,
    TextInput,
    TextArea,
    LoadingSpinner,
    QuestionList,
    QuestionForm,
    QuestionPreview,
    SearchComponent
  },
  computed: {
    ...mapGetters([
      'lessonEditorSubject',
      'lessonEditorObject',
      'lessonEditorType',
      'isLessonEditorSubjectSelected',
      'lessonEditorQuestions',
      'currentQuestion',
      'lessonEditorLesson',
      'apiUrl'
    ]),
    lessonType() {
      if (this.lessonEditorType === 'Course::Lesson::PlaylistLesson') {
        return 'Course::Lesson::PlaylistLesson';
      } else if (this.lessonEditorQuestions.length > 0 && this.lessonEditorSubject.mediaType === 'film') {
        return 'Course::Lesson::TopicQuizLesson';
      } else {
        return 'Course::Lesson::TopicLesson';
      }
    },
    lessonAttributes() {
      let attributes = {
        title: this.title,
        description: this.description,
        type: this.lessonType,
        subject: this.lessonEditorSubject,
        questions: this.$store.state.course.lessonEditor.questions,
      }

      return attributes;
    },
    searchOptions: {
      get: function() {
        let filters = ['all', 'own_only'];

        if (this.contributionType === 'Topic') {
          filters.push('co_authors_only')
        }

        return {
          title: this.$i18n.t(`course.select_lesson_${this.contributionType.toLowerCase()}`),
          closeButton: true,
          searchUrl: this.apiUrl('findContribution'),
          indices: [this.contributionType.toLowerCase()],
          filterOptions: {
            sortOptions: ['best_match', 'newest', 'oldest'],
            filters: filters
          }
        }
      }
    }
  },
  mounted() {
    if (this.lessonEditorSubject && this.lessonEditorSubject.detailUrl) {
      if (this.lessonEditorSubject.loaded) {
        this.setLessonEditorSubject({ ...this.lessonEditorSubject });
        this.previewLoaded = true;
      } else {
        this.fetchLessonDetail(this.lessonEditorSubject.detailUrl);
      }
    }
  },
  methods: {
    ...mapActions([
      'setUiState',
      'setLessonEditorSubject',
      'saveEditorLesson',
      'resetLessonEditorSubject',
      'clearQuestions',
      'unlockLesson',
      'copyQuestions'
    ]),
    cancel() {
      this.setUiState('lessonList');
      this.unlockLesson(this.lessonEditorLesson);
    },
    contributionSelected(contribution) {
      if (this.title.length === 0 || this.title === this.lessonEditorSubject.title) {
        this.title = contribution.title;
      }

      this.clearQuestions();
      this.fetchLessonDetail(contribution.detailUrl).then(() => {
        if (this.lessonEditorSubject.questions && this.lessonEditorSubject.questions.length > 0) {
          // Ask user to copy questions that are already assigned to the topic
          confirmDialog({
            content: this.$i18n.t('course.copy_questions'),
            title: this.$i18n.t('course.copy_questions_title')
          }).then(() => {
            this.copyQuestions(this.lessonEditorSubject.questions);
          }).catch(() => {});
        }
      });
    },

    contributionSelectCancelled() {
      if (this.lessonEditorObject.subject) {
        this.setLessonEditorSubject(this.lessonEditorObject.subject);
      } else {
        this.setUiState('lessonList');
      }
    },

    saveChanges() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.saveEditorLesson(this.lessonAttributes);
        }
      });
    },

    fetchLessonDetail(detailUrl) {
      this.previewLoaded = false;

      return fetch(detailUrl, { credentials: 'include' })
        .then(checkFetchStatus)
        .then(response => response.json())
        .then(response => {
          this.previewLoaded = true
          this.setLessonEditorSubject({ detailUrl, loaded: true, ...response });
        });
    },

    previewQuestions() {
      return this.lessonEditorQuestions.map(question => {
        if (question.isCompleted) {
          question.isCompleted = false;
        }

        return question;
      });
    },

    openPreview() {
      this.showPreview = true;
    },

    onPreviewReady(videoPlayer) {
      videoPlayer.play();
    },

    onPreviewClosed() {
      this.showPreview = false;
    }
  },
  watch: {
    lessonEditorObject: {
      handler: function(lesson, oldLesson) {
        if (lesson.title && lesson.title.length > 0) {
          this.title = lesson.title;
        }

        this.description = lesson.description || '';
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.search-component {
  max-width: 800px;
  margin: 0 auto;
}

.lesson-editor-form {
  display: flex;
}

.lesson-preview {
  position: relative;
  width: 434px;
  min-width: 434px;
  transition: min-width .2s ease-in;
  padding-right: 50px;

  &.expanded {
    min-width: 40%;
  }
}

.lesson-details {
  flex: 1 auto;
  max-width: 800px;
}

.lesson-preview-title {
  font-size: 14px;
  font-weight: 400;
}

.lesson-preview-author {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.lesson-preview-author-avatar {
  height: 30px;
  width: 30px;
  background-size: cover;
  border-radius: 50%;
  margin-right: 15px;
}

.lesson-preview-author-name {
  flex: 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.actions {
  text-align: right;
  margin-top: 50px;
}
</style>
