<template>
  <div>
    <div class="tutorial-video-container">
      <video-player
        autoplay
        :sources="currentVideo.source"
        :screenplay="currentVideo.screenplay"
        :options="playerOptions" />
    </div>
    <div class="tutorial-videos-similar">
      <tutorial-video
        v-for="video in videosByCategoryId(currentVideo.category)"
        :active="video === currentVideo"
        :key="video.id"
        :video="video">
      </tutorial-video>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapGetters } = createNamespacedHelpers('tutorials');

import VideoPlayer from '../../globals/vue/video_player.vue';
import TutorialVideo from './tutorial_video.vue';

export default {
  components: {
    VideoPlayer,
    TutorialVideo
  },
  computed: {
    ...mapGetters([
      'videoById',
      'currentVideo',
      'videosByCategoryId'
    ]),
    playerOptions() {
      return {
        fluid: false
      }
    }
  },
  methods: mapActions(['setCurrentVideo']),
  beforeRouteUpdate(to, from, next) {
    const video = this.videoById(to.params.videoId);

    if (video) {
      this.setCurrentVideo(video.id);
      next();
    } else {
      next('/');
    }
  }
}
</script>

<style lang="scss" scoped>
  .tutorial-video-container {
    background-color: $color-black;
    height: calc(100% - 180px);
  }

  .tutorial-videos-similar {
    display: flex;
    height: 180px;
    align-items: center;
    overflow-x: auto;

    :first-child {
      margin-left: auto;
    }

    :last-child {
      margin-right: auto;
    }
  }

  ::v-deep .tutorial-video {
    margin-bottom: 0;
  }

  ::v-deep .video-player {
    height: 100%;

    .video-js {
      height: 100%;
      padding: 0;
    }
  }
</style>
