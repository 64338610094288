import { GetterTree } from 'vuex';
import { LibraryState } from './state';
import { RootState } from '../../store/types';
import { Take, Slideshow, Image, Clip } from '../interfaces';

export const Getters = {
  ALL_MEDIA: 'library/allMedia',
  PRIVATE_TAKES: 'library/privateTakes',
  PUBLIC_TAKES: 'library/publicTakes',
  DOCUMENTS: 'library/documents',
  CLIPS: 'library/clips',
  IMAGES: 'library/images',
  ACTIVE_TAB: 'library/activeTab',
  ACTIVE_FILTER: 'library/activeFilter',
  SELECTED_TAKES: 'library/selectedTakes',
  SELECTED_SLIDES: 'library/selectedSlides',
  SELECTED_IMAGES: 'library/selectedImages',
  INSPECT_ITEM: 'library/inspectItem',
  TAB_ITEMS: 'library/tabItems',
  BRAND_URL: 'library/brandUrl',
  UPLOAD_URL: 'library/uploadUrl',
  CONVERTING_DOCUMENTS: 'library/convertingDocuments',
  BASE_URL: 'library/baseUrl',
  SELECTION_MODE: 'library/selectionMode',
  REQUESTED_TAKES: 'library/requestedTakes',
  APPROVED_TAKES: 'library/approvedTakes',
  INFINITY_ID: 'library/infinityId',
  MEDIA_THIS_WEEK: 'library/mediaThisWeek',
  MEDIA_LAST_WEEK: 'library/mediaLastWeek',
  MEDIA_OLDER: 'library/mediaOlder',
  UPLOADED_TAKES: 'library/uploadedTakes',
  SETTINGS: 'library/settings',
  MEDIA_TYPES: 'library/mediaTypes',
  SHOW_SHARE_AND_DELETE_BUTTON: 'library/showShareAndDeleteButton',
  REQUESTED_AND_APPROVED_TAKES: 'library/requestedAndApprovedTakes',
  DELETABLE_SELECTED: 'library/deletableSelected',
  SHAREABLE_SELECTED: 'library/shareableSelected',
  NAME_FILTER: 'library/nameFilter',
  DURATION_FILTER: 'library/durationFilter',
  CREATION_DATE_FILTER: 'library/creationDateFilter',
  RESOLUTION_FILTER: 'library/resolutionFilter',
  RESOURCE_TYPE_FILTER: 'library/resourceTypeFilter',
  RESOURCE_SOURCE_FILTER: 'library/resourceSourceFilter',
  ACTIVE_FILTERS: 'library/activeFilters',
  TOTAL_FILTER_RESULTS: 'library/filterTotalResults',
};

export const getters: GetterTree<LibraryState, RootState> = {
  allMedia(state): Array<Take | Clip | Slideshow | Image> {
    return state.allMedia.results;
  },
  privateTakes(state): Array<Take> {
    return state.privateTakes.results;
  },
  publicTakes(state): Array<Take> {
    return state.publicTakes.results;
  },
  documents(state): Array<Slideshow> {
    return state.documents.results;
  },
  clips(state): Array<Image> {
    return state.clips.results;
  },
  images(state): Array<Image> {
    return state.images.results;
  },
  requestedTakes(state): Array<Take> {
    // skip filtering if user is not master editor
    if (_app.info.user.roles.indexOf('master_editor') === -1) {
      return [];
    } else {
      return state.publicTakes.results.filter((take) => take.asset_state === 'requested');
    }
  },
  approvedTakes(state): Array<Take> {
    return state.publicTakes.results.filter((take) => take.asset_state === 'approved');
  },
  requestedAndApprovedTakes(state, getters): Array<Take> {
    return state.publicTakes.results.filter((take) => {
      if (take.asset_state === 'requested' || take.asset_state === 'approved') {
        return true;
      } else {
        return false;
      }
    });
  },
  activeTab(state): string {
    return state.activeTab;
  },
  selectedTakes: (state) => {
    return state.selectedTakes;
  },
  selectedSlides: (state) => {
    return state.selectedSlides;
  },
  selectedImages: (state) => {
    return state.selectedImages;
  },
  inspectItem: (state) => {
    return state.inspectItem;
  },
  tabItems: (state, getters) => (identifier: string) => {
    if (identifier === 'publicTakes') {
      return getters.approvedTakes;
    } else {
      return state[identifier].results;
    }
  },
  infinityId: (state) => (identifier: string) => {
    if (['privateTakes', 'publicTakes', 'documents', 'clips', 'images'].some((tab) => tab === identifier)) {
      return state[identifier].infinityId;
    } else {
      return null;
    }
  },
  brandUrl: (state) => state.brandUrl,
  uploadUrl: (state) => state.uploadUrl,
  convertingDocuments: (state) => state.documents.results.filter((doc) => doc.state === 'converting').map((doc) => doc.id),
  baseUrl: (state) => state.apiBaseUrl,
  selectionMode: (state) => state.selectionMode,
  mediaThisWeek: (state, getters) => (identifier: string, tab?: string) => {
    const thisWeek = getThisWeek();
    const items = getters.tabItems(identifier).filter((item) => {
      const dateString = tab === 'publicTakes' ? item.asset_updated_at : item.created_at;
      return new Date(dateString) >= thisWeek;
    });

    return items;
  },
  mediaLastWeek: (state, getters) => (identifier: string, tab?: string) => {
    const thisWeek = getThisWeek();
    const lastWeek = getLastWeek();

    const items = getters.tabItems(identifier).filter((item) => {
      const dateString = tab === 'publicTakes' ? item.asset_updated_at : item.created_at;
      const date = new Date(dateString);
      return date >= lastWeek && date < thisWeek;
    });

    return items;
  },
  mediaOlder: (state, getters) => (identifier: string, tab?: string) => {
    const lastWeek = getLastWeek();

    const items = getters.tabItems(identifier).filter((item) => {
      const dateString = tab === 'publicTakes' ? item.asset_updated_at : item.created_at;
      return new Date(dateString) < lastWeek;
    });

    return items;
  },
  uploadedTakes: (state) => state.uploadedTakes,
  settings: (state) => state.settings,
  mediaTypes: (state) => state.mediaTypes,
  showShareAndDeleteButton: (state) => state.showShareAndDeleteButton,
  selectedTakeObjects: (state) =>
    state.selectedTakes.map((takeId) => {
      // Workaround to get take from store,
      // search first in private takes and then in public takes if none was found
      let take = state.privateTakes.results.find((take) => take.id === takeId);
      if (!take) {
        take = state.publicTakes.results.find((take) => take.id === takeId);
      }

      // In case the take was just uploaded
      if (!take) {
        take = state.uploadedTakes.find((take) => take.id === takeId);
      }

      // In case the selected take is from the all media tab
      if (!take) {
        let takes = [];
        state.allMedia.results.forEach((val)=>{
          // push takes in another array
          if (val.type === 'take'){
            takes.push(val);
          }
        });
        take = takes.find((take) => take.id === takeId);
      }

      return take;
    }),
  deletableSelected: (state, getters) => {
    return getters.selectedTakeObjects.some((take) => take.can_manage && (take.asset_state === 'private' || take.asset_state === null));
  },
  shareableSelected: (state, getters) => {
    const canUserShare = _app.info.user.highestRoleId >= _app.roles.find((role) => role.key === getters.settings.releaseTakeRole).id;
    return getters.selectedTakeObjects.some((take) => canUserShare && take.can_manage && (take.asset_state === 'private' || take.asset_state === null));
  },
  nameFilter: (state: LibraryState) => {
    return state.nameFilter;
  },
  durationFilter: (state: LibraryState) => {
    return state.durationFilter;
  },
  creationDateFilter: (state: LibraryState) => {
    return state.creationDateFilter;
  },
  resolutionFilter: (state: LibraryState) => {
    return state.resolutionFilter;
  },
  resourceTypeFilter: (state: LibraryState) => {
    return state.resourceTypeFilter;
  },
  resourceSourceFilter: (state: LibraryState) => {
    return state.resourceSourceFilter;
  },
  activeFilters: (state: LibraryState) => {
    return state.activeFilters;
  },
  filterTotalResults: (state: LibraryState) => {
    return state.filterTotalResults;
  },
};

function getThisWeek(): Date {
  return moment().startOf('week').toDate();
}

function getLastWeek(): Date {
  return moment().startOf('week').subtract(1, 'd').startOf('week').toDate();
}
