import { UIAction } from "./ui_action";
//

/** TabActions inform other users when a user changes his current tab
  * The Avatar etc. get updated
  * Only the last TabAction per User is kept because earlier ones become outdated when a new one arrives
  */
export class TabAction extends UIAction {
  /**
   * @constructor
   * @param {string} tabId  - TabId of the tab the action originates from
   * @param {number} user - UserId of the chat room owner
   */
  constructor(tabId, user) {
    super(tabId, user, null);
    this.actionType = "TabAction";
  }

  execute() {
    TabAction.updateTab(this.user, this.tabId);
    let activeSteps = VERSTEHE.ContributionWorkflow.workflow.getActiveSteps();
    let tabId = activeSteps.step;
    if (this.user !== _app.info.user.userId) {
      if (this.tabId === tabId) {} else if (this.tabId !== tabId) {
        // if user joined different tab make sure is cursor does not show
        VERSTEHE.CollaborativeAuthoring.removeCursorAndHighlights(this.user);
      }
    }
  }

  static updateTab(user, tabId) {
    if ($('.communication-user-avatar[data-user_id="' + user + '"]').length > 0) {
      // get avatar and change tooltip to "Username [tabId]"
      let userAvatar = $('.communication-user-avatar[data-user_id="' + user + '"]');
      let tabSpan = userAvatar.parents(".user-row").find(".communication-user-info-tab");
      let tabName = document.querySelector('[data-uid="' + tabId + '"]')?.innerText;
      userAvatar.attr("data-original-title", userAvatar.attr("data-user_name") + " [" + tabName + "]");
      userAvatar.attr("data-tabId", tabId);
      tabSpan.html(tabName);
    }
  }

  isComparableTo(other) {
    if (this.actionType === other.actionType) {
      return (this.user === other.user);
    } else {
      return false;
    }
  }

  reduce(other) {
    return this;
  }

  static fromJson(json) {
    let action = JSON.parse(json);
    return new TabAction(action.tabId, action.user);
  }

  static getTabId() {
    let activeSteps = VERSTEHE.ContributionWorkflow.workflow.getActiveSteps();
    return activeSteps.subStep ? activeSteps.subStep : activeSteps.step;
  }

  static registerToElement() {
    VERSTEHE.ContributionWorkflow.workflow.on('activated', (step) => {
      const action = new TabAction(step.uid, _app.info.user.userId);
      VERSTEHE.content_channel.send_message(action);
      $('.cursor').remove();
    });
  }
}
