import { Action } from "./action";

/**
  * This Action is sent to other users to ask for the current ActionQueue
  */
export class UpdateQueueAction extends Action {
  /**
   * @constructor
   * @param {String} tabId  - TabId of the tab the action originates from
   * @param {number} user - UserId of the chat room owner
   * @param {String} queueName - Name of the Queue the user wants to update
   */
  constructor(tabId, user, queueName) {
    super(tabId, user);
    this.queueName = queueName;
    this.actionType = "UpdateQueueAction";
  }

  execute() {
    if (VERSTEHE.CollaborativeAuthoring.Queues.find(q => q.queueName === this.queueName)) {
      VERSTEHE.CollaborativeAuthoring.Queues.find(q => q.queueName === this.queueName).send(this.user);
    }
  }

  static fromJson(json) {
    let action = JSON.parse(json);
    return new UpdateQueueAction(action.tabId, action.user, action.queueName);
  }
  static sendUpdateQueueAction(queueName) {
    let action = new UpdateQueueAction(UpdateQueueAction.getTabId(), _app.info.user.userId, queueName);
    VERSTEHE.content_channel.send_message(action);
  }
}
