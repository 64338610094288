import { Module } from 'vuex';
import { actions } from './actions';
import { mutations } from './mutations';
import { getters } from './getters';
import { RootState } from '../../store/types';

export { ActionTypes } from './actions';
export { MutationTypes } from './mutations';
export { GetterTypes } from './getters';

export interface TwoFactorState {
  verificationMethod: string
  otpSecret: string
  activateUrl: string
  backupCodes: string[]
  redirectUrl: string
  sendOtpUrl: string
  email: string
}

const state: TwoFactorState = {
  verificationMethod: null,
  otpSecret: null,
  activateUrl: null,
  backupCodes: [],
  redirectUrl: null,
  sendOtpUrl: null,
  email: ''
}

export const TwoFactorModule: Module<TwoFactorState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
