import { ChapterItem, Chapter } from "./entities";
import store from '../../store';
import { Getters } from "./store/getters";
import { Actions } from './store/actions';
import { v4 as uuid } from 'uuid';

export default class Utils {
  static searchResultToCHapterItem(result, chapterId): ChapterItem {
    return {
      id: uuid(),
      chapterId: chapterId,
      courseId: result.actableId,
      courseTitle: result.title,
      courseDescription: result.description,
      position: null,
      thumbnail: result.thumbnailUrl,
      createdAt: null,
      updatedAt: null,
      deleted: false
    }
  }

  static serializeChapters() {
    store.dispatch(Actions.REFRESH_POSITIONS);
    
    return store.getters[Getters.CHAPTER_IDS_WITH_DELTED].map(chapterId => {
      const chapter: Chapter = store.getters[Getters.CHAPTER](chapterId);
      return {
        id: typeof chapter.id === "string" ? null : chapter.id,
        title: chapter.title,
        position: chapter.position,
        _destroy: chapter.deleted === true,
        plan_chapter_items_attributes: [
          ...chapter.chapterItems.map(itemId => {
            const chapterItem: ChapterItem = store.getters[Getters.CHAPTER_ITEM](itemId);
            return {
              id: typeof chapterItem.id === "string" ? null : chapterItem.id,
              course_id: chapterItem.courseId,
              position: chapterItem.position,
              _destroy: chapterItem.deleted === true
            }
          }),
          ...store.getters[Getters.ALL_CHAPTER_ITEMS_WITH_DELTED].filter(item => {
            return item.deleted === true && item.originChapterId === chapter.id;
          }).map(item => {
            return {
              id: item.originId,
              _destroy: true
            }
          })
        ]
      }
    });
  }
}
