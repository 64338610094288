import axios from 'axios';
export class QRCodeModal {

  private link: HTMLElement;
  private value: string;
  private kendoWindow: kendo.ui.Window;
  private logoTag;
  private backgroundTag;

  constructor(selector) {
    this.link = document.querySelector(selector);
    this.value = this.link.getAttribute('data-qrvalue');
    this.initModal();
    this.initButtonClick();
  }

  private initModal() {
    const value = this.value;
    let brandImage: HTMLElement = document.querySelector('.navbar-brand > img');
    this.kendoWindow = $('<div></div>').kendoWindow({
      content: {
        template: `<div id="myqrcode" class="qr-code">
        </div>`
      },
      actions: ["Print", "Download", "Close"],
      minWidth: 200,
      minHeight: 200,
      modal: true,
      draggable: false,
      activate: () => {
        (<HTMLInputElement>document.getElementById('checkLogo')).checked = true;
        let logoImage = new Image();
        logoImage.onload = () => {
          this.initLogoPosition(logoImage);
        }
        logoImage.src = brandImage.dataset.alt ? brandImage.dataset.alt : '/static/brand/logo_coovi_qr.png';
      }
    }).data('kendoWindow');

    this.kendoWindow.wrapper.find('.k-window-title').html(`<div class="qrcode-window"> 
    <input id="checkLogo" type="checkbox" class="k-checkbox" data-change="toggleLogo($event)"> 
    <label class="k-checkbox-label" for="logo"> ${VERSTEHE.vueI18n.t('globals.qrcode.qrcode_with_logo')}</label>
    </div>`);

    const qr = this.kendoWindow.wrapper.find('.qr-code').kendoQRCode({
      value: value,
      errorCorrection: "H",
      size: 300,
      background: '#fff'
    });

    this.kendoWindow.wrapper.find('.k-i-print').parent().click((e) => {
      this.handlePrint();
    });

    this.kendoWindow.wrapper.find('.k-i-download').parent().click((e) => {
      this.handleDownload();
    });

    this.kendoWindow.wrapper.find('.k-checkbox').click(() => {
      this.toggleLogo();
    });
  }

  /**
   * loads the logo to get the b64 string from the image
   * @param brandImage logo 
   */
  private initLogoPosition(brandImage) {
    axios.get(brandImage.src, { responseType: 'blob' }).then(response => {
      let fileReader = new FileReader();
      fileReader.onload = (event) => {
        this.createLogoTags(event.target.result, brandImage)
        this.showQRCodeWithLogo();
      }
      fileReader.readAsDataURL(response.data)
    });
  }

  /**
   * creates the tags for the logo and the white background to overlay the qrcode
   * @param data b64-string from the logo
   * @param brandImage logo
   */
  private createLogoTags(data, brandImage) {
    const getImageCenterX = document.getElementsByClassName('k-qrcode')[0].clientWidth / 2;
    const getImageCenterY = document.getElementsByClassName('k-qrcode')[0].clientHeight / 2;
    const logoRatio = brandImage.width / brandImage.height;
    const logoHeight = this.calculateLogoSizeWithErrorCorrection(brandImage);
    const xCord = getImageCenterX - (logoHeight * logoRatio / 2)
    const yCord = getImageCenterY - (logoHeight / 2)

    this.logoTag = document.createElementNS("http://www.w3.org/2000/svg", 'image');
    this.logoTag.setAttributeNS(null, 'x', xCord.toString());
    this.logoTag.setAttributeNS(null, 'y', yCord.toString());
    this.logoTag.setAttributeNS(null, 'height', logoHeight + "px");
    this.logoTag.setAttribute('href', data.toString());

    this.backgroundTag = document.createElementNS("http://www.w3.org/2000/svg", 'rect');
    this.backgroundTag.setAttributeNS(null, 'y', (Number(this.logoTag.getAttributeNS(null, 'y')) - 1).toString());
    this.backgroundTag.setAttributeNS(null, 'x', (Number(this.logoTag.getAttributeNS(null, 'x')) - 1).toString());
    this.backgroundTag.setAttributeNS(null, 'width', (logoHeight * logoRatio) + 2 + 'px');
    this.backgroundTag.setAttributeNS(null, 'height', logoHeight + 2 + 'px');
    this.backgroundTag.setAttributeNS(null, 'fill', 'white');
  }

  /**
   * called when checkbox was clicked
   * draws logo onto qrcode or removes the logo
   */
  private toggleLogo() {
    if ((<HTMLInputElement>document.getElementById('checkLogo')).checked) {
      this.showQRCodeWithLogo();
    } else {
      this.showQRCode();
    }
  }

  /**
   * appends the tags to show the qrcode with logo
   */
  private showQRCodeWithLogo() {
    let svgElement = document.getElementsByClassName('k-qrcode')[0].getElementsByTagName('svg')[0];
    svgElement.getElementsByTagName('g')[0].appendChild(this.backgroundTag);
    svgElement.getElementsByTagName('g')[0].appendChild(this.logoTag);
  }

  /**
   * removes tags to show qrcode without logo
   */
  private showQRCode() {
    let svgElement = document.getElementsByClassName('k-qrcode')[0].getElementsByTagName('svg')[0];
    svgElement.getElementsByTagName('g')[0].removeChild(this.logoTag);
    svgElement.getElementsByTagName('g')[0].removeChild(this.backgroundTag);
  }

  /**
   * calculates the size of the logo dependent on the error correction level (H => 30%)
   * logo should not take more than 20% of space
   * default height of logo is 40px (same size as in navigation bar)
   * @param logo logo to show on qrcode 
   * @returns height of the logo
   */
  private calculateLogoSizeWithErrorCorrection(logo) {
    const svgDiv = document.getElementById('myqrcode');
    const qrCodeSize = svgDiv.clientWidth * svgDiv.clientHeight;
    const possibleLogoSize = qrCodeSize / 100 * 20;
    const logoAspectRatio = logo.width / logo.height;
    const height = this.checkErrorCorrectionLevel(possibleLogoSize, 40, logoAspectRatio, svgDiv.clientWidth)
    return height;
  }

  /**
   * checks if the logo is to big for error correction level
   * @param possibleSize maximum size (20%) of the logo that is within the error correction level (30%)
   * @param height height of the logo
   * @param ratio aspect ratio of the logo
   * @param divWidth width of the qrcode
   * @returns height of logo
   */
  private checkErrorCorrectionLevel(possibleSize, height, ratio, divWidth) {
    const logoSize = (height * ratio) * height;
    if ((logoSize > possibleSize || (height * ratio > divWidth)) && height > 5) {
      //reduce height and try again
      return this.checkErrorCorrectionLevel(possibleSize, height - 5, ratio, divWidth);
    } else {
      return height;
    }
  }

  private handlePrint() {
    let svgDiv = document.getElementById('myqrcode');
    let data = svgDiv.getElementsByTagName('svg')[0].outerHTML;
    let img = new Image();
    img.onload = function () {
      let pwin = window.open('', "Image");
      pwin.document.write('<img src="' + img.src + '" height="' + svgDiv.clientHeight + '" width="' + svgDiv.clientWidth + '"/>');
      pwin.print();
    }
    img.src = 'data:image/svg+xml;base64,' + btoa(data);
  }

  private handleDownload() {
    let svgDiv = document.getElementById('myqrcode');
    let data = svgDiv.getElementsByTagName('svg')[0].outerHTML;
    let img = new Image();

    img.onload = function () {
      let c = document.createElement('canvas');
      c.width = svgDiv.clientWidth;
      c.height = svgDiv.clientHeight;
      c.style.width = svgDiv.clientWidth + "px";
      c.style.height = svgDiv.clientHeight + "px";
      c.getContext('2d').drawImage(img, 0, 0);
      let a = document.createElement("a");
      a.download = "qrcode.png";
      a.href = c.toDataURL("image/png");
      a.click();
    }
    img.src = 'data:image/svg+xml;base64,' + btoa(data);
  }

  private initButtonClick() {
    this.link.addEventListener('click', () => {
      this.kendoWindow.center().open();
    })
  }
}
