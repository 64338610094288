import { schema } from 'normalizr';
import { chapterItemSchema } from './chapter_item';

export interface Chapter {
  id: EntityId;
  position: number;
  title: string;
  createdAt: string;
  updatedAt: string;
  chapterItems: EntityId[];
  deleted?: boolean;
}

export const chapterSchema = new schema.Entity('chapters', {
  chapterItems: [chapterItemSchema]
});
