<template>
  <router-link :to="'/video/' + video.id" class="tutorial-video">
    <div
      class="tutorial-video-thumbnail"
      :style="{ 'background-image': 'url(' + video.thumbnail + ')' }">
      <span class="tutorial-video-duration">{{ video.duration }}</span>
      <div class="tutorial-video-hover">
        <i class="material-icons">play_arrow</i>
      </div>
    </div>
    <div class="tutorial-video-title">
      {{ video.title }}
    </div>
  </router-link>
</template>

<script>
  export default {
    props: ['video'],
    computed: {
      videoThumbnail() {
        return this.video.thumbnail;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .tutorial-video {
    margin-right: 10px;
    margin-bottom: 24px;

    .tutorial-video-hover {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      visibility: hidden;
      background-color: $black-54-opacity;
      line-height: 108px;
      text-align: center;
      transition: opacity .1s ease-in;

      > i {
        color: white;
        font-size: 48px;
      }
    }

    &.router-link-active {
      cursor: default;
      opacity: .4;
    }

    &:not(.router-link-active):hover {
      .tutorial-video-hover {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .tutorial-video-thumbnail {
    position: relative;
    width: 192px;
    height: 108px;
  }

  .tutorial-video-title {
    width: 192px;
    height: 40px;
    color: $black-87-opacity;
    margin-top: 6px;
    line-height: 20px;
    font-size: 14px;
    overflow: hidden;
  }

  .tutorial-video-duration {
    position: absolute;
    width: 48px;
    bottom: 5px;
    right: 5px;
    font-size: 11px;
    text-align: center;
    color: $color-white;
    background-color: $black-54-opacity;
  }
</style>
