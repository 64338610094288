import { ChangeAction } from "../../../system_actions/change_action";

/**
 * Represents all actions that concern the RecordingAppAction events
 */
export class RecordingAppAction extends ChangeAction {
  /**
   * @constructor
   * @param {string} tabId  - tabId of the tab the action originates from
   * @param {number} user - userId of the chat room owner
   * @param {String} element - element thats subject of the ChangeAction
   * @param {Object} result - Information about the event
   * @param {String} event_name - "ra:onImportSuccess", "ra:onRecordSuccess", "ra:onSplitAmorSuccess", "ra:onSlideAudioRecordSuccess", "ra:onRefreshAmor", "ra:onUploadFinished"
   */
  constructor(tabId, user, element, result, event_name) {
    super(tabId, user, element);
    this.result = result;
    this.event_name = event_name;
    this.actionType = "RecordingAppAction";
  }

  execute() {
    // listener must be unbound to prevent loop
    $(document).off(this.event_name, '[data-collaborative-action*="RecordingAppAction"]');
    if (this.event_name === "ra:onUploadFinished") {
      $('.take[data-amor="' + this.result + '"]').removeClass('converting converted uploading').addClass('uploaded');
    }
    this.element.trigger(this.event_name, [this.result]);
    // rebinding the listener
    RecordingAppAction.bindListener(this.event_name);
  }

  static fromJson(json) {
    let action = JSON.parse(json);
    return new RecordingAppAction(action.tabId, action.user, action.elementLocation, action.result, action.event_name);
  }

  static registerToElement() {
    let ra_events = ["ra:onImportSuccess", "ra:onRecordSuccess", "ra:onSplitAmorSuccess", "ra:onSlideAudioRecordSuccess", "ra:onRefreshAmor"];
    $.each(ra_events, function(index, event_name) {
      RecordingAppAction.bindListener(event_name);
    });
    $(document).on('ra:onUploadFinished', '[data-collaborative-action*="RecordingAppAction"]', function(e, amorId) {
      RecordingAppAction.createAction(RecordingAppAction.getTabId(), _app.info.user.userId, $('[data-collaborative-action*="RecordingAppAction"]'), amorId, "ra:onUploadFinished");
    });
  }
  static bindListener(event_name) {
    $(document).on(event_name, '[data-collaborative-action*="RecordingAppAction"]', function(e, result) {
      RecordingAppAction.createAction(RecordingAppAction.getTabId(), _app.info.user.userId, $('[data-collaborative-action*="RecordingAppAction"]'), result, event_name);
    });
  }

  static createAction(tabId, user, element, result, event_name) {
    let action = new RecordingAppAction(tabId, user, element, result, event_name);
    VERSTEHE.content_channel.send_message(action);
  }
}
