var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"livestream-aws-integration"},[_c('div',{staticClass:"livestream-aws-settings"},[_c('ValidationProvider',{attrs:{"rules":"required","name":_vm.$t('integrations.livestream_aws_access_key')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('div',{staticClass:"form-group label-static",class:{ 'error': errors.length }},[_c('label',{staticClass:"control-label",attrs:{"for":"livestream_aws_access_key"}},[_vm._v(_vm._s(_vm.$t('integrations.livestream_aws_access_key')))]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.livestream_aws_access_key),expression:"livestream_aws_access_key"}],staticClass:"form-control",attrs:{"type":"password","id":"livestream_aws_access_key"},domProps:{"value":(_vm.livestream_aws_access_key)},on:{"input":function($event){if($event.target.composing){ return; }_vm.livestream_aws_access_key=$event.target.value}}}),_vm._v(" "),(errors.length > 0)?_c('span',{staticClass:"help-block error-block"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required","name":_vm.$t('integrations.livestream_aws_secret_key')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('div',{staticClass:"form-group label-static",class:{ 'error': errors.length }},[_c('label',{staticClass:"control-label",attrs:{"for":"livestream_aws_secret_key"}},[_vm._v(_vm._s(_vm.$t('integrations.livestream_aws_secret_key')))]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.livestream_aws_secret_key),expression:"livestream_aws_secret_key"}],staticClass:"form-control",attrs:{"type":"password","id":"livestream_aws_secret_key"},domProps:{"value":(_vm.livestream_aws_secret_key)},on:{"input":function($event){if($event.target.composing){ return; }_vm.livestream_aws_secret_key=$event.target.value}}}),_vm._v(" "),(errors.length > 0)?_c('span',{staticClass:"help-block error-block"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required","name":_vm.$t('integrations.livestream_aws_region')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('div',{staticClass:"form-group label-static",class:{ 'error': errors.length }},[_c('label',{staticClass:"control-label",attrs:{"for":"livestream_aws_region"}},[_vm._v(_vm._s(_vm.$t('integrations.livestream_aws_region')))]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.livestream_aws_region),expression:"livestream_aws_region"}],staticClass:"form-control",attrs:{"type":"text","id":"livestream_aws_region"},domProps:{"value":(_vm.livestream_aws_region)},on:{"input":function($event){if($event.target.composing){ return; }_vm.livestream_aws_region=$event.target.value}}}),_vm._v(" "),(errors.length > 0)?_c('span',{staticClass:"help-block error-block"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required","name":_vm.$t('integrations.livestream_aws_medialive_access_role_arn')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('div',{staticClass:"form-group label-static",class:{ 'error': errors.length }},[_c('label',{staticClass:"control-label",attrs:{"for":"livestream_aws_medialive_access_role_arn"}},[_vm._v(_vm._s(_vm.$t('integrations.livestream_aws_medialive_access_role_arn')))]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.livestream_aws_medialive_access_role_arn),expression:"livestream_aws_medialive_access_role_arn"}],staticClass:"form-control",attrs:{"type":"text","id":"livestream_aws_medialive_access_role_arn"},domProps:{"value":(_vm.livestream_aws_medialive_access_role_arn)},on:{"input":function($event){if($event.target.composing){ return; }_vm.livestream_aws_medialive_access_role_arn=$event.target.value}}}),_vm._v(" "),(errors.length > 0)?_c('span',{staticClass:"help-block error-block"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required","name":_vm.$t('integrations.livestream_aws_medialive_input_security_group_id')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('div',{staticClass:"form-group label-static",class:{ 'error': errors.length }},[_c('label',{staticClass:"control-label",attrs:{"for":"livestream_aws_medialive_input_security_group_id"}},[_vm._v(_vm._s(_vm.$t('integrations.livestream_aws_medialive_input_security_group_id')))]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.livestream_aws_medialive_input_security_group_id),expression:"livestream_aws_medialive_input_security_group_id"}],staticClass:"form-control",attrs:{"type":"text","id":"livestream_aws_medialive_input_security_group_id"},domProps:{"value":(_vm.livestream_aws_medialive_input_security_group_id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.livestream_aws_medialive_input_security_group_id=$event.target.value}}}),_vm._v(" "),(errors.length > 0)?_c('span',{staticClass:"help-block error-block"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])}),_vm._v(" "),_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }