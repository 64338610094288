/** Class representing the CollaborationCenter containing the chat and Co-Author management*/
export class CollabCenter {
  /**
   * @constructor
   * @param {number} userId - Id of the owner of the current content
   * @param {number} contentId  - Id of the current content
   * @param {String} manage_get_collaborators_path - Link to the path to get collaborators for that content
   */
  constructor(userId, contentId, manage_get_collaborators_path) {
    this.userId = userId;
    this.contentId = contentId;
    this.manage_get_collaborators_path = manage_get_collaborators_path;
    let collabCenter = this;
    this.favicon = new Favico({ animation: 'popFade' });
    this.unreadMessagesCounter = null;
    this.chat = new VERSTEHE.common.ChatRoom(`content:${this.contentId}`, this.userId);
    collabCenter.localization = {
      'en': {
        'active': 'active',
        'not_active': 'not active',
        'confirm_remove_author': 'Do you really want to remove this Co-Author?'
      },
      'de': {
        'active': 'Aktiv',
        'not_active': 'Inaktiv',
        'confirm_remove_author': 'Möchten Sie diesen Koautoren wirklich entfernen?'
      },
      'es': {
        'active': 'enérgico',
        'not_active': 'Inactivo',
        'confirm_remove_author': '¿Realmente quieres eliminar a estos coautores?'
      }
    };
  }

  /**
   * Function to initialize the CollabCenter
   * prepares the user interface, connects the chat component,
   * loads collaborators, initializes searchField for collaborators and adds listeners
   */
  initialize(){
    $("#communication-maximized").hide();
    $('#add-users, #chat, #users').hide();
    $('#communication-status').hide();
    this.chat.connect();
    this.initializeListeners();
    this.updateCollaborators(true);
    $('#user_ids').selectize({
      plugins: ['remove_button'],
      valueField: 'id',
      labelField: 'username',
      searchField: ['username'],
      closeAfterSelect: true,
      load: function(query, callback) {
        if (!query.length) {
          return callback();
        }
        $.ajax({
          url: this.$input.data('url'),
          type: 'GET',
          dataType: 'json',
          data: {
            q: query
          },
          error: function() {
            callback();
          },
          success: function(res) {
            callback(res);
          }
        });
      }
    });
  }

  initializeListeners() {

    $(window).bind('beforeunload', function () {
      VERSTEHE.StatusAction.StatusAction.sendStatusAction('goodbye');
    });

    $('.communication-header').click(function(event) {
      if (!$(event.target).is('.header-button') && !$(event.target).is('.head-button')) {
        VERSTEHE.utils.createCookie('CollabCenter_state', '.communication-header', 365);
        $('#communication-maximized').hide();
        $('#communication-status').show();
      }
    });

    $('#communication-status').click(function() {
      VERSTEHE.utils.createCookie('CollabCenter_state', '#communication-status', 365);
      if ($("#communication-status").hasClass("unread")) {
        $('.show-chat-button').click();
      }
      $('#communication-maximized').show();
      $('#communication-status').hide();
      $("#communication-status").removeClass("unread");
      $("#communication-wrapper").removeClass("unread");
      if ($(".chat-messages").is(":visible")) {
        $(".chat-messages").scrollTop($(".chat-messages").prop("scrollHeight"));
        $('#chat').find('textarea.chat-post-input').focus();
      }
    });

    $('.show-chat-button').click(function() {
      VERSTEHE.utils.createCookie('CollabCenter_tab', '.show-chat-button', 365);
      $('#users').hide();
      $('#users').removeClass("active-view");
      $('#chat').show();
      $('#chat').addClass("active-view");
      $(".show-chat-button .chat-chat-count").html("");
      $(".maximize-button .maximize-chat-count").html("");
      collabCenter.favicon.reset();
      collabCenter.unreadMessagesCounter = null;
      $(".chat-messages").scrollTop($(".chat-messages").prop("scrollHeight"));
      $('#chat').find('textarea.chat-post-input').focus();
    });

    $('.show-users-button, .back-users-button').click(function() {
      VERSTEHE.utils.createCookie('CollabCenter_tab', '.show-users-button', 365);
      $('#add-users, #chat').hide();
      $('#chat').removeClass("active-view");
      $('#users').show();
      $('#users').addClass("active-view");
    });

    $('.show-add-users-button').click(function() {
      $('#users').hide();
      $('#users').removeClass("active-view");
      $('#add-users').show();
      $('#add-users').addClass("active-view");
      $('#add-users').find('input[type="text"]').focus();
    });

    $('form').bind("ajax:success", function(event, xhr, settings) {
      $('#add-users, #chat').hide();
      $('#add-users').removeClass("active-view");
      $('#users').show();
      $('#users').addClass("active-view");
      let co_authors = xhr;
      $('#user_ids').selectize()[0].selectize.clear();
      collabCenter.updateCollaborators(true);
      VERSTEHE.StatusAction.StatusAction.sendStatusAction("update-collaborators");
    });

    $(this.chat.wrapper).on('chat:message', function(e, data) {
      let increase = false;
      if (!$('#chat').is('.active-view')) {
        increase = true;
        $(".show-chat-button .chat-chat-count").html(collabCenter.unreadMessagesCounter + 1);
        collabCenter.favicon.badge(collabCenter.unreadMessagesCounter + 1);
      }
      if (!$('#communication-maximized').is(':visible')) {
        // increase = true;
        // $(".maximize-button .maximize-chat-count").html(collabCenter.unreadMessagesCounter + 1);
        $("#communication-status").addClass("unread");
        $("#communication-wrapper").addClass("unread");
      }
      if (increase) {
        collabCenter.favicon.badge(collabCenter.unreadMessagesCounter + 1);
        collabCenter.unreadMessagesCounter++;
      }
    });

    $(this.chat.wrapper).on('chat:participant_change', function(e, data) {
      if (VERSTEHE.CollaborativeAuthoring.collaborators) {
        collabCenter.updateCollaboratorsStatus();
      }
    });

    $(document).ready(function() {
      if (VERSTEHE.utils.readCookie('CollabCenter_state') != null && VERSTEHE.utils.readCookie('CollabCenter_tab') != null) {
        $(VERSTEHE.utils.readCookie('CollabCenter_tab')).click();
        $(VERSTEHE.utils.readCookie('CollabCenter_state')).click();
      } else {
        $('.show-users-button').click();
        $(".communication-header").click();
      }
    });

    $(document).on('click', ".delete-button", function() {
      if (confirm(collabCenter.localization[_app.info.currentLocale].confirm_remove_author)) {
        $.ajax({
          url: $(this).data('url'),
          type: 'POST',
          dataType: 'json',
          data: {
            user_id: $(this).data('delete-user_id'),
            content_id: collabCenter.contentId
          },
          error: function() {
            callback();
          },
          success: function(res) {
            collabCenter.updateCollaborators(true);
            VERSTEHE.StatusAction.StatusAction.sendStatusAction("update-collaborators");
            // $('[data-delete-user_id="'+res.success[0]+'"]').closest(".user-row").remove(); $('[data-user_id="'+res.success[0]+'"]').closest(".communication-status-avatar").remove();
          }
        });
      }
    });
  }

  updateCollaborators(own) {
    let collabCenter = this;
    $("#collaborators-status").children(":not(script)").remove();
    $("#collaborators-list").children(":not(script)").remove();
    $.ajax({
      url: collabCenter.manage_get_collaborators_path,
      type: 'get',
      dataType: 'json',
      data: {
        content_id: collabCenter.contentId
      },
      error: function() {
        // callback();
      },
      success: function(res) {
        VERSTEHE.CollaborativeAuthoring.collaborators = res.collaborators;
        let statusSource = $("#communication-status-avatar-template").html();
        let listSource = $("#collaborators-user-row-template").html();
        let statusTemplate = Handlebars.compile(statusSource);
        let listTemplate = Handlebars.compile(listSource);
        res.collaborators.forEach(function(user) {
          user.link = "/" + _app.info.site + "/users/" + user.id;
          $("#collaborators-status").append(statusTemplate(user));
          $("#collaborators-list").append(listTemplate(user));
        });
        collabCenter.updateCollaboratorsStatus();
        if (own) {
          VERSTEHE.StatusAction.StatusAction.sendStatusAction("hello");
        }
        $('[data-hover="tooltip"]').tooltip({container: 'body'});
      }
    });
  }

  isCollaborator(participant) {
    let isCollaborator = false;
    VERSTEHE.CollaborativeAuthoring.collaborators.forEach(function(collaborator) {
      if (collaborator.id === participant.id || isCollaborator) {
        isCollaborator = true;
      }
    });
    return isCollaborator;
  }

  updateCollaboratorsStatus() {
    let collabCenter = this;
    let collaborators = VERSTEHE.CollaborativeAuthoring.collaborators;
    let participants = collabCenter.chat.participants;
    participants.forEach((participant) => {
      if (collabCenter.isCollaborator(participant)) {
        // Change the Collaborators status online/offline
        collabCenter.updateCollaboratorAvatarStatus(participant.id, participant.online);
      } else {
        if (participant.online) {
          if (!$(".communication-user-avatar[data-user_id='" + participant.id + "']").length > 0) {
            let statusSource = $("#communication-status-avatar-template").html();
            let listSource = $("#collaborators-user-row-template").html();
            let statusTemplate = Handlebars.compile(statusSource);
            let listTemplate = Handlebars.compile(listSource);
            participant = this.initCoAuthor(participant);
            $("#collaborators-status").append(statusTemplate(participant));
            $("#collaborators-list").append(listTemplate(participant));
          }
          collabCenter.updateCollaboratorAvatarStatus(participant.id, true);
        } else {
          if ($(".communication-user-avatar[data-user_id='" + participant.id + "']").length > 0) {
            $(".user-row > .communication-user-info-avatar > .communication-user-avatar[data-user_id='" + participant.id + "']").parent().parent().remove();
            $(".communication-status-avatar > .communication-user-avatar[data-user_id='" + participant.id + "']").remove();
          }
        }
      }
    });
  }

  initCoAuthor(participant) {
    participant.collaboratorType = "external_author";
    participant.htmlId = "external_author";
    participant.htmlClass = "external_author";
    participant.avatar = "/" + _app.info.site + participant.avatar.slice(2);
    participant.link = "/" + _app.info.site + "/users/" + participant.id;
    return participant;
  }

  // changes the status (color) of a avatar
  updateCollaboratorAvatarStatus(user, isOnline) {
    let userAvatar = $('.communication-user-avatar[data-user_id="' + user + '"]');
    let tabSpan = userAvatar.parents(".user-row").find(".communication-user-info-tab");
    let stateSpan = userAvatar.parents(".user-row").find(".communication-user-info-state");
    let color = user === this.chat.owner ?
      this.chat.options.ownerColor :
      this.chat.options.colors[user % 20];
    switch (isOnline) {
      case true:
        userAvatar.removeClass("offline");
        userAvatar.addClass("online");
        userAvatar.css("color", color);
        stateSpan.html(collabCenter.localization[_app.info.currentLocale].active);
        break;
      case false:
        userAvatar.removeClass("online");
        userAvatar.addClass("offline");
        userAvatar.css("color", "#aaa");
        stateSpan.html(collabCenter.localization[_app.info.currentLocale].not_active);
        tabSpan.html("...");
        break;
    }
  };
}
