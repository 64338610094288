import Vue from 'vue';
import DragAndDropQuestion from './questions/drag_and_drop.vue';

const questions: Array<Vue> = [];

export function initDragAndDropQuestion(selector: string) {
  const baseElement = <HTMLDivElement> document.querySelector(selector);
  const initialData = JSON.parse(baseElement.dataset.initial);

  questions.push(new Vue({
    name: 'QuizDragAndDropQuestion',
    el: baseElement,
    i18n: VERSTEHE.vueI18n,
    provide:  {
      positions: initialData.positions,
      dragOptions: initialData.answerOptions
    },
    render: h => h(DragAndDropQuestion, { 
      props: { 
        image: initialData.image
      }
    })
  }));
}
