import BaseWidget from './base_widget';

export default class UserHistoryWidget extends BaseWidget {
  constructor(options) {
    super(options);

    this.type = 'UserHistory';
  }

  static get defaultParams() {
    return {
      title: '',
      show_title: true
    };
  }
}
