import { Chart } from './chart';
export class KeyFigure {
  constructor(element, container) {
    this.element = element;
    this.id = element.getAttribute('id');
    this.container = container;
    this.currentValue = null;
    this.chart = new Chart(this.element, this.container);
    this.chart.chartArea = this.container.chartArea;
    this.chartGroup = this.element.getAttribute('data-chart-group');
    this.previousMarker = this.element.getAttribute('data-previous-marker');
    this.previous = 'positiv';
    this.active = this.element.classList.contains('active');
    this.chartConfig = {};
    this.icons = {"positiv": '<i class="material-icons">arrow_upward</i>',
      "negativ": '<i class="material-icons">arrow_downward</i>',
      "flat": '<i class="material-icons">arrow_forward</i>'};

    this.bindListener();
  }

  load(data) {
    this.currentValue = data;
    let previous = this.calcPreviousPeriod(this.currentValue.current, this.currentValue.previous);
    this.element.querySelector('.value').innerHTML = this.currentValue.current;

    if(this.previousMarker && this.previous) {
      this.element.querySelector('.previous').innerHTML = this.icons[this.previous] + previous;
    } else {
      this.element.querySelector('.previous').innerHTML = previous;
    }

    if (this.hasChart() && this.active) {
      this.renderChart();
    }
  }

  calcPreviousPeriod(current, previous) {   
    if ( typeof current === 'string' ) {
      current = this.currentValue.unformatted_current;
    }

    if (current === previous) {
      this.previous = 'flat';
      this.updatePreviousMarker();
      return 0;
    }

    let fluctuating = previous > 0 ? Math.round(((current / previous) - 1 ) * 100) : ' – ';

    if (fluctuating > 0) {
      this.previous = 'positiv';
    } else if (fluctuating < 0) {
      this.previous = 'negativ';
    } else {
      this.previous = null;
    }

    this.updatePreviousMarker();
    return fluctuating;
  }

  updatePreviousMarker() {
    let previousElement = this.element.querySelector('.previous');

    ['positiv', 'flat', 'negativ', 'percant'].forEach( s => {
      previousElement.classList.remove(s);
    });

    if (this.previous) {
      previousElement.classList.add(this.previous);
      previousElement.classList.add('percant');
    }

  }

  hasChart() {
    if (this.chart !== null && this.chart !== undefined){
      return true;
    }

    return false;
  }

  renderChart() {
    this.chart.load(this.currentValue);
  }

  renderFullSizeChart() {
    if(this.hasChart()) {
      this.chart.renderFullSizeChart();
    }
  }

  deactivate() {
    this.element.classList.remove("active");
    this.active = false;
  }

  buildParam() {
    let params = {};
    if (this.chart !== null && this.chart !== undefined) {
      params["chart"] = { "type": this.chart.chart };
    }
    params["previous"] = this.previousMarker;

    return params;
  }

  bindListener() {
    if( this.hasChart()) {
      this.element.addEventListener('click', function(e) {
        if (this.container.activatedWidget) {
          this.container.activatedWidget().deactivate();
        }

        this.element.classList.add('active');
        this.active = true;
        this.renderChart();
      }.bind(this));
    }
  }
}
