import { Action } from "./action";
import { ElementFinder } from "../../element_finder";

/** UIActions are all Actions that produce optical changes in other users interfaces (MouseActions, ChangeActions) */
export class UIAction extends Action {
  /**
   * @constructor
   * @param {String} tabId  - TabId of the tab the action originates from
   * @param {number} user - UserId of the chat room owner
   * @param {String} element - Element thats subject of the UIAction
   */
  constructor(tabId, user, element) {
    super(tabId, user);
    /** Check if inheriting class implements execute() method */
    if (this.execute === undefined) {
      throw new TypeError("Must implement execute");
    }
    if (typeof element === "string") {
      this.elementLocation = element;
      if (this.tabId !== UIAction.getTabId()) {
        // catches no element errors when elements are not existant because they are on an nonexisting tab
        try {
          this.element = $(VERSTEHE.ElementFinder.ElementFinder.locationToElement(element));
        } catch (err) {
          // console.log("element from remote tab");
        }
      } else {
        this.element = $(VERSTEHE.ElementFinder.ElementFinder.locationToElement(element));
      }
    } else if (typeof element === "object") {
      this.elementLocation = VERSTEHE.ElementFinder.ElementFinder.elementToLocation(element);
      this.element = element;
    }
  }
}
