<template>
  <div class="playlist-component">
    <div v-for="element in deletedElements" :key="element.id">
      <input type="hidden" :name="`playlist[playlist_elements_attributes][][id]`" :value="element.id">
      <input type="hidden" :name="`playlist[playlist_elements_attributes][][_destroy]`" :value="1">
    </div>
    <slide-x-right-transition mode="out-in" :duration="200" name="slide-fade">
      <div v-if="showComponent === 'list' && elements.length > 0" :key="'list'">
        <div class="playlist-header">
          <div class="headline"> {{ $t('playlist.playlist_elements')}}</div>
          <div class="info">{{ $tc('playlist.element', elements.length) }} - {{ totalDuration | secondsToMinuteString }} </div>
        </div>
        <draggable v-model="elements" v-bind="dragOptions">
            <playlist-element v-for="element in elements" :key="element.id" :element="element" :position="elements.indexOf(element) + 1"></playlist-element>
        </draggable>
        <div class="add-element-btn">
        <button @click.prevent="showSearchComponent()" class="btn btn-default btn-flat add-element-btn">{{ $t("playlist.add_element")}} </button>
        </div>
      </div>
      <div v-else :key="'search'">
        <search-component :options="searchOptions" @close="showListComponent()" @selectresult="addElementToPlaylist($event)"></search-component>
      </div>
    </slide-x-right-transition>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { SlideXRightTransition } from 'vue2-transitions';
import PlaylistElement from './element.vue';
import { mapGetters, mapActions} from 'vuex';
import { Getters } from '../../store/getters';
import { Actions } from '../../store/actions';
import SearchComponent from '../../../search_component/search_component.vue';
import { v4 as uuid } from 'uuid';

export default {
  components: {
    'playlist-element': PlaylistElement,
    draggable,
    SlideXRightTransition,
    SearchComponent
  },
  methods: {
    showSearchComponent() {
      this.showComponent = 'search'
    },
    showListComponent() {
      this.showComponent = 'list'
    },
    addElementToPlaylist(searchResult) {
      const element = {
        id: uuid(),
        title: searchResult.title,
        thumbnailUrl: searchResult.thumbnailUrl,
        elementId: searchResult.type === 'content' ? searchResult.actableId : searchResult.id,
        elementType: searchResult.type == 'content' ? 'Topic' : 'Media::Clip',
        author: searchResult.author,
        updated: searchResult.updated,
        displaytype: searchResult.displaytype,
        searchType: searchResult.type, 
        duration: searchResult.duration,
        position: null,
        createdAt: null,
        updatedAt: null
      };
      this.$store.dispatch(Actions.ADD_ELEMENT, element);
      this.$store.dispatch(Actions.SHOW_COMPONENT, 'list');
    }
  },
  computed: {
    ...mapGetters({
      deletedElements: Getters.DELETED_ELEMENTS,
      totalDuration: Getters.TOTAL_DURATION,
      searchUrl: Getters.SEARCH_URL
    }),
    elements: {
      get: function () {
        return this.$store.getters[Getters.ELEMENTS];
      },
      set: function (values) {
        this.$store.dispatch(Actions.SET_ELEMENTS, values);
      }
    },
    showComponent: {
      get: function () {
        return this.$store.getters[Getters.SHOW_COMPONENT];
      },
      set: function (value) {
        this.$store.dispatch(Actions.SHOW_COMPONENT, value);
      }
    },
    dragOptions() {
      return {
        handle: '.draghandle',
        animation: 100
      }
    },
    searchOptions: {
      get: function() {
        return {
          title: this.$i18n.t('playlist.select_topic_or_clip'),
          closeButton: true,
          searchUrl: this.searchUrl,
          indices: ['topic', 'clip'],
          filterOptions: {
            sortOptions: ['best_match', 'newest', 'oldest'],
            filters: ['all', 'own_only']
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.playlist-component {
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
}

.search-component {
  max-width: 800px;
  margin: 0 auto;
}

.add-element-btn {
  text-align: center;
}

.playlist-header {
  border-bottom: 1px solid $color-light-grey-2;
  font-size: 24px;
}

.info {
  font-size: 13px;
  color: $black-54-opacity;
  margin-bottom: 8px;
}
</style>
