import mutations from './mutations';
import actions from './actions';
import getters from './getters';

// Initial state
const state = {
  uiState: 'lessonList',
  lessonEditor: {
    lessonType: null,
    lesson: null,
    lessonGroupId: null,
    questions: [],
    currentQuestion: null,
    mediaData: null,
    subject: {
      selected: false,
      subjectType: null,
      subjectId: null,
      title: '',
      description: '',
      previewHtml: ''
    }
  },
  lessons: [],
  lessonGroups: [],
  dirty: false
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
