import type { Store } from 'vuex';
import type { Settings, State } from './state';
import store from './index';
import { BACKGROUND_IMAGES } from '../../common/background_replacement/constants/background_images';
import { Mutations } from './mutations';

const baseLsKey = 'capture_settings';

function lsKey() {
  return `${baseLsKey}_${store.state.appData!.userId}`;
}

export function getSettingsFromLS(): Partial<Settings> {
  // the getItem with baseLsKey can be removed after certain time when all non-personalized keys got migrated to personalized keys (03-08-2022)
  const lsValue = window.localStorage.getItem(lsKey()) || window.localStorage.getItem(baseLsKey);

  if (!lsValue) {
    return {};
  }

  try {
    const settings = JSON.parse(lsValue) as Partial<Settings>;

    if (
      settings.backgroundReplacement
      && !(
        settings.backgroundReplacement === 'BLUR'
        || settings.backgroundReplacement === 'BLUR_SOFT'
        || settings.backgroundReplacement === 'TRANSPARENT'
        || BACKGROUND_IMAGES.find((background) => background === settings.backgroundReplacement)
        || store.getters.customBackgroundImageIds.find((background: string) => background === settings.backgroundReplacement)
      )
    ) {
      settings.backgroundReplacement = undefined;
    }

    return settings;
  } catch (e) {
    if (e instanceof Error && e.name === 'SyntaxError') {
      console.error('Stored Settings could not be parsed:', e.message);
    } else {
      throw e;
    }
  }

  return {};
}

export default (store: Store<State>): void => {
  store.subscribe((mutation, state) => {
    // Second condition can be removed after certain time when all non-personalized keys got migrated to personalized keys (03-08-2022)
    if (mutation.type.startsWith('SET_SETTING_') || mutation.type === Mutations.SET_SETTINGS) {
      window.localStorage.setItem(lsKey(), JSON.stringify(state.settings));
    }
  });
};
