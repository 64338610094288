import axios from 'axios';
const Component = videojs.getComponent('Component');
const QUMU_GET_PROGRAM_ENDPOINT = '/viewerportal/services/rest/video/programs';

class QumuHandler extends Component {
  constructor(source, tech, options) {
    super(tech, options);
    this.qumuOptions = JSON.parse(tech.el().dataset.qumu);
    
    if (this.qumuOptions.ssoUrl) {
      // Handle out a SSO ticket before requesting program details
      const iframe = this.getSSOIFrame();

      iframe.onload = () => {
        this.fetchVideoSource(source, tech);
      };
    } else {
      this.fetchVideoSource(source, tech);
    }
  }

  async fetchVideoSource(source, tech) {
    const mediaLink = await this.getMediaLink(source.src);
    tech.src(mediaLink);
  }

  async getMediaLink(programId) {
    let mediaLink = '';
    const getProgramUrl = `${this.qumuOptions.endpoint}/viewerportal/services/rest/video/programs/${programId}`;

    try {
      const response = await axios.get(getProgramUrl, { withCredentials: true });
      mediaLink = response.data.vodMediaStreams[0].mediaLink;
    } catch (error) {
      console.error(error);
    }

    return mediaLink;
  }

  getSSOIFrame() {
    let iframe = document.getElementById('qumu_sso_iframe');

    if (!iframe) {
      iframe = document.createElement('iframe');
      iframe.id = 'qumu_sso_iframe';
      iframe.src = this.qumuOptions.ssoUrl;
      iframe.seamless = true;
      iframe.frameBorder = 0;
      iframe.width = 0;
      iframe.height = 0;
      document.body.appendChild(iframe);
    }

    return iframe;
  }
}

const QumuSourceHandler = {
  name: 'qumu',
  canHandleSource(source) {
    if (/^esc_program:\d+/.test(source.src)) {
      return 'probably';
    }

    return '';
  },
  handleSource(source, tech, options) {
    tech.qumu = new QumuHandler(source, tech, options);
    return tech.qumu;
  },
  canPlayType(type)  {
    if (/^video\/qumu/.test(type)) {
      return 'probably';
    }

    return '';
  }
};

export {
  QumuHandler,
  QumuSourceHandler
};
