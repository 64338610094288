import BaseWidget from './base_widget';

export default class WordCloudWidget extends BaseWidget {
  constructor(options) {
    super(options);
    this.type = 'WordCloud';
  }

  static get defaultParams() {
    return {
      title: '',
      show_title: false,
      word_category: 'tags',
      word_limit: '50',
      word_shape: 'elliptic',
      timespan: 'total'
    };
  }

  makeEditable() {
    super.makeEditable();
  }

  update(params) {
    super.update(params);
  }

  initialize() {
    super.initialize();
    let content = this.element.querySelector('.landing-page-widget-content');
    var dataUrl;
    var handler;

    if (this.params.word_category === "tags") {
      dataUrl = content.dataset.findTagsUrl + `?limit=${this.params.word_limit}&timespan=${this.params.timespan}`;
      handler = function (id) {
        let handlerUrl = content.dataset.tagUrl.replace("id", id);
        window.open(handlerUrl, "_self");
      };
    } else if (this.params.word_category === "topic_areas") {
      dataUrl = content.dataset.findTopicAreasUrl + `?limit=${this.params.word_limit}&timespan=${this.params.timespan}`;
      handler = function (id) {
        let handlerUrl = content.dataset.topicAreaUrl.replace("id", id);
        window.open(handlerUrl, "_self");
      };
    } else if (this.params.word_category === "users") {
      dataUrl = content.dataset.findUsersUrl + `?limit=${this.params.word_limit}&timespan=${this.params.timespan}`;
      handler = function (id) {
        let handlerUrl = content.dataset.userUrl.replace("id", id);
        window.open(handlerUrl, "_self");
      };
    } else if (this.params.word_category.includes("metadata_field_")) {
      let metadata_field_id = this.params.word_category.replace('metadata_field_', '');
      dataUrl = content.dataset.findMetadataFieldsUrl + `?metadata_field_id=${metadata_field_id}&limit=${this.params.word_limit}&timespan=${this.params.timespan}`;
      handler = function (id) {
        let handlerUrl = content.dataset.metadataUrl.replace("field_id", metadata_field_id).replace("id", id);
        window.open(handlerUrl, "_self");
      };
    }

    fetch(dataUrl).then(r => r.json())
      .then(data => {
        data.forEach(function (e) {
          e.handlers = {
            click: function () {
              handler(e.id);
            }
          };
        });
        $(content).jQCloud(data, {
          autoResize: true,
          shape: this.params.word_shape
        });
      })
      .catch(e => console.log(e));
  }
}

