import BaseWidget from './base_widget';

export default class TextBlockWidget extends BaseWidget {
  constructor(options) {
    super(options);

    this.type = 'TextBlock';
  }

  static get defaultParams() {
    return {
      title: '',
      show_title: false,
      text : ''
    };
  }

  makeEditable() {
    super.makeEditable();

    this.content.setAttribute('contenteditable', true);
    let shouldSanitize = $(this.content).data("sanitize");
    let baseurl = _app.info.rootUrl + _app.info.site;

    $(this.content).kendoEditor({
      pasteCleanup: {
        keepNewLines: true
      },
      tools: [
        "formatting",
        "cleanFormatting",
        "bold",
        "italic",
        "underline",
        "insertUnorderedList",
        "insertOrderedList",
        "indent",
        "outdent",
        "justifyLeft",
        "justifyCenter",
        "justifyRight",
        "justifyFull",
        "createLink",
        "unlink",
        "insertImage",
        "tableWizard",
        "createTable",
        "addColumnLeft",
        "addColumnRight",
        "addRowAbove",
        "addRowBelow",
        "deleteRow",
        "deleteColumn"
      ],
      imageBrowser: {
        transport: {
          read: baseurl + '/image_browser/read',
          thumbnailUrl: baseurl + '/image_browser/thumbnail',
          uploadUrl: baseurl + '/image_browser/upload',
          imageUrl: baseurl + '/image_browser/image?path={0}',
          destroy: {
            url: baseurl + '/image_browser/destroy',
            type: "POST"
          }
        }
      },
      pasteCleanup: {
        custom: function(html) {
          return shouldSanitize ? VERSTEHE.Globals.Sanitizer.sanitize(html) : html;
        }
      },
      deserialization: {
        custom: function(html) {
          return shouldSanitize ? VERSTEHE.Globals.Sanitizer.sanitize(html) : html;
        }
      },
      serialization: {
        custom: function(html) {
          return shouldSanitize ? VERSTEHE.Globals.Sanitizer.sanitize(html) : html;
        }
      }
    });
    this.editor = $(this.content).data("kendoEditor");

    // for some reason the focus event does not work properly when the kendo editor is wrapped in the widget
    $(this.editor.body).click(() => $(this.editor.body).focus());

    // Disable draggable as long as the editor has focus
    $(this.editor.body).focus( (e) => {
      $(this.wrapper).draggable('disable');
    });

    $(this.editor.body).blur( (e) => {
      $(this.wrapper).draggable('enable');
    });

    // keyup event is triggered through text changes
    this.editor.bind("keyup", this.editorChanged.bind(this));

    // select event is triggered after format changes
    this.editor.bind("select", this.editorChanged.bind(this));
  }

  resized() {
    this.editor.toolbar.window.setOptions({
      width: this.content.clientWidth,
      animation: {
        close: false
      }
    });

    this.editor.toolbar.hide();
  }

  editorChanged() {
    let text = this.editor.value();

    if (this.params.text !== text) {
      this.grid.dirty = true;
      this.setParam('text', text);
    }
  }

  update(params) {
    params.text = this.editor.value();
    super.update(params);
  }

  initialize() {
    super.initialize();
    this.content = this.element.querySelector('.landing-page-widget-content');
  }

  // overwrites base widget render function
  render() {
    let content = this.wrapper.querySelector('.grid-stack-item-content');
    const wrapper = document.createElement('div');
    wrapper.classList.add('landing-page-widget', 'landing-page-widget-text-block');

    const innerWrapper = document.createElement('div');
    innerWrapper.classList.add('landing-page-widget-content');
    innerWrapper.setAttribute('data-baseurl', content.getAttribute('data-baseurl'));
    innerWrapper.setAttribute('data-sanitize', content.getAttribute('data-sanitize'));

    content.appendChild(wrapper);
    wrapper.appendChild(innerWrapper);
    innerWrapper.innerHTML = this.params.text;

    this.initialize();

    if (this.options.editable) {
      this.makeEditable();
    }
  }
}

