/*
 * Both enum values correspond with the values from app\models\interface\amor.rb
 */
export enum ResourceType {
  Screencast = 'screencast',
  Webcam = 'webcam',
  Hybrid = 'hybrid',
  Window = 'window',
  Slide = 'slide',
  Audio = 'audio',
  Livestream = 'livestream',
  Conference = 'conference',
  Unknown = 'unknown',
}

export enum ResourceSource {
  External = 'external',
  Mobile = 'mobile',
  BigBlueButton = 'bigbluebutton',
  Capture = 'capture',
  Studio = 'studio',
  System = 'system',
}