import { Dashboard } from './dashboard';
import { Container } from './widget/container';

export function init() {
  let dahsboard = new AnalyticContributionDashboard();
}

class AnalyticContributionDashboard extends Dashboard {

  constructor() {
    super();
    this.datePickerContainer = document.querySelector('.dashboard-interval-picker');
    this.container = [];
    this.contribution_token = document.querySelector('.contribution-dashboard').getAttribute('data-token');
    this.initWidgetContainer();
    this.bindListener();
  }

  initWidgetContainer() {
    document.querySelectorAll('.widget-container').forEach(e => {
      this.container.push(new Container(e, this));
    });
  }

  refreshContainer() {
    this.container.forEach( c => {
      c.loadWidgets();
    });
  }

  bindListener() {
    //Listener for Custom Filter and Interval changes
    document.addEventListener('filterChanges', e => {
      this.refreshContainer();
    });
  }
}
