import { Tree } from './tree';

export class TopicAreaTree extends Tree {
  onChecked (e) {
    super.onChecked(e);
    this.buildPrimarySelectOptions();
  }

  onready() {
    this.buildPrimarySelectOptions();
  }

  onSelectNode(e) {
    super.onSelectNode(e);
    this.buildPrimarySelectOptions();
  }

  buildPrimarySelectOptions() {
    let primaryControl = this._wrapper.querySelector('.tree-primary-node-form-control');
    let primarySelect = this._dialog.querySelector('.tree-primary-node-select .node-list');
    let checkedItems = this.getChecked();
    let primary = (primaryControl !== null) ? parseInt(primaryControl.value, 10) : 0;

    if (primarySelect !== null ) {
      primarySelect.innerHTML = '';
      for (let i = 0; i < checkedItems.length; i++) {
        let option = this.buildPrimaryItemRadioOpt(checkedItems[i]);
        primarySelect.appendChild(option);

        if (primary === checkedItems[i].id) {
          option.querySelector('input').checked = true;
        }
      }

      this.checkIsPrimaryTopicAreaChecked(primarySelect);
    }

    $.material.init();
  }

  buildPrimaryItemRadioOpt (item) {
    let wrapper = document.createElement('div');
    let label = document.createElement('label');
    let input = document.createElement('input');
    let title = document.createTextNode(this.buildPath(item));

    wrapper.className = 'radio radio-primary';
    input.type = 'radio';
    input.name = 'primary_node';
    input.value = item.id;

    wrapper.appendChild(label);
    label.appendChild(input);
    label.appendChild(title);

    return wrapper;
  }

  buildPath (item) {
    let path = item.title;

    while (item = item.parentNode()) {
      path = `${item.title} / ${path}`;
    };

    return path;
  }

  setSelectedElementsHelper(elements) {
    super.setSelectedElementsHelper(elements, false);
    this.setPrimaryTopicArea();

    // Detect changes in case of Topic
    if (VERSTEHE.TopicManage) {
      VERSTEHE.TopicManage.checkReviewNeeded();
    }
    if(VERSTEHE.Course?.manage){
      VERSTEHE.Course.manage.check();
    }
  }

  setPrimaryTopicArea() {
    let nodeList = this._dialog.querySelector('.tree-primary-node-select .node-list');

    if (nodeList === null) {
      return;
    }

    let primary = nodeList.querySelector('input:checked');
    let newPrimary = this._valueLabelsWrapper.querySelector('span[data-id="' + primary.value + '"]');

    if(!newPrimary.classList.contains('primary-item')) {
      this.unsetPrimaryTopicArea();

      newPrimary.classList.add('primary-item');
      this._wrapper.querySelector('.tree-primary-node-form-control').value = primary.value;
    }
  }

  getPrimaryTopicAreaId() {
    return this._wrapper.querySelector('.tree-primary-node-form-control').value;
  }

  setPrimaryTopicAreaId(id) {
    this._wrapper.querySelector('.tree-primary-node-form-control').value = id;
  }

  unsetPrimaryTopicArea() {
    let primary = this._valueLabelsWrapper.querySelector('.primary-item');

    if (primary) {
      primary.classList.remove('primary-item');
    }
  }

  checkIsPrimaryTopicAreaChecked (primarySelect) {
    if (primarySelect.querySelector('input:checked') === null && primarySelect.querySelector('input') !== null) {
      primarySelect.querySelector('input').checked = true;
    }
  }
}
