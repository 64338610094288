import Vue from 'vue';

export default {
  setParameters(state, parameters) {
    state.parameters = parameters;
  },

  setCourse(state, course) {
    Vue.set(state, 'course', course);
  },

  setLessonDetail(state, { lesson, lessonDetail }) {
    Vue.set(lesson, 'detail', lessonDetail);
    Vue.set(lesson, 'loaded', true);
  },

  updateLessonProgress(state, { lesson, progress }) {
    if (progress > lesson.progress) {
      lesson.progress = progress;
    }
  },

  setCurrentPlaylistElement(state, playlistElement) {
    Vue.set(state, 'currentPlaylistElement', playlistElement);
  },

  setCurrentPlaylistElementProgress(state, progress) {
    if (state.currentPlaylistElement && state.currentPlaylistElement.progress < progress) {
      state.currentPlaylistElement.progress = progress;
    }
  },

  setQuizCompleted(state) {
    Vue.set(state.course.quiz, 'isCompleted', true);
  },

  setCompleted(state, completedAt) {
    Vue.set(state.course, 'completedAt', completedAt);
  },

  setCourseConfirmation(state, { needsConfirmation, confirmedAt, confirmUrl }) {
    if (needsConfirmation !== undefined) {
      Vue.set(state.course, 'needsConfirmation', needsConfirmation);
    }

    if (confirmedAt !== undefined) {
      Vue.set(state.course, 'confirmedAt', confirmedAt);
    }

    if (confirmUrl !== undefined) {
      Vue.set(state.course, 'confirmUrl', confirmUrl);
    }
  }
};
