export const locals = {
  de: {
    action_edit: 'Bearbeiten',
    action_adopt: 'Annehmen',
    action_reject: 'Ablehnen',
    action_show: 'Ansehen',
    action_history: 'Verlauf',
    action_manual_assign: 'Manuelle Zuweisung',
    and: 'und',
    author: 'Erstellt von',
    bulk_change_message: '#= affected # # if (affected == 1) {#Eintrag wurde geändert.#} else {#Einträge wurden geändert#} #',
    change: 'Ändern',
    contains: 'Beinhaltet',
    contribution: 'Beitrag',
    created_at: 'Eingereicht am',
    eq: 'ist',
    neq: 'ist nicht',
    evaluation_state: 'Status',
    evaluation_approved: 'Ergebnis',
    is_after_or_equal_to: 'gleich oder später ist',
    is_before_or_equal_to: 'gleich oder früher ist',
    is_equal_to: 'gleich ist',
    updated_at: 'Aktualisiert am',
    state_in_progress: 'In Bearbeitung',
    state_submitted: 'Eingereicht',
    state_approved: 'Genehmigt',
    state_denied: 'Abgelehnt',
    state_pending: 'Ausstehend',
    state_new: 'Eingereicht',
    state_done: 'Abgeschlossen',
    primary_topicarea: 'Primärer Themenbereich',
    topic_areas: 'Themenbereiche',
    filter_state_is_not_done: 'Keine Abgeschlossenen'
  },
  en: {
    action_edit: 'Edit',
    action_adopt: 'Accept',
    action_reject: 'Reject',
    action_show: 'Show',
    action_history: 'History',
    action_manual_assign: 'Manual Assignment',
    and: 'and',
    author: 'Created by',
    bulk_change_message: '#= affected # # if (affected == 1) {#Entry was changed.#} else {#Entries were changed.#} #',
    change: 'Change',
    contains: 'Contains',
    contribution: 'Contribution',
    created_at: 'Submitted on',
    eq: 'equals',
    neq: 'is not equal',
    evaluation_state: 'Status',
    evaluation_approved: 'Result',
    is_after_or_equal_to: 'Is after or equal to',
    is_before_or_equal_to: 'Is before or equal to',
    is_equal_to: 'Is equal to',
    inactive_contribution: 'Inactive contribution',
    updated_at: 'Updated at',
    state_in_progress: 'In process',
    state_submitted: 'Submitted',
    state_approved: 'Approved',
    state_denied: 'Denied',
    state_pending: 'Pending',
    state_new: 'Submitted',
    state_done: 'Completed',
    topic_areas: 'Topic Areas',
    primary_topicarea: 'Primary topic area',
    filter_state_is_not_done: 'Not completed'
  },
  es: {
    action_edit: 'Corregir',
    action_adopt: 'Aceptar',
    action_reject: 'Rechazar',
    action_show: 'Mostrar',
    action_history: 'Historial',
    action_manual_assign: 'Asignación manual',
    and: 'y',
    author: 'Creado por',
    bulk_change_message: '#= affected # # if (affected == 1) {#Se ha modificado la entrada.#} else {#Se han modificado las entradas.#} #',
    change: 'Cambiar',
    contains: 'Contiene',
    contribution: 'Contribución',
    created_at: 'Presentado el',
    eq: 'inguales',
    neq: 'no es igual',
    evaluation_state: 'Estado',
    evaluation_approved: 'Resultado',
    is_after_or_equal_to: 'Es igual o posterior a',
    is_before_or_equal_to: 'Es anterior o igual a',
    is_equal_to: 'Es igual a',
    inactive_contribution: 'Contribución inactiva',
    updated_at: 'Actualizado en',
    state_in_progress: 'En proceso',
    state_submitted: 'Remitida',
    state_approved: 'Aprobadas',
    state_denied: 'Denegadas',
    state_pending: 'Pendiente',
    state_new: 'Enviada',
    state_done: 'Completados',
    topic_areas: 'Àrea temática',
    primary_topicarea: 'Área temática principal',
    filter_state_is_not_done: 'No completado'
  }
};

export function t(key) {
  return locals[_app.info.currentLocale][key] || key;
}

export function tt(key, variables = {}) {
  let template = kendo.template(t(key));
  return template(variables);
}
