import { t } from '../configurations/localization';

export function stateIsNotDone (filter) {
  let column = filter.getFilterColumn('evaluation_state');

  return filter.buildFieldItem({ title: t('filter_state_is_not_done') }, null, () => {
    let filterContraint = {
      field: 'evaluation_state',
      operator: 'neq',
      value: 'done',
      readOnly: true
    };

    column.filterMenu.filter({ filters: [filterContraint] });
    filter.fieldSelect.close();
  });
}
