import { Video } from './video';

export class Clip extends Video {
  constructor(player_id) {
    super();
    VERSTEHE.media.disposePlayer(player_id);
    this.player_id = player_id;
    this.element = document.getElementById(player_id);
    this.player = null;
    this.viewed = false;
    this.tracking = this.element.getAttribute('data-tracking');
    this.initPlayer();
  }

  initPlayer() {
    this.player = videojs(this.player_id, {
      language: this.element.getAttribute('data-locale'),
      fluid: true,
      playbackRates: [0.5, 1, 1.25, 1.5, 2],
      example_option: true,
      html5: {
        nativeTextTracks: false
      },
      plugins: {
        theatreMode: {
          resolution: 720
        }
      }
    });

    this.player.ready(() => {
      this.initThumbnails();
      this.initSources();
      this.initMarkers();
      this.initOverlay();
      this.initListeners();

      const disableRmb = this.element.getAttribute('data-disable-rmb');

      if (disableRmb === 'true') {
        this.player.on('contextmenu', (e) => {
          e.preventDefault();
        });
      }

      this.player.on('mouseleave', () => {
        this.player.userActive(false);
      });

      // Add quality selector after time select
      this.player.controlBar.addChild('QualitySelector', {}, 6);
    });
  }

  initSources() {
    this.player.setupVideoSources();
  }

  initMarkers() {
    this.player.markers({
      marker_breaks: [0],
      marker_text: [''],
      marker_overlay: [this.element.getAttribute('data-clip-title')],
      setting: {
        markerStyle: {
          'display': 'none'
        },
        markerTip: {
          default_text: "",
          show_colon: false
        },
        breakOverlay: {
          display: true,
          default_text: "",
          show_colon: false,
          style: {
            'height': '2.4em',
            'top': '0',
            'background-color': 'rgba(0, 0, 0, 0.54)'
          }
        },
        forceInitialization: true
      }
    });
  }

  setClipViewed(async) {
    if (!this.viewed) {
      if (this.tracking && _app.info.user.loggedIn && (typeof this.player !== 'undefined')) {
        $.ajax({
          url: this.element.getAttribute('data-viewed-url'),
          type: 'POST',
          async: async,
          success: () => { this.viewed = true; }
        });
      }
    }
  }

  setClipCompleted(async) {
    if (this.tracking && _app.info.user.loggedIn && (typeof this.player !== 'undefined')) {
      $.ajax({
        url: this.element.getAttribute('data-completed-url'),
        type: 'POST',
        success: function() {
          $(player.el()).trigger('updatedProgress');
        },
        async: async
      });
    }
  }

  initListeners() {
    this.player.on('ended', () => {
      if (this.tracking) { this.setClipCompleted(true); }
    });
    this.player.on('firstplay', () => {
      if (this.tracking) { this.setClipViewed(true); }
    });
  }
}
