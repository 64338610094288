import axios from 'axios';
import { state } from '../store/state';
import { ModalLayout, ModalTypes } from '../entities/modalInstance';
import { ModalInstance } from '../entities/modalInstance';
import logger from '../../logger/logger';

const apiClient = axios.create({
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export default {

  async getRoomParticipants() {
    try {
      let res = await apiClient.get(state.urls.get_room_participants_url);
      return res.data.participants;
    } catch (error) {
      throw error;
    }
  },

  async getRoomStartingTime() {
    try {
      let res = await apiClient.get(state.urls.get_room_starting_time_url);
      return res.data.roomStartedAt;
    } catch (error) {
      throw error;
    }
  },

  async getAccesTokenAndJoin(userDetails) {
    let res = await apiClient.get(state.urls.new_access_token_url, {
      params: {
        userDetails: userDetails,
      },
    });
    return res.data.token;
  },

  async kickParticipant(identity: string) {
    await apiClient.get(state.urls.kick_participant_url, {
      params: {
        identity: identity,
      },
    });
  },

  async muteParticipant(identity: string) {
    await apiClient.get(state.urls.mute_participant_url, {
      params: {
        identity: identity,
      },
    });
  },

  async giveUserPresentatorRights(identity: string) {
    await apiClient.get(state.urls.set_user_as_presentator_url, {
      params: {
        identity: identity,
      },
    });
  },

  async setUserToDefaultRole(identity: string) {
    await apiClient.get(state.urls.set_user_to_default_url, {
      params: {
        identity: identity,
      },
    });
  },

  async setRaiseHand(identity: string, raisesHand: boolean) {
    await apiClient.get(state.urls.user_raises_hand_url, {
      params: {
        identity: identity,
        raisesHand: raisesHand,
      },
    });
  },

  async startRecording() {
    try {
      await apiClient.get(state.urls.start_recording_url);
      return true;
    } catch (error) {
      if (error.response.status === 403) {
        let modal: ModalInstance = {
          type: ModalTypes.RECORDING_ERROR,
          text: VERSTEHE.vueI18n.t('web_conference.error.recording_exist'),
          headline: VERSTEHE.vueI18n.t('web_conference.error.recording_exist_headline'),
          layout: ModalLayout.ACCEPT,
        };
        state.modals.push(modal);
      } else if (error.response.status === 406) {
        let modal: ModalInstance = {
          type: ModalTypes.RECORDING_ERROR,
          text: VERSTEHE.vueI18n.t('web_conference.error.no_authorisation'),
          headline: VERSTEHE.vueI18n.t('web_conference.error.no_authorisation_headline'),
          layout: ModalLayout.ACCEPT,
        };
        state.modals.push(modal);
      } else if (error.response.status === 500) {
        let modal: ModalInstance = {
          type: ModalTypes.RECORDING_ERROR,
          text: VERSTEHE.vueI18n.t('web_conference.error.server_error'),
          headline: VERSTEHE.vueI18n.t('web_conference.error.server_error_headline'),
          layout: ModalLayout.ACCEPT,
        };
        state.modals.push(modal);
      }
      return false;
    }
  },

  async endRecording() {
    let res = null;
    try {
      res = await apiClient.get(state.urls.end_recording_url);
      return true;
    } catch (error) {
      if (error.response.status === 403) {
        let modal: ModalInstance = {
          type: ModalTypes.RECORDING_ERROR,
          text: VERSTEHE.vueI18n.t('web_conference.error.no_recording_exist'),
          headline: VERSTEHE.vueI18n.t('web_conference.error.no_recording_exist_headline'),
          layout: ModalLayout.ACCEPT,
        };
        state.modals.push(modal);
      }
      return false;
    }
  },

  async acceptGuest(identity: string) {
    try {
      await apiClient.get(state.urls.accept_guest_url, {
        params: {
          identity: identity,
        },
      });
    } catch (error) {
      logger.error({
        message: 'acceptGuest failed',
        error,
        section: 'ConferenceService:acceptGuest',
      });
    }
  },

  async saveMessage(payload) {
    await apiClient.get(state.urls.save_chat_message_url, {
      params: {
        payload: payload,
      },
    });
  },

  async getMessages() {
    let res = await apiClient.get(state.urls.get_all_chat_messages_url);
    return res.data.response;
  },

  async checkRecordingStatus() {
    let res = await apiClient.get(state.urls.get_recording_status_url);
    return res.data.response;
  },

  async userAllowsRecording(identity: string) {
    await apiClient.get(state.urls.user_allows_recording_url, {
      params: {
        identity: identity,
      },
    });
  },

  async startBreakout(roomList) {
    try {
      await apiClient.get(state.urls.start_breakout_url, {
        params: {
          participants: roomList,
        },
      });
    } catch (error) {
      state.breakoutStarted = false;
      let modal: ModalInstance = {
        type: ModalTypes.RECORDING_ERROR,
        text: VERSTEHE.vueI18n.t('web_conference.error.breakout_start_error'),
        headline: VERSTEHE.vueI18n.t('web_conference.error.breakout_error_headline'),
        layout: ModalLayout.ACCEPT,
      };
      state.modals.push(modal);
    }
  },

  async stopBreakout() {
    try {
      state.breakoutStarted = false;
      await apiClient.get(state.urls.stop_breakout_url);
    } catch (error) {
      state.breakoutStarted = true;
      let modal: ModalInstance = {
        type: ModalTypes.RECORDING_ERROR,
        text: VERSTEHE.vueI18n.t('web_conference.error.breakout_stop_error'),
        headline: VERSTEHE.vueI18n.t('web_conference.error.breakout_error_headline'),
        layout: ModalLayout.ACCEPT,
      };
      state.modals.push(modal);
    }
  },

  async breakoutDetails() {
    let res = await apiClient.get(state.urls.breakout_details_url);
    return res.data;
  },

  async switchParticipantToBreakout(participant: { identity: string, sid: string, metadata: string }, roomNumber) {
    await apiClient.get(state.urls.switch_participant_to_breakout_url, {
      params: {
        participant: participant,
        roomNumber: roomNumber,
      },
    });
  },

  async switchParticipantToMainRoom(participant: { identity: string, sid: string, metadata: string }) {
    await apiClient.get(state.urls.switch_participant_to_mainroom_url, {
      params: {
        participant: participant,
      },
    });
  },

  async createBreakoutRoom(roomNumber) {
    await apiClient.get(state.urls.create_breakout_room_url, {
      params: {
        roomNumber: roomNumber,
      },
    });
  },

  async closeBreakoutRoom(roomNumber) {
    await apiClient.get(state.urls.close_breakout_room_url, {
      params: {
        roomNumber: roomNumber,
      },
    });
  },
};
