<template>
<div class="question-answer">
  <div
    class="answer-option"
    v-for="option in options"
    :class="{ 'was-wrong': valueBeforeSolution === option.id && !option.correct, 'is-correct' : isSolutionShown && option.correct }"
    :key="option.id">
    <div class="radio">
      <label>
        <input type="radio" v-model="value" :disabled="isSolutionShown" :value="option.id">
        {{ option.title }}
      </label>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'single-select',
  props: {
    options: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      value: null,
      valueBeforeSolution: null,
      isSolutionShown: false
    }
  },
  mounted() {
    $.material.init();
  },
  methods: {
    isCorrect() {
      return this.options.find(option => option.correct).id === this.value;
    },
    showSolution() {
      this.valueBeforeSolution = this.value;

      // Mark correct option
      this.value = this.options.find(option => option.correct).id

      this.isSolutionShown = true;
    }
  }
}
</script>

<style lang="scss" scoped>
.answer-option {
  background: $translucent-white-light;
  margin: 10px 0;
  border-radius: 3px;
  border: 2px solid transparent;

  &:hover {
    background: $white-15-opacity;
  }

  &.is-correct {
    border-color: var(--primary-color);
  }

  &.was-wrong {
    border-color: $color-error-red;
  }
}

.radio {
  margin: 0;

  label {
    display: block;
    padding: 10px 10px 10px 45px;
    font-size: 16px;
    color: $color-white;
  }

  ::v-deep span {
    top: 14px;
    left: 14px;
  }

  ::v-deep .circle {
    border-color: $white-70-opacity;
  }

  ::v-deep input[type="radio"][disabled] {
    ~ .check {
      background-color: var(--primary-color);
    }

    ~ .circle {
      border-color: var(--primary-color);
    }
  }
}
</style>
