import { ActionTree } from "vuex";
import { MatManageState } from "./state";
import { RootState } from "../../../store/types";
import { Mutations } from "./mutations";

export const Actions = {
  SET_STATE: 'mediaAssemblingTemplate/setState',
  SET_TEMPLATE_ATTRIBUTES: 'mediaAssemblingTemplate/setTemplateAttributes',
  SET_SLIDE_ATTRIBUTES: 'mediaAssemblingTemplate/setSlideAttributes',
  SET_ELEMENT_ATTRIBUTES: 'mediaAssemblingTemplate/setElementAttributes',
  ADD_ELEMENT: 'mediaAssemblingTemplate/addElement',
  TRANSFORM_ELEMENT: 'mediaAssemblingTemplate/transformElement',
  REMOVE_ELEMENT: 'mediaAssemblingTemplate/removeElement',
  COPY_SLIDE: "mediaAssemblingTemplate/copySlide"
};

export const actions: ActionTree<MatManageState, RootState> = {
  setState({ commit }, payload: MatManageState) {
    commit(Mutations.SET_STATE, payload);
  },
  setTemplateAttributes({ commit }, payload) {
    commit(Mutations.SET_TEMPLATE_ATTRIBUTES, payload);
  },
  setSlideAttributes({ commit }, payload: { key: string, attributes }) {
    commit(Mutations.SET_SLIDE_ATTRIBUTES, payload);
  },
  addElement({ getters, commit }, payload: { slideKey: string, elementId: string, x: number, y: number }) {
    const configElement = getters.templateConfigElements.find((element) => element.id === payload.elementId);
    let element = Object.assign({}, configElement);
    Object.assign(element, { top: payload.y, left: payload.x });
    commit(Mutations.ADD_ELEMENT, { slideKey: payload.slideKey, element: element });
  },
  transformElement({ commit }, payload: { slideKey: string, elementId: string, x: number, y: number, width?: number }) {
    commit(Mutations.TRANSFORM_ELEMENT, payload);
  },
  removeElement({ commit }, payload: { slideKey: string, elementId: string }) {
    commit(Mutations.REMOVE_ELEMENT, payload);
  },
  setElementAttributes({ commit }, payload: { slideKey: string, elementId: string, attributes }) {
    commit(Mutations.SET_ELEMENT_ATTRIBUTES, payload);
  },
  copySlide({ commit }, payload: { sourceSlide: string, targetSlide: string }) {
    commit(Mutations.COPY_SLIDE, payload);
  }
};
