import { WorkflowStep, WorkflowSubstep, WorkflowState } from './interfaces';

// Initial state
const state: WorkflowState = {
  workflow: {
    steps: new Array<WorkflowStep>(),
    activeStep: null,
    activeSubstep: null
  }
}

// Getters
const getters = {
  indexOfStep: (state) => (uid: string) => {
    return state.workflow.steps.indexOf(state.workflow.steps.find(step => step.uid === uid));
  },
  activeStep: state => state.workflow.activeStep,
  activeSubstep: state => state.workflow.activeSubstep,
  getActiveSteps: (state) => {
    return {
      step: state.workflow.activeStep ? state.workflow.activeStep.uid : null,
      subStep: state.workflow.activeSubstep ? state.workflow.activeSubstep.uid : null,
    }
  },

  nextStepState(state) {
    const activeSubstep = state.workflow.activeSubstep;
    const activeStep = state.workflow.activeStep;
    let nextStep = null;
    let nextSubstep = null;

    if (activeSubstep && activeStep && (activeStep.substeps.length - 1) > activeStep.substeps.indexOf(activeSubstep)) {
      nextSubstep = activeStep.substeps[activeStep.substeps.indexOf(activeSubstep) + 1];
      nextStep = activeStep;
    } else if ((state.workflow.steps.length - 1) > state.workflow.steps.indexOf(activeStep)) {
      nextStep = state.workflow.steps[state.workflow.steps.indexOf(activeStep) + 1];
      if (nextStep.substeps.length > 0) {
        nextSubstep = nextStep.substeps[0];
      }
    }
    return { step: nextStep, substep: nextSubstep };
  },

  hasNextStep: (state, getters) => {
    if (getters.nextStepState.step === null) {
      return false;
    } else {
      if (getters.nextStepState.substep) {
        return getters.nextStepState.substep.disabled === false;
      } else {
        return getters.nextStepState.step.disabled === false;
      }
    }
  },

  isStepActive: (state) => (uid: string) => {
    return state.workflow.activeStep != null && state.workflow.activeStep.uid === uid;
  },

  isSubstepActive: (state) => (uid: string) => {
    return state.workflow.activeSubstep != null && state.workflow.activeSubstep.uid === uid;
  },

  getSubstep: (state) => (uid: string) => {
    const step = state.workflow.steps.find((step) => {
      return step.substeps.some(substep => substep.uid === uid)
    });

    if (step) {
      return step.substeps.find((substep) => substep.uid === uid);
    } else {
      return null;
    }
  },

  getStep: (state) => (uid: string) => {
    return state.workflow.steps.find((step) => step.uid === uid);
  },

  getByUid: (state, getters) => (uid: string) => {
    let step = getters.getStep(uid);
    if (!step) {
      step = getters.getSubstep(uid);
    }

    return step;
  },

  isStepDisabled: (state, getters) => (uid: string) => {
    return getters.getStep(uid).disabled === true;
  },

  isSubstepDisabled: (state, getters) => (uid: string) => {
    return getters.getSubstep(uid).disabled === true;
  },

  isStepPending: (state, getters) => (uid: string) => {
    const pending = getters.getStep(uid).pending;
    return pending === true || (typeof pending === 'string' && pending.length > 0);
  },

  isSubstepPending: (state, getters) => (uid: string) => {
    const pending = getters.getSubstep(uid).pending;
    return pending === true || (typeof pending === 'string' && pending.length > 0);
  },

  hasSubstepError: (state, getters) => (uid: string) => {
    return getters.getSubstep(uid).error === true;
  },

  hasStepError: (state, getters) => (uid: string) => {
    const step: WorkflowStep = getters.getStep(uid);
    return step.error === true || step.substeps.some(substep => substep.error === true);
  },

  pendingStepText: (state, getters) => (uid: string) => {
    const pending = getters.getStep(uid).pending;
    return typeof pending === 'string' ? pending : null
  },

  pendingSubstepText: (state, getters) => (uid: string) => {
    const pending = getters.getSubstep(uid).pending;
    return typeof pending === 'string' ? pending : null
  },

  firstWithError: (state: WorkflowState, getters) => {
    const firstErrorStep = state.workflow.steps.find(step => getters.hasStepError(step.uid));

    if (firstErrorStep && firstErrorStep.substeps.length > 0 && firstErrorStep.error != true) {
      return firstErrorStep.substeps.find(substep => getters.hasSubstepError(substep.uid)).uid;
    } else {
      return firstErrorStep ? firstErrorStep.uid : null;
    }
  }
}

const mutations = {
  setActiveSubstep(state, object) {
    state.workflow.activeSubstep = object;
  },

  addStep(state, data: { uid: string, active: boolean, disabled: boolean, error: boolean, pending: boolean|string }) {
    const step: WorkflowStep = { uid: data.uid, disabled: data.disabled, error: data.error, pending: data.pending, substeps: [] }; 
    state.workflow.steps.push(step);
    if (data.active && !data.disabled) {
      state.workflow.activeStep = step;
    }
  },

  addSubstep(state, data: { uid: string, active: boolean, disabled: boolean, error: boolean, pending: boolean|string, parentUid: string }) {
    const substep: WorkflowSubstep = { uid: data.uid, disabled: data.disabled, error: data.error, pending: data.pending };
    const parent = state.workflow.steps.find((step) => step.uid === data.parentUid);
    parent.substeps.push(substep);

    if((state.workflow.activeStep && state.workflow.activeStep.uid === parent.uid) 
        && parent.substeps[0].uid === substep.uid && !data.disabled) {
      state.workflow.activeSubstep = substep;
    }
  },

  removeSubstep(state, data: { parent: string, uid: string }) {
    const parent = state.workflow.steps.find(step => step.uid === data.parent);
    const index = parent.substeps.findIndex(step => step.uid === data.uid);
    parent.substeps.splice(index, 1);
  },

  setActiveStep(state, object) {
    state.workflow.activeStep = object;
  },

  enableStep(state, object) {
    object.disabled = false;
  },

  disableStep(state, object) {
    object.disabled = true;
  },

  setPendingStepState(state, data: { object: WorkflowStep | WorkflowSubstep, pending: boolean|string }) {
    data.object.pending = data.pending;
  },

  setErrorStepState(state, data: { object: WorkflowStep | WorkflowSubstep, error: boolean }) {
    data.object.error = data.error;
  }
}

const actions = {
  activateFirst(context) {
    context.dispatch('activateStep', { uid: state.workflow.steps[0].uid });
  },

  activateFirstWithError(context) {
    const firstWithError = context.getters.firstWithError;

    if (firstWithError) {
      context.dispatch('activateByUid', firstWithError);
    }
  },

  activateStep(context, payload: { uid: string }) {
    const activeStep = context.getters.getStep(payload.uid);
    if (activeStep && !activeStep.disabled && context.state.workflow.activeStep != activeStep) {
      context.commit('setActiveStep', activeStep);
      if (activeStep.substeps.length > 0) {
        context.commit('setActiveSubstep', activeStep.substeps.find((s) => !s.disabled));
      } else {
        context.commit('setActiveSubstep', null);
      }
    }
  },

  activateSubstep(context, payload: { uid: string }) {
    const substep = context.getters.getSubstep(payload.uid);
    if (substep && !substep.disabled) {
      const parent = context.state.workflow.steps.find((state) => state.substeps.some((s) => s.uid === substep.uid));
      if (context.state.workflow.activeStep != parent) {
        context.commit('setActiveStep', parent);
      }

      if (context.state.workflow.activeSubstep != substep) {
        context.commit('setActiveSubstep', substep);
      }
    }
  },

  activateByUid(context, uid: string) {
    const step = context.getters.getStep(uid);

    if (step) {
      context.dispatch('activateStep', { uid: uid});
    } else {
      context.dispatch('activateSubstep', { uid: uid });
    }
  },

  nextStep(context) {
    if (context.getters.hasNextStep) {
      const next = context.getters.nextStepState;
      context.commit('setActiveStep', next.step);
      context.commit('setActiveSubstep', next.substep);
    }

    if (context.getters.activeSubstep) {
      return context.getters.activeSubstep.uid;
    } else {
      return context.getters.activeStep.uid; 
    }
  },

  enableStep(context, payload: { uid: string }) {
    const step = context.getters.getByUid(payload.uid);

    if (step) {
      context.commit('enableStep', step);
    }
  },

  disableStep(context, payload: { uid: string }) {
    const step = context.getters.getByUid(payload.uid);

    if (step) {
      context.commit('disableStep', step);
    }
  },

  setPendingStepState(context, payload: { uid: string, pending: boolean|string }) {
    const step = context.getters.getByUid(payload.uid);

    if (step) {
      context.commit('setPendingStepState', { object: step, pending: payload.pending });
    }
  },

  setErrorStepState(context, payload: { uid: string, error: boolean }) {
    const step = context.getters.getByUid(payload.uid);

    if (step) {
      context.commit('setErrorStepState', { object: step, error: payload.error });
    }
  },

  removeSubstep(context, payload: { parent: string, uid: string }) {
    const isActive = context.getters.activeSubstep === payload.uid;
    if (isActive) {
      context.commit('setActiveSubstep', null);
    }

    context.commit('removeSubstep', payload);
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
