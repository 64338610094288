import { SideNavigation } from './side_navigation';

export let sideNavigation;

export function init() {
  sideNavigation = new SideNavigation();
  initMenuAim();
  bindListeners();
}

function initMenuAim() {
  $('#manage-dropdown-menu').menuAim({
    activate: function(row) {
      var $row = $(row);

      $row.siblings('.active').removeClass('active');
      $row.addClass('active');
    }
  });
}

function bindListeners() {
  $(document).on('click', '.explore-sidebar .taxonomy-dimension-select .dropdown-menu > a', function(e) {
    e.preventDefault();
    sideNavigation.loadDimension(this);
  });
}
