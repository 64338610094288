import BaseWidget from './base_widget';
import { KrTool } from '../global/tools';

export default class TableWidget extends BaseWidget {
  constructor(options) {
    super(options);

    this.type = 'table';
  }

  static get defaultParams() {
    return {
      title: '',
      show_title: true
    };
  }

  initialize() {
    super.initialize();
    this.renderTable(this.element.querySelector('.report-widget-content'));
  }

  /**
   * Render the chart
   */
  renderTable(element) {
    let targetUrl = element.getAttribute('data-target');
    let reportID = $(element).parents('.grid-stack-item').data('report');
    let reportType = $(element).parents('.grid-stack-item').data('type');
    let chartID = element.getAttribute('data-chart');
    let tableContainer = element.querySelector('.table-container');
    let interval = KrTool.setTillAndFrom();
    let elementHeight = element.clientHeight;

    //Set current locale
    VERSTEHE.kendoui.changeLocale(_app.info.currentLocale);

    // Add spinner
    $(tableContainer).append(this.busy);

    $.get(targetUrl, { id: chartID, report_id: reportID, report_type: reportType, till: interval['till'], from: interval['from'] }, (response) => {
      this.busy.remove();
      this.table = $(tableContainer).kendoGrid({
        columns: response.resultset.columns,
        dataSource: {
          data: response.resultset.data
        },
        height: elementHeight,
        toolbar: ["excel"],
        excel: {
          fileName: "Verstehe_Reporting_Export.xlsx"
        },
        groupable: true,
        scrollable: true,
        resizable: true,
        sortable: true,
        pageable: {
          pageSize: 10,
          buttonCount: 5
        }
      });
    });
  }

}
