export const validLessonGroup = (lessonGroup) => {
  return new Promise(resolve => {
    let valid = true;

    if (lessonGroup.lessonsCount === 0) {
      valid = false;
    }

    resolve({ valid });
  });
}
