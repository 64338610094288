const t = {
  de: {
    search_result: "Ergebnisse in "
  },
  en: {
    search_result: "Results in "
  },
  es: {
    search_result: "Resultados en "
  }
};

export class SearchResultSet {
  constructor(bar) {
    this.bar = bar;
  }

  processSearchResult (results, title) {
    let container = this.buildResultContainer();
    container.appendChild(this.buildHeader(title));
    let hr = document.createElement('hr');
    container.appendChild(this.buildListElement(results));
    container.appendChild(hr);
    return container;
  }

  buildHeader (title) {
    let container = document.createElement('div');
    container.innerHTML = t[_app.info.currentLocale].search_result + title;
    container.classList.add('search-result-header');

    return container;
  }

  buildResultContainer () {
    // create quicklink container
    let outerWrapper = document.createElement('div'); 
    outerWrapper.classList.add('search-result-wrapper');

    return outerWrapper;
  }

  buildListElement (results) {
    // create search result list
    let list = document.createElement('ul');
    results.forEach(result => {
      let listElement = document.createElement('li');
      let link = document.createElement('a');
      link.href = '#';
      link.innerText = result.title;
      link.setAttribute('data-id', result.id);
      result.source.bindSearchResultClickListener(link);
      this.dropdownRemoveListener(link);
      listElement.appendChild(link);
      list.appendChild(listElement);
    });
    
    return list;
  }

  dropdownRemoveListener (element) {
    element.addEventListener('click', () => {
      this.bar.removeSearchResults();
    });
  }
}
