import { ChangeAction } from "../../system_actions/change_action";

/**
 * SelectActions handle all changes to the state of select inputs
 * they always replace each other when regarding the same select element
 */
export class SelectAction extends ChangeAction {
  constructor(tabId, user, element, value) {
    super(tabId, user, element, value);
    this.value = value;
    this.actionType = "SelectAction";
  }

  execute() {
    $(this.element).val(this.value).trigger("change");
  }

  isComparableTo(other) {
    return this.element.is(other.element);
  }

  reduce(other) {
    return this;
  }

  static fromJson(json) {
    let action = JSON.parse(json);
    return new SelectAction(action.tabId, action.user, action.elementLocation, action.value);
  }

  static registerToElement() {
    $(document).on('change', '[data-collaborative-action*="SelectAction"]', function(event) {
      if (event.originalEvent) {
        SelectAction.createAction(SelectAction.getTabId(), _app.info.user.userId, event.target, this.value);
      }
    });
  }

  static createAction(tabId, user, element, value) {
    let action = new SelectAction(tabId, user, element, value);
    VERSTEHE.content_channel.send_message(action);
  }
}
