import { UIAction } from "./ui_action";

  /** ChangeActions are actions that handle changes of the content which make them different than before  */
export class ChangeAction extends UIAction {
  /**
   * @constructor
   * @param {string} tabId  - tabId of the tab the action originates from
   * @param {number} user - userId of the chat room owner
   * @param {String} element - element thats subject of the ChangeAction
   */
  constructor(tabId, user, element) {
    super(tabId, user, element);
  }
}
