<template>
<div>
  <contribution-lesson-form
    v-if="subjectType === 'Topic' || subjectType === 'Playlist'"
    key ="contributionLessonForm"
    :contribution-type="subjectType"></contribution-lesson-form>

  <external-lesson-form
    v-if="subjectType === 'Link'"
    key="externalLessonForm"></external-lesson-form>

  <custom-content-lesson-form
    v-if="subjectType === 'CustomContent'"
    key="customContentLessonForm"></custom-content-lesson-form>

  <elearning-package-lesson-form
    v-if="subjectType === 'ElearningPackage'"
    key="elearningPackageLessonForm"></elearning-package-lesson-form>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import ContributionLessonForm from './contribution_lesson_form.vue';
import ExternalLessonForm from './external_lesson_form.vue';
import CustomContentLessonForm from './custom_content_lesson_form.vue';
import ElearningPackageLessonForm from './elearning_package_lesson_form.vue';

export default {
  name: 'lesson-editor',
  $_veeValidate: {
    validator: 'new'
  },
  components: {
    ContributionLessonForm,
    ExternalLessonForm,
    CustomContentLessonForm,
    ElearningPackageLessonForm
  },
  computed: {
    ...mapGetters({
      lessonType: 'course/lessonEditorType'
    }),
    lessonType() {
      return this.$store.getters['course/lessonEditorType'];
    },
    subjectType() {
      const typeMap = {
        'Course::Lesson::TopicLesson': 'Topic',
        'Course::Lesson::TopicQuizLesson': 'Topic',
        'Course::Lesson::PlaylistLesson': 'Playlist',
        'Course::Lesson::ExternalLesson': 'Link',
        'Course::Lesson::CustomContentLesson': 'CustomContent',
        'Course::Lesson::ElearningPackageLesson': 'ElearningPackage'
      }

      return typeMap[this.lessonType];
    }
  }
}
</script>
