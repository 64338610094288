import { Phrase } from "../interfaces";

export interface FilmTranscriptState {
  urls: {
    videoSrc: string;
    updatePhraseUrl: string;
    deletePhraseUrl: string;
    resetUrl: string;
  };
  currentTime: number;
  filterValue: string;
  autoscrollValue: boolean;
  phrases: Array<Phrase>;
  phrasesSaving: Array<number>;
  savingErrors: Array<{ id: number, message: string }>;
  exportUrl: string;
}

export const state: FilmTranscriptState = {
  urls: {
    videoSrc: null,
    updatePhraseUrl: null,
    deletePhraseUrl: null,
    resetUrl: null
  },
  currentTime: 0,
  filterValue: '',
  autoscrollValue: true,
  phrases: [],
  phrasesSaving: [],
  savingErrors: [],
  exportUrl: '',
}
