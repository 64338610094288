import { Datepicker } from '../globals/datepicker';
export class EditUserRegistration {
  constructor () {
    let dataContainer = document.querySelector('.data-container');
    
    if (dataContainer) {
      this.datepicker = new Datepicker(dataContainer.getAttribute('data-uuid'), $('.unavailable-until-wrapper'), {});
    }
  }
}
