import Vue from 'vue';
import Component from 'vue-class-component'
import { Watch, Prop } from 'vue-property-decorator';
import axios from 'axios';

@Component
export class LinkMixin extends Vue {
  @Prop(String) public uid: string;
  @Prop(String) protected title: string;
  @Prop(Boolean) protected active: boolean;
  @Prop(String) protected remotetarget: string;
  @Prop({ default: false }) protected disabled: boolean;
  @Prop({ default: false }) protected error: boolean;
  @Prop({ default: 'default' }) protected type: string;
  @Prop({ default: null }) protected remoteurl: string;

  protected contentElement: HTMLDivElement;

  @Watch('isActive')
  onChangeActive(isActive: boolean): void {
    if (isActive) {
      this.onActivate();
    } else {
      this.onDeactivate();
    }

    // Scroll to init some tabs correctly
    $(window).scroll();
  }

  onActivate() {
    if (this.remotetarget) {
      this.showBsTab();
    } else if (this.type === 'remote') {
      this.loadRemoteTab();
    } else {
      this.showContentTab();
    }
  }

  onDeactivate() {
    this.hideContentTab();
  }

  private showBsTab() {
    const bsTabs = document.querySelector('.workflow-navigation-tabs');
    bsTabs.classList.remove('hidden');
    this.triggerRemoteTab();
  }

  private loadRemoteTab() {
    kendo.ui.progress($(this.contentElement).parent(), true);
    axios.get(this.remoteurl, { responseType: 'text' }).then(response => {
      this.contentElement.innerHTML = response.data;
      ($ as any).material.init();
      kendo.ui.progress($(this.contentElement).parent(), false);
      this.showContentTab();
    });
  }

  private showContentTab() {
    const isSublink = this.$store.getters['contributionWorkflow/isSubstepActive'](this.uid);

    if (this.contentElement) {
      this.contentElement.classList.add('active');
      const bsTabs = this.contentElement.parentElement.querySelector('.workflow-navigation-tabs');

      if (bsTabs && isSublink) {
        bsTabs.classList.add('hidden');
      }
    }

    this.$root.$emit('show-' + this.uid);
  }

  private hideContentTab() {
    if (this.contentElement) {
      this.contentElement.classList.remove('active');
    }

    if (this.type === 'remote') {
      this.contentElement.innerHTML = '';
    }

    this.$root.$emit('hide-' + this.uid);
  }

  protected triggerRemoteTab() {
    const link: HTMLBaseElement = document.querySelector(`[href="${this.remotetarget}"]`);
    VERSTEHE.ContributionWorkflow.workflow.activateRemoteTab(link);
  }

  get isActive(): boolean {
    throw new Error('Not Implemented');
  };

  activate(): void {
    throw new Error('Not Implemented');
  };
}
