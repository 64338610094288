import { render, staticRenderFns } from "./integration_card.vue?vue&type=template&id=2ffe191f&scoped=true&"
import script from "./integration_card.vue?vue&type=script&lang=js&"
export * from "./integration_card.vue?vue&type=script&lang=js&"
import style0 from "./integration_card.vue?vue&type=style&index=0&id=2ffe191f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ffe191f",
  null
  
)

export default component.exports