<template>
  <div class="cdn-qumu-integration">
    <div class="cdn-qumu-settings">
      <ValidationProvider rules="required" :name="$t('integrations.cdn_qumu_endpoint')" v-slot="{ valid, errors }">
        <div class="form-group label-static" :class="{ 'error': errors.length }">
          <label for="qumu_endpoint" class="control-label">{{ $t('integrations.cdn_qumu_endpoint') }}</label>
          <input v-model="qumu_endpoint" type="text" class="form-control" id="qumu_endpoint" placeholder="https://qumu.your-company.com">
          <span v-if="errors.length > 0" class="help-block error-block">{{ errors[0] }}</span>
        </div>
      </ValidationProvider>

      <ValidationProvider rules="required" :name="$t('integrations.cdn_qumu_user')" v-slot="{ valid, errors }">
        <div class="form-group label-static" :class="{ 'error': errors.length }">
          <label for="qumu_user" class="control-label">{{ $t('integrations.cdn_qumu_user') }}</label>
          <input v-model="qumu_user" type="text" class="form-control" id="qumu_user">
          <span v-if="errors.length > 0" class="help-block error-block">{{ errors[0] }}</span>
        </div>
      </ValidationProvider>

      <ValidationProvider rules="required" :name="$t('integrations.cdn_qumu_client_id')" v-slot="{ valid, errors }">
        <div class="form-group label-static" :class="{ 'error': errors.length }">
          <label for="qumu_client_id" class="control-label">{{ $t('integrations.cdn_qumu_client_id') }}</label>
          <input v-model="qumu_client_id" type="text" class="form-control" id="qumu_client_id">
          <span v-if="errors.length > 0" class="help-block error-block">{{ errors[0] }}</span>
        </div>
      </ValidationProvider>

      <ValidationProvider rules="required" :name="$t('integrations.cdn_qumu_client_secret')" v-slot="{ valid, errors }">
        <div class="form-group label-static" :class="{ 'error': errors.length }">
          <label for="qumu_client_secret" class="control-label">{{ $t('integrations.cdn_qumu_client_secret') }}</label>
          <input v-model="qumu_client_secret" type="text" class="form-control" id="qumu_client_secret">
          <span v-if="errors.length > 0" class="help-block error-block">{{ errors[0] }}</span>
        </div>
      </ValidationProvider>

      <ValidationProvider rules="required" :name="$t('integrations.cdn_qumu_program_template')" v-slot="{ valid, errors }">
        <div class="form-group label-static" :class="{ 'error': errors.length }">
          <label for="qumu_program_template" class="control-label">{{ $t('integrations.cdn_qumu_program_template') }}</label>
          <input v-model="qumu_program_template" type="text" class="form-control" id="qumu_program_template" placeholder="prg_template:1000">
          <span v-if="errors.length > 0" class="help-block error-block">{{ errors[0] }}</span>
        </div>
      </ValidationProvider>

      <div v-show="more">
        <div class="form-group label-static">
          <label for="qumu_template_settings" class="control-label">{{ $t('integrations.cdn_qumu_template_settings') }}</label>
          <textarea v-model="qumu_template_settings" rows="5" class="form-control" id="qumu_template_settings"></textarea>
          <p class="help-block">{{ $t('integrations.cdn_qumu_template_settings_help') }}</p>
        </div>

        <div class="checkbox">
          <label>
            <input
              type="checkbox"
              v-model="qumu_sso_enabled">
              {{ $t('integrations.cdn_qumu_sso_enabled') }}
          </label>
        </div>

        <div class="form-group label-static">
          <label for="qumu_sso_url" class="control-label">{{ $t('integrations.cdn_qumu_sso_url') }}</label>
          <input :disabled="!qumu_sso_enabled" v-model="qumu_sso_url" type="text" class="form-control" id="qumu_sso_url" placeholder="https://qumu.your-company.com/viewerportal/video/sso/establishSession.vp">
          <p class="help-block">{{ $t('integrations.cdn_qumu_sso_url_help') }}</p>
        </div>
      </div>

      <button v-if="!more" @click="more = true" class="btn btn-default btn-flat btn-xxs">{{ $t('integrations.show_more') }}</button>

      <slot/>
    </div>
  </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex';
  const { mapGetters, mapActions } = createNamespacedHelpers('integrations');

  export default {
    data() {
      return {
        qumu_endpoint: null,
        qumu_client_id: null,
        qumu_client_secret: null,
        qumu_user: null,
        qumu_program_template: null,
        qumu_template_settings: null,
        qumu_sso_enabled: null,
        qumu_sso_url: null,
        more: false
      }
    },
    computed: {
      ...mapGetters([
        'integrationById'
      ]),
      integration() {
        return this.integrationById('cdn_qumu');
      },
      settings() {
        return {
          qumu_endpoint: this.qumu_endpoint,
          qumu_client_id: this.qumu_client_id,
          qumu_client_secret: this.qumu_client_secret,
          qumu_user: this.qumu_user,
          qumu_program_template: this.qumu_program_template,
          qumu_template_settings: this.qumu_template_settings,
          qumu_sso_enabled: this.qumu_sso_enabled,
          qumu_sso_url: this.qumu_sso_url
        }
      },
    },
    methods: {
      load() {
        const settings = this.integration.settings;
        this.qumu_endpoint = settings.qumu_endpoint;
        this.qumu_client_id = settings.qumu_client_id;
        this.qumu_client_secret = settings.qumu_client_secret;
        this.qumu_user = settings.qumu_user;
        this.qumu_program_template = settings.qumu_program_template;
        this.qumu_template_settings = settings.qumu_template_settings;
        this.qumu_sso_enabled = settings.qumu_sso_enabled;
        this.qumu_sso_url = settings.qumu_sso_url;
      }
    },
    mounted() {
      this.load();
      $.material.init();
    }
  }
</script>

<style lang="scss" scoped>
  .cdn-qumu-integration {
    display: flex;
  }

  .cdn-qumu-settings {
    flex: 1 0;
    align-self: flex-start;
    min-width: 400px;
    max-width: 800px;
    background-color: $color-white;
    border-radius: 3px;
    padding: 30px;
  }

  #qumu_template_settings {
    font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
    resize: vertical;
  }

  .form-group.error {
    .control-label,
    .help-block {
      color: $color-error-red !important;
    }

    .form-control {
      border-color: $color-error-red;
      background-image: linear-gradient($color-error-red, $color-error-red), linear-gradient($color-error-red, $color-error-red);
    }
  }
</style>
