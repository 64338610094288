import { PrivateSelect } from './private_select';
import { Datepicker } from '../globals/datepicker';
import { CopyToClipboardInput } from '../globals/copy_to_clipboard_input';
import { t } from './localization';

export class Visibility {

  constructor() {
    this._publishButton = document.querySelector('.publish-contribution-btn');
    this._privateSelectWrap = document.querySelector('.private-select-wrapper');
    this._privateUntilWrap = document.querySelector('#private-until-wrapper');
    this._contentUrlWrap = document.querySelector('.content-url-wrapper');
    this._urlInput = document.querySelector('#contribution-url');
    this._privateUntilNotice = document.querySelector('.private-until-notice');
    this.currentVisibility = null;
    this.initStateSelect();
    this.initPrivateUntil();
    this.privateSelect = new PrivateSelect();

    if(this._urlInput) {
      this._urlInput = new CopyToClipboardInput(this._urlInput);
    }
  }

  initStateSelect() {
    const radioButtons = document.querySelectorAll('.contribution-publish-visibility input[type="radio"');
    radioButtons.forEach((button) => {
      if (button.checked) {
        this.currentVisibility = button.value;
      }
      button.addEventListener('change', (e) => {
        this.stateSelectChange(e.target.value);
      });
    });
  }

  initPrivateUntil() {
    if (this._privateUntilWrap) {
      const uuid = this._privateUntilWrap.getAttribute('data-uuid');
      const min = new Date();
      min.setDate(min.getDate() + 1);
      min.setHours(0,0,0,0);
  
      const options = { 
        "min": min
      };
  
      this.privateUntil = new Datepicker(uuid, this._privateUntilWrap, options);
    }
  }

  stateSelectChange(visibility) {
    this.currentVisibility = visibility;
    switch(visibility) {
      case 'private':
        this.privateSelect.enable(true);
        this._privateSelectWrap && this._privateSelectWrap.querySelectorAll('.control-label').forEach(label => {
          label.classList.remove('disabled');
        });

        if (this._privateUntilWrap) {
          this.privateUntil.datepicker.enable(true);
        }
        break;
      default:
        this.privateSelect.enable(false);
        this._privateSelectWrap && this._privateSelectWrap.querySelectorAll('.control-label').forEach(label => {
          label.classList.add('disabled');
        });

        if (this._privateUntilWrap) {
          this.privateUntil.datepicker.enable(false);
        }
      }
    this.switchButtonLabel(visibility);
  }

  switchButtonLabel(visibility) {
    if (visibility === 'unpublished') {
      this._publishButton.innerHTML = t('save');
    } else {
      this._publishButton.innerHTML = t('publish');
    }
  }

  getVisibility() {
    return this.currentVisibility;
  }
}
