export const localization = {
  'en': {
    'new_field': 'Add new field',
    'edit_headline': 'Edit ',
    'new_element_prefix': 'Add ',
    'new_element_suffix': '',
    'change_reviewers': 'Change reviewers',
    'change_editors': 'Change editors',
    'edit': 'Open details',
    'rename': 'Rename',
    'delete': 'Delete',
    'are_you_sure': 'Do you really want to convert this field?',
    'search_no_elements': 'There have been no matches for your search string.'
  },
  'de': {
    'new_field': 'Neues Feld hinzufügen',
    'edit_headline': 'Bearbeite ',
    'new_element_prefix': '',
    'new_element_suffix': ' hinzufügen',
    'change_reviewers': 'Rezensent bearbeiten',
    'change_editors': 'Fachredakteure bearbeiten',
    'edit': 'Details öffnen',
    'rename': 'Umbenennen',
    'delete': 'Löschen',
    'are_you_sure': 'Möchten Sie dieses Feld wirklich umwandeln?',
    'search_no_elements': 'Für Ihre Suche konnten keine Treffer gefunden werden.'
  },
  'es': {
    'new_field': 'Añadir nuevo campo',
    'edit_headline': 'corregir ',
    'new_element_prefix': 'Agregar ',
    'new_element_suffix': '',
    'change_reviewers': 'revisores de cambios',
    'change_editors': 'Editar editores especializados',
    'edit': 'Abrir detalles',
    'rename': 'renombramiento',
    'delete': 'borradura',
    'are_you_sure': '¿Realmente quieres convertir este campo?',
    'search_no_elements': 'No se han encontrado resultados para su búsqueda.'
  }
};
