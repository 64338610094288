export const locals = {
  de: {
    recording_application_unavailable: 'Bei der Kommunikation mit einer Anwendungskomponente trat ein Fehler auf. Bitte laden Sie die Seite neu und versuchen Sie es erneut.',
    guide_first_start: 'Bitte laden Sie AppLauncher.exe herunter und starten Sie sie.',
    guide_first_start_mac: 'Bitte laden sie AppLauncher.dmg herunter und installieren Sie die Anwendung.',
    guide_start: 'Wählen Sie "Öffnen" oder "Starten", wenn Sie vom Browser dazu aufgefordert werden.',
    nothing_happens: 'Es passiert nichts?',
    download_again: 'Erneut herunterladen',
    download_does_not_start: 'Der Download startet nicht automatisch?',
    download_manually: 'Manuell herunterladen',
    privacy_disclaimer: 'Datenschutzhinweis',
    privacy_disclaimer_text: 'Werden Sie andere Personen aufnehmen? Denken Sie daran, alle Beteiligten zu informieren und um ihre Zustimmung zu bitten.',
    do_not_show_again: 'Nicht erneut anzeigen',
    understood: 'Verstanden',
    cancel: 'Abbrechen'
  },
  en: {
    recording_application_unavailable: 'An error occured while communicating with an application component. Please reload the page and retry.',
    guide_first_start: 'Please download and start AppLauncher.exe',
    guide_first_start_mac: 'Please download AppLauncher.dmg and install the application.',
    guide_start: 'Select \'Open\' or \'Start\' when prompted by the browser.',
    nothing_happens: 'Nothing happens?',
    download_again: 'Download again',
    download_does_not_start: 'The download does not start automatically?',
    download_manually: 'Download manually',
    privacy_disclaimer: 'Privacy disclaimer',
    privacy_disclaimer_text: 'Are you about to record other people? Remember to inform everyone involved and ask for their agreement.',
    do_not_show_again: 'Do not show again',
    understood: 'Understood',
    cancel: 'Cancel'
  },
  es: {
    recording_application_unavailable: 'Se ha producido un error durante la comunicación con un componente de aplicación. Vuelva a cargar la página y vuelva a intentarlo.',
    guide_first_start: 'Por favor, descargue e inicie AppLauncher.exe',
    guide_first_start_mac: 'Por favor, descargue AppLauncher.dmg e instale la aplicación.',
    guide_start: 'Seleccione «Abrir» o «Iniciar» cuando el navegador se lo solicite.',
    nothing_happens: '¿No pasa nada?',
    download_again: 'Descargar de nuevo',
    download_does_not_start: '¿La descarga no se inicia automáticamente?',
    download_manually: 'Descargar manualmente',
    privacy_disclaimer: 'Aviso de privacidad',
    privacy_disclaimer_text: '¿Estás a punto de grabar a otras personas? Recuerde informar a todas las personas involucradas y pedir su consentimiento.',
    do_not_show_again: 'No volver a aparecer',
    understood: 'Entendido',
    cancel: 'Cancelar'
  }
};

export function t(key) {
  return locals[_app.info.currentLocale][key] || key;
}
