import BaseWidget from './base_widget';

export default class ContributionListWidget extends BaseWidget {
  constructor(options) {
    super(options);

    this.type = 'ContributionList';
  }

  static get defaultParams() {
    return {
      title: '',
      show_title: true,
      filter_query: '',
      filter_types: [],
      filter_topic_area: [],
      filter_metadata: [],
      filter_tags: [],
      order_field: 'views',
      order_direction: 'desc',
      timespan: 'total',
      static_contents: false,
      static_content_list: []
    };
  }

  renderParameters() {
    if (this.grid.editable) {
      return { p: this.params };
    } else {
      return this.options.params.page ? { p: { page: this.options.params.page } } : {};
    }
  }

  initialize() {
    super.initialize();
    // Bind click listener to the pagination, so we only replace the widget content
    let pageLinks = this.element.querySelectorAll('.landing-page-widget-pagination li:not(.active):not(.disabled) a');

    for (let i = 0; i < pageLinks.length; i++) {
      pageLinks[i].addEventListener('click', (e) => {
        e.preventDefault();

        // set new endpoint and rerender the contribution list
        this.options.params.page = pageLinks[i].getAttribute('data-page');
        this.render();
      });
    }
  }
}
