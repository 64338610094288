export function tagsFilterUi(element) {
  element.closest('.k-filter-menu').addClass('tags-multiselect');
  var operatorSelect = Array.prototype.slice.call(element[0].parentNode.childNodes, 0, 2);
  
  for (var i = 0; i < operatorSelect.length; i++) {
    operatorSelect[i].parentNode.removeChild(operatorSelect[i]);
  }

  var select = element.kendoMultiSelect({
    dataTextField: "name",
    dataValueField: "name",
    minLength: 2,
    enforceMinLength: true,
    dataSource: [],
    valuePrimitive: true,
    autoBind: false,
    clearButton: false,
    change: function(e) {
      if (this.value().length === 0) {
        VERSTEHE.ContributionGrid.grid.filter.getFilterColumn("tags").filterMenu.filterModel.filters[0].value = null;
      }
    }
  }).data('kendoMultiSelect');

  var dataSource = new kendo.data.DataSource({
    transport: {
      read: {
        url: document.querySelector('.contribution-grid-filter').dataset.tagEndpoint,
        dataType: "json"
      }
    }
  });

  var filterPopup = element.closest('.k-filter-menu').data('kendoPopup');
  filterPopup.one('open', function() {
    select.setDataSource(dataSource);
  });

  filterPopup.bind('open', function() {
    var gridFilter = VERSTEHE.ContributionGrid.grid.dataSource.filter();

    if (gridFilter) {
      var filters = gridFilter.filters;
      var tagFilter = filters.find(function (filter) {
        return filter.field === "tags";
      });
      select.value((tagFilter ? tagFilter.value.split(',') : ''));
    }
  });
}
