import { BaseGrid } from '../grid/base_grid';
import { DataSource } from './data_source';
import { columnConfiguration } from './configurations/column_configurations';
import { manageColumnConfiguration } from './configurations/manage_column_configurations';
import { Filter } from './filter/filter';
import { openReviewStateFilterMenu } from './filter/filter_menu';
import { BulkActions } from './configurations/bulk_actions';
import { ColumnSelect } from '../grid/base_column_select';
const GRID_COOKIE_KEY = `review_grid_options_${_app.info.siteId}_${_app.info.user.userId}`;

export class ReviewGrid extends BaseGrid {
  constructor () {
    super({ skipInit: true });

    if (this.gridElement) {
      this.gridType = this.gridElement.getAttribute('data-type');
      this.dataSource = new DataSource ({ endpointUrl: this.endpointUrl, grid: this });

      if (this.gridType === 'manage') {
        this.bulkActions = new BulkActions(this);
      }

      this.initGrid();
      this.filter = new Filter(this);
      this.columnSelect = new ColumnSelect(this);

      // Set title filter from URL parameters
      this.setFilterFromLocation();
    }
  }

  setGridOptions (option) {
    // Do not save filter
    delete option['filter'];

    super.setGridOptions(option);
  }

  getColumnConfigurations () {
    if (this.gridType === 'manage') {
      return manageColumnConfiguration;
    } else {
      return columnConfiguration;
    }
  }

  gridColumns () {
    let visibleColumns = this.getGridOptions().columns;
    let columns =  { columns: this.getColumnConfigurations().map(column => {
      if (visibleColumns && column.hideable) {
        column.hidden = visibleColumns.indexOf(column.field) === -1;
      }

      return column;
      })
    };

    return columns;
  }

  gridOptions () {
    return {
      scrollable: {
        endless: true
      },
      pageable: {
        numeric: false,
        previousNext: false
      },
      filterable: {
        extra: false
      },
      sortable: {
        mode: 'single',
        allowUnsort: false
      },
      persistSelection: true
    };
  }

  gridCookieKey () {
    return GRID_COOKIE_KEY;
  }

  gridSort () {
    let sort = this.getGridOptions().sort;

    return sort ? sort : {
      field: 'updated_at',
      dir: 'desc'
    };
  }

  onGridSort (e) {
    this.setGridOptions({ sort: e.sort });
  }

  onGridDataBound(e) {
    this.filter.updateFilters(this.dataSource.dataSource.filter());
  }

  onGridFilter(e) {
    this.filter.filterChange(e);
  }

  gridFilter () {
    let filter = {};

    if (this.gridType === "reviews") {
      filter = {
        field: "evaluation_state",
        operator: "neq",
        value: "done",
        readOnly: true
      };
    }

    return filter;
  }

  setFilterFromLocation() {
    const locationParams = window.location.search.substr(1);
    const searchMatch = locationParams.match(/search=(.+?)(&|$)/);

    if (searchMatch !== null) {
      const query = searchMatch[1].replace(/\+/g, ' ');
      const column = this.filter.getFilterColumn('title');
      const constraint = {
        field: 'title',
        operator: 'contains',
        value: decodeURIComponent(query)
      };

      column.filterMenu.filter({ filters: [constraint] });
    }
  }

  onGridFilterMenu (e) {
    switch (e.field) {
      case 'evaluation_state':
        openReviewStateFilterMenu(e, this.gridType);
        break;
    }
  }

  onGridChange (e) {
    this.selected = this.grid.selectedKeyNames();
    this.bulkActions.updateSelection(this.selected);
  }

}
