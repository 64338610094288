<template>
  <div class="video-question">
    <video-player
      ref="videoPlayer"
      v-bind="{ sources, screenplay, thumbnails, tracking, annotations, preload, initialTime, poster, offerDownload, disableRmb, qumu, locale, crossorigin, autoplay, theatreMode, sceneMarkers, fluid, startTime }"
      :remoteAnnotations="remoteAnnotations"
      :options="playerOptions"
      @progress="$emit('progress', $event)"
      @timeupdate="onTimeupdate"
      @play="onPlay"
      @ended="onEnded"
      @seeked="onSeeked"
      @loadedmetadata="onLoadedmetadata"
      @ready="onReady">
      <slot/>
    </video-player>

    <question-overlay
      v-show="currentQuestion"
      ref="overlay"
      :question="currentQuestion"
      @completed="onQuestionCompleted"
      @rewatch="onRewatch"></question-overlay>
  </div>
</template>

<script>
import VideoPlayer from './video_player';
import QuestionOverlay from './questions/question_overlay';

export default {
  name: 'video-questions-player',
  components: {
    VideoPlayer,
    QuestionOverlay
  },
  props: {
    sources: {
      type: Array || String,
      required: true
    },
    options: {
      type: Object,
      default: () => {}
    },
    questions: {
      type: Array
    },
    preload: String,
    poster: String,
    locale: String,
    progress: Number,
    initialTime: Number,
    thumbnails: String,
    tracking: String,
    annotations: {},
    offerDownload: Boolean,
    disableRmb: Boolean,
    crossorigin: String,
    qumu: Object,
    autoplay: Boolean,
    screenplay: {},
    startTime: Number,
    fluid: {
      type: Boolean,
      default: true
    },
    theatreMode: {
      type: Boolean,
      default: true
    },
    sceneMarkers: {
      type: Boolean,
      default: true
    },
    remoteAnnotations: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      videoOverlay: null,
      currentQuestion: null
    }
  },
  computed: {
    duration() {
      return this.$refs.videoPlayer.duration;
    },
    playerOptions() {
      const defaultOptions = {
        plugins: {
          coursePlugin: {
            questions: this.questions
          }
        },
        controlBar: {
          pictureInPictureToggle: false
        }
      };

      return Object.assign(defaultOptions, this.options);
    }
  },
  methods: {
    nextQuestionStart() {
      return this.questions.find(question => !question.isCompleted && question.time == 0.0);
    },
    nextQuestionDuring(time) {
      return this.questions.find(question => !question.isCompleted && question.time > 0 && time >= question.time);
    },
    nextQuestionEnd() {
      return this.questions.find(question => !question.isCompleted && (question.time === null || question.time >= this.duration));
    },
    onTimeupdate(player, time) {
      if (this.questions && this.questions.length > 0) {
        if (!this.currentQuestion) {
          this.currentQuestion = this.nextQuestionDuring(time);
        } else {
          player.pause();
        }
      }

      // Bubble event
      this.$emit('timeupdate', player, time);
    },
    onPlay(player) {
      if (!this.questions || this.questions.length === 0) {
        return;
      }

      if (parseInt(player.currentTime(), 10) === 0 && !this.currentQuestion) {
        this.currentQuestion = this.nextQuestionStart();
      }
    },
    onEnded(player) {
      if (!this.questions || this.questions.length === 0) {
        return;
      }

      if (!this.currentQuestion) {
        this.currentQuestion = this.nextQuestionEnd();
      }

      if (!this.currentQuestion) {
        this.$emit('completed');
      }
    },
    jumpToQuestion(questionId) {
      const question = this.questions.find(question => question.id === questionId);

      if (question) {
        if (question.isCompleted) {
          this.currentQuestion = question;
          this.$nextTick(() => this.$refs.overlay.showOnlySolution());
        } else if (question.time == 0) {
          this.currentQuestion = this.nextQuestionStart();
        }
      }
    },
    onQuestionCompleted() {
      let shouldPlay = true;

      this.$refs.videoPlayer.player.coursePlugin().enableQuestion(this.currentQuestion.id);

      this.questions.forEach(question => {
        if (question === this.currentQuestion) {
          question.isCompleted = true;
        }
      });

      if (this.currentQuestion.time === null || this.currentQuestion.time >= this.duration) {
        this.currentQuestion = this.nextQuestionEnd();

        // Do not play the video, if the end is reached
        if (!this.currentQuestion) {
          shouldPlay = false;
          this.$emit('completed');
        }
      } else if (this.currentQuestion.time == 0) {
        this.currentQuestion = this.nextQuestionStart();
      } else {
        this.currentQuestion = null;
      }

      if (!this.currentQuestion) {
        this.$refs.videoPlayer.controls(true);

        if (shouldPlay) {
          this.$refs.videoPlayer.play();
        }
      }
    },
    onRewatch() {
      let jumpToTime = 0;
      const currentIndex = this.questions.findIndex(q => q === this.currentQuestion);

      if (currentIndex && currentIndex > 0) {
        const question = this.questions[currentIndex - 1];
        jumpToTime = question.time
      }

      this.currentQuestion = null;
      this.$refs.videoPlayer.setTime(jumpToTime);
      this.$refs.videoPlayer.controls(true);
      this.$refs.videoPlayer.play();
    },
    onReady(player) {
      // Inject questions overlay component into videojs element
      player.el().appendChild(this.$refs.overlay.$el);

      // Triggered when user clicked question icon in vjs progress control
      player.on('question', (e, questionId) => {
        this.jumpToQuestion(questionId);
      });

      // Bubble event
      this.$emit('ready', player);
    },
    onSeeked(player) {
      if (this.currentQuestion) {
        player.pause();
      }
    },
    onLoadedmetadata(player) {
      this.$emit('loadedmetadata', player);

      if (this.questions && this.questions.length > 0 && this.progress > 0) {
        this.setQuestionsCompletedUntil(this.duration * this.progress);
      }
    },
    setQuestionsCompletedUntil(time) {
      // Mark questions as completed up to this time
      this.questions.forEach(question => {
        if (question.time <= time) {
          question.isCompleted = true;
          this.$refs.videoPlayer.player.coursePlugin().enableQuestion(question.id);
        }
      });
    },
    setInitialTime(time) {
      return this.$refs.videoPlayer.setInitialTime(time);
    }
  },
  watch: {
    currentQuestion(question) {
      if (question) {
        this.$refs.videoPlayer.pause();
        this.$refs.videoPlayer.controls(false);
        this.$refs.videoPlayer.player.coursePlugin().disableQuestion(question.id);

        if (question.time !== null) {
          this.$refs.videoPlayer.setTime(question.time);
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.video-question {
  width: 100%;
  height: 100%;
}
</style>
