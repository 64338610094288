export const locals = {
  de: {
    action_edit: 'Bearbeiten',
    action_export: 'Exportieren',
    action_show: 'Ansehen',
    action_delete: 'Löschen',
    action_feedback: 'Feedback',
    author: 'Erstellt von',
    contains: 'Beinhaltet',
    created_at: 'Erstellt am',
    eq: 'ist',
    filter_only_my_own: 'Nur meine eigenen',
    is_after_or_equal_to: 'gleich oder später ist',
    is_before_or_equal_to: 'gleich oder früher ist',
    is_equal_to: 'gleich ist',
    title: 'Titel',
    updated_at: 'Aktualisiert am'
  },
  en: {
    action_edit: 'Edit',
    action_export: 'Export',
    action_show: 'Show',
    action_delete: 'Delete',
    action_feedback: 'Feedback',
    author: 'Created by',
    contains: 'Contains',
    created_at: 'Created at',
    eq: 'equals',
    filter_only_my_own: 'Only my own',
    is_after_or_equal_to: 'Is after or equal to',
    is_before_or_equal_to: 'Is before or equal to',
    is_equal_to: 'Is equal to',
    title: 'Title',
    updated_at: 'Updated at'
  },
  es: {
    action_edit: 'Corregir',
    action_export: 'Exportación',
    action_show: 'Mostrar',
    action_delete: 'Borrar',
    action_feedback: 'Opinión',
    author: 'Creado por',
    contains: 'Contiene',
    created_at: 'Creado en',
    eq: 'inguales',
    filter_only_my_own: 'Sólo el mío',
    is_after_or_equal_to: 'Es igual o posterior a',
    is_before_or_equal_to: 'Es anterior o igual a',
    is_equal_to: 'Es igual a',
    title: 'Título',
    updated_at: 'Actualizado en'
  }
};

export function t(key) {
  return locals[_app.info.currentLocale][key] || key;
}

export function tt(key, variables = {}) {
  let template = kendo.template(t(key));
  return template(variables);
}
