import { ChangeDetector } from './change_detector';
import { locals } from '../common/localization';
import SaveTopicButton from './save_button';

export class TopicPublishment {

  constructor() {
    this._saveDraftButton = document.getElementById('save-draft');
    this._saveButton = document.getElementById('save-topic');
    this._unlockButton = document.getElementById('unlock');
    this._reviewingDialog = document.getElementById('dialog_reviewing_options');
    this._topicForm = document.getElementById('topic-form');
    this._unlockForm = document.getElementById('unlock-form');
    this._reviewerMessageInput = document.getElementById('reviewer-message-field');
    this._publishInstantly = document.getElementById('publish-instantly');
    this._submitToReviewers = document.getElementById('submit-to-reviewers');
    this._closeReviewingModal = document.getElementById('close-reviewing-modal');

    const newRecord = this._topicForm.getAttribute('data-newrecord') === 'true';
    if(!newRecord) {
        this.changeDetector = new ChangeDetector('#topic-form');
    }

    this.initSaveButton();
    this.initDraftButton();
    this.initUnlockButton();
    this.initSubmitToReviewersButton();
    this.initPublishInstantlyButton();
    this.initCloseReviewingModalButton();
  }

  initDraftButton() {
    this._saveDraftButton.addEventListener('click', (e) => {
      this._saveDraftButton.classList.add('disabled');
      this.saveDraft(false);
    });
  }

  initUnlockButton() {
    if(!this._unlockButton){
      return;
    }

    this._unlockButton.addEventListener('click', () => {
      this._unlockForm.submit();
    });
  }

  saveDraft(produce = false) {
    if(VERSTEHE.Screenplay.screenplayApp && VERSTEHE.Screenplay.unsavedChanges) {
      VERSTEHE.Screenplay.validateScreenplay().then((valid) => {
        if(valid) {
          VERSTEHE.Screenplay.saveScreenplay().then(() => {
            this.appendDataAndSubmit(false, false, false, produce);
          }).catch(() => {
            this._saveDraftButton.classList.remove('disabled');
            $.snackbar({ content: locals[_app.info.currentLocale].generic_error, style: 'error', duration: 4000 });
          });
        }
      });
    } else {
      this.appendDataAndSubmit(false, false, false, produce);
    }
  }

  saveAndProduce() {
    return new Promise((resolve, reject) => {
      VERSTEHE.Screenplay.validateScreenplay().then((valid) => {
        if(valid) {
          VERSTEHE.Screenplay.saveScreenplay().then(() => {
            this.appendDataAndSubmit(false, false, false, true);
            resolve();
          }).catch(() => {
            reject();
          });
        } else {
          reject();
        }
      });
    })
  }

  initSaveButton() {
    if(!this._saveButton){
      return;
    }

    this.saveTopicButton = new SaveTopicButton(this._saveButton);

    this._saveButton.addEventListener('click', (e) => {
      this._saveButton.disabled = true;
      const visibility = VERSTEHE.ContributionPublishment.visibility.getVisibility();
      this.changeDetector.remoteCheck();

      this.changeDetector.checkComplete.then(() => {
        if (this.changeDetector.needsReview && visibility === 'public') {
          $(this._reviewingDialog).modal({ backdrop: 'static', keyboad: false });
        } else {
          this.appendDataAndSubmit();
        }
      });
    });
  }

  initSubmitToReviewersButton() {
    this._submitToReviewers.addEventListener('click', (e) => {
      this.appendDataAndSubmit(false, true);
    });
  }

  initPublishInstantlyButton() {
    if(this._publishInstantly) {
      this._publishInstantly.addEventListener('click', (e) => {
        this.appendDataAndSubmit(true, false);
      });
    }
  }

  initCloseReviewingModalButton() {
    if(this._closeReviewingModal && this._saveButton) {
      this._closeReviewingModal.addEventListener('click', () => {
        this._saveButton.disabled = false;
      });
    }
  }

  appendDataAndSubmit(skipReview = false, withMessage = false, publish = true, produce = false) {
    if (skipReview) { this.buildAndAppendField('skip_review', true); }
    if (withMessage) { this.buildAndAppendField('reviewer_message', this._reviewerMessageInput.value); }
    if (produce) { this.buildAndAppendField('produce', true, 'production'); }
    this.buildAndAppendField('publish', publish);
    VERSTEHE.Screenplay.setUnsavedChanges(false);
    this.appendLocationField();
    this._topicForm.submit();
  }

  buildAndAppendField(param, value, paramWrapper = 'publishment') {
    let input = document.createElement('input');
    input.type = 'hidden';
    input.name = `${paramWrapper}[${param}]`;
    input.value = value;

    this._topicForm.appendChild(input);

    return input;
  }

  enableSaveDraft() {
    this._saveDraftButton.classList.remove('disabled');
  }

  disableSaveDraft() {
    this._saveDraftButton.classList.add('disabled');
  }

  appendLocationField() {
    let locationField = document.createElement('input');
    locationField.type = 'hidden';
    locationField.name = 'location';
    locationField.value = window.location.hash.length > 0 ? window.location.hash : null;
    this._topicForm.appendChild(locationField);
  }
}
