import { ChangeAction } from "../../system_actions/change_action";

/**
 * Action that handles changes (upload/edit/delete)in the attachment list of the general tab
 * Changes regarding the attachment list are immediately saved on the server. Therefore the queuing of AttachmentAction is not necessary
 */
export class AttachmentAction extends ChangeAction {
  /**
   * @constructor
   * @param {String} tabId  - TabId of the tab the action originates from
   * @param {number} user - UserId of the chat room owner
   * @param {String} element - Element thats subject of the UIAction
   * @param {object} data - Information about the new or edited attachment
   * @param {String} method - Method of this AttachmentAction (upload, remove, edit)
   */
  constructor(tabId, user, element, data, method) {
    super(tabId, user, element);
    this.data = data;
    this.method = method;
    this.actionType = "AttachmentAction";
  }

  execute() {
    if (this.method === "upload") {
      let document_template = Handlebars.compile($('[data-collaborative-action*="AttachmentAction"].document-upload-toolbar').find('.document-blueprint').text());
      $(document_template(this.data)).hide().prependTo($('[data-collaborative-action*="AttachmentAction"].document-upload-toolbar').find('.document-list')).show(400);
    } else if (this.method === "remove") {
      let deleteButton = $(VERSTEHE.ElementFinder.ElementFinder.locationToElement(this.elementLocation));
      $(deleteButton).closest('.document').hide(400, function() { $(deleteButton).remove(); });
    } else if (this.method === "edit") {
      VERSTEHE.documents.updateTitle(this.data.id, this.data.title);
    }
  }

  static fromJson(json) {
    let action = JSON.parse(json);
    return new AttachmentAction(action.tabId, action.user, action.elementLocation, action.data, action.method);
  }

  static registerToElement() {
    AttachmentAction.registerToUpload();
    AttachmentAction.registerToRemove();
    AttachmentAction.registerToEdit();
  }
  static registerToUpload() {
    $('[data-collaborative-action*="AttachmentAction"].document-upload-toolbar').on('completed', function(e, data) {
      AttachmentAction.createAction(AttachmentAction.getTabId(), _app.info.user.userId, null, data, "upload");
    });

  }
  static registerToRemove() {
    $(document).on('ajax:success', '[data-collaborative-action*="AttachmentAction"] .remove-document', function(e, data, status, xhr) {
      if (data.status === 200) {
        AttachmentAction.createAction(AttachmentAction.getTabId(), _app.info.user.userId, $(this), null, "remove");
      }
    });
  }
  static registerToEdit() {
    $(document).on('ajax:success', '[data-collaborative-action*="AttachmentAction"]#edit_document_form', function(e, data, status, xhr) {
      AttachmentAction.createAction(AttachmentAction.getTabId(), _app.info.user.userId, null, data, "edit");
    });
  }
  static createAction(tabId, user, element, data, method) {
    let action = new AttachmentAction(tabId, user, element, data, method);
    VERSTEHE.content_channel.send_message(action);
  }
}
