<template>
<div class="course-perform">
  <course-sidebar></course-sidebar>

  <fade-transition mode="out-in" :duration="150">
    <router-view :key="$route.params.lessonId" class="course-content"></router-view>
  </fade-transition>
</div>
</template>

<script>
import { FadeTransition } from 'vue2-transitions'
import { createNamespacedHelpers } from 'vuex';
const { mapActions } = createNamespacedHelpers('coursePerform');
import CourseSidebar from './navigation/sidebar';

export default {
  components: {
    FadeTransition,
    CourseSidebar
  },
  methods: {
    ...mapActions([
      'checkCompletedBeacon'
    ])
  },
  mounted() {
    // Make sure the completion status is checked if the user leaves
    window.addEventListener('unload', () => this.checkCompletedBeacon());
  }
}
</script>

<style lang="scss" scoped>
.course-perform {
  width: 100%;
}

.course-content {
  &.v-enter-to,
  &.v-leave-to {
    overflow: hidden;
  }
}
</style>
