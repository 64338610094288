/**
 * Helper Methods especially for Tus-Uploads
 */
export default class UploadUtils {

  /**
   * Open File Browser and return Promise with File when resolved
   * @param acceptedTypes 
   */
  static selectFile(acceptedTypes: Array<String> | String): Promise<File> {
    return new Promise((resolve, reject) => {
      let input = document.createElement('input');
      input.type = 'file';
      input.hidden = true;
      input.accept = acceptedTypes.toString();
      document.body.appendChild(input);

      input.onchange = (e: any) => {
        const file = e.target.files[0];
        document.body.removeChild(input);
        resolve(file);
      }

      input.click();
    });
  }

  /**
   * Generate Fingerprint that is used to identify resumable uploads
   * @param file: File to upload
   */
  static generateFingerprint(file: File) {
    return [
      "coovi",
      file.name,
      file.type,
      file.size,
      file.lastModified,
      _app.info.tenant,
      _app.info.site,
      _app.info.user.userId
    ].join("-");
  }

  /**
   * Generate simple hash code from string
   * @param str: String 
   */
  static hashCode(str: String) {
    let hash = 0;
    if (str.length === 0) {
      return hash;
    }
    for (var i = 0; i < str.length; i++) {
      let char = str.charCodeAt(i);
      hash = ((hash << 5) - hash) + char;
      hash = hash & hash;
    }
    return hash;
  }
}
