import { t } from './localization';

export function visibilityFilterMenu (e) {
  
    let values = ['unpublished', 'public', 'unlisted', 'private'];
    let operator = e.container.find('select');
    let filterInput = e.container.find('input');
    operator.parent().css('display', 'none');
    filterInput.css('display', 'none');
    
    values.forEach(value => {
      let wrapper = document.createElement('div');
      let label = document.createElement('label');
      let radio = document.createElement('input');
      radio.type = 'radio';
      radio.name = 'filter_visibility';
      radio.value = value;
      wrapper.className = 'radio';
      label.appendChild(radio);
      label.appendChild(document.createTextNode(` ${t('visibility_' + value)}`));
      wrapper.appendChild(label);
      filterInput.after(wrapper);

      radio.addEventListener('click', () => {
        filterInput.val(radio.value).change();
      });
    });
}

export function booleanFilterMenu (e) {
  let labels = e.container[0].querySelectorAll('label');

  labels.forEach(label => {
    let wrapper = document.createElement('div');
    wrapper.className = 'radio';

    label.parentNode.insertBefore(wrapper, label);
    wrapper.appendChild(label);
  });
}
