import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../../store';
import App from './components/app.vue';
import routes from './routes';
import { MatManageModule } from './store';
import { Actions } from './store/actions';

class MediaAssemblingTemplateModule {
  public content: Vue;
  public navigation: Vue;
  public router: VueRouter;
  public saveButton: HTMLButtonElement;
  public formElement: HTMLFormElement

  public init() {
    this.router = new VueRouter({
      routes
    });

    this.initContent(this.router);
    this.initSaveButton();
  }

  private initContent(router: VueRouter) {
    const appNode = document.getElementById('v-media-assembling-template');
    const initialData = JSON.parse(appNode.getAttribute('data-initial'));
    const configData = JSON.parse(appNode.getAttribute('data-config'));
    store.registerModule('mediaAssemblingTemplate', MatManageModule);
    store.dispatch(Actions.SET_STATE, initialData);
    store.dispatch(Actions.SET_STATE, { config: configData });

    this.content = new Vue({
      store,
      router,
      i18n: VERSTEHE.vueI18n,
      el: appNode,
      render: h => h(App)
    });
  }

  private async initSaveButton() {
    this.saveButton = document.querySelector('#save-mat-btn');
    this.formElement = document.querySelector('#save_media_assembling_template');
    this.saveButton.addEventListener('click', () => this.saveForm());
  }

  public async saveForm() {
    (this.content.$children[0] as any).observer.validate().then(valid => {
      if (valid) {
        this.saveButton.classList.add('disabled');
        this.formElement.submit();
      }
    });
  }
}

export default new MediaAssemblingTemplateModule();
