import { ChangeAction } from "../../../system_actions/change_action";
import store from '../../../../../store';
import { Actions as StoreActions } from '../../../../../film_production/store/actions';

/**
 * FilmProductionAction handle tall changes that occur in the screenplay step
 */
export class FilmProductionAction extends ChangeAction {
  /**
   * @constructor
   * @param {string} tabId  - tabId of the tab the action originates from
   * @param {number} user - userId of the chat room owner
   * @param {String} element - element thats subject of the ChangeAction
   * @param {number} data - data (state of the vue sore or other payload)
   * @param {String} type - type of the FilmProductionAction
   */
  constructor(tabId, user, element, data, type) {
    super(tabId, user, element);
    this.data = data;
    this.type = type;
    this.actionType = "FilmProductionAction";
  }

  execute() {
    switch(this.type) {
      case 'SET_TEMPLATE':
        store.dispatch(StoreActions.SET_TEMPLATE_SILENT, this.data);
        break;
      case 'REMOVE_TEMPLATE':
        store.dispatch(StoreActions.REMOVE_TEMPLATE_SILENT);
        break;
    }
  }

  isComparableTo(other) {
    return this.type === other.type;
  }

  reduce(other) {
    return this;
  }

  static fromJson(json) {
    let action = JSON.parse(json);
    return new FilmProductionAction(action.tabId, action.user, action.elementLocation, action.data, action.type);
  }

  static registerToElement() {
    if(VERSTEHE.FilmProduction.app) {
      VERSTEHE.FilmProduction.app.$on('SET_TEMPLATE', (template) => {
        FilmProductionAction.createAction('medium-production',  _app.info.user.userId, null, template, 'SET_TEMPLATE');
      });
      VERSTEHE.FilmProduction.app.$on('REMOVE_TEMPLATE', () => {
        FilmProductionAction.createAction('medium-production',  _app.info.user.userId, null, null, 'REMOVE_TEMPLATE');
      });
    }
  }

  static createAction(tabId, user, element, data, type) {
    let action = new FilmProductionAction(tabId, user, element, data, type);
    VERSTEHE.content_channel.send_message(action);
  }
}
