export class ColumnSelect {
  constructor (grid) {
    this.grid = grid;
    this.wrapper = grid.gridElement.querySelector('.grid-columns');
    this.columnsButton = this.wrapper.querySelector('.grid-columns-button');
    this.columnsDropdown = this.wrapper.querySelector('.grid-columns-dropdown');
    this.columnCheckboxes = {};

    this.initDropdown();

    this.columnsButton.addEventListener('click', () => {
      if (this.columnsPopup.visible()) {
        this.columnsPopup.close();
      } else {
        this.columnsPopup.open();
      }
    });
  }

  availableColumns () {
    return this.grid.grid.columns.filter(column => column.hideable);
  }

  visibleColumns () {
    return this.grid.grid.columns.filter(column => !column.hidden && column.hideable).map(column => {
      return column.field;
    });
  }

  refreshVisibilityState() {
    const visibleColumns = this.visibleColumns();
    Object.entries(this.columnCheckboxes).forEach(([field, checkbox]) => {
      checkbox.checked = visibleColumns.includes(field);
    });
  }

  initDropdown () {
    this.availableColumns().forEach(column => {
      let listItem = document.createElement('li');
      let label = document.createElement('label');
      let checkbox = document.createElement('input');
      checkbox.type = 'checkbox';
      listItem.className = 'checkbox';

      label.appendChild(checkbox);
      label.appendChild(document.createTextNode(column.title));
      listItem.appendChild(label);
      this.columnsDropdown.appendChild(listItem);
      this.columnCheckboxes[column.field] = checkbox;

      checkbox.addEventListener('change', () => {
        if (checkbox.checked) {
          this.grid.grid.showColumn(column.field);
        } else {
          this.grid.grid.hideColumn(column.field);
        }

        this.grid.setGridOptions({ columns: this.visibleColumns() });
      });
    });

    this.columnsPopup = $(this.columnsDropdown).kendoPopup({
      anchor: this.columnsButton,
      origin: 'bottom right',
      position: 'top right',
      appendTo: this.wrapper,
      open: () => this.refreshVisibilityState()
    }).data('kendoPopup');

    $.material.init();
  }
}
