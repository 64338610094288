import axios from 'axios';

export default {
  setLessonGroups({ commit }, data) {
    commit('setLessonGroups', data);
  },

  setApiUrls({ commit }, apiUrls) {
    commit('setApiUrls', apiUrls);
  },
  
  setIsCollaborative({ commit }, isCollaborative) {
    commit('setIsCollaborative', isCollaborative);
  },

  updateLessonGroupPosition({ commit }, { lessonGroup, position }) {
    commit('updateLessonGroupPosition', { lessonGroup, position });
  },

  updateLessonPosition({ commit }, { lesson, position, lessonGroupId }) {
    commit('updateLessonPosition', { lesson, position, lessonGroupId });
  },

  changeLessonGroupTitle({ commit }, { lessonGroup, title }) {
    commit('changeLessonGroupTitle', { lessonGroup, title: title });
  },

  changeLessonTitle({ commit }, { lesson, title }) {
    commit('changeLessonTitle', { lesson, title: title });
  },

  createLessonGroup({ commit }, { title, position, id }) {
    commit('createLessonGroup', { title, position, id });
  },

  removeLessonGroup({ commit }, lessonGroup) {
    commit('removeLessonGroup', lessonGroup);
  },

  removeLesson({ commit }, lesson) {
    commit('removeLesson', lesson);
  },

  setUiState({ commit }, state) {
    commit('setUiState', state);
  },

  editLesson({ commit }, lesson) {
    commit('editLesson', lesson);
  },

  newLesson({ commit }, { lessonType, lessonGroupId }) {
    commit('newLesson', { lessonType, lessonGroupId });
  },

  setLessonEditorSubject({ commit }, subjectAttributes) {
    commit('setLessonEditorSubject', subjectAttributes);
  },

  saveEditorLesson({ commit, getters, dispatch }, lessonAttributes) {
    const lessonId = getters.lessonEditorObject.id;
    commit('saveEditorLesson', lessonAttributes);
    dispatch('saveEditorLessonCollaborative', getters.lessonById(lessonId));
  },

  saveEditorLessonCollaborative({}, lesson) {},

  saveEditorLessonCollaborativeSilent({ commit }, lesson) {
    commit('addLesson', lesson);
  },

  resetLessonEditorSubject({ commit }) {
    commit('resetLessonEditorSubject');
  },

  changeLessonType({ commit }, type) {
    commit('changeLessonType', type);
  },

  newQuestion({ commit }, questionType) {
    commit('newQuestion', questionType);
  },

  editQuestion({ commit }, question) {
    commit('editQuestion', question);
  },

  removeQuestion({ commit }, question) {
    commit('removeQuestion', question);
  },

  resetQuestionChanges({ commit }) {
    commit('resetQuestionChanges');
  },

  saveQuestion({ commit }, questionAttributes) {
    commit('saveQuestion', questionAttributes);
  },
  
  clearQuestions({ commit }) {
    commit('clearQuestions');
  },

  copyQuestions({ commit }, questions) {
    commit('copyQuestions', questions);
  },

  setMediaData({ commit }, mediaData) {
    commit('setMediaData', mediaData);
  },

  addDeletedLesson({ commit }, payload: { lesson: any, lessonGroupId: any }) {
    commit('addDeletedLesson', payload);
  },

  processGroupSwitchForLesson({ commit }, payload: { lessonId: any, lessonGroupId: any }) {
    commit('processGroupSwitchForLesson', payload);
  },

  lockLesson({ commit }, {lesson, userId}) {
    commit('lockLesson', {lesson, userId});
  },

  unlockLesson({ commit }, lesson) {
    commit('unlockLesson', lesson);
  },

  lockLessonGroup({ commit }, {lessonGroup, userId}) {
    commit('lockLessonGroup', {lessonGroup, userId});
  },

  unlockLessonGroup({ commit }, lessonGroup) {
    commit('unlockLessonGroup', lessonGroup);
  },

  async createElearningPackage({ commit, state, getters }, uploadId) {
    const url = getters.elearningPackageUrl(uploadId);

    return new Promise((resolve, reject) => {
      axios.post(url)
        .then(response => {
          if (state.lessonEditor.lessonType === 'Course::Lesson::ElearningPackageLesson') {
            commit('setLessonEditorSubject', response.data);
            resolve(response.data);
          }
        })
        .catch(error => {
          console.error(error);
          reject(error.response);
        })
    });

  }
}
