export enum WatermarkPosition {
  TOP_LEFT = 'topleft',
  TOP_RIGHT =  'topright',
  BOTTOM_LEFT = 'bottomleft',
  BOTTOM_RIGHT = 'bottomright'
}

export enum TextAlign {
  LEFT= 'left',
  CENTER = 'center',
  RIGHT = 'right'
}

export enum FontSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  BIG = 'big'
}

export enum SlideType {
  SLIDE = 'slide',
  VIDEO = 'video'
}

export enum NameStyle {
  FIRSTNAME_LASTNAME = 'firstname_lastname',
  LASTNAME_FIRSTNAME = 'lastname_firstname',
  FIRSTNAME_LASTNAME_USERNAME = 'firstname_lastname_username',
  LASTNAME_FIRSTNAME_USERNAME = 'lastname_firstname_username',
  USERNAME = 'username'
}

export interface MediaAssemblingTemplate {
  id: number;
  title: string;
  slides: { [key: string]: TemplateSlide };
  watermark_position: WatermarkPosition;
  watermark: string | File;
  watermark_file_name: string;
  delete_watermark: number;
  upload_watermark: boolean;
  preview: string | File;
  jingle: string | File;
  jingle_file_name: string;
  delete_jingle: number;
  upload_jingle: boolean;
  created_at: string;
  updated_at: string;
  generate_intro: boolean;
  generate_intersection: boolean;
  generate_outro: boolean;
  intro_image_copy: string;
  outro_image_copy: string;
  intersection_image_copy: string;
  active: boolean;
  state: string;
  user_id: number;
}

export interface TemplateSlide {
  generate: boolean;
  bg_color: string;
  duration: number;
  elements: Array<TemplateElement>;
  fadein: number;
  fadeout: number;
  image: string | File;
  image_file_name: string;
  delete_image: number;
  upload_image: boolean;
  slide_type: SlideType;
  take_id: number;
  video: Video
}

export interface TemplateElement {
  id: string;
  type: string;
  text_align: TextAlign;
  font_size: FontSize;
  text_color: string;
  font: string;
  height: number;
  width: number;
  left?: number;
  top?: number;
  name_style?: NameStyle;
  background_color: string;
}

export interface ConfigElement {
  id: string;
  type: string;
  dimension: string;
  font_size: string;
  text_align: string;
  subject?: string;
  font: string;
  width: number;
  height: number;
}

export interface Video {
  title: string;
  url: string;
}
