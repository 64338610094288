import Vue from 'vue';
import App from './components/app.vue';

import store from '../store';
import gettingStartedStore from './store';
import tutorialsStore from '../tutorials/store';

export let app = null;

export function init() {
  const element = document.getElementById('getting-started');

  if (!element) {
    return;
  }

  store.registerModule('gettingStarted', gettingStartedStore);
  store.registerModule('tutorials', tutorialsStore);
  store.dispatch('gettingStarted/setUser', element.dataset.user);
  store.dispatch('gettingStarted/currentRoles', _app.info.user.roles);
  store.dispatch('gettingStarted/setCompletedTasksUrl', element.dataset.completed);
  store.dispatch('tutorials/setTutorialVideos', { tutorialVideos: JSON.parse(element.dataset.tutorials) });
  store.dispatch('tutorials/filterRole', _app.info.user.roles);

  if (element.dataset.training !== 'true') {
    store.dispatch('gettingStarted/hideTrainerTasks');
  }

  app = new Vue({
    store,
    el: element,
    i18n: VERSTEHE.vueI18n,
    render: h => h(App)
  });
}
