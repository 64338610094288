import { ChangeAction } from "../../system_actions/change_action";

/**
 * NestedElementAction help recreating the creation and removal of NestedElements
 * e.g. Scenes and Sections in the Screenplay tab
 */
export class NestedElementAction extends ChangeAction {
  /**
   * @constructor
   * @param {String} tabId  - TabId of the tab the action originates from
   * @param {number} user - UserId of the chat room owner
   * @param {String} element - Element thats subject of the UIAction
   * @param {String} type - Add or remove the element
   * @param {number} timestamp_id - Vertical coordinates of the MouseClickAction
   */
  constructor(tabId, user, element, type, timestamp_id) {
    super(tabId, user, element);
    this.type = type;
    this.timestamp_id = timestamp_id;
    this.actionType = "NestedElementAction";
  }

  execute() {
    if (this.type === "remove") {
      // listener must be unbound to prevent loop
      $(document).off('click', '[data-collaborative-action*="RemoveNestedElementAction"]');
      // must be triggered for some logic of forms to work
      this.element.click();
      // rebinding the listener
      NestedElementAction.registerToRemoveElement();
    } else if (this.type === "add") {
      let e = { currentTarget: this.element, timestamp_id: this.timestamp_id };
      window.nestedFormEvents.addFields(e);
    }
  }

  static fromJson(json) {
    let action = JSON.parse(json);
    return new NestedElementAction(action.tabId, action.user, action.elementLocation, action.type, action.timestamp_id);
  }

  static registerToElement() {
    NestedElementAction.registerToAddElement();
    NestedElementAction.registerToRemoveElement();
  }

  static registerToRemoveElement() {
    $(document).on('click', '[data-collaborative-action*="RemoveNestedElementAction"]', function(event) {
      NestedElementAction.createAction(NestedElementAction.getTabId(), _app.info.user.userId, event.target, "remove", null);
    });
  }

  static registerToAddElement() {
    $(document).on('nested:fieldAdded', '[data-collaborative-action*="AddNestedElementAction"]', function(event) {
      if (!event.remoteTrigger) {
        NestedElementAction.createAction(NestedElementAction.getTabId(), _app.info.user.userId, event.add_button, "add", event.timestamp_id);
      }
    });
  }

  static createAction(tabId, user, element, type, timestamp_id) {
    let action = new NestedElementAction(tabId, user, element, type, timestamp_id);
    VERSTEHE.content_channel.send_message(action);
  }
}
