import BaseWidget from './base_widget';

export default class SliderWidget extends BaseWidget {
  constructor(options) {
    super(options);

    this.type = 'Slider';
    this.slider = null;
  }

  static get defaultParams() {
    return {
      title: '',
      show_title: true,
      filter_query: '',
      filter_types: [],
      filter_topic_area: [],
      filter_metadata: [],
      filter_tags: [],
      order_field: 'views',
      order_direction: 'desc',
      limit: 10,
      timespan: 'total',
      static_contents: false,
      static_content_list: []
    };
  }

  resized(e) {
    if (this.slider) {
      $(this.slider).trigger('refresh.owl.carousel');
    }
  }

  initialize() {
    super.initialize();
    this.slider = this.element.querySelector('.landing-page-widget-content');

    $(this.slider).owlCarousel({
      mouseDrag: false,
      dots: false,
      nav: true,
      navText: [
        '<i class="material-icons">chevron_left</i>',
        '<i class="material-icons">chevron_right</i>'
      ],
      slideBy: 2,
      responsiveBaseElement: this.element,
      responsive: {
        0: { items: 2 },
        640: { items: 3 },
        860: { items: 4 },
        1000: { items: 5 }
      }
    });
  }
}
