import BaseWidget from './widgets/base_widget';
import ChartWidget from './widgets/chart_widget';
import StockWidget from './widgets/stock_widget';
import LineWidget from './widgets/line_widget';
import ColumnWidget from './widgets/column_widget';
import TableWidget from './widgets/table_widget';
import PieWidget from './widgets/pie_widget';
import TextWidget from './widgets/text_block_widget';

const widgets = {
  ChartWidget,
  StockWidget,
  LineWidget,
  ColumnWidget,
  TableWidget,
  PieWidget,
  TextWidget
};

class Widget {
  constructor(widgetName, opts = {}) {
    return new widgets[`${widgetName}Widget`](opts);
  }
}

module.exports = Object.assign({}, widgets, { Widget });
