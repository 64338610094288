let fromPicker;
let tillPicker;

export function init() {
  initKendoDateTimePicker();
}

function initKendoDateTimePicker() {
  let fromField = document.querySelector('#system_status_start');
  let tillField = document.querySelector('#system_status_end');

  if (fromField === null || tillField === null) {
    return;
  }

  let fromDate = getFrom();
  let tillDate = getTill();
  VERSTEHE.kendoui.changeLocale(_app.info.currentLocale);
  initFromPicker(fromDate, tillDate, fromField);
  initTillPicker(tillDate, fromDate, tillField);
}

function initFromPicker(fromDate, tillDate, fromField) {
  fromPicker = $('#system_status_range_picker_start').kendoDateTimePicker({
    value: fromDate,
    dateInput: true,
    max: tillDate,
    change: function() {
      fromField.setAttribute('value', this.value().toISOString());
      let from = this.value();
      let till = getTill();
      if (from) {
        from = new Date(from);
        tillPicker.min(from);
      } else if (till) {
        fromPicker.max(new Date(till));
      } else {
        till = new Date();
        fromPicker.max(till);
        tillPicker.min(till);
      }
    }
  }).data("kendoDateTimePicker");
};

function initTillPicker(tillDate, fromDate, tillField) {
  tillPicker = $("#system_status_range_picker_end").kendoDateTimePicker({
    value: tillDate,
    dateInput: true,
    min: fromDate,
    change: function() {
      tillField.setAttribute('value', this.value().toISOString());
      let from = getFrom();
      let till = this.value();

      if (till) {
        till = new Date(till);
        fromPicker.max(till);
      } else if (from) {
        tillPicker.min(new Date(from));
      } else {
        till = new Date(till);
        fromPicker.max(till);
        tillPicker.min(till);
      }
    }
  }).data("kendoDateTimePicker");
};

function getFrom() {
  let fromData = document.querySelector('#system_status_start');
  let from = new Date();

  if (fromData !== null || fromData !== undefined) {
    from = new Date(fromData.getAttribute('value'));
  }

  return from;
};

function getTill() {
  let tillData = document.querySelector('#system_status_end');
  let till = new Date();

  if (tillData !== null || tillData !== undefined) {
    till = new Date(tillData.getAttribute('value'));
  }

  return till;
};
