var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"livestream-azure-integration"},[_c('div',{staticClass:"livestream-azure-settings"},[_c('ValidationProvider',{attrs:{"rules":"required","name":_vm.$t('integrations.livestream_azure_account_name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('div',{staticClass:"form-group label-static",class:{ 'error': errors.length }},[_c('label',{staticClass:"control-label",attrs:{"for":"livestream_azure_account_name"}},[_vm._v(_vm._s(_vm.$t('integrations.livestream_azure_account_name')))]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.livestream_azure_account_name),expression:"livestream_azure_account_name"}],staticClass:"form-control",attrs:{"type":"text","id":"livestream_azure_account_name"},domProps:{"value":(_vm.livestream_azure_account_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.livestream_azure_account_name=$event.target.value}}}),_vm._v(" "),(errors.length > 0)?_c('span',{staticClass:"help-block error-block"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required","name":_vm.$t('integrations.livestream_azure_resource_group_name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('div',{staticClass:"form-group label-static",class:{ 'error': errors.length }},[_c('label',{staticClass:"control-label",attrs:{"for":"livestream_azure_resource_group_name"}},[_vm._v(_vm._s(_vm.$t('integrations.livestream_azure_resource_group_name')))]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.livestream_azure_resource_group_name),expression:"livestream_azure_resource_group_name"}],staticClass:"form-control",attrs:{"type":"text","id":"livestream_azure_resource_group_name"},domProps:{"value":(_vm.livestream_azure_resource_group_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.livestream_azure_resource_group_name=$event.target.value}}}),_vm._v(" "),(errors.length > 0)?_c('span',{staticClass:"help-block error-block"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required","name":_vm.$t('integrations.livestream_azure_subscription_id')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('div',{staticClass:"form-group label-static",class:{ 'error': errors.length }},[_c('label',{staticClass:"control-label",attrs:{"for":"livestream_azure_subscription_id"}},[_vm._v(_vm._s(_vm.$t('integrations.livestream_azure_subscription_id')))]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.livestream_azure_subscription_id),expression:"livestream_azure_subscription_id"}],staticClass:"form-control",attrs:{"type":"text","id":"livestream_azure_subscription_id"},domProps:{"value":(_vm.livestream_azure_subscription_id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.livestream_azure_subscription_id=$event.target.value}}}),_vm._v(" "),(errors.length > 0)?_c('span',{staticClass:"help-block error-block"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required","name":_vm.$t('integrations.livestream_azure_client_id')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('div',{staticClass:"form-group label-static",class:{ 'error': errors.length }},[_c('label',{staticClass:"control-label",attrs:{"for":"livestream_azure_client_id"}},[_vm._v(_vm._s(_vm.$t('integrations.livestream_azure_client_id')))]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.livestream_azure_client_id),expression:"livestream_azure_client_id"}],staticClass:"form-control",attrs:{"type":"text","id":"livestream_azure_client_id"},domProps:{"value":(_vm.livestream_azure_client_id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.livestream_azure_client_id=$event.target.value}}}),_vm._v(" "),(errors.length > 0)?_c('span',{staticClass:"help-block error-block"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required","name":_vm.$t('integrations.livestream_azure_client_secret')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('div',{staticClass:"form-group label-static",class:{ 'error': errors.length }},[_c('label',{staticClass:"control-label",attrs:{"for":"livestream_azure_client_secret"}},[_vm._v(_vm._s(_vm.$t('integrations.livestream_azure_client_secret')))]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.livestream_azure_client_secret),expression:"livestream_azure_client_secret"}],staticClass:"form-control",attrs:{"type":"password","id":"livestream_azure_client_secret"},domProps:{"value":(_vm.livestream_azure_client_secret)},on:{"input":function($event){if($event.target.composing){ return; }_vm.livestream_azure_client_secret=$event.target.value}}}),_vm._v(" "),(errors.length > 0)?_c('span',{staticClass:"help-block error-block"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required","name":_vm.$t('integrations.livestream_azure_storage_account_name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('div',{staticClass:"form-group label-static",class:{ 'error': errors.length }},[_c('label',{staticClass:"control-label",attrs:{"for":"livestream_azure_storage_account_name"}},[_vm._v(_vm._s(_vm.$t('integrations.livestream_azure_storage_account_name')))]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.livestream_azure_storage_account_name),expression:"livestream_azure_storage_account_name"}],staticClass:"form-control",attrs:{"type":"text","id":"livestream_azure_storage_account_name"},domProps:{"value":(_vm.livestream_azure_storage_account_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.livestream_azure_storage_account_name=$event.target.value}}}),_vm._v(" "),(errors.length > 0)?_c('span',{staticClass:"help-block error-block"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])}),_vm._v(" "),_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }