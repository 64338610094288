import { ActionTree } from 'vuex';
import { LibraryState } from './state';
import { RootState } from '../../store/types';
import { Mutations } from './mutations';
import { Slideshow, Take, SelectionMode, Image } from '../interfaces';
import axios from 'axios';
import { Mutations as ScreenplayMutations } from '../../screenplay/store/mutations';
import { noticeMessage } from '../../mixins/flash_message';
import { MediaLibraryFilter } from '../filter/media_library_filter';

export const Actions = {
  SET_BASE_STATE: 'library/setBaseState',
  FETCH_MEDIA: 'library/fetchMedia',
  FETCH_REQUESTED_TAKES: 'library/fetchRequestedTakes',
  RESET_MEDIA: 'library/resetMedia',
  FETCH_DOCUMENT: 'library/fetchDocument',
  FETCH_INITIAL_STATE: 'library/fetchInitialState',
  ACTIVATE_TAB: 'library/activateTab',
  CHANGE_FILTER: 'library/changeFilter',
  CREATE_FILTER: 'library/createFilter',
  SET_SELECTED_TAKES: 'library/setSelectedTakes',
  SET_SELECTED_SLIDES: 'library/setSelectedSlides',
  SET_SELECTED_IMAGES: 'library/setSelectedImages',
  SELECT_SLIDES: 'library/selectSlides',
  UNSELECT_SLIDES: 'library/unselectSlides',
  SET_INSPECT_ITEM: 'library/setInspectItem',
  INSPECT_TAKE_BY_ID: 'library/inspectTakeById',
  UPDATE_TAKE: 'library/updateTake',
  DELETE_TAKE: 'library/deleteTake',
  REFRESH_AMOR_ACTION: 'library/refreshAmorAction',
  DELETE_MEDIUM: 'library/deleteMedium',
  DELETE_CLIP: 'library/deleteClip',
  UPDATE_DOCUMENT_PROGRESS: 'library/updateDocumentProgress',
  UPDATE_DOCUMENT: 'library/updateDocument',
  SET_SELECTION_MODE: 'library/setSelectionMode',
  RENAME_MEDIUM: 'library/renameMedium',
  SHARE_TAKE: 'library/shareTake',
  UPDATE_ASSET_STATE: 'library/updateAssetState',
  ADD_TAKE: 'library/addTake',
  PUSH_TAKE: 'library/pushTake',
  REFRESH_TAKE: 'library/refreshTake',
  REFRESH_CLIP: 'library/refreshClip',
  CLONE_TAKE: 'library/cloneTake',
  REMOVE_BARCODES: 'library/removeBarcodes',
  ADD_UPLOADED_TAKE: 'library/addUploadedTake',
  CREATE_CLIP: 'library/createClip',
  SET_MEDIA_TYPES: 'library/setMediaTypes',
  SHOW_SHARE_AND_DELETE_BUTTON: 'library/showShareAndDeleteButton',
  SET_ETA: 'library/setEta',
};

export const actions: ActionTree<LibraryState, RootState> = {
  setBaseState({ commit }, baseState: { apiUrl: string; brandUrl: string; uploadUrl: string }) {
    commit(Mutations.SET_BASE_STATE, baseState);
  },

  resetMedia({ state, commit }, identifier) {
    commit(Mutations.RESET_MEDIA_RESULTS, identifier);
  },

  async fetchMedia({ state, commit, getters }, identifier) {
    const tabState = state[identifier];
    const from = identifier === 'publicTakes' ? getters.approvedTakes.length : tabState.results.length;

    try {
      const response = await axios.get(`${state.apiBaseUrl}/${tabState.route}?from=${from}`);

      if (response.data.results.length > 0) {
        let payload = response.data;
        payload['collection'] = identifier;

        commit(Mutations.ADD_MEDIA_RESULTS, payload);

        payload.results
          .filter((r) => {
            return r.state === 'converting' || r.state === 'production';
          })
          .map((r) => {
            switch (r.type) {
              case 'take':
                return r.convert_job;
              case 'clip':
                return r.job;
              case 'document':
                return r.picturize_job;
            }
          })
          .forEach((id) => {
            if (id) {
              VERSTEHE.MediaLibrary.library.subscribeProductionJob(id);
            }
          });

        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error(e);
    }
  },

  async fetchRequestedTakes({ state, commit }) {
    try {
      const response = await axios.get(`${state.apiBaseUrl}/requested_takes`);
      const payload = {
        results: response.data,
        collection: 'publicTakes',
      };

      commit(Mutations.ADD_MEDIA_RESULTS, payload);
    } catch (e) {
      console.error(e);
    }
  },

  activateTab({ commit }, identifier: string) {
    commit(Mutations.SET_ACTIVE_TAB, identifier);
  },

  changeFilter({ state, commit }) {
    commit(Mutations.RESET_MEDIA_RESULTS, 'allMedia');
    commit(Mutations.RESET_MEDIA_RESULTS, 'privateTakes');
    commit(Mutations.RESET_MEDIA_RESULTS, 'publicTakes');
    commit(Mutations.RESET_MEDIA_RESULTS, 'documents');
    commit(Mutations.RESET_MEDIA_RESULTS, 'clips');
    commit(Mutations.RESET_MEDIA_RESULTS, 'images');
  },

  async createFilter({ state, commit }, activeFilters: MediaLibraryFilter[]) {
    // always load from all media as the result is applied to the other tabs.
    const from: number = state.allMedia.results.length;
    try {
      const response = await axios.post(`${state.apiBaseUrl}/filter_query`, {
        filters: activeFilters,
        from: from,
      });
      commit(Mutations.APPLY_FILTER, response.data);
      return response.data.results.length > 0;
    } catch (e) {
      console.error(e);
    }
  },

  async fetchDocument({ state, commit }, documentId: number) {
    try {
      const response = await axios.get(`${state.apiBaseUrl}/document?document_id=${documentId}`);
      commit(Mutations.ADD_OR_UPDATE_DOCUMENT, response.data);
      if (response.data.picturize_job !== null) {
        VERSTEHE.MediaLibrary.library.subscribeProductionJob(response.data.picturize_job);
      }

      return state.documents.results.find((doc) => doc.id === documentId) || state.images.results.find((doc) => doc.id === documentId);
    } catch (error) {
      console.error(error);
    }
  },

  setSelectedTakes({ state, commit, getters }, ids: Array<number>) {
    if (state.selectionMode === SelectionMode.Single) {
      ids = ids.filter((id) => !state.selectedTakes.includes(id));
      commit(Mutations.SET_SELECTED_SLIDES, []);
    }

    commit(Mutations.SET_SELECTED_TAKES, ids);
    let showShareAndDeleteButton = false;
    showShareAndDeleteButton = updateShareButton(state, getters, showShareAndDeleteButton);
    commit(Mutations.SHOW_SHARE_AND_DELETE_BUTTON, showShareAndDeleteButton);
  },

  setSelectedSlides({ state, commit }, ids: Array<number>) {
    if (state.selectionMode === SelectionMode.Single) {
      ids = ids.filter((id) => !state.selectedSlides.includes(id));
      commit(Mutations.SET_SELECTED_TAKES, []);
    }

    commit(Mutations.SET_SELECTED_SLIDES, ids);
  },

  setSelectedImages({ state, commit }, ids: Array<number>) {
    if (state.selectionMode === SelectionMode.Single) {
      ids = ids.filter((id) => !state.selectedImages.includes(id));
      commit(Mutations.SET_SELECTED_IMAGES, []);
    }

    commit(Mutations.SET_SELECTED_IMAGES, ids);
  },

  unselectSlides({ commit, getters }, ids: Array<number>) {
    const selected = getters['selectedSlides'].filter((slide) => ids.indexOf(slide) < 0);
    commit(Mutations.SET_SELECTED_SLIDES, selected);
  },

  selectSlides({ commit, getters }, ids: Array<number>) {
    const currentSelected = getters['selectedSlides'];
    const toAdd = ids.filter((slide) => currentSelected.indexOf(slide) < 0);
    const selected = [...currentSelected, ...toAdd];
    commit(Mutations.SET_SELECTED_SLIDES, selected);
  },

  setInspectItem({ commit }, item: Take | Slideshow | Image) {
    commit(Mutations.SET_INSPECT_ITEM, item);
  },

  refreshAmorAction({ commit }, payload) {
    commit(Mutations.REFRESH_TAKE, payload);
  },

  updateTake({ state, commit, getters }, payload) {
    let showShareButton = false;
    commit(Mutations.UPDATE_TAKE, payload);
    showShareButton = updateShareButton(state, getters, showShareButton);
    commit(Mutations.SHOW_SHARE_AND_DELETE_BUTTON, showShareButton);
  },

  deleteTake({ commit }, payload: { medium_type: string; medium_id: number }) {
    commit(Mutations.DELETE_MEDIUM, payload);
  },

  deleteMedium({ state, commit, getters }, payload: { medium_type: string; medium_id: number }) {
    const slideshow = payload.medium_type === 'slideshow' ? state.documents.results.find((doc) => doc.id === payload.medium_id) : null;

    axios
      .post(state.apiBaseUrl + '/delete_medium', {
        medium_type: payload.medium_type,
        medium_id: payload.medium_id,
      })
      .then((res) => {
        commit(Mutations.DELETE_MEDIUM, payload);

        if (state.selectionMode !== SelectionMode.None) {
          if (payload.medium_type === 'take') {
            commit('screenplay/' + ScreenplayMutations.REMOVE_MEDIUM, { type: payload.medium_type, medium_id: payload.medium_id }, { root: true });
            commit(
              'screenplay/' + ScreenplayMutations.REMOVE_MEDIA_FROM_SCREENPLAY,
              { type: payload.medium_type, medium_id: payload.medium_id },
              { root: true }
            );
          } else {
            if (slideshow) {
              const slides = slideshow.slides.map((slide) => slide.id);
              for (let i = 0; i < slides.length; i++) {
                commit('screenplay/' + ScreenplayMutations.REMOVE_MEDIUM, { type: 'slide', medium_id: slides[i] }, { root: true });
                commit('screenplay/' + ScreenplayMutations.REMOVE_MEDIA_FROM_SCREENPLAY, { type: 'slide', medium_id: slides[i] }, { root: true });
              }
            }
          }
        }
      })
      .catch((error) => {
        noticeMessage(VERSTEHE.vueI18n.t('media_library.delete_failed'));
        console.error(error);
      });
  },

  deleteClip({ state, commit }, clipId) {
    axios
      .post(state.apiBaseUrl + '/delete_medium', {
        medium_type: 'clip',
        medium_id: clipId,
      })
      .then((res) => {
        commit(Mutations.DELETE_CLIP, clipId);
      })
      .catch((e) => console.error(e));
  },

  updateDocumentProgress({ commit }, payload) {
    commit(Mutations.UPDATE_DOCUMENT_PROGRESS, payload);
  },

  updateDocument({ commit }, payload) {
    commit(Mutations.UPDATE_DOCUMENT, payload);
  },

  setSelectionMode({ state, commit }, value: SelectionMode) {
    if (state.settings.canCreateTopics) {
      commit(Mutations.SET_SELECTION_MODE, value);
    } else {
      // Make sure selection mode is always set to none, if user
      // is not allowed to create topics
      commit(Mutations.SET_SELECTION_MODE, SelectionMode.None);
    }
  },

  renameMedium({ commit, state }, payload: { type: string; id: number; title: string }) {
    return new Promise((resolve, reject) => {
      axios
        .post(state.apiBaseUrl + '/rename_medium', {
          medium_type: payload.type,
          medium_id: payload.id,
          title: payload.title,
        })
        .then((response) => {
          // Update in Library
          switch (payload.type) {
            case 'take': {
              commit(Mutations.UPDATE_TAKE, { id: payload.id, values: { title: payload.title } });
              break;
            }
            case 'slideshow': {
              commit(Mutations.UPDATE_DOCUMENT, { id: payload.id, values: { title: payload.title } });
              break;
            }
            case 'clip': {
              commit(Mutations.UPDATE_CLIP, { id: payload.id, values: { title: payload.title } });
            }
            case 'image': {
              commit(Mutations.UPDATE_IMAGE, { id: payload.id, values: { title: payload.title } });
            }
          }

          // Update in Screenplay
          if (state.selectionMode !== SelectionMode.None && payload.type !== 'clip') {
            commit('screenplay/' + ScreenplayMutations.UPDATE_MEDIUM, { type: payload.type, id: payload.id, values: { title: payload.title } }, { root: true });
          }

          resolve(true);
        })
        .catch((error) => {
          console.error(error);
          reject();
        });
    });
  },

  async shareTake({ commit, state }, takeId: number) {
    let take = null;
    await axios
      .post(state.apiBaseUrl + '/share_medium', {
        medium_type: 'take',
        medium_id: takeId,
      })
      .then((response) => {
        take = response.data;

        commit(Mutations.UPDATE_TAKE, { id: takeId, values: { asset_state: take.asset_state } });

        if (take.asset_state === 'approved' && state.publicTakes.results.filter((t) => t.id === take.id).length === 0) {
          commit(Mutations.ADD_PUBLIC_TAKE, take);
        }
      })
      .catch((error) => console.error(error));

    return take;
  },

  async updateAssetState({ commit, state, getters }, payload: { takeId: number; assetState: string }) {
    let take = null;
    await axios
      .post(state.apiBaseUrl + '/asset_state', {
        medium_type: 'take',
        medium_id: payload.takeId,
        state: payload.assetState,
      })
      .then((response) => {
        take = response.data;
        commit(Mutations.UPDATE_TAKE, { id: take.id, values: { asset_state: take.asset_state, state_set_by_user: take.state_set_by_user } });
        let showShareButton = false;
        showShareButton = updateShareButton(state, getters, showShareButton);
        commit(Mutations.SHOW_SHARE_AND_DELETE_BUTTON, showShareButton);
      })
      .catch((error) => console.error(error));

    return take;
  },

  async addTake({ commit, state }, takeId: number) {
    await axios
      .get(state.apiBaseUrl + `/take/${takeId}`)
      .then((response) => {
        commit(Mutations.ADD_PRIVATE_TAKE, response.data);
      })
      .catch((e) => console.error(e));
  },

  pushTake({ commit, state }, take: Take) {
    commit(Mutations.ADD_PRIVATE_TAKE, take);
  },

  async refreshTake({ commit, state }, takeId: number) {
    await axios
      .get(state.apiBaseUrl + `/take/${takeId}`)
      .then((response) => {
        commit(Mutations.UPDATE_TAKE, { amor_id: response.data.amor_id, values: response.data });
      })
      .catch((e) => console.error(e));
  },

  async refreshClip({ commit, state }, clipId: number) {
    await axios
      .get(state.apiBaseUrl + `/clip/${clipId}`)
      .then((response) => {
        commit(Mutations.UPDATE_CLIP, { id: response.data.id, values: response.data });
      })
      .catch((e) => console.error(e));
  },

  /**
   * Clones take and adds it to private library of current user
   * @param takeId
   */
  async cloneTake({ commit, state }, takeId: number) {
    commit(Mutations.UPDATE_TAKE, { id: takeId, values: { is_cloning: true } });

    return await axios
      .post(state.apiBaseUrl + '/clone_medium', {
        medium_type: 'take',
        medium_id: takeId,
      })
      .then((response) => {
        commit(Mutations.ADD_PRIVATE_TAKE, response.data);
        commit(Mutations.SET_INSPECT_ITEM, response.data);
        commit(Mutations.UPDATE_TAKE, { id: takeId, values: { is_cloning: false } });
        noticeMessage(VERSTEHE.vueI18n.t('media_library.clone_success'));
      })
      .catch((error) => {
        commit(Mutations.UPDATE_TAKE, { id: takeId, values: { is_cloning: false } });
        noticeMessage(VERSTEHE.vueI18n.t('media_library.clone_fail'));
        console.error(error);
      });
  },

  async removeBarcodes({ commit, state }, takeId: number) {
    return await axios
      .post(state.apiBaseUrl + '/remove_barcodes', {
        medium_type: 'take',
        medium_id: takeId,
      })
      .then((response) => {
        commit(Mutations.UPDATE_TAKE, { id: response.data.id, values: response.data });
        noticeMessage(VERSTEHE.vueI18n.t('media_library.remove_barcode_success'));
      })
      .catch((error) => {
        noticeMessage(VERSTEHE.vueI18n.t('media_library.remove_barcode_fail'));
        console.error(error);
      });
  },

  addUploadedTake({ commit }, take: Take) {
    commit(Mutations.ADD_UPLOADED_TAKE, take);
  },

  createClip({ commit, state }, take: Take) {
    axios
      .post(state.settings.createClipUrl, {
        from_library: true,
        media_clip: {
          take_id: take.id,
          title: take.title,
          description: take.description,
        },
      })
      .then((response) => {
        if (response.data.state === 'production') {
          VERSTEHE.MediaLibrary.library.subscribeProductionJob(response.data.job);
        }

        commit(Mutations.ADD_CLIP, response.data);
        commit(Mutations.SET_ACTIVE_TAB, 'clips');
        commit(Mutations.SET_INSPECT_ITEM, response.data);
      })
      .catch((error) => console.error(error));
  },

  setMediaTypes({ commit }, mediaTypes: Array<'Take' | 'Document' | 'Clip' | 'Image'>) {
    commit(Mutations.SET_MEDIA_TYPES, mediaTypes);
  },

  inspectTakeById({ commit, state }, takeId: number) {
    const take = state.privateTakes.results.find((take) => take.id === takeId);
    commit(Mutations.SET_INSPECT_ITEM, take);
  },

  setEta({ commit, state }, payload) {
    commit(Mutations.SET_ETA, payload);
  },
};
function updateShareButton(state, getters, showShareButton) {
  state.selectedTakes.forEach((item) => {
    if (
      getters.requestedAndApprovedTakes.length === 0 ||
      (getters.requestedAndApprovedTakes.length > 0 && getters.requestedAndApprovedTakes.findIndex((take) => take.id === item) == -1)
    ) {
      showShareButton = true;
    }
  });
  return showShareButton;
}
