import { BaseDataSource } from './base_data_source';
const GRID_COOKIE_KEY = `default_grid_options_${_app.info.siteId}_${_app.info.user.userId}`;

export class BaseGrid {
  constructor (options) {
    this.gridElement = document.querySelector('.grid-component');

    if (this.gridElement) {
      this.endpointUrl = this.gridElement.getAttribute('data-endpoint');
      this.wrapper = this.gridElement.querySelector('.grid-wrapper');
      this.dataSource = new BaseDataSource ({ endpointUrl: this.endpointUrl, grid: this });

      if (options === undefined || !options.skipInit) {
        this.initGrid();
      }
    }
  }

  /**
   * Updates the dataset in the datasource with the values passed.
   * @param {Integer} token Contains the ID of the dataset in the datasource
   * @param {Hash} attributes Contains a hash with attribute names and the new value.
   */
  updateRow (token, attributes = {}) {
    let item = this.dataSource.dataSource.get(token);

    Object.keys(attributes).forEach(attribute => {
      item[attribute] = attributes[attribute];
    });
  }

  getTableRow (token) {
    let uid = this.dataSource.dataSource.get(token).uid;
    return this.gridElement.querySelector(`tr[data-uid="${uid}"]`);
  }

  gridConfiguration () {
    let configuration = {
      ...this.gridOptions(),
      ...this.gridDataSource(),
      ...this.gridColumns(),
      ...this.gridCallbacks()
    };

    return configuration;
  }

  gridCallbacks () {
    return {
      filter: (e) => this.onGridFilter(e),
      dataBound: (e) => this.onGridDataBound(e),
      sort: (e) => this.onGridSort(e),
      filterMenuInit: (e) => this.onGridFilterMenu(e),
      change: (e) => this.onGridChange(e)
    };
  }

  onGridChange (e) {
    return {};
  }

  onGridFilterMenu (e) {
    return {};
  }

  onGridFilter (e) {
    return {};
  }

  onGridDataBound (e) {
    return {};
  }

  onGridSort (e) {
    return {};
  }

  gridOptions () {
    return {};
  }

  gridColumns () {
    return {};
  }

  gridDataSource () {
    return { dataSource: this.dataSource.dataSource };
  }

  initGrid () {
    if (this.wrapper) {
      this.grid = $(this.wrapper).kendoGrid(this.gridConfiguration()).data('kendoGrid');
    }
  }

  gridCookieKey () {
    return GRID_COOKIE_KEY;
  }

  getGridOptions () {
    try {
      return JSON.parse(VERSTEHE.utils.readCookie(this.gridCookieKey()) || '{}');
    } catch(e) {
      console.error(e);
      VERSTEHE.utils.createCookie(this.gridCookieKey(), "\"filter\":[]");
      return {"filter":[]};
    }
  }

  setGridOptions (option) {
    let options = Object.assign(this.getGridOptions(), option);
    VERSTEHE.utils.createCookie(this.gridCookieKey(), JSON.stringify(options), 365 * 10);

    return options;
  }

  selectAll () {
    let oldPageSize = this.dataSource.dataSource.pageSize();

    let finished = (e) => {
      this.grid.select('tr');
      this.dataSource.dataSource.pageSize(oldPageSize);
      this.dataSource.dataSource.unbind('change', finished);
    };

    this.dataSource.dataSource.bind('change', finished);
    this.dataSource.dataSource.pageSize(this.dataSource.dataSource.total());
  }
}
