import { get as getColor } from 'color-string';

function luminanceFromRgb(rgbColor) {
  const rgb = getColor.rgb(rgbColor);

  return 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2];
}

export class Branding {
  constructor () {
    this.form = document.getElementById('branding-form');
    this.deactivatable = this.form.getAttribute('data-deactivatable') === "true";
    this.defaultColors = this.form.getAttribute('data-default-colors');
    
    if (this.defaultColors) {
      this.defaultColors = JSON.parse(this.defaultColors);
    }

    if (this.form) {
      this.initializeColorPickers();
      this.bindListeners();
    }

    if (this.deactivatable) {
      this.initEnabledSwitch();
    }
  }

  resetColorsToDefault() {
    document.querySelectorAll('.branding-color-input').forEach(input => {
      let value = this.resetVarToDefault(input.dataset.var);
      $(input).data('kendoColorPicker').value(value);
    });

    this.resetVarToDefault('--navbar-foreground-color');
    this.resetToggleColorFields();
  }

  resetVarToDefault(cssVar) {
    // Remove variable definition from <style> tag and <body>
    const cssRule = document.querySelector('style.theme-colors').sheet.cssRules[0];
    cssRule.style.removeProperty(cssVar);
    document.documentElement.style.removeProperty(cssVar);
    
    // Set default from passed attributes if present
    const fromDefaultColors = this.getFromDefaultColors(cssVar);

    if (fromDefaultColors && fromDefaultColors.length > 0) {
      document.documentElement.style.setProperty(cssVar, fromDefaultColors);
      return fromDefaultColors;
    }

    // Get default value
    const defaultValue = getComputedStyle(document.documentElement).getPropertyValue(cssVar).trim();

    if (defaultValue.length === 0 || defaultValue === 'transparent') {
      return null;
    }

    if (cssVar === '--navbar-background-color') {
      this.resetVarToDefault('--navbar-foreground-color');
    }

    return defaultValue;
  }

  initializeColorPickers() {
    document.querySelectorAll('.branding-color-input').forEach(input => {
      const cssVar = input.dataset.var;
      const self = this;

      $(input).kendoColorPicker({
        buttons: false,
        clearButton: true,
        change: function(e) {
          if (e.value === null) {
            // Reset color
            this.close();
            const value = self.resetVarToDefault(cssVar);
            this.value(value);
          }
        },
        select: (e) => {
          document.documentElement.style.setProperty(cssVar, e.value);

          // Calculate foreground color for navbar
          // Usually the same would be needed for footer, but since
          // it's not visible during preview we skip it
          if (cssVar === '--navbar-background-color') {
            // If lumninance of BG color is more than 140 we use black foreground
            // color, otherwise white
            const foregroundColor = luminanceFromRgb(e.value) > 140 ? '0, 0, 0' : '255, 255, 255';
            document.documentElement.style.setProperty('--navbar-foreground-color', foregroundColor);
          }
        }
      });
    });
  }

  resetToggleColorFields() {
    const enableNavBorder = document.getElementById('enable_navbar_border');
    const enableFooterBorder = document.getElementById('enable_footer_border');

    enableNavBorder.checked = false;
    enableFooterBorder.checked = false;
    this.toggleColorField(enableNavBorder);
    this.toggleColorField(enableFooterBorder);
  }

  toggleColorField(checkbox) {
    const wrapper = checkbox.parentNode.parentNode.parentNode;
    const colorInput = wrapper.querySelector('.branding-color-input');

    if (checkbox.checked) {
      wrapper.classList.remove('hide-color-select');
    } else {
      wrapper.classList.add('hide-color-select');
      colorInput.value = null;
      const value = this.resetVarToDefault(colorInput.dataset.var);
      $(colorInput).data('kendoColorPicker').value(value);
    }
  }

  bindListeners() {
    const resetColorsBtn = document.querySelector('.reset-branding-colors');
    const enableNavBorder = document.getElementById('enable_navbar_border');
    const enableFooterBorder = document.getElementById('enable_footer_border');

    resetColorsBtn.addEventListener('click', () => {
      this.resetColorsToDefault();
      resetColorsBtn.blur();
    });

    enableNavBorder.addEventListener('change', (e) => this.toggleColorField(enableNavBorder));
    enableFooterBorder.addEventListener('change', (e) => this.toggleColorField(enableFooterBorder));
  }

  initEnabledSwitch() {
    const checkBox = document.getElementById('branding-enabled-field');
    const fieldWrapper = document.querySelector('.branding-fields-wrapper');

    checkBox.addEventListener('change', (e) => {
      if (checkBox.checked) {
        fieldWrapper.classList.remove('disabled');
      } else {
        fieldWrapper.classList.add('disabled');
      }
    });
  }

  getFromDefaultColors(cssVar) {
    if (this.defaultColors && this.defaultColors[cssVar]) {
      const defaultValue = this.defaultColors[cssVar];

      if (defaultValue && defaultValue.length > 0) {
        document.documentElement.style.setProperty(cssVar, defaultValue);
        return defaultValue;
      } else {
        return null;
      }
    }
  }
}
