<template>
<div class="loading-spinner">
  <div class="spinner-circle">
    <span class="spinner-circle-outer"></span>
    <span class="spinner-circle-inner"></span>
  </div>
</div>
</template>

<script>
export default {
  name: 'loading-spinner'
}
</script>

<style lang="scss" scoped>
@keyframes border-animate {
  0% {
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }

  25% {
    border-top-color: var(--accent-color);
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }

  50% {
    border-top-color: var(--accent-color);
    border-right-color: var(--accent-color);
    border-bottom-color: transparent;
    border-left-color: transparent;
  }

  75% {
    border-top-color: var(--accent-color);
    border-right-color: var(--accent-color);
    border-bottom-color: var(--accent-color);
    border-left-color: transparent;
  }

  100% {
    border-top-color: var(--accent-color);
    border-right-color: var(--accent-color);
    border-bottom-color: var(--accent-color);
    border-left-color: var(--accent-color);
  }
}

.loading-spinner {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: $translucent-white-heavy-95;
  z-index: 100;

  .spinner-circle {
    position: relative;
    width: 100px;
    height: 100px;
  }

  .spinner-circle-outer {
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    left: 0;
    border: 50px solid var(--accent-color);
    border-radius: 50%;
    animation: border-animate 1.6s linear 0s infinite alternate;
  }

  .spinner-circle-inner {
    position: absolute;
    left: 10px;
    top: 10px;
    width: 80px;
    height: 80px;
    background-color: $color-white;
    border: 4px solid var(--accent-color);
    border-radius: 50%;
  }
}
</style>
