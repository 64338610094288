export class BaseDataSource {
  constructor (options) {
    this.options = options;
    this.endpointUrl = options.endpointUrl;
    this.grid = options.grid;

    this.initDataSource();
  }

  initDataSource () {
    let configuration = {
      ...this.initTransport(),
      ...this.initGeneralOptions(),
      ...this.initSchema()
    };

    this.dataSource = new kendo.data.DataSource(configuration);
  }

  prepareReadParams (options) {
    let params = {};
            
    if (options.data.page) {
      params['page'] = options.data.page;
    }

    if (options.data.pageSize) {
      params['per_page'] = options.data.pageSize;
    }

    if (options.data.sort) {
      params['sort_field'] = options.data.sort[0].field;
      params['sort_dir'] = options.data.sort[0].dir;
    }

    if (options.data.filter) {
      params['filter'] = options.data.filter.filters || [];
    }

    return params;
  }

  read (options) {
    $.ajax({
      url: this.endpointUrl,
      dataType: 'json',
      data: this.prepareReadParams(options),
      success: options.success,
      error: options.error
    });
  }

  destroy (options) { }

  initTransport () {
    return {
      transport: {
        read: (options) => this.read(options),
        destroy: (options) => this.destroy(options)
      }
    };
  }

  initSchema () {
    return {};
  }

  initGeneralOptions () {
    return {
      serverSorting: true,
      serverPaging: true,
      serverFiltering: true,
      page: 1,
      pageSize: 20
    };
  }
}
