import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from 'vue-property-decorator';
import { MapGetter, MapAction } from '../../vue/decorators';
import { Getters } from '../store/getters';
import { Take, Settings } from '../interfaces';
import { Actions } from '../store/actions';
import { confirmDialog } from '../../common/dialog';
import { noticeMessage } from '../../mixins/flash_message';

/**
 * Mixin for shared methods by TakeItem- and TakePreview-Components
 */
@Component
export class TakeMixin extends Vue {
  @Inject('recorder') recorder;
  @MapGetter(Getters.BASE_URL) baseUrl;
  @MapGetter(Getters.SETTINGS) settings: Settings;
  @MapAction(Actions.DELETE_MEDIUM) deleteMedium;
  @MapAction(Actions.SHARE_TAKE) shareTake;
  @MapAction(Actions.CLONE_TAKE) cloneTake;
  @MapAction(Actions.REMOVE_BARCODES) removeBarcodesAction;

  item: Take;
  menu: any;

  /**
   * Returns tooltip for share button depending on the medium state
   * @return {string}
   */
  get shareTooltip(): string {
    if (this.item.asset_state === 'requested') {
      return this.$i18n.t('media_library.shared_take_requested') as string;
    } else if (this.item.state !== 'uploaded') {
      return this.$i18n.t('media_library.upload_needed') as string;
    } else {
      return this.$i18n.t('media_library.share_tooltip') as string;
    }
  }

  /**
   * Returns tooltip for clone button depending on the medium state
   * @return {string}
   */
  get cloneTooltip(): string {
    if (this.item.is_cloning) {
      return this.$i18n.t('media_library.is_cloning') as string;
    } else if (this.item.state !== 'uploaded') {
      return this.$i18n.t('media_library.upload_needed') as string;
    } else {
      return this.$i18n.t('media_library.clone_tooltip') as string;
    }
  }

  /**
   * Returns whether medium is shared
   * @return {boolean}
   */
  get shared(): boolean {
    return this.item.asset_state === 'approved';
  }

  /**
   * Returns whether share button should be enabled
   * @return {boolean}
   */
  get shareDisabled(): boolean {
    return !this.uploaded || this.item.asset_state === 'requested';
  }

  /**
   * Returns whether user can share takes
   * @return {boolean}
   */
  get canShare(): boolean {
    return _app.info.user.highestRoleId >= _app.roles.find(role => role.key === this.settings.releaseTakeRole).id;
  }

  /**
   * Returns whether user is owner of the take
   * @return {boolean}
   */
  get isOwner(): boolean {
    return _app.info.user.userId === this.item.user.id;
  }

  /**
   * Returns whether clone button should be displayed (User should have author role)
   * @return {boolean}
   */
  get cloneEnabled(): boolean {
    return (_app.info.user.roles.indexOf("author") != -1);
  }

  /**
   * Returns whether medium is already uploaded
   * @return {boolean}
   */
  get uploaded(): boolean {
    return this.item.state === 'uploaded';
  }

  /**
   * Returns whether medium has a barcode assigned
   */
  get hasBarcode(): boolean {
    return this.item.has_barcode;
  }

  /**
   * Start editing in RA with confirm Dialog if Take was requested for public release
   * @return {void}
   */
  edit(): void {
    if (this.item.asset_state === 'requested') {
      confirmDialog({ content: this.$i18n.t('media_library.requested_take_confirm_notice') })
        .then(() => {
          this.editTake();
        })
        .catch(() => {
        });
    } else {
      this.editTake();
    }
  }

  /**
   * Starts editing in RA
   * @return {void}
   */
  editTake(): void {
    this.recorder.startAndCall(() => {
      this.recorder.edit(this.item.amor_id, true, this.item.state === 'uploaded');
    });
  }

  /**
   * Display confirm dialog and delete remote take
   * @return {void}
   */
  deleteTake(): void {
    confirmDialog({ content: this.$i18n.t('media_library.confirm_delete') }).then(() => {
      this.deleteMedium({ medium_type: 'take', medium_id: this.item.id });
    });
  }

  /**
   * Display confirm dialog and request public release for take
   * @return {void}
   */
  share(): void {
    confirmDialog({ content: this.$i18n.tc('media_library.confirm_share', 1) }).then(() => {
      this.shareTake(this.item.id).then((take: Take) => {
        noticeMessage(this.$i18n.t('media_library.shared_take_response_' + take.asset_state));
      });
    });
  }

  /**
   * Clone take for current user
   * @param {MouseEvent} event - mouse event when triggert by click
   * @return {void}
   */
  clone(event?: MouseEvent): void {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.cloneTake(this.item.id).then(() => {
      this.menu.close();
    });
  }

  createClip(event?: MouseEvent): void {
    this.$store.dispatch(Actions.CREATE_CLIP, this.item);
  }

  /**
   * Display Confirm Message and trigger remove Barcode Action
   */
  removeBarcodes() {
    confirmDialog({ content: this.$i18n.t('media_library.confirm_remove_barcode_assignment') }).then(() => {
      this.removeBarcodesAction(this.item.id);
    });
  }
}
