import { UppyModule } from './store_module';
import store from '../../store';

export class UppyStore {
  constructor(private prefix = 'uppyStore') {
    store.registerModule(prefix, UppyModule);
  }

  public getState() {
    return store.getters[this.prefix + '/state'];
  }

  public setState(patch) {
    store.dispatch(this.prefix + '/setState', patch);
  }

  public subscribe(cb) {
    return store.subscribe((mutations, state) => {
      if (mutations.type.startsWith(this.prefix)) {
        cb(mutations, state);
      }
    });
  }
}
