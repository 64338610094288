<template>
  <div class="livestream-azure-integration">
    <div class="livestream-azure-settings">
      <ValidationProvider rules="required" :name="$t('integrations.livestream_azure_account_name')" v-slot="{ valid, errors }">
        <div class="form-group label-static" :class="{ 'error': errors.length }">
          <label for="livestream_azure_account_name" class="control-label">{{ $t('integrations.livestream_azure_account_name') }}</label>
          <input v-model="livestream_azure_account_name" type="text" class="form-control" id="livestream_azure_account_name">
          <span v-if="errors.length > 0" class="help-block error-block">{{ errors[0] }}</span>
        </div>
      </ValidationProvider>

      <ValidationProvider rules="required" :name="$t('integrations.livestream_azure_resource_group_name')" v-slot="{ valid, errors }">
        <div class="form-group label-static" :class="{ 'error': errors.length }">
          <label for="livestream_azure_resource_group_name" class="control-label">{{ $t('integrations.livestream_azure_resource_group_name') }}</label>
          <input v-model="livestream_azure_resource_group_name" type="text" class="form-control" id="livestream_azure_resource_group_name">
          <span v-if="errors.length > 0" class="help-block error-block">{{ errors[0] }}</span>
        </div>
      </ValidationProvider>

      <ValidationProvider rules="required" :name="$t('integrations.livestream_azure_subscription_id')" v-slot="{ valid, errors }">
        <div class="form-group label-static" :class="{ 'error': errors.length }">
          <label for="livestream_azure_subscription_id" class="control-label">{{ $t('integrations.livestream_azure_subscription_id') }}</label>
          <input v-model="livestream_azure_subscription_id" type="text" class="form-control" id="livestream_azure_subscription_id">
          <span v-if="errors.length > 0" class="help-block error-block">{{ errors[0] }}</span>
        </div>
      </ValidationProvider>

      <ValidationProvider rules="required" :name="$t('integrations.livestream_azure_client_id')" v-slot="{ valid, errors }">
        <div class="form-group label-static" :class="{ 'error': errors.length }">
          <label for="livestream_azure_client_id" class="control-label">{{ $t('integrations.livestream_azure_client_id') }}</label>
          <input v-model="livestream_azure_client_id" type="text" class="form-control" id="livestream_azure_client_id">
          <span v-if="errors.length > 0" class="help-block error-block">{{ errors[0] }}</span>
        </div>
      </ValidationProvider>

      <ValidationProvider rules="required" :name="$t('integrations.livestream_azure_client_secret')" v-slot="{ valid, errors }">
        <div class="form-group label-static" :class="{ 'error': errors.length }">
          <label for="livestream_azure_client_secret" class="control-label">{{ $t('integrations.livestream_azure_client_secret') }}</label>
          <input v-model="livestream_azure_client_secret" type="password" class="form-control" id="livestream_azure_client_secret">
          <span v-if="errors.length > 0" class="help-block error-block">{{ errors[0] }}</span>
        </div>
      </ValidationProvider>

      <ValidationProvider rules="required" :name="$t('integrations.livestream_azure_storage_account_name')" v-slot="{ valid, errors }">
        <div class="form-group label-static" :class="{ 'error': errors.length }">
          <label for="livestream_azure_storage_account_name" class="control-label">{{ $t('integrations.livestream_azure_storage_account_name') }}</label>
          <input v-model="livestream_azure_storage_account_name" type="text" class="form-control" id="livestream_azure_storage_account_name">
          <span v-if="errors.length > 0" class="help-block error-block">{{ errors[0] }}</span>
        </div>
      </ValidationProvider>

      <slot/>
    </div>
  </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex';
  const { mapGetters, mapActions } = createNamespacedHelpers('integrations');

  export default {
    data() {
      return {
        more: false,
        livestream_azure_account_name: null,
        livestream_azure_resource_group_name: null,
        livestream_azure_subscription_id: null,
        livestream_azure_client_id: null,
        livestream_azure_client_secret: null,
        livestream_azure_storage_account_name: null
      }
    },
    computed: {
      ...mapGetters([
        'integrationById'
      ]),
      integration() {
        return this.integrationById('livestream_azure');
      },
      settings() {
        return {
          livestream_azure_account_name: this.livestream_azure_account_name,
          livestream_azure_resource_group_name: this.livestream_azure_resource_group_name,
          livestream_azure_subscription_id: this.livestream_azure_subscription_id,
          livestream_azure_client_id: this.livestream_azure_client_id,
          livestream_azure_client_secret: this.livestream_azure_client_secret,
          livestream_azure_storage_account_name: this.livestream_azure_storage_account_name
        }
      },
    },
    methods: {
      load() {
        const settings = this.integration.settings;
        this.livestream_azure_account_name = settings.livestream_azure_account_name,
        this.livestream_azure_resource_group_name = settings.livestream_azure_resource_group_name,
        this.livestream_azure_subscription_id = settings.livestream_azure_subscription_id,
        this.livestream_azure_client_id = settings.livestream_azure_client_id,
        this.livestream_azure_client_secret = settings.livestream_azure_client_secret,
        this.livestream_azure_storage_account_name = settings.livestream_azure_storage_account_name
      }
    },
    mounted() {
      this.load();
      $.material.init();
    },
    watch: {
    }
  }
</script>

<style lang="scss" scoped>
 .livestream-azure-integration {
    display: flex;
  }

  .livestream-azure-settings {
    flex: 1 0;
    align-self: flex-start;
    min-width: 400px;
    max-width: 800px;
    background-color: $color-white;
    border-radius: 3px;
    padding: 30px;
  }
</style>
