import { Action } from "./action";
import { TabAction } from "./tab_action";

/**
  * StatusActions handle the Status of Users
  * When a User joins he sends a "Hello".
  * If other users are present already they reply with "hello-back"
  * When a user leaves he sends a "goodbye"
  * regarding to the Status a user sends, their Avatar, Cursor, Highlights etc. are changed, added or removed.
  * StatusActions are never Queued
  */
export class StatusAction extends Action {
  /**
   * @constructor
   * @param {string} tabId  - TabId of the tab the action originates from
   * @param {number} user - UserId of the chat room owner
   * @param {string} status - status of the user can be: hello, hello-back, update-collaborators or goodbye
   */
  constructor(tabId, user, status) {
    super(tabId, user);
    this.status = status;
    this.actionType = "StatusAction";
  }

  execute() {
    if (this.status === "hello" || (this.status === "hello-back" && this.user !== _app.info.user.userId)) {
      TabAction.updateTab(this.user, this.tabId);
    }
    if (this.status === "hello" && this.user !== _app.info.user.userId) {
      StatusAction.sendStatusAction("hello-back");
    }
    if (this.status === "update-collaborators" && this.user !== _app.info.user.userId) {
      collabCenter.updateCollaborators(false);
    } else if (this.status === "goodbye") {
      VERSTEHE.CollaborativeAuthoring.removeCursorAndHighlights(this.user);

      if (VERSTEHE.Course.manage) {
        VERSTEHE.Course.manage.app.$store.dispatch("quizManage/setQuestionEditors", {questionId: null, editor: this.user, silent: true});
      }
    }
  }

  static fromJson(json) {
    let action = JSON.parse(json);
    return new StatusAction(action.tabId, action.user, action.status);
  }

  static getTabId() {
    let activeSteps = VERSTEHE.ContributionWorkflow.workflow?.getActiveSteps();
    return activeSteps.step;
  }

  static sendStatusAction(status) {
    if (VERSTEHE.CollaborativeAuthoring.isEnabled()) {
      let action = new StatusAction(StatusAction.getTabId(), _app.info.user.userId, status);
      VERSTEHE.content_channel.send_message(action);
    }
  }
}
