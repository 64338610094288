import Vue from 'vue';
import store from '../../store';
import { QuizManageModule } from './store';
import QuestionsForm from './components/questions_form.vue';
import GeneralForm from './components/general_form.vue';
import { Actions } from './store/actions';
import { quizSchema } from './entities';
import { normalize } from 'normalizr';
import { serializeQuiz } from './serializer';
import { imagemapAreaValidator } from './validators/imagemap_area';
import { Validator } from 'vee-validate';
import { Getters } from './store/getters';
import axios from 'axios';
import { flashMessage } from '../../mixins/flash_message';
import { QuizAction } from '../../collaborative_authoring/actions/content_actions/course_actions/quiz_action';

export class QuizManageApp {
  public questionsFormComponent: Vue;
  public generalFormComponent: Vue;
  private saveButton: HTMLButtonElement;
  private questionsRootElement: HTMLElement;
  private generalRootElement: HTMLButtonElement;
  private isNew: boolean;

  public init() {
    this.questionsRootElement = document.querySelector('#v-quiz-manage-questions');
    this.generalRootElement = document.querySelector('#v-quiz-manage-general');

    if (this.questionsRootElement && this.generalRootElement) {
      const initialData = JSON.parse(this.questionsRootElement.getAttribute('data-serialized-quiz'));
      this.isNew = initialData.isNew;

      QuizManageApp.initStore(initialData);
      this.initQuestionsComponent();
      this.initGeneralFormComponent();
      this.initSaveQuizButton();
    }
  }

  public static initStore(initialData) {
    if (store.state.quizManage) {
      store.unregisterModule('quizManage');
    }
    const normalized = normalize(initialData.questions, quizSchema);
    store.registerModule('quizManage', QuizManageModule);
    store.dispatch(Actions.INITIALIZE, {
      entities: normalized.entities,
      quiz: {
        id: initialData.id,
        questions: normalized.result,
        general: initialData.general,
        urls: initialData.urls,
        isNew: initialData.isNew
      }
    });

    store.dispatch(Actions.SET_CURRENT_QUESTION, normalized.result[0]);

    // Sync state from collaborative authors
    QuizAction.syncStateAction();
  }

  private initGeneralFormComponent() {
    this.generalFormComponent = new Vue({
      name: 'QuizManageGeneral',
      el: '#v-quiz-manage-general',
      store: store,
      i18n: VERSTEHE.vueI18n,
      render: h => h(GeneralForm)
    });
  }

  private initQuestionsComponent() {
    Validator.extend('imagemap', imagemapAreaValidator);

    this.questionsFormComponent = new Vue({
      name: 'QuizManageQuestions',
      el: '#v-quiz-manage-questions',
      store: store,
      i18n: VERSTEHE.vueI18n,
      render: h => h(QuestionsForm)
    });
  }

  public async initSaveQuizButton() {
    this.saveButton = document.querySelector('#save-quiz');
    this.saveButton.addEventListener('click', async (evt) => {
      this.saveButton.classList.add('disabled');
      const generalInvalid = await (this.generalFormComponent.$children[0] as any).observer.validate() === false;
      const quizInvalid = store.getters[Getters.QUIZ_INVALID];

      if (quizInvalid || generalInvalid) {
        this.questionsFormComponent.$root.$emit('validate_observer');
        VERSTEHE.ContributionWorkflow.workflow.setErrorStepState('general', generalInvalid);
        VERSTEHE.ContributionWorkflow.workflow.setErrorStepState('questions', quizInvalid);
        VERSTEHE.ContributionWorkflow.workflow.activateFirstWithError();
        this.quizSaveFailed();
      } else {
        if (this.isNew) {
          axios.post(store.state.quizManage.urls.createUrl, serializeQuiz()).then((response) => {
            window.location.replace(response.data.urls.editUrl);
          }).catch((e) => {
            console.log(e);
            this.quizSaveFailed();
          });
        } else {
          axios.patch(store.state.quizManage.urls.updateUrl, serializeQuiz()).then((response) => {
            QuizManageApp.initStore(response.data);
            VERSTEHE.ContributionWorkflow.workflow.setErrorStepState('general', false);
            VERSTEHE.ContributionWorkflow.workflow.setErrorStepState('questions', false);

            flashMessage(VERSTEHE.vueI18n.t('quiz.quiz_saved'))
            this.saveButton.classList.remove('disabled');
          }).catch((e) => {
            console.log(e);
            this.quizSaveFailed();
          });
        }
      }
    });
  }

  private quizSaveFailed() {
    this.generalFormComponent.$root.$emit('validate_observer');
    flashMessage(VERSTEHE.vueI18n.t('quiz.quiz_save_failed'));
    this.saveButton.classList.remove('disabled');
  }
}
export default new QuizManageApp();
