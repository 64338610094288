import { BaseTree } from './base_tree';
/** Class representing a tree select element */
export class Tree extends BaseTree {
  /**
   * @constructor
   * @param {string} uuid - Unique identifier for tree instance
   * @param {string} type - Type of the tree select
   */
  constructor (uuid, type, fieldId) {
    super(uuid, type, fieldId);

    // DOM Elements
    this._select = this._wrapper.querySelector('.tree-form-control');
    this._valueLabelsWrapper = this._wrapper.querySelector('.tree-select-items');
    this._modalButton = this._wrapper.querySelector('[data-toggle="modal"][data-target]');
    this._modalId = this._modalButton.getAttribute('data-target');

    $(this._modalId).on('show.bs.modal', () => {
      this.toggleTemporaryState();
      this.initialState({});
    });
  }

  initialState (e) {
    super.initialState(e);
    // If node is undefined, data was bound at root level
    if (!e.node && !this.temporaryState) {
      let checkedIds = [];
      let items = this._valueLabelsWrapper.querySelectorAll('.tree-select-item');

      for (let i = 0; i < items.length; i++) {
        let id = parseInt(items[i].getAttribute('data-id'), 10);
        checkedIds.push(id);
      }

      this.setChecked(checkedIds);

      if(!this._treeControl.getAttribute('data-close-all')) {
        this.expandAll();
      }

      // Set a flag for temp changes in the tree
      this.toggleTemporaryState();
      this.ready = true;
      
      if (this.onready && typeof this.onready === 'function') {
        this.onready();
      }
    }
  }

  setSelectedElementsHelper(elements, triggerChange = true) {
    super.setSelectedElementsHelper(elements);

    elements.forEach(function(element) {
      let option = this._select.querySelector('option[value="' + element.id + '"]');

      if (option) {
        option.setAttribute('selected', 'selected');
      } else {
        this.createSelectOption(element);
      }

      if(!this._valueLabelsWrapper.querySelector('span[data-id="' + element.id + '"]')) {
        this.createSpanElement(element);
      }
    }, this);
    
    if (triggerChange) {
      this._select.dispatchEvent(new Event('change'));
    }
  }

  createSpanElement(element) {
    let classes = ['tree-select-item', 'label', 'label-primary'];
    let span = document.createElement('span');
    span.innerHTML = element.title;
    for (let i = 0; i < classes.length; i++) {
      span.classList.add(classes[i]);
    }
    span.setAttribute('data-id', element.id);
    this._valueLabelsWrapper.insertAdjacentElement('beforeend', span);
  }

  createSelectOption(element) {
    let opt = document.createElement("option");
    opt.text = element.title;
    opt.value = element.id;
    opt.setAttribute('selected', "selected");

    this._select.add(opt);
  }

  removeUncheckedLabels() {
    let labels = this._valueLabelsWrapper.querySelectorAll('span');

    for (let i = 0; i < labels.length; i++) {
      if (this.ids.indexOf(parseInt(labels[i].getAttribute('data-id'), 10)) === -1 ) {
        this._valueLabelsWrapper.removeChild(labels[i]);
      }
    }
  }

  uncheckSelectOptions() {
    let options = this._select.querySelectorAll('option[selected="selected"]');

    for (let i = 0; i < options.length; i++) {
      if(this.ids.indexOf(parseInt(options[i].value, 10)) === -1) {
        options[i].removeAttribute('selected');
      }
    }
  }
}
