<template>
  <div class="getting-started-task-detail">
    <fade-transition mode="out-in" :duration="100">
      <component :is="activeTaskComponent"/>
    </fade-transition>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { FadeTransition } from 'vue2-transitions'

  import SetupProfile from './tasks/setup_profile';
  import FirstVideo from './tasks/first_video';
  import PublishVideo from './tasks/publish_video';
  import CreateCourse from './tasks/create_course';
  import CreateQuiz from './tasks/create_quiz';
  import CreateCoursePlan from './tasks/create_course_plan';
  import CustomizeTopicAreas from './tasks/customize_topic_areas';
  import CustomBranding from './tasks/custom_branding';
  import VideoTemplate from './tasks/video_template';
  import LandingPage from './tasks/landing_page';

  import Welcome from './welcome';

  export default {
    components: {
      FadeTransition,
      Welcome,
      SetupProfile,
      FirstVideo,
      PublishVideo,
      CreateCourse,
      CreateQuiz,
      CreateCoursePlan,
      CustomizeTopicAreas,
      CustomBranding,
      VideoTemplate,
      LandingPage
    },
    computed: {
      ...mapGetters({
        activeTask: 'gettingStarted/activeTask'
      }),
      activeTaskComponent() {
        if (this.activeTask) {
          return this.activeTask.replace(/_/g, '-');
        } else {
          return 'welcome';
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .getting-started-task-detail {
    flex-grow: 1;
    padding: 30px;
  }

  ::v-deep h3 {
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 15px;
  }

  ::v-deep p {
    color: $black-54-opacity;
    max-width: 400px;
    margin: 0;
  }

  ::v-deep .btn {
    margin: 15px 0 30px;
  }

  ::v-deep .getting-started-video-wrapper {
    max-width: 640px;
  }

  ::v-deep .video-player {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
  }

  ::v-deep .video-player .video-js {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
</style>
