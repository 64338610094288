<template>
<div class="course-lesson">
  <div class="course-lesson-title">{{ this.quiz.title }}</div>
  <loading-spinner v-show="!isLoaded"></loading-spinner>

  <div class="quiz-content" ref="quizContent"></div>
</div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('coursePerform');

import { checkFetchStatus } from '../../utils';
import LoadingSpinner from '../../../globals/loading_spinner.vue';

export default {
  name: 'quiz',
  components: {
    LoadingSpinner
  },
  data() {
    return {
      isLoaded: false
    }
  },
  computed: {
    ...mapGetters([
      'course'
    ]),
    quiz() {
      return this.course.quiz;
    }
  },
  created() {
    fetch(this.quiz.url, { credentials: 'include' })
      .then(response => response.text())
      .then(response => {
        const html = response;

        this.$refs.quizContent.innerHTML = response;
        VERSTEHE.Quiz.initQuiz();
        this.isLoaded = true;
      })
  }
}
</script>

<style lang="scss" scoped>
.course-lesson {
  position: relative;
}
</style>
