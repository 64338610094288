import { t } from '../configurations/localization';
import { BaseFilterColumn } from '../../grid/filter/base_filter_column';

export class FilterColumn extends BaseFilterColumn {
    /**
   * @constructor
   * @param {Object} kendoColumn - Column object from Kendo UI library
   */
  constructor (kendoColumn, filter) {
    super(kendoColumn, filter);
  }

  /**
   * Remove the filter for this column
   */
  reset () {
    // clear topicAreaTree when filter is removed
    if (this.field === "topic_areas"){
      this.filter.topicAreaTree.setChecked([]);
    }

    this.filters = null;
    this.filterMenu.clear();
    this.removeFilterLabel();
  }

  /**
   * Create & prepare the filter label for display in filter input
   */
  setupFilterLabel () {
    this.filterLabel = document.createElement('div');
    this.filterLabel.className = 'grid-filter-label';

    let filterLabelInner = document.createElement('div');
    filterLabelInner.className = 'grid-filter-label-inner';

    let filterText = document.createElement('span');
    let filterReset = document.createElement('i');
    filterReset.className = 'material-icons';
    filterReset.innerText = 'clear';

    filterReset.addEventListener('click', () => this.reset());

    this.filterLabel.addEventListener('click', e => {
      if (!e.target.isEqualNode(filterReset)) {
        if (!this.filters.find(filter => filter.readOnly)) {
          this.filter.fieldSelect.close();       
          if (this.field === "topic_areas"){
            let checkedIds = this.isActive() ? this.filters[0].value.split(',').map(Number) : [];
            this.filter.topicAreaTree.setChecked(checkedIds);
            $(this.filter.topicAreaTree._dialog).modal();
          } else {
            this.open();
          }
        }
      }
    });

    filterLabelInner.appendChild(filterText);
    filterLabelInner.appendChild(filterReset);
    this.filterLabel.appendChild(filterLabelInner);
  }

  /**
   * Build the label text for filter input
   * e.g. "Created by contains 'Test'"
   */
  filterLabelText () {
    return new Promise((resolve, reject) => {
      // Use selected option text for boolean filters
      if (this.filterMenu.type === 'boolean') {
        return this.filters[0].value === 'true' ? this.filterable.messages.isTrue : this.filterable.messages.isFalse;
      }

      let text = this.title + " ";
      text += this.filters.map(filter => {
        let operator = this.filterOperator(filter.operator);
        let value = filter.value;

        if (this.filterMenu.type === 'date') {
          value = kendo.parseDate(value);
        }

        if (this.kendoColumn.format) {
          value = kendo.format(this.kendoColumn.format, value);
        }


        if (this.field === "evaluation_state") {
          value = t(`state_${value}`);
        }

        if (this.field === "topic_areas"){
          let filterIds = filter.value.split(",").map(Number);
          text = this.getTopicAreaLabel(filterIds).then((selectedItems) => {
            value = selectedItems.map(selectedItem => selectedItem.title).join(", ");
            return `${this.title}  ${operator} '${value}'`;
          });
          resolve(text);
        }

        return `${operator} '${value}'`;
      }).join(` ${t('and')} `);
      
      resolve(text);
    });
  }

    /**
   * promise to get the topicAreas from the topicAreaTrees data attribute
   * if the data attribute is not loaded yet it will bind a eventlistener
   * to get the topicAreas as soon as the data attribute is loaded
   */
  getTopicAreaLabel(filterIds) {
    return new Promise((resolve, reject) => {
      if (this.filter.topicAreaTree.data) {
        resolve(this.getSelectedItemsfromIds(filterIds));
      } else {
        this.filter.topicAreaTree.once("dataLoaded", () => {
          resolve(this.getSelectedItemsfromIds(filterIds));
        });
      }
    });
  }


  getSelectedItemsfromIds(filterIds) {
    let selectedItems = [];

    filterIds.forEach((id) => {
      selectedItems.push(this.filter.topicAreaTree.getItemById(id));
    });

    return selectedItems;
  }

  /**
   * Opens the filter popup
   */
  open () {
    if (this.field === 'topic_areas') {
      return $(this.filter.topicAreaTree._dialog).modal();
    } else {
      this.popup.open();
      return this.popupStyling();
    }
  }
}
