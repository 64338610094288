import { ChangeAction } from "../../system_actions/change_action";
import store from '../../../../store';
import { Actions as QuizStoreActions } from '../../../../quizzes/manage/store/actions';

const mutationWhitelist = [
  'quizManage/UPDATE_ENTITIES',
  'quizManage/SET_QUESTION_IDS',
  'quizManage/CLEAR_QUESTION_IMG',
  'quizManage/SET_QUESTION_IMG',
  'quizManage/SET_ANSWER_IMG',
  'quizManage/SET_QUESTION_ATTRIBUTES',
  'quizManage/SET_ANSWER_ATTRIBUTES',
  'quizManage/SET_MATCHING_ANSWER_ATTRIBUTES',
  'quizManage/SET_ANSWER_IDS',
  'quizManage/ADD_ANSWER',
  'quizManage/ADD_MATCHING_ANSWER',
  'quizManage/ADD_MATCHING_OPTION',
  'quizManage/SET_MATCHING_OPTION',
  'quizManage/DELETE_ANSWER',
  'quizManage/DELETE_MATCHING_ANSWER',
  'quizManage/DELETE_QUESTION',
  'quizManage/ADD_QUESTION',
  'quizManage/UPDATE_ARRANGEMENT_SOLUTIONS',
  'quizManage/SET_GENERAL_ATTRIBUTES',
  'quizManage/SET_GENERAL_IMG',
];

const stateWhitelist = [
  'questions',
  'entities',
  'general',
];

let stateSynced = false;

/**
 * QuizActions handles all changes that occur in the quiz tab
 */
export class QuizAction extends ChangeAction {
  /**
   * @constructor
   * @param {string} tabId  - tabId of the tab the action originates from
   * @param {number} user - userId of the chat room owner
   * @param {String} element - element thats subject of the ChangeAction
   * @param {number} data - data (state of the vue sore or other payload)
   * @param {String} type - type of the QuizAction
   */
  constructor(tabId, user, element, data, type) {
    super(tabId, user, element);
    this.data = data;
    this.type = type;
    this.actionType = "QuizAction";
  }

  execute() {
    switch(this.type) {
      case "quiz/change":
        store.dispatch(QuizStoreActions.SET_STATE, QuizAction.whitelistedState(this.data));
        break;
      case "quiz/setQuestionEditors":
        store.dispatch(QuizStoreActions.SET_QUESTION_EDITORS, this.data);
        break;
      case "resetQuiz":
        VERSTEHE.Course.quiz.resetQuiz(true);
        break;
      case "setQuiz":
        VERSTEHE.Course.quiz.setQuiz(this.data, true);
        break;
      case "quiz/syncState":
        QuizAction.syncStateResponseAction();
        break;
      case "quiz/syncStateResponse":
        if (!stateSynced) {
          store.dispatch(QuizStoreActions.SET_STATE, QuizAction.whitelistedState(this.data));
          stateSynced = true;
        }
        break;
    }
  }

  static fromJson(json) {
    let action = JSON.parse(json);
    return new QuizAction(
      action.tabId,
      action.user,
      action.elementLocation,
      action.data,
      action.type
    );
  }

  static registerToElement() {
    if (VERSTEHE.Course.manage) {
      store.subscribe((mutation, state) => {
        if (mutationWhitelist.includes(mutation.type)) {
          if (!(mutation.payload?.silent)) {
            QuizAction.createAction(
              QuizAction.getTabId(),
              _app.info.user.userId,
              null,
              QuizAction.whitelistedState(state.quizManage),
              "quiz/change"
            );
          }
        }
      });
    }
  }

  static createAction(tabId, user, element, data, type) {
    if(VERSTEHE.Course?.manage?.app?.$store?.state?.course?.isCollaborative){
      let action = new QuizAction(tabId, user, element, data, type);
      VERSTEHE.content_channel.send_message(action);
    }
  }

  static setQuestionEditorAction(questionId, editor) {
    QuizAction.createAction(
      QuizAction.getTabId(),
      _app.info.user.userId,
      null,
      { questionId: questionId, editor: editor },
      "quiz/setQuestionEditors"
    );
  }

  /**
   * Request current state from other trainees
   */
  static syncStateAction() {
    QuizAction.createAction(
      QuizAction.getTabId(),
      _app.info.user.userId,
      null,
      null,
      "quiz/syncState"
    );
  }

  /**
   * Respond with current state
   */
  static syncStateResponseAction() {
    QuizAction.createAction(
      QuizAction.getTabId(),
      _app.info.user.userId,
      null,
      QuizAction.whitelistedState(store.state.quizManage),
      "quiz/syncStateResponse"
    );
  }

  /**
   * return a new object only with keys that are whitlisted in 'stateWhitelist'
   */
  static whitelistedState(state) {
    return Object.keys(state).filter(key => stateWhitelist.includes(key)).reduce((obj, key) => {
      obj[key] = state[key];
      return obj;
    }, {});
  }
}
