import { BaseDataSource } from '../grid/base_data_source';

export class DataSource extends BaseDataSource {
  constructor (options) {
    super(options);

  }

  initSchema () {
    let schema = {
      schema: {
        data: 'users',
        model: {
          id: 'id',
          fields: {
            thumbnail: { type: 'string' },
            name: { type: 'string' },
            email: { type: 'string' },
            role: { type: 'string' },
            created_at: { type: 'date' },
            confirmed_at: { type: 'date' },
            has_access: { type: 'string' },
            managed: { type: 'boolean' },
            current_sign_in_at: { type: 'date' },
            last_signed_in_mobile: { type: 'date' },
          }
        },
        total: 'total'
      }
    };

    return schema;
  }

  initGeneralOptions () {
    return {
      serverSorting: true,
      serverPaging: true,
      serverFiltering: true,
      sort: this.grid.gridSort(),
      filter: this.grid.gridFilter(),
      page: 1,
      pageSize: 20
    };
  }

  setFilter (filter) {
    this.dataSource.filter = filter;
  }
}
