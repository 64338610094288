import { ActionTree } from "vuex";
import { FilmProductionState } from "./state";
import { RootState } from "../../store/types";
import { Mutations } from './mutations';
import axios from 'axios';
import { ProductionTemplate, ProductionTemplateSettings } from "../interfaces";
import { Actions as ScreenplayActions } from '../../screenplay/store/actions';

export const Actions = {
  SET_PROGRESS_DATA: 'filmProduction/progressData',
  SET_API_URLS: 'filmProduction/setApiUrls',
  ABORT_PRODUCTION: 'filmProduction/abortProduction',
  SET_TEMPLATE_DATA: 'filmProduction/setTemplateData',
  SET_TEMPLATE: 'filmProduction/setTemplate',
  SET_TEMPLATE_SILENT: 'filmProduction/setTemplateSilent',
  REMOVE_TEMPLATE: 'filmProduction/removeTemplate',
  REMOVE_TEMPLATE_SILENT: 'filmProduction/removeTemplateSilent',
  SET_ERRORS: 'filmProduction/setErrors',
  RESET_TEMPLATE: 'filmProduction/resetTemplate',
  SET_ABS_DATA: 'filmProduction/absData'
}

export const actions: ActionTree<FilmProductionState, RootState> = {
  progressData({ commit }, data: {}) {
    commit(Mutations.SET_PROGRESS_DATA, data);
  },

  absData({ commit }, data: {}) {
    commit(Mutations.SET_ABS_DATA, data);
  },

  setApiUrls({ commit }, urls) {
    commit(Mutations.SET_API_URLS, urls);
  },

  setTemplateData({ commit }, templateData: ProductionTemplateSettings) {
    commit(Mutations.SET_TEMPLATE_STATE, templateData);
  },

  abortProduction({ state, commit, dispatch }) {
    return new Promise((resolve, reject) => {
      axios.patch(state.apiUrls.abortProduction)
      .then(() => {
        commit(Mutations.SET_JOB_STATE, 'aborted');
        dispatch(ScreenplayActions.SET_NEEDS_PRODUCTION, true, { root: true });
        resolve(true);
      })
      .catch(error => {
        console.error(error);
        reject();
      })
    });
  },

  async setTemplate({ state, commit, dispatch }, template: ProductionTemplate) {
    return axios.post(state.template.urls.film_template, {
      template_id: template.id
    })
    .then(response => {
      commit(Mutations.SET_TEMPLATE, template);
      dispatch(ScreenplayActions.SET_NEEDS_PRODUCTION, true, { root: true });
      VERSTEHE.FilmProduction.app.$emit('SET_TEMPLATE', template);
    })
    .catch(error => {
      console.error(error);
    });
  },

  setTemplateSilent({ commit, dispatch }, template: ProductionTemplate) {
    commit(Mutations.SET_TEMPLATE, template);
    dispatch(ScreenplayActions.SET_NEEDS_PRODUCTION, true, { root: true });
  },

  removeTemplateSilent({ commit, dispatch }) {
    commit(Mutations.REMOVE_TEMPLATE);
    dispatch(ScreenplayActions.SET_NEEDS_PRODUCTION, true, { root: true });
  },

  async removeTemplate({ state, commit, dispatch }) {
    return axios.delete(state.template.urls.film_template)
      .then(response => {
        commit(Mutations.REMOVE_TEMPLATE);
        VERSTEHE.FilmProduction.app.$emit('REMOVE_TEMPLATE');
        dispatch(ScreenplayActions.SET_NEEDS_PRODUCTION, true, { root: true });
      })
      .catch(error => console.error(error));
  },

  setErrors({ state, commit, dispatch }, errors) {
    commit(Mutations.SET_ERRORS, errors);
  },

  resetTemplate({ state, commit, dispatch }) {
    axios.post(state.template.urls.reset_film_template)
      .then(response => {
        commit(Mutations.SET_TEMPLATE, response.data);
        VERSTEHE.FilmProduction.app.$emit('SET_TEMPLATE', response.data);
        dispatch(ScreenplayActions.SET_NEEDS_PRODUCTION, true, { root: true });
      })
      .catch(error => {
        console.error(error);
      });
  }
}
