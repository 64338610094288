import { BaseGrid } from '../grid/base_grid';
import { DataSource } from './data_source';
import { Filter } from './filter/filter';
import { BulkActions } from './configurations/bulk_actions';
import { columnConfiguration } from './configurations/column_configurations';
import { ColumnSelect } from '../grid/base_column_select';
import { t, tt } from './configurations/localization';

const GRID_COOKIE_KEY = `user_grid_options_${_app.info.siteId}_${_app.info.user.userId}`;

export class UserGrid extends BaseGrid {
  constructor () {
    super({ skipInit: true });

    if (this.gridElement) {
      this.isPrivateSite = this.gridElement.getAttribute('data-private-site') === 'true';
      this.dataSource = new DataSource ({ endpointUrl: this.endpointUrl, grid: this });
      this.bulkActions = new BulkActions(this);
      this.metaDataFieldUrl = this.gridElement.getAttribute('data-meta-data-endpoint');
      this.addMetaDataColumns();
      this.initGrid();
      this.columnSelect = new ColumnSelect(this);
      this.bindListeners();
      this.filter = new Filter(this);

      const userLimit = Number(this.gridElement.getAttribute('data-user-limit'));
      if (userLimit > 0) {
        this.addUserLimitNotice(userLimit);
      }
    }
  }

   addUserLimitNotice(userLimit) {
    const notice = tt('user_limit', { limit: userLimit });
    const noticeElement = document.createElement('span');
    noticeElement.innerHTML = notice;
    noticeElement.classList.add('k-user-limit-notice');
    const pagerElement = this.gridElement.querySelector('.k-pager-wrap');
    pagerElement.classList.add('has-user-limit');
    pagerElement.appendChild(noticeElement);
  }

  /**
   * Adds custom meta data fields dynamiclly to the column configuration
   * the custom meta data fields are passed as a json string on the grid html container
   */
  addMetaDataColumns () {
    let columns = JSON.parse(this.gridElement.getAttribute('data-dynamic-fields'));
    columns.forEach((field) => {
      columnConfiguration.push({
      field: `${field.field_key}`,
      title: field.title,
      type: 'tree',
      treeType: field.type,
      filterable: true,
      attributes: {
        'class': 'grid-metadata'
      },
      hidden: true
      });
    });
  }

  gridColumns () {
    let visibleColumns = undefined;
    let columns =  { columns: columnConfiguration.map(column => {
      if (visibleColumns && column.hideable) {
        column.hidden = visibleColumns.indexOf(column.field) === -1;
      }

      return column;
      })
    };

    return columns;
  }

  gridOptions () {
    return {
      scrollable: {
        endless: true
      },
      pageable: {
        numeric: false,
        previousNext: false
      },
      filterable: {
        extra: false
      },
      sortable: {
        mode: 'single',
        allowUnsort: false
      },
      persistSelection: true
    };
  }

  gridCookieKey () {
    return GRID_COOKIE_KEY;
  }

  gridSort () {
    let sort = this.getGridOptions().sort;

    return sort ? sort : {
      field: 'any_name',
      dir: 'desc'
    };
  }

  gridFilter () {
    let filters = this.getGridOptions().filter;
    let gridFilter = [];

    if (filters) {
      gridFilter = filters.reduce((filters, filter) => {
        return filters.concat(filter.filters);
      }, []);
    }

    // Remove has_access filter if present
    const index = gridFilter.findIndex(filter => filter.field === 'has_access');
    if (index > -1) {
      gridFilter.splice(index, 1);
    }

    if (this.isPrivateSite) {
      // For private sites display only users with access by default
      gridFilter.push({
        field: 'has_access',
        operator: 'contains',
        value: 'true',
        readOnly: true
      });
    }

    return gridFilter;
  }

  onGridSort (e) {
    this.setGridOptions({ sort: e.sort });
  }

  onGridFilter(e) {
    this.filter.filterChange(e);
  }

  onGridDataBound(e) {
    this.filter.updateFilters(this.dataSource.dataSource.filter());
    $('[data-toggle="tooltip"]').tooltip();
  }

  onGridChange (e) {
    this.selected = this.grid.selectedKeyNames();
    this.bulkActions.updateSelection(this.selected);
    this.checkDeletedFilter();
  }

  /**
   * checks if is_deleted filter is active
   * if true hide delete bulk button
   */
  checkDeletedFilter(){
    this.deleteAction = this.gridElement.querySelector('.bulk-delete');
    if (this.getGridOptions().filter.some(f => f.field === 'is_deleted')){
      this.deleteAction.setAttribute('style', 'display:none;');
    }else{
      this.deleteAction.setAttribute('style', '');
    }
  }

  /**
   * Removes the grid row
   * @param {*} id the kendo ui grid row id
   */
  removeFromList (id) {
    let row = this.getTableRow(id);
    this.grid.options.editable = { mode: 'incell', confirmation: false };
    this.grid.removeRow(row);
    this.grid.options.editable = { mode: 'inline', confirmation: true };
  }

  bindListeners() {
    $(this.gridElement).on('ajax:before', '.grid-action-confirm', (e) => {
      e.currentTarget.classList.add('disabled');
    });

    $(this.gridElement).on('ajax:success', '.grid-action-confirm', (e) => {
      const row = e.currentTarget.parentNode.parentNode;
      const parent = row.querySelector('.grid-title > a');
      const icon = parent.querySelector('.unconfirmed-icon');
      parent.removeChild(icon);
    });

    $(this.gridElement).on('ajax:error', '.grid-action-confirm', (e, xhr) => {
      e.currentTarget.classList.remove('disabled');
      $.snackbar({ content: t('could_not_confirm'), style: 'notice', duration: 4000 });
      console.log(xhr);
    });

    $(this.gridElement).on('ajax:success', '.grid-action-grant-access', (_e, data) => {
      this.updateRow(data.id, data);
      VERSTEHE.UserGrid.grid.grid.refresh();
    });

    $(this.gridElement).on('ajax:success', '.grid-action-revoke-access', (_e, data) => {
      this.updateRow(data.id, data);
      VERSTEHE.UserGrid.grid.grid.refresh();
    });

    $(this.gridElement).on('click', '.grid-action-delete', (e) => {
      if (!confirm(t('delete_confirm'))) {
        e.preventDefault();
        e.stopPropagation();
      }
    });
  }

}
