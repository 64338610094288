export class BaseBulkActions {
  constructor(grid) {
    this.grid = grid;

    this.wrapper = grid.gridElement.querySelector('.grid-bulk-actions');
    this.countLabel = grid.gridElement.querySelector('.bulk-action-item-count');
    this.actionsMessage = grid.gridElement.querySelector('.bulk-actions-message');
    this.selectAll = this.wrapper.querySelector('.bulk-actions-select-all');
    this.closeAction = this.wrapper.querySelector('.bulk-actions-close');
    this.cancelAction = this.wrapper.querySelector('.bulk-actions-cancel');
    this.saveAction = this.wrapper.querySelector('.bulk-actions-save');
    this.selectedItems = [];

    this.bindListeners();
  }

  selected () {
    return this.selectedItems;
  }

  updateSelection (items) {
    this.selectedItems = items;
    this.countLabel.innerText = items.length;

    if (items.length > 0) {
      this.wrapper.classList.add('opened');
    } else {
      this.closeEdit();
      this.wrapper.classList.remove('opened');
    }

    let totalItems = this.grid.dataSource.dataSource.total();
    
    // Hide select all action when all items are selected
    if (items.length !== totalItems) {
      let totalItemsLabel = this.selectAll.querySelector('.bulk-action-total-items');
      totalItemsLabel.innerHTML = totalItems;
      this.selectAll.style.display = 'block';
    } else {
      this.selectAll.style.display = 'none';
    }
  }

  openEdit (attribute) {
    this.activeAttribute = attribute;
    this.editorWrappers[attribute].classList.add('opened');
    this.wrapper.classList.add('editing');
  }

  closeEdit () {
    let editors = Object.values(this.editorWrappers);

    for (let i = 0; i < editors.length; i++) {
      editors[i].classList.remove('opened');
    }

    this.activeAttribute = null;
    this.wrapper.classList.remove('editing');
  }

  showBulkMessage (message) {
    clearTimeout(this._actionsMessageTimeout);
    this.actionsMessage.classList.add('fade-in');
    this.actionsMessage.innerHTML = message;

    this._actionsMessageTimeout = setTimeout(() => {
      this.actionsMessage.classList.remove('fade-in');
    }, 8000);
  }

  /**
   * Resets the bulk action Ui
   * @param {string} action Contains the executed action name 
   */
  bulkChange (action) {
    this.saveAction.classList.add('disabled');

    // Map requested action to function
    action = this.actions[action];

    if (action) {
      this[action]()
        .then(() => {
          this.closeEdit();
          this.saveAction.classList.remove('disabled');
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  bindListeners () {
    this.closeAction.addEventListener('click', e => {
      e.preventDefault();
      this.wrapper.classList.remove('opened');

      // Deselect all
      this.grid.grid._selectedIds = {};
      this.grid.grid.clearSelection();
    });

    this.cancelAction.addEventListener('click', e => {
      e.preventDefault();
      this.closeEdit();
    });

    this.wrapper.querySelectorAll('.bulk-edit').forEach(action => {
      action.addEventListener('click', e => {
        e.preventDefault();

        let attribute = action.getAttribute('data-attribute');
        this.openEdit(attribute);
      });
    });

    this.saveAction.addEventListener('click', e => {
      this.bulkChange(this.activeAttribute);
    });

    this.selectAll.addEventListener('click', e => {
      this.grid.selectAll();
      this.selectAll.style.display = 'none';
    });
  }
}
