export const locals = {
  de: {
    user_registration_title: 'Neuen Benutzer anlegen',
    save_button: 'Benutzer erstellen',
    cancel_button: 'Abbrechen',
    load_form_error: '<h3>Beim laden des Formulars ist ein Fehler aufgetreten.</h3>',
    server_error: 'Beim anlegen des Benutzers ist ein Fehler aufgetreten'
  },
  en: {
    user_registration_title: 'Create new user',
    save_button: 'Create user',
    cancel_button: 'Cancel',
    load_form_error: '<h3>An error occurred while loading the form.</h3>',
    server_error: 'An error occurred when creating the user'
  },
  es: {
    user_registration_title: 'Crear nuevo usuario',
    save_button: 'Crear usuario',
    cancel_button: 'Cancelar',
    load_form_error: '<h3>Se ha producido un error al cargar el formulario.</h3>',
    server_error: 'Se ha producido un error al crear el usuario'
  }
};

export function t(key) {
  return locals[_app.info.currentLocale][key] || key;
}

export function tt(key, variables = {}) {
  let template = kendo.template(t(key));
  return template(variables);
}
