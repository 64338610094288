import axios from "axios";

export const Actions = {
  SET_ONBOARDING_TYPE: 'userOnboarding/setOnboardingType',
  SET_ONBOARDING_HIDE_URL: 'userOnboarding/setOnboardingHideUrl'
};

export default {
  namespaced: true,
  state: {
    onboardingType: null,
    hideOnboarding: false,
    hideUrl: null
  },
  getters: {
    onboardingType(state) {
      return state.onboardingType;
    },
    hideOnboarding(state) {
      return state.hideOnboarding;
    }
  },
  actions: {
    setOnboardingType({ commit }, onboardingType) {
      commit('SET_ONBOARDING_TYPE', onboardingType);
    },
    setHideOnboarding({ commit, state }, shouldHide) {
      commit('SET_HIDE_ONBOARDING', shouldHide);

      axios.post(state.hideUrl, { hide: shouldHide })
        .catch(error => {
          console.error(error);
          // Could not save
          commit('SET_HIDE_ONBOARDING', !shouldHide);
        });
    },
    setOnboardingHideUrl({ commit }, url) {
      commit('SET_ONBOARDING_HIDE_URL', url);
    }
  },
  mutations: {
    SET_ONBOARDING_TYPE(state, onboardingType) {
      state.onboardingType = onboardingType;
    },
    SET_HIDE_ONBOARDING(state, shouldHide) {
      state.hideOnboarding = shouldHide;
    },
    SET_ONBOARDING_HIDE_URL(state, url) {
      state.hideUrl = url;
    }
  }
};
