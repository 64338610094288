import BbbImportApp from './bbb_import_app.vue';
import Vue from 'vue';

export class BbbImport {

  readonly containerId: string;
  readonly parseUrl: string;
  readonly performUrl: string;
  readonly vueApp: Vue;

  constructor(containerId: string, onImportCallback: Function) {
    this.containerId = containerId;
    const container = <HTMLDivElement> document.querySelector(containerId);
    this.parseUrl = container.dataset.parseUrl;
    this.performUrl = container.dataset.performUrl;
    this.vueApp = this.initVueApp();
    this.vueApp.$root.$on('import-bbb', onImportCallback);
  }

  private initVueApp() : Vue {
    return new Vue({
      el: this.containerId,
      provide: {parseUrl: this.parseUrl, performUrl: this.performUrl},
      i18n: VERSTEHE.vueI18n,
      render: h => h(BbbImportApp)
    });
  }
}
