export { Slide, Take, Image } from '../media_library/interfaces';

export interface ApiUrls {
  getWorkspace: string;
  getWorkspaceMedium: string;
  addWorkspaceMedia: string;
  removeWorkspaceMedium: string;
  getScreenplay: string;
  updateScreenplay: string;
  produceFilmUrl: string;
  sceneSettings: string;
  takeUrl: string;
}

export interface Section {
  id: number | string;
  title: string;
  position: number;
  created_at: string;
  updated_at: string;
  scenes: Array<Scene>;
  deleted?: boolean;
  is_new?: boolean;
  transition?: Transition;
}

export interface Scene {
  id: number | string;
  medium_id: number;
  medium_type: string;
  title: string;
  duration: number;
  position: number;
  created_at: string;
  updated_at: string;
  thumbnail_url: string;
  url?: string;
  tts_enable: boolean;
  tts_speaker: string;
  tts_text: string;
  deleted?: boolean;
  is_new?: boolean;
}

export enum ScreenplayStatus {
  UNSAVED = 'UNSAVED',
  SAVING = 'SAVING',
  SAVED = 'SAVED',
  FAILED = 'FAILED'
}

export enum TranscriptionLanguage {
  DE = 'de',
  EN = 'en',
  ES = 'es'
}

export enum TransitionType {
  NONE = "none",
  FADE = "fade",
  FADEBLACK = "fadeblack",
  FADEWHITE = "fadewhite",
  WIPELEFT = "wipeleft",
  WIPERIGHT = "wiperight",
  WIPEUP = "wipeup",
  WIPEDOWN = "wipedown",
  SLIDELEFT = "slideleft",
  SLIDERIGHT = "slideright",
  SLIDEUP = "slideup",
  SLIDEDOWN = "slidedown",
  SMOOTHLEFT = "smoothleft",
  SMOOTHRIGHT = "smoothright",
  SMOOTHUP = "smoothup",
  SMOOTHDOWN = "smoothdown",
  CIRCLECROP = "circlecrop",
  RECTCROP = "rectcrop",
  CIRCLECLOSE = "circleclose",
  CIRCLEOPEN = "circleopen",
  HORZCLOSE = "horzclose",
  HORZOPEN = "horzopen",
  VERTCLOSE = "vertclose",
  VERTOPEN = "vertopen",
  DIAGBL = "diagbl",
  DIAGBR = "diagbr",
  DIAGTL = "diagtl",
  DIAGTR = "diagtr",
  HLSLICE = "hlslice",
  HRSLICE = "hrslice",
  VUSLICE = "vuslice",
  VDSLICE = "vdslice",
  DISSOLVE = "dissolve",
  PIXELIZE = "pixelize",
  RADIAL = "radial"
}

export interface Transition {
  name: TransitionType;
  duration: number;
}
