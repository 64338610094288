<template>
  <div>
    <h3>{{ $t('getting_started.task_create_course_plan') }}</h3>
    <p>{{ $t('getting_started.task_create_course_plan_detail') }}</p>
    <a :href="cooviLink('manage/course_plans/new')" target="_blank" class="btn btn-primary btn-flat">
      {{ $t('getting_started.create_new_course_plan') }}
    </a>
    <div class="getting-started-video-wrapper">
      <video-player
        :sources="video.source"
        :screenplay="video.screenplay">
      </video-player>
    </div>
  </div>
</template>

<script>
  import helpers from '../../helpers';
  import { mapGetters } from 'vuex';

  import VideoPlayer from '../../../globals/vue/video_player.vue';

  export default {
    mixins: [helpers],
    components: {
      VideoPlayer
    },
    computed: {
      ...mapGetters({
        videoById: 'tutorials/videoById',
        videos: 'tutorials/videos'
      }),
      video() {
        return this.videoById('getting-started-create-courseplan', true);
      }
    }
  }
</script>
