import { ProductionJobProgress, ProductionTemplateSettings, Task } from '../interfaces';

export interface FilmProductionState {
  progress: ProductionJobProgress,
  absData: {
    enabled: boolean,
    progress?: Task
  },
  apiUrls: {
    abortProduction: string
  },
  template: ProductionTemplateSettings
  errors: any;
}

export const state: FilmProductionState = {
  progress: {
    id: null,
    medium_id: null,
    state: null,
    finished: false,
    merged: {
      exists: false,
      source_url: null
    },
    merge_tooltip: null,
    merge_duration: null,
    merge_eta_state: {
      work_type: null,
      work_status: null,
      eta: null,
      current_step: null,
      conversion_tuple: null
    },
    thumbnail_state: null,
    thumbnail_state_tooltip: null,
    thumbnail_duration: null,
    preview_thumbnail_state: null,
    preview_thumbnail_tooltip: null,
    preview_thumbnail_duration: null,
    transcribe_state: null,
    transcribe_tooltip: null,
    transcribe_duration: null,
    converting_jobs: []
  },
  absData: {
    enabled: false,
  },
  apiUrls: null,
  template: null,
  errors: {}
}
