import BaseWidget from './widgets/base_widget';
import SliderWidget from './widgets/slider_widget';
import HeroSliderWidget from './widgets/hero_slider_widget';
import ContributionListWidget from './widgets/contribution_list_widget';
import ContributionGridWidget from './widgets/contribution_grid_widget';
import TextBlockWidget from './widgets/text_block_widget';
import WordCloudWidget from './widgets/word_cloud_widget';
import UserTaskListWidget from './widgets/user_task_list_widget';
import UserHistoryWidget from './widgets/user_history_widget';
import SubscriptionFeedWidget from './widgets/subscription_feed_widget';

const widgets = {
  HeroSliderWidget,
  SliderWidget,
  ContributionListWidget,
  ContributionGridWidget,
  UserTaskListWidget,
  UserHistoryWidget,
  TextBlockWidget,
  WordCloudWidget,
  SubscriptionFeedWidget
};

class Widget {
  constructor(widgetName, opts = {}) {
    return new widgets[`${widgetName}Widget`](opts);
  }
}

module.exports = Object.assign({}, widgets, { Widget });
