import ChartWidget from './chart_widget';
import { KrTool } from '../global/tools';

export default class StockWidget extends ChartWidget {
  constructor(options) {
    super(options);

    this.type = 'stock';
  }

  static get defaultParams() {
    return {
      title: '',
      show_title: true
    };
  }

  initialize() {
    super.initialize();
    this.renderChart(this.element.querySelector('.report-widget-content'));
  }

  /**
   * Render the chart
   */
  renderChart(element) {
    let targetUrl = element.getAttribute('data-target');
    let reportID = $(element).parents('.grid-stack-item').data('report');
    let reportType = $(element).parents('.grid-stack-item').data('type');
    let chartID = element.getAttribute('data-chart');
    let chartContainer = element.querySelector('.chart-container');
    let interval = KrTool.setTillAndFrom();


    // Add spinner
    $(chartContainer).append(this.busy);

    $.get(targetUrl, { id: chartID, report_id: reportID, report_type: reportType, till: interval['till'], from: interval['from'] }, (response) => {
      this.busy.remove();
      let data = this.setExportingChartParams(response.resultset);
      Highcharts.setOptions(this.setLangChartParams(undefined));
      this.chart = Highcharts.stockChart(chartContainer, data);
    });
  }

}
