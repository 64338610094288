<template>
  <div class="saml-integration">
    <div class="saml-settings">
      <div class="form-group label-static">
        <label for="saml_issuer" class="control-label">{{ $t('integrations.identity_saml_issuer') }}</label>
        <input v-model="saml_issuer" type="text" placeholder="coovi" class="form-control" id="saml_issuer">
      </div>

      <div class="form-group label-static">
        <label for="saml_idp_sso_target_url" class="control-label">{{ $t('integrations.identity_saml_idp_sso_target_url') }}</label>
        <input v-model="saml_idp_sso_target_url" type="text" class="form-control" id="saml_idp_sso_target_url">
      </div>

      <div class="form-group label-static">
        <label for="saml_idp_cert" class="control-label">{{ $t('integrations.identity_saml_idp_cert') }}</label>
        <textarea v-model="saml_idp_cert" rows="5" class="form-control" id="saml_idp_cert"></textarea>
        <p class="help-block">{{ $t('integrations.identity_saml_idp_cert_help') }}</p>
      </div>

      <div class="form-group">
        <div class="checkbox">
          <label>
            <input
              type="checkbox"
              v-model="saml_manual_sign_in">
              {{ $t('integrations.identity_saml_attribute_manual_sign_in') }}
          </label>
        </div>
        <p class="help-block">
          {{ $t('integrations.identity_saml_attribute_manual_sign_in_help') }}
        </p>
      </div>

      <div v-show="more">
        <div class="form-group label-static">
          <label for="saml_name_identifier_format" class="control-label">{{ $t('integrations.identity_saml_name_identifier_format') }}</label>
          <input v-model="saml_name_identifier_format" placeholder="urn:oasis:names:tc:SAML:1.1:nameid-format:emailAddress" type="text" class="form-control" id="saml_name_identifier_format">
          <p class="help-block">{{ $t('integrations.identity_saml_name_identifier_format_help') }}</p>
        </div>

        <h3>{{ $t('integrations.identity_saml_attribute_mappings') }}</h3>

        <div class="form-group row">
          <label for="saml_attribute_email" class="col-md-4 control-label">{{ $t('integrations.identity_saml_attribute_email') }}</label>
          <div class="col-md-8">
            <input v-model="saml_attribute_email" type="text" class="form-control" id="saml_attribute_email">
            <p class="help-block">{{ $t('integrations.identity_saml_attribute_email_help') }}</p>
          </div>
        </div>

        <div class="form-group row">
          <label for="saml_attribute_name" class="col-md-4 control-label">{{ $t('integrations.identity_saml_attribute_name') }}</label>
          <div class="col-md-8">
            <input v-model="saml_attribute_name" type="text" class="form-control" id="saml_attribute_name">
            <p class="help-block">{{ $t('integrations.identity_saml_attribute_name_help') }}</p>
          </div>
        </div>

        <div class="form-group row">
          <label for="saml_attribute_firstname" class="col-md-4 control-label">{{ $t('integrations.identity_saml_attribute_firstname') }}</label>
          <div class="col-md-8">
            <input v-model="saml_attribute_firstname" type="text" class="form-control" id="saml_attribute_firstname">
            <p class="help-block">{{ $t('integrations.identity_saml_attribute_firstname_help') }}</p>
          </div>
        </div>

        <div class="form-group row">
          <label for="saml_attribute_lastname" class="col-md-4 control-label">{{ $t('integrations.identity_saml_attribute_lastname') }}</label>
          <div class="col-md-8">
            <input v-model="saml_attribute_lastname" type="text" class="form-control" id="saml_attribute_lastname">
            <p class="help-block">{{ $t('integrations.identity_saml_attribute_lastname_help') }}</p>
          </div>
        </div>


        <div class="form-group">
        <div class="checkbox">
          <label>
            <input
              type="checkbox"
              v-model="saml_user_role_synchronization">
              {{ $t('integrations.identity_saml_user_role_synchronization') }}
          </label>
        </div>

        <div class="checkbox">
          <label>
            <input
              type="checkbox"
              :disabled="!saml_user_role_synchronization"
              v-model="saml_user_role_synchronization_demote_check">
              {{ $t('integrations.identity_saml_user_role_synchronization_demote') }}
          </label>
        </div>
      </div>

      <div class="form-group label-static">
          <label for="saml_user_role_synchronization_claim_name" class="control-label">{{ $t('integrations.identity_saml_user_role_synchronization_claim_name') }}</label>
          <input :disabled="!saml_user_role_synchronization" v-model="saml_user_role_synchronization_claim_name" type="text" class="form-control" id="saml_user_role_synchronization_claim_name">
          <p class="help-block">{{ $t('integrations.identity_saml_user_role_synchronization_claim_name_help') }}</p>
        </div>

        <div class="saml-user-role-rules" v-if="saml_user_role_synchronization">
          <h3>
            {{ $t('integrations.identity_saml_user_role_rules') }}
            <button class="btn btn-fab btn-fab-mini btn-primary" type="button" @click="addUserRoleRule()"><i class="material-icons">add</i></button>
          </h3>
          <div class="saml-user-role-rule" v-for="rule in samlUserRoleRules" :key="rule.id">

            <ValidationProvider rules="required" name="claim" class="form-group" v-slot="{ errors }">
              <input v-model="rule.match" class="form-control" :placeholder="$t('integrations.identity_saml_user_role_rules_match')" type="text" />
              <span v-if="errors.length > 0" class="error-info">{{ errors[0] }}</span>
            </ValidationProvider>

            <i class="material-icons">chevron_right</i>
            <div class="form-group">
              <select class="form-control" :value="rule.role" @change="updateUserRoleRule(rule, 'role', $event.target.value)">
                <option value="explorer">{{ $t('integrations.identity_saml_user_role_rules_explorer') }}</option>
                <option value="supporter">{{ $t('integrations.identity_saml_user_role_rules_supporter') }}</option>
                <option value="author">{{ $t('integrations.identity_saml_user_role_rules_author') }}</option>
                <option value="trainer">{{ $t('integrations.identity_saml_user_role_rules_trainer') }}</option>
                <option value="reviewer">{{ $t('integrations.identity_saml_user_role_rules_reviewer') }}</option>
                <option value="editor">{{ $t('integrations.identity_saml_user_role_rules_editor') }}</option>
                <option value="master_editor">{{ $t('integrations.identity_saml_user_role_rules_master_editor') }}</option>
                <option value="publisher">{{ $t('integrations.identity_saml_user_role_rules_publisher') }}</option>
              </select>
            </div>
            <i class="material-icons delete-rule" @click="removeUserRoleRule(rule)">delete</i>
          </div>
        </div>
      </div>
      <button v-show="!more" @click="more = true" class="btn btn-default btn-flat btn-xxs">{{ $t('integrations.show_more') }}</button>
      <slot/>
    </div>

    <div class="saml-help">
      <h3>{{ $t('integrations.identity_saml_help_howto_configure') }}</h3>
      <ol>
        <li>
          <p v-html="$t('integrations.identity_saml_help_step1')"></p>
        </li>
        <li>
          <p v-html="$t('integrations.identity_saml_help_step2_issuer')"></p>
          <pre>{{ saml_issuer || 'coovi' }}</pre>
          <p v-html="$t('integrations.identity_saml_help_step2_sp_entity_id')"></p>
          <pre>coovi</pre>
          <p v-html="$t('integrations.identity_saml_help_step2_acs_url')"></p>
          <pre>{{ acsUrl }}</pre>
          <p v-html="$t('integrations.identity_saml_help_step2_metadata', { url: metadataDownloadUrl })"></p>
        </li>
        <li>
          <p v-html="$t('integrations.identity_saml_help_step3')"></p>
        </li>
        <li>
          <p v-html="$t('integrations.identity_saml_help_step4')"></p>
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex';
  import { v4 as uuid } from 'uuid';
  import Vue from 'vue';
  const { mapGetters, mapActions } = createNamespacedHelpers('integrations');

  export default {
    inject: ['$validator'],
    data() {
      return {
        saml_issuer: null,
        saml_idp_sso_target_url: null,
        saml_idp_cert: null,
        saml_name_identifier_format: null,
        saml_attribute_email: null,
        saml_attribute_name: null,
        saml_attribute_firstname: null,
        saml_attribute_lastname: null,
        saml_user_role_synchronization: false,
        saml_user_role_synchronization_demote: false,
        saml_user_role_synchronization_claim_name: null,
        saml_user_role_rules: [],
        saml_manual_sign_in: false,
        more: false
      }
    },
    computed: {
      ...mapGetters([
        'integrationById',
        'baseUrl'
      ]),
      integration() {
        return this.integrationById('identity_saml');
      },
      settings() {
        return {
          saml_issuer: this.saml_issuer,
          saml_idp_sso_target_url: this.saml_idp_sso_target_url,
          saml_idp_cert: this.saml_idp_cert,
          saml_name_identifier_format: this.saml_name_identifier_format,
          saml_attribute_email: this.saml_attribute_email,
          saml_attribute_name: this.saml_attribute_name,
          saml_attribute_firstname: this.saml_attribute_firstname,
          saml_attribute_lastname: this.saml_attribute_lastname,
          saml_user_role_synchronization: this.saml_user_role_synchronization,
          saml_user_role_synchronization_claim_name: this.saml_user_role_synchronization_claim_name,
          saml_user_role_synchronization_demote: this.saml_user_role_synchronization_demote,
          saml_manual_sign_in: this.saml_manual_sign_in,
          saml_user_role_rules_attributes: this.saml_user_role_rules.map(rule => {
            let newRule = { ...rule };

            if (typeof rule.id === 'string') {
              delete newRule.id;
            }

            return newRule;
          })

        }
      },
      saml_user_role_synchronization_demote_check: {
        set(value) {
          this.saml_user_role_synchronization_demote = value;
        },
        get() {
          return this.saml_user_role_synchronization && this.saml_user_role_synchronization_demote;
        }
      },
      samlUserRoleRules() {
        return this.saml_user_role_rules.filter(r => r['_destroy'] !== '1');
      },
      acsUrl() {
        return `${_app.info.rootUrl}users/auth/saml/callback`;
      },
      metadataDownloadUrl() {
        return `${this.baseUrl}identity_saml/saml_descriptor.xml`;
      }
    },
    methods: {
      load() {
        const settings = this.integration.settings;
        this.saml_issuer = settings.saml_issuer;
        this.saml_idp_sso_target_url = settings.saml_idp_sso_target_url;
        this.saml_idp_cert = settings.saml_idp_cert;
        this.saml_name_identifier_format = settings.saml_name_identifier_format;
        this.saml_attribute_email = settings.saml_attribute_email;
        this.saml_attribute_name = settings.saml_attribute_name;
        this.saml_attribute_firstname = settings.saml_attribute_firstname;
        this.saml_attribute_lastname = settings.saml_attribute_lastname;
        this.saml_user_role_synchronization = settings.saml_user_role_synchronization;
        this.saml_user_role_synchronization_demote = settings.saml_user_role_synchronization_demote;
        this.saml_user_role_synchronization_claim_name = settings.saml_user_role_synchronization_claim_name;
        this.saml_user_role_rules = settings.saml_user_role_rules;
        this.saml_manual_sign_in = settings.saml_manual_sign_in;
      },
      addUserRoleRule() {
        this.saml_user_role_rules.push({
          id: uuid(),
          role: 'explorer',
          match: ''
        });
      },
      updateUserRoleRule(userRoleRule, attribute, value) {
        const index = this.saml_user_role_rules.findIndex(r => r.id === userRoleRule.id);
        let updatedElement = { ...this.saml_user_role_rules[index] };
        updatedElement[attribute] = value;
        Vue.set(this.saml_user_role_rules, index, updatedElement);
      },
      removeUserRoleRule(userRoleRule) {
        const index = this.saml_user_role_rules.findIndex(r => r.id === userRoleRule.id);
        if (typeof userRoleRule.id === 'string') {
          // No persistet yet, delete from array
          this.saml_user_role_rules.splice(index, 1);
        } else {
          let updatedElement = { ...this.saml_user_role_rules[index] };
          updatedElement['_destroy'] = '1';
          Vue.set(this.saml_user_role_rules, index, updatedElement);
        }
      },
    },
    mounted() {
      this.load();
      $.material.init();
    },
  }
</script>

<style lang="scss" scoped>
  .saml-integration {
    display: flex;
  }

  .saml-settings {
    flex: 1 0;
    align-self: flex-start;
    min-width: 400px;
    max-width: 800px;
    background-color: $color-white;
    border-radius: 3px;
    padding: 30px;
  }

  h3 {
    font-size: 14px;
    font-weight: 500;
  }

  .saml-help {
    font-size: 14px;
    padding: 0 30px;
    max-width: 800px;

    img {
      display: block;
      margin-top: 10px;
      border-radius: 3px;
    }

    ol > li {
      margin-bottom: 24px;
    }

    pre {
      display: inline-block;
    }
  }

  .error-info {
    color:  $color-error-red;
  }

  .saml-user-role-rule {
    display: flex;

    .form-group {
      flex-grow: 1;
      padding: 0 10px;
      width: 100%;
    }

    i {
      font-size: 24px;
      margin: 0 24px;
      line-height: 40px;

      &.delete-rule {
        cursor: pointer;
      }
    }
  }
</style>
