<template>
  <div ref="lessonSubjectPreview" class="lesson-media">
    <div class="no-access" v-if="!subject.hasAccess">
      <i class="material-icons">lock</i>
      <h5>{{ $t('course.no_access') }}</h5>
    </div>
    <video-player
      ref="videoPlayer"
      v-if="subject.hasAccess && subject.mediaType === 'film'"
      :sources="this.subject.sources"
      :options="videoOptions"
      @timeupdate="onTimeupdate"
      @loadedmetadata="onLoadedmetadata"></video-player>
    <slideshow-player fluid
      v-if="subject.hasAccess && subject.mediaType === 'slideshow'"
      :slides="this.subject.sources"></slideshow-player>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import VideoPlayer from '../../../globals/vue/video_player';
import SlideshowPlayer from '../../../globals/vue/slideshow_player';

export default {
  name: 'lesson-subject-preview',
  components: {
    VideoPlayer,
    SlideshowPlayer
  },
  data() {
    return {
      currentTime: null,
      duration: 0,

    }
  },
  computed: {
    ...mapGetters({
      subject: 'course/lessonEditorSubject',
      currentQuestion: 'course/currentQuestion'
    }),
    videoOptions() {
      if (this.subject.mediaType === 'film') {
        return {
          plugins: {
            sceneMarkerPlugin: {
              scenes: this.subject.scenes
            }
          }
        };
      } else {
        return {};
      }
    }
  },
  updated() {
    this.initialize();
  },
  mounted() {
    this.initialize();
  },
  beforeDestroy() {
    this.setMediaData(null);
  },
  methods: {
    ...mapActions({
      setMediaData: 'course/setMediaData'
    }),
    initialize() {
      if (this.subject.hasAccess && !this.subject.mediaType) {
        // Playlists
        this.$refs.lessonSubjectPreview.innerHTML = this.subject.previewHtml;
      }
    },
    onLoadedmetadata(player) {
      this.duration = player.duration();

      this.setMediaData({
        currentTime: 0,
        duration: this.duration
      })
    },
    onTimeupdate(player, currentTime) {
      this.currentTime = currentTime;

      this.setMediaData({
        currentTime: this.currentTime,
        duration: this.duration
      });
    }
  },
  watch: {
    currentQuestion(question) {
      if (question && question.time && this.duration) {
        this.$refs.videoPlayer.play();
        this.$refs.videoPlayer.pause();
        this.$refs.videoPlayer.setTime(question.time);
      }
    }
  }
}
</script>

<style lang="scss">
.lesson-media {
  position: relative;
}

.course-playlist-elements {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    display: flex;
    border-bottom: 1px solid $color-grey-2;
    padding: 10px 0;

    &:last-child {
      border-bottom: 0;
    }
  }

  .playlist-element-title {
    flex: 1 auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .playlist-element-duration {
    flex-shrink: 0;
  }
}

.no-access {
  padding: 48px 0;
  text-align: center;

  i {
    font-size: 24px;
    color: $black-54-opacity;
  }
}
</style>
