<template>
<div class="question-answer">
  <div
    class="answer-option"
    v-for="option in options"
    :class="{ 'was-wrong': isSolutionShown && !option.correct && valueBeforeSolution.includes(option.id), 'is-correct': isSolutionShown && option.correct }"
    :key="option.id">
    <div class="checkbox">
      <label>
        <input type="checkbox" v-model="value" :disabled="isSolutionShown" :value="option.id">
        {{ option.title }}
      </label>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'multiple-select',
  props: {
    options: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      value: [],
      valueBeforeSolution: [],
      isSolutionShown: false
    }
  },
  mounted() {
    $.material.init();
  },
  methods: {
    isCorrect() {
      let result = true;

      this.options.forEach(option => {
        if (option.correct === this.value.indexOf(option.id) < 0) {
          result = false;
        }
      });

      return result;
    },
    showSolution() {
      this.valueBeforeSolution = [ ...this.value ];
      this.value = this.options.filter(option => option.correct).map(option => option.id);
      this.isSolutionShown = true;
    }
  }
}
</script>

<style lang="scss" scoped>
.answer-option {
  background: $translucent-white-light;
  margin: 10px 0;
  border-radius: 3px;
  border: 2px solid transparent;

  &:hover {
    background: $white-15-opacity;
  }

  &.is-correct {
    border-color: var(--primary-color);
  }

  &.was-wrong {
    border-color: $color-error-red;
  }
}

.checkbox {
  margin: 0;

  label {
    display: block;
    padding: 10px 10px 10px 55px;
    font-size: 16px;
    color: $color-white;
  }

  ::v-deep .checkbox-material {
    position: absolute;
    left: 10px;
    top: 12px;
  }

  ::v-deep .check {
    border-color: $white-70-opacity;
  }

  ::v-deep input[type="checkbox"][disabled] + .checkbox-material {
    opacity: .26;
  }
}
</style>
