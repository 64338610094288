export default {
  categories: (state, getters) => {
    return Object.values(state.categories.byId).filter((category) => {
      return getters.videosByCategoryId(category.id).length > 0;
    });
  },

  categoryById: (state, getters) => (categoryId) => {
    if (getters.videosByCategoryId(categoryId).length > 0) {
      return state.categories.byId[categoryId];
    }
  },

  filteredCategories: (state, getters) => {
    if (state.filterCategory === 'all') {
      return getters.categories;
    } else {
      return [ state.categories.byId[state.filterCategory] ];
    }
  },

  isCategoryVisible: (state) => (categoryId) => {
    return state.filterCategory === 'all' || categoryId === state.filterCategory;
  },

  videos: (state) => {
    return Object.values(state.videos.byId);
  },

  currentVideo: (state) => {
    if (state.currentVideo) {
      return state.videos.byId[state.currentVideo];
    }
  },

  videoById: (state) => (videoId, skipRoleCheck = false) => {
    const video = state.videos.byId[videoId];

    if (skipRoleCheck || state.filterRole.includes(video.role)) {
      return video;
    }
  },

  videosByCategoryId: (state) => (categoryId) => {
    const category = state.categories.byId[categoryId];

    if (category) {
      const videoIds = state.categories.byId[categoryId].videos;
      return videoIds.map(videoId => state.videos.byId[videoId]).filter(video => state.filterRole.includes(video.role));
    }

    return [];
  },

  searchResults: (state, getters) => {
    if (state.searchQuery && state.searchQuery.length > 0) {
      return getters.videos.filter((video) => {
        const searchString = `${video.title} ${(video.keywords || []).join(' ')}`;
        return video.category !== 'getting-started' && searchString.toLowerCase().includes(state.searchQuery.toLowerCase());
      });
    } else {
      return [];
    }
  },

  searchQuery: (state) => {
    return state.searchQuery;
  }
};
