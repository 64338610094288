import { MutationTree } from 'vuex';
import { FilmProductionState } from './state';
import Vue from 'vue';
import { ProductionTemplate, ProductionTemplateSettings } from '../interfaces';

export const Mutations = {
  SET_PROGRESS_DATA: 'SET_PROGRESS_DATA',
  SET_API_URLS: 'SET_API_URLS',
  SET_JOB_STATE: 'SET_JOB_STATE',
  SET_TEMPLATE_STATE: 'SET_TEMPLATE_STATE',
  SET_TEMPLATE: 'SET_TEMPLATE',
  REMOVE_TEMPLATE: 'REMOVE_TEMPLATE',
  SET_ERRORS: 'SET_ERRORS',
  SET_ABS_DATA: 'SET_ABS_DATA'
}

export const mutations: MutationTree<FilmProductionState> = {
  [Mutations.SET_PROGRESS_DATA] (state, data) {
    Vue.set(state, 'progress', data);
  },

  [Mutations.SET_ABS_DATA] (state, data) {
    Vue.set(state, 'absData', data);
  },

  [Mutations.SET_API_URLS] (state, urls) {
    Vue.set(state, 'apiUrls', urls);
  },

  [Mutations.SET_JOB_STATE] (state, jobState) {
    state.progress.state = jobState;
  },

  [Mutations.SET_TEMPLATE_STATE] (state, templateState: ProductionTemplateSettings) {
    Vue.set(state, 'template', templateState);
  },

  [Mutations.SET_TEMPLATE] (state, template: ProductionTemplate) {
    Vue.set(state.template, 'current_template', template);
  },

  [Mutations.REMOVE_TEMPLATE] (state) {
    Vue.set(state.template, 'current_template', null);
  },

  [Mutations.SET_ERRORS] (state, errors) {
    Vue.set(state, 'errors', errors)
  }
};
