import { openDB } from 'idb';
import type { IDBPDatabase } from 'idb';
import type ImageStoreDB from './types/image_store_db';
import logger from '../../logger/logger';
import { isFirefoxIncognito } from '../environment';

export default class ImageStore {
  private static DATABASE_NAME = 'CooviCaptureImage';
  private static DATABASE_VERSION = 1;

  private static db: IDBPDatabase<ImageStoreDB>;
  private static isBlocked: boolean = false;
  private static userId: number;

  public static async init(userId: number): Promise<void> {
    this.userId = userId;

    try {
      if (isFirefoxIncognito()) {
        ImageStore.isBlocked = true;
        return;
      }

      ImageStore.db = await openDB<ImageStoreDB>(ImageStore.DATABASE_NAME, ImageStore.DATABASE_VERSION, {
        blocked() {
          // implement notification and/or other actions for blocked state if database goes to version 2 (see backup_store.ts)
        },
        async upgrade(db, oldVersion, newVersion, transaction) {
          if (oldVersion < 1) {
            const imagesStore = db.createObjectStore('images', {
              keyPath: 'id',
            });

            imagesStore.createIndex('byUserId', 'userId');
          }
        },
      });
    } catch (e) {
      logger.error({
        message: 'Failed to init imageStore',
        error: e as Error,
        section: 'imageStore:init',
      });

      ImageStore.isBlocked = true;
    }
  }

  public static async getAll(): Promise<ImageStoreDB['images']['value'][]> {
    try {
      if (ImageStore.isBlocked) {
        return [];
      }

      return ImageStore.db.getAllFromIndex('images', 'byUserId', this.userId);
    } catch (e) {
      logger.error({
        message: 'Failed to load images',
        error: e as Error,
        section: 'imageStore:getAll',
      });

      return [];
    }
  }

  public static async add(id: string, image: Blob, thumbnail: Blob): Promise<void> {
    try {
      if (ImageStore.isBlocked) {
        return;
      }

      await ImageStore.db.add('images', {
        id,
        userId: this.userId,
        image,
        thumbnail,
        timestamp: Date.now(),
      });
    } catch (e) {
      logger.error({
        message: 'Failed to add image',
        error: e as Error,
        section: 'imageStore:add',
        data: {
          id,
          imageSize: image?.size,
          imageType: image?.type,
          thumbnailSize: thumbnail?.size,
          thumbnailType: thumbnail?.type,
        },
      });
    }
  }

  public static async remove(id: string): Promise<void> {
    try {
      if (ImageStore.isBlocked) {
        return;
      }

      await ImageStore.db.delete('images', id);
    } catch (e) {
      logger.error({
        message: 'Failed to remove image',
        error: e as Error,
        section: 'imageStore:remove',
        data: {
          id,
        },
      });
    }
  }
}