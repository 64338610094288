export default class SaveTopicButton {
  private reasonsForDisabled: {
    no_medium: boolean,
    needs_production: boolean,
    document_not_converted: boolean,
    in_review: boolean
  }

  constructor(private buttonElement: HTMLButtonElement) {
    this.reasonsForDisabled = JSON.parse(this.buttonElement.dataset.disabledPublishmentReasons);
    this.updateButtonState();
  }

  public setDisabledCondition(condition: string, value: boolean) {
    this.reasonsForDisabled[condition] = value;
    this.updateButtonState();
  }

  public isDisabled() {
    return Object.values(this.reasonsForDisabled).some(condition => condition === true);
  }

  public updateButtonState() {
    if (this.isDisabled()) {
      this.buttonElement.disabled = true;
    } else {
      this.buttonElement.disabled = false;
    }

    this.buttonElement.title = this.getDisabledMessage();
  }

  private getDisabledMessage() {
    if (this.isDisabled()) {
      let fullMessage = VERSTEHE.vueI18n.t('globals.publishment.disabledMessages.preventedBy');
      let messages = Object.keys(this.reasonsForDisabled)
        .filter(key => this.reasonsForDisabled[key] === true)
        .map(key => VERSTEHE.vueI18n.t('globals.publishment.disabledMessages.' + key));

      fullMessage += '\n \u25cf ';
      fullMessage += messages.join('\n \u25cf ');

      return fullMessage;
    } else {
      return VERSTEHE.vueI18n.t('globals.publishment.publish');
    }
  }
}
