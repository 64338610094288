import { schema } from 'normalizr';

export class FillTheBlankOption {
  uuid: string;
  points: number = 0;
  rightAnswer: string;
  wrongAnswers: TextOption[];
  id: number;

 constructor(option, attributes: FillTheBlankOption = null){
  this.uuid = option.id;
  this.points = Number(option.points) || 0;
  this.rightAnswer = option.rightAnswer || "";
  this.wrongAnswers = option.wrongAnswers || [{text: ""}];
  if (attributes) {
    Object.assign(this, attributes);
  }
 }
}

export class TextOption {
  text: string = "";
}

export const FillTheBlankOptionSchema = new schema.Entity('blankOptions');
