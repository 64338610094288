import { createDecorator } from 'vue-class-component';

/*
* Decorators for Vuex MapGetters and MapActions
* 
* e.g. @MapGetter('namespace/getter') myGetter;
*
*/
export function MapGetter (getter: string) {
  return createDecorator((options, key) => {
    if (!options.computed) {
      options.computed = {};
    }
    
    options.computed[key] = function() {
      return this.$store.getters[getter];
    }
  });
}

export function MapAction(action: string) {
  return createDecorator((options, key) => {
    if (!options.methods) {
      options.methods = {};
    }

    options.methods[key] = function(payload?) {
      return this.$store.dispatch(action, payload);
    };
  });
}

export function MapGetterSetter(params: { getter: string, setter: string }) {
  return createDecorator((options, key) => {
    if (!options.computed) {
      options.computed = {};
    }

    options.computed[key] = {
      get: function() {
        return this.$store.getters[params.getter];
      },

      set: function(payload?) {
        return this.$store.dispatch(params.setter, payload);
      }
    }
  })
}

/*
* Decorator for VeeValidate Extension
* 
* e.g. @ValidateComponent({ name: ..., validator:...}) for:
* 
*   $_veeValidate: {
*     name: ...,
*     validator: ... 
*   },
*/
export function ValidateComponent (props: {} = { validator: 'new' }){
  return createDecorator((options, key) => {
    options['$_veeValidate'] = props;
  });
}

/*
* Decorator for Defining Filters
* 
*   @Filter('optionalName')
*   myFilter(value) {
*      return ...;
*   }
* 
*/

export function Filter(name?: string) {
  return createDecorator((options, key) => {
    if (!options.filters) {
      options.filters = {};
    }

    options.filters[name || key] = options.methods[key];
    delete options.methods[key];
  });
}
