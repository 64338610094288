<template>
<div class="question-option" :class="{ error: errors.has(validateName) }">
  <div class="draghandle">
    <i class="material-icons">drag_indicator</i>
  </div>
  <div class="checkbox">
    <label>
      <input
        type="checkbox"
        :name="name"
        value="1"
        v-model="checkboxValue">
    </label>
  </div>
  <div class="question-option-title">
    <textarea
      rows="1"
      v-model="title"
      maxlength="255"
      ref="textArea"
      data-vv-as="questionOptionTitle"
      v-validate="'required'"
      :data-vv-name="validateName"
      :placeholder="placeholderText"
      @keydown="handleInput"
      @change="$emit('change', $event.target.value)"></textarea>
  </div>
  <div class="question-answer-actions">
    <a :class="{ disabled: disabled }" v-on:click.prevent="$emit('remove', option)" href="#" class="material-icons">delete</a>
  </div>
</div>
</template>

<script>
export default {
  name: 'checkbox-option',
  inject: ['$validator'], // inject parent validator
  props: ['option', 'name', 'correct', 'number', 'disabled'],
  data() {
    return {
      title: this.option.title
    }
  },
  computed: {
    checkboxValue: {
      get() {
        return this.option.correct;
      },
      set(checked) {
        this.option.correct = checked;
      }
    },
    validateName() {
      return `option_title_${this.option.id}`;
    },
    placeholderText() {
      if (this.errors.has(this.validateName)) {
        return this.$t('course.question_option_required');
      } else {
        return this.$t('course.new_question_option_placeholder', { number: this.number });
      }
    }
  },
  methods: {
    handleInput(e) {
      // Prevent line break
      if (e && e.keyCode === 13) {
        e.preventDefault();
        return;
      }

      // Auto-expand textarea
      setTimeout(() => {
        this.$refs.textArea.style.height = 'auto';
        this.$refs.textArea.style.height = `${this.$refs.textArea.scrollHeight}px`;
      });
    }
  },
  mounted() {
    this.handleInput();
    $.material.init();
  }
}
</script>
