export { AllSlidesAction } from "./content_actions/topic_actions/slideshow_actions/all_slides_action";
export { AttachmentAction } from "./content_actions/general_actions/attachment_action";
export { CheckboxAction } from "./content_actions/general_actions/checkbox_action";
export { HighlightAction } from "./content_actions/general_actions/highlight_action";
export { TreeAction } from "./content_actions/general_actions/tree_action";
export { MouseAction } from "./content_actions/general_actions/mouse_action";
export { MouseClickAction } from "./content_actions/general_actions/mouse_click_action";
export { MouseMoveAction } from "./content_actions/general_actions/mouse_move_action";
export { QueueAction } from "./system_actions/queue_action";
export { RadioAction } from "./content_actions/general_actions/radio_action";
export { SaveSlidesAction } from "./content_actions/topic_actions/slideshow_actions/save_slides_action";
export { SelectizeAction } from "./content_actions/general_actions/selectize_action";
export { MultiSelectAction } from "./content_actions/general_actions/multiselect_action";
export { DatePickerAction } from "./content_actions/general_actions/datepicker_action";
export { SlideAction } from "./content_actions/topic_actions/slideshow_actions/slide_action";
export { StatusAction } from "./system_actions/status_action";
export { TextAction } from "./content_actions/general_actions/text_action";
export { TabAction } from "./system_actions/tab_action";
export { ImageUploadAction } from "./content_actions/topic_actions/publish_actions/image_upload_action";
export { SelectAction } from "./content_actions/general_actions/select_action";
export { SlideshowAction } from "./content_actions/topic_actions/slideshow_actions/slideshow_action";
export { MediumChangeAction } from "./content_actions/topic_actions/medium_change_action";
export { SavePublishAction } from "./content_actions/save_publish_action";
export { UpdateQueueAction } from "./system_actions/update_queue_action";
export { NestedElementAction } from "./content_actions/general_actions/nested_element_action";
export { ScreenplayAction } from "./content_actions/topic_actions/film_actions/screenplay_action";
export { LessonAction } from "./content_actions/course_actions/lesson_action";
export { QuizAction } from "./content_actions/course_actions/quiz_action";
export { RecordingAppAction } from "./content_actions/topic_actions/film_actions/recording_app_action";
export { ProduceScreenplayAction } from "./content_actions/topic_actions/film_actions/produce_screenplay_action";
export { PostMediumAction } from "./content_actions/post_actions/post_medium_action";
export { FilmProductionAction } from './content_actions/topic_actions/film_actions/film_production_action';
export { PlaylistAction } from './content_actions/playlist_actions/playlist_action';

import { AllSlidesAction } from "./content_actions/topic_actions/slideshow_actions/all_slides_action";
import { AttachmentAction } from "./content_actions/general_actions/attachment_action";
import { CheckboxAction } from "./content_actions/general_actions/checkbox_action";
import { HighlightAction } from "./content_actions/general_actions/highlight_action";
import { TreeAction } from "./content_actions/general_actions/tree_action";
import { MouseAction } from "./content_actions/general_actions/mouse_action";
import { MouseMoveAction } from "./content_actions/general_actions/mouse_move_action";
import { MouseClickAction } from "./content_actions/general_actions/mouse_click_action";
import { QueueAction } from "./system_actions/queue_action";
import { RadioAction } from "./content_actions/general_actions/radio_action";
import { SaveSlidesAction } from "./content_actions/topic_actions/slideshow_actions/save_slides_action";
import { SelectizeAction } from "./content_actions/general_actions/selectize_action";
import { MultiSelectAction } from "./content_actions/general_actions/multiselect_action";
import { DatePickerAction } from "./content_actions/general_actions/datepicker_action";
import { SlideAction } from "./content_actions/topic_actions/slideshow_actions/slide_action";
import { StatusAction } from "./system_actions/status_action";
import { TextAction } from "./content_actions/general_actions/text_action";
import { TabAction } from "./system_actions/tab_action";
import { ImageUploadAction } from "./content_actions/topic_actions/publish_actions/image_upload_action";
import { SelectAction } from "./content_actions/general_actions/select_action";
import { SlideshowAction } from "./content_actions/topic_actions/slideshow_actions/slideshow_action";
import { MediumChangeAction } from "./content_actions/topic_actions/medium_change_action";
import { SavePublishAction } from "./content_actions/save_publish_action";
import { UpdateQueueAction } from "./system_actions/update_queue_action";
import { NestedElementAction } from "./content_actions/general_actions/nested_element_action";
import { ScreenplayAction } from "./content_actions/topic_actions/film_actions/screenplay_action";
import { LessonAction } from "./content_actions/course_actions/lesson_action";
import { QuizAction } from "./content_actions/course_actions/quiz_action";
import { RecordingAppAction } from "./content_actions/topic_actions/film_actions/recording_app_action";
import { ProduceScreenplayAction } from "./content_actions/topic_actions/film_actions/produce_screenplay_action";
import { PostMediumAction } from "./content_actions/post_actions/post_medium_action";
import { FilmProductionAction } from './content_actions/topic_actions/film_actions/film_production_action';
import { PlaylistAction } from "./content_actions/playlist_actions/playlist_action";



/** All Actionclasses that are triggered by listeners bound to specific elements
  * Is uses to bind the listeners for these actions during initialization of the CollaborativeAuthoring component
  */
export const ACTIONS = {
  WITH_LISTENERS: {
    "MouseClickAction": MouseClickAction,
    "MouseMoveAction": MouseMoveAction,
    "CheckboxAction": CheckboxAction,
    "TreeAction": TreeAction,
    "SelectizeAction": SelectizeAction,
    "MultiSelectAction": MultiSelectAction,
    "DatePickerAction": DatePickerAction,
    "HighlightAction": HighlightAction,
    "TextAction": TextAction,
    "RadioAction": RadioAction,
    "AttachmentAction": AttachmentAction,
    "SlideAction": SlideAction,
    "AllSlidesAction": AllSlidesAction,
    "SaveSlidesAction": SaveSlidesAction,
    "TabAction": TabAction,
    "SelectAction": SelectAction,
    "NestedElementAction": NestedElementAction,
    "ScreenplayAction": ScreenplayAction,
    "LessonAction": LessonAction,
    "RecordingAppAction": RecordingAppAction,
    "PostMediumAction": PostMediumAction,
    "FilmProductionAction": FilmProductionAction,
    "PlaylistAction": PlaylistAction,
  },
  /** All Actionclasses that are triggered in other ways (via controller e.g.) */
  WITHOUT_LISTENERS: {
    "StatusAction": StatusAction,
    "MediumChangeAction": MediumChangeAction,
    "QueueAction": QueueAction,
    "UpdateQueueAction": UpdateQueueAction,
    "SavePublishAction": SavePublishAction,
    "ImageUploadAction": ImageUploadAction,
    "QuizAction": QuizAction,
  },
}

/** Array of Actions that have implemented the comparableTo and reduce methods
  *
  */
export const COMPARE_AND_REDUCE_ACTIONS = [CheckboxAction, HighlightAction, TextAction, SlideshowAction, TabAction, TreeAction, SelectizeAction, MultiSelectAction, DatePickerAction, RadioAction, ScreenplayAction];

/** Array of Actions that should not be pushed to the Queue
  * Actions that do not need to be recreated when another user joins because they are either directly persisted or not necessary to be repeated, e.g. MouseActions etc.
  */
export const NOT_QUEUEABLE_ACTIONS = [MouseAction, QueueAction, StatusAction, AttachmentAction, MediumChangeAction, TabAction, UpdateQueueAction, SavePublishAction, RecordingAppAction, ProduceScreenplayAction, QuizAction];

export const ACTIONCLASSES_WITH_LISTENERS = [...Object.values(ACTIONS.WITH_LISTENERS), ...Object.values(ACTIONS.WITH_LISTENERS)];
export const ACTIONNAMES_WITH_LISTENERS = [...Object.keys(ACTIONS.WITH_LISTENERS), ...Object.keys(ACTIONS.WITH_LISTENERS)];

export const ACTIONCLASSES_WITHOUT_LISTENERS = [...Object.values(ACTIONS.WITHOUT_LISTENERS), ...Object.values(ACTIONS.WITHOUT_LISTENERS)];
export const ACTIONNAMES_WITHOUT_LISTENERS = [...Object.keys(ACTIONS.WITHOUT_LISTENERS), ...Object.keys(ACTIONS.WITHOUT_LISTENERS)];

export const ACTIONCLASSES = { ...ACTIONS.WITH_LISTENERS, ...ACTIONS.WITHOUT_LISTENERS };
export const ACTIONNAMES = Object.keys(ACTIONCLASSES);
