import { ActionTree } from 'vuex';
import { nanoid } from 'nanoid';
import { AppState } from './state';
import { Mutations } from './mutations';
import stopTracks from '../../common/stop_tracks';
import resizeImage from '../utils/resize_image';
import type { Settings, State } from './state';
import type { CustomBackgroundImage } from '../../common/background_replacement/types/custom_background_image';

export const Actions = {
  setAppData: 'setAppData',
  setSettings: 'setSettings',
  setAppState: 'setAppState',
  setMicrophoneStream: 'setMicrophoneStream',
  setWebcamLoading: 'setWebcamLoading',
  setWebcamStream: 'setWebcamStream',
  setDisplayStream: 'setDisplayStream',
  setDisplaySourceIsScreen: 'setDisplaySourceIsScreen',
  setHybridStream: 'setHybridStream',
  setCurrentCountdown: 'setCurrentCountdown',
  setDuration: 'setDuration',
  setRecording: 'setRecording',
  setRecordingType: 'setRecordingType',
  discardRecording: 'discardRecording',
  setCustomBackgroundImages: 'setCustomBackgroundImages',
  addCustomBackgroundImage: 'addCustomBackgroundImage',
  removeCustomBackgroundImage: 'removeCustomBackgroundImage',
  setSettingsDialogOpen: 'setSettingsDialogOpen',
  setSettingCountdownDuration: 'setSettingCountdownDuration',
  setSettingBackupActivated: 'setSettingBackupActivated',
  setSettingWebcamRatio: 'setSettingWebcamRatio',
  setSettingMirrorWebcam: 'setSettingMirrorWebcam',
  setSettingBackgroundReplacement: 'setSettingBackgroundReplacement',
  setSettingPoseAnimatorView: 'setSettingPoseAnimatorView',
  setSettingPoseAnimatorAvatar: 'setSettingPoseAnimatorAvatar',
  setSettingPoseAnimatorOutfit: 'setSettingPoseAnimatorOutfit',
};

export const actions: ActionTree<State, State> = {
  setAppData({ commit }, appData: State['appData']) {
    commit(Mutations.SET_APP_DATA, appData);
  },
  setSettings({ commit, state }, settings: Partial<State['settings']>) {
    commit(Mutations.SET_SETTINGS, {
      ...state.settings,
      ...settings,
    });
  },
  setAppState({ commit }, state: State['appState']) {
    commit(Mutations.SET_APP_STATE, state);
  },
  setMicrophoneStream({ commit, state }, stream: State['microphoneStream']) {
    const oldStream = state.microphoneStream;

    commit(Mutations.SET_MICROPHONE_STREAM, stream);

    stopTracks(oldStream);
  },
  setWebcamLoading({ commit }, loading: State['webcamLoading']) {
    commit(Mutations.SET_WEBCAM_LOADING, loading);
  },
  setWebcamStream({ commit, state }, stream: State['webcamStream']) {
    const oldStream = state.webcamStream;

    commit(Mutations.SET_WEBCAM_STREAM, stream);

    stopTracks(oldStream);
  },
  setDisplayStream({ commit, state }, stream: State['displayStream']) {
    const oldStream = state.displayStream;

    commit(Mutations.SET_DISPLAY_STREAM, stream);

    stopTracks(oldStream);
  },
  setDisplaySourceIsScreen({ commit }, isDisplay: State['displaySourceIsScreen']) {
    commit(Mutations.SET_IS_SCREEN_STREAM, isDisplay)
  },
  setHybridStream({ commit }, hybridStream: State['hybridStream']) {
    commit(Mutations.SET_HYBRID_STREAM, hybridStream);
  },
  setCurrentCountdown({ commit }, countdown: State['currentCountdown']) {
    commit(Mutations.SET_CURRENT_COUNTDOWN, countdown);
  },
  setDuration({ commit }, duration: State['duration']) {
    commit(Mutations.SET_DURATION, duration);
  },
  setRecording({ commit }, recording: State['recording']) {
    commit(Mutations.SET_RECORDING, recording);
  },
  setRecordingType({ commit }, recordingType: State['recordingType']) {
    commit(Mutations.SET_RECORDING_TYPE, recordingType);
  },
  discardRecording({ commit }) {
    commit(Mutations.SET_APP_STATE, AppState.READY);
    commit(Mutations.SET_RECORDING, null);
  },
  setSettingsDialogOpen({ commit }, open: State['settingsDialogOpen']) {
    commit(Mutations.SET_SETTINGS_DIALOG_OPEN, open);
  },
  setCustomBackgroundImages({ commit }, customBackgroundImages: State['customBackgroundImages']) {
    commit(Mutations.SET_CUSTOM_BACKGROUND_IMAGES, customBackgroundImages);
  },
  async addCustomBackgroundImage({ commit }, image: Blob) {
    const id = nanoid();

    const thumbnail = await resizeImage(image, 48);

    commit(Mutations.ADD_CUSTOM_BACKGROUND_IMAGE, {
      id,
      image,
      thumbnail: thumbnail ?? image,
    });

    return id;
  },
  removeCustomBackgroundImage({ commit }, customBackgroundId: CustomBackgroundImage['id']) {
    commit(Mutations.REMOVE_CUSTOM_BACKGROUND_IMAGE, customBackgroundId);
  },

  // Settings
  setSettingCountdownDuration({ commit }, duration: Settings['countdownDuration']) {
    commit(Mutations.SET_SETTING_COUNTDOWN_DURATION, duration);
  },
  setSettingBackupActivated({ commit }, activated: Settings['backupActivated']) {
    commit(Mutations.SET_SETTING_BACKUP_ACTIVATED, activated);
  },
  setSettingWebcamRatio({ commit }, ratio: Settings['webcamRatio']) {
    commit(Mutations.SET_SETTING_WEBCAM_RATIO, ratio);
  },
  setSettingMirrorWebcam({ commit }, mirror: Settings['mirrorWebcam']) {
    commit(Mutations.SET_SETTING_MIRROR_WEBCAM, mirror);
  },
  setSettingBackgroundReplacement({ commit }, backgroundReplacement: Settings['backgroundReplacement']) {
    commit(Mutations.SET_SETTING_BACKGROUND_REPLACEMENT, backgroundReplacement);
  },
  setSettingPoseAnimatorView({ commit, getters }, view: Settings['poseAnimator']['view']) {
    if (getters.canEnablePoseAnimator) {
      commit(Mutations.SET_SETTING_POSE_ANIMATOR_VIEW, view);
    }
  },
  setSettingPoseAnimatorAvatar({ commit }, avatar: Settings['poseAnimator']['avatar']) {
    commit(Mutations.SET_SETTING_POSE_ANIMATOR_AVATAR, avatar);
  },
  setSettingPoseAnimatorOutfit({ commit }, outfit: Settings['poseAnimator']['outfit']) {
    commit(Mutations.SET_SETTING_POSE_ANIMATOR_OUTFIT, outfit);
  },
};
