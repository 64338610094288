import * as Emoji from './emojis';

export class ChatMessage {
  constructor(user, body, time, bold = false, color = null, convert_emoticons = true) {
    this.username = null;
    this.userId = null;
    this.user = user;
    this.body = convert_emoticons ? this.convertEmojis(body) : body;
    this._time = null; // will store internal time object
    this.time = time;
    this.bold = bold;
    this.color = color;

  }

  static fromJSON(json) {
    let data = JSON.parse(json);
    return new ChatMessage(data.room, data.user, data.body, data.time);
  }

  set user(user) {
    this.username = user.name;
    this.userId = user.id;
  }

  set time(time) {
    this._time = moment(time);
  }

  get time() {
    return this._time.format('LT');
  }

  convertEmojis(body) {
    let converted = body;
    for (let i in Emoji.emojis) {
      let regex = new RegExp(i, "g");
      converted = converted.replace(regex, Emoji.emojis[i]);
    }
    return converted;
  }
}
