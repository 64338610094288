import { SignIn } from './sign_in';
import { Subscriptions } from './subscriptions';
import { Profile } from './profile';

export let signIn, subscriptions, profile;

export function init() {
  signIn = new SignIn();
  subscriptions = new Subscriptions();
  profile = new Profile();
}
