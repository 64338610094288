import { Uppy } from '@uppy/core';
import * as German from '@uppy/locales/lib/de_DE';
import * as English from '@uppy/locales/lib/en_US';
import * as Spanish from '@uppy/locales/lib/es_ES';
import { UppyStore } from './vuex_store/uppy_store';
import { videoFormats, audioFormats } from '../common/formats';
import store from '../store';
import { flashMessage } from '../mixins/flash_message';

const Tus = require('@uppy/tus');
const FileInput = require('@uppy/file-input');
const DragDrop = require('@uppy/drag-drop');

export class VideoUploader {
  public uppy: Uppy;
  private store: UppyStore;

  private localeMapping = {
    'de': German,
    'en': English,
    'es': Spanish
  };

  constructor(private tusUrl: string, private fileSelect: HTMLElement = null) {
    this.store = new UppyStore('videoUploader');

    this.uppy = new Uppy({
      id: 'videoUploader',
      autoProceed: true,
      allowMultipleUploadBatches: true,
      debug: _app.info.env === 'development',
      restrictions: {
        maxFileSize: _app.info.maxVideoUploadSize,
        maxNumberOfFiles: null,
        minNumberOfFiles: null,
        allowedFileTypes: [...videoFormats, ...audioFormats]
      },
      meta: {
        uploadtype: 'video'
      },
      locale: this.localeMapping[_app.info.currentLocale],
      store: this.store
    });

    this.uppy.on('file-added', (file) => this.onFileAdded(file));
    this.uppy.on('file-removed', (file) => this.onFileRemoved(file));
    this.uppy.on('restriction-failed', (file, error) => flashMessage(error.message));
    this.initPlugins();
  }

  private initPlugins() {
    this.useTus();

    if (this.fileSelect) {
      this.useFileInput();
    }
  }

  private useTus() {
    this.uppy.use(Tus, {
      endpoint: this.tusUrl,
      limit: 1,
      removeFingerprintOnSuccess: true,
      retryDelays: [0, 1000, 3000, 5000]
    });
  }

  public useFileInput(fileSelect: HTMLElement = null) {
    if (fileSelect) {
      this.fileSelect = fileSelect;
    }

    this.uppy.use(FileInput, {
      target: this.fileSelect,
      pretty: false
    })
  }

  public useDropDown(dropZone: HTMLElement) {
    this.uppy.use(DragDrop, {
      target: dropZone,
      height: '170px'
    });
  }

  public pause() {
    this.uppy.pauseAll();
    this.uppy.setOptions({
      autoProceed: false
    });
    store.dispatch('videoUploader/setPausedState', true);
  }

  public resume() {
    this.uppy.resumeAll();
    this.uppy.setOptions({
      autoProceed: true
    });
    store.dispatch('videoUploader/setPausedState', false);
  }

  public reset() {
    this.uppy.reset();
    this.uppy.setOptions({
      autoProceed: true
    });
    store.dispatch('videoUploader/setPausedState', false);
  }

  private onFileAdded(file) {
    if(/^audio\/\w+/.test(file.type)) {
      this.uppy.setFileMeta(file.id, { uploadtype: 'audio' });
    }
  }

  private onFileRemoved(file) {
    if (Object.keys(this.uppy.state.files).length === 0) {
      this.reset();
    }
  }
}
