import { ChangeAction } from "../../../system_actions/change_action";

/**
  * Action that handles changes (upload/edit/delete) in the thumbnail list of the publish tab
  * Changes regarding the thumbnail are immediately saved on the server. Therefore the queuing of ImageUploadActions is not necessary
 */
export class ImageUploadAction extends ChangeAction {
  /**
   * @constructor
   * @param {string} tabId  - tabId of the tab the action originates from
   * @param {number} user - userId of the chat room owner
   * @param {String} element - element thats subject of the ChangeAction
   * @param {Object} data - information about the thumbnail
   * @param {String} method - "change", "upload" or "remove"
   */
  constructor(tabId, user, element, data, method) {
    super(tabId, user, element);
    this.method = method;
    this.data = data;
    this.actionType = "ImageUploadAction";
  }

  execute() {
    if (this.element) {
      let wrapper = $(this.element.parent()),
        input = wrapper.find('.image-upload-input'),
        inputBase64 = wrapper.find('.image-upload-input-base64'),
        preview = wrapper.find('.thumbnail-preview'),
        inputDelete = wrapper.find('.image-upload-delete-input');
      if (this.method === "upload") {
        preview.css('background-image', 'url(' + this.data + ')');
        wrapper.addClass('thumbnail-set');
        inputDelete.val('0');
        inputBase64.val(this.data);
        let customField = wrapper.find('.custom-image-field');
        if (customField) {
          customField.val(true);
        }
  }
      if (this.method === "remove") {
        wrapper.removeClass('thumbnail-set');
        inputDelete.val('1');
        input.val('');
        inputBase64.val('');
        preview.css('background-image', null);
      }
    }
  }

  static fromJson(json) {
    let action = JSON.parse(json);
    return new ImageUploadAction(action.tabId, action.user, action.elementLocation, action.data, action.method);
  }

  static createAction(element, data, method) {
    if (VERSTEHE.CollaborativeAuthoring.isEnabled()) {
      let action = new ImageUploadAction(ImageUploadAction.getTabId(), _app.info.user.userId, element, data, method);
      VERSTEHE.content_channel.send_message(action);
    }
  }
}
