import { SlideshowAction } from "./slideshow_action";


/**
 * SlideActions represent changes to the selection of single slides
 * 2 SlideActions (select/deselect) regarding the same element even each other out
 */
export class SlideAction extends SlideshowAction {
  /**
   * @constructor
   * @param {String} tabId  - TabId of the tab the action originates from
   * @param {number} user - UserId of the chat room owner
   * @param {String} element - Element thats subject of the UIAction
   */
  constructor(tabId, user, element) {
    super(tabId, user, element);
    this.actionType = "SlideAction";
  }

  execute() {
    VERSTEHE.slideshows.slideToggleClick(this.element);
  }
  isComparableTo(other) {
    if (this.actionType === other.actionType) {
      return this.element.parent().is(other.element.parent());
    } else {
      return false;
    }
  }

  reduce(other) {
    return null;
  }

  static fromJson(json) {
    let action = JSON.parse(json);
    return new SlideAction(action.tabId, action.user, action.elementLocation);
  }

  static registerToElement() {
    $(document).on('click', '[data-collaborative-action*="SlideAction"] .slide-page, [data-collaborative-action*="SlideAction"] .slide-toggle-use', function() {
      SlideAction.createAction(SlideAction.getTabId(), _app.info.user.userId, event.target);
    });
  }

  static createAction(tabId, user, element) {
    let action = new SlideAction(tabId, user, element);
    VERSTEHE.content_channel.send_message(action);
  }
}
