import Vue from 'vue';
import Component from 'vue-class-component'
import { Image, Slide, Take } from '../../media_library/interfaces';
import { Prop } from 'vue-property-decorator';
import { Getters } from '../store/getters';
import { MapAction } from '../../vue/decorators';
import { Actions } from '../store/actions';
import { confirmDialog } from '../../common/dialog';

@Component
export class MediaItem<T extends Slide | Take | Image> extends Vue {
  @Prop(Object) protected item: T;
  @MapAction(Actions.REMOVE_FROM_WORKSPACE) remove;

  get isUsed() {
    return this.$store.getters[Getters.SCENES_WITH_MEDIUM](this.item).length > 0;
  }

  removeMedium() {
    if (this.isUsed) {
      confirmDialog({ content: this.$i18n.t('screenplay.confirm_delete') }).then(() => {
        this.remove({ medium_id: this.item.id, medium_type: this.item.type});
      }).catch(() => {});
    } else {
      this.remove({ medium_id: this.item.id, medium_type: this.item.type});
    }
  }
}
