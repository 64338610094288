import { Tree } from './tree';
import { BooleanFilter } from './boolean';
import { Multiselect } from './multiselect';
import { Label } from './label';
import { SearchResultSet } from './search_result_set';

export class Bar {
  constructor(bar, dashboard) {
    this.element = bar;
    this.activeFilterWrapper = this.element.parentElement.querySelector('.analytics-filters-wrapper');
    this.trees = {};
    this.searchableFilter = [];
    this.dashboard = dashboard;
    this.label = new Label(this);
    this.boolFilter = new BooleanFilter(this);
    this.multiselects = {};
    this.searchResultSet = new SearchResultSet(this);
    this.initilizeDropDown();
    this.bindListener();
  }

  initilizeDropDown() {
    // create Dropdown div
    this.fieldSelectWrapper = document.createElement('div');
    this.fieldSelectWrapper.className = 'analytics-bar-filter';

    this.initilizeFilterItems();
    //initialize kendo ui dropdown
    this.fieldSelect = $(this.fieldSelectWrapper).kendoPopup({
      anchor: this.element
    }).data('kendoPopup');
  }

  initilizeFilterItems() {
    // create quicklink container
    let outerWrapper = document.createElement('div'); 
    outerWrapper.classList.add('quick-link-wrapper');
    
    // create quick link list
    let dropdown = document.createElement('ul');
    let options = JSON.parse(this.element.getAttribute('data-filter-options'));

    // create list elements
    options.forEach((quick) => {    
      dropdown.appendChild(this.createQuickLinkElement(quick));
    });

    // add list to the container
    outerWrapper.appendChild(dropdown);

    // add quicklinks to the left side
    this.fieldSelectWrapper.innerHTML = '';
    this.fieldSelectWrapper.appendChild(outerWrapper);
  }

  createQuickLinkElement(quick) {
    let element = document.createElement('li');
    let link = document.createElement('a');
    link.href = '#';
    link.innerText = quick.title;

    if (quick.target === 'modal') {
      this.initializeQuickLinkTypes(quick);
      link.setAttribute('data-toggle', 'modal');
      link.setAttribute('data-target', `#dialog_${quick.id}`);
      link.addEventListener('click', () => {
        this.fieldSelect.close();
      });
    } else if (quick.type === 'boolean') {
      link = this.boolFilter.addQuickLink(quick, link);
    } else if (quick.type === 'multiselect') {
      this.initializeQuickLinkTypes(quick);
      link = this.multiselects[quick.id].addQuickLink(quick, link);
    }
    element.appendChild(link);

    return element;
  }

  /**
   * Trigger change events to refresh charts
   */
  triggerFilterChanges() {
    this.dashboard.setActiveBarFilter();
    this.dashboard.triggerFilterChanges();
  }

  /**
   * This method adds on aktiv filter element
   * @param {*} filter Label element of active filter
   * @param {*} triggerEvents boolean flaq must be send a filter change event after remove
   */
  addActiveFilter(filter, triggerEvents) {
    this.activeFilterWrapper.appendChild(filter);

    if (triggerEvents !== false) {
      this.triggerFilterChanges();
    }
  }

  /**
   * This method removes one aktive filter element
   * @param {*} filter Label element of active filter
   * @param {*} triggerEvents boolean flaq must be send a filter change event after remove
   */
  removeActiveFilter(filter, triggerEvents) {
    this.activeFilterWrapper.removeChild(filter);

    if (triggerEvents !== false) {
      this.triggerFilterChanges();
    }
  }

  /**
   * This Method adds more than one aktiv filter element
   * @param {HTMLELEMENT} filters Label elements of active filter
   * @param {Boolean} triggerEvents boolean flaq must be send a filter change event after remove
   */
  addActiveFilters(filters, triggerEvents) {
    filters.forEach((filter) => {
      this.activeFilterWrapper.appendChild(filter);
    });

    if (triggerEvents !== false) {
      this.triggerFilterChanges();
    }
  }

  /**
   * This Method remove more than one active filter element
   * @param {HTMLELEMENT} filters Label elements of active filter
   * @param {Boolean} triggerEvents boolean flaq must be send a filter change event after remove
   */
  removeActiveFilters(filters, triggerEvents) {
    filters.forEach((filter) => {
      this.activeFilterWrapper.removeChild(filter);
    });

    if (triggerEvents !== false) {
      this.triggerFilterChanges();
    }
  }

  // currently exists only the type tree
  // in this method can be add function for more filter types
  initializeQuickLinkTypes(quick) {
    if (quick.type === "tree") {
      this.trees[quick.id] = new Tree(this, quick.id, quick.title);
      this.addAsSarchableFilter(quick, this.trees[quick.id]);
    } else if (quick.type === 'multiselect') {
      this.multiselects[quick.id] = new Multiselect(this, quick);
      this.addAsSarchableFilter(quick, this.multiselects[quick.id]);
    }
  }

  addAsSarchableFilter(element, initFilter) {
    if (element.searchable) {
      this.searchableFilter.push(initFilter);
    }
  }

  // removed the search result wich added in the drop down
  removeSearchResults () {
    let results = this.fieldSelectWrapper.querySelectorAll('.search-result-wrapper');

    results.forEach(result => {
      result.remove();
    });
  }

  closePopup() {
    this.fieldSelect.close();
  }

  bindListener() {
    if (this.element) {
      // open drop down on click in the filter field
      this.element.addEventListener('click', () => {
        this.fieldSelect.open();
      });

      // Event lister for typing in filter input field
      // The timeout prevent method spamming 
      this.element.addEventListener('keyup', (e) => {
        clearTimeout(this.typingTimeout);

        this.typingTimeout = setTimeout( () => {
          if (e.target.value.length > 0) {
            this.searchableFilter.forEach( filter => {
              this.removeSearchResults();
              let results = filter.search(this.element.value);
              // return if no results are available
              if (results.length === 0) { return; }
              // add search results to dropdown
              let dropdownElement = this.searchResultSet.processSearchResult(results, filter.title);
              this.fieldSelectWrapper.insertAdjacentElement('afterbegin', dropdownElement);
            });
          } else {
            this.removeSearchResults();
          }
        }, 200);
      });
    }
  }
}
