<template>
  <div class="select-pane">
    <div class="video-player-wrapper">
      <video-player
        ref="videoPlayer"
        v-if="initialized"
        :sources="[videoSrc]"
        :options="videoOptions"
        @timeupdate="onTimeUpdate"
        @loadedmetadata="onLoadedmetadata"></video-player>

      <div class="add-new-question">
        <button class="btn btn-primary btn-flat" @click="newQuestion(currentTime)">{{ $t('film_questions.add_question') }}</button>
      </div>
    </div>

    <question-list/>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import VideoPlayer from '../../globals/vue/video_player';
import QuestionList from './question_list.vue';
import { Actions } from '../store';

export default {
  components: {
    VideoPlayer,
    QuestionList
  },
  computed: {
    ...mapGetters({
      videoSrc: 'filmQuestions/videoSrc',
      initialized: 'filmQuestions/initialized',
      currentTime: 'filmQuestions/currentTime'
    }),
    videoOptions() {
      return {};
    }
  },
  methods: {
    ...mapActions({
      newQuestion: Actions.NEW_QUESTION,
      setCurrentTime: Actions.SET_CURRENT_TIME,
      setDuration: Actions.SET_DURATION
    }),

    onTimeUpdate(player, currentTime) {
      this.setCurrentTime(currentTime);
    },

    onLoadedmetadata(player) {
      this.setDuration(player.duration());
      const playPromise = player.play();

      if (playPromise !== undefined) {
        playPromise.then(() => {
          player.pause();
          player.currentTime(0);
        })
      } else {
        player.pause();
        player.currentTime(0);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.select-pane {
  flex: 1 auto;
  overflow-y: auto;
  max-width: 820px;
  padding: 10px;
}

.add-new-question {
  margin-top: 24px;

  > .btn {
    display: block;
    margin: 0 auto;
  }
}
</style>
