import { Datepicker } from './datepicker';
import { fireEvent } from './custom_event';

export class RangeDatepicker {
  constructor(container, options) {
    this.container = container;
    this.fromPicker = new Datepicker(null, this.container.find('.datepicker-from-wrapper'), {});
    this.tillPicker = new Datepicker(null, this.container.find('.datepicker-till-wrapper'), {});
    this.bindListerners();
  }

  getDisplayedTimeInterval() {
    let displayFrom = this.fromPicker.getValueAsMoment().format(datepickerLocalization[_app.info.currentLocale]['moment'].dateFormat);
    let from = moment(new Date(this.fromPicker.getValue())).format();
    let displayTill = this.tillPicker.getValueAsMoment().format(datepickerLocalization[_app.info.currentLocale]['moment'].dateFormat);
    let till = moment(new Date(this.tillPicker.getValue())).format();
    
    return { "from": from, "displayFrom": displayFrom, "till": till, "displayTill": displayTill }; 
  }

  fromChanges() {
    if(this.fromPicker.getValue() === 'Invalid date') { return; }
    let from = this.fromPicker.getValue();
    let till = this.tillPicker.getValue();

    if (from) {
      from = new Date(from);
      this.tillPicker.datepicker.min(from);
    } else if (till) {
      this.fromPicker.datepicker.max(new Date(till));
    } else {
      from = new Date();
      this.fromPicker.datepicker.max(from);
      this.tillPicker.datepicker.min(from);
    }
  };

  tillChanges() {
    let from = this.fromPicker.getValue();
    let till = this.tillPicker.getValue();

    if (till) {
      till = new Date(till);
      this.fromPicker.datepicker.max(till);
    } else if (from) {
      this.tillPicker.datepicker.min(new Date(from));
    } else {
      till = new Date();    
      this.fromPicker.datepicker.max(till);
      this.tillPicker.datepicker.min(till);
    }
  };

  blankPicker() {
    this.resetPicker(this.fromPicker.datepicker);
    this.resetPicker(this.tillPicker.datepicker);
  }

  resetPicker(datepicker) {
    datepicker.value(null);
    // Set min and max to kendo ui defaults
    datepicker.max(new Date(2099, 11, 31));
    datepicker.min(new Date(1900, 0, 1));
  }

  bindListerners() {
    this.fromPicker.datepicker.bind("change", () => {
      this.fromChanges();
    });

    this.tillPicker.datepicker.bind("change", () => {
      this.tillChanges();
    });
  }
}
