import axios from 'axios';
import { v4 as uuid } from 'uuid';
import { Actions } from './';
import { serializeQuestions } from '../';

export default {
  initUrl({ commit }, url) {
    commit('INIT_URL', url);

    axios.get(url).then(response => {
      commit('UPDATE_QUESTIONS', response.data.questions);
      commit('UPDATE_VIDEO_SRC', response.data.videoUrl);
      commit('INITIALIZED');
    }).catch(error => {
      console.error('Failed to load film questions');
      console.log(error.response);
    });
  },

  resetState({ commit, dispatch, state })  {
    const initUrl = state.initUrl;

    commit('RESET_STATE');
    dispatch('initUrl', initUrl);
  },

  editQuestion({ commit }, question) {
    commit('EDIT_QUESTION', question);
  },

  removeQuestion({ commit, state, dispatch }, question) {
    if (state.currentQuestion === question) {
      // Reset current question
      commit('EDIT_QUESTION', null);
    }

    commit('REMOVE_QUESTION', question);
    dispatch('saveRemote');
  },

  newQuestion({ commit }, time = null) {
    commit('EDIT_QUESTION', {
      id: uuid(),
      title: '',
      hint: '',
      randomize: false,
      type: 'FilmQuestion::SingleSelect',
      time: time,
      isNew: true,
      options: [
        {
          id: uuid(),
          title: '',
          position: 1,
          correct: true,
          isNew: true
        },
        {
          id: uuid(),
          title: '',
          position: 2,
          correct: false,
          isNew: true
        }
      ]
    });
  },

  saveQuestion({ commit, dispatch }, attributes) {
    commit('SAVE_QUESTION', attributes);
    dispatch('saveRemote');
  },

  cancelQuestion({ commit }) {
    commit('EDIT_QUESTION', null);
  },

  setCurrentTime({ commit }, time) {
    commit('SET_CURRENT_TIME', time);
  },

  setDuration({ commit }, duration) {
    commit('SET_DURATION', duration);
  },

  saveRemote({ commit, state }) {
    const serializedQuestions = serializeQuestions(state.questions);
    commit('SAVING', true);

    return new Promise((resolve, reject) => {
      axios.patch(state.initUrl, {
        questions_attributes: serializedQuestions
      }).then(response => {
        commit('UPDATE_QUESTIONS', response.data.questions);
        commit('SAVED', true);
        resolve(true);
      }).catch(error => {
        console.error(error);
        commit('SAVED', false);
        reject(error);
      }).finally(() => {
        commit('SAVING', false);
      });
    });
  }
};
