const logger = Logger.get('WidgetEditor');

/** Class representing the Widget Editor */
export class WidgetEditor {
  /**
   * @constructor
   * @param {WidgetGrid} widgetGrid - Reference to the widget grid
   */
  constructor(widgetGrid) {
    this.widgetGrid = widgetGrid;
    this.container = document.querySelector('.landing-page-widget-edit');
    this.widget = null;

    this.saveBtn = document.querySelector('.landing-page-widget-edit-save');
    this.discardBtn = document.querySelector('.landing-page-widget-edit-discard');
    this.staticContentToggle = this.container.querySelector('.widget-edit-static-contents');

    this.saveBtn.addEventListener('click', (e) => {
      e.stopPropagation();
      this.save();
    });

    this.discardBtn.addEventListener('click', (e) => {
      e.stopPropagation();
      this.discard();
    });

    this.setFieldMap();

    this.fieldMap['static_contents'].field.addEventListener('change', () => {
      this.toggleStaticContentsView(this.fieldMap['static_contents'].field.checked);
    });

    this.initStaticContentSearch();
    this.initTagInput();
  }

  /**
   * Initialize a field map for every manipulateable input we need
   * to read or fill when saving/loading a widget.
   */
  setFieldMap() {
    let showTitleCheckbox = this.container.querySelector('.widget-edit-show-title'),
      titleInput = this.container.querySelector('.widget-edit-title'),
      hideViewButtonCheckbox = this.container.querySelector('.widget-edit-hide-viewbutton'),
      filterTypesCheckboxes = this.container.querySelectorAll('.widget-edit-contribution-type'),
      filterQueryInput = this.container.querySelector('.widget-edit-query'),
      filterTopicAreaInput = this.container.querySelector('[name="filter[topic_areas][]"]'),
      filterMetadataInputs = this.container.querySelectorAll('[name="filter[metadata][]"]'),
      filterTagsInput = this.container.querySelector('.widget-edit-tags'),
      orderFieldSelect = this.container.querySelector('.widget-edit-order-field'),
      orderDirectionInputs = this.container.querySelectorAll('.widget-edit-order-direction'),
      limitSelect = this.container.querySelector('.widget-edit-limit'),
      staticContentCheckbox = this.container.querySelector('.widget-edit-static-contents'),
      staticContentList = this.container.querySelector('.landing-page-widget-edit-contribution-static-list'),
      wordCategorySelect = this.container.querySelector('.widget-edit-word-category'),
      wordShapeSelect = this.container.querySelector('.widget-edit-word-shape'),
      wordLimitSelect = this.container.querySelector('.widget-edit-word-limit'),
      timespanSelect = this.container.querySelector('.widget-edit-timespan');

    this.fieldMap = {
      title: { type: 'input', field: titleInput },
      static_contents: { type: 'checkbox', field: staticContentCheckbox },
      show_title: { type: 'checkbox', field: showTitleCheckbox },
      hide_viewbutton: { type: 'checkbox', field: hideViewButtonCheckbox },
      filter_types: { type: 'multi_checkbox', field: filterTypesCheckboxes },
      filter_query: { type: 'input', field: filterQueryInput },
      filter_topic_area: { type: 'tree', field: [filterTopicAreaInput] },
      filter_metadata: { type: 'tree', field: filterMetadataInputs },
      filter_tags: { type: 'selectize', field: filterTagsInput },
      order_field: { type: 'input', field: orderFieldSelect },
      order_direction: { type: 'radio', field: orderDirectionInputs },
      limit: { type: 'input', field: limitSelect },
      static_content_list: { type: 'content_list', field: staticContentList },
      word_shape: { type: 'input', field: wordShapeSelect },
      word_category: { type: 'input', field: wordCategorySelect },
      word_limit: { type: 'input', field: wordLimitSelect },
      timespan: { type: 'input', field: timespanSelect }
    };
  }

  /**
   * Edit a given widget in the WidgetEditor
   * @param {BaseWidget} widget that is to be edited
   */
  edit(widget) {
    logger.debug('Started editor for ', widget);

    if (this.widget) {
      this.discard();
    }

    this.widget = widget;

    // Scroll to widget
    let posX = this.widget.element.getBoundingClientRect().top + document.documentElement.scrollTop;
    window.scrollTo(0, posX - 70);

    document.querySelector('body').classList.add('show-popover');
    document.querySelector('.landing-page-popover').classList.add('widget-edit-active');

    this.setFormParams();
    let hasContributionFilter = this.setContributionFilterVisibility();

    this.container.querySelector(".landing-page-widget-edit-word-cloud").classList.toggle('hide', this.widget.type !== 'WordCloud');

    if (hasContributionFilter) {
      this.toggleStaticContentsView(this.fieldMap['static_contents'].field.checked);
    }
    if (this.widget.type === 'WordCloud') {
      this.container.querySelectorAll('.landing-page-edit-contribution-timespan, .landing-page-edit-contribution-timespan div').forEach(e => {
        e.classList.remove('hide');
      });
    }
  }

  /**
   * Display or hide form components for a static content list
   * @param {Boolean} Should we show it?
   */
  toggleStaticContentsView(show = true) {
    let staticElements = this.container.querySelectorAll('.landing-page-edit-contribution-filter-static'),
      dynamicElements = this.container.querySelectorAll('.landing-page-edit-contribution-filter-dynamic');

    for (let i = 0; i < staticElements.length; i++) {
      show ? staticElements[i].classList.remove('hide') : staticElements[i].classList.add('hide');
    }

    for (let i = 0; i < dynamicElements.length; i++) {
      show ? dynamicElements[i].classList.add('hide') : dynamicElements[i].classList.remove('hide');
    }
  }

  /**
   * Returns the parameters according to fieldMap.
   * @returns {Object} All form parameters
   */
  getFormParams() {
    return Object.keys(this.fieldMap).reduce((params, key) => {
      let entry = this.fieldMap[key];
      params[key] = this.getFormParamValue(entry.type, entry.field);

      return params;
    }, {});
  }

  /**
   * Sets the parameters according to fieldMap
   */
  setFormParams() {
    let keys = Object.keys(this.fieldMap);
    for (let i = 0; i < keys.length; i++) {
      let entry = this.fieldMap[keys[i]];
      let value = this.widget.params[keys[i]];

      if (typeof value === 'undefined') {
        this.hideFormGroup(entry.type, entry.field);
      } else {
        this.showFormGroup(entry.type, entry.field);
        this.setFormParamValue(entry.type, entry.field, value);
      }
    }
  }

  /**
   * Either show or hide the contribution filters, depending of the currently
   * edited widget type
   */
  setContributionFilterVisibility() {
    let typesWithContributionFilter = [
      'ContributionGrid',
      'ContributionList',
      'HeroSlider',
      'Slider'
    ];

    let contributionFilterWrappers =
      this.container.querySelectorAll('.landing-page-widget-edit-contribution-filter, .landing-page-edit-contribution-filter-static, .landing-page-widget-edit-contribution-order, .landing-page-edit-contribution-timespan');

    let isVisible = typesWithContributionFilter.indexOf(this.widget.type) > -1;
    for (let i = 0; i < contributionFilterWrappers.length; i++) {
      if (isVisible) {
        contributionFilterWrappers[i].classList.remove('hide');
      } else {
        contributionFilterWrappers[i].classList.add('hide');
      }
    }

    return isVisible;
  }

  /**
   * Initialize the contribution search field with typeahead
   */
  initStaticContentSearch() {
    let searchInput = this.container.querySelector('.widget-edit-static-find');

    $(searchInput).typeahead({
      hint: true,
      highlight: true,
      minLength: 1
    }, {
      name: 'contributions',
      display: 'title',
      source: new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('title'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
          url: searchInput.getAttribute('data-url') + '?q=%QUERY',
          wildcard: '%QUERY',
          transform: function (response) {
            return response['contributions']['results'];
          }
        }
      }),
      limit: 6,
      templates: {
        suggestion: Handlebars.compile('<div class="sr-contribution" data-type="{{type}}">{{title}}</div>')
      }
    }).on('typeahead:select', (e, suggestion) => {
      $(searchInput).typeahead('val', null);
      this.addStaticContribution(suggestion);
    });

    // sortable content list
    let list = this.container.querySelector('.landing-page-widget-edit-contribution-static-list');
    $(list).sortable();
  }

  initTagInput() {
    let input = this.container.querySelector('.widget-edit-tags');
    let url = input.getAttribute('data-url');

    $(input).selectize({
      plugins: ['remove_button', 'clear_button'],
      valueField: 'id',
      labelField: 'name',
      searchField: 'name',
      load: (query, callback) => {
        if (!query.length) {
          return callback();
        }

        $.ajax({
          url: url,
          type: 'GET',
          dataType: 'json',
          data: { q: query },
          error: () => {
            callback();
          },
          success: (res) => {
            callback(res);
          }
        });
      }
    });
  }

  /**
   * Add a static contribution to the list
   * @param {Object} contribution
   */
  addStaticContribution(contribution) {
    let list = this.container.querySelector('.landing-page-widget-edit-contribution-static-list');
    let item = document.createElement('li');
    let title = document.createTextNode(contribution.title);
    let removeIcon = document.createElement('i');
    item.setAttribute('data-type', contribution.type);
    item.setAttribute('data-id', contribution.id);
    removeIcon.className = 'remove-item material-icons';
    removeIcon.innerText = 'clear';

    item.appendChild(title);
    item.appendChild(removeIcon);
    list.appendChild(item);

    removeIcon.addEventListener('click', () => {
      item.remove();
    });
  }

  /**
   * Toggle a form element from fieldMap
   * @param {String} type - Input field type
   * @param {Object} field - Input field
   * @param {Boolean} show
   */
  toggleFormGroup(type, field, show) {
    let element;

    switch (type) {
      case 'input':
      case 'checkbox':
      case 'select':
        element = field.closest('.form-group');
        break;
      case 'radio':
        element = field[0].closest('.form-group');
    }

    if (element) {
      show ? element.classList.remove('hide') : element.classList.add('hide');
    }
  }

  /**
   * Hide a form element from fieldMap
   * @param {String} type - Input field type
   * @param {Object} field - Input field
   */
  hideFormGroup(type, field) {
    this.toggleFormGroup(type, field, false);
  }

  /**
   * Show a form element from fieldMap
   * @param {String} type - Input field type
   * @param {Object} field - Input field
   */
  showFormGroup(type, field) {
    this.toggleFormGroup(type, field, true);
  }

  /**
   * Get the current value of a form field
   * @param {String} type - Input field type
   * @param {Object} field - Input field
   * @return {Object} Input field value. Can be a string or an array
   */
  getFormParamValue(type, field) {
    let values;

    switch (type) {
      case 'input':
        return field.value;
      case 'checkbox':
        return field.checked;
      case 'multi_checkbox':
        values = [];

        for (let i = 0; i < field.length; i++) {
          if (field[i].checked) {
            values.push(field[i].value);
          }
        }

        return values;
      case 'radio':
        for (let i = 0; i < field.length; i++) {
          if (field[i].checked) {
            return field[i].value;
          }
        }

        break;
      case 'content_list':
        let items = field.getElementsByTagName('li');
        values = [];

        for (let i = 0; i < items.length; i++) {
          values.push(items[i].getAttribute('data-id'));
        }

        return values;
      case 'tree':
        values = [];

        for (let i = 0; i < field.length; i++) {

          let tree = this.widgetGrid.initializedTrees.find(
            function (obj) { return obj.uuid === field[i].parentNode.getAttribute('data-uuid'); }
          );

          if (tree !== undefined) {
            values = values.concat(tree.getChecked().map(x => x.id));
          }

        }

        return values;
      case 'selectize':
        let options = field.querySelectorAll('option:checked');
        values = {};

        for (let i = 0; i < options.length; i++) {
          values[options[i].value] = options[i].innerText;
        }

        return values;
    }
  }

  /**
   * Set the value of a form field
   * @param {String} type - Input field type
   * @param {Object} field - Input field
   * @param {Object} value - Value for input field
   */
  setFormParamValue(type, field, value) {
    switch (type) {
      case 'input':
        if (value) {
          field.value = value;
        } else {
          field.value = '';
        }
        $(field).trigger('keydown');
        break;
      case 'checkbox':
        field.checked = value.toString() === 'true';
        break;
      case 'multi_checkbox':
        for (let i = 0; i < field.length; i++) {
          let checked = value.length === 0 || value.indexOf(field[i].value) > -1;
          field[i].checked = checked;
        }

        break;
      case 'radio':
        for (let i = 0; i < field.length; i++) {
          if (field[i].value === value) {
            field[i].checked = true;
          }
        }

        break;
      case 'content_list':
        let infoUrl = field.getAttribute('data-info-url');
        // clear list
        field.innerHTML = "";

        // get title/type from id
        $.getJSON(infoUrl, { id: value }, (response) => {
          for (let i = 0; i < value.length; i++) {
            this.addStaticContribution({
              id: value[i],
              title: response[value[i]].title,
              type: response[value[i]].type
            });
          }
        });

        break;
      case 'tree':
        for (let i = 0; i < field.length; i++) {
          let tree = this.widgetGrid.initializedTrees.find(
            function (obj) { return obj.uuid === field[i].parentNode.getAttribute('data-uuid'); }
          );

          if (tree) {
            value = value.map(Number);
            tree.setChecked(value);
            tree.setSelectedElementsHelper(tree.getChecked());
          }
        }

        break;
      case 'selectize':
        let keys = Object.keys(value);
        field.selectize.clearOptions();

        for (let i = 0; i < keys.length; i++) {
          let id = keys[i];
          let label = value[id];
          field.selectize.addOption({ id: id, name: label });
          field.selectize.addItem(id);
        }

        field.selectize.refreshOptions(false);
        field.selectize.refreshItems();
    }
  }

  /**
   * Update the currently edited widget with the current parameters
   */
  save() {
    if (this.widget) {
      document.querySelector('.landing-page-popover').classList.remove('widget-edit-active');
      let params = this.getFormParams();
      logger.debug('Updating widget with parameters', this.widget, params);
      this.widget.update(params);
      this.widget = null;
      this.widgetGrid.dirty = true;
    }
  }

  /**
   * Discard the current changes for the widget
   */
  discard() {
    if (this.widget) {
      document.querySelector('.landing-page-popover').classList.remove('widget-edit-active');
      this.widget.discardEdit();
      this.widget = null;
    }
  }
}
