export enum FilterType {
  Name = 'name_filter',
  Duration = 'duration',
  Resolution = 'resolution',
  CreationDate = 'creation_date',
  ResourceType = 'resource_type',
  ResourceSource = 'resource_source',
}

export enum CompareOperator {
  Equals = 'equals',
  EqualsOrGreater = 'equals_or_greater',
  EqualsOrSmaller = 'equals_or_smaller',
  Greater = 'greater',
  Smaller = 'smaller',
}
