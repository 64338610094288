export const locals = {
  de: {
    action_evaluate: 'Auswerten',
    action_delete: 'Löschen',
    action_edit: 'Bearbeiten',
    action_rollout: 'Ausliefern',
    action_share: 'Teilen',
    action_restore: 'Wiederherstellen',
    edit_contribution: 'Beitrag bearbeiten',
    and: 'und',
    author: 'Erstellt von',
    bulk_change_message: '#= affected # # if (affected == 1) {#Beitrag wurde geändert.#} else {#Beiträge wurden geändert#} #',
    bulk_delete_message: '#= affected # # if (affected == 1) {#Beitrag wurde gelöscht.#} else {#Beiträge wurden gelöscht#} #',
    bulk_delete_confirm: 'Sind Sie sicher, dass Sie #= count # Beiträge löschen wollen?',
    not_allowed_contents: 'Es konnten nicht alle Beiträge an den Nutzer übertragen werden. Der ausgewählte Nutzer hat nicht genügend Rechte.',
    change: 'Ändern',
    comments: 'Kommentare',
    contains: 'Beinhaltet',
    contribution: 'Beitrag',
    created_at: 'Erstellt am',
    eq: 'ist',
    expired: 'Abgelaufen',
    expires_at: 'Gültig bis',
    featured: 'Beitragshervorhebung',
    featured_ribbon: 'Featured',
    filter_featured_true: 'Nur hervorgehobene Beiträge',
    filter_featured_false: 'Keine hervorgehobenen Beiträge',
    is_collaborative_content: 'Kollaborative Beiträge',
    filter_is_collaborative_content_true: 'Nur kollaborative Beiträge',
    filter_is_collaborative_content_false: 'Keine kollaborativen Beiträge',
    filter_only_my_own: 'Nur meine eigenen',
    filter_private_true: 'Nur private Beiträge',
    filter_private_false: 'Nur öffentliche Beiträge',
    filter_expired_true: 'Nur abgelaufene Beiträge',
    filter_expired_false: 'Keine abgelaufenen Beiträge',
    find_users: 'Beginnen Sie mit der Eingabe, um Benutzer zu finden.',
    is_after_or_equal_to: 'ist gleich oder später',
    is_before_or_equal_to: 'ist gleich oder früher',
    is_equal_to: 'ist gleich',
    inactive_contribution: 'Inaktiver Beitrag',
    tags: 'Tags',
    updated_at: 'Geändert am',
    topic_areas: 'Themenbereiche',
    untitled_post: 'Unbenannter Post',
    untitled_topic: 'Unbenanntes Thema',
    untitled_playlist: 'Unbenannte Playlist',
    untitled_course: 'Unbenannter Kurs',
    untitled_course_plan: 'Unbenannter Kursplan',
    and_others: 'und #: co_authors.length # weitere# if (co_authors.length == 1) {#r#} #',
    views: 'Aufrufe',
    visibility: 'Sichtbarkeit',
    visibility_public: 'Öffentlich',
    visibility_private: 'Privat',
    visibility_unpublished: 'Unveröffentlicht',
    visibility_unlisted: 'Ungelistet',
    deleted_contributions: 'Nur gelöschte Beiträge',
    deleted_user: "Gelöschter Benutzer"
  },
  en: {
    action_evaluate: 'Evaluate',
    action_delete: 'Delete',
    action_edit: 'Edit',
    action_rollout: 'Rollout',
    action_share: 'Share',
    action_restore: 'Restore',
    edit_contribution: 'Edit contribution',
    and: 'and',
    author: 'Created by',
    bulk_change_message: '#= affected # # if (affected == 1) {#contribution was changed.#} else {#contributions were changed.#} #',
    bulk_delete_message: '#= affected # # if (affected == 1) {#contribution was deleted.#} else {#contributions were deleted.#} #',
    bulk_delete_confirm: 'Are you sure you want to delete #= count # contributions?',
    not_allowed_contents: 'Not all contents were transferred to the user. The selected User has not enough rights.',
    change: 'Change',
    comments: 'Comments',
    contains: 'Contains',
    contribution: 'Contribution',
    created_at: 'Created at',
    eq: 'equals',
    expired: 'Expired',
    expires_at: 'Valid until',
    featured: 'Featured',
    featured_ribbon: 'Featured',
    filter_featured_true: 'Only featured contributions',
    filter_featured_false: 'No featured contributions',
    is_collaborative_content: 'Collaborative contributions',
    filter_is_collaborative_content_true: 'Only collaborative contributions',
    filter_is_collaborative_content_false: 'No collaborative contributions',
    filter_only_my_own: 'Only my own',
    filter_private_true: 'Only private contributions',
    filter_private_false: 'Only public contributions',
    filter_expired_true: 'Only expired contributions',
    filter_expired_false: 'No expired contributions',
    find_users: 'Start typing to find users.',
    is_after_or_equal_to: 'Is after or equal to',
    is_before_or_equal_to: 'Is before or equal to',
    is_equal_to: 'Is equal to',
    inactive_contribution: 'Inactive contribution',
    tags: 'Tags',
    updated_at: 'Updated at',
    topic_areas: 'Topic Areas',
    untitled_post: 'Untitled Post',
    untitled_topic: 'Untitled Topic',
    untitled_playlist: 'Untitled Playlist',
    untitled_course: 'Untitled Course',
    untitled_course_plan: 'Untitled Course Plan',
    and_others: 'and #: co_authors.length # others',
    views: 'Views',
    visibility: 'Visibility',
    visibility_public: 'Public',
    visibility_private: 'Private',
    visibility_unpublished: 'Unpublished',
    visibility_unlisted: 'Unlisted',
    deleted_contributions: 'Only deleted contributions',
    deleted_user: "Deleted user"
  },
  es: {
    action_evaluate: 'Evaluar',
    action_delete: 'Suprimir',
    action_edit: 'Corregir',
    action_rollout: 'Entregar',
    action_share: 'Compartir',
    action_restore: 'Restaurar',
    edit_contribution: 'Editar contribución',
    and: 'y',
    author: 'Creado por',
    bulk_change_message: '#= affected # # if (affected == 1) {#contribución fue modificada.#} else {#contribuciones fueron modificadas.#} #',
    bulk_delete_message: '#= affected # # if (affected == 1) {#se eliminó la contribución.#} else {#contribuciones fueron eliminadas.#} #',
    bulk_delete_confirm: '¿Está seguro de que desea eliminar las contribuciones de #= count #?',
    not_allowed_contents: 'No todos los contenidos se transfirieron al usuario. No tiene suficientes derechos.',
    change: 'Cambiar',
    comments: 'Comentarios',
    contains: 'Contiene',
    contribution: 'Contribución',
    created_at: 'Creado en',
    eq: 'inguales',
    expired: 'Vencido',
    expires_at: 'Válido hasta',
    featured: 'Featured',
    featured_ribbon: 'Featured',
    filter_featured_true: 'Sólo contribuciones destacadas',
    filter_featured_false: 'No hay contribuciones destacadas',
    is_collaborative_content: 'Contribuciones colaborativas',
    filter_is_collaborative_content_true: 'Sólo contribuciones colaborativas',
    filter_is_collaborative_content_false: 'No hay contribuciones colaborativas',
    filter_only_my_own: 'Sólo el mío',
    filter_private_true: 'Sólo contribuciones privadas',
    filter_private_false: 'No hay contribuciones privadas',
    filter_expired_true: 'Sólo las contribuciones vencidas',
    filter_expired_false: 'No hay contribuciones vencidas',
    find_users: 'Comience a escribir para encontrar usuarios',
    is_after_or_equal_to: 'Es igual o posterior a',
    is_before_or_equal_to: 'Es anterior o igual a',
    is_equal_to: 'Es igual a',
    inactive_contribution: 'Contribución inactiva',
    tags: 'Tags',
    updated_at: 'Actualizado en',
    topic_areas: 'Àrea temática',
    untitled_post: 'Mensaje sin título',
    untitled_topic: 'Tema sin título',
    untitled_playlist: 'Lista de reproducción sin título',
    untitled_course: 'Curso sin título',
    untitled_course_plan: 'Plan de curso sin título',
    and_others: 'y otros #: co_authors.length #',
    views: 'Vistas',
    visibility: 'Visibilidad',
    visibility_public: 'Público',
    visibility_private: 'Privado',
    visibility_unpublished: 'Inédito',
    visibility_unlisted: 'Sin cotización',
    deleted_contributions: 'Sólo contribuciones borradas',
    deleted_user: "Usuario eliminado"
  }
};

export function t(key) {
  return locals[_app.info.currentLocale][key] || key;
}

export function tt(key, variables = {}) {
  let template = kendo.template(t(key));
  return template(variables);
}
