type dimension = {
  width: number,
  height: number,
  ratio: number,
}

const dimensions: dimension[] = [
  {
    "width": 1920,
    "height": 1080,
    "ratio": 16 / 9,
  },
  {
    "width": 1600,
    "height": 1200,
    "ratio": 4 / 3,
  },
  {
    "width": 1280,
    "height": 720,
    "ratio": 16 / 9,
  },
  {
    "width": 960,
    "height": 720,
    "ratio": 4 / 3,
  },
  {
    "width": 800,
    "height": 600,
    "ratio": 4 / 3,
  },
  {
    "width": 640,
    "height": 480,
    "ratio": 4 / 3,
  },
  {
    "width": 640,
    "height": 360,
    "ratio": 16 / 9,
  },
  {
    "width": 352,
    "height": 288,
    "ratio": 4 / 3,
  },
  {
    "width": 320,
    "height": 240,
    "ratio": 4 / 3,
  },
  {
    "width": 176,
    "height": 144,
    "ratio": 4 / 3,
  },
  {
    "width": 160,
    "height": 120,
    "ratio": 4 / 3,
  },
];

export default dimensions;
