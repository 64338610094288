import { Chapter, ChapterItem } from "../entities";

export interface KeyValue<T> {
  [key: number]: T,
  [key: string]: T
}

export interface Entities {
  chapters?: KeyValue<Chapter>,
  chapterItems?: KeyValue<ChapterItem> 
}

export interface CoursePlanManageState {
  chapterList: EntityId[],
  addToChapter: EntityId,
  searchUrl: string,
  entities: Entities
}

export const state: CoursePlanManageState = {
  chapterList: [],
  addToChapter: null,
  searchUrl: null,
  entities: {
    chapters: {},
    chapterItems: {}
  }
}
