<template>
  <div class="question-list">
    <div class="question-list-top">
      <h3>
        {{ $t('film_questions.questions') }}
        <div class="save-status">
          <span class="save-status-saving" v-if="isSaving">
            <i class="material-icons icon-rotate-infinite">hourglass_empty</i> {{ $t('film_questions.saving') }}
          </span>
          <span class="save-status-saved" v-else-if="isSaved">
            <i class="material-icons">done</i> {{ $t('film_questions.saved') }}
          </span>
          <span :title="$t('film_questions.save_error_help')" class="save-status-error" v-else>
            <i class="material-icons">error_outline</i> {{ $t('film_questions.save_error') }}
          </span>
        </div>
      </h3>
      <button
        class="btn btn-primary btn-flat"
        :disabled="questions.length === 0"
        @click="openPreview">{{ $t('film_questions.preview') }}</button>
    </div>

    <ul class="film-question-list">
      <transition-group name="slide-fade">
        <question-list-item
          v-for="question in questions"
          :key="question.id"
          :question="question"
          :class="{ active: currentQuestion === question }"></question-list-item>
      </transition-group>
    </ul>

    <question-preview
      v-if="showPreview"
      :sources="[videoSrc]"
      :questions="previewQuestions"
      @close="onPreviewClosed"
      @ready="onPreviewReady">
    </question-preview>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Actions } from '../store';
import QuestionPreview from '../../course/manage/components/questions/preview';
import QuestionListItem from './question_list_item';

export default {
  components: {
    QuestionPreview,
    QuestionListItem
  },
  data() {
    return {
      showPreview: false,
      previewQuestions: []
    }
  },
  computed: {
    ...mapGetters({
      questions: 'filmQuestions/questionsByTime',
      videoSrc: 'filmQuestions/videoSrc',
      currentQuestion: 'filmQuestions/currentQuestion',
      isSaved: 'filmQuestions/isSaved',
      isSaving: 'filmQuestions/isSaving'
    })
  },
  methods: {
    openPreview() {
      this.previewQuestions = JSON.parse(JSON.stringify(this.questions));
      this.showPreview = true
    },
    onPreviewReady(videoPlayer) {
      videoPlayer.play();
    },
    onPreviewClosed() {
      this.showPreview = false
    }
  },
}
</script>

<style lang="scss" scoped>
.question-list-top {
  display: flex;

  > h3 {
    flex: 1 0 0;
  }
}

.film-question-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-leave-active {
  transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(24px);
  opacity: 0;
}

.save-status {
  display: inline-block;
  font-size: 14px;
  line-height: 26px;
  padding-left: 10px;
  vertical-align: top;

  .save-status-saved {
    color: $color-success-green;
  }

  .save-status-error {
    color: $color-error-red;
  }
}
</style>
