import Vue from 'vue';
import axios from 'axios';

export default {
  namespaced: true,
  state: {
    user: null,
    roles: [],
    taskStatusUrl: null,
    tutorialsUrl: null,
    activeTask: null,
    completedTasks: [],
    completedTasksUrl: null,
    hideTrainerTasks: false
  },
  actions: {
    setUser({ commit }, user) {
      commit('setUser', user);
    },
    setActive({ commit }, taskId) {
      commit('setActive', taskId);
    },
    setCompletedTasksUrl({ commit }, completedTasksUrl) {
      commit('setCompletedTasksUrl', completedTasksUrl);
    },
    currentRoles({ commit }, roles) {
      commit('currentRoles', roles);
    },
    refreshCompletedTasks({ state, commit }) {
      axios.get(state.completedTasksUrl).then((response) => {
        commit('setCompletedTasks', response.data.completed);
      });
    },
    hideTrainerTasks({ commit }) {
      commit('hideTrainerTasks');
    }
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setActive(state, taskId) {
      state.activeTask = taskId;
    },
    setCompletedTasks(state, completedTasks) {
      Vue.set(state, 'completedTasks', completedTasks);
    },
    setCompletedTasksUrl(state, completedTasksUrl) {
      state.completedTasksUrl = completedTasksUrl;
    },
    currentRoles(state, roles) {
      state.roles = roles;
    },
    hideTrainerTasks(state) {
      state.hideTrainerTasks = true;
    }
  },
  getters: {
    user(state) {
      return state.user && state.user.length > 0 ? state.user : null;
    },
    completedTasks(state) {
      return state.completedTasks;
    },
    activeTask(state) {
      return state.activeTask;
    },
    hasRole: (state) => (role) => {
      return state.roles.includes(role);
    },
    isCompleted: (state) => (taskId) => {
      return state.completedTasks.includes(taskId);
    },
    isActive: (state) => (taskId) => {
      return state.activeTask === taskId;
    },
    hideTrainerTasks(state) {
      return state.hideTrainerTasks;
    }
  }
};
