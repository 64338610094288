let localization = {
  'en': {
    'medium_added': 'A co author added a medium. The page needs to be reloaded',
    'medium_removed': 'A co author removed the medium. The page needs to be reloaded'
  },
  'de': {
    'medium_added': 'Ein Koautor hat ein Medium hinzugefügt. Die Seite muss neugeladen werden',
    'medium_removed': 'Ein Koautor hat das Medium entfernt. Die Seite muss neugeladen werden'
  },
  'es': {
    'medium_added': 'Un coautor ha añadido un medio. La página debe ser recargada',
    'medium_removed': 'Un coautor ha eliminado el medio. La página debe ser recargada'
  }
};

import { Action } from "../../system_actions/action";

/**
 * A MediumChangeAction represents changes to a topics medium.
 * Adding a film or document as a new medium or resetting the medium triggers MediumChangeActions
 */
export class MediumChangeAction extends Action {
  /**
   * @constructor
   * @param {String} tabId  - TabId of the tab the action originates from
   * @param {number} user - UserId of the chat room owner
   * @param {String} medium - Medium type: "film", "document" or "reset"
   */
  constructor(tabId, user, medium) {
    super(tabId, user);
    this.medium = medium;
    this.actionType = "MediumChangeAction";
  }

  execute() {
    let medium = this.medium;
    VERSTEHE.cable.disconnect();
    if (this.medium === "reset") {
      VERSTEHE.utils.showLoadingIndicator();
    } else {
      window.alert(localization[_app.info.currentLocale].medium_added);
      VERSTEHE.utils.showLoadingIndicator();
    }
    setTimeout(function() {
      window.location.hash = 'medium';
      window.location.reload();
    }, 500);
  }

  static fromJson(json) {
    let action = JSON.parse(json);
    return new MediumChangeAction(action.tabId, action.user, action.medium);
  }

  static sendMediumChangeAction(medium) {
    let action = new MediumChangeAction(MediumChangeAction.getTabId(), _app.info.user.userId, medium);
    VERSTEHE.content_channel.send_message(action);
  }
}
