import Vue from 'vue';
import ChangeHistoryApp from './components/change_history_app.vue';

export let changeHistoryApp: Vue = null;

export function init() {
  const element = document.getElementById('v-change-history');
  if (element) {
    const url = element.dataset.change_history_entries_url;
    const initialData = {
      url: url,
    };

    changeHistoryApp = new Vue ({
      name: 'ChangeHistoryApp',
      el: '#v-change-history',
      i18n: VERSTEHE.vueI18n,
      provide: {
        initialData: initialData
      },
      $_veeValidate: {
        validator: 'new'
      },
      render: h => h(ChangeHistoryApp)
    });
  }
}

