<template>
  <div>
    <div class="form-group">
      <label class="control-label">{{ $t('user_import.import_action') }}</label>
      <select ref="importActionSelect" class="user-import-action-select" :value="importAction">
        <option value="create">{{ $t('user_import.import_action_create') }}</option>
        <option value="update">{{ $t('user_import.import_action_update') }}</option>
        <option value="create_or_update">{{ $t('user_import.import_action_create_or_update') }}</option>
      </select>
    </div>

    <div class="form-group">
      <div class="checkbox">
        <label>
          <input type="checkbox" v-model="containsHeadings"> {{ $t('user_import.contains_headings') }}
        </label>
      </div>
      <div class="help-block">{{ $t('user_import.contains_headings_help') }}</div>
    </div>

    <button class="btn btn-primary" :class="{ disabled: saving }" @click="saveSettings">{{ $t('user_import.save_settings') }}</button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Actions } from '../store';

export default {
  computed: {
    ...mapGetters({
      saving: 'userImport/saving',
      importAction: 'userImport/importAction'
    }),
    containsHeadings: {
      get() {
        return this.$store.state.userImport.containsHeadings;
      },
      set(value) {
        this.$store.commit('userImport/UPDATE_CONTAINS_HEADINGS', value);
      }
    }
  },
  methods: {
    ...mapActions({
      saveSettings: Actions.SAVE_SETTINGS
    })
  },
  mounted() {
    $.material.init();
    $(this.$refs.importActionSelect).kendoDropDownList({
      autoWidth: false,
      change: (event) => {
        const value = event.sender.value();
        this.$store.commit('userImport/UPDATE_IMPORT_ACTION', value);
      }
    });
  }
}
</script>

<style lang="scss" scoped>
.form-group {
  .checkbox {
    margin-bottom: 24px;

    label {
      font-size: 14px;
      color: $black-54-opacity;
    }
  }

  &.is-focused {
    .checkbox label:hover {
      color: $black-87-opacity;
    }

    .help-block {
      display: block;
    }
  }

  .help-block {
    position: absolute;
    display: none;
    bottom: 0;
    left: 40px;
    height: 12px;
    line-height: 12px;
  }
}
</style>

<style lang="scss">
.user-import-action-select {
  display: block;
  width: 50%;
  margin-bottom: 24px;
}
</style>
