<template>
  <div>
    <h4 v-if="!completed">{{ $t('user_import.import_process_running') }} ... {{ importProgress }}%</h4>
    <h4 v-else>
      {{ $t('user_import.import_completed') }}
      <i class="material-icons text-success">check</i>
    </h4>

    <div class="progress">
      <div class="progress-bar" :style="{ width: `${importProgress}%` }"></div>
    </div>
    <span v-if="total > 0">{{ $t('user_import.import_progress', { total: total, processed: processedCount }) }}</span>

    <div class="import-asynchronous-notice">
      <i class="material-icons">hourglass_empty</i>
      <span>{{ $t('user_import.asynchronous_notice') }}</span>
    </div>

    <div class="import-progress">
      <div class="import-progress-stats">
        <div class="import-progress-stat">
          <span>{{ created }}</span>
          <label>{{ $t('user_import.stat_created') }}</label>
        </div>
        <div class="import-progress-stat">
          <span>{{ unchanged }}</span>
          <label>{{ $t('user_import.stat_unchanged') }}</label>
        </div>
        <div class="import-progress-stat">
          <span>{{ updated }}</span>
          <label>{{ $t('user_import.stat_updated') }}</label>
        </div>
        <div class="import-progress-stat">
          <span>{{ invalid }}</span>
          <label>{{ $t('user_import.stat_invalid') }}</label>
        </div>
      </div>
    </div>

    <a :href="reportUrl" class="btn btn-primary show-report-btn" :class="{ disabled: !completed }">{{ $t('user_import.show_report') }}</a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data: () => {
    return {
      subscription: null,
      completed: false,
      total: 0,
      created: 0,
      updated: 0,
      unchanged: 0,
      invalid: 0
    }
  },
  computed: {
    ...mapGetters({
      info: 'userImport/info',
      id: 'userImport/id',
      reportUrl: 'userImport/reportUrl'
    }),
    processedCount() {
      return this.created + this.updated + this.unchanged + this.invalid;
    },
    importProgress() {
      if (this.total > 0) {
        return Math.ceil((this.processedCount / this.total) * 100);
      } else {
        return 0;
      }
    }
  },
  mounted() {
    this.subscription = VERSTEHE.cable.subscriptions.create({
      channel: 'UserImportJobChannel',
      id: this.id
    }, {
      received: (data) => {
        this.completed = data.completed;
        this.total = data.total;
        this.created = data.created;
        this.updated = data.updated;
        this.unchanged = data.unchanged;
        this.invalid = data.invalid;
      }
    });
  },
  beforeDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
</script>

<style lang="scss" scoped>
.import-progress {
  margin: 24px 0;
}

.import-progress-stats {
  display: flex;
  flex-wrap: wrap;
  width: 400px;
  margin: 0 auto;
}

.import-progress-stat {
  padding: 10px;
  width: 200px;

  > span {
    display: block;
    font-size: 32px;
    text-align: center;
  }

  > label {
    display: block;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
  }
}

.progress {
  margin: 0 0 8px 0;
}

.show-report-btn {
  display: block;
  margin: 0;
}

.import-asynchronous-notice {
  display: flex;
  align-items: center;
  margin-top: 24px;
  padding: 15px;
  background-color: $color-light-blue-grey;

  i {
    margin-right: 10px;
    font-size: 24px;
    color: var(--primary-color);
  }
}
</style>
