export const videoFormats = [
  ".3G2",
  ".3GP",
  ".ASF",
  ".ASX",
  ".AVI",
  ".BUP",
  ".FLV",
  ".IFO",
  ".IMOVIEPROJ",
  ".MOV",
  ".MP4",
  ".MPG",
  ".MTS",
  ".NSV",
  ".OGV",
  ".RM",
  ".SWF",
  ".TS",
  ".VOB",
  ".WLMP",
  ".WMV",
  ".WTV",
  ".AAF",
  ".AVP",
  ".AVS",
  ".DIVX",
  ".EDL",
  ".ENC",
  ".FCP",
  ".FCPROJECT",
  ".HDMOV",
  ".M2P",
  ".M2T",
  ".M4V",
  ".MOD",
  ".MOI",
  ".MP4V",
  ".MPEG",
  ".MPG2",
  ".MSWMM",
  ".PGI",
  ".PLB",
  ".PPJ",
  ".PRPROJ",
  ".R3D",
  ".SPL",
  ".SQZ",
  ".SUB",
  ".THM",
  ".TOD",
  ".VCR",
  ".VSP",
  ".WEBM",
  ".3MM",
  ".3P2",
  ".AEC",
  ".AEP",
  ".AEPX",
  ".AJP",
  ".AMV",
  ".ARF",
  ".ASD",
  ".AVB",
  ".AVD",
  ".AX",
  ".AXM",
  ".BDMV",
  ".BIX",
  ".BOX",
  ".BPJ",
  ".CINE",
  ".CPI",
  ".DDAT",
  ".DMB",
  ".DM_84",
  ".DREAM",
  ".DV",
  ".DV2",
  ".DVR",
  ".DVR-MS",
  ".EVO",
  ".F4V",
  ".FBR",
  ".FBZ",
  ".FLC",
  ".FLI",
  ".GTS",
  ".IMOVIEPROJECT",
  ".IRCP",
  ".IVR",
  ".M2TS",
  ".M2V",
  ".MBT",
  ".MBV",
  ".MJ2",
  ".MK3D",
  ".MKV",
  ".MNV",
  ".MOCHA",
  ".MOFF",
  ".MSDVD",
  ".MVP",
  ".MXF",
  ".OGX",
  ".PDS",
  ".PMF",
  ".PRTL",
  ".PSH",
  ".PVR",
  ".QT",
  ".QTL",
  ".RATDVD",
  ".RMS",
  ".ROQ",
  ".RPF",
  ".RV",
  ".SMK",
  ".TP0",
  ".UDP",
  ".VEG",
  ".VGZ",
  ".VVF",
  ".WMMP",
  ".WP3",
  ".XFL",
  ".XVID",
  ".264",
  ".AM4",
  ".CVC",
  ".DKD",
  ".DLX",
  ".DSM",
  ".DVM",
  ".IRF",
  ".IRF",
  ".MZT",
  ".PNS",
  ".SLC",
  ".USM",
  ".VFT",
  ".DAV"
];

export const audioFormats = [
  ".MP3",
  ".WAV"
];
