import { flashMessage } from '../mixins/flash_message';

const locals = {
  de: {
    url_copied: 'Die URL wurde zu Ihrer Zwischenablage hinzugefügt.',
    url_not_copied: 'Die URL konnte nicht zu Ihrer Zwischenablage hinzugefügt werden.'
  },
  en: {
    url_copied: 'The URL has been added to your clipboard.',
    url_not_copied: 'The URL could not be added to your clipboard.'
  },
  es: {
    url_copied: 'La URL se ha añadido al portapapeles.',
    url_not_copied: 'La URL no se puede añadir al portapapeles.'
  }
};

export class CopyToClipboardInput {
  constructor(element, readonly = true) {
    this.readonly = readonly;
    this.input = element;
    this.button = element.parentNode.querySelector('.copy-to-clipboard');

    this.initialize();
  }

  initialize() {
    if (this.readonly) {
      this.input.readOnly = true;
    }

    this.input.addEventListener('click', () => this.selectAll());

    if (this.button) {
      this.button.addEventListener('click', (e) => this.copyToClipboard(e));
    }
  }

  selectAll() {
    this.input.select();
  }

  copyToClipboard(event) {
    const clipboard = new Clipboard('.null', {
      text: () => this.input.value
    });

    clipboard.on('success', (e) => {
      flashMessage(locals[_app.info.currentLocale]['url_copied']);
    });

    clipboard.on('error', (e) => {
      flashMessage(locals[_app.info.currentLocale]['url_not_copied']);
    });

    clipboard.onClick(event);
  }
}
