<template>
<li :class="{ active: isActive }">
  <div class="lesson-missing" :style="{ width: `${100 - progress}%` }"></div>
  <router-link :to="`/lesson/${lesson.id}`" class="lesson">
    <div class="lesson-type">
      <i class="coovi-icon">{{ lessonTypeIcon }}</i>
    </div>
    <div class="lesson-title">{{ lessonNumber }}. {{ lesson.title }}</div>
    <div v-if="lesson.processingTime" class="lesson-duration">{{ lesson.processingTime }}</div>
    <div class="lesson-progress">
      <i v-if="isLessonCompleted" class="material-icons">check</i>
    </div>
  </router-link>

  <collapse-transition>
    <playlist-elements
      :lesson="lesson"
      v-if="isActive && lesson.type === 'Course::Lesson::PlaylistLesson'">
    </playlist-elements>
  </collapse-transition>
</li>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('coursePerform');
import { CollapseTransition } from 'vue2-transitions'

import PlaylistElements from './playlist_elements.vue';

export default {
  name: 'lesson-item',
  props: {
    lesson: { required: true }
  },
  components: {
    CollapseTransition,
    PlaylistElements
  },
  computed: {
    ...mapGetters([
      'lessonGroups',
      'lessonById',
      'currentLesson',
      'lessonProgress'
    ]),
    isActive() {
      return this.currentLesson === this.lesson;
    },
    lessonTypeIcon() {
      return this.lesson.type.split('::').pop().split(/(?=[A-Z])/).join('_').toLowerCase();
    },
    isLessonCompleted() {
      return this.progress === 100;
    },
    progress() {
      return Math.round(this.lessonProgress(this.lesson) * 100)
    },
    lessonNumber() {
      const lessonGroups = this.lessonGroups;
      let number = 1;

      for (let i = 0; i < lessonGroups.length; i++) {
        const index = lessonGroups[i].lessons.indexOf(this.lesson);

        if (index >= 0) {
          number += index;
          break;
        } else {
          number += lessonGroups[i].lessons.length;
        }
      }

      return number;
    }
  },
  methods: {
    ...mapActions([
      'checkCompleted'
    ])
  },
  watch: {
    isLessonCompleted(isCompleted) {
      if (isCompleted) {
        // Since the server progress is updated via Beacon-API and thus cannot be waited for,
        // we delay the completion check by 1s.
        setTimeout(this.checkCompleted, 1000);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
li {
  position: relative;

  .lesson {
    position: relative;
    z-index: 1;
  }

  .lesson-missing {
    position: absolute;
    top: 0;
    right: 0;
    height: 45px;
    background-color: $color-white-20;
    transition: width .2s linear;
  }
}
</style>
