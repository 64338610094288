import { SelfieSegmentation, Results } from '@mediapipe/selfie_segmentation';
import stopTracks from '../stop_tracks';
import type { BackgroundReplacement } from './types/background_replacement';
import { BACKGROUND_IMAGE_URLS, BACKGROUND_IMAGES } from './constants/background_images';
import { DEBUG_CALCULATE_FPS, SELFIE_SEGMENTATION_FPS } from './constants/configuration';
import { updateWebcamStreamResolution } from '../../capture/utils/user_media';
import includes from '../includes_readonly_array';
import { CustomBackgroundImage } from './types/custom_background_image';
import { defer } from '../defer';

type Mode = 'BLUR_SOFT' | 'BLUR' | 'REPLACE' | 'TRANSPARENT';

export default class SelfieSegmentationStream {
  private readonly useTrackProcessor: boolean = false;

  private readonly streamVideoEl: HTMLVideoElement;
  private readonly canvas: HTMLCanvasElement;
  private readonly ctx: CanvasRenderingContext2D;
  private readonly selfieSegmentation: SelfieSegmentation;

  private initializePromise?: Promise<boolean>;
  private requestAnimationFrameId?: number;
  private trackProcessorSourceTrack?: MediaStreamVideoTrack;
  private busy = false;
  private stopped = false;
  private paused = false;
  private blurMaskIntensity: number = 0;
  private blurMaskFix: number = 0;

  private mode?: Mode;
  private img?: ImageBitmap;
  private imgPosition = {
    width: 0,
    height: 0,
    sourceX: 0,
    sourceY: 0,
  };
  private firstFramePromise: Deferred<void>;

  private fps: number = 0;
  private fpsStartTime: number = performance.now();

  constructor(private getCustomBackgroundImage: (name: string) => CustomBackgroundImage) {
    if ('MediaStreamTrackProcessor' in window && 'MediaStreamTrackGenerator' in window) {
      this.useTrackProcessor = true;
    }

    this.streamVideoEl = document.createElement('video');
    this.canvas = document.createElement('canvas');
    this.ctx = this.canvas.getContext('2d')!;

    this.streamVideoEl.onresize = () => {
      this.canvas.width = this.streamVideoEl.videoWidth;
      this.canvas.height = this.streamVideoEl.videoHeight;
      this.blurMaskIntensity = Math.ceil((this.canvas.height * 0.0037) * 10) / 10;
      this.blurMaskFix = this.blurMaskIntensity * 2.5;
      this.calculateBackgroundImagePosition();
      this.selfieSegmentation.reset();
    };

    this.selfieSegmentation = new SelfieSegmentation({
      locateFile: (file) => {
        return `/assets/selfie-segmentation/${file}`;
      },
    });

    this.selfieSegmentation.setOptions({
      modelSelection: 0, // general=0; landscape=1
      selfieMode: false,
    });
    this.selfieSegmentation.onResults(this.render);
  }

  private get stream(): MediaStream {
    const stream = this.canvas.captureStream();
    const originalStream = this.streamVideoEl.srcObject as MediaStream;
    // required to be able to change the dimensions of the webcam stream by StreamInStream class
    stream.setHeight = (height: number) => {
      updateWebcamStreamResolution(originalStream, height);
    };

    stream.firstFrameReady = this.firstFramePromise = defer();

    originalStream.getVideoTracks()[0].addEventListener('ended', () => {
      stopTracks(stream, true);
    });

    return stream;
  }

  public init = (): Promise<boolean> => {
    if (this.initializePromise) {
      return this.initializePromise;
    }

    this.initializePromise = this.selfieSegmentation.initialize()
      .then(() => {
        return true;
      }).catch((e) => {
        console.error('could not initialize selfie segmentation', e);
        this.initializePromise = undefined;
        return false;
      });

    return this.initializePromise;
  };

  public async start(stream: MediaStream, background: BackgroundReplacement, selfieMode: boolean = false): Promise<MediaStream | null> {
    const { streamVideoEl } = this;

    const initialized = await this.init();

    if (!initialized) {
      return null;
    }

    this.stop();
    this.setBackground(background);
    this.setSelfieMode(selfieMode);

    stream.getVideoTracks()[0].addEventListener('ended', () => this.stop());

    streamVideoEl.srcObject = stream;

    await streamVideoEl.play();

    this.stopped = false;

    if (this.useTrackProcessor) {
      this.trackProcessorSourceTrack = stream.getVideoTracks()[0]!.clone();

      const trackProcessor = new MediaStreamTrackProcessor({ track: this.trackProcessorSourceTrack, maxBufferSize: 1 });
      const reader = trackProcessor.readable.getReader();

      let start = performance.now();
      const delay = 1000 / SELFIE_SEGMENTATION_FPS;
      const loop = async () => {
        const { done, value } = await reader.read();

        const current = performance.now();
        const delta = current - start;

        if (delta >= delay) {
          this.send();
          start = current - (delta - delay);
        }
        value?.close();

        if (!done) {
          loop();
        }
      };

      loop();
    } else {
      this.requestInterval(this.send, 1000 / SELFIE_SEGMENTATION_FPS);
    }

    return this.stream;
  }

  public setBackground = (background: BackgroundReplacement): void => {
    if (!background) {
      return;
    }

    if (background === 'BLUR_SOFT') {
      this.mode = 'BLUR_SOFT';
      this.img = undefined;
    } else if (background === 'BLUR') {
      this.mode = 'BLUR';
      this.img = undefined;
    } else if (background === 'TRANSPARENT') {
      this.mode = 'TRANSPARENT';
      this.img = undefined;
    } else if (includes(BACKGROUND_IMAGES, background)) {
      this.mode = 'REPLACE';
      fetch(BACKGROUND_IMAGE_URLS[background].original).then((res) => {
        if (!res.ok) {
          throw res; // ToDo: ErrorHandling
        }

        return res.blob();
      }).then((blob) => {
        return createImageBitmap(blob, {
          premultiplyAlpha: 'none',
          colorSpaceConversion: 'none',
        });
      }).then((img) => {
        this.img = img;
        this.calculateBackgroundImagePosition();
      }).catch((e) => {
        console.error('load background image failed:', e);
        this.img = undefined;
      });
    } else {
      this.mode = 'REPLACE';

      const backgroundImage = this.getCustomBackgroundImage(background);

      if (backgroundImage) {
        createImageBitmap(backgroundImage.image, {
          premultiplyAlpha: 'none',
          colorSpaceConversion: 'none',
        }).then((img) => {
          this.img = img;
          this.calculateBackgroundImagePosition();
        });
      }
    }
  };

  private calculateBackgroundImagePosition = () => {
    const { canvas, img } = this;
    if (img) {
      const fillMaxHeight = canvas.width / canvas.height <= img.width / img.height;

      this.imgPosition = {
        width: img.width,
        height: img.height,
        sourceX: 0,
        sourceY: 0,
      };

      if (fillMaxHeight) {
        this.imgPosition.width = img.height * (canvas.width / canvas.height);
        this.imgPosition.sourceX = (img.width - this.imgPosition.width) / 2;
      } else {
        this.imgPosition.height = img.width * (canvas.height / canvas.width);
        this.imgPosition.sourceY = (img.height - this.imgPosition.height) / 2;
      }
    }
  };

  public setSelfieMode = (active: boolean): void => {
    this.selfieSegmentation.setOptions({
      selfieMode: active,
    });
  };

  public play = () => {
    this.paused = false;

    if (DEBUG_CALCULATE_FPS) {
      this.fpsStartTime = performance.now();
      this.fps = 0;
    }
  };
  public pause = () => {
    this.paused = true;
  };

  public stop() {
    this.stopped = true;

    if (this.useTrackProcessor) {
      this.trackProcessorSourceTrack?.stop();
      this.trackProcessorSourceTrack = undefined;
    } else {
      window.cancelAnimationFrame(this.requestAnimationFrameId);
    }

    stopTracks(this.streamVideoEl.srcObject as MediaStream | null);

    this.selfieSegmentation.reset();
  }

  private send = (): void => {
    if (this.busy || this.stopped || this.paused) {
      return;
    }

    this.busy = true;
    this.selfieSegmentation?.send({ image: this.streamVideoEl });
  };

  private requestInterval(fn: () => void, delay: number): void {
    let start = performance.now();

    const loop = (): void => {
      const current = performance.now();
      const delta = current - start;

      if (delta >= delay) {
        fn();
        start = current - (delta - delay);
      }

      this.requestAnimationFrameId = window.requestAnimationFrame(loop);
    };

    this.requestAnimationFrameId = window.requestAnimationFrame(loop);
  }

  private render = (results: Results): void => {
    const { ctx, canvas, mode, img, imgPosition } = this;

    ctx.save();
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // draw only the detected body/face
    ctx.filter = `blur(${this.blurMaskIntensity}px)`;
    ctx.drawImage(results.segmentationMask, 0, 0, canvas.width, canvas.height);
    ctx.filter = `none`;

    // the blur filter results in transparent edges, because the detected segmentation always fills the bottom edge
    // this adds the original non blurred segmentation mask to the bottom 7 pixels (just a dirty fix till a better solution is found)
    ctx.drawImage(results.segmentationMask, 0, results.segmentationMask.height - 1 - this.blurMaskFix, results.segmentationMask.width, this.blurMaskFix, 0, results.segmentationMask.height - 1 - this.blurMaskFix, canvas.width, canvas.height);

    ctx.filter = `none`;
    ctx.globalCompositeOperation = 'source-in';
    ctx.drawImage(results.image, 0, 0, canvas.width, canvas.height);

    // draw background
    ctx.globalCompositeOperation = 'destination-over';

    if (mode === 'BLUR_SOFT' || mode === 'BLUR') {
      ctx.filter = `blur(${mode === 'BLUR_SOFT' ? 5 : 10}px)`;// ToDo: create CONSTANT ?
      ctx.drawImage(results.image, 0, 0, canvas.width, canvas.height);

      // render image behind blurred image to prevent transparent pixel at canvas edges
      ctx.filter = `none`;
      ctx.drawImage(results.image, 0, 0, canvas.width, canvas.height);
    } else if (mode === 'TRANSPARENT') {
    } else if (img) {
      ctx.drawImage(img, imgPosition.sourceX, imgPosition.sourceY, imgPosition.width, imgPosition.height, 0, 0, canvas.width, canvas.height);
    } else {
      // fallback if image couldn't be loaded
      ctx.fillStyle = 'rgb(52,52,52)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    }

    ctx.restore();
    this.busy = false;

    if (this.firstFramePromise) {
      const toResolve = this.firstFramePromise;
      this.firstFramePromise = null;

      // timeout allows stream to receive first image before resolving the promise
      setTimeout(() => {
        toResolve.resolve();
      }, 0);
    }

    if (DEBUG_CALCULATE_FPS) {
      this.fps += 1;

      const delta = performance.now() - this.fpsStartTime;
      if (delta >= 1000) {
        const factor = delta / 1000;
        const actualFps = Math.floor(this.fps * factor);
        console.log('[SSS] FPS:', actualFps, this.fps - actualFps);
        this.fps = 0;
        this.fpsStartTime = performance.now();
      }
    }
  };
}
