var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"participant"},[_c('div',{staticClass:"participantImage",style:({ backgroundImage: 'url(' + this.metadata.avatar + ')' })}),_vm._v(" "),_c('div',{staticClass:"participantName"},[_vm._v("\n    "+_vm._s(_vm.displayName)+"\n    "),(_vm.showIsPresentingText())?_c('p',[_vm._v(_vm._s(_vm.$t('web_conference.user_is_presenting')))]):_vm._e(),_vm._v(" "),(
        this.metadata.recordingPermission &&
        this.settings.askForRecordingPermission &&
        this.settings.recordingAllowed &&
        (this.localMetadata.scope == _vm.ScopeType.ADMIN || this.localMetadata.scope == _vm.ScopeType.CREATOR)
      )?_c('p',[_vm._v("\n      "+_vm._s(_vm.$t('web_conference.recording_allowed'))+"\n    ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"participantRole"},[(this.metadata.scope == _vm.ScopeType.CREATOR)?_c('i',{staticClass:"material-icons small",attrs:{"title":_vm.$t('web_conference.creator_tooltip')}},[_vm._v("star")]):_vm._e(),_vm._v(" "),(this.metadata.scope == _vm.ScopeType.ADMIN)?_c('i',{staticClass:"material-icons small",attrs:{"title":_vm.$t('web_conference.admin_tooltip')}},[_vm._v("edit")]):_vm._e(),_vm._v(" "),(this.metadata.scope == _vm.ScopeType.SPECTATOR)?_c('i',{staticClass:"material-icons small",attrs:{"title":_vm.$t('web_conference.spectator_tooltip')}},[_vm._v("person")]):_vm._e(),_vm._v(" "),(this.metadata.scope == _vm.ScopeType.PRESENTATOR)?_c('i',{staticClass:"material-icons small",attrs:{"title":_vm.$t('web_conference.presentator_tooltip')}},[_vm._v("tv")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"statusOfSpeaking"},[(_vm.showMuted)?_c('i',{staticClass:"material-icons small deactive"},[_vm._v("mic_off")]):_c('i',{staticClass:"material-icons small active"},[_vm._v("mic")])]),_vm._v(" "),(_vm.shouldShowMenu)?_c('v-menu',{attrs:{"bottom":"","offset-y":"","content-class":"action-menu"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('i',_vm._g(_vm._b({staticClass:"material-icons small more-icon",attrs:{"id":_vm.user.identity}},'i',attrs,false),on),[_vm._v("more_vert")])]}}],null,false,1640721264),model:{value:(_vm.menuActivated),callback:function ($$v) {_vm.menuActivated=$$v},expression:"menuActivated"}},[_vm._v(" "),_c('v-list',[(this.metadata.inWaitingRoom == true)?_c('v-list-item',{ref:"",attrs:{"id":_vm.user.identity},on:{"click":function($event){return _vm.acceptGuest()}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('web_conference.user_accept')))])],1):_vm._e(),_vm._v(" "),(_vm.canSetUserAsPresentator)?_c('v-list-item',{attrs:{"id":_vm.user.identity},on:{"click":function($event){return _vm.setUserAsPresentator()}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('web_conference.user_set_presentator')))])],1):_vm._e(),_vm._v(" "),(_vm.canSetUserAsDefault)?_c('v-list-item',{attrs:{"id":_vm.user.identity},on:{"click":function($event){return _vm.setUserToDefault()}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('web_conference.user_remove_presentator')))])],1):_vm._e(),_vm._v(" "),(_vm.canMuteParticipant)?_c('v-list-item',{attrs:{"id":_vm.user.identity},on:{"click":function($event){return _vm.muteParticipant()}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('web_conference.user_mute')))])],1):_vm._e(),_vm._v(" "),_c('v-list-item',{attrs:{"id":_vm.user.identity},on:{"click":function($event){return _vm.kickParticipant()}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('web_conference.user_kick')))])],1)],1)],1):_c('div',{staticClass:"placeholder"}),_vm._v(" "),(this.metadata.raisesHand == true)?_c('div',{staticClass:"raisedHand"},[_c('i',{staticClass:"material-icons small"},[_vm._v("pan_tool")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }