import { FilterColumn } from './filter_column';
import { BaseFilter } from '../../grid/filter/base_filter';
import { userIsDeleted, unconfirmedUsers, usersWithAccess, usersWithoutAccess } from './preset_filters';
import { BaseCustomMetaDataTree } from '../../grid/filter/base_custom_meta_data_tree';

export class Filter extends BaseFilter {
  constructor (grid) {
    super(grid);
    this.treeFilters = [];
    this.initTreeFilter();
  }

  /**
   * initalize all custom meta data filter
   * and push the filter to the property this.treeFilters
   */
  initTreeFilter () {
    this.grid.grid.columns.forEach((column) => {
      if ( column.filterable && column.type === 'tree') {
        this.treeFilters.push(
          new BaseCustomMetaDataTree(`${column.field}_tree` , column.field, this.grid, column.treeType)
        );
      }
    });
  }

  /**
  * Initialize FilterColumn object, based of configured kendoUI grid columns.
  */
  prepareFilterableColumns () {
    this.grid.grid.columns.forEach(column => {
      if (column.filterable) {
        let filterColumn = new FilterColumn(column, this);
        filterColumn.attach(this.input);

        this.filterableColumns.push(filterColumn);
      }
    });
  }

  /**
   * Override to set default filter column
   */
  updateFieldSelect () {
    super.updateFieldSelect('any_name');
  }

  /**
   * Append preset filters to field select dropdown
   * @param {DOMObject} dropdown Dropdown element
   */
  buildPresetFilters (dropdown) {
    if (this.inactiveFilters().length > 0 || this.input.value.length > 0) {
      dropdown.appendChild(document.createElement('hr'));
    }

    // add preset filters to dropdown
    dropdown.appendChild(unconfirmedUsers(this));
    dropdown.appendChild(userIsDeleted(this));

    if (this.grid.isPrivateSite) {
      dropdown.appendChild(usersWithAccess(this));
      dropdown.appendChild(usersWithoutAccess(this));
    }
  }
}
