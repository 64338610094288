import { render, staticRenderFns } from "./background_select.vue?vue&type=template&id=59f0dcb6&scoped=true&"
import script from "./background_select.vue?vue&type=script&lang=ts&"
export * from "./background_select.vue?vue&type=script&lang=ts&"
import style0 from "./background_select.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./background_select.vue?vue&type=style&index=1&id=59f0dcb6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59f0dcb6",
  null
  
)

export default component.exports