import { FilterColumn } from './filter_column';
import { BaseFilter } from '../../../grid/filter/base_filter';
import { onlyMyOwnFilter, onlyDefaultsFilter } from './preset_filters';

export class Filter extends BaseFilter {
  constructor (grid) {
    super(grid);
  }

  /**
  * Initialize FilterColumn object, based of configured kendoUI grid columns.
  */
  prepareFilterableColumns () {
    this.grid.grid.columns.forEach(column => {
      if (column.filterable) {
        let filterColumn = new FilterColumn(column, this);
        filterColumn.attach(this.input);

        this.filterableColumns.push(filterColumn);
      }
    });
  }

  /**
   * Append preset filters to field select dropdown
   * @param {DOMObject} dropdown Dropdown element
   */
  buildPresetFilters (dropdown) {
    if (this.inactiveFilters().length > 0 || this.input.value.length > 0) {
      dropdown.appendChild(document.createElement('hr'));
    }

    let onlyMyOwn = onlyMyOwnFilter(this);
    dropdown.appendChild(onlyMyOwn);

    let onlyDefaults = onlyDefaultsFilter(this);
    dropdown.appendChild(onlyDefaults);
  }
}
