import Vue from 'vue';
import store from '../../store';
import { CoursePlanManageModule } from './store';
import App from './components/app.vue';
import { Actions } from './store/actions';
import { normalize } from 'normalizr';
import { baseSchema } from './entities';
import Utils from './utils';
import { initQualificationForm } from './qualification_form';
import { AnalyseCustomFilter } from './analyse_custom_filter';

export let vueApp: Vue = null;

let form: HTMLFormElement = null;
let saveButton: HTMLButtonElement = null;
let topActionsMessage: HTMLElement = null;
let analyseCustomGroup: AnalyseCustomFilter = null;

export function init() {
  const baseElementSelector = '#v-course-plan-manage';
  const baseElement = document.querySelector(baseElementSelector);
  const analyseCustomGroupButton = document.querySelector('#analyse-custom-target-group');

  if (analyseCustomGroupButton) {
    analyseCustomGroup = new AnalyseCustomFilter();
  }

  if (!baseElement) {
    return;
  }

  initQualificationForm();
  
  const initialStructure = JSON.parse(baseElement.getAttribute('data-initial-structure'));
  const searchUrl = baseElement.getAttribute('data-search-url');

  form = document.querySelector('.form-course-plan');
  topActionsMessage = document.querySelector('.top-actions-message');
  initSaveButton();

  store.registerModule('coursePlanManage', CoursePlanManageModule);
  
  let normalized = normalize(initialStructure, baseSchema);
  store.dispatch(Actions.UPDATE_ENTITIES, normalized.entities);
  store.dispatch(Actions.SET_CHAPTER_LIST, normalized.result);
  store.dispatch(Actions.SET_SEARCH_URL, searchUrl);

  if (normalized.result.length === 0) {
    store.dispatch(Actions.NEW_CHAPTER);
  }

  vueApp = new Vue ({
    name: 'CoursePlanManage',
    el: baseElementSelector,
    store: store,
    i18n: VERSTEHE.vueI18n,
    render: h => h(App)
  });
}

function initSaveButton() {
  saveButton = document.querySelector('#save-course-plan');
  saveButton.addEventListener('click', (e) => {
    vueApp.$validator.validate().then(valid => {
      if (valid) {
        appendChapterData();
        form.submit();
      }      
    })
  });
}

export function disableSave() {
  saveButton.classList.add('disabled');
}

export function enableSave() {
  saveButton.classList.remove('disabled');
}

export function showTopActionsMessage(message: string) {
  topActionsMessage.innerHTML = message;
}

export function clearTopActionsMessage() {
  topActionsMessage.innerHTML = '';
}

export function appendChapterData() {
  const chapters = JSON.stringify(Utils.serializeChapters());
  let chaptersInput = document.createElement('input');
  chaptersInput.type = 'hidden';
  chaptersInput.name = 'course_plan[chapters_attributes]';
  chaptersInput.value = chapters;
  form.appendChild(chaptersInput);
}
