export class Preferences {
  constructor () {
    this.registerEventListeners();
  }

  /**
   *  Register all EventListeners on page load
   */
  registerEventListeners () {
    this.registerEventListenerSavePreferences();
    this.initNotificationCategory();
    this.registerEventListenerDeactivateAllEmailNotifications();
  }

  /**
   * Select the general tab category.
   */
  initNotificationCategory () {
    document.querySelector('#notification-category-title-general').classList.add('active');
    document.querySelector('#notification-category-general').classList.add('in', 'active');
  }

  /**
   * Register EventListener for submitting the preference form.
   */
  registerEventListenerSavePreferences () {
    document.querySelector('#save-preferences').addEventListener('click', () => {
      document.querySelector('#preference-form').submit();
    });
  }

  /**
   * EventListener for deactivating all email notifications. 
   * If the EventListener change the state to false, all E-Mail notification will be deactivated.
   * The states of the previous selection will be stored for reactivating.
   */
  registerEventListenerDeactivateAllEmailNotifications () {
    this.dumpEmailNotificationToggles = [];
    [].forEach.call(this.allEmailNotificationToggles, e => {
      this.dumpEmailNotificationToggles.push([e.name, e.checked]);
    });

    this.findByName('preferences[disable_mail_notifications]').addEventListener('change', (value) => {
      if (value.srcElement.checked) {
        this.dumpEmailNotificationToggles = [];
        [].forEach.call(this.allEmailNotificationToggles, element => {
          this.dumpEmailNotificationToggles.push([element.name, element.checked]);
          element.checked = false;
        });
      } else {
        [].forEach.call(this.dumpEmailNotificationToggles, (element) => {
          this.findByName(element[0]).checked = element[1];
        });
      }
    });
    [].forEach.call(this.allEmailNotificationToggles, (element) => {
      element.addEventListener('change', (change) => {
        if (change.srcElement.checked === true) {
          this.findByName('preferences[disable_mail_notifications]').checked = false;
        }
      });
    });
  }

  get allSystemNotificationToggles () {
    return document.querySelectorAll('[name*="preferences[system_notifications]"]');
  }

  get allEmailNotificationToggles () {
    return document.querySelectorAll('[name*="preferences[email_notifications]"]');
  }

  findByName (name) {
    return document.querySelector(`[name*="${name}"]`);
  }
}
