import { MouseAction } from "./mouse_action";
import { MouseMoveAction } from "./mouse_move_action";

/**
 *  MouseClickActions are triggered by mouse clicks of users
 *  When executed they show a visual representation of the users click in other users interfaces
 */
export class MouseClickAction extends MouseAction {
  constructor(tabId, user, element, x, y) {
    /**
     * @constructor
     * @param {String} tabId  - TabId of the tab the action originates from
     * @param {number} user - UserId of the chat room owner
     * @param {String} element - Element thats subject of the UIAction
     * @param {number} x - Horizontal coordinates of the MouseClickAction
     * @param {number} y - Vertical coordinates of the MouseClickAction
     */
    super(tabId, user, element, x, y);
    this.actionType = "MouseClickAction";
  }

  execute() {
    let tabId = MouseClickAction.getTabId();
    if (this.tabId !== tabId || this.element.length === 0) { return; }
    // make relative offset absolute again (according to element dimensions)
    let relativeOffsetY = this.element.offset().top + (this.y * this.element.outerHeight()) - 15;
    let relativeOffsetX = this.element.offset().left + (this.x * this.element.outerWidth()) - 15;
    // find avatar with users id and take it's color as the color for the click
    let click;
    let chat = collabCenter.chat;
    let color = this.user === chat.owner ? chat.options.ownerColor : chat.options.colors[this.user % 20];
    if ($('.communication-user-avatar[data-user_id="' + this.user + '"]').length > 0) {
      let userAvatar = $('.communication-user-avatar[data-user_id="' + this.user + '"]');
      let authorId = userAvatar.attr("id");
      // create click element with authorId and fitting color
      click = $("<div id='" + authorId + "_click' class='click'></div>");
    } else {
      if ($("#editor_user_" + this.user + "click").length === 0) {
        click = $("<div id='editor_user_" + this.user + "_click' class='click'></div>");
      } else {
        click = $("#editor_user_" + this.user + "_click");
      }
    }
    click.css({
      "background-color": color,
      top: relativeOffsetY,
      left: relativeOffsetX
    });
    // append click to the body and make it disappear after the timeout
    $(document.body).append(click);
    // todo
    clickTimeout(click);
  }

  static fromJson(json) {
    let action = JSON.parse(json);
    return new MouseClickAction(action.tabId, action.user, action.elementLocation, action.x, action.y);
  }

  static registerToElement() {
    $(document).on("click", function(event) {
      // for some reason a second click gets triggered. Did not find a more elegant solution to prevent it
      if (!$(event.target).is(":radio") && !$(event.target).is(".remove")){
        let elementLocation = VERSTEHE.ElementFinder.ElementFinder.elementToLocation(event.target);
        if (elementLocation == null) {
          return;
        }
        // event.page coordinates subtracted by the offset of the element results in the cursor position over the element
        // will help for different screen sizes and scroll positions
        // relative offset helps when elements have different dimension due to different screen sizes
        let relativeOffsetX = Math.floor((Math.floor(event.pageX - $(event.target).offset().left) / $(event.target).outerWidth()) * 100) / 100;
        let relativeOffsetY = Math.floor((Math.floor(event.pageY - $(event.target).offset().top) / $(event.target).outerHeight()) * 100) / 100;
        let mouseMoveAction = new MouseMoveAction(MouseClickAction.getTabId(), _app.info.user.userId, event.target, relativeOffsetX, relativeOffsetY);
        VERSTEHE.content_channel.send_message(mouseMoveAction);
        let mouseClickAction = new MouseClickAction(MouseClickAction.getTabId(), _app.info.user.userId, event.target, relativeOffsetX, relativeOffsetY);
        VERSTEHE.content_channel.send_message(mouseClickAction);
      }
    });
  }
}

// todo: bessere Möglichkeit zum löschen des Klick-Elements finden
// Timer to remove the Click-Element after 1 sec
function clickTimeout(click) {
  setTimeout(function() {
    click.remove();
  }, 1000);
};
