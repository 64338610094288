export interface Phrase {
  id: number,
  text: string,
  startTime: number,
  endTime: number
}

export enum SaveStatus {
  SAVING = 'SAVING',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS'
}
