import Vue from 'vue';
import { MutationTree } from 'vuex';
import { TemplateElement, TemplateSlide } from '../interfaces';
import { MatManageState } from './state';

export const Mutations = {
  SET_STATE: 'SET_STATE',
  SET_TEMPLATE_ATTRIBUTES: 'SET_TEMPLATE_ATTRIBUTES',
  SET_SLIDE_ATTRIBUTES: 'SET_SLIDE_ATTRIBUTES',
  SET_ELEMENT_ATTRIBUTES: 'SET_ELEMENT_ATTRIBUTES',
  ADD_ELEMENT: 'ADD_ELEMENT',
  REMOVE_ELEMENT: 'REMOVE_ELEMENT',
  TRANSFORM_ELEMENT: 'MOVE_ELEMENT',
  COPY_SLIDE: 'COPY_SLIDE'
};

export const mutations: MutationTree<MatManageState> = {
  [Mutations.SET_STATE] (state, payload: MatManageState) {
    Object.assign(state, payload);
  },
  [Mutations.SET_TEMPLATE_ATTRIBUTES] (state, payload) {
    Object.assign(state.template, payload);
  },
  [Mutations.SET_SLIDE_ATTRIBUTES] (state, payload: { key: string, attributes }) {
    Object.assign(state.template.slides[payload.key], payload.attributes);
  },
  [Mutations.SET_ELEMENT_ATTRIBUTES] (state, payload: { slideKey: string, elementId: string, attributes }) {
    let element = state.template.slides[payload.slideKey].elements.find(element => element.id === payload.elementId);
    Object.keys(payload.attributes).forEach(key => Vue.set(element, key, payload.attributes[key]));

  },
  [Mutations.ADD_ELEMENT] (state, payload: { slideKey: string, element: TemplateElement }) {
    state.template.slides[payload.slideKey].elements.push(payload.element);
  },
  [Mutations.REMOVE_ELEMENT] (state, payload: { slideKey: string, elementId: string }) {
    const elementIndex = state.template.slides[payload.slideKey].elements.findIndex(el => el.id === payload.elementId);

    if (elementIndex >= 0) {
      state.template.slides[payload.slideKey].elements.splice(elementIndex, 1);
    }
  },
  [Mutations.TRANSFORM_ELEMENT] (state, payload: { slideKey: string, elementId: string, x: number, y: number, width: number }) {
    let element = state.template.slides[payload.slideKey].elements.find(element => element.id === payload.elementId);
    element.left += payload.x;
    element.top += payload.y;

    if (payload.width) {
      element.width = payload.width;
    }
  },
  [Mutations.COPY_SLIDE] (state, payload: { sourceSlide: string, targetSlide: string }) {
    Object.assign(state.template.slides[payload.targetSlide], state.template.slides[payload.sourceSlide]);
    state.template[payload.targetSlide + '_image_copy'] = payload.sourceSlide;
  }
};
