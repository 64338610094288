<template>
  <div class="livestream-aws-integration">
      <div class="livestream-aws-settings">
        <ValidationProvider rules="required" :name="$t('integrations.livestream_aws_access_key')" v-slot="{ valid, errors }">
        <div class="form-group label-static" :class="{ 'error': errors.length }">
          <label for="livestream_aws_access_key" class="control-label">{{ $t('integrations.livestream_aws_access_key') }}</label>
          <input v-model="livestream_aws_access_key" type="password" class="form-control" id="livestream_aws_access_key">
          <span v-if="errors.length > 0" class="help-block error-block">{{ errors[0] }}</span>
        </div>
      </ValidationProvider>

      <ValidationProvider rules="required" :name="$t('integrations.livestream_aws_secret_key')" v-slot="{ valid, errors }">
        <div class="form-group label-static" :class="{ 'error': errors.length }">
          <label for="livestream_aws_secret_key" class="control-label">{{ $t('integrations.livestream_aws_secret_key') }}</label>
          <input v-model="livestream_aws_secret_key" type="password" class="form-control" id="livestream_aws_secret_key">
          <span v-if="errors.length > 0" class="help-block error-block">{{ errors[0] }}</span>
        </div>
      </ValidationProvider>

      <ValidationProvider rules="required" :name="$t('integrations.livestream_aws_region')" v-slot="{ valid, errors }">
        <div class="form-group label-static" :class="{ 'error': errors.length }">
          <label for="livestream_aws_region" class="control-label">{{ $t('integrations.livestream_aws_region') }}</label>
          <input v-model="livestream_aws_region" type="text" class="form-control" id="livestream_aws_region">
          <span v-if="errors.length > 0" class="help-block error-block">{{ errors[0] }}</span>
        </div>
      </ValidationProvider>

      <ValidationProvider rules="required" :name="$t('integrations.livestream_aws_medialive_access_role_arn')" v-slot="{ valid, errors }">
        <div class="form-group label-static" :class="{ 'error': errors.length }">
          <label for="livestream_aws_medialive_access_role_arn" class="control-label">{{ $t('integrations.livestream_aws_medialive_access_role_arn') }}</label>
          <input v-model="livestream_aws_medialive_access_role_arn" type="text" class="form-control" id="livestream_aws_medialive_access_role_arn">
          <span v-if="errors.length > 0" class="help-block error-block">{{ errors[0] }}</span>
        </div>
      </ValidationProvider>

      <ValidationProvider rules="required" :name="$t('integrations.livestream_aws_medialive_input_security_group_id')" v-slot="{ valid, errors }">
        <div class="form-group label-static" :class="{ 'error': errors.length }">
          <label for="livestream_aws_medialive_input_security_group_id" class="control-label">{{ $t('integrations.livestream_aws_medialive_input_security_group_id') }}</label>
          <input v-model="livestream_aws_medialive_input_security_group_id" type="text" class="form-control" id="livestream_aws_medialive_input_security_group_id">
          <span v-if="errors.length > 0" class="help-block error-block">{{ errors[0] }}</span>
        </div>
      </ValidationProvider>
        <slot/>
      </div>
    </div>
  </template>
  
  <script>
    import { createNamespacedHelpers } from 'vuex';
    const { mapGetters, mapActions } = createNamespacedHelpers('integrations');
  
    export default {
      data() {
        return {
          more: false,
          livestream_aws_access_key: null,
          livestream_aws_secret_key: null,
          livestream_aws_region: null,
          livestream_aws_medialive_access_role_arn: null,
          livestream_aws_medialive_input_security_group_id: null
        }
      },
      computed: {
        ...mapGetters([
          'integrationById'
        ]),
        integration() {
          return this.integrationById('livestream_aws');
        },
        settings() {
          return {
            livestream_aws_access_key: this.livestream_aws_access_key,
            livestream_aws_secret_key: this.livestream_aws_secret_key,
            livestream_aws_region: this.livestream_aws_region,
            livestream_aws_medialive_access_role_arn: this.livestream_aws_medialive_access_role_arn,
            livestream_aws_medialive_input_security_group_id: this.livestream_aws_medialive_input_security_group_id
          }
        },
      },
      methods: {
        load() {
          const settings = this.integration.settings;
          this.livestream_aws_access_key = settings.livestream_aws_access_key,
          this.livestream_aws_secret_key = settings.livestream_aws_secret_key,
          this.livestream_aws_region = settings.livestream_aws_region,
          this.livestream_aws_medialive_access_role_arn = settings.livestream_aws_medialive_access_role_arn,
          this.livestream_aws_medialive_input_security_group_id = settings.livestream_aws_medialive_input_security_group_id
        }
      },
      mounted() {
        this.load();
        $.material.init();
      }
    }
  </script>
  
  <style lang="scss" scoped>
   .livestream-aws-integration {
      display: flex;
    }
  
    .livestream-aws-settings {
      flex: 1 0;
      align-self: flex-start;
      min-width: 400px;
      max-width: 800px;
      background-color: $color-white;
      border-radius: 3px;
      padding: 30px;
    }
  </style>
