import Vue from 'vue';
import VueRouter from 'vue-router';
import Vuetify from '@webcomputing/vuetify';

import store from '../store';
import App from './components/app.vue';
import routes from './routes';
import { Actions } from './store/actions';
import { WebConferenceModule } from './store';
import { getUserSettingsFromLS } from './user_settings_store';
import SelfieSegmentationStream from '../common/background_replacement/selfie_segmentation_stream';
import { Getters } from './store/getters';
import { VideoAudioService } from './services/VideoAudioService';
import { RoomService } from './services/RoomService';
import ImageStore from '../common/background_replacement/image_store';
import { Settings, UserDetails } from './store/state';
import logger from '../logger/logger';

let content: Vue;

Vue.use(Vuetify);

export function init() {
  logger.init({
    source: 'coovi_web_conferences',
    catchGlobalErrors: true,
    logToConsole: true,
  });

  this.router = new VueRouter({
    routes,
  });

  initContent(this.router);
}

export function initKendoDateTimePicker() {
  var startDate = getDefaultDate()
  var altField = $('.datepicker-scheduled-at input[name="web_conference[scheduled_at]"]');

  $('#datepicker-scheduled-at').kendoDateTimePicker({
    value: startDate,
    dateInput: true,
    change: function() {
      altField.val(this.value().toISOString());
    }
  });

  var altFieldDuration = $('.duration input[name="web_conference[duration]"]');
  var field  = $('#web_conference_duration').val();
  var duration = getMinutesFromDate(field);

  $('#duration').kendoTimePicker({
    value: duration,
    dateInput: true,
    interval: 15,
    format: "H:mm",
    min: new Date(2000, 0, 1, 0, 15, 0), //date part is ignored
    max: new Date(2000, 0, 1, 5, 0, 0), //date part is ignored
    change: function() {
      altFieldDuration.val(this.value().getHours() * 60 + this.value().getMinutes());
    }
  });
}

function getDefaultDate() {
  var defaultDate = new Date();
  var field = $('#web_conference_scheduled_at');

  if (field.val() !== '' && field.val() != '0') {
    var dateString = field.val() as any;
    dateString = dateString.split(" ") ;

    dateString.pop();
    dateString = dateString.join("T");

    defaultDate = new Date(Date.parse(dateString));
  }

  return defaultDate;
};

function getMinutesFromDate(minutes) {
  var num = minutes;
  var hours = (num / 60);
  var rhours = Math.floor(hours);
  var mins = (hours - rhours) * 60;
  var rminutes = Math.round(mins);
  return  new Date(2000, 0, 1, rhours, rminutes, 0)
};

async function initContent(router: VueRouter) {
  const appNode = document.getElementById('v-web-conference');
  const urls = JSON.parse(appNode.dataset.urls);
  const webConferenceDetails = JSON.parse(appNode.dataset.webconferenceDetails);
  const webConferenceSettings = JSON.parse(appNode.dataset.webconferenceSettings) as Settings;
  const userDetails = JSON.parse(appNode.dataset.userDetails) as UserDetails;
  const livekitUrl = JSON.parse(appNode.dataset.livekitWssUrl);

  store.registerModule('webConference', WebConferenceModule);
  store.dispatch(Actions.SET_URLS, urls);
  store.dispatch(Actions.SET_DETAILS, webConferenceDetails);
  store.dispatch(Actions.SET_SETTINGS, webConferenceSettings);
  store.dispatch(Actions.SET_USER_DETAILS, userDetails);
  store.dispatch(Actions.SET_BREAKOUT_STARTED, webConferenceDetails.breakout_started);
  store.dispatch(Actions.SET_LIVEKIT_URL, livekitUrl);

  const userSettings = await getUserSettingsFromLS();
  store.dispatch(Actions.SET_USER_SETTINGS, userSettings);

  logger.setDefaults({
    userId: userDetails.id,
    siteId: webConferenceDetails.siteId,
    data: {
      isGuest: userDetails.isGuest,
      webConference: {
        id: webConferenceDetails.id,
        state: webConferenceDetails.state,
        live: webConferenceDetails.live,
        guestAllowed: webConferenceDetails.guest_allowed,
        guestsAllowed: webConferenceDetails.guests_allowed,
      }
    },
  });

  await ImageStore.init(userDetails.id);
  const customBackgroundImages = await ImageStore.getAll();
  await store.dispatch(Actions.SET_CUSTOM_BACKGROUND_IMAGES, customBackgroundImages);

  const selfieSegmentationStream = new SelfieSegmentationStream(store.getters[Getters.CUSTOM_BACKGROUND_IMAGE]);
  if (store.state.webConference!.userSettings.backgroundReplacement) {
    selfieSegmentationStream.init();
  }

  const videoAudioService = new VideoAudioService(selfieSegmentationStream);
  const roomService = new RoomService(selfieSegmentationStream);

  const primaryColor = getComputedStyle(document.body).getPropertyValue('--primary-color').trim();

  const vuetify = new Vuetify({
    icons: {
      iconfont: 'md',
    },
    theme: {
      themes: {
        light: {
          primary: primaryColor,
        },
      },
    },
  });

  Vue.config.errorHandler = function (error, vm, info) {
    logger.error({
      message: 'vue error',
      error,
      section: 'index:vue-errorHandler',
      data: {
        info,
        vueComponentName: vm.$options.name,
      },
    });
  };

  content = new Vue({
    store,
    router,
    vuetify,
    i18n: VERSTEHE.vueI18n,
    el: appNode,
    provide: {
      selfieSegmentationStream,
      videoAudioService,
      roomService,
    },
    render: h => h(App),
  });
}
