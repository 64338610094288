import { BaseGrid } from '../grid/base_grid';
import { columnConfiguration } from './column_configuration';
import { DataSource } from './data_source';
import { Filter } from './filter';
import { ColumnSelect } from '../grid/base_column_select';
const GRID_COOKIE_KEY = `quiz_grid_options_${_app.info.siteId}_${_app.info.user.userId}`;

export class QuizGrid extends BaseGrid {
  constructor() {
    super({ skipInit: true });

    this.gridElement = document.querySelector('.grid-component.quizzes-list');

    if (this.gridElement) {
      this.endpointUrl = this.gridElement.dataset.endpoint;
      this.wrapper = this.gridElement.querySelector('.grid-wrapper');
      this.dataSource = new DataSource({ endpointUrl: this.endpointUrl, grid: this });

      this.initGrid();
      this.filter = new Filter(this);
      this.columnSelect = new ColumnSelect(this);

      this.bindListeners();
    }
  }

  gridColumns() {
    let visibleColumns = this.getGridOptions().columns;
    let columns = columnConfiguration.map(column => {
      if (visibleColumns && column.hideable) {
        column.hidden = visibleColumns.indexOf(column.field) === -1;
      }

      return column;
    });

    return { columns: columns };
  }

  gridOptions() {
    return {
      editable: {
        mode: 'inline',
        confirmation: true
      },
      scrollable: {
        endless: true
      },
      pageable: {
        numeric: false,
        previousNext: false
      },
      filterable: {
        extra: false
      },
      sortable: {
        mode: 'single',
        allowUnsort: false
      }
    };
  }

  gridSort() {
    let sort = this.getGridOptions().sort;

    return sort ? sort : {
      field: 'updated_at',
      dir: 'desc'
    };
  }

  gridFilter () {
    let filters = this.getGridOptions().filter;

    if (filters) {
      return filters.reduce((filters, filter) => {
        return filters.concat(filter.filters);
      }, []);
    } else {
      return [];
    }
  }

  gridCookieKey() {
    return GRID_COOKIE_KEY;
  }

  onGridSort(e) {
    this.setGridOptions({ sort: e.sort });
  }

  onGridDataBound(e) {
    this.filter.updateFilters(this.dataSource.dataSource.filter());
  }

  onGridFilter(e) {
    this.filter.filterChange(e);
  }

  gridChange (e) {
    this.selected = this.grid.selectedKeyNames();
    this.bulkActions.updateSelection(this.selected);
  }

  bindListeners() {
    $(this.gridElement).on('click', '.grid-action-delete', (e) => {
      e.preventDefault();

      let tableRow = e.currentTarget.parentNode.parentNode;
      this.grid.removeRow(tableRow);
    });
  }
}
