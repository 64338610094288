<template>
  <div class="course-lesson">
    <div class="course-lesson-title">{{ currentLesson.title }}</div>
    <loading-spinner v-show="!isLoaded"></loading-spinner>

    <component v-if="isLoaded"
      :is="lessonComponent"
      :embed-mode="embedMode"
      @progress="onProgress"></component>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapGetters } = createNamespacedHelpers('coursePerform');

import { checkFetchStatus } from '../../../utils';
import LoadingSpinner from '../../../../globals/loading_spinner.vue';
import TopicLesson from './topic_lesson.vue';
import TopicQuizLesson from './topic_quiz_lesson.vue';
import ExternalLesson from './external_lesson.vue';
import PlaylistLesson from './playlist_lesson.vue';
import CustomContentLesson from './custom_content_lesson.vue';
import ElearningPackageLesson from './elearning_package_lesson.vue';

export default {
  name: 'lesson',
  components: {
    LoadingSpinner,
    TopicLesson,
    TopicQuizLesson,
    ExternalLesson,
    PlaylistLesson,
    CustomContentLesson,
    ElearningPackageLesson,
  },
  props: {
    embedMode: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      'currentLesson',
      'lessonById'
    ]),
    lessonComponent() {
      switch (this.currentLesson.type) {
        case 'Course::Lesson::TopicLesson':
          return 'topic-lesson';
        case 'Course::Lesson::TopicQuizLesson':
          return 'topic-quiz-lesson';
        case 'Course::Lesson::PlaylistLesson':
          return 'playlist-lesson';
        case 'Course::Lesson::ExternalLesson':
          return 'external-lesson';
        case 'Course::Lesson::CustomContentLesson':
          return 'custom-content-lesson';
        case 'Course::Lesson::ElearningPackageLesson':
          return 'elearning-package-lesson';
      }
    },
    isLoaded() {
      return this.currentLesson.loaded === true;
    }
  },
  methods: {
    ...mapActions([
      'updateLessonProgress',
      'fetchLessonDetail'
    ]),
    onProgress(progress) {
      this.updateLessonProgress({ lesson: this.currentLesson, progress: progress });
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (this.lessonById(to.params.lessonId)) {
      next();
    } else {
      next(false);
    }
  },
  created() {
    // Fetch lesson details
    this.fetchLessonDetail(this.currentLesson);
  }
}
</script>

<style lang="scss" scoped>
.course-lesson {
  position: relative;
}

::v-deep {
  .lesson-description {
    margin: 24px 0;
  }

  .lesson-detail {
    margin: 24px 0;
  }

  .lesson-subject {
    padding-top: 24px;
    border-top: 1px solid $black-12-opacity;
  }

  .lesson-subject-title {
    margin: 0 0 15px;
    font-size: 14px;
    font-weight: 400;
  }

  .lesson-subject-author {
    display: flex;
    align-items: center;
    margin: 15px 0;
  }

  .lesson-subject-author-avatar {
    width: 30px;
    height: 30px;
    background-size: cover;
    border-radius: 50%;
    margin-right: 15px;
  }

  .lesson-subject-author-title {
    flex: 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
