export const locals = {
  de: {
    action_edit: 'Bearbeiten',
    action_delete: 'Löschen',
    title: 'Titel',
    is_active: 'Aktiviert',
    is_active_label_true: 'Aktivierte Vorlagen',
    is_active_label_false: 'Deaktivierte Vorlagen',
    is_public: 'Öffentlich',
    is_public_label_true: 'Öffentliche Vorlagen',
    is_public_label_false: 'Private Vorlagen',
    true: 'Ja',
    false: 'Nein',
    and: 'und',
    author: 'Erstellt von',
    contains: 'Beinhaltet',
    created_at: 'Erstellt am',
    eq: 'ist',
    neq: 'ist nicht',
    is_after_or_equal_to: 'gleich oder später ist',
    is_before_or_equal_to: 'gleich oder früher ist',
    is_equal_to: 'gleich ist',
    updated_at: 'Aktualisiert am',
    filter_only_my_own: 'Nur meine eigenen',
    is_film_default: 'Standard für Filme',
    is_clip_default: 'Standard für Clips',
    filter_only_defaults: 'Nur Standardvorlagen',
    set_film_default: 'Als Film-Standard festlegen',
    unset_film_default: 'Als Film-Standard entfernen',
    set_clip_default: 'Als Clip-Standard festlegen',
    unset_clip_default: 'Als Clip-Standard entfernen',
    set_default_failed: 'Die Standard-Vorlage konnte nicht gesetzt werden.',
    unset_default_failed: 'Die Standard-Vorlage konnte nicht entfernt werden.',
    set_default_success: 'Die Standard-Vorlage wurde gesetzt.',
    unset_default_success: 'Die Standard-Vorlage wurde entfernt.',
    confirm_default_title: 'Vorlage nicht aktiv oder öffentlich',
    confirm_default_content: 'Wenn Sie diese Vorlage als Standard festlegen wird sie automatisch aktiviert und veröffentlicht.'
  },
  en: {
    action_edit: 'Edit',
    action_delete: 'Delete',
    title: 'Title',
    true: 'Yes',
    false: 'No',
    and: 'and',
    author: 'Created by',
    created_at: 'Created at',
    eq: 'equals',
    neq: 'is not equal',
    is_after_or_equal_to: 'Is after or equal to',
    is_before_or_equal_to: 'Is before or equal to',
    is_equal_to: 'Is equal to',
    updated_at: 'Updated at',
    filter_only_my_own: 'Only my own',
    is_active: 'Active',
    is_active_label_true: 'Active templates',
    is_active_label_false: 'Inactive templates',
    is_public: 'Public',
    is_public_label_true: 'Public templates',
    is_public_label_false: 'Private templates',
    is_film_default: 'Default for films',
    is_clip_default: 'Default for Clips',
    filter_only_defaults: 'Only default templates',
    set_film_default: 'Set as film default',
    unset_film_default: 'Remove as film default',
    set_clip_default: 'Set as clip default',
    unset_clip_default: 'Remove as clip default',
    set_default_failed: 'The default template could not be set.',
    unset_default_failed: 'The default template could not be removed.',
    set_default_success: 'The default template has been set.',
    unset_default_success: 'The default template has been removed.',
    confirm_default_title: 'Template is not active or public',
    confirm_default_content: 'If you set this template as default it will be automatically activated and published.'
  },
  es: {
    action_edit: 'Corregir',
    action_delete: 'Eliminar',
    title: 'Título',
    true: 'Sí',
    false: 'No',
    and: 'y',
    author: 'Creado por',
    created_at: 'Creado en',
    eq: 'inguales',
    neq: 'no es igual',
    is_after_or_equal_to: 'Es igual o posterior a',
    is_before_or_equal_to: 'Es anterior o igual a',
    is_equal_to: 'Es igual a',
    updated_at: 'Actualizado en',
    filter_only_my_own: 'Sólo el mío',
    is_active: 'Activo',
    is_active_label_true: 'Plantillas activas',
    is_active_label_false: 'Plantillas inactivas',
    is_public: 'Público',
    is_public_label_true: 'Plantillas públicas',
    is_public_label_false: 'Plantillas privadas',
    is_film_default: 'Por defecto para películas',
    is_clip_default: 'Por defecto para Clips',
    filter_only_defaults: 'Sólo plantillas por defecto',
    set_film_default: 'Establecer como película por defecto',
    unset_film_default: 'Quitar como película por defecto',
    set_clip_default: 'Establecer como clip por defecto',
    unset_clip_default: 'Quitar como clip por defecto',
    set_default_failed: 'La plantilla por defecto no pudo ser establecida.',
    unset_default_failed: 'No se ha podido eliminar la plantilla por defecto.',
    set_default_success: 'La plantilla por defecto ha sido establecida.',
    unset_default_success: 'La plantilla por defecto ha sido eliminada.',
    confirm_default_title: 'Plantilla no activa o pública',
    confirm_default_content: 'Si establece esta plantilla como predeterminada, se activará y publicará automáticamente.'
  }
};

export function t(key) {
  return locals[_app.info.currentLocale][key] || key;
}

export function tt(key, variables = {}) {
  let template = kendo.template(t(key));
  return template(variables);
}
