import { ConfigElement, MediaAssemblingTemplate } from "../interfaces";

export interface MatManageState {
  newRecord: boolean;
  urls: {
    createUrl: string;
    updateUrl: string;
  };
  template: MediaAssemblingTemplate;
  config: { [key: string]: Array<ConfigElement> },
  fonts: Array<string>;
};

export const state: MatManageState = {
  newRecord: null,
  urls: null,
  template: null,
  config: null,
  fonts: []
};
