export default {
  lessonsInGroup: (state) => (lessonGroupId) => {
    return state.lessons
      .filter(lesson => lesson.lessonGroupId === lessonGroupId && !lesson.deleted)
      .sort((a, b) => a.position - b.position);
  },
  lessonGroups: (state) => {
    return state.lessonGroups.filter(lessonGroup => !lessonGroup.deleted).sort((a, b) => a.position - b.position);
  },
  lessonGroup: (state) =>  (id) => state.lessonGroups.find(group => group.id === id),
  uiState: (state) => state.uiState,
  lessonEditorType: (state) => state.lessonEditor.lessonType,
  lessonEditorObject: (state) => state.lessonEditor.lesson,
  lessonEditorGroup: (state) => state.lessonEditor.lessonGroupId,
  lessonEditorSubject: (state) => state.lessonEditor.subject,
  lessonEditorLesson: (state) => state.lessonEditor.lesson,
  isLessonEditorSubjectSelected: (state) => state.lessonEditor.subject.selected,
  apiUrl: (state) => (endpoint) => {
    return state.apiUrls[endpoint];
  },
  elearningPackageUrl: (state) => (uploadId) => {
    let url = state.apiUrls['elearningPackage'];

    return url.replace('__upload_id__', uploadId);
  },
  lessonEditorQuestions: (state) => {
    return (state.lessonEditor.questions || []).filter(question => !question.deleted).sort((a, b) => {
      // Ascending sort by display time. Null values at the end
      if (a.time === null) {
        return 1;
      } else if (b.time === null) {
        return -1;
      } else if (a.time === b.time) {
        return 0;
      } else {
        return a.time < b.time ? -1 : 1;
      }
    });
  },
  currentQuestion: (state) => state.lessonEditor.currentQuestion,
  mediaData: (state) => state.lessonEditor.mediaData,
  lessonById: (state) => (lessonId) => state.lessons.find(lesson => lesson.id === lessonId ),
  isAnythingLocked: (state) => {
    let isAnyLessonLocked = state.lessons.some(lessons => lessons.lockedByUserId !== null)
    let isAnyLessonGroupLocked = state.lessonGroups.some(lessonGroups => lessonGroups.lockedByUserId !== null)
    return isAnyLessonLocked || isAnyLessonGroupLocked
  }
}
