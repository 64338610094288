import { SlideshowAction } from "./slideshow_action";

/**
 * The AllSlidesAction handles the toggle to select or deselect all slides in the Topic/Slideshow tab
 * When reduced it replaces all other SlideshowActions and evens out with other AllSlidesActions
 */
export class AllSlidesAction extends SlideshowAction {
  /**
   * @constructor
   * @param {String} tabId  - TabId of the tab the action originates from
   * @param {number} user - UserId of the chat room owner
   * @param {String} element - Element thats subject of the UIAction
   */
  constructor(tabId, user, element) {
    super(tabId, user, element);
    this.actionType = "AllSlidesAction";
  }

  execute() {
    let button = this.element.parents().find("#select-all-slides");
    VERSTEHE.slideshows.allSlidesToggleClick(button);
  }

  isComparableTo(other) {
    if (this.actionType === other.actionType || other.actionType === "SlideAction") {
      return true;
    } else {
      false;
    }
  }

  reduce(other, last) {
    if (last) {
      if (this.actionType === other.actionType) {
        return null;
      } else {
        return this;
      }
    } else {
      if (this.actionType === other.actionType) {
        return other;
      } else {
        return null;
      }
    }
  }

  static fromJson(json) {
    let action = JSON.parse(json);
    return new AllSlidesAction(action.tabId, action.user, action.elementLocation);
  }

  static registerToElement() {
    $('[data-collaborative-action*="AllSlidesAction"]').click(function(event) {
      AllSlidesAction.createAction(AllSlidesAction.getTabId(), _app.info.user.userId, event.target);
    });
  }

  static createAction(tabId, user, element) {
    let action = new AllSlidesAction(tabId, user, element);
    VERSTEHE.content_channel.send_message(action);
  }
}
