import { ResourceSource, ResourceType } from '../../common/resource_enum';
import { TranslatePart } from './util';
import { CompareOperator, FilterType } from './types';

/**
 * Represents a filter used in the media library.
 */
export interface MediaLibraryFilter {
  /**
   * Name of the filter used by the backend to build a search query.
   * See: app/components/searchengine/media_library_search.rb
   */
  readonly type: FilterType;

  /**
   * Resets the values of the filter to its defaults.
   */
  reset();

  update(filter: MediaLibraryFilter);

  /**
   * Generates name parts that can be translated and displayed in the ui.
   */
  toLabel(): TranslatePart[];
}

export class NameContainsFilter implements MediaLibraryFilter {
  readonly type: FilterType = FilterType.Name;

  name: string;

  constructor() {
    this.reset();
  }

  reset() {
    this.name = '';
  }

  update(filter: NameContainsFilter) {
    this.name = filter.name;
  }

  toLabel(): TranslatePart[] {
    return [new TranslatePart('media_library.filter.name_contains', true), new TranslatePart(this.name, false)];
  }
}

export class ResourceTypeFilter implements MediaLibraryFilter {
  readonly type: FilterType = FilterType.ResourceType;

  resourceType: ResourceType;

  constructor() {
    this.reset();
  }

  update(filter: ResourceTypeFilter) {
    this.resourceType = filter.resourceType;
  }

  reset() {
    this.resourceType = ResourceType.Screencast;
  }

  toLabel(): TranslatePart[] {
    return [new TranslatePart('media_library.filter.type', true), new TranslatePart(`media_library.media_type.${this.resourceType}`, true)];
  }
}

export class ResourceSourceFilter implements MediaLibraryFilter {
  readonly type: FilterType = FilterType.ResourceSource;

  source: ResourceSource;

  constructor() {
    this.reset();
  }

  reset() {
    this.source = ResourceSource.Capture;
  }

  update(filter: ResourceSourceFilter) {
    this.source = filter.source;
  }

  toLabel(): TranslatePart[] {
    return [new TranslatePart('media_library.filter.source', true), new TranslatePart('media_library.media_source.' + this.source, true)];
  }
}

export class CreationDateFilter implements MediaLibraryFilter {
  readonly type: FilterType = FilterType.CreationDate;

  startDate: Date;
  endDate: Date;

  constructor() {
    this.reset();
  }

  reset() {
    this.endDate = new Date();
    this.startDate = new Date();
    this.startDate.setMonth(this.endDate.getMonth() - 1);
  }

  update(filter: CreationDateFilter) {
    this.startDate = new Date(filter.startDate);
    this.endDate = new Date(filter.endDate);
  }

  toLabel(): TranslatePart[] {
    return [
      new TranslatePart('media_library.filter.creation_date', true),
      new TranslatePart('media_library.filter.from', true),
      new TranslatePart(this.startDate.toLocaleDateString(), false),
      new TranslatePart('media_library.filter.to', true),
      new TranslatePart(this.endDate.toLocaleDateString(), false),
    ];
  }
}

export class DurationFilter implements MediaLibraryFilter {
  readonly type: FilterType = FilterType.Duration;

  private from: number;
  private to: number;

  constructor() {
    this.reset();
  }

  public setAndFormatFrom(time: string): void {
    this.from = this.parseTime(time);
  }

  public setAndFormatTo(time: string): void {
    this.to = this.parseTime(time);
    // If the length of the video is set to zero or smaller treat it as if the value is infinite.
    if (this.to <= 0) {
      this.to = -1;
    }
  }

  private parseTime(time: string): number {
    const parts: string[] = time.split(':');
    let hours: string = null;
    let minutes: string = null;
    let seconds: string = null;
    if (parts.length === 1) {
      // seconds only
      seconds = parts[0];
    } else if (parts.length === 2) {
      // mm:ss
      minutes = parts[0];
      seconds = parts[1];
    } else if (parts.length === 3) {
      // hh:mm:ss
      hours = parts[0];
      minutes = parts[1];
      seconds = parts[2];
    }
    return Number(hours) * 3600 + Number(minutes) * 60 + Number(seconds);
  }

  public getFrom(): string {
    return this.toTimeFormat(this.from);
  }

  public getTo(): string {
    return this.toTimeFormat(this.to);
  }

  private toTimeFormat(value: number) {
    if (value > 0) {
      let hours: number = Math.floor(value / 3600);
      let withoutHours: number = value - hours * 3600;
      let minutes: number = Math.floor(withoutHours / 60);
      let seconds: number = withoutHours - minutes * 60;
      return this.zeroPad(hours) + ':' + this.zeroPad(minutes) + ':' + this.zeroPad(seconds);
    }
    return '00:00:00';
  }

  private zeroPad(num): string {
    return String(num).padStart(2, '0');
  }

  isValid(): boolean {
    if (this.to > 0) {
      return this.to > this.from;
    }
    return true;
  }

  reset() {
    this.from = 0;
    this.to = 0;
  }

  update(filter: DurationFilter) {
    this.from = filter.from;
    this.to = filter.to;
  }

  toLabel(): TranslatePart[] {
    if (this.to > 0) {
      return [
        new TranslatePart('media_library.filter.label_length_from', true),
        new TranslatePart(this.getFrom(), false),
        new TranslatePart('media_library.filter.label_length_to', true),
        new TranslatePart(this.getTo(), false),
      ];
    } else {
      return [new TranslatePart('media_library.filter.label_length_from', true), new TranslatePart(this.getFrom(), false)];
    }
  }
}

export class ResolutionFilter implements MediaLibraryFilter {
  readonly type: FilterType = FilterType.Resolution;

  operator: CompareOperator;
  width: number;
  height: number;

  constructor() {
    this.reset();
  }

  reset() {
    this.width = 1920;
    this.height = 1080;
    this.operator = CompareOperator.Equals;
  }

  update(filter: ResolutionFilter) {
    this.operator = filter.operator;
    this.width = filter.width;
    this.height = filter.height;
  }

  toLabel(): TranslatePart[] {
    return [
      new TranslatePart('media_library.filter.resolution', true),
      new TranslatePart('media_library.filter.' + this.operator, true),
      new TranslatePart(this.width + 'x' + this.height, false),
    ];
  }
}
