import { SearchOptions } from './interfaces';

export const defaultSortOptions: SearchOptions = {
  title: '',
  closeButton: true,
  searchUrl: `${_app.info.rootUrl}/${_app.info.site}/manage/search_component/query`,
  indices: [],
  filterOptions: {
    sortOptions: [],
    filters: []
  }
}
