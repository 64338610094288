import { LessonSerializer } from './lesson_serializer';

export class CustomContentLessonSerializer extends LessonSerializer {
  serialize(lesson) {
    let attributes = super.serialize(lesson);

    attributes.subject_attributes = {
      content: lesson.subject.content
    };

    if (lesson.subject.id) {
      attributes.subject_attributes.id = lesson.subject.id;
    }

    return attributes;
  }
}

export default function serializeCustomContentLesson(lesson) {
  const serializer = new CustomContentLessonSerializer();
  return serializer.serialize(lesson);
}
