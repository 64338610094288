<template>
  <div
    class="getting-started-task-entry"
    :class="{ completed: isCompleted(id), active: isActive(id) }"
    @click="setActive(id)"
  >
    <div class="getting-started-task-entry-check">
      <i v-if="isCompleted(id)" class="material-icons">check</i>
    </div>
    <div class="getting-started-task-entry-label">
      {{ $t(`getting_started.task_${id}`) }}
    </div>
    <i class="material-icons getting-started-task-entry-help">help_outline</i>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';

  export default {
    props: ['id'],
    computed: {
      ...mapGetters({
        isCompleted: 'gettingStarted/isCompleted',
        isActive: 'gettingStarted/isActive'
      })
    },
    data: () => {
      return {
        completed: false
      };
    },
    methods: {
      ...mapActions({
        setActive: 'gettingStarted/setActive'
      })
    }
  }
</script>

<style lang="scss" scoped>
  .getting-started-task-entry {
    display: flex;
    position: relative;
    align-items: center;
    padding: 10px;
    border-top: 1px solid $black-12-opacity;
    background-color: $color-white;
    cursor: pointer;
    transition: background-color .1s ease-in;

    &:hover {
      background-color: $color-grey-10;
    }

    &.completed {
      .getting-started-task-entry-label {
        color: $black-54-opacity;
        text-decoration: line-through;
      }
    }

    &.active {
      background-color: $color-grey-10;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        right: -18px;
        width: 0;
        height: 0;
        margin-top: -9px;
        border-color: transparent transparent transparent $black-12-opacity;
        border-style: solid;
        border-width: 9px;
      }

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: -16px;
        width: 0;
        height: 0;
        margin-top: -8px;
        border-color: transparent transparent transparent $color-grey-10;
        border-style: solid;
        border-width: 8px;
      }
    }
  }

  .getting-started-task-entry-check {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    border: 1px solid $black-12-opacity;
    border-radius: 50%;
    background-color: $color-white;
    text-align: center;

    > i {
      font-size: 16px;
      color: var(--primary-color);
    }
  }

  .getting-started-task-entry-label {
    flex-grow: 1;
    padding: 0 10px;
  }

  .getting-started-task-entry-help {
    color: var(--primary-color);
    font-size: 20px;
  }
</style>
