import ChartWidget from './chart_widget';

export default class PieWidget extends ChartWidget {
  constructor(options) {
    super(options);

    this.type = 'pie';
  }

  static get defaultParams() {
    return {
      title: '',
      show_title: true
    };
  }

  initialize() {
    super.initialize();
    this.renderChart(this.element.querySelector('.report-widget-content'));
  }
}
