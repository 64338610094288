import { t, tt } from './localization';
import { BaseBulkActions } from '../../grid/actions/base_bulk_actions';
import Axios from 'axios';
import { ExportGrid } from '../../grid/export_grid';
export class BulkActions extends BaseBulkActions {
  constructor(grid) {
    super(grid);

    this.deleteAction = this.wrapper.querySelector('.bulk-delete');
    this.assignRoleAction = this.wrapper.querySelector('.bulk-assign-role');
    this.managedAccountAction = this.wrapper.querySelector('.bulk-managed-account');
    this.confirmAction = this.wrapper.querySelector('.bulk-confirm');
    this.accessAction = this.wrapper.querySelector('.bulk-access');
    this.exportAction = this.wrapper.querySelector('.bulk-export');
    this.editorWrappers = {
      role: this.wrapper.querySelector('.bulk-action-edit[data-attribute="role"]'),
      access: this.wrapper.querySelector('.bulk-action-edit[data-attribute="access"]'),
      managed: this.wrapper.querySelector('.bulk-action-edit[data-attribute="managed"]')
    };

    this.actions = {
      role: 'bulkAssignRole',
      access: 'bulkAccess',
      managed: 'bulkManagedAccount'
    };

    this.initRoleAction();
    this.initAccessSelect();
    this.initManagedAction();
    this.bindListener();
  }

  /**
   * Initializes the bulkaction to change the user role
   */
  initRoleAction() {
    let wrapper = this.editorWrappers.role;
    let select = wrapper.querySelector('select');

    this._roleSelect = $(select).kendoDropDownList().data('kendoDropDownList');
  }

  initAccessSelect() {
    const wrapper = this.editorWrappers.access;

    if (wrapper) {
      const select = wrapper.querySelector('select');
      this.accessSelect = $(select).kendoDropDownList().data('kendoDropDownList');
    }
  }

  initManagedAction() {
    let wrapper = this.editorWrappers.managed;
    let select = wrapper.querySelector('select');

    this._manageSelect = $(select).kendoDropDownList().data('kendoDropDownList');
  }

  bulkDelete(users) {
    let removeFromGrid = response => {
      users.forEach(id => {
        if (!response.failed.includes(parseInt(id, 10))) {
          this.grid.removeFromList(id);
        }

        this.showBulkMessage(tt('bulk_delete_message', response));
      });
    };

    $.ajax({
      url: this.deleteAction.href,
      method: 'POST',
      data: {
        ids: users
      },
      success: (response) => {
        removeFromGrid(response);
      },
      error: (xhr) => {
        console.error(xhr);
      }
    });
  }

  bulkConfirm(users) {
    $.ajax({
      url: this.confirmAction.href,
      method: 'POST',
      data: {
        ids: users
      },
      success: (response) => {
        // Update UI
        users.forEach(userId => {
          const item = this.grid.dataSource.dataSource.get(userId);

          if (item) {
            const row = this.grid.gridElement.querySelector(`[role="row"][data-uid="${item.uid}"]`);

            // Remove unconfirmed icon
            const iconContainer = row.querySelector('.grid-title > a');
            const icon = iconContainer.querySelector('.unconfirmed-icon');

            if (icon) {
              iconContainer.removeChild(icon);
            }

            // Add disabled class to action
            const action = row.querySelector('.grid-action-confirm');

            if (action) {
              action.classList.add('disabled');
            }
          }
        });

        this.showBulkMessage(tt('bulk_confirm_message', response));
      },
      error: xhr => console.log(xhr)
    });
  }

  bulkAccess() {
    return new Promise((resolve, reject) => {
      const url = this.editorWrappers['access'].getAttribute('data-url');
      const access = this.accessSelect.value();

      Axios.post(url, { user_ids: this.selected(), access: access })
        .then(response => {
          const msgKey = access === 'true' ? 'bulk_access_granted_message' : 'bulk_access_revoked_message';
          this.showBulkMessage(tt(msgKey, { affected: response.data.affected }));

          // Update rows in kendo grid
          response.data.users.forEach(user => {
            this.grid.updateRow(user.id, user);
          });

          this.grid.grid.refresh();

          resolve();
        })
        .catch(error => {
          console.error(error);
          reject();
        });
    });
  }

  bulkExport() {
    let exportGrid = new ExportGrid();
    let columns = "";
    columns += exportGrid.exportHeaderLine(this.grid.grid.columns, this.getActiveColumns());
    $.ajax({
      url: "users/export_users",
      method: 'GET',
      data: {
        ids: this.selected()
      },
      success: (result) => {
        result.users.forEach(user => {
          columns += user.has_access + ";" + user.fullname + ";" + user.email + ";" + user.role + ";" + exportGrid.getDateString(user.created_at) + ";" + user.current_sign_in_at + ";" + user.last_signed_in_mobile + "\n";
        });
        exportGrid.exportGrid(columns, 'user_export');
      }
    })
  }

  getActiveColumns() {
    let activeColumns = ["has_access", "any_name", "email", "role", "created_at", "current_sign_in_at", "last_signed_in_mobile"] // title has no hidden attribute and is always visible
    return activeColumns;
  }

  /**
   * Retrieves the data and sends it to the backend. Then the grid is updated with the new data.
   * @return {Promise}
   */
  bulkAssignRole() {
    return new Promise((resolve, reject) => {
      let url = this.editorWrappers['role'].getAttribute('data-url');
      let params = { new_role: this._roleSelect.value(), user_ids: this.selected() };

      Axios.post(url, params)
        .then(response => {
          this.showBulkMessage(tt('bulk_assign_role_message', response.data));

          if (response.data.subscription_error !== null) {
            this.renderSubscriptionErrorModal(response.data.subscription_error);
          }

          this.renderSubscriptionErrorModal;
          this.afterBulkAction(response.data.items);
          resolve();
        })
        .catch(error => {
          console.error(error);
          reject();
        });
    });
  }

  /**
 * Retrieves the data and sends it to the backend. Then the grid is updated with the new data.
 * @return {Promise}
 */
  bulkManagedAccount() {
    return new Promise((resolve, reject) => {
      let url = this.editorWrappers['managed'].getAttribute('data-url');
      let params = { managed: this._manageSelect.value(), user_ids: this.selected() };

      Axios.post(url, params)
        .then(response => {
          this.showBulkMessage(tt('bulk_managed_accounts', response.data));

          this.afterBulkAction(response.data.items);
          resolve();
        })
        .catch(error => {
          reject();
        });
    });
  }

  /**
   * Updates the datasource and refreshes the grid
   * @param {Hash} items - Contains all changed users and their new roles.
   */
  afterBulkAction(items) {
    this.selected().forEach(id => {
      let currentUser = items.find(user => user.id == id);

      if (currentUser) {
        if (currentUser.role) {
          this.grid.updateRow(id, { role: currentUser.role });
        } else if (currentUser.managed) {
          this.grid.updateRow(id, { managed: (currentUser.managed === 'true') });
        }
      }
    });

    this.grid.grid.refresh();
  }


  /**
   * Render the errormessage of the author subscription rules
   */
  renderSubscriptionErrorModal(modal) {
    let modalContainer = document.createElement('div');
    let modalContent = document.createElement('div');
    let modalDialog = document.createElement('div');
    modalContainer.setAttribute('id', 'no_author_roles_modal');
    modalContainer.setAttribute('role', 'dialog');
    modalContainer.classList.add('modal', 'fade');
    modalDialog.classList.add('modal-dialog', 'modal-lg');
    modalContent.classList.add('modal-content');
    modalContent.innerHTML = modal;
    modalDialog.appendChild(modalContent);
    modalContainer.appendChild(modalDialog);
    let container = document.getElementsByTagName('body')[0];
    container.appendChild(modalContainer);
    $('#no_author_roles_modal').modal('show');
  }

  bindListener() {
    this.deleteAction.addEventListener('click', e => {
      e.preventDefault();
      this.bulkDelete(this.selected());
    });

    /**
     * Bind the eventlistener to display the ui of the assing role bulk action
     */
    this.assignRoleAction.addEventListener('click', e => {
      e.preventDefault();
      this.openEdit('role');
    });

    this.managedAccountAction.addEventListener('click', e => {
      e.preventDefault();
      this.openEdit('managed');
    })

    this.confirmAction && this.confirmAction.addEventListener('click', e => {
      e.preventDefault();
      this.bulkConfirm(this.selected());
    });

    this.accessAction && this.accessAction.addEventListener('click', e => {
      e.preventDefault();
      this.openEdit('access');
    });

    this.exportAction && this.exportAction.addEventListener('click', e => {
      e.preventDefault();
      this.bulkExport();
    });
  }
}
