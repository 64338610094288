import { MutationTree } from 'vuex';
import { ScreenplayState } from './state';
import { Slide, ApiUrls, Section, Scene, TranscriptionLanguage, Transition } from '../../screenplay/interfaces';
import { Take, Image } from '../../screenplay/interfaces';
import Vue from 'vue';
import { v4 as uuid } from 'uuid';
import Utils from '../utils';

export const Mutations = {
  SET_INITIAL_DATA: "SET_INITIAL_DATA",
  SET_WORKSPACE_MEDIA: "SET_WORKSPACE_MEDIA",
  SET_WORKSPACE_MEDIA_SILENT: "SET_WORKSPACE_MEDIA_SILENT",
  ADD_MEDIA: "ADD_MEDIA",
  REMOVE_MEDIUM: "REMOVE_MEDIUM",
  SET_SCREENPLAY: "SET_SCREENPLAY",
  SET_SCREENPLAY_SILENT: "SET_SCREENPLAY_SILENT",
  SET_SCENES: "SET_SCENES",
  DELETE_SCENE: "DELETE_SCENE",
  DELETE_SECTION: "DELETE_SECTION",
  CREATE_SECTION: "CREATE_SECTION",
  CREATE_SCENE: "CREATE_SCENE",
  SET_SECTION_TITLE: "SET_SECTION_TITLE",
  SET_SCENE_TITLE: "SET_SCENE_TITLE",
  REPLACE_SCENE_MEDIUM: "REPLACE_SCENE_MEDIUM",
  UPDATE_POSITIONS: "UPDATE_POSITIONS",
  SET_SCENE_DRAGGING: "SET_SCENE_DRAGGING",
  SET_WORKSPACE_DRAGGING: "SET_WORKSPACE_DRAGGING",
  UPDATE_TTS: "UPDATE_TTS",
  SET_SCREENPLAY_STATUS: "SET_SCREENPLAY_STATUS",
  SET_SCREENPLAY_STATUS_SILENT: "SET_SCREENPLAY_STATUS_SILENT",
  REFRESH_TAKE: "REFRESH_TAKE",
  REMOVE_MEDIA_FROM_SCREENPLAY: "REMOVE_MEDIA_FROM_SCREENPLAY",
  UPDATE_TAKE: "UPDATE_TAKE",
  UPDATE_MEDIUM: "UPDATE_MEDIUM",
  SET_SCREENPLAY_VALID: "SET_SCREENPLAY_VALID",
  SET_NEEDS_PRODUCTION: "SET_NEEDS_PRODUCTION",
  SET_SCREENPLAY_ERRORS: "SET_SCREENPLAY_ERRORS",
  ADD_DELETED_SCENE: "ADD_DELETED_SCENE",
  PROCESS_SECTION_SWTICH_FOR_SCENE: "PROCESS_SECTION_SWTICH_FOR_SCENE",
  SET_TRANSCRIPTION_LANGUAGE: "SET_TRANSCRIPTION_LANGUAGE",
  ADD_SCENE_TO_SECTION: "ADD_SCENE_TO_SECTION",
  SET_SPEAKER_FOR_ALL: "SET_SPEAKER_FOR_ALL",
  SET_TRANSITION_TO_SECTION: "SET_TRANSITION_TO_SECTION"
}

export const mutations: MutationTree<ScreenplayState> = {
  [Mutations.SET_INITIAL_DATA] (state, data) {
    state.apiUrls = data.urls;
    state.filmId = data.film_id;
    state.updatedAt = data.updated_at;
    state.needsProduction = data.needs_production;
    state.screenplayErrors = data.screenplay_errors;
    state.transcriptionLanguageRequired = data.transcription_language_required;
    state.availableVoices = data.available_voices;
    state.noSpanishTranscription = data.no_spanish_transcription;
  },

  [Mutations.SET_WORKSPACE_MEDIA] (state, media: Array<Slide | Take | Image>) {
    state.workspace = media;
  },

  [Mutations.SET_WORKSPACE_MEDIA_SILENT] (state, media: Array<Slide | Take | Image>) {
    state.workspace = media;
  },

  [Mutations.SET_SCREENPLAY] (state, screenplay: Array<Section>) {
    state.screenplay = screenplay;
  },

  [Mutations.SET_SCREENPLAY_SILENT] (state, screenplay: Array<Section>) {
    state.screenplay = screenplay;
  },

  [Mutations.ADD_MEDIA] (state, media: Array<Slide | Take | Image>) {
    state.workspace = [...state.workspace, ...media];
  },

  [Mutations.ADD_SCENE_TO_SECTION] (state, payload: { section_id: number, scene: any }) {
    state.screenplay.find(section => section.id === payload.section_id).scenes.push(payload.scene);
  },

  [Mutations.REMOVE_MEDIUM] (state, payload: { medium_id: number | string, type: string }) {
    const item = state.workspace.find(obj => (obj.type === payload.type) && (obj.id === payload.medium_id));
    if (item) {
      state.workspace.splice(state.workspace.indexOf(item), 1);
    }
  },

  [Mutations.SET_SCENES] (state, payload: { section_id: number | string, scenes: Array<Scene> }) {
    state.screenplay.find(section => section.id === payload.section_id).scenes = payload.scenes;
  },

  [Mutations.DELETE_SCENE] (state, payload: { sectionId: number | string, sceneId: number | string }) {
    let scene = state.screenplay.find(section => section.id === payload.sectionId).scenes.find(scene => {
      return scene.id === payload.sceneId
    })

    Vue.set(scene, 'deleted', true);

    if (!scene.is_new) {
      state.deletedScenes.push({ section: payload.sectionId, scene: payload.sceneId });
    }
  },

  [Mutations.DELETE_SECTION] (state, sectionId: number | string) {
    let section = state.screenplay.find(section => section.id === sectionId);

    Vue.set(section, 'deleted', true);
  },

  [Mutations.CREATE_SECTION] (state, index = null) {
    let section = {
      id: uuid(),
      title: null,
      position: null,
      created_at: null,
      updated_at: null,
      scenes: [],
      deleted: false,
      is_new: true
    }

    if (index === null) {
      state.screenplay.push(section);
    } else {
      state.screenplay.splice(index + 1, 0, section);
    }
  },

  [Mutations.CREATE_SCENE] (state, sectionId) {
    let section = state.screenplay.find(section => section.id === sectionId);
    section.scenes.push({
      id: uuid(),
      medium_id: null,
      medium_type: null,
      title: null,
      duration: 10,
      position: section.scenes.length + 1,
      created_at: null,
      updated_at: null,
      thumbnail_url: null,
      tts_enable: false,
      tts_speaker: 'james',
      tts_text: '',
      deleted: false,
      is_new: true
    })
  },

  [Mutations.SET_SECTION_TITLE] (state, payload: { id: number | string, title: string }) {
    state.screenplay.find(section => section.id === payload.id).title = payload.title;
  },

  [Mutations.SET_SCENE_TITLE] (state, payload: {
    sectionId: number | string, sceneId: number | string, title: string}) {

    let scene = state.screenplay.find(section => section.id === payload.sectionId).scenes.find(scene => {
      return scene.id === payload.sceneId
    })

    Vue.set(scene, 'title', payload.title);
  },

  [Mutations.REPLACE_SCENE_MEDIUM] (state, payload: {
    sectionId: number | string,
    sceneId: number | string,
    medium_type: string,
    medium_id: number,
    duration: number,
    thumbnail_url: string,
    title?: string }) {

    let scene = state.screenplay.find(section => section.id === payload.sectionId).scenes.find(scene => {
      return scene.id === payload.sceneId
    });

    Object.assign(scene, payload);
  },

  [Mutations.UPDATE_POSITIONS] (state) {
    for(let i = 0; i < state.screenplay.length; i++) {
      state.screenplay[i].position = i + 1;

      for(let j = 0; j < state.screenplay[i].scenes.length; j++) {
        state.screenplay[i].scenes[j].position = j + 1;
      }
    }
  },

  [Mutations.SET_SCENE_DRAGGING] (state, value: boolean) {
    state.sceneDragging = value;
  },

  [Mutations.SET_WORKSPACE_DRAGGING] (state, value: boolean) {
    state.workspaceDragging = value;
  },

  [Mutations.UPDATE_TTS] (state, payload: { sectionId, sceneId, ttsParams }) {
    let scene = state.screenplay.find(section => section.id === payload.sectionId).scenes.find(s => {
      return s.id === payload.sceneId });

    Object.assign(scene, payload.ttsParams);
  },

  [Mutations.SET_SCREENPLAY_STATUS] (state, status) {
    state.screenplayStatus = status;
  },

  [Mutations.SET_SCREENPLAY_STATUS_SILENT] (state, status) {
    state.screenplayStatus = status;
  },

  [Mutations.REFRESH_TAKE] (state, payload) {
    let take = state.workspace.find((medium) => medium.type === 'take' && medium.id === payload.id) as Take;

    if(take) {
      take.thumbnail_url = payload.thumbnail_url + '?' + Date.now();
      take.state = payload.state;
      take.video.duration = payload.duration;
      take.video.resolution_height = payload.height;
      take.video.resolution_width = payload.width;
      take.video.url = payload.source;
      take.is_locked = payload.is_locked;
      take.last_locking_user = payload.last_locking_user;
      take.last_locking_user_id = payload.last_locking_user_id;
      take.uploadlock_user_id = payload.uploadlock_user_id;
    }
  },

  [Mutations.REMOVE_MEDIA_FROM_SCREENPLAY] (state, object: { type: string, medium_id: any }) {
    const type = `Media::${object.type.charAt(0).toUpperCase() + object.type.slice(1)}`;
    let scenes = [].concat(...state.screenplay.map(section => section.scenes)).filter((scene) => {
      return scene.medium_id === object.medium_id && scene.medium_type === type
    });

    scenes.forEach(scene => {
      Vue.set(scene, 'medium_type', null);
      Vue.set(scene, 'medium_id', null);
    })
  },

  [Mutations.UPDATE_TAKE] (state, payload: { amor_id: number, values: any }) {
    let take = state.workspace.find(medium => medium.type === 'take' && (medium as Take).amor_id === payload.amor_id);

    if(take) {
      for(let key in payload.values) {
        Vue.set(take, key, payload.values[key]);
      }
    }
  },

  [Mutations.UPDATE_MEDIUM] (state, payload: { id: number, type: string, values: any }) {
    let medium = state.workspace.find(medium => medium.type === payload.type && medium.id === payload.id);

    if(medium) {
      for(let key in payload.values) {
        Vue.set(medium, key, payload.values[key]);
      }
    }
  },

  [Mutations.SET_SCREENPLAY_VALID] (state, value: boolean) {
    state.valid = value;
  },

  [Mutations.SET_NEEDS_PRODUCTION] (state, value: boolean) {
    state.needsProduction = value;
  },

  [Mutations.SET_SCREENPLAY_ERRORS] (state, errors: Array<any>) {
    state.screenplayErrors = errors;
  },

  [Mutations.ADD_DELETED_SCENE] (state, payload: { sectionId: number, sceneId: number }) {
    state.deletedScenes.push({ section: payload.sectionId, scene: payload.sceneId });
  },

  [Mutations.PROCESS_SECTION_SWTICH_FOR_SCENE] (state, payload: { sectionId: EntityId, sceneId: EntityId }) {
    let scene = state.screenplay.find(section => section.id === payload.sectionId).scenes.find(scene => scene.id === payload.sceneId);
    Vue.set(scene, 'oldId', scene.id);
    Vue.set(scene, 'id', uuid());
  },

  [Mutations.SET_TRANSCRIPTION_LANGUAGE] (state, language: TranscriptionLanguage) {
    state.transcriptionLanguage = language;
  },

  [Mutations.SET_SPEAKER_FOR_ALL] (state, speaker) {
    state.screenplay.forEach(section => {
      section.scenes.forEach(scene => {
        scene.tts_speaker = speaker;
        scene.tts_text = Utils.changeXMLSpeakerValues(scene.tts_text, speaker);
      });
    });
  },

  [Mutations.SET_TRANSITION_TO_SECTION] (state, { sectionId, transition }) {
    let section = state.screenplay.find(section => section.id === sectionId);
    Vue.set(section, 'transition', transition);
  }
}
