import axios from 'axios';

export class Livestream {
  constructor(id) {
    this.element = document.getElementById(id);
    this.data = this.element.querySelector('.livestream-data');
    this.videoWrapper = this.element.querySelector('.livestream-video');
    this.offlineWrapper = this.element.querySelector('.livestream-offline');
    this.player = null;
    this.owner = this.data.dataset.owner;
    this.session = this.data.dataset.session;
    this.manifest = this.data.dataset.manifest;
    this.pollingUrl = this.data.dataset.polling;
    this.status = {};

    this.updateStatus(JSON.parse(this.data.dataset.status));
    this.startPolling();
  }

  updateStatus(status) {
    this.status = status;

    if (!this.player && this.status.live) {
      this.playLive();
    }
  }

  startPolling() {
    // Poll livestream status every 3 seconds
    const interval = setInterval(async () => {
      const response = await axios.get(this.pollingUrl);

      if (response.status === 200) {
        this.updateStatus(response.data);
      } else {
        clearInterval(interval);
      }
    }, 3000);
  }

  playLive() {
    if (this.player) {
      this.player.dispose();
    }

    const video = document.createElement('video');
    video.classList = 'video-js vjs-theme-coovi';
    video.autoplay = true;
    video.setAttribute('controls', 'controls');

    this.videoWrapper.appendChild(video);

    this.player = videojs(video, {
      language: _app.info.currentLocale,
      html5: {
        nativeTextTracks: false,
        dash: {
          streaming: {
            bufferBehind: 30,
            bufferingGoal: 2,
            rebufferingGoal: 2
          },
          manifest: {
            retryParameters: {
              maxAttempts: 10
            }
          }
        }
      }
    }, () => {
      this.player.src({
        type: 'application/dash+xml',
        src: this.manifest
      });

      this.player.on('loadedmetadata', () => {
        this.player.shaka.addEventListener('buffering', (e) => {
          if (e.buffering && !this.status.live) {
            // livestream ended
            this.stopLive();
          }
        });

        this.player.shaka.addEventListener('streaming', () => {
          // Fix for firefox autoplay issue
          setTimeout(function() {
            this.player.play();
          });
        });

        this.player.shaka.addEventListener('error', (_e, error) => {
          console.error(error);
        });
      });
    });

    this.offlineWrapper.style.display = 'none';
    this.videoWrapper.style.display = 'block';
  };

  stopLive() {
    if (this.player) {
      this.player.dispose();
      this.player = null;
    }

    this.videoWrapper.style.display = 'none';
    this.offlineWrapper.style.display = 'block';
  }
}
