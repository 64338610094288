import { MutationTree } from 'vuex';
import { CoursePlanManageState } from './state';
import Vue from 'vue';
import { Chapter, ChapterItem } from '../entities';
import { v4 as uuid } from 'uuid';

export const Mutations = {
  UPDATE_ENTITIES: 'SET_CHAPTERS',
  SET_CHAPTER_LIST: 'SET_CHAPTER_LIST',
  SET_CHAPTER_ITEM_LIST: 'SET_CHAPTER_ITEM_LIST',
  SET_ADD_TO_CHAPTER: 'SET_ADD_TO_CHAPTER',
  SET_SEARCH_URL: 'SET_SEARCH_URL',
  REMOVE_CHAPTER: 'REMOVE_CHAPTER',
  REMOVE_CHAPTER_ITEM: 'REMOVE_CHAPTER_ITEM',
  SET_CHAPTER_TITLE: 'SET_CHAPTER_TITLE',
  ADD_CHAPTER: 'ADD_CHAPTER',
  ADD_CHAPTER_ITEM: 'ADD_CHAPTER_ITEM',
  REFRESH_POSITIONS: 'REFRESH_POSITIONS',
  ADD_DELETED_CHAPTER_ITEM: 'ADD_DELETED_CHAPTER_ITEM',
  PROCESS_CHAPTER_SWITCH_FOR_ITEM: 'PROCESS_CHAPTER_SWITCH_FOR_ITEM'
}

export const mutations: MutationTree<CoursePlanManageState> = {
  [Mutations.SET_CHAPTER_LIST] (state, chapterIds) {
    state.chapterList = chapterIds;
  },

  [Mutations.SET_CHAPTER_ITEM_LIST] (state, payload: { chapterId: EntityId, chapterItemIds: EntityId[] }) {
    state.entities.chapters[payload.chapterId].chapterItems = payload.chapterItemIds;
  },

  [Mutations.UPDATE_ENTITIES] (state, entities) {
    Object.assign(state.entities, entities);
  },
  
  [Mutations.REMOVE_CHAPTER] (state, chapterId: EntityId) {
    Vue.set(state.entities.chapters[chapterId], 'deleted', true);
  },

  [Mutations.REMOVE_CHAPTER_ITEM] (state, chapterItemId: EntityId) {
    Vue.set(state.entities.chapterItems[chapterItemId], 'deleted', true);
  },

  [Mutations.SET_CHAPTER_TITLE] (state, payload: { chapterId: EntityId, title: string }) {
    state.entities.chapters[payload.chapterId].title = payload.title;
  },

  [Mutations.ADD_CHAPTER] (state, payload: { chapter: Chapter, insertAfter: EntityId }) {
    Vue.set(state.entities.chapters, payload.chapter.id, payload.chapter);
    
    if (payload.insertAfter) {
      const index = state.chapterList.indexOf(payload.insertAfter);
      state.chapterList.splice((index + 1), 0, payload.chapter.id);
    } else {
      state.chapterList.push(payload.chapter.id);
    }
  },

  [Mutations.SET_ADD_TO_CHAPTER] (state, chapterId: EntityId) {
    state.addToChapter = chapterId;
  },
  
  [Mutations.SET_SEARCH_URL] (state, url: string) {
    state.searchUrl = url;
  },
  
  [Mutations.ADD_CHAPTER_ITEM] (state, chapterItem: ChapterItem) {
    Vue.set(state.entities.chapterItems, chapterItem.id, chapterItem);
    state.entities.chapters[chapterItem.chapterId].chapterItems.push(chapterItem.id);
  },

  [Mutations.REFRESH_POSITIONS] (state) {
    const chapters = state.chapterList.filter(id => !state.entities.chapters[id].deleted);
    for (let i = 0; i < chapters.length; i++) {
      const chapterId = chapters[i];
      state.entities.chapters[chapterId].position = i+1;

      const chapterItems = state.entities.chapters[chapterId].chapterItems
        .filter(itemId => !state.entities.chapterItems[itemId].deleted);

      for (let j = 0; j < chapterItems.length; j++) {
        const itemId = chapterItems[j];
        state.entities.chapterItems[itemId].position = j+1;
      }
    }
  },

  [Mutations.PROCESS_CHAPTER_SWITCH_FOR_ITEM] (state, payload: { chapterId: EntityId, chapterItemId: EntityId }) {
    const newId = uuid();
    state.entities.chapterItems[newId] = state.entities.chapterItems[payload.chapterItemId];
    let item = state.entities.chapterItems[newId];
    Vue.set(item, 'id', newId);
    const itemIndex = state.entities.chapters[payload.chapterId].chapterItems.indexOf(payload.chapterItemId);
    state.entities.chapters[payload.chapterId].chapterItems.splice(itemIndex, 1, newId);
    delete state.entities.chapterItems[payload.chapterItemId];
  },
  
  [Mutations.ADD_DELETED_CHAPTER_ITEM] (state,  payload: { chapterId: EntityId, chapterItemId: EntityId }) {
    Vue.set(state.entities.chapterItems, payload.chapterItemId, {});
    let item = state.entities.chapterItems[payload.chapterItemId];
    
    Vue.set(item, 'id', payload.chapterItemId);
    Vue.set(item, 'deleted', true);

    if (typeof payload.chapterItemId === 'number') {
      Vue.set(item, 'originId', payload.chapterItemId);
      Vue.set(item, 'originChapterId', payload.chapterId);
    }
  }
}
