import { toggleVisibility } from '../../common/helpers';
import { Autoplay } from '../mixins/autoplay';

export class Slideshow {
  constructor(slides_id, { height = 480 } = {}) {
    this.slides_id = slides_id;
    this.element = document.getElementById(slides_id);
    this.slideContainer = this.element.parentElement;
    this.tracking = this.element.getAttribute('data-tracking');
    this.slider = null;
    this.inFullScreen = false;
    this.upNextInterval = null;
    this.started = false;
    this.height = height;
    this.initSlider();
    this.initListeners();
  }

  initSlider() {
    let height = document.querySelectorAll('.player-wrapper').length > 0 ? this.height : 300;

    this.slider = new IdealImageSlider.Slider({
      selector: '#' + this.slides_id,
      height: height,
      interval: 10000,
      transitionDuration: 240,
      effect: 'fade',
      onInit: () => {
        this.slideContainer.querySelector('.iis-previous-nav').classList.add('prev');
        this.slideContainer.querySelector('.iis-next-nav').classList.add('next');
        this.updateProgress(1);
      },
      afterChange: () => {
        var slideNum = this.slideContainer.querySelector('.iis-current-slide').getAttribute('data-page');
        this.slideContainer.querySelector('.page-indicator .current').value = slideNum;
        $(this.slideContainer).trigger('slideChanged', [slideNum, $(this.slideContainer).find('.page-indicator .total-slides').text()]);
        this.updateProgress(slideNum);
      },
      onStart: () => {
        this.slideContainer.querySelector('.play').style.display = 'none';
        this.slideContainer.querySelector('.pause').style.display = 'block';
        this.started = true;
      },
      onStop: () => {
        this.slideContainer.querySelector('.play').style.display = 'block';
        this.slideContainer.querySelector('.pause').style.display = 'none';
        this.started = false;
      },
      beforeChange: () => {
        let totalSlides = this.slideContainer.querySelector('.total-slides').innerHTML;
        let slideNum = this.slideContainer.querySelector('.iis-current-slide').getAttribute('data-page');
        if (this.started && slideNum === totalSlides) {
          this.slider.stop();
          new Autoplay();
        }
      }
    });

    if (this.element.parentNode) {
      this.element.parentNode.style.height = `${height + 30}px`;
    }

    if (this.slideContainer.hasAttribute('autoplay')) {
      this.slider.start();
    }
  }

  updateProgress(slideNum) {
    let updateProgressUrl = this.element.getAttribute('data-update-progress-url');
    if (updateProgressUrl !== 'null' && this.tracking && _app.info.user.loggedIn && slideNum > 0) {
      $.ajax({
        url: updateProgressUrl,
        type: 'POST',
        data: { slide: slideNum }
      });
    }
  }

  initListeners() {
    this.slideContainer.querySelector('input.current').onchange = (e) => {
      this.slider.gotoSlide(e.target.value);
    };
    this.slideContainer.querySelector('input.current').onclick = (e) => {
      e.target.select();
    };

    this.slideContainer.querySelector('.prev').onclick = () => {
      if(!this.slideContainer.querySelector('.slides').classList.contains('iis-is-animating')) {
        this.slider.previousSlide();
      }
    };

    this.slideContainer.querySelector('.next').onclick = () => {
      if (!this.slideContainer.querySelector('.slides').classList.contains('iis-is-animating')) {
        this.slider.nextSlide();
      }
    };

    this.slideContainer.querySelector('.play').onclick = () => {
      this.slider.start();
    };

    this.slideContainer.querySelector('.pause').onclick = () => {
      this.slider.stop();
    };

    this.slideContainer.querySelector('.fullscreen').onclick = () => {
      if (this.slideContainer.requestFullscreen) {
        this.slideContainer.requestFullscreen();
      } else if (this.slideContainer.msRequestFullscreen) {
        this.slideContainer.msRequestFullscreen();
      } else if (this.slideContainer.mozRequestFullScreen) {
        this.slideContainer.mozRequestFullScreen();
      } else if (this.slideContainer.webkitRequestFullscreen) {
        this.slideContainer.webkitRequestFullscreen();
      }
    };

    this.slideContainer.querySelector('.exit-fullscreen').onclick = () => {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    };

    document.addEventListener('webkitfullscreenchange', e => this.exitFullscreenHandler(e), false);
    document.addEventListener('mozfullscreenchange', e => this.exitFullscreenHandler(e), false);
    document.addEventListener('fullscreenchange', e => this.exitFullscreenHandler(e), false);
    document.addEventListener('MSFullscreenChange', e => this.exitFullscreenHandler(e), false);
  }

  exitFullscreenHandler() {
    this.inFullScreen = !this.inFullscreen;
    toggleVisibility(this.slideContainer.querySelector('.fullscreen'));
    toggleVisibility(this.slideContainer.querySelector('.exit-fullscreen'));
  }
}
