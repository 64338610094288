import Vue from 'vue';

export default {
  SET_UPLOAD_URL(state, url) {
    state.uploadUrl = url;
  },

  SAVING(state, saving) {
    state.saving = saving;
  },

  GENERIC_ERROR(state) {
    state.status = 'error';
  },

  UPLOAD_VALID(state, { id, status, info, guessContainsHeadings, settingsUrl, mappingsUrl, reportUrl }) {
    state.id = id;
    state.status = status;
    state.info = info;
    state.step = 'settings';
    state.containsHeadings = guessContainsHeadings;
    state.settingsUrl = settingsUrl;
    state.mappingsUrl = mappingsUrl;
    state.reportUrl = reportUrl;
  },

  UPLOAD_INVALID(state, errors) {
    state.status = 'invalid';
    state.errors = errors;
    state.step = 'upload';
  },

  UPDATE_IMPORT_ACTION(state, value) {
    state.importAction = value;
  },

  UPDATE_CONTAINS_HEADINGS(state, value) {
    state.containsHeadings = value;
  },

  SETTINGS_SAVED(state, { availableFields, columnGuesses }) {
    state.step = 'mappings';

    state.mappings = [];
    availableFields.forEach((field) => {
      const column = columnGuesses.indexOf(field);

      state.mappings.push({
        field: field,
        column: column + 1
      });
    });
  },

  SETTINGS_INVALID(state, errors) {
    state.status = 'invalid';
    state.errors = errors;
    state.step = 'upload';
  },

  UPDATE_MAPPING(state, { field, column }) {
    const index = state.mappings.findIndex(mapping => mapping.field === field);
    Vue.set(state.mappings, index, { field, column });
  },

  UPDATE_COLUMN_SEPERATOR(state, value) {
    state.columnSeperator = value;
  },

  RESET_UPLOAD_STATE(state) {
    state.file = null;
    state.id = null;
    state.info = {};
    state.settingsUrl = null;
    state.mappingsUrl = null;
    state.reportUrl = null;
  },

  RESET_SETTINGS_STATE(state) {
    state.columnGuesses = [];
    state.availableFields = [];
    state.mappings = [];
  },

  SET_STEP(state, step) {
    state.step = step;
  }
};
