import { schema } from 'normalizr';
import { answerSchema } from './answer';
import { matchingAnswerSchema } from './matching_answer';
import { v4 as uuid } from 'uuid';

export enum QuestionType {
  single = 'single_choice',
  statement = 'statement',
  numerical = 'numerical',
  imagemap = 'imagemap',
  open = 'open',
  arrangement = 'arrangement',
  matching = 'matching',
  drag_and_drop = 'drag_and_drop',
  fill_the_blank = 'fill_the_blank',
}

export class Question {
  public id?: EntityId = null;
  public image?: string = null;
  public imageFileName?: string = null;
  public imageUpload?: Boolean = false;
  public editors?:  number[] = [];
  public position?: number = null;
  public type: QuestionType = null;
  public question?: string = null;
  public createdAt?: string = null;
  public updatedAt?: string = null;
  public answers?: Array<{ id: EntityId, schema: string }> = [];
  public deleted?: boolean = false;
  public points?: number = 0;
  public valid?: boolean = true;
  public distractors?: string = "";

  // Do not validate new question on first view
  // Should be set to true when question editor was opened
  public validateOnView?: boolean = false;

  constructor(attributes: Question = null) {
    this.id = uuid();

    if (attributes) {
      Object.assign(this, attributes);
    }
  }
}

export const answerValues = new schema.Union(
  {
    answers: answerSchema,
    matchingAnswers: matchingAnswerSchema
  },
  (input, parent, key) => {
    if (input.type === 'Quiz::Answer::Matching') {
      return 'matchingAnswers';
    } else {
      return 'answers';
    }
  }
);

export const questionSchema = new schema.Entity('questions', {
  answers: [answerValues]
}, {
  processStrategy: (entity) => Object.assign(entity, { imageUpload: null, deleted: false, valid: true, validateOnView: true, editors: [], distractors: entity.distractors || "" })
});
