import { Mutations } from "./mutations";

export const Actions = {
  SET_STATE: 'playlist/setState',
  SET_ELEMENTS: 'playlist/setElements',
  SHOW_COMPONENT: 'playlist/setShowComponent',
  ADD_ELEMENT: 'playlist/addElementToPlaylist',
  REMOVE_ELEMENT: 'playlist/removeElementFromPlaylist',
  SET_COLLABORATIVE_STATE: 'playlist/setCollaborativeState'
};

export const actions = {
  setState({ commit }, values) {
    commit(Mutations.SET_STATE, values);
  },
  setElements({ commit }, values) {
    commit(Mutations.SET_ELEMENTS, values);
  },
  setShowComponent({ commit }, value) {
    commit(Mutations.SHOW_COMPONENT , value);
  },
  addElementToPlaylist( { commit }, element) {
    commit(Mutations.ADD_ELEMENT, element);
  },
  removeElementFromPlaylist( { commit }, elementId) {
    commit(Mutations.REMOVE_ELEMENT, elementId);
  }
};

