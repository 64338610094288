import { Answer, Question } from "../entities";
import store from "../../../store";

export class MatchingAnswerSerializer {
  protected serializedAnswer = {};

  constructor(answer: Answer, question: Question) {
    this.serializedAnswer = {
      id: typeof answer.id === 'string' ? null : answer.id,
      _destroy: answer.deleted,
      points: answer.points,
      text: answer.text,
      type: answer.type,
      matching_option_attributes: {
        id: typeof answer.matchingOption === 'string' ? null : answer.matchingOption,
        text: store.state.quizManage.entities.matchingOptions[answer.matchingOption].text
      }
    }
  }

  public getSerialized() {
    return this.serializedAnswer;
  }
}
