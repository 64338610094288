<template>
  <div class="tutorials-breadcrumb page-subnav">
    <ol class="breadcrumb">
      <li><router-link to="/">Tutorials</router-link></li>
      <li v-if="currentVideo">{{ currentVideo.title }}</li>
    </ol>
  </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex';
  const { mapActions, mapGetters } = createNamespacedHelpers('tutorials');

  export default {
    computed: mapGetters(['currentVideo'])
  }
</script>

<style lang="scss" scoped>
  .tutorials-breadcrumb {
    position: relative;
    background-color: var(--navbar-background-color);
    height: 80px;
    margin-top: -1px;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: var(--navbar-border-color);
  }
</style>
