import { state } from './store/state';
import type { UserSettings } from './store/state';
import logger from '../logger/logger';

const baseLsKey = 'web_conferences_settings';

function lsKey() {
  return `${baseLsKey}_${state.userDetails!.id}`;
}

export async function getUserSettingsFromLS(): Promise<Partial<UserSettings>> {
  const lsValue = window.localStorage.getItem(lsKey());

  if (!lsValue) {
    return {};
  }

  try {
    return JSON.parse(lsValue) as Partial<UserSettings>;
  } catch (e) {
    logger.error({
      message: 'parse user settings failed',
      error: e,
      section: 'user_settings_store:getUserSettingsFromLS',
    });

    if (e instanceof Error && e.name === 'SyntaxError') {
      console.error('Stored Settings could not be parsed:', e.message);
    } else {
      throw e;
    }
  }

  return {};
}

export function saveUserSettingsToLS(userSettings: UserSettings): void {
  window.localStorage.setItem(lsKey(), JSON.stringify(userSettings));
}
