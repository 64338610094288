import { render, staticRenderFns } from "./slide_item.vue?vue&type=template&id=68fedeb7&scoped=true&"
import script from "./slide_item.vue?vue&type=script&lang=ts&"
export * from "./slide_item.vue?vue&type=script&lang=ts&"
import style0 from "./slide_item.vue?vue&type=style&index=0&id=68fedeb7&lang=scss&scoped=true&"
import style1 from "./slide_item.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68fedeb7",
  null
  
)

export default component.exports