import { Busy } from '../globals/busy';
import { fireEvent } from '../globals/custom_event';
import { DateFilter } from './filter/date';
import { Bar } from './filter/bar';

export class Dashboard {
  constructor() {
    this.uuid = document.querySelector('.contribution-dashboard').getAttribute('data-uuid');
    this.wrapper = document.querySelector('.contribution-dashboard');
    this.filter = {};
    this.filterContainer = document.querySelector("#filter_dialog_" + this.uuid);
    this.busy = new Busy();
    this.filterInput = document.querySelector('.analytics-filter-control');
    this.dateFilter = new DateFilter(this);
    this.initFilter();
  }

  initFilter() {
    this.filter["from"] = this.dateFilter.element.getAttribute('data-from');
    this.filter["till"] = this.dateFilter.element.getAttribute('data-till');

    // Initilize Filter Controlbar if it exist
    if(this.filterInput) {
      this.filterBar = new Bar(this.filterInput, this);
    }
  }

  filterParams() {
    let filter_array = $(this.filterContainer).find(".dashboard-filters").serializeArray();
    this.filter = {};
    this.initFilter();

    for (let i in filter_array) {
      if (filter_array[i].value === undefined || filter_array[i].value === '') {
        continue;
      } else if (this.filter[filter_array[i].name] === undefined || this.filter[filter_array[i].name] === '') {
        this.filter[filter_array[i].name] = [filter_array[i].value];
      } else {
        this.filter[filter_array[i].name].push(filter_array[i].value);
      }
    };
  }

  getLocatedTimeInterval() {
    return this.dateFilter.getDisplayedTimeInterval();
  }

  setIntervalFilter() {
    this.filter["from"] = this.dateFilter.element.getAttribute('data-from');
    this.filter["till"] = this.dateFilter.element.getAttribute('data-till');
    if (this.filter["from"] === "" || this.filter["till"] === "") {
      this.filter["unlimited"] = true;
    } else {
      this.filter["unlimited"] = false;
    }
  }

  setActiveBarFilter() {
    this.filter.customFilter = {};
    this.setTopicAreaFilter();
    this.setYoursOnlyFilter();
  }

  setYoursOnlyFilter() {
    let activeElement = this.filterInput.parentElement.querySelector('.analytics-filter-label.yours-only');
    if (activeElement) {
      this.filter.customFilter.yoursOnly = true;
    }
  }

  setTopicAreaFilter() {
    let activeElements = this.filterInput.parentElement.querySelectorAll('.analytics-filter-label.topicarea, .analytics-filter-label.topicarea_multiselect');
    let active = [];
    if (activeElements.length === 0) { return; }

    activeElements.forEach(element => {
      active.push(element.getAttribute('data-id'));
    });

    this.filter.customFilter.topicareas = active;
  }

  triggerFilterChanges(element, event) {
    if (!element) { element = document; }
    if (!event) { event = 'filterChanges'; }
    fireEvent(element, event);
  }
}
