import { LessonSerializer } from './lesson_serializer';

export class TopicQuizLessonSerializer extends LessonSerializer {
  serialize(lesson) {
    let attributes = super.serialize(lesson);

    attributes.questions_attributes = lesson.questions.map((question) => {
      let questionAttributes = {
        title: question.title,
        time: question.time,
        randomize: question.randomize,
        hint: question.hint,
        type: question.type,
        options_attributes: question.options.map((option) => {
          let optionAttributes = {
            title: option.title,
            correct: option.correct,
            position: option.position
          };

          if (!option.isNew) {
            optionAttributes.id = option.id;
          }

          if (option.deleted) {
            optionAttributes = { id: option.id, _destroy: true };
          }

          return optionAttributes;
        })
      };

      if (!question.isNew) {
        questionAttributes.id = question.id;
      }

      if (question.deleted) {
        questionAttributes = { id: question.id, _destroy: true };
      }

      return questionAttributes;
    });

    return attributes;
  }
}

export default function serializeTopicQuizLesson(lesson) {
  const serializer = new TopicQuizLessonSerializer();
  return serializer.serialize(lesson);
}
