export default {
  setParameters({ commit }, parameters) {
    commit('setParameters', parameters);
  },

  setCourse({ commit }, course) {
    commit('setCourse', course);
  },

  fetchLessonDetail({ commit }, lesson) {
    if (!lesson.loaded) {
      fetch(lesson.detailUrl, { credentials: 'include' })
        .then(response => response.json())
        .then(response => {
          const lessonDetail = response;

          commit('setLessonDetail', { lesson, lessonDetail });
        });
    }
  },

  updateLessonProgress({ commit }, { lesson, progress }) {
    commit('updateLessonProgress', { lesson, progress });
  },

  setCurrentPlaylistElement({ commit }, playlistElement) {
    commit('setCurrentPlaylistElement', playlistElement);
  },

  setCurrentPlaylistElementProgress({ commit }, progress) {
    commit('setCurrentPlaylistElementProgress', progress);
  },

  setQuizCompleted({ commit }) {
    commit('setQuizCompleted');
  },
  
  checkCompleted({ commit, state }) {
    if (!state.course.completedAt) {
      fetch(state.course.checkCompleted, { credentials: 'include' })
        .then(response => response.json())
        .then(response => {
          commit('setCompleted', response.completedAt);
          commit('setCourseConfirmation', {
            needsConfirmation: response.needsConfirmation,
            confirmedAt: response.confirmedAt,
            confirmUrl: response.confirmUrl
          });
        });
    }
  },

  checkCompletedBeacon({ state }) {
    navigator.sendBeacon(state.course.checkCompleted);
  },

  confirmCourse({ commit, state }) {
    if (!state.course.confirmedAt) {
      fetch(state.course.confirmUrl, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        }
      }).then(response => response.json())
        .then(response => {
          commit('setCourseConfirmation', { confirmedAt: response.confirmedAt });
        });
    }
  }
};
