import { LocalAudioTrack, LocalParticipant, LocalVideoTrack, Participant } from 'livekit-client';
import { ChatMessage } from '../entities/chatMessage';
import { ModalInstance } from '../entities/modalInstance';
import { Notification } from '../entities/notification';
import { ConferenceLayout } from '../entities/ConferenceLayout';
import { Room } from 'livekit-client/dist/proto/livekit_models';
import type { CustomBackgroundImage } from '../../common/background_replacement/types/custom_background_image';
import type { BackgroundReplacement } from '../../common/background_replacement/types/background_replacement';

export interface UserDetails {
  avatar: string;
  firstname: string;
  id: number;
  isGuest: boolean;
  lastname: string;
  recordingPermission: boolean;
  scope: string;
  username: string;
}

export interface Settings {
  askForRecordingPermission: boolean;
  recordingAllowed: boolean;
}

export interface UserSettings {
  selectedAudio: MediaDeviceInfo['deviceId'];
  selectedCam: MediaDeviceInfo['deviceId'];
  selectedMic: MediaDeviceInfo['deviceId'];
  conferenceLayout: ConferenceLayout; // This number changes the Layout of the conference
  noiseSuppression: boolean;
  playEventAudio: boolean;
  backgroundReplacement: undefined | BackgroundReplacement;
}

export interface WebConferenceState {
  room: Room;
  details: any; //Shows all Details from the Web-Conference
  settings: Settings; //Shows all the settings details which were set from the admin
  userDetails: UserDetails; //Shows the all UserDetails
  participants: Participant[];  //all Participants get safed here
  localParticipant: LocalParticipant; //me (the local participant) get safed here
  chatMessages: ChatMessage[]; //All Chat Messages get safed here
  roomName: string;
  isGuest: boolean; //Shows if I am logged in or not
  startingTime: Date; //Shows when the Conference started
  isRecording: boolean; //Shows if the Conference gets recorded
  modals: ModalInstance[]; //Here are all Modals for Notifications
  settingsOpened: boolean; //If True - open Settings Modal
  breakoutStarted: boolean,
  isBreakout: boolean,
  livekitUrl: string;
  onJoinPage: boolean;

  urls: any

  sharedScreenParticipant: Participant; //This is the Participant which
  currentWebcamId: MediaDeviceInfo['deviceId'] | null;
  currentMicrophoneId: MediaDeviceInfo['deviceId'] | null;
  webcamLoading: boolean;
  localVideoTrack: LocalVideoTrack; //This is the localVideoTrack which got created on the JOIN-Page
  localAudioTrack: LocalAudioTrack; //This is the localAudioTrack which got created on the JOIN-Page
  micActivated: boolean;
  camActivated: boolean;
  raiseHandNotifications: any[];
  showCameraError: boolean;
  notifications: Notification[];//All Notifications get safed here - most of them get delete after 30 seconds

  userSettings: UserSettings;
  customBackgroundImages: CustomBackgroundImage[];
}

export const state: WebConferenceState = {
  room: null,
  details: null,
  settings: null,
  userDetails: null,
  participants: null,
  localParticipant: null,
  chatMessages: [],
  roomName: null,
  isGuest: null,
  startingTime: null,
  isRecording: null,
  modals: [],
  settingsOpened: false,
  breakoutStarted: false,
  isBreakout: false,
  livekitUrl: null,
  onJoinPage: null,

  urls: null,

  sharedScreenParticipant: null,
  currentWebcamId: null,
  currentMicrophoneId: null,
  webcamLoading: false,
  localVideoTrack: null,
  localAudioTrack: null,
  camActivated: true,
  micActivated: true,
  raiseHandNotifications: [],
  showCameraError: false,
  notifications: [],

  userSettings: {
    selectedMic: null,
    selectedCam: null,
    selectedAudio: null,
    conferenceLayout: ConferenceLayout.Standard,
    noiseSuppression: true,
    playEventAudio: true,
    backgroundReplacement: undefined,
  },
  customBackgroundImages: [],
};
