import { t } from './localization';

export function confirmDialog(options) {
  return new Promise((resolve, reject) => {
    options = Object.assign({
      width: '450px',
      closable: false,
      title: t('dialog_confirm_title'),
      content: t('dialog_confirm_text'),
      modal: true,
      buttonLayout: 'normal',
      actions: [{
        text: t('dialog_confirm_ok'),
        action: resolve,
        primary: true
      }, {
        text: t('dialog_confirm_cancel'),
        action: reject
      }],
      close: function() { this.destroy(); }
    }, options);

    $('<div/>').kendoDialog(options).data('kendoDialog').open();
  });
}

export function alertDialog(options) {
  return new Promise((resolve, reject) => {
    options = Object.assign({
      width: '450px',
      closable: true,
      title: t('dialog_alert_title'),
      content: t('dialog_alert_text'),
      modal: true,
      buttonLayout: 'normal',
      actions: [{
        text: t('dialog_alert_ok'),
        action: resolve,
        primary: true
      }],
      close: function() { this.destroy(); }
    }, options);

    $('<div/>').kendoDialog(options).data('kendoDialog').open();
  });
}
