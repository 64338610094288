import { MouseAction } from "./mouse_action";

/**
 * MouseMoveAction are triggered by mouse movements of users
 * When executed they show a visual representation of the users cursor in other users interfaces
 */
export class MouseMoveAction extends MouseAction {
  constructor(tabId, user, element, x, y) {
    /**
     * @constructor
     * @param {String} tabId  - TabId of the tab the action originates from
     * @param {number} user - UserId of the chat room owner
     * @param {String} element - Element thats subject of the UIAction
     * @param {number} x - Horizontal coordinates of the MouseClickAction
     * @param {number} y - Vertical coordinates of the MouseClickAction
     */
    super(tabId, user, element, x, y);
    this.actionType = "MouseMoveAction";
  }
  // time between cursor moves action be send
  static get MIN_TIME() {
    return 100;
  }

  execute() {
    let tabId = MouseMoveAction.getTabId();
    if (this.tabId !== tabId || this.element.length === 0) {
      return;
    }
    // make relative offset absolute again (according to element dimensions)
    let relativeOffsetY = this.element.offset().top + (this.y * this.element.outerHeight());
    let relativeOffsetX = this.element.offset().left + (this.x * this.element.outerWidth());
    // find avatar with users id and take it's color as the color for the cursor
    let cursor;
    let chat = collabCenter.chat;
    let color = this.user === chat.owner ? chat.options.ownerColor : chat.options.colors[this.user % 20];
    if ($('.communication-user-avatar[data-user_id="' + this.user + '"]').length > 0) {
      if ($("#editor_user_" + this.user + "_cursor")) {
        $("#editor_user_" + this.user + "_cursor").remove();
      }
      let userAvatar = $('.communication-user-avatar[data-user_id="' + this.user + '"]');
      let authorId = userAvatar.attr("id");
      // create cursor element if not created yet
      if ($("#" + authorId + "_cursor").length === 0) {
        //let cursor = $("<object id='" + authorId + "_cursor' class='cursor' data='http://jm.verstehe.local:3000/assets/Cursor2.svg' type='image/svg+xml'></object>");
        cursor = $('<svg id="' + authorId + '_cursor" class="cursor" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 24 24" version="1.0" xml:space="preserve" width="12" height="20"><rect id="backgroundrect" width="100%" height="100%" x="0" y="0" fill="none" stroke="none" class="" /><g class="currentLayer"><title>Layer 1</title><path d="M0,-5.960464477539064e-8 l12,11.2 l-5.8,0.5 l3.3,7.3 l-2.2,1 l-3.2,-7.4 L0,16.499999940395355 V-5.960464477539064e-8 " id="svg_1" class="" /></g></svg>');
        $(document.body).append(cursor);
      } else {
        cursor = $("#" + authorId + "_cursor");
      }
    } else {
      if ($("#editor_user_" + this.user + "_cursor").length === 0) {
        cursor = $('<svg id="editor_user_' + this.user + '_cursor" class="cursor" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 24 24" version="1.0" xml:space="preserve" width="12" height="20"><rect id="backgroundrect" width="100%" height="100%" x="0" y="0" fill="none" stroke="none" class="" /><g class="currentLayer"><title>Layer 1</title><path d="M0,-5.960464477539064e-8 l12,11.2 l-5.8,0.5 l3.3,7.3 l-2.2,1 l-3.2,-7.4 L0,16.499999940395355 V-5.960464477539064e-8 " id="svg_1" class="" /></g></svg>');
        $(document.body).append(cursor);
      } else {
        cursor = $("#editor_user_" + this.user + "_cursor");
      }
    }
    cursor.css({
      fill: color,
      top: relativeOffsetY,
      left: relativeOffsetX
    });
  }

  static fromJson(json) {
    let action = JSON.parse(json);
    return new MouseMoveAction(action.tabId, action.user, action.elementLocation, action.x, action.y);
  }

  static registerToElement() {
    $(document).mousedown(function() {
      VERSTEHE.CollaborativeAuthoring.mouseDown = true;
    }).mouseup(function() {
      VERSTEHE.CollaborativeAuthoring.mouseDown = false;
    });
    $(document).on("mousemove", function(event) {
      if (event.target === document) {
        return;
      }
      let now = Date.now();
      // slow down cursor stream (reduce spamming)
      // MouseMoveAction.MIN_TIME is the minimal time between the transmission of two cursor-update actions
      if (now - VERSTEHE.CollaborativeAuthoring.lastTime < MouseMoveAction.MIN_TIME) {
        return;
      }
      VERSTEHE.CollaborativeAuthoring.lastTime = now;
      let x = event.pageX;
      let y = event.pageY;
      // only send action if the movement was substantial (reduce spamming)
      // if the change of coordinates was smaller than 3 pixels there will not be a new cursor-update action
      if (Math.abs(VERSTEHE.CollaborativeAuthoring.lastX - x) < 3 && Math.abs(VERSTEHE.CollaborativeAuthoring.lastY - y) < 3) {
        return;
      }
      VERSTEHE.CollaborativeAuthoring.lastX = x;
      VERSTEHE.CollaborativeAuthoring.lastY = y;
      let target = event.target;
      if (VERSTEHE.CollaborativeAuthoring.mouseDown) {
        target = $('body');
      }
      let elementLocation = VERSTEHE.ElementFinder.ElementFinder.elementToLocation(target);
      if (elementLocation == null) {
        return;
      }
      // event.page coordinates subtracted by the offset of the element results in the cursor position over the element
      // will help for different screen sizes and scroll positions
      // relative offset helps when elements have different dimension due to different screen sizes
      let relativeOffsetX = Math.floor((Math.floor(event.pageX - $(target).offset().left) / $(target).outerWidth()) * 100) / 100;
      let relativeOffsetY = Math.floor((Math.floor(event.pageY - $(target).offset().top) / $(target).outerHeight()) * 100) / 100;
      let action = new MouseMoveAction(MouseMoveAction.getTabId(), _app.info.user.userId, target, relativeOffsetX, relativeOffsetY);
      VERSTEHE.content_channel.send_message(action);
    });
  }
}
