import { MutationTree } from 'vuex';
import Vue from 'vue';
import { CustomState } from './state';

export const mutations: MutationTree<CustomState> = {
  setState(state , payload) {
    Object.assign(state, payload);
  },
  setPausedState(state, paused: boolean) {
    state.paused = paused;
  }
}
