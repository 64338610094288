export class SignIn {
  constructor () {
    if (document.querySelector('.login-page-container')) {
      this.initFeatureSlider();
      this.bindListeners();
    }
  }

  initFeatureSlider () {
    $('.login-page-container .login-features').owlCarousel({
      items: 1,
      loop: true,
      mouseDrag: false,
      dots: true,
      autoplay: true,
      autoplayTimeout: 10000,
      autoplayHoverPause: true
    });
  }

  bindListeners () {
    let loginBackdrop = document.querySelector('.login-backdrop');
    let loginDebug = document.querySelector('.login-debug');
    let loginSignup = document.querySelector('.login-signup');
    let debugToggle = document.querySelector('.login-debug-toggle');
    let newAccount = document.querySelector('.login-page-container .new-account');
    let sendOtpCode = document.querySelector('.login-send-otp-code > a');

    debugToggle && debugToggle.addEventListener('click', () => {
      loginBackdrop.classList.add('show');
      loginDebug.classList.add('show');
    });

    newAccount && newAccount.addEventListener('click', () => {
      loginBackdrop.classList.add('show');
      loginSignup.classList.add('show');
    });

    document.querySelectorAll('.login-page-container .back-link').forEach((element) => {
      element.addEventListener('click', () => {
        loginBackdrop.classList.remove('show');
        loginDebug && loginDebug.classList.remove('show');
        loginSignup.classList.remove('show');
      });
    });

    $(sendOtpCode).on('ajax:before', () => {
      document.querySelector('.login-send-otp-code-success').classList.add('hidden');
      document.querySelector('.login-send-otp-code-error').classList.add('hidden');
    });
    
    $(sendOtpCode).on('ajax:success', () => {
      document.querySelector('.login-send-otp-code-success').classList.remove('hidden');
    });

    $(sendOtpCode).on('ajax:error', () => {
      document.querySelector('.login-send-otp-code-error').classList.remove('hidden');
    })
  }
}
