export const locals = {
  de: {
    from: "Von",
    till: "Bis",
    acceptFilter: "Anwenden",
    topicarea_multiselect: "Filtern nach Themenbereich",
    topicarea_multi_placeholder: "Wählen Sie einen Themenbereich"
  },
  en: {
    from: "From",
    till: "Till",
    acceptFilter: "Apply",
    topicarea_multiselect: "Filter by topic area",
    topicarea_multi_placeholder: "Select a topic area"
  },
  es: {
    from: "Desde",
    till: "Hasta",
    acceptFilter: "Aplicar",
    topicarea_multiselect: "Filtrar por área temática",
    topicarea_multi_placeholder: "Seleccione un área temática"
  }
};

export function t(key) {
  return locals[_app.info.currentLocale][key] || key;
}

