import AudioPlayer from './audio_player';
import { flashMessage } from '../mixins/flash_message';

export let wrapper, parameters, ssmlEditor, playerWrapper, audioPlayer, ttsAvailable;
let durationInput, durationSlider;

export function initModal (params) {
  parameters = params;
  wrapper = document.getElementById('scene_settings_modal');
  playerWrapper = wrapper.querySelector('.tts-preview-player');
  ttsAvailable = wrapper.querySelector('.slide-settings-modal-content').getAttribute('data-tts-available') === 'true';

  if (ttsAvailable) {
    audioPlayer = new AudioPlayer(playerWrapper, {
      onRequest: () => {
        wrapper.querySelector('.slide-settings-tts-error').classList.add('hide');
      },
      onError: (status, response) => {
        let errorWrapper = wrapper.querySelector('.slide-settings-tts-error');
        errorWrapper.innerText = response.error;
        errorWrapper.classList.remove('hide');
      }
    });

    let options = JSON.parse(wrapper.querySelector('.slide-settings-modal-content').getAttribute('data-synthify'));
    ssmlEditor = new Synthify(wrapper.querySelector('.ssml-editor'), options);
  }


  durationSlider = wrapper.querySelector('.slide-settings-duration');
  durationInput = wrapper.querySelector('.slide-settings-duration-input');

  noUiSlider.create(durationSlider, {
    tooltips: true,
    format: wNumb({ decimals: 0 }),
    start: [ parseInt(parameters.sceneDuration, 10) ],
    step: 1,
    range: {
      min: [ parseInt(durationInput.getAttribute('min'), 10) ],
      max: [ parseInt(durationInput.getAttribute('max'), 10) ]
    }
  });

  setParameters(parameters);
  bindListeners();
}

export function getParameters () {
  if (ttsAvailable) {
    parameters.ttsText = ssmlEditor.getSSML();
    let speakers = ssmlEditor.getSpeakers();

    if (speakers.length > 0) {
      parameters.speaker = speakers[0].name;
    }
  } else {
    parameters.ttsEnabled = false;
    parameters.speaker = '';
    parameters.ttsText = '';
  }
  return parameters;
}

// wait for loaded event to get duration of tts text
export function getLoadedEvent(player){
  return new Promise((resolve)=>{
    const listener = () => {
      player.removeEventListener('loadedmetadata', listener);
      parameters.sceneDuration = player.duration;
      resolve(parameters);
    }
    player.addEventListener('loadedmetadata', listener);
  })
}

export async function getSceneParameters () {
    const ttsActive = ttsAvailable && parameters.ttsEnabled;
    if (ttsActive) {
      parameters.ttsText = ssmlEditor.getSSML();
      const speakers = ssmlEditor.getSpeakers();
      let url = playerWrapper.getAttribute('data-url');
      const text = ssmlEditor.getSSML();

      if (speakers.length > 0) {
        parameters.speaker = speakers[0].name;
        url = `${url}?speaker=${speakers[0].name}&engine=${speakers[0].engine}&text=${encodeURIComponent(text)}`;
        if(audioPlayer.player.readyState != 4){ //if readyState == 4 the player is fully loaded
          audioPlayer.loadSource(url);
          await getLoadedEvent(audioPlayer.player);
        }else{
          parameters.sceneDuration = audioPlayer.player.duration;
        }
      }
    } else {
      parameters.ttsEnabled = false;
      parameters.speaker = '';
      parameters.ttsText = '';
    }
    return parameters;
}

function setParameters(parameters) {
  let typeTts = wrapper.querySelector('.slide-settings-type[value="tts"]'),
      typeStatic = wrapper.querySelector('.slide-settings-type[value="static"]'),
      settingsTts = wrapper.querySelectorAll('.slide-settings-tts'),
      settingsStatic = wrapper.querySelectorAll('.slide-settings-static'),
      sceneDuration = wrapper.querySelector('.slide-settings-duration-input'),
      slidePreview = wrapper.querySelector('.slide-settings-slide-preview img'),
      ttsActive = ttsAvailable && parameters.ttsEnabled;

  if (ssmlEditor) {
    ssmlEditor.setSSML(parameters.ttsText);
  } else {
    typeTts.parentNode.parentNode.style.display = 'none';

  }

  sceneDuration.value = parameters.sceneDuration;
  slidePreview.src = parameters.previewImage;

  // set radio group value
  ttsActive ? typeTts.checked = true : typeStatic.checked = true;

  wrapper.querySelector('.slide-settings-modal-content').setAttribute('data-setting', ttsActive ? 'tts' : 'static');

  for(let i = 0; i < settingsTts.length; i++) {
    ttsActive ? settingsTts[i].classList.remove('hide') : settingsTts[i].classList.add('hide');
  }

  for(let i = 0; i < settingsStatic.length; i++) {
    ttsActive ? settingsStatic[i].classList.add('hide') : settingsStatic[i].classList.remove('hide');
  }
}

function toggleHistory (show) {
  let toggleButton = wrapper.querySelector('.tts-history-toggle');
  let ttsHistory = wrapper.querySelector('.tts-history');
  show = typeof show === 'undefined' ? !toggleButton.classList.contains('active') : show;

  if (show) {
    toggleButton.classList.add('active');
    ttsHistory.classList.add('tts-history-open');
    refreshHistory();
  } else {
    toggleButton.classList.remove('active');
    ttsHistory.classList.remove('tts-history-open');
  }

  toggleButton.blur();
}

function refreshHistory () {
  let paginationAnchors = wrapper.querySelectorAll(".tts-history-pagination a");
  paginationAnchors.forEach((anchor) => {
    if (anchor.getAttribute("href").includes("javascript:void(0)")) {
      // Disable the next or back link in case it is the last or first page already.
      disableAnchor(anchor);
    } else {
      addHistoryPageButtonClickListener(anchor);
    }
  })
}

function disableAnchor(anchor) {
  anchor.pointerEvents = "none"
  anchor.cursor = "default"
  anchor.href = "#"
}

function addHistoryPageButtonClickListener(anchor) {
  anchor.addEventListener('click', (event) => {
    // Overwrite the anchor element from linking to a new page for the given url.
    // Take the url instead and render its result in the current tts-history div.
    event.preventDefault();
    event.stopPropagation();
    let target = event.target || event.srcElement

    // The event can be triggered by either the <a>-tag or a <span>-tag that overlays it, depending on
    // the exact click location. If the user triggers the event on the <span>-tag the url needs to be read
    // from its parent element, the <a>-tag instead.
    // The tagName "SPAN" has to be in upper case, otherwise it cannot be found. The tag is in lowercase in html.
    let url = target.tagName.toString().includes("SPAN")
            ? target.parentElement.getAttribute("href")
            : target.getAttribute("href");

    let ttsHistory = wrapper.querySelector('.tts-history');
    let currentHistory = ttsHistory.innerHTML;
    ttsHistory.innerHTML = ''

    fetchHistoryPage(url, ttsHistory, currentHistory);
  });
}

function fetchHistoryPage(url, ttsHistory, currentHistory) {
  fetch(url).then(function(response) {
    return response.json()
  }).then(function(data) {
    let historyEntriesAsHtml = data.tts_requests;
    let paginationTagAsHtml = data.pagination.html;
    historyEntriesAsHtml.forEach(entry => appendContentToTtsHistory(ttsHistory, entry))
    appendContentToTtsHistory(ttsHistory, paginationTagAsHtml)
    refreshHistory()
  }).catch(function() {
    flashMessage(ttsHistory.getAttribute("data-error-message"))
    appendContentToTtsHistory(ttsHistory, currentHistory);
    refreshHistory();
  });
}

function appendContentToTtsHistory(ttsHistory, toAppend) {
  ttsHistory.innerHTML += toAppend
}

function bindListeners () {
  audioPlayer && audioPlayer.player.addEventListener('beforeplay', (e) => {
    e.preventDefault();
    let url = playerWrapper.getAttribute('data-url');
    let text = ssmlEditor.getSSML();
    let speakers = ssmlEditor.getSpeakers();

    if (speakers.length > 0) {
      url = `${url}?speaker=${speakers[0].name}&engine=${speakers[0].engine}&text=${encodeURIComponent(text)}`;
      audioPlayer.loadAndPlay(url);
    }
  });

  wrapper.querySelector('.tts-history-toggle').addEventListener('click', (e) => {
    toggleHistory();
  });

  let slideSettingsType = wrapper.querySelectorAll('.slide-settings-type');

  for (let i = 0; i < slideSettingsType.length; i++) {
    let element = slideSettingsType[i];

    element.addEventListener('click', (e) => {
      let params = getParameters();
      params.ttsEnabled = element.value === 'tts';
      setParameters(params);
    });
  }

  wrapper.addEventListener('click', e => {
    if (!e.target.classList.contains('tts-history-toggle') && !e.target.closest('.tts-history')) {
      toggleHistory(false);
    }
  });

  durationSlider.noUiSlider.on('update', (values, handle) => {
    parameters.sceneDuration = values[0];
    durationInput.value = values[0];
  });

  wrapper.querySelector('.tts-history').addEventListener('click', (e) => {
    let item = e.target.closest('.tts-history-item');
    let ssml = item.querySelector('.tts-history-item-text').getAttribute('data-ssml');

    ssmlEditor.setSSML(ssml);
  });
}
