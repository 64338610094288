import { isAbsolute } from 'path';
import { AutoReassignSettings } from './auto_reassign_settings_modal';
import { t } from './localization';

export class AutoReassignSettingsOpen {

  constructor () {
    this.configModal = $('#config-modal').kendoWindow({
      modal: true,
      visible: false,
      draggable: false,
      resizable: false,
      pinned: true,
      width: "500px",
      height: "500px",
      title: t('modal_title_auto_reassign'),
    }).data("kendoWindow");

    this.assignSettingModal = new AutoReassignSettings('#reassign-wrapper',() => this.onCallback());

    this.openButton = document.querySelector('#site-setting-auto-reassign-open');
    this.openButton && this.openButton.addEventListener('click', () =>  {
      this.configModal.open();
      this.configModal.center();
    });
  }

  onCallback() {
    this.configModal.close();
  }
}


