import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Inject } from 'vue-property-decorator';
import { Take, Slideshow, Image } from '../interfaces';
import { Getters } from '../store/getters';
import { FadeTransition } from 'vue2-transitions';
import { MapGetter, MapAction } from '../../vue/decorators';
import TakeItem from './take_item.vue';
import ClipItem from './clip_item.vue';
import SlideshowItem from './slideshow_item.vue';
import ImageItem from './image_item.vue';
import LoadingSpinner from '../../globals/loading_spinner.vue';
import { Actions } from '../store/actions';
import { StateChanger } from 'vue-infinite-loading';

@Component({
  name: 'tab-content-component',
  components: {
    FadeTransition,
    LoadingSpinner,
    take: TakeItem,
    slideshow: SlideshowItem,
    clip: ClipItem,
    imageitem: ImageItem,
  },
})
export class TabContentBase extends Vue {
  @Prop(String) protected identifier: string;
  @Prop(Boolean) protected search: boolean;
  @MapGetter(Getters.ACTIVE_TAB) activeTab;
  @MapGetter(Getters.TAB_ITEMS) tabItems;
  @MapAction(Actions.FETCH_MEDIA) fetchMedia;
  @MapAction(Actions.CREATE_FILTER) createFilter;
  @MapGetter(Getters.MEDIA_LAST_WEEK) mediaLastWeek;
  @MapGetter(Getters.MEDIA_THIS_WEEK) mediaThisWeek;
  @MapGetter(Getters.MEDIA_OLDER) mediaOlder;
  @MapGetter(Getters.ACTIVE_FILTERS) activeFilters;

  isLoading = false;

  get infinityId() {
    return this.$store.getters[Getters.INFINITY_ID](this.identifier);
  }

  infiniteHandler(infinite: StateChanger) {
    this.isLoading = true;
    if (this.activeFilters.length > 0) {
      this.createFilter(this.activeFilters).then((hasMore: boolean) => {
        this.onInfinityLoadDone(infinite, hasMore);
      });
    } else {
      this.fetchMedia(this.identifier).then((hasMore: boolean) => {
        this.onInfinityLoadDone(infinite, hasMore);
      });
    }
  }

  onInfinityLoadDone(infinite: StateChanger, hasMore: boolean) {
    this.$root.$emit(`${this.identifier}-loaded`);
    this.isLoading = false;
    if (hasMore) {
      infinite.loaded();
    } else {
      infinite.complete();
    }
  }

  get libraryItems(): Array<Take | Slideshow | Image> {
    return this.tabItems(this.identifier);
  }

  get thisWeekItems(): Array<Take | Slideshow | Image> {
    return this.mediaThisWeek(this.identifier, this.activeTab);
  }

  get lastWeekItems(): Array<Take | Slideshow | Image> {
    return this.mediaLastWeek(this.identifier, this.activeTab);
  }

  get olderItems(): Array<Take | Slideshow | Image> {
    return this.mediaOlder(this.identifier, this.activeTab);
  }

  resetInfiniteScroll() {
    // Is called by the media library in case a filter was applied or removed to reset the infinity scroll component.
    // The implementation is overwritten by the vue tab components.
  }
}
