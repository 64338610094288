export class Renderer {
  constructor(options) {
    this.type = options['type'];
    this.selector = options['selector'];
    this.width = options['width'];
    this.height = options['height'];
  }

  render(data) {
    if (this.type === 'table') {
      this.renderTable(data);
    } else if(this.type === 'stock-line' ) {
      this.renderStockChart(data);
    } else {
      this.renderHighchart(data);
    }
  }

  renderTable(data) {
    $('#chart-1').kendoGrid({
      columns: data.columns,
      dataSource: {
        data: data.data
      },
      groupable: true,
      scrollable: true,
      resizable: false,
      sortable: true,
      height: this.height,
      width: this.width,
      pageable: {
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 'all'],
        buttonCount: 5
      }
    });
  }

  renderStockChart(data) {
    Highcharts.stockChart(this.id, this.disableChartExport(data));
  }

  renderHighchart(data) {
    Highcharts.chart("chart-1", this.setChartOptions(data));
  }

  setChartOptions(data){
    data = this.setHeightAndWidth(data);
    data = this.disableChartExport(data);

    return data;
  }

  setHeightAndWidth(data) {
    data.chart.height = this.height;
    data.chart.width = this.width;

    return data;
  }

  disableChartExport(data) {
    data['exporting'] = { enabled: false };
    return data;
  }
}
