import { schema } from 'normalizr';
import { v4 as uuid } from 'uuid';

export class MatchingOption {
  public id?: EntityId = null;
  public text?: string = null;

  constructor(attributes: MatchingOption = null) {
    this.id = uuid();
    if (attributes) {
      Object.assign(this, attributes);
    }
  }
}

export const matchingOptionSchema = new schema.Entity('matchingOptions');
