import { ChangeAction } from "../../system_actions/change_action";
import { Actions as PlaylistActionTypes } from '../../../../playlists/store/actions';
import store from '../../../../store';

const mutationWhitelist = [
  'playlist/SET_ELEMENTS',
  'playlist/ADD_ELEMENT',
  'playlist/REMOVE_ELEMENT'
];

const stateWhitelist = [
  'elements',
  'deletedElements'
];

/**
 * PlaylistAction handles changes that occur while editing a Playlist
 */
export class PlaylistAction extends ChangeAction {
  /**
   * @constructor
   * @param {string} tabId  - tabId of the tab the action originates from
   * @param {number} user - userId of the chat room owner
   * @param {String} element - element thats subject of the ChangeAction
   * @param {number} data - data (state of the vue sore or other payload)
   * @param {String} type - type of the PlaylistAction
   */
  constructor(tabId, user, element, data, type) {
    super(tabId, user, element);
    this.data = data;
    this.type = type;
    this.actionType = "PlaylistAction";
    this.actionTypeNo = VERSTEHE.CollaborativeActions.ACTIONNAMES.indexOf(this.actionType);
  }

  execute() {
    if (this.type === "playlist/change") {
      store.dispatch(PlaylistActionTypes.SET_STATE, PlaylistAction.whitelistedState(this.data));
    }
  }

  isComparableTo(other) {
    return this.type === other.type;
  }

  reduce(other) {
    return this;
  }

  static fromJson(json) {
    let action = JSON.parse(json);
    return new PlaylistAction(action.tabId, action.user, action.elementLocation, action.data, action.type);
  }

  static registerToElement() {
    if(VERSTEHE.PlaylistManage.playlistComponent) {
      store.subscribe((mutation, state) => {
        if (mutationWhitelist.includes(mutation.type)) {
          PlaylistAction.createAction(
            PlaylistAction.getTabId(),
            _app.info.user.userId,
            null,
            PlaylistAction.whitelistedState(state.playlist),
            "playlist/change"
          );
        }
      });
    }
  }

  static createAction(tabId, user, element, data, type) {
    let action = new PlaylistAction(tabId, user, element, data, type);
    VERSTEHE.content_channel.send_message(action);
  }

  /**
   * return a new object only with keys that are whitlisted in 'stateWhitelist'
   */
  static whitelistedState(state) {
    return Object.keys(state).filter(key => stateWhitelist.includes(key)).reduce((obj, key) => {
      obj[key] = state[key];
      return obj;
    }, {});
  }
}
