import Vue from 'vue';
import Vuex from 'vuex';
import { RootState } from './types';

Vue.use(Vuex);

export const state: RootState = {
  filmTranscript: null
};

const store = new Vuex.Store({
  state
});

export default store;
