import Base64 from 'crypto-js/enc-base64';
import Utf8 from 'crypto-js/enc-utf8';

export class Tenant {
  constructor () {
    this.editors = [];
    this.initializeEditors();
    this.initializeParameters();
  }

  initializeEditors() {
    let textAreas = document.querySelectorAll('.tenant-wysiwyg > textarea');

    textAreas.forEach((textArea) => {
      const shouldSanitize = textArea.dataset.sanitize === 'true';

      $(textArea).kendoEditor({
        pasteCleanup: {
          custom: function(text) {
            return shouldSanitize ? VERSTEHE.Globals.Sanitizer.sanitize(text) : text;
          },
          keepNewLines: true
        },
        resizable: true,
        encoded: false,
        tools: [
          "viewHtml",
          "formatting",
          "bold",
          "italic",
          "underline",
          "cleanFormatting",
          "insertUnorderedList",
          "insertOrderedList",
          "justifyLeft",
          "justifyCenter",
          "justifyRight",
          "justifyFull",
          "createLink",
          "unlink",
          "insertImage"
        ],
        deserialization: {
          custom: function(html) {
            return shouldSanitize ? VERSTEHE.Globals.Sanitizer.sanitize(html) : html;
          }
        },
        serialization: {
          custom: function(html) {
            return shouldSanitize ? VERSTEHE.Globals.Sanitizer.sanitize(html) : html;
          }
        },
        stylesheets: [
          "/blank.css"
        ]
      });
      let editor = $(textArea).data("kendoEditor");
      this.editors.push(editor);
    });
  }

  initializeParameters() {
    this.initRegistrationEmailDomains();
    this.initRegistrationPassword();
    this.initRegistrationToken();
  }

  initRegistrationEmailDomains() {
    const toggle = document.getElementById('registration_email_domains_enabled_checkbox');
    const select = document.getElementById('registration_email_domains_select');

    if (!select) {
      return;
    }

    const selectize = select.selectize;
    const formGroup = select.parentElement;

    const validateDomainPresence = () => {
      const saveBtn = document.getElementById('save-tenant-btn');

      if (!toggle.checked || (selectize.items && selectize.items.length > 0)) {
        formGroup.classList.remove('has-error');
        saveBtn.classList.remove('disabled');
      } else {
        formGroup.classList.add('has-error');
        saveBtn.classList.add('disabled');
      }
    }

    selectize.on('change', (items) => {
      validateDomainPresence();
    });

    toggle.checked ? selectize.enable() : selectize.disable();

    toggle.addEventListener('change', () => {
      toggle.checked ? selectize.enable() : selectize.disable();
      validateDomainPresence();
    });
  }

  initRegistrationPassword() {
    const toggle = document.getElementById('registration_password_enabled_checkbox');
    const input = document.getElementById('registration_password_input');

    if (!input) {
      return;
    }

    input.disabled = !toggle.checked;
    toggle.addEventListener('change', () => input.disabled = !toggle.checked);
  }

  initRegistrationToken() {
    const toggle = document.getElementById('registration_token_enabled_checkbox');
    const input = document.getElementById('registration_token_input');

    if (!input) {
      return;
    }

    const urlInput = document.createElement('input');
    const refreshBtn = document.createElement('button');
    urlInput.type = 'text';
    urlInput.className = 'form-control';
    urlInput.readOnly = true;
    input.style.display = 'none';

    refreshBtn.className = 'btn btn-fab btn-fab-mini btn-primary';
    refreshBtn.type = 'button';
    refreshBtn.innerHTML = '<i class="material-icons">refresh</i>';

    input.parentNode.appendChild(urlInput);
    input.parentNode.parentNode.appendChild(refreshBtn);

    urlInput.disabled = !toggle.checked;

    if (toggle.checked) {
      refreshBtn.classList.remove('disabled');
    } else {
      refreshBtn.classList.add('disabled');
    }

    toggle.addEventListener('change', () => {
      urlInput.disabled = !toggle.checked;

      if (toggle.checked) {
        refreshBtn.classList.remove('disabled');

        if (input.value.length === 0) {
          input.value = this.generateRegistrationToken();
          this.fillRegistrationTokenUrl(urlInput, input.value);
        }
      } else {
        refreshBtn.classList.add('disabled');
      }
    });

    refreshBtn.addEventListener('click', () => {
      input.value = this.generateRegistrationToken();
      this.fillRegistrationTokenUrl(urlInput, input.value);
    });

    this.fillRegistrationTokenUrl(urlInput, input.value);
  }

  generateRegistrationToken() {
    return Base64.stringify(Utf8.parse((Math.random() * 10000).toString(36)));
  }

  fillRegistrationTokenUrl(input, token) {
    const baseUrl = `${_app.info.rootUrl}user/sign_up`;
    input.value = `${baseUrl}/${token}`;

    return input.value;
  }
}
