import { Queue } from "./queue";
import { HighlightAction } from "./actions/content_actions/general_actions/highlight_action";

import * as index from "./actions/index";

// An array of Queues of tabs that are currently visible
export let Queues;

// helps to prevent "retriggering"
export let ignoreNextSelectizeAction = 0;
// two helper variables for sorting sections and scenes
export let screenplaySortOriginalElement = null;
export let screenplaySortOriginalValue = null;

export let playlistSortOriginalElement = null;
export let playlistSortOriginalValue = null;

export let publishEventTriggered = false;

export let collaborators = null;

// time when the last MouseMoveAction was sent
export let lastTime = 0;
// position of the last MouseMoveAction that was sent
export let lastX = -1;
export let lastY = -1;
export let mouseDown = false;

/**
 * Removes Cursor and Highlights from a specific user
 * is used, when a user leaves the current tab or the contribution
 * @param {number} userId - id of the user
 **/
export function removeCursorAndHighlights(userId, mainTabId) {
  let userAvatar = $(`.communication-user-avatar[data-user_id="${userId}"]`);
  let authorId = userAvatar.length > 0 ? userAvatar.attr("id") : `editor_user_${userId}`;
  removeCursor(authorId);
  removeHighlights(authorId, userId, mainTabId);
}

/**
 * Removes Highlights from a specific user
 * @param {number} userId - id of the user
 * @param {number} authorId - authorId of the user
 **/
function removeHighlights(authorId, userId, mainTabId) {
  let highlightElement = $(`.${authorId}_highlight_element`);
  if (highlightElement.length > 0) {
    if (highlightElement.is(".stepper-step") && mainTabId === "#film") {} else {
      VERSTEHE.ActionManager.ActionManager.receivedAction(new HighlightAction(null, userId, highlightElement, "out"));
    };
  }
}

/**
 * Removes Cursor from a specific user
 * @param {number} authorId - authorId of the user
 **/
function removeCursor(authorId) {
  let cursor = $(`#${authorId}_cursor`);
  cursor.remove();
}

/**
 * Initializes CollaborativeAuthoring features
 **/
export function initialize() {
  $('.manage-sidebar a[data-toggle="remotetab"], .stepper-horizontal a[data-toggle="remotetab"]').on('hide.bs.tab', function() {
    let tabId = $(this).attr("href");
    if (Queues.find(q => q.queueName === tabId)) {
      let deleteQueue = Queues.find(q => q.queueName === tabId);
      Queues.splice(Queues.indexOf(deleteQueue), 1);
    }
  });

  index.ACTIONCLASSES_WITH_LISTENERS.forEach(function(action) {
    action.registerToElement();
  });
  let mainQueue = new Queue("mainQueue");
  Queues = [mainQueue];
  index.UpdateQueueAction.sendUpdateQueueAction("mainQueue");
  Queues.find(q => q.queueName === "mainQueue").state = "requested";
  setTimeout(function() {
    if (Queues.find(q => q.queueName === "mainQueue").state === "requested") {
      Queues.find(q => q.queueName === "mainQueue").state = "up-to-date";
    }
  }, 1000);
};

/**
 * Reinitializes listeners of SelectizeActions for Publish tab
 * This is necessary because the publish tab gets reloaded sometimes and the listeners for SelectizeActions are not delegable
 **/
function reinitializePublishSelectize() {
  index.SelectizeAction.registerToElement("#publish");
};

/**
 * Gets specific setting from collaborative-authoring-settings
 * @param {string} name - name of the setting
 **/
export function getSetting(name) {
  let optionsNode = document.querySelector('.collaborative-authoring-settings');
  return optionsNode ? optionsNode.getAttribute(`data-${name}`) : null;
}

/**
 * Returns whether CollaborativeAuthoring is enabled
 * getSetting('enabled') returns string instead of boolean
 **/
export function isEnabled() {
  return getSetting('enabled') === 'true';
}

/**
 * Creates a new Queue when a remoteTab is loaded
 * @param {string} tabId - id of the tab e.g. #general, #film ...
 **/
$(document).on("remotetabLoaded", function(event, tabId) {
  if (isEnabled()) {
    if (tabId === "#publish") {
      reinitializePublishSelectize();
    }

    let tabQueue = Queues.find(q => q.queueName === tabId);
    // if Queue for tabId exist delete it
    if (tabQueue) {
      Queues.splice(Queues.indexOf(tabQueue), 1);
    }
    // create a new Queue for tabId
    tabQueue = new Queue(tabId);
    Queues.push(tabQueue);
    index.UpdateQueueAction.sendUpdateQueueAction(tabId);
    Queues.find(q => q.queueName === tabId).state = "requested";
    setTimeout(function () {
      if (Queues.find(q => q.queueName === tabId).state === "requested") {
        Queues.find(q => q.queueName === tabId).state = "up-to-date";
      }
    }, 1000);
  }
});
