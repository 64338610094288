export default {
  initUrl(state) {
    return state.initUrl;
  },

  initialized(state) {
    return state.initialized;
  },

  videoSrc(state) {
    return {
      src: state.videoSrc,
      type: 'video/mp4'
    };
  },

  currentQuestion(state) {
    return state.currentQuestion;
  },

  questions(state) {
    return state.questions;
  },

  questionsByTime(state) {
    return (state.questions || []).filter(question => !question.deleted).sort((a, b) => {
      // Ascending sort by display time. Null values at the end
      if (a.time === null) {
        return 1;
      } else if (b.time === null) {
        return -1;
      } else if (a.time === b.time) {
        return 0;
      } else {
        return parseFloat(a.time) < parseFloat(b.time) ? -1 : 1;
      }
    });
  },

  currentTime(state) {
    return state.currentTime;
  },

  duration(state) {
    return state.duration;
  },

  isSaved(state) {
    return state.isSaved;
  },

  isSaving(state) {
    return state.isSaving;
  }
};
