import { AnswerSerializer } from "./answer_serializer";
import { Answer, Question } from "../entities";
import store from "../../../store";

export class DragAndDropAnswerSerializer extends AnswerSerializer {
  constructor(protected answer: Answer, protected question: Question) {
    super(answer, question);

    if (answer.imageUpload) {
      this.serializedAnswer['image'] = answer.image;
      this.serializedAnswer['image_file_name'] = answer.imageFileName;
    }

    const { answers, dragAndDropOptions } = store.state.quizManage.entities;
    this.serializedAnswer['options_attributes'] = answers[answer.id].dragAndDropOptions.map(optionId => dragAndDropOptions[optionId]);
  }
}
