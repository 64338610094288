import Vue from 'vue';

export default {
  setTutorialVideos(state, { tutorialVideos, hiddenCategories }) {
    let categories = {},
        videos = {},
        allCategoryIds = [],
        allVideoIds = [];

    if (!tutorialVideos) {
      return;
    }

    tutorialVideos.forEach((category) => {
      let videoIds = [];

      if (hiddenCategories.includes(category.id)) {
        return;
      }

      category.videos.forEach((video) => {
        videos[video.id] = {
          ...video,
          category: category.id
        };

        allVideoIds.push(video.id);
        videoIds.push(video.id);
      });

      categories[category.id] = {
        id: category.id,
        name: category.name,
        videos: videoIds
      };

      allCategoryIds.push(category.id);
    });

    Vue.set(state, 'categories', {
      byId: categories,
      allIds: allCategoryIds
    });

    Vue.set(state, 'videos', {
      byId: videos,
      allIds: allVideoIds
    });
  },

  setSearchQuery(state, query) {
    if (query && query.length > 0) {
      state.filterCategory = 'all';
      state.currentVideo = null;
      state.searchQuery = query;
    } else {
      state.searchQuery = null;
    }
  },

  filterCategory(state, categoryId) {
    state.currentVideo = null;
    state.searchQuery = null;
    state.filterCategory = categoryId;
  },

  setCurrentVideo(state, videoId) {
    state.searchQuery = null;
    state.currentVideo = videoId;
  },

  filterRole(state, roles = []) {
    let filterRole = ['guest'].concat(roles);
    state.filterRole = filterRole;
  }
};
