<template>
  <div>
    <div class="api-token-list-head">
      <div class="api-token-list-col">{{ $t('api_tokens.list_description') }}</div>
      <div class="api-token-list-col">
        <div>
          {{ $t('api_tokens.list_prefix') }}
          <i ref="tooltip" class="prefix-tooltip material-icons" :data-title="$t('api_tokens.prefix_help')">help</i>
        </div>
      </div>
      <div class="api-token-list-col">{{ $t('api_tokens.list_access') }}</div>
    </div>
    <api-token-item
      v-for="token in tokens"
      :key="token.id"
      :token="token"/>

    <div class="no-api-token" v-if="loaded && tokens.length === 0">
      <i class="material-icons">vpn_key</i>
      <h3>{{ $t('api_tokens.no_token_generated_yet') }}</h3>
      <p>{{ $t('api_tokens.no_token_generated_yet_description') }}</p>
    </div>
    <button type="button" @click="newApiToken" class="btn new-token btn-primary btn-flat">{{ $t('api_tokens.new_api_token') }}</button>
    <api-token-form ref="tokenForm"/>
  </div>
</template>

<script>
import ApiTokenItem from './api_token_item.vue';
import ApiTokenForm from './api_token_form.vue';
import { mapGetters, mapActions } from 'vuex';
import { ValidationProvider } from 'vee-validate';

export default {
  components: {
    ApiTokenItem,
    ApiTokenForm,
    ValidationProvider
  },
  methods: {
    ...mapActions({
      openForm: 'apiTokens/OPEN_FORM'
    }),
    newApiToken() {
      this.openForm();
    },
    editApiToken(id) {
      this.openForm(id);
    }
  },
  mounted() {
    $(this.$refs.tooltip).tooltip();
  },
  computed: {
    ...mapGetters({
      tokens: 'apiTokens/tokens',
      loaded: 'apiTokens/loaded'
    })
  }
}
</script>

<style lang="scss" scoped>
.api-token-list-head {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 52px;
  grid-gap: 20px;
  align-items: stretch;
  padding: 10px;
  margin: 10px;
}

.api-token-list-col {
  display: grid;
  justify-content: start;
  align-items: center;
  white-space: nowrap;
  font-weight: 400;
}

.no-api-token {
  max-width: 500px;
  margin: 0 auto;
  text-align: center;

  > i {
    font-size: 48px;
    color: $black-020-opacity;
  }

  h3 {
    font-size: 20px;
  }
}

.btn.new-token {
  display: block;
  margin: 24px auto;
}
</style>
