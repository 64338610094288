import { t } from './configurations/localization';
import { successMessage, errorMessage } from '../mixins/flash_message';
import Axios from 'axios';

export class ManageUser {
  constructor() {
    this.registerEventListeners();
  }

  registerEventListeners() {
    this.registerModalBatchAction();
    this.registerModalShowTransitContent();
    this.registerModalNewUser();

    document.addEventListener('resetNewUserForm', (e) => {
      this.resetForm();
    });

    $(document).on('infinite-loaded', () => {
      $('.grid-action-transit-content').off('click');
      this.registerModalShowTransitContent();
    });
  }

  registerModalShowTransitContent() {
    $(document).on('click', '.grid-action-transit-content', function () {
      $('#transit-content-modal-wrap').html('');
      $('#transit-content-modal-wrap').load($(this).data('url'), () => $('#transit-content-modal').modal('show'));
    });
  }

  registerModalBatchAction() {
    $(document).on('click', '.batch-action-modal a', (e) => {
      e.preventDefault();
      let ids = [];

      $(this).data('ids', '');

      $('.list-item.active').each(function () {
        let id = $(this).data('id');
        ids.push(id);
      });

      $(this).data('ids', ids);

      $('#roles-assignment-modal').modal('toggle');
      $('#roles-assignment-modal').find('.modal-body #user_ids').val(ids);
    });
  }

  registerModalNewUser() {
    const button = document.querySelector('.register-new-user-button');

    if (!button) { return; }

    this.newUserFormUrl = button.getAttribute('data-url');
    this.loadNewUserForm().then(() => {
      if (button.getAttribute('data-limit-reached') === "false") {
        button.removeAttribute('disabled');

        button.addEventListener('click', (e) => {
          if (this.newUserDialog) {
            this.newUserDialog.open();
          } else {
            this.initNewUserDialog();
          }
        });
      }
    });
  }

  initNewUserDialog() {
    const container = document.getElementById('new-user-dialog');

    this.newUserDialog = $(container).kendoDialog({
      width: '550px',
      title: t('user_registration_title'),
      closable: true,
      modal: true,
      scrollable: true,
      content: this.newUserForm,
      actions: [{
        text: t('cancel_button'),
          action: function() {
            let resetEvent = new Event('resetNewUserForm');
            document.dispatchEvent(resetEvent);
          }
        }, {
          text: t('save_button'),
          primary: true,
          action: this.submitUserForm
        }]
    }).data("kendoDialog");

    this.bindFormListener();
  }

  loadNewUserForm() {
    return Axios.get(this.newUserFormUrl)
    .then( response => {
      this.newUserForm = response.data;
    })
    .catch( error => {
      this.newUserForm = t('load_form_error');
      console.error(error);
    });
  }

  submitUserForm(e) {
    $('#user-form').trigger('submit.rails');
    kendo.ui.progress($('.k-dialog'), true);
    return false;
  }

  resetForm() {
    this.loadNewUserForm().then(() => {
      this.newUserDialog.content(this.newUserForm);
      this.bindFormListener();
    });
  }

  /**
   * Render the errormessage of the author subscription rules
   */
  renderSubscriptionErrorModal(modal) {
    let modalContainer = document.createElement('div');
    let modalContent = document.createElement('div');
    let modalDialog = document.createElement('div');
    modalContainer.setAttribute('id', 'no_author_roles_modal');
    modalContainer.setAttribute('role', 'dialog');
    modalContainer.classList.add('modal', 'fade');
    modalDialog.classList.add('modal-dialog', 'modal-lg');
    modalContent.classList.add('modal-content');
    modalContent.innerHTML = modal;
    modalDialog.appendChild(modalContent);
    modalContainer.appendChild(modalDialog);
    let container = document.getElementsByTagName('body')[0];
    container.appendChild(modalContainer);
    $('#no_author_roles_modal').modal('show');
  }

  addFormCheckboxListener() {
    document.querySelector('#generate_password').addEventListener('change', (e) => {
      let forceChangePw = document.querySelector('#force_change_password');
      let skipEmailNotification = document.querySelector('#skip_email_notification');
      let userPassword = document.querySelector('#user_password');

      if (e.target.checked) {
        userPassword.setAttribute('disabled', 'disabled');
        skipEmailNotification.setAttribute('disabled', 'disabled');
        skipEmailNotification.checked = false;
        forceChangePw.checked = true;
        forceChangePw.setAttribute('disabled', 'disabled');
      } else {
        userPassword.removeAttribute('disabled');
        skipEmailNotification.removeAttribute('disabled');
        forceChangePw.checked = false;
        forceChangePw.removeAttribute('disabled');
      }
    });
  }

  addPasswordFieldListener() {
    let visibilityIcon = document.querySelector('.visibility-change');
    let userPassword = document.querySelector('#user_password');

    visibilityIcon.addEventListener('mouseenter', e => {
      userPassword.type = 'text';
    });

    visibilityIcon.addEventListener('mouseout', e => {
      userPassword.type = 'password';
    });
  }

  bindSumitListener() {
    $('#user-form').on('ajax:complete', (_event, xhr) => {
      if (xhr.status === 200 && xhr.responseJSON) {
        let response = xhr.responseJSON;
        successMessage(response.notice, 4000);
        kendo.ui.progress($('.k-dialog'), false);

        if (response.form) {
          this.newUserDialog.content(response.form);
          this.bindFormListener();
        } else {
          this.newUserDialog.close();
          this.resetForm();
          VERSTEHE.UserGrid.grid.dataSource.dataSource.read();
        }

        if (response.subscription_error) {
          this.renderSubscriptionErrorModal(response.subscription_error);
        }
      } else {
        this.newUserDialog.close();
        errorMessage(t('server_error'), 4000);
      }
    });
  }

  bindFormListener() {
    this.addFormCheckboxListener();
    this.addPasswordFieldListener();
    this.bindSumitListener();
  }
}
