<template>
  <div class="okta-integration">
    <div class="okta-settings">
      <div class="form-group label-static">
        <label for="okta_client_id" class="control-label">{{ $t('integrations.identity_okta_client_id') }}</label>
        <input v-model="okta_client_id" type="text" class="form-control" id="okta_client_id">
      </div>

      <div class="form-group label-static">
        <label for="okta_client_secret" class="control-label">{{ $t('integrations.identity_okta_client_secret') }}</label>
        <input v-model="okta_client_secret" type="password" class="form-control" id="okta_client_secret">
      </div>

      <div class="form-group label-static">
        <label for="okta_url" class="control-label">{{ $t('integrations.identity_okta_url') }}</label>
        <input v-model="okta_url" type="text" class="form-control" id="okta_url" placeholder="https://your-company.okta.com">
        <p class="help-block">{{ $t('integrations.identity_okta_url_help') }}</p>
      </div>

      <div class="form-group">
        <div class="checkbox">
          <label>
            <input
              type="checkbox"
              v-model="okta_manual_sign_in">
              {{ $t('integrations.identity_okta_attribute_manual_sign_in') }}
          </label>
        </div>
        <p class="help-block">
          {{ $t('integrations.identity_okta_attribute_manual_sign_in_help') }}
        </p>
      </div>

      <div v-show="more">
        <div class="form-group label-static">
          <label for="okta_issuer" class="control-label">{{ $t('integrations.identity_okta_issuer') }}</label>
          <input v-model="okta_issuer" type="text" class="form-control" id="okta_issuer" placeholder="https://your-company.okta.com/oauth2/default">
          <p class="help-block">{{ $t('integrations.identity_okta_issuer_help') }}</p>
        </div>

        <div class="form-group label-static">
          <label for="okta_auth_server_id" class="control-label">{{ $t('integrations.identity_okta_auth_server_id') }}</label>
          <input v-model="okta_auth_server_id" type="text" class="form-control" id="okta_auth_server_id" placeholder="default">
          <p class="help-block">{{ $t('integrations.identity_okta_auth_server_id_help') }}</p>
        </div>

        <h3>{{ $t('integrations.identity_okta_user_role_synchronization_heading') }}</h3>

        <div class="form-group">
          <div class="checkbox">
            <label>
              <input
                type="checkbox"
                v-model="okta_user_role_synchronization">
                {{ $t('integrations.identity_okta_user_role_synchronization') }}
            </label>
          </div>

          <div class="checkbox">
            <label>
              <input
                type="checkbox"
                :disabled="!okta_user_role_synchronization"
                v-model="okta_user_role_synchronization_demote_check">
                {{ $t('integrations.identity_okta_user_role_synchronization_demote') }}
            </label>
          </div>
        </div>

        <div class="form-group label-static">
          <label for="okta_user_role_synchronization_claim_name" class="control-label">{{ $t('integrations.identity_okta_user_role_synchronization_claim_name') }}</label>
          <input :disabled="!okta_user_role_synchronization" v-model="okta_user_role_synchronization_claim_name" type="text" class="form-control" id="okta_user_role_synchronization_claim_name">
          <p class="help-block">{{ $t('integrations.identity_okta_user_role_synchronization_claim_name_help') }}</p>
        </div>

        <div class="okta-user-role-rules" v-if="okta_user_role_synchronization">
          <h3>
            {{ $t('integrations.identity_okta_user_role_rules') }}
            <button class="btn btn-fab btn-fab-mini btn-primary" type="button" @click="addUserRoleRule()"><i class="material-icons">add</i></button>
          </h3>
          <div class="okta-user-role-rule" v-for="rule in oktaUserRoleRules" :key="rule.id">

            <ValidationProvider rules="required" name="claim" class="form-group" v-slot="{ errors }">
              <input v-model="rule.match" class="form-control" :placeholder="$t('integrations.identity_okta_user_role_rules_match')" type="text" @input="updateUserRoleRule(rule, 'match', $event.target.value)" />
              <span v-if="errors.length > 0" class="error-info">{{ errors[0] }}</span>
            </ValidationProvider>
            <i class="material-icons">chevron_right</i>
            <div class="form-group">
              <select class="form-control" :value="rule.role" @change="updateUserRoleRule(rule, 'role', $event.target.value)">
                <option value="explorer">{{ $t('integrations.identity_okta_user_role_rules_explorer') }}</option>
                <option value="supporter">{{ $t('integrations.identity_okta_user_role_rules_supporter') }}</option>
                <option value="author">{{ $t('integrations.identity_okta_user_role_rules_author') }}</option>
                <option value="trainer">{{ $t('integrations.identity_okta_user_role_rules_trainer') }}</option>
                <option value="reviewer">{{ $t('integrations.identity_okta_user_role_rules_reviewer') }}</option>
                <option value="editor">{{ $t('integrations.identity_okta_user_role_rules_editor') }}</option>
                <option value="master_editor">{{ $t('integrations.identity_okta_user_role_rules_master_editor') }}</option>
                <option value="publisher">{{ $t('integrations.identity_okta_user_role_rules_publisher') }}</option>
              </select>
            </div>
            <i class="material-icons delete-rule" @click="removeUserRoleRule(rule)">delete</i>
          </div>
        </div>
      </div>

      <button v-show="!more" @click="more = true" class="btn btn-default btn-flat btn-xxs">{{ $t('integrations.show_more') }}</button>
      <slot/>
    </div>

    <div class="okta-help">
      <h3>{{ $t('integrations.identity_okta_help_howto_configure') }}</h3>
      <ol>
        <li>
          <p v-html="$t('integrations.identity_okta_help_step1')"></p>
          <img :src="require('images/integrations/integrations_identity_okta_new_application.jpg')">
        </li>
        <li>
          <p v-html="$t('integrations.identity_okta_help_step2_name')"></p>
          <pre>coovi</pre>
          <p v-html="$t('integrations.identity_okta_help_step2_uri')"></p>
          <pre>{{ redirectUri }}</pre>
          <img :src="require('images/integrations/integrations_identity_okta_application_settings.jpg')">
        </li>
        <li>
          <p v-html="$t('integrations.identity_okta_help_step3')"></p>
          <ul>
            <li v-html="$t('integrations.identity_okta_help_step3_client_id')"></li>
            <li v-html="$t('integrations.identity_okta_help_step3_client_secret')"></li>
            <li v-html="$t('integrations.identity_okta_help_step3_okta_url')"></li>
          </ul>
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex';
  import { v4 as uuid } from 'uuid';
  import Vue from 'vue';
  const { mapGetters, mapActions } = createNamespacedHelpers('integrations');

  export default {
    data() {
      return {
        okta_client_id: null,
        okta_client_secret: null,
        okta_url: null,
        okta_issuer: null,
        okta_auth_server_id: null,
        okta_user_role_synchronization: false,
        okta_user_role_synchronization_claim_name: null,
        okta_user_role_synchronization_demote: false,
        okta_manual_sign_in: false,
        okta_user_role_rules: [],
        more: false
      }
    },
    computed: {
      ...mapGetters([
        'integrationById'
      ]),
      integration() {
        return this.integrationById('identity_okta');
      },
      settings() {
        return {
          okta_client_id: this.okta_client_id,
          okta_client_secret: this.okta_client_secret,
          okta_url: this.okta_url,
          okta_issuer: this.okta_issuer,
          okta_auth_server_id: this.okta_auth_server_id,
          okta_user_role_synchronization: this.okta_user_role_synchronization,
          okta_user_role_synchronization_claim_name: this.okta_user_role_synchronization_claim_name,
          okta_user_role_synchronization_demote: this.okta_user_role_synchronization_demote,
          okta_manual_sign_in: this.okta_manual_sign_in,
          okta_user_role_rules_attributes: this.okta_user_role_rules.map(rule => {
            let newRule = { ...rule };

            if (typeof rule.id === 'string') {
              delete newRule.id;
            }

            return newRule;
          })
        }
      },
      isDefaultIssuer() {
        return this.okta_issuer === `${this.okta_url}/oauth2/default`;
      },
      redirectUri() {
        return `${_app.info.rootUrl}users/auth/oktaoauth/callback`;
      },
      okta_user_role_synchronization_demote_check: {
        set(value) {
          this.okta_user_role_synchronization_demote = value;
        },
        get() {
          return this.okta_user_role_synchronization && this.okta_user_role_synchronization_demote;
        }
      },
      oktaUserRoleRules() {
        return this.okta_user_role_rules.filter(r => r['_destroy'] !== '1');
      }
    },
    methods: {
      load() {
        const settings = this.integration.settings;

        this.okta_client_id = settings.okta_client_id;
        this.okta_client_secret = settings.okta_client_secret;
        this.okta_url = settings.okta_url;
        this.okta_issuer = settings.okta_issuer;
        this.okta_auth_server_id = settings.okta_auth_server_id;
        this.okta_user_role_synchronization = settings.okta_user_role_synchronization;
        this.okta_user_role_synchronization_demote = settings.okta_user_role_synchronization_demote;
        this.okta_user_role_synchronization_claim_name = settings.okta_user_role_synchronization_claim_name;
        this.okta_user_role_rules = settings.okta_user_role_rules;
        this.okta_manual_sign_in = settings.okta_manual_sign_in;
      },
      addUserRoleRule() {
        this.okta_user_role_rules.push({
          id: uuid(),
          role: 'explorer',
          match: ''
        });
      },
      updateUserRoleRule(userRoleRule, attribute, value) {
        const index = this.okta_user_role_rules.findIndex(r => r.id === userRoleRule.id);
        let updatedElement = { ...this.okta_user_role_rules[index] };
        updatedElement[attribute] = value;
        Vue.set(this.okta_user_role_rules, index, updatedElement);
      },
      removeUserRoleRule(userRoleRule) {
        const index = this.okta_user_role_rules.findIndex(r => r.id === userRoleRule.id);

        if (typeof userRoleRule.id === 'string') {
          // No persistet yet, delete from array
          this.okta_user_role_rules.splice(index, 1);
        } else {
          this.updateUserRoleRule(userRoleRule, '_destroy', '1');
        }
      }
    },
    mounted() {
      this.load();
      $.material.init();
    },
    watch: {
      okta_url(url, oldUrl) {
        if (!this.more || this.okta_issuer === `${oldUrl}/oauth2/default`) {
          this.okta_issuer = `${url}/oauth2/default`;
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .okta-integration {
    display: flex;
  }

  .okta-settings {
    flex: 1 0;
    align-self: flex-start;
    min-width: 400px;
    max-width: 800px;
    background-color: $color-white;
    border-radius: 3px;
    padding: 30px;
  }

  .okta-help {
    font-size: 14px;
    padding: 0 30px;

    img {
      display: block;
      margin-top: 10px;
      border-radius: 3px;
    }

    ol > li {
      margin-bottom: 24px;
    }

    pre {
      display: inline-block;
    }
  }

  h3 {
    font-size: 14px;
    font-weight: 500;
  }

  .btn.btn-fab.btn-fab-mini {
    width: 30px;
    height: 30px;
    min-width: 30px;
    margin: 8px;

    > i {
      font-size: 24px;
    }
  }

  .okta-user-role-rule {
    display: flex;

    .form-group {
      flex-grow: 1;
      padding: 0 10px;
      width: 100%;
    }

    i {
      font-size: 24px;
      margin: 0 24px;
      line-height: 40px;

      &.delete-rule {
        cursor: pointer;
      }
    }
  }

  .error-info {
    color:  $color-error-red;
  }
</style>
