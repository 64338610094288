import { LocaleMessages } from 'vue-i18n';

export type Messages = typeof messages.de;

const messages = {
  de: {
    previewHint: 'Vorschau',
    betaNotice: 'Die visuellen Effekte befinden sich derzeit im Beta-Status. Abhängig von den verfügbaren Hardware-Ressourcen kann es zu Leistungsproblemen kommen.',
    backgroundReplacement: {
      label: 'Hintergrundeffekt',
      tooltip: {
        disable: 'Deaktivieren',
        transparent: 'Hintergrund transparent darstellen',
        blurSoft: 'Hintergrund leicht weichzeichnen',
        blur: 'Hintergrund weichzeichnen',
        blurBlocked: 'Da derzeit "Figur ersetzen" aktiv ist, kann der Hintergrund nicht unscharf eingestellt werden. Die Einstellung muss zunächst deaktiviert werden.',
        uploadCustomImage: 'Hintergrundbild hinzufügen',
        removeCustomImage: 'Hintergrundbild entfernen',
      },
    },
  },
  en: {
    previewHint: 'Preview',
    betaNotice: 'The visual effects are currently in beta status. Depending on the available hardware resources, you may experience performance issues.',
    backgroundReplacement: {
      label: 'Background effect',
      tooltip: {
        disable: 'Disable',
        transparent: 'Display background transparently',
        blurSoft: 'Slightly blur background',
        blur: 'Blur background',
        blurBlocked: 'Since "Replace figure" is currently active, the background cannot be blurred. The setting must first be deactivated.',
        uploadCustomImage: 'Add background image',
        removeCustomImage: 'Remove background image',
      },
    },
  },
  es: {
    previewHint: 'Vista previa',
    betaNotice: 'Los efectos visuales están actualmente en estado beta. Dependiendo de los recursos de hardware disponibles, puedes experimentar problemas de rendimiento.',
    backgroundReplacement: {
      label: 'Efecto de fondo',
      tooltip: {
        disable: 'Desactivar',
        transparent: 'Mostrar el fondo de forma transparente',
        blurSoft: 'Suavizar ligeramente el fondo',
        blur: 'Fondo borroso',
        blurBlocked: 'Como la opción "Reemplazar figura" está activada, el fondo no se puede difuminar. Primero hay que desactivar el ajuste.',
        uploadCustomImage: 'Añadir imagen de fondo',
        removeCustomImage: 'Eliminar la imagen de fondo',
      },
    },
  },
};

export default messages as LocaleMessages;