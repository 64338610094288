import { t } from '../configurations/localization';

export class BaseFilterColumn {
  /**
   * @constructor
   * @param {Object} kendoColumn - Column object from Kendo UI library
   */
  constructor(kendoColumn, filter) {
    this.header = document.getElementById(kendoColumn.headerAttributes.id);
    this.kendoColumn = kendoColumn;
    this.filterMenu = $(this.header).data('kendoFilterMenu');
    this.filterModel = this.filterMenu.filterModel;
    this.title = kendoColumn.title;
    this.hidden = kendoColumn.hidden;
    this.field = kendoColumn.field;
    this.filterable = kendoColumn.filterable;
    this.filter = filter;

    this.setupFilterMenu();
    this.setupFilterLabel();
  }

  /**
   * Set the given filter
   * @param {Object} filterConstraint Filter condition
   */
  set filters(filterConstraint) {
    this._filters = filterConstraint;

    if (this.isActive()) {
      this.updateFilterLabel();

      if (!this.filterLabel.parentNode) {
        this.filter.filtersWrapper.appendChild(this.filterLabel);
      }

      this.attach(this.filterLabel);
    } else {
      this.removeFilterLabel();
    }
  }

  /**
   * Returns the current filter for this column
   */
  get filters() {
    return this._filters;
  }

  /**
   * Returns true, if the current column has an active filter
   */
  isActive() {
    return !!this._filters;
  }

  /**
   * Remove the filter for this column
   */
  reset() {
    this.filters = null;
    this.filterMenu.clear();
    this.removeFilterLabel();
  }

  /**
   * Manipulate the filter popup from kendo ui to have
   * an extra header
   */
  setupFilterMenu() {
    this.filterMenu.options.messages.title = '';
    this.filterMenu._init();
    this.popup = $(this.filterMenu.form).data('kendoPopup');
    this.popup.options.animation.open.duration = 0;
    this.popup.options.animation.close.duration = 0;

    // Insert popup header
    let header = document.createElement('div');
    header.className = 'grid-filter-header';

    let title = document.createElement('h2');
    title.innerText = this.title;

    let closeAction = document.createElement('i');
    closeAction.className = 'material-icons';
    closeAction.innerText = 'close';
    closeAction.addEventListener('click', () => this.close());

    header.appendChild(title);
    header.appendChild(closeAction);

    let form = this.popup.element[0];
    form.firstChild.prepend(header);
  }

  /**
   * Create & prepare the filter label for display in filter input
   */
  setupFilterLabel() {
    this.filterLabel = document.createElement('div');
    this.filterLabel.className = 'grid-filter-label';

    let filterLabelInner = document.createElement('div');
    filterLabelInner.className = 'grid-filter-label-inner';

    let filterText = document.createElement('span');
    let filterReset = document.createElement('i');
    filterReset.className = 'material-icons';
    filterReset.innerText = 'clear';

    filterReset.addEventListener('click', () => this.reset());

    this.filterLabel.addEventListener('click', (e) => {
      if (!e.target.isEqualNode(filterReset)) {
        if (!this.filters.find((filter) => filter.readOnly)) {
          this.filter.fieldSelect.close();
          this.open();
        }
      }
    });

    filterLabelInner.appendChild(filterText);
    filterLabelInner.appendChild(filterReset);
    this.filterLabel.appendChild(filterLabelInner);
  }

  /**
   * Refresh the filter text with the current filter value
   */
  updateFilterLabel() {
    let labelText = this.filterLabel.querySelector('span');
    this.filterLabelText().then((text) => (labelText.innerText = text));
  }

  /**
   * Remove the filter label from the filter input
   */
  removeFilterLabel() {
    if (this.filterLabel && this.filterLabel.parentNode) {
      this.filterLabel.parentNode.removeChild(this.filterLabel);
      this.close();
      this.attach(this.filter.input);
    }
  }

  /**
   * Returns the translated given operator
   * @param {String} operator The operator to translate
   */
  filterOperator(operator) {
    try {
      return this.filterMenu.operators[this.filterMenu.type][operator].toLowerCase();
    } catch (e) {
      return t(operator).toLowerCase();
    }
  }

  /**
   * Build the label text for filter input
   * e.g. "Created by contains 'Test'"
   */
  filterLabelText() {
    return new Promise((resolve, reject) => {
      // Use selected option text for boolean filters
      if (this.filterMenu.type === 'boolean') {
        return this.filters[0].value === 'true' ? this.filterable.messages.isTrue : this.filterable.messages.isFalse;
      }

      let text = this.title + ' ';
      text += this.filters
        .map((filter) => {
          let operator = this.filterOperator(filter.operator);
          let value = filter.value;

          if (this.filterMenu.type === 'date') {
            value = kendo.parseDate(value);
          }

          if (this.kendoColumn.format) {
            value = kendo.format(this.kendoColumn.format, value);
          }

          return `${operator} '${value}'`;
        })
        .join(` ${t('and')} `);

      resolve(text);
    });
  }

  /**
   * Attach the popup to an element
   * @param {DOMElement} element Specifies the element that will be used as an anchor. The widget will open next to that element.
   * @param {String} origin Specifies how to position the popup element based on anchor point.
   */
  attach(element, origin = 'top left') {
    this.popup.setOptions({ anchor: element, origin: origin });
  }

  /**
   * Opens the filter popup
   */
  open() {
    if (this.field === 'topic_areas') {
      return $(this.filter.topicAreaTree._dialog).modal();
    } else {
      this.popup.open();
      return this.popupStyling();
    }
  }

  /**
   * Closes the filter popup
   */
  close() {
    return this.popup.close();
  }

  popupStyling() {
    let buttonClasses = ['grid-popup-buttons', 'k-button-group', 'k-dialog-buttongroup', 'k-dialog-button-layout-stretched'];
    let wrapper = this.popup.wrapper[0];
    let form = wrapper.firstChild;
    let contentContainer = form.firstChild;
    let buttonContainer = contentContainer.lastChild;

    wrapper.classList.add('grid-filter-wrapper');
    contentContainer.classList.add('grid-filter-popup-container');
    buttonClasses.forEach((c) => {
      buttonContainer.classList.add(c);
    });
    // set styles to increase the width and decrease the padding bottom
    form.style.minWidth = '200px';
    form.style.paddingBottom = '0';
  }
}
