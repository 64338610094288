<template>
<div class="lesson-editor-form">
  <div class="lesson-details edit-elearning-package-lesson">
    <text-input
      name="titleInput"
      v-model="title"
      :validate="{ required: true, max: 75 }"
      :label="$t('course.lesson_title')"></text-input>

    <text-area
      name="descriptionInput"
      v-model="description"
      :validate="{ max: 300 }"
      :label="$t('course.lesson_description')"></text-area>

    <h3>{{ $t('course.elearning_package_lesson') }}</h3>
    <p class="help-block">
      {{ $t('course.package_help_format') }}
      <ul>
        <li>
          <a target="_blank" :href="`https://helpx.adobe.com/${currentLocale}/captivate/using/publish-projects-html5-files.html`">
            {{ $t('course.package_help_format_captivate') }}
          </a>
        </li>
        <li>
          <a target="_blank" href="https://articulate.com/support/article/How-to-Distribute-Articulate-Storyline-360-Courses-for-HTML5-Flash-and-the-Articulate-Mobile-Player">
            {{ $t('course.package_help_format_articulate') }}
          </a>
        </li>
      </ul>
    </p>

    <div class="package-select" :class="{ error: errors.has('package_id') }">
      <file-upload name="uploadId" @uploaded="onUploaded" />
      <input name="package_id" type="hidden" v-model="packageId" v-validate="'required'">

      <div v-if="isChecking" class="package-status">
        {{ $t('course.package_is_processing') }}
      </div>

      <div v-if="isError" class="package-status text-danger">
        <i class="material-icons">error</i>
        <span>{{ $t(`course.package_error_${packageError}`) }}</span>
      </div>

      <div v-if="this.lesson.subject" class="package-metadata">
        <!-- Currently only for Captivate -->
        <div class="package-metadata-row row">
          <div class="package-metadata-label col-sm-3">{{ $t('course.package_filename') }}</div>
          <div class="package-metadata-value col-sm-9">{{ subject.filename }}</div>
        </div>

        <div class="package-metadata-row row">
          <div class="package-metadata-label col-sm-3">{{ $t('course.package_type') }}</div>
          <div class="package-metadata-value col-sm-9">{{ $t(`course.package_type_${subject.packageType}`) }}</div>
        </div>
      </div>
    </div>

    <div class="actions">
      <button v-on:click="setUiState('lessonList')" type="button" class="btn btn-primary btn-flat">{{ $t('course.cancel') }}</button>
      <button v-on:click="saveChanges" :class="{ disabled: errors.any() }" type="button" class="btn btn-primary">{{ $t('course.apply_changes') }}</button>
    </div>
  </div>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { TextInput, TextArea, FileUpload } from './form';

export default {
  name: 'elearning-package-lesson-form',
  inject: ['$validator'],
  data() {
    return {
      title: '',
      description: '',
      content: '',
      isUploaded: false,
      isChecking: false,
      isError: false,
      packageError: ''
    }
  },
  components: {
    TextInput,
    TextArea,
    FileUpload
  },
  computed: {
    ...mapGetters({
      subject: 'course/lessonEditorSubject',
      lesson: 'course/lessonEditorObject',
      lessonType: 'course/lessonEditorType',
      apiUrl: 'course/apiUrl',
      elearningPackageUrl: 'course/elearningPackageUrl'
    }),
    lessonAttributes() {
      return {
        title: this.title,
        description: this.description,
        type: this.lessonType,
        subject: this.subject
      }
    },
    currentLocale() {
      return _app.info.currentLocale;
    },
    packageId() {
      return this.subject.id;
    }
  },
  methods: {
    ...mapActions({
      setUiState: 'course/setUiState',
      saveEditorLesson: 'course/saveEditorLesson',
      createElearningPackage: 'course/createElearningPackage'
    }),
    saveChanges() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.saveEditorLesson(this.lessonAttributes);
        }
      });
    },
    onUploaded(uploadId) {
      this.isUploaded = true;
      this.isError = false;
      this.isChecking = true;

      this.createElearningPackage(uploadId)
        .then(response => {
          if (response.metadata.title && this.title.length === 0) {
            this.title = response.metadata.title;
          }

          if (response.metadata.description && this.description.length === 0) {
            this.description = response.metadata.description;
          }

          this.isChecking = false;
        })
        .catch(response => {
          this.isChecking = false;
          this.isError = true;
          this.packageError = response.data.error;
        })
    }
  },
  watch: {
    lesson: {
      handler: function(lesson) {
        if (lesson.title && lesson.title.length > 0) {
          this.title = lesson.title;
        }

        this.description = lesson.description || '';
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.lesson-editor-form {
  max-width: 800px;
  margin: 0 auto;
}

.actions {
  text-align: right;
  margin-top: 50px;
}

.editor-label {
  margin-top: 40px;
  font-weight: initial;
}

.package-select {
  padding: 15px 24px;
  background-color: $color-light-blue-grey;

  &.error {
    border: 1px solid $color-error-red;
  }

  > .form-group {
    margin: 0;
    padding: 0;
  }
}

.package-metadata-row {
  padding: 5px 0;
}

.package-metadata-label {
  font-weight: 500;
}
</style>
