export class Label {
  constructor(filterBar) {
    this.filterBar = filterBar;
  }

  createLabelforBool(title, type) {
    let div = this.createOuterContainer(type);
    div.appendChild(this.createInnerContainer(title));
    this.bindRemoveListener(div);

    return div;
  }

  createLabelforTree(element, type) {
    let div = this.createOuterContainer(type);
    div.setAttribute('data-id', element.id);
    div.appendChild(this.createInnerContainer(element.title));
    this.bindRemoveListener(div);

    return div;
  }

  createLabelforMultiSelect(element, type, sourceClass) {
    let div = this.createOuterContainer(type);
    div.setAttribute('data-id', element.id);
    div.id = element.uid;
    div.appendChild(this.createInnerContainer(element.title));
    this.bindRemoveListener(div);

    // Deaktivate filter
    div.getElementsByTagName('i')[0].addEventListener('click', (e) => {
      sourceClass.deactivateFilter(element.id);
    });

    return div;
  }

  createLabelforSearchResult(element, type, sourceClass, uid) {
    let div = this.createOuterContainer(type);
    div.setAttribute('data-id', element.getAttribute('data-id'));
    div.appendChild(this.createInnerContainer(element.innerHTML));
    if (uid) { div.id = uid; }

    this.bindRemoveListener(div);

    // Deaktivate filter
    div.getElementsByTagName('i')[0].addEventListener('click', (e) => {
      sourceClass.deactivateFilter(element.getAttribute('data-id'));
    });

    return div;
  }

  createOuterContainer(type) {
    let div = document.createElement('div');
    div.classList.add('analytics-filter-label');
    div.classList.add(type);

    return div;
  }

  createInnerContainer(title) {
    let container = document.createElement('div');
    let span = document.createElement('span');
    let filterReset = document.createElement('i');
    container.classList.add('analytics-filter-label-inner');
    span.classList.add('title');
    span.innerHTML = title;
    
    filterReset.className = 'material-icons';
    filterReset.innerText = 'clear';

    container.appendChild(span);
    container.appendChild(filterReset);

    return container;
  }

  bindRemoveListener (label) {
    label.getElementsByTagName('i')[0].addEventListener('click', (e) => {
      let label = e.target.parentElement.parentElement;
      this.filterBar.removeActiveFilter(label);
    });
  }
}
