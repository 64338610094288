<template>
  <div class="cdn-azure-integration">
    <div class="cdn-azure-settings">
      <ValidationProvider rules="required" :name="$t('integrations.cdn_azure_storage_account_name')" v-slot="{ errors }">
        <div class="form-group label-static" :class="{ 'error': errors.length }">
          <label for="azure_storage_account_name" class="control-label">{{ $t('integrations.cdn_azure_storage_account_name') }}</label>
          <input v-model="azure_storage_account_name" type="text" class="form-control" id="azure_storage_account_name">
          <span v-if="errors.length > 0" class="help-block error-block">{{ errors[0] }}</span>
        </div>
      </ValidationProvider>

      <ValidationProvider rules="required" :name="$t('integrations.cdn_azure_storage_access_key')" v-slot="{ errors }">
        <div class="form-group label-static" :class="{ 'error': errors.length }">
          <label for="azure_storage_access_key" class="control-label">{{ $t('integrations.cdn_azure_storage_access_key') }}</label>
          <input v-model="azure_storage_access_key" type="password" class="form-control" id="azure_storage_access_key">
          <span v-if="errors.length > 0" class="help-block error-block">{{ errors[0] }}</span>
        </div>
      </ValidationProvider>

      <hr/>

      <div class="form-group">
        <div class="checkbox">
          <label>
            <input
              type="checkbox"
              v-model="azure_cdn_enabled">
              {{ $t('integrations.cdn_azure_cdn_enabled') }}
          </label>
        </div>
      </div>

      <ValidationProvider :rules="{ url: { require_protocol: true }}" :name="$t('integrations.cdn_azure_cdn_endpoint')" v-slot="{ errors }">
        <div class="form-group label-static" :class="{ 'error': errors.length }">
          <label for="azure_cdn_endpoint" class="control-label">{{ $t('integrations.cdn_azure_cdn_endpoint') }}</label>
          <input :disabled="!azure_cdn_enabled" v-model="azure_cdn_endpoint" type="text" class="form-control" id="azure_cdn_endpoint">
          <span v-if="errors.length > 0" class="help-block error-block">{{ errors[0] }}</span>
          <p v-else class="help-block">{{ $t('integrations.cdn_azure_cdn_endpoint_help') }}</p>
        </div>
      </ValidationProvider>

      <hr/>

      <div class="cdn-azure-settings-sas">
        <div class="form-group">
          <div class="checkbox">
            <label>
              <input
                type="checkbox"
                v-model="azure_sas_enabled">
                {{ $t('integrations.cdn_azure_sas_enabled') }}
            </label>
          </div>
        </div>

        <div class="form-group label-static">
          <label for="azure_sas_expiry" class="control-label">{{ $t('integrations.cdn_azure_sas_expiry') }}</label>
          <input :disabled="!azure_sas_enabled" v-model="azure_sas_expiry" type="number" class="form-control" id="azure_sas_expiry">
          <p class="help-block">{{ $t('integrations.cdn_azure_sas_expiry_help') }}</p>
        </div>

        <div class="form-group label-static">
          <label for="azure_sas_ip_range" class="control-label">{{ $t('integrations.cdn_azure_sas_ip_range') }}</label>
          <input :disabled="!azure_sas_enabled" v-model="azure_sas_ip_range" type="text" class="form-control" id="azure_sas_ip_range" :placeholder="$t('integrations.cdn_azure_sas_ip_range_placeholder')">
          <p class="help-block">{{ $t('integrations.cdn_azure_sas_ip_range_help') }}</p>
        </div>
      </div>

      <hr/>

      <div class="cdn-azure-settings-security-token">
        <div class="form-group">
          <div class="checkbox">
            <label>
              <input
                type="checkbox"
                :disabled="!azure_cdn_enabled"
                v-model="azure_cdn_token_auth_enabled">
                {{ $t('integrations.cdn_azure_cdn_token_auth_enabled') }}
            </label>
          </div>
        </div>

        <div class="form-group label-static">
          <label for="azure_cdn_token_auth_key" class="control-label">{{ $t('integrations.cdn_azure_cdn_token_auth_key') }}</label>
          <input :disabled="!azure_cdn_token_auth_enabled || !azure_cdn_enabled" v-model="azure_cdn_token_auth_key" type="password" class="form-control" id="azure_cdn_token_auth_key">
          <p class="help-block">{{ $t('integrations.cdn_azure_cdn_token_auth_key_help') }}</p>
        </div>

        <div class="cdn-azure-settings-ip-mappings">
          <div v-if="!azure_cdn_token_auth_enabled || !azure_cdn_enabled" class="cdn-azure-settings-ip-mappings-disabled"/>
          <h3>
            {{ $t('integrations.cdn_azure_cdn_token_auth_allowed_ips') }}
            <button class="btn btn-fab btn-fab-mini btn-primary" type="button" @click="addIpMapping()"><i class="material-icons">add</i></button>
          </h3>
          <p>{{ $t('integrations.cdn_azure_cdn_token_auth_allowed_ips_help') }}</p>
          <div class="cdn-azure-settings-ip-mapping-head">
            <div class="cdn-azure-settings-ip-mapping-col">{{ $t('integrations.cdn_azure_cdn_token_auth_allowed_ips_source') }}</div>
            <div class="cdn-azure-settings-ip-mapping-col"/>
            <div class="cdn-azure-settings-ip-mapping-col">{{ $t('integrations.cdn_azure_cdn_token_auth_allowed_ips_target') }}</div>
            <div class="cdn-azure-settings-ip-mapping-col"/>
          </div>
          <div class="cdn-azure-settings-ip-mapping" v-for="mapping in azureIpMappings" :key="mapping.id">
            <div class="cdn-azure-settings-ip-mapping-source">
              <div class="form-group">
                <input class="form-control" :placeholder="$t('integrations.cdn_azure_cdn_token_auth_allowed_ips_placeholder')" type="text" :value="mapping.source" @input="updateIpMapping(mapping, 'source', $event.target.value)" />
              </div>
            </div>
            <div class="cdn-azure-settings-ip-mapping-arrow">
              <i class="material-icons">chevron_right</i>
            </div>
            <div class="cdn-azure-settings-ip-mapping-target">
              <div class="form-group">
                <input class="form-control" :placeholder="$t('integrations.cdn_azure_cdn_token_auth_allowed_ips_placeholder')" type="text" :value="mapping.target" @input="updateIpMapping(mapping, 'target', $event.target.value)" />
              </div>
            </div>
            <div class="cdn-azure-settings-ip-mapping-remove">
              <i class="material-icons" @click="removeIpMapping(mapping)">clear</i>
            </div>
          </div>
          <div class="cdn-azure-settings-ip-mapping-none" v-if="azureIpMappings.length === 0">
            {{ $t('integrations.cdn_azure_cdn_token_auth_allowed_ips_none') }}
          </div>
        </div>
      </div>

      <slot/>
    </div>
  </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex';
  import { v4 as uuid } from 'uuid';
  import Vue from 'vue';
  const { mapGetters } = createNamespacedHelpers('integrations');

  export default {
    data() {
      return {
        azure_storage_account_name: null,
        azure_storage_access_key: null,
        azure_sas_expiry: null,
        azure_sas_ip_range: null,
        azure_sas_enabled: null,
        azure_cdn_enabled: null,
        azure_cdn_endpoint: null,
        azure_cdn_token_auth_enabled: null,
        azure_cdn_token_auth_key: null,
        azure_cdn_token_auth_ip_mappings: []
      }
    },
    computed: {
      ...mapGetters([
        'integrationById'
      ]),
      integration() {
        return this.integrationById('cdn_azure');
      },
      settings() {
        return {
          azure_storage_account_name: this.azure_storage_account_name,
          azure_storage_access_key: this.azure_storage_access_key,
          azure_sas_expiry: this.azure_sas_expiry,
          azure_sas_ip_range: this.azure_sas_ip_range,
          azure_sas_enabled: this.azure_sas_enabled,
          azure_cdn_enabled: this.azure_cdn_enabled,
          azure_cdn_endpoint: this.azure_cdn_endpoint,
          azure_cdn_token_auth_enabled: this.azure_cdn_token_auth_enabled,
          azure_cdn_token_auth_key: this.azure_cdn_token_auth_key,
          azure_cdn_token_auth_ip_mappings_attributes: this.azure_cdn_token_auth_ip_mappings.map(mapping => {
            let newMapping = { ...mapping };

            if (typeof mapping.id === 'string') {
              delete newMapping.id;
            }

            return newMapping;
          })
        }
      },
      azureIpMappings() {
        return this.azure_cdn_token_auth_ip_mappings.filter(m => m['_destroy'] !== '1');
      }
    },
    methods: {
      load() {
        const settings = this.integration.settings;
        this.azure_storage_account_name = settings.azure_storage_account_name;
        this.azure_storage_access_key = settings.azure_storage_access_key;
        this.azure_sas_expiry = settings.azure_sas_expiry;
        this.azure_sas_ip_range = settings.azure_sas_ip_range;
        this.azure_sas_enabled = settings.azure_sas_enabled;
        this.azure_cdn_enabled = settings.azure_cdn_enabled;
        this.azure_cdn_endpoint = settings.azure_cdn_endpoint;
        this.azure_cdn_token_auth_enabled = settings.azure_cdn_token_auth_enabled;
        this.azure_cdn_token_auth_key = settings.azure_cdn_token_auth_key;
        this.azure_cdn_token_auth_ip_mappings = settings.azure_cdn_token_auth_ip_mappings;
      },
      addIpMapping() {
        this.azure_cdn_token_auth_ip_mappings.push({
          id: uuid(),
          source: '',
          target: ''
        });
      },
      updateIpMapping(ipMapping, attribute, value) {
        const index = this.azure_cdn_token_auth_ip_mappings.findIndex(m => m.id === ipMapping.id);
        let updatedElement = { ...this.azure_cdn_token_auth_ip_mappings[index] };
        updatedElement[attribute] = value;
        Vue.set(this.azure_cdn_token_auth_ip_mappings, index, updatedElement);
      },
      removeIpMapping(ipMapping) {
        const index = this.azure_cdn_token_auth_ip_mappings.findIndex(m => m.id === ipMapping.id);

        if (typeof ipMapping.id === 'string') {
          this.azure_cdn_token_auth_ip_mappings.splice(index, 1);
        } else {
          this.updateIpMapping(ipMapping, '_destroy', '1');
        }
      }
    },
    mounted() {
      this.load();
      $.material.init();
    }
  }
</script>

<style lang="scss" scoped>
  .cdn-azure-integration {
    display: flex;
  }

  .cdn-azure-settings {
    flex: 1 0;
    align-self: flex-start;
    min-width: 400px;
    max-width: 800px;
    background-color: $color-white;
    border-radius: 3px;
    padding: 30px;
  }

  .form-group.error {
    .control-label,
    .help-block {
      color: $color-error-red !important;
    }

    .form-control {
      border-color: $color-error-red;
      background-image: linear-gradient($color-error-red, $color-error-red), linear-gradient($color-error-red, $color-error-red);
    }
  }

  hr {
    margin: 20px -30px;
  }

  h3 {
    font-size: 14px;
    font-weight: 500;
  }

  p {
    margin-bottom: 24px;
    color: $black-54-opacity;
  }

  ::v-deep .checkbox input[type="checkbox"][disabled]~.checkbox-material .check {
    opacity: .5;
  }

  .cdn-azure-settings-ip-mappings {
    position: relative;
  }

  .cdn-azure-settings-ip-mapping-none {
    text-align: center;
    margin: 10px 0 42px;
  }

  .cdn-azure-settings-ip-mappings-disabled {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: $color-white;
    opacity: .4;
    z-index: 10;
  }

  .btn.btn-fab.btn-fab-mini {
    width: 30px;
    height: 30px;
    min-width: 30px;
    margin: 8px;

    > i {
      font-size: 24px;
    }
  }

  .cdn-azure-settings-ip-mapping-head,
  .cdn-azure-settings-ip-mapping {
    display: grid;
    grid-template-columns: 1fr 24px 1fr 24px;
    grid-gap: 10px;
    align-items: stretch;
  }

  .cdn-azure-settings-ip-mapping-col {
    justify-content: start;
    align-items: center;
    white-space: nowrap;
    font-weight: 400;
  }

  .cdn-azure-settings-ip-mapping-source,
  .cdn-azure-settings-ip-mapping-target,
  .cdn-azure-settings-ip-mapping-arrow,
  .cdn-azure-settings-ip-mapping-remove {
    justify-content: start;
    align-items: center;
    white-space: nowrap;

    .form-group {
      width: 100%;

      .form-control {
        margin: 0;
      }
    }
  }

  .cdn-azure-settings-ip-mapping-arrow > i {
    font-size: 24px;
  }

  .cdn-azure-settings-ip-mapping-remove > i {
    cursor: pointer;
    font-size: 24px;
  }
</style>
