export function init() {
  this.newTopicForm = new NewTopicComponent();
}

class NewTopicComponent {
  private topicForm: HTMLFormElement = null;
  private filmButton: HTMLButtonElement = null;
  private generatedFilmUploader: HTMLElement = null;
  private slideshowUploader: HTMLElement = null;

  constructor() {
    this.topicForm = document.getElementById('topic-form') as HTMLFormElement;
    this.filmButton = document.getElementById('create-topic-with-film') as HTMLButtonElement;
    this.generatedFilmUploader = document.querySelector('.medium-select .create-generated-film');
    this.slideshowUploader = document.querySelector('.medium-select .create-slideshow');

    if (this.filmButton) {
      this.initFilmButton();
    }

    if (this.generatedFilmUploader) {
      this.initUploader(this.generatedFilmUploader);
    }

    if (this.slideshowUploader) {
      this.initUploader(this.slideshowUploader);
    }
  }

  public initFilmButton(): void {
    const createURL = this.filmButton.getAttribute('data-url');
    this.filmButton.addEventListener('click', () => {
      this.topicForm.action = createURL;
      this.topicForm.submit();
    });
  }

  public initUploader(wrapper: HTMLElement): void {
    const uploader = wrapper.querySelector('.document-upload');
    const progressbar: HTMLElement = uploader.querySelector('.progress-bar');
    const createURL = wrapper.getAttribute('data-url');

    $(uploader).on('completed', (e, data) => {
      progressbar.style.width = '100%';
      const mediumField = this.createMediumField(data.id);
      this.topicForm.action = createURL;
      this.topicForm.appendChild(mediumField);
      this.topicForm.submit();
    });
  }

  private createMediumField(id: string): HTMLElement {
    let input = document.createElement('input');
    input.type = 'hidden';
    input.name = 'medium_id';
    input.value = id;

    return input;
  }
}

