import dimensions from '../constants/dimensions';

export async function getUserMediaWebcam(deviceId: string, ratio: number, height: number): Promise<MediaStream | null> {
  if (navigator.mediaDevices.getSupportedConstraints().aspectRatio) {
    try {
      return await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: { exact: deviceId },
          aspectRatio: { exact: ratio },
          height: { ideal: height },
        },
      });
    } catch (e) {
      console.error('[WEBCAM_STREAM] ', e);
      return null;
    }
  }

  const ratioDimensions = dimensions.filter(d => d.ratio === ratio).sort((a, b) => (a.height - height) - (b.height - height));

  for (const dimension of ratioDimensions) {
    try {
      return await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: { exact: deviceId },
          height: { exact: dimension.height },
          width: { exact: dimension.width },
        },
      });

    } catch (e) {
      if (!(e instanceof Error) || e.name !== 'OverconstrainedError') {
        console.error('[WEBCAM_STREAM] ', e);
        return null;
      }
    }
  }

  return null;
}

export async function getUserMediaMicrophone(deviceId: string): Promise<MediaStream | null> {
  try {
    return await navigator.mediaDevices.getUserMedia({
      audio: {
        deviceId: { exact: deviceId },
      },
    });
  } catch (e) {
    console.error('[MICROPHONE_STREAM] ', e);
    return null;
  }
}

export async function updateWebcamStreamResolution(stream: MediaStream, height: number): Promise<boolean> {
  if (navigator.mediaDevices.getSupportedConstraints().aspectRatio) {
    const ratio = stream.getVideoTracks()[0].getSettings().aspectRatio;

    await stream.getVideoTracks()[0]?.applyConstraints({
      height: { exact: height },
      aspectRatio: { exact: ratio },
    });

    return true;
  }

  const settings = stream.getVideoTracks()[0].getSettings();
  const ratio = settings.width! / settings.height!;

  const ratioDimensions = dimensions.filter(d => d.ratio === ratio).sort((a, b) => (a.height - height) - (b.height - height));

  for (const dimension of ratioDimensions) {
    try {
      await stream.getVideoTracks()[0]?.applyConstraints({
        height: { exact: dimension.height },
        width: { exact: dimension.width },
      });

      return true;
    } catch (e) {
      if (!(e instanceof Error) || e.name !== 'OverconstrainedError') {
        console.error('[WEBCAM_STREAM] ', e);
        return false;
      }
    }
  }

  return false;
}
