<template>
  <div class="onboarding-modal">
    <p>{{ description }} {{ $t('user_onboarding.tutorial_videos_notice') }}</p>
    <video-player
      :sources="video.source"
      :screenplay="video.screenplay">
    </video-player>

    <div class="bottom-controls">
      <div class="checkbox">
        <label>
          <input type="checkbox" :value="hideOnboarding" @change="updateHideOnboarding($event)">
          {{ $t('user_onboarding.do_not_show_again') }}
        </label>
      </div>

      <button type="button" class="btn btn-primary" @click="close">
        {{ $t('user_onboarding.got_it') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import VideoPlayer from '../../globals/vue/video_player';

const tutorialVideoMap = {
  new_topic: 'publish-video',
  new_course: 'create-course'
};

export default {
  components: {
    VideoPlayer
  },
  computed: {
    ...mapGetters({
      onboardingType: 'userOnboarding/onboardingType',
      hideOnboarding: 'userOnboarding/hideOnboarding',
      videoById: 'tutorials/videoById'
    }),
    description() {
      return this.$t(`user_onboarding.${this.onboardingType}_description`);
    },
    video() {
      const tutorialVideoId = tutorialVideoMap[this.onboardingType];
      return this.videoById(tutorialVideoId, true);
    }
  },
  methods: {
    ...mapActions({
      setHideOnboarding: 'userOnboarding/setHideOnboarding'
    }),
    close() {
      VERSTEHE.UserOnboarding.dialog.close();
    },
    updateHideOnboarding(event) {
      this.setHideOnboarding(event.target.checked);
    }
  },
  mounted() {
    $.material.init();
  }
}
</script>

<style lang="scss" scoped>
p {
  margin: 0 0 24px;
  font-size: 13px;
}

.bottom-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;

  .btn {
    margin: 0;
  }
}
</style>
