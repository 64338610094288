import { t } from './localization';
import { simpleContainsFilterUi, datepickerFilterUi, dateColumnTemplate } from '../../../grid/configurations/base_column_configuration';

export const columnConfiguration = [
  {
    field: 'thumbnail',
    title: ' ',
    sortable: false,
    filterable: false,
    hideable: false,
    attributes: {
      'class': 'grid-thumbnail'
    },
    template:
    `<div class="grid-thumbnail-image-wrapper">` +
    `# if (thumbnail) {# <div class="grid-thumbnail-image" style="background-image: url('#: thumbnail #');"></div> #} #` +
    `</div>`
  },
  {
    field: 'title',
    title: t('title'),
    attributes: {
      'class': 'grid-title'
    },
    template:
    `#: display_title #`,
    filterable: {
      operators: {
        string: {
          contains: t('contains')
        }
      },
      ui: simpleContainsFilterUi
    },
    hideable: false
  },
  {
    field: 'user',
    title: t('author'),
    attributes: {
      'class': 'grid-author'
    },
    template:
      `# if (user) { #` +
      `<div class="grid-author-wrapper">` +
        `<div class="user-profile-picture" style="background-image: url(#: user.avatar #)"></div>` +
        `<div class="user-info">` +
          `<a title="#: user.username #" href="#: user.show_url #">#: user.username #</a>` +
        `</div>` +
      `</div>` +
      `# } #`,
    filterable: {
      operators: {
        string: {
          contains: t('contains')
        }
      },
      ui: simpleContainsFilterUi
    },
    hideable: true
  },
  {
    field: 'is_active',
    title: t('is_active'),
    attributes: {
      'class': 'grid-active'
    },
    template:
      `# if(is_active === true) {#` +
          `${t('true')}` +
      `# } else { #` +
          `${t('false')}` +
      `# } #`,
    filterable: {
      messages: {
        info: '',
        isTrue: t('true'),
        isFalse: t('false'),
        trueLabel: t('is_active_label_true'),
        falseLabel: t('is_active_label_false')
      }
    },
    hidden: true,
    hideable: true,
    sortable: false
  },
  {
    field: 'is_public',
    title: t('is_public'),
    attributes: {
      'class': 'grid-public'
    },
    template:
    `# if(is_public === true) {#` +
        `${t('true')}` +
    `# } else { #` +
        `${t('false')}` +
    `# } #`,
    filterable: {
      messages: {
        info: '',
        isTrue: t('true'),
        isFalse: t('false'),
        trueLabel: t('is_public_label_true'),
        falseLabel: t('is_public_label_false')
      }
    },
    hidden: true,
    hideable: true,
    sortable: false
  },
  {
    field: 'is_film_default',
    title: t('is_film_default'),
    attributes: {
      'class': 'grid-film-default hide-actions'
    },
    template:
      `# if(is_film_default === true) {#` +
          `${t('true')}` +
      `# } else { #` +
          `${t('false')}` +
      `# } #`,
    hidden: false,
    hideable: true,
    sortable: false
  },
  {
    field: 'is_clip_default',
    title: t('is_clip_default'),
    attributes: {
      'class': 'grid-clip-default hide-actions'
    },
    template:
      `# if(is_clip_default === true) {#` +
          `${t('true')}` +
      `# } else { #` +
          `${t('false')}` +
      `# } #`,
    hidden: false,
    hideable: true,
    sortable: false
  },
  {
    field: 'created_at',
    title: t('created_at'),
    width: 150,
    attributes: {
      'class': 'grid-updated'
    },
    format: '{0:D}',
    filterable: {
      extra: true,
      operators: {
        date: {
          eq: t('is_equal_to'),
          gte: t('is_after_or_equal_to'),
          lte: t('is_before_or_equal_to')
        }
      },
      ui: datepickerFilterUi
    },
    hidden: true,
    hideable: true,
    template: dateColumnTemplate('created_at')
  },
  {
    field: 'updated_at',
    title: t('updated_at'),
    width: 150,
    attributes: {
      'class': 'grid-updated'
    },
    format: '{0:D}',
    filterable: {
      extra: true,
      operators: {
        date: {
          eq: t('is_equal_to'),
          gte: t('is_after_or_equal_to'),
          lte: t('is_before_or_equal_to')
        }
      },
      ui: datepickerFilterUi
    },
    hidden: false,
    hideable: true,
    template: dateColumnTemplate('updated_at')
  },
  {
    field: 'is_default',
    title: '',
    labelText: t('filter_only_defaults'),
    sortable: false,
    hideable: false,
    hidden: true,
    filterable: {
      onlyPreset: true
    }
  },
  {
    field: 'actions',
    title: ' ',
    attributes: {
      'class': 'grid-actions'
    },
    sortable: false,
    filterable: false,
    hideable: false,
    template:
    `# if (can_set_default) {#` +
      `# if(is_film_default === true) {#` +
        `<a class="grid-action-unset-default" href="\\#" data-unset-url="#: unset_default_url #" data-subject="film" data-id="#: id #"><i class="material-icons">toggle_on</i> ${t('unset_film_default')}</a>` +
      `# } else { #` +
        `<a class="grid-action-set-default" href="\\#" data-skip-confirm="#: is_public && is_active #" data-set-url="#: set_default_url #" data-subject="film" data-id="#: id #"><i class="material-icons">toggle_off</i> ${t('set_film_default')}</a>` +
      `# } #` +
      `# if(is_clip_default === true) {#` +
        `<a class="grid-action-unset-default" href="\\#" data-unset-url="#: unset_default_url #" data-subject="clip" data-id="#: id #"><i class="material-icons">toggle_on</i> ${t('unset_clip_default')}</a>` +
      `# } else { #` +
        `<a class="grid-action-set-default" href="\\#" data-skip-confirm="#: is_public && is_active #" data-set-url="#: set_default_url #" data-subject="clip" data-id="#: id #"><i class="material-icons">toggle_off</i> ${t('set_clip_default')}</a>` +
      `# } #` +
    `# } #` +
    `<a class="grid-action-edit" href="#: actions.edit #" data-method="get"><i class="material-icons">edit</i> ${t('action_edit')}</a>` +
    `<a class="grid-action-delete" href="\\#"><i class="material-icons">delete</i> ${t('action_delete')}</a>`
  }
];
