export const locals = {
  de: {
    private_select_placeholder: 'Benutzer und Zielgruppen auswählen ..',
    user: 'Benutzer',
    target_group: 'Zielgruppen',
    save: 'Speichern',
    publish: 'Veröffentlichen'
  },
  en: {
    private_select_placeholder: 'Select users and target groups ..',
    user: 'Users',
    target_group: 'Target Groups',
    save: 'Save',
    publish: 'Publish'
  },
  es: {
    private_select_placeholder: 'Seleccionar usuarios y grupos objetivo ..',
    user: 'Drogadicta',
    target_group: 'Grupo objetivo',
    save: 'Acumular',
    publish: 'Publicar'
  }
}

export function t(key) {
  return locals[_app.info.currentLocale][key] || key;
}
