export class ExportGrid {
  constructor () {}

  exportGrid(data, filename){
    let file = new Blob(["\ufeff",data], {type: 'text/csv;charset=utf-8;'}); // ufeff required for utf-8 encoding
    let a = document.createElement('a');
    a.href = URL.createObjectURL(file);
    a.download = filename;
    document.body.appendChild(a);
    a.click();
  }

  exportHeaderLine(columns, activeColumns){
    let headers = "";
    columns.forEach((column) =>{ 
      if(activeColumns.find((col)=> col === column.field)){
        headers += column.title + ";";
      }
    });
    headers = headers.substring(0, headers.length-1);
    headers += "\n";
    return headers;
  }

  getDateString(dateString){
    let date = new Date(dateString);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
  }
}
