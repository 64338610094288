import Component, { mixins } from "vue-class-component";
import { MapGetter } from "../../../../vue/decorators";
import { Getters } from "../../store/getters";
import { TemplateElementMixin } from "./template_element_mixin";

@Component
export class TextElementMixin extends mixins(TemplateElementMixin) {
  @MapGetter(Getters.FONTS) fonts: Array<string>;
  popupElement: HTMLDivElement = null;
  fontSizeSelect: HTMLSelectElement = null;
  textAlignSelect: HTMLSelectElement = null;
  settingsBtn: HTMLAnchorElement = null;

  kendoPopup = null;
  fontSizes = {
    [this.$i18n.t('media_assembling_templates.element_attributes.font_sizes.small') as string]: 60,
    [this.$i18n.t('media_assembling_templates.element_attributes.font_sizes.medium') as string]: 80,
    [this.$i18n.t('media_assembling_templates.element_attributes.font_sizes.big') as string]: 120,
  };

  mounted() {
    this.popupElement = <HTMLDivElement>this.$refs.popup;
    this.fontSizeSelect = <HTMLSelectElement>this.$refs.fontSizeSelect;
    this.textAlignSelect = <HTMLSelectElement>this.$refs.textAlignSelect;
    this.settingsBtn = <HTMLAnchorElement>this.$refs.settingsBtn;

    this.kendoPopup = $(this.popupElement).kendoPopup({
      anchor: $(this.settingsBtn),
      position: 'top right',
      origin: 'bottom right',
      animation: {
        close: {
          duration: 0
        }
      }
    }).data('kendoPopup');

    this.$on('start-move', () => this.kendoPopup.close());
  }

  togglePopup() {
    this.kendoPopup.toggle();
  }

  get textColor(): string {
    return this.element.text_color;
  }

  set textColor(value: string) {
    this.setAttributes({ text_color: value });
  }

  get height(): number {
    return this.element.height;
  }

  set height(value: number) {
    this.setAttributes({ height: value });
  }

  get textAlign(): string {
    return this.element.text_align;
  }

  set textAlign(value: string) {
    this.setAttributes({ text_align: value });
  }

  get font(): string {
    return this.element.font;
  }

  set font(value) {
    this.setAttributes({ font: value });
  }

  get backgroundColor(): string {
    return this.element.background_color === 'transparent' ? null : this.element.background_color;
  }

  /**
   * Used to display preview. If no color was set a white background with opacity will be displayed.
   */
  get backgroundDisplayColor(): string {
    return this.backgroundColor ? this.backgroundColor : "rgba(255, 255, 255, .7)";
  }

  set backgroundColor(value: string) {
    this.setAttributes({ background_color: value });
  }
}

