<template>
  <div class="form-pane">
    <slide-x-right-transition mode="out-in" :duration="200">
      <question-form v-if="currentQuestion"/>
      <div class="no-question-placeholder" v-else>
        <i class="material-icons">help_outline</i>
        <h2>{{ $t('film_questions.create_or_select_question') }}</h2>
        <img src="~/images/arrow_left.svg">
      </div>
    </slide-x-right-transition>
  </div>
</template>

<script>
import QuestionForm from './question_form';
import { mapGetters } from 'vuex';
import { SlideXRightTransition } from 'vue2-transitions';

import 'images/arrow_left.svg';

export default {
  components: {
    QuestionForm,
    SlideXRightTransition
  },
  computed: {
    ...mapGetters({
      currentQuestion: 'filmQuestions/currentQuestion'
    })
  }
}
</script>

<style lang="scss" scoped>
.form-pane {
  flex: 1 0 0;
  min-width: 600px;
  overflow-y: auto;
  padding: 10px 5%;
  background-color: $color-grey-10;
}

.no-question-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  h2 {
    margin: 24px 0;
    text-align: center;
  }

  i {
    font-size: 92px;
  }

  img {
    height: 128px;
    opacity: .2;
  }
}
</style>
