export class Datepicker {
  constructor(uuid, container, options) {
    this.uuid = uuid;
    this.container = $(container);
    this.inputField = this.setDatepickerField();
    this.min = options['min'];
    this.datepicker = null;
    this.valueField = this.container.find(
      `.${this.uuid}-datepicker-value-field`
    );
    this.noValueField = options['noValueField'];
    this.disableMinToTodayDiff = options['minToDayDiff'];
    this.initKendoDatePicker();
    this.deletable = this.datepicker.element.data('deletable');
    this.deletableTemplateAdded = false;
    this.deletableTemplate = `<i class='clear-datepicker material-icons' title='${VERSTEHE.vueI18n.t(
      'globals.datepicker.clear'
    )}'>clear</i>`;
    this.refreshable = this.datepicker.element.data('refreshable');
    this.refreshableTemplateAdded = false;
    this.refreshableTemplate = `<i class='refresh-datepicker material-icons' title='${VERSTEHE.vueI18n.t(
      'globals.datepicker.refresh'
    )}'>cached</i>`;
    this.setMax(options['max']);
    this.setMin(options['min']);
    this.setValue(options['value']);
  }

  initKendoDatePicker() {
    VERSTEHE.kendoui.changeLocale(_app.info.currentLocale);

    this.datepicker = this.inputField
      .kendoDatePicker({
        dateInput: false,
        change: this.onChange.bind(this),
      })
      .data('kendoDatePicker');

    this.datepicker.element[0].nextElementSibling.setAttribute(
      'title',
      VERSTEHE.vueI18n.t('globals.datepicker.select')
    );

    if (this.disableMinToTodayDiff) {
      this.disableDates();
    }
  }

  onChange() {
    if (this.noValueField !== true) {
      this.valueField.val(moment(this.datepicker.value()).format());
    }

    const validated = this.validateDate(this.datepicker.value());
    if (validated !== true) {
      this.datepicker.value(validated);
    }

    this.deleteableIcon();
    this.refereshableIcon();
  }

  setDatepickerField() {
    if (this.uuid) {
      return this.container.find(`.${this.uuid}-datepicker`);
    } else {
      return this.container.find('input');
    }
  }

  setValue(value) {
    if (value !== undefined && value !== null) {
      this.datepicker.value(value);
    } else if (
      this.valueField.val() !== undefined &&
      this.valueField.val() !== ''
    ) {
      this.datepicker.value(
        this.container.find(`.${this.uuid}-datepicker-value-field`).val()
      );
    }

    this.deleteableIcon();
    this.refereshableIcon();
  }

  setMax(max) {
    if (max != null && max !== undefined) {
      this.datepicker.max(new Date(max));
    }
  }

  setMin(min) {
    if (min != null && min !== undefined) {
      this.datepicker.min(min);
    }
  }

  getValue() {
    return this.valueField.val() || this.datepicker.value();
  }

  getValueAsMoment() {
    let value = this.valueField.val() || this.datepicker.value();
    return moment(new Date(value));
  }

  dateWithoutTime(date) {
    if (date === null) {
      return null;
    }
    date.setHours(0, 0, 0, 0);

    return date;
  }

  refereshableIcon() {
    if (!this.refreshable) {
      return;
    }
    if (this.refreshableTemplateAdded === false) {
      this.addRefreshableTemplate();
    }

    if (
      this.dateWithoutTime(this.datepicker.value()) <
      this.dateWithoutTime(this.datepicker.max())
    ) {
      this.container.find('.refresh-datepicker').show();
    } else {
      this.container.find('.refresh-datepicker').hide();
    }
  }

  addRefreshableTemplate() {
    this.container.append(this.refreshableTemplate);
    this.refreshableTemplateAdded = true;
    // Get the HTML element from jquery object
    let htmlContainer = this.container[0];
    let icon = htmlContainer.querySelector('.refresh-datepicker');

    icon.addEventListener('click', (e) => {
      this.datepicker.value(this.datepicker.max());
      this.datepicker.trigger('change');
    });
  }

  deleteableIcon() {
    if (!this.deletable) {
      return;
    }
    if (this.deletableTemplateAdded === false) {
      this.addDeletableTemplate();
    }

    if (this.datepicker.value() !== null) {
      this.container.find('.clear-datepicker').show();
    } else {
      this.container.find('.clear-datepicker').hide();
    }
  }

  addDeletableTemplate() {
    this.container.append(this.deletableTemplate);
    this.deletableTemplateAdded = true;
    // Get the HTML element from jquery object
    let htmlContainer = this.container[0];
    let icon = htmlContainer.querySelector('.clear-datepicker');

    icon.addEventListener('click', (e) => {
      this.datepicker.value(null);
      this.datepicker.trigger('change');
    });
  }

  disableDates() {
    if (this.datepicker.value() === null) {
      return;
    }
    this.datepicker.setOptions({
      disableDates: function (date) {
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        if (
          date !== null &&
          date.getTime() < today.getTime() &&
          date.getTime() > this.datepicker.min().getTime()
        ) {
          return true;
        } else {
          return false;
        }
      }.bind(this),
    });
  }

  validateDate(date) {
    if (this.deletable && date === null) {
      this.datepicker.value(null);
      return true;
    }
    if (this.datepicker.value() === null || date === null) {
      //check if date or datepicker value is null --> set to max date. otherwise the last valid date cannot be selected
      this.datepicker.value(this.datepicker.max());
      date = this.datepicker.max();
      this.updateErrorMessage(true);
      return date;
    }

    const isDate = !isNaN(Date.parse(date)) && Date.parse(date) !== null;
    const dateObject = new Date(date);
    const min = new Date(this.datepicker.min());
    const max = new Date(this.datepicker.max());
    const isBetween = dateObject >= min && dateObject <= max;

    if (isDate && isBetween) {
      this.updateErrorMessage(false);
      return true;
    } else {
      this.updateErrorMessage(true);
      return min;
    }
  }

  updateErrorMessage(show) {
    if (this.container.find('.error-span')[0]) {
      this.container.children().last().remove();
    }
    if (show) {
      let span = document.createElement('span');
      span.classList.add('error-span');
      span.innerText = VERSTEHE.vueI18n.t('globals.datepicker.invalid_date');
      this.container.append(span);
    }
  }
}
