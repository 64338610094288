export function launchUriHandler(uri) {
  return new Promise((resolve, reject) => {
    if (navigator.msLaunchUri) {
      // For IE and Edge in Win 8 & Win 10
      openUriWithMsLaunchUri(uri, resolve, reject);
    } else {
      let browser = VERSTEHE.utils.browserInfo();

      if (browser.name === 'Firefox') {
        openUriUsingFirefox(uri, resolve, reject);
      } else if (browser.name === 'Chrome') {
        openUriWithTimeoutHack(uri, resolve, reject);
      } else if (browser.name === 'Safari') {
        openUriWithHiddenFrame(uri, resolve, reject);
      } else if (browser.name === 'Internet Explorer') {
        openUriWithHiddenFrame(uri, resolve, reject);
      } else {
        reject();
      }
    }
  });
}

function _registerEvent (target, eventType, cb) {
  if (target.addEventListener) {
    target.addEventListener(eventType, cb);
    return {
      remove: function () {
        target.removeEventListener(eventType, cb);
      }
    };
  } else {
    target.attachEvent(eventType, cb);
    return {
      remove: function () {
        target.detachEvent(eventType, cb);
      }
    };
  }
}

function _createHiddenIframe (target, uri) {
  let iframe = document.createElement('iframe');
  iframe.src = uri;
  iframe.id = 'hiddenIframeUriHandler';
  iframe.style.display = 'none';
  target.appendChild(iframe);

  return iframe;
}

function openUriWithHiddenFrame (uri, resolve, reject) {
  let timeout = setTimeout(function () {
    reject();
    handler.remove();
  }, 1000);

  let iframe = document.querySelector('#hiddenIframeUriHandler');
  if (!iframe) {
    iframe = _createHiddenIframe(document.body, 'about:blank');
  }

  let handler = _registerEvent(window, 'blur', onBlur);

  function onBlur () {
    clearTimeout(timeout);
    handler.remove();
    resolve();
  }

  iframe.contentWindow.location.href = uri;
}

function openUriWithTimeoutHack (uri, resolve, reject) {
  let timeout = setTimeout(function () {
    reject();
    handler.remove();
  }, 1000);

  // handle page running in an iframe (blur must be registered with top level window)
  let target = window;
  while (target !== target.parent) {
    target = target.parent;
  }

  let handler = _registerEvent(target, 'blur', onBlur);

  function onBlur () {
    clearTimeout(timeout);
    handler.remove();
    resolve();
  }

  window.location = uri;
}

function openUriUsingFirefox (uri, resolve, reject) {
  let iframe = document.querySelector('#hiddenIframeUriHandler');

  if (!iframe) {
    iframe = _createHiddenIframe(document.body, 'about:blank');
  }

  try {
    iframe.contentWindow.location.href = uri;
    resolve();
  } catch (e) {
    if (e.name === 'NS_ERROR_UNKNOWN_PROTOCOL') {
      reject();
    }
  }
}

function openUriWithMsLaunchUri (uri, resolve, reject) {
  navigator.msLaunchUri(uri,
    resolve,
    reject
  );
}
