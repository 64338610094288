import { VideoUploader } from './video_uploader';

class UploaderService {
  
  private videoUploader = null;

  get videoUploaderInstance(): VideoUploader {
    if (this.videoUploader === null) {
      this.videoUploader = new VideoUploader(_app.info.tusUrl);
    }

    return this.videoUploader;
  }
}

export default new UploaderService();
