import {
  createLocalAudioTrack,
  createLocalVideoTrack,
  LocalAudioTrack,
  LocalVideoTrack,
  Track,
} from 'livekit-client';
import { ConferenceSettings } from '../entities/ConferenceSettings';
import { state } from '../store/state';
import store from '../../store';
import type SelfieSegmentationStream from '../../common/background_replacement/selfie_segmentation_stream';
import { Actions } from '../store/actions';
import logger from '../../logger/logger';

export class VideoAudioService {
  private disableCamButton = false;
  private disableMicButton = false;
  private backgroundReplacementCache: {
    deviceId: string;
    track: MediaStreamTrack;
  } = null;

  constructor(private selfieSegmentationStream: SelfieSegmentationStream) {
    store.watch((state) => state.webConference.userSettings.backgroundReplacement, async (value, oldValue) => {
      if (oldValue === undefined || value === undefined) {
        if (state.onJoinPage) {
          await this.changeLocalVideoTrack();
        } else {
          await this.changeVideoTrack();
        }
      } else {
        this.selfieSegmentationStream.setBackground(value);
      }

      // A deactivated cam should be activated when backgroundReplacement is turned on or if on join page
      // turning off the backgroundReplacement should never activate the cam
      if (state.onJoinPage) {
        await this.setCamActivatedJoinPage(true);
      } else if (value !== undefined) {
        await this.setCamActivated(true);
      }
    });
  }

  get webcamId(): MediaDeviceInfo['deviceId'] {
    return state.currentWebcamId ?? state.userSettings.selectedCam;
  }

  get microphoneId(): MediaDeviceInfo['deviceId'] {
    return state.currentMicrophoneId ?? state.userSettings.selectedMic;
  }

  async setCameraAndMicWhenJoining() {
    if (this.microphoneId && state.micActivated) {
      state.localParticipant.setMicrophoneEnabled(true);
    }

    if (!this.webcamId) {
      state.camActivated = false;
      return;
    }

    if (state.userSettings.backgroundReplacement) {
      if (state.camActivated) {
        await this.startBackgroundReplacement();
      } else {
        state.camActivated = false;
        await this.startBackgroundReplacement();
        this.selfieSegmentationStream.pause();
      }
    } else if (state.camActivated) {
      state.localParticipant.setCameraEnabled(true);
    } else {
      state.camActivated = false;
    }
  }

  async changeAudioTrack() {
    try {
      let audioTracks = state.localParticipant.getTracks().filter(track => track.kind === Track.Kind.Audio);
      if (audioTracks.length > 0) {
        await (audioTracks[0].track as LocalAudioTrack).restartTrack({
          deviceId: this.microphoneId,
          echoCancellation: true,
          noiseSuppression: state.userSettings.noiseSuppression,
        });
      } else {
        let localAudioTrack = await createLocalAudioTrack({
          deviceId: this.microphoneId,
          echoCancellation: true,
          noiseSuppression: state.userSettings.noiseSuppression,
        });

        if (!state.micActivated) {
          localAudioTrack.mute();
        }
        state.currentMicrophoneId = await localAudioTrack.getDeviceId();
        await state.localParticipant.publishTrack(localAudioTrack);
      }
    } catch (error) {
      logger.error({
        message: 'changeAudioTrack failed',
        error,
        section: 'VideoAudioService:changeAudioTrack',
      });
    }
  }

  async startBackgroundReplacement(): Promise<void> {
    let brTrack: MediaStreamTrack;
    let firstFrameReady: Promise<void>;

    store.dispatch(Actions.SET_WEBCAM_LOADING, true);

    if (this.backgroundReplacementCache?.deviceId === state.currentWebcamId && this.backgroundReplacementCache?.track.readyState !== 'ended' && !state.onJoinPage) {
      brTrack = this.backgroundReplacementCache.track;
    } else {
      const localVideoTrack = await createLocalVideoTrack({
        deviceId: this.webcamId,
        resolution: {
          width: ConferenceSettings.videoWidthPx,
          height: ConferenceSettings.videoHeightPx,
          frameRate: ConferenceSettings.frameRate,
        },
      });
      state.currentWebcamId = await localVideoTrack.getDeviceId();

      const inputStream = new MediaStream([localVideoTrack.mediaStreamTrack]);
      const outputStream = await this.selfieSegmentationStream.start(inputStream, state.userSettings.backgroundReplacement);
      firstFrameReady = outputStream.firstFrameReady;

      // if cam is not activated there will be no frames to process
      if (state.camActivated) {
        await firstFrameReady;
      }

      brTrack = outputStream.getVideoTracks()[0];

      this.backgroundReplacementCache = {
        deviceId: state.currentWebcamId,
        track: brTrack,
      };
    }

    if (state.onJoinPage) {
      state.localVideoTrack = new LocalVideoTrack(brTrack);
    } else {
      const oldTrack = state.localParticipant.getTrack(Track.Source.Camera)?.videoTrack;
      if (oldTrack) {
        state.localParticipant.unpublishTrack(oldTrack);
      }

      await state.localParticipant.publishTrack(brTrack, {
        source: Track.Source.Unknown,
        name: 'canvas',
      });
      state.localParticipant.setCameraEnabled(state.camActivated);
    }

    if (firstFrameReady) {
      firstFrameReady.then(() => {
        setTimeout(() => store.dispatch(Actions.SET_WEBCAM_LOADING, false), 100);
      });
    } else {
      store.dispatch(Actions.SET_WEBCAM_LOADING, false);
    }
  }

  async changeVideoTrack(): Promise<void> {
    if (state.userSettings.backgroundReplacement) {
      return this.startBackgroundReplacement();
    } else {
      this.selfieSegmentationStream.stop();
      this.backgroundReplacementCache = null;
    }

    const videoTracks = state.localParticipant.getTracks().filter(track => track.kind === Track.Kind.Video && track.source !== 'screen_share');

    if (videoTracks.length > 0) {
      try {
        const track = videoTracks[0].track as LocalVideoTrack;
        await track.restartTrack({
          deviceId: this.webcamId,
        });
        state.showCameraError = false;

        if (!state.camActivated) {
          track.mute();
        }
      } catch (error) {
        this.cameraError(error);
        (videoTracks[0].track as LocalVideoTrack).restartTrack();
      }
    } else {
      try {
        const localVideoTrack = await createLocalVideoTrack({
          deviceId: this.webcamId,
          resolution: {
            width: ConferenceSettings.videoWidthPx,
            height: ConferenceSettings.videoHeightPx,
            frameRate: ConferenceSettings.frameRate,
          },
        });

        if (!state.camActivated) {
          localVideoTrack.mute();
        }

        state.currentWebcamId = await localVideoTrack.getDeviceId();
        await state.localParticipant.publishTrack(localVideoTrack, {
          simulcast: true,
        });
        state.showCameraError = false;
      } catch (error) {
        this.cameraError(error);
      }
    }
  }

  async changeLocalVideoTrack() {
    try {
      if (state.userSettings.backgroundReplacement) {
        await this.startBackgroundReplacement();
      } else {
        this.selfieSegmentationStream.stop();
        this.backgroundReplacementCache = null;

        state.localVideoTrack = await createLocalVideoTrack({
          deviceId: this.webcamId,
          resolution: {
            width: ConferenceSettings.videoWidthPx,
            height: ConferenceSettings.videoHeightPx,
            frameRate: ConferenceSettings.frameRate,
          },
        });

        state.currentWebcamId = await state.localVideoTrack.getDeviceId();
      }

      this.appendVideoElement(state.localVideoTrack);

      if (!state.camActivated) {
        state.localVideoTrack.mute();
      }

      state.showCameraError = false;
    } catch (error) {
      this.cameraError(error);
    }
  }

  cameraError(error) {
    logger.error({
      message: 'camera error',
      error,
      section: 'VideoAudioService:cameraError',
    });

    state.showCameraError = true;
    setTimeout(() => {
      state.showCameraError = false;
    }, 10000);
  }

  async changeLocalAudioTrack() {
    try {
      const oldTrack = state.localAudioTrack;

      state.localAudioTrack = await createLocalAudioTrack({
        deviceId: this.microphoneId,
        echoCancellation: true,
        noiseSuppression: state.userSettings.noiseSuppression,
      });

      state.currentMicrophoneId = await state.localAudioTrack.getDeviceId();

      oldTrack?.stop();
    } catch (error) {
      logger.error({
        message: 'createLocalAudioTrack failed',
        error,
        section: 'VideoAudioService:changeLocalAudioTrack',
      });
    }
  }

  async toggleMic() {
    if (this.disableMicButton) {
      return;
    }
    this.disableMicButton = true;
    try {
      const status = !state.micActivated;
      await state.localParticipant.setMicrophoneEnabled(status);
      state.micActivated = status;
    } catch (error) {
      logger.error({
        message: 'toggleMic failed',
        error,
        section: 'VideoAudioService:toggleMic',
      });
    }
    this.disableMicButton = false;
  }

  async toggleCam() {
    this.setCamActivated(!state.camActivated);
  }

  async setCamActivated(activate: boolean) {
    if (this.disableCamButton) {
      return;
    }
    this.disableCamButton = true;

    try {
      if (activate) {
        this.selfieSegmentationStream.play();
      }

      await state.localParticipant.setCameraEnabled(activate);

      if (!activate) {
        this.selfieSegmentationStream.pause();
      }

      state.camActivated = activate;
    } catch (error) {
      logger.error({
        message: 'setCamActivated failed',
        error,
        section: 'VideoAudioService:setCamActivated',
      });
    }

    this.disableCamButton = false;
  }

  async toggleMicJoinPage() {
    if (state.micActivated) {
      state.micActivated = false;
      state.localAudioTrack?.stop();
      state.localAudioTrack = undefined;
    } else {
      state.micActivated = true;
      this.changeLocalAudioTrack();
    }
  }

  async toggleCamJoinPage() {
    this.setCamActivatedJoinPage(!state.camActivated);
  }

  async setCamActivatedJoinPage(activate: boolean) {
    if (state.camActivated === activate) {
      return;
    }

    state.camActivated = activate;
    if (state.camActivated) {
      this.changeLocalVideoTrack();
    } else {
      state.localVideoTrack?.stop();
      state.localVideoTrack = undefined;
    }
  }

  appendVideoElement(element) {
    const videoContainer = document.getElementById('videoContainer');

    videoContainer.innerHTML = ``;
    const videoElement = element.attach();
    videoElement.style.height = '100%';
    videoElement.style.width = '100%';
    videoElement.style.position = 'relative';
    videoElement.style.borderRadius = '8px';
    videoElement.style.transform = 'scale(-1,1)';
    videoContainer.appendChild(videoElement);
  }
}

