import { GetterTree } from 'vuex';
import { RootState } from '../../store/types';
import { TwoFactorState } from './index';

export enum GetterTypes {
  PROVISIONING_URI = 'twoFactor/provisioningUri',
  OTP_SECRET = 'twoFactor/otpSecret',
  VERIFICATION_METHOD = 'twoFactor/verificationMethod',
  BACKUP_CODES = 'twoFactor/backupCodes',
  REDIRECT_URL = 'twoFactor/redirectUrl',
  EMAIL = 'twoFactor/email'
}

export const getters: GetterTree<TwoFactorState, RootState> = {
  provisioningUri(state): string {
    return `otpauth://totp/coovi:${_app.info.tenant}?secret=${state.otpSecret}&issuer=coovi`;
  },
  otpSecret(state): string {
    return state.otpSecret;
  },
  verificationMethod(state): string {
    return state.verificationMethod;
  },
  backupCodes(state): string[] {
    return state.backupCodes;
  },
  redirectUrl(state): string {
    return state.redirectUrl;
  },
  email(state): string {
    return state.email;
  }
}
