<template>
  <div>
    <table class="table table-striped table-hover">
      <thead>
        <th>{{ $t('user_import.field') }}</th>
        <th>{{ $t('user_import.column') }}</th>
        <th>
          {{ $t('user_import.example') }}
          <button class="btn btn-fab btn-fab-mini btn-default" @click="updateSampleData">
            <i class="material-icons">refresh</i>
          </button>
        </th>
      </thead>

      <tbody>
        <tr v-for="mapping in mappings" :key="mapping.field">
          <td>{{ $t(`user_import.field_${mapping.field}`) }}</td>
          <td>
            <select :data-field="mapping.field" class="user-import-column-select" :value="mapping.column">
              <option v-for="(column, index) in columns" :key="column" :value="index">{{ column }}</option>
            </select>
          </td>
          <td>
            <span v-if="mapping.column > 0">{{ sampleData[mapping.column - 1] }}</span>
          </td>
        </tr>
      </tbody>
    </table>

    <button class="btn btn-primary" :class="{ disabled: saving }" @click="startImport">{{ $t('user_import.start_import') }}</button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Actions } from '../store';
import { confirmDialog } from '../../common/dialog';

export default {
  data: () => {
    return {
      sampleDataIndex: 0,
      sampleData: []
    }
  },
  computed: {
    ...mapGetters({
      saving: 'userImport/saving',
      mappings: 'userImport/mappings',
      containsHeadings: 'userImport/containsHeadings',
      importAction: 'userImport/importAction',
      info: 'userImport/info'
    }),
    columns() {
      let columns = [this.$t('user_import.column_unassigned')];

      if (this.containsHeadings) {
        columns = columns.concat(this.info.first);
      } else {
        columns = columns.concat(
          Array.from({ length: this.info.columns }, (_, i) => {
            return `${this.$t('user_import.column')} ${i + 1}`;
          })
        );
      }

      return columns;
    }
  },
  methods: {
    ...mapActions({
      saveMappings: Actions.SAVE_MAPPINGS,
      updateMapping: Actions.UPDATE_MAPPING
    }),
    updateSampleData() {
      const max = this.info.sample.length;

      if (this.containsHeadings) {
        this.sampleDataIndex = Math.floor(Math.random() * (max - 1) + 1);
      } else {
        this.sampleDataIndex = Math.floor(Math.random() * max);
      }

      this.sampleData = this.info.sample[this.sampleDataIndex];
    },
    startImport() {
      if (this.importAction !== 'update') {
        confirmDialog({
          title: this.$i18n.t('user_import.confirm_import_emails_warning_title'),
          content: this.$i18n.t('user_import.confirm_import_emails_warning')
        }).then(() => {
          this.saveMappings();

          // Fix backdrop of import modal
          VERSTEHE.UserImport.dialog.open();
        }, () => {
          // Fix backdrop of import modal
          VERSTEHE.UserImport.dialog.open();
        });
      } else {
        this.saveMappings();
      }
    }
  },
  mounted() {
    $(this.$el).find('.user-import-column-select').kendoDropDownList({
      change: (event) => {
        const field = event.sender.element[0].dataset.field;
        const column = parseInt(event.sender.value(), 10);

        this.updateMapping({ field, column });
      }
    });

    VERSTEHE.UserImport.dialog.center();
    this.updateSampleData();
    $.material.init();
  }
}
</script>

<style lang="scss" scoped>
.table > tbody > tr > td {
  padding: 4px;
}

tbody {
  display: block;
  max-height: 400px;
  overflow-y: auto;
}

thead,
tbody > tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.btn.btn-fab.btn-fab-mini {
  width: 30px;
  height: 30px;
  min-width: 30px;
  margin: 8px;

  > i {
    font-size: 24px;
  }
}
</style>
