export class TargetGroupSelect {
  selectElement: HTMLSelectElement;
  searchUrl: string;
  multiselect = null;
  preselected: Array<{ id: string, title: string}>;
  activeFields: Array<number>;
  template = (data, type) => `<i class="ps-avatar ps-icon ps-${ type } material-icons">group</i><span class="ps-label ps-${type}">${data.title}</span>`;

  constructor(selector = '#target_group_ids') {
    this.selectElement = document.querySelector(selector);

    if (this.selectElement) {
      this.searchUrl = this.selectElement.getAttribute('data-search-url');
      this.preselected = JSON.parse(this.selectElement.getAttribute('data-preselected'));
      this.activeFields = JSON.parse(this.selectElement.getAttribute('data-active-fields'))
      this.init();
    }
  }

  init() {
    this.multiselect = $(this.selectElement).kendoMultiSelect({
      autoBind: true,
      placeholder: VERSTEHE.vueI18n.t('globals.contributions.target_group_placeholder'),
      dataTextField: 'title',
      dataValueField: 'id',
      height: 400,
      delay: 800,
      itemTemplate: data => this.template(data, 'item'),
      tagTemplate: data => this.template(data, 'tag'),
      select: (e) => this.onSelect(e),
      change: (e) => this.onChange(e),
      dataSource: {
        transport: {
          read: this.searchUrl
        },
        schema: {
          data: (response) => {
            return response;
          }
        },
        serverFiltering: true,
      },
      value: this.preselected
    }).data('kendoMultiSelect');
  }

  onChange(e) {
    this.multiselect.element[0].dispatchEvent(new CustomEvent('change'))
  }

  onSelect(e) {
    const targetGroup = e.dataItem;
    const presentElements = targetGroup.metadata_links.filter(link => this.activeFields.indexOf(link.field_id) >= 0);

    // Check if any metadata field would be changed
    const showDialog = presentElements.some(link => {
      if (link.elements.length > 0) {
        let selected = [];

        switch(link.type) {
          case 'tree_field': {
            const treeField = VERSTEHE.Taxonomy.getByFieldId(link.field_id);
            selected = treeField.getChecked(null).map(o => o.id);
            break;
          }
          case 'set_field': {
            const setField = VERSTEHE.ContributionGeneral.getSetFieldById(link.field_id);
            selected = setField.selectize.items;
            break;
          }
        }

        const diff = selected.filter(x => link.elements.indexOf(x) === -1).concat(link.elements.filter(x => selected.indexOf(x) === -1));
        return diff.length > 0;
      } else {
        return false;
      }
    });

    if (showDialog) {
      this.showDialog(presentElements).then(() => {
        presentElements.forEach(link => {
          if (link.elements.length > 0) {
            switch(link.type) {
              case 'tree_field': {
                const tree = VERSTEHE.Taxonomy.getByFieldId(link.field_id);
                tree.setChecked(link.elements);
                tree.setSelectedElementsHelper(tree.getChecked());
                break;
              }
              case 'set_field': {
                const setField = VERSTEHE.ContributionGeneral.getSetFieldById(link.field_id);
                setField.selectize.clear(true);
                link.elements.forEach(element => setField.selectize.addItem(element, true));
                setField.selectize.refreshItems();
                break;
              }
            }
          }
        });
      }).catch(() => null);
    }
  }

  showDialog(metadataLinks) {
    return new Promise((resolve, reject) => {
      const options = {
        width: '450px',
        closable: false,
        title: VERSTEHE.vueI18n.t('globals.contributions.target_group_confirm_metadata_title'),
        content: this.buildDialogBody(metadataLinks),
        modal: true,
        buttonLayout: 'normal',
        actions: [{
          text: VERSTEHE.vueI18n.t('globals.decline'),
          action: reject,
          primary: false
        }, {
          text: VERSTEHE.vueI18n.t('globals.accept'),
          action: resolve,
          primary: true
        }],
        close: function() { this.destroy(); }
      };

      $('<div/>').kendoDialog(options).data('kendoDialog').open();
    });
  }

  buildDialogBody(metadataLinks: Array<any>) {
    const langAttribute = _app.info.currentLocale === "de" ? 'title_de' : 'title_en';
    const fieldsWithElements = metadataLinks.filter(link => link.elements.length > 0).map(link => link[langAttribute]);

    return `
      <div>
        ${VERSTEHE.vueI18n.t('globals.contributions.target_group_confirm_metadata_description')}
      </div>
      <div style="margin-top: 15px;">
        ${VERSTEHE.vueI18n.t('globals.contributions.target_group_confirm_fields')}
      </div>
      <ul>
        <li style="padding: 5px 0;">
          ${fieldsWithElements.join('</li><li>')}
        </li>
      </ul>
    `;
  }

  values() {
    if (this.multiselect) {
      return this.multiselect.value();
    } else {
      return null;
    }
  }
}
