import Vue from 'vue';
import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator';
import { Actions } from '../../store/actions';
import { MapAction } from '../../../../vue/decorators';
import { TemplateElement } from '../../interfaces';
import interact from 'interactjs';
import { CurrentSlideMixin } from './current_slide_mixin';

@Component
export class TemplateElementMixin extends mixins(CurrentSlideMixin) {
  @Prop() element: TemplateElement;
  @Prop() parentWidth: number;
  @Prop() parentHeight: number;
  @MapAction(Actions.TRANSFORM_ELEMENT) transformElement: (payload: { slideKey: string, elementId: string, x: number, y: number, width?: number }) => void;
  @MapAction(Actions.REMOVE_ELEMENT) removeElement: (payload: { slideKey: string, elementId: string }) => void;
  @MapAction(Actions.SET_ELEMENT_ATTRIBUTES) setElementAttributes: (payload: { slideKey: string, elementId: string, attributes }) => void;

  dragElement: HTMLDivElement = null;

  mounted() {
    this.dragElement = <HTMLDivElement>this.$refs.dragElement;
    this.initDraggable();
  }

  initDraggable() {
    interact(this.dragElement).draggable({
      modifiers: [
        interact.modifiers.restrictRect({
          restriction: 'parent',
          endOnly: true
        })
      ],
      listeners: {
        start: () => this.$emit('start-move'),
        move: this.onDrag
      }
    })
    .resizable({
      edges: { left: true, right: true, bottom: false, top: false },
      listeners: {
        start: () => this.$emit('start-move'),
        move: this.onResize
      },
      modifiers: [
        interact.modifiers.restrictEdges({
          outer: 'parent'
        }),
        interact.modifiers.restrictSize({
          min: { width: 5, height: 0 }
        })
      ],
      inertia: true
    })
  }

  onResize(event) {
    const target = event.target
    const translatedX = event.deltaRect.left / this.parentWidth * 1920;
    const translatedY = event.deltaRect.top / this.parentHeight * 1080;
    const translatedWidth = event.rect.width / this.parentWidth * 1920;

    this.transformElement({ slideKey: this.slideKey, elementId: this.element.id, x: translatedX, y: translatedY, width: translatedWidth });
  }

  onDrag(ev) {
    const translatedX = ev.dx / this.parentWidth * 1920;
    const translatedY = ev.dy / this.parentHeight * 1080;
    this.transformElement({ slideKey: this.slideKey, elementId: this.element.id, x: translatedX, y: translatedY });
  }

  get relativeWidth(): number {
    return this.element.width / 1920 * this.parentWidth;
  }

  get relativeHeight(): number {
    return this.element.height / 1080 * this.parentHeight;
  }

  get relativeX(): number {
    return this.element.left / 1920 * this.parentWidth;
  }

  get relativeY(): number {
    return this.element.top / 1080 * this.parentHeight;
  }

  get subject(): string {
    return this.element.id.split('.')[0];
  }

  remove() {
    this.removeElement({ slideKey: this.slideKey, elementId: this.element.id });
  }

  setAttributes(attributes) {
    this.setElementAttributes({ slideKey: this.slideKey, elementId: this.element.id, attributes: attributes });
  }
}
