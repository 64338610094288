import { schema } from 'normalizr';
import { v4 as uuid } from 'uuid';

export class DragAndDropOption {
  public id?: EntityId = null;
  public points?: number = 0;
  public x?: number = 0;
  public y?: number = 0;
  public text?: string = null;

  constructor(attributes: DragAndDropOption = null) {
    this.id = uuid();

    if (attributes) {
      Object.assign(this, attributes);
    }
  }
}

export const dragAndDropOptionSchema = new schema.Entity('dragAndDropOptions');
