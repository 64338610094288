export class TranslatePart {
  private readonly _key: string;
  private readonly _needsTranslate: boolean;

  constructor(key: string, needsTranslate: boolean) {
    this._key = key;
    this._needsTranslate = needsTranslate;
  }

  get key(): string {
    return this._key;
  }

  get needsTranslate(): boolean {
    return this._needsTranslate;
  }
}
