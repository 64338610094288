import { BaseGrid } from '../../grid/base_grid';
import { DataSource } from './data_source';
import { columnConfiguration } from './configurations/column_configurations';
import { ColumnSelect } from '../../grid/base_column_select';
import { Filter } from './filter/filter';
import axios from 'axios';
import { flashMessage } from '../../mixins/flash_message';
import { t } from './configurations/localization';
import { confirmDialog } from '../../common/dialog';

const GRID_COOKIE_KEY = `mat_grid_options_${_app.info.siteId}_${_app.info.user.userId}`;

export class MediaAssemblingTemplateGrid extends BaseGrid {
  constructor () {
    super({ skipInit: true });

    if (this.gridElement) {
      this.dataSource = new DataSource ({ endpointUrl: this.endpointUrl, grid: this });

      this.initGrid();
      this.filter = new Filter(this);
      this.columnSelect = new ColumnSelect(this);

      this.bindListeners();
    }
  }

  setGridOptions (option) {
    // Do not save filter
    delete option['filter'];

    super.setGridOptions(option);
  }

  getColumnConfigurations () {
    return columnConfiguration;
  }

  gridColumns () {
    let visibleColumns = this.getGridOptions().columns;
    let columns =  { columns: this.getColumnConfigurations().map(column => {
      if (visibleColumns && column.hideable) {
        column.hidden = visibleColumns.indexOf(column.field) === -1;
      }

      return column;
      })
    };

    return columns;
  }

  gridOptions () {
    return {
      editable: {
        mode: 'inline',
        confirmation: true
      },
      scrollable: {
        endless: true
      },
      pageable: {
        numeric: false,
        previousNext: false
      },
      filterable: {
        extra: false
      },
      sortable: {
        mode: 'single',
        allowUnsort: false
      },
      persistSelection: true
    };
  }

  gridCookieKey () {
    return GRID_COOKIE_KEY;
  }

  gridSort () {
    let sort = this.getGridOptions().sort;

    return sort ? sort : {
      field: 'updated_at',
      dir: 'desc'
    };
  }

  onGridSort (e) {
    this.setGridOptions({ sort: e.sort });
  }

  onGridDataBound(e) {
    this.filter.updateFilters(this.dataSource.dataSource.filter());
  }

  onGridFilter(e) {
    this.filter.filterChange(e);
  }

  gridFilter () {
    let filter = {};
    return filter;
  }

  onGridFilterMenu (e) {
    switch (e.field) {
      case '':
        break;
    }
  }

  onGridChange (e) {
    this.selected = this.grid.selectedKeyNames();
    this.bulkActions.updateSelection(this.selected);
  }

  bindListeners() {
    $(this.gridElement).on('click', '.grid-action-delete', (e) => {
      e.preventDefault();

      let tableRow = e.currentTarget.parentNode.parentNode;
      this.grid.removeRow(tableRow);
    });

    $(this.gridElement).on('click', '.grid-action-set-default', (e) => {
      e.preventDefault();
      e.currentTarget.classList.add('disabled');
      const id = e.currentTarget.getAttribute('data-id');
      const subject = e.currentTarget.getAttribute('data-subject');
      const url = e.currentTarget.getAttribute('data-set-url');
      const skipConfirm = e.currentTarget.getAttribute('data-skip-confirm') === 'true';

      if (skipConfirm) {
        this.setDefault(id, subject, url);
      } else {
        confirmDialog({
          content: t('confirm_default_content'),
          title: t('confirm_default_title')
        }).then(() => {
          this.setDefault(id, subject, url);
        }).catch(() => e.currentTarget.classList.remove('disabled'));
      }
    });

    $(this.gridElement).on('click', '.grid-action-unset-default', (e) => {
      e.preventDefault();
      e.currentTarget.classList.add('disabled');
      const id = e.currentTarget.getAttribute('data-id');
      const subject = e.currentTarget.getAttribute('data-subject');
      const url = e.currentTarget.getAttribute('data-unset-url');
      this.unsetDefault(id, subject, url);
    });
  }

  setDefault(id, subject, url) {
    axios.post(url, { subject: subject }).then(response => {
      const dataAttribute = subject === 'film' ? 'is_film_default' : 'is_clip_default';

      const data = this.grid.dataSource.data().map(object => {
        if (object.id === parseInt(id, 10)) {
          return {
            ...object,
            [dataAttribute]: object.id === parseInt(id, 10),
            is_active: true,
            is_public: true
          };
        } else {
          return {...object, [dataAttribute]: object.id === parseInt(id, 10) }
        }
      });

      this.grid.dataSource.data(data);
      this.grid.refresh();
      flashMessage(t('set_default_success'));
    }).catch(e => {
      console.error(e);
      flashMessage(t('set_default_failed'));
      this.grid.refresh();
    });
  }

  unsetDefault(id, subject, url) {
    axios.post(url, { subject: subject }).then(response => {
      const dataAttribute = subject === 'film' ? 'is_film_default' : 'is_clip_default';
      let dataItem = this.grid.dataSource.get(parseInt(id, 10));
      dataItem[dataAttribute] = false;
      this.grid.refresh();
      flashMessage(t('unset_default_success'));
    }).catch(e => {
      console.error(e);
      flashMessage(t('unset_default_failed'));
      this.grid.refresh();
    });
  }
}
