import { BaseTree } from '../../taxonomy/base_tree';

export class BaseTopicAreaTree extends BaseTree {
  constructor (uuid, type, grid) {
    super(uuid, type);
    this.grid = grid;
  }

  setSelectedElementsHelper(elements) {
    this.ids = elements.map(v => v.id);

    let activeFilters = [];

    if (this.grid.grid.dataSource.filter()) {
      activeFilters = this.grid.grid.dataSource.filter().filters.filter(function (filter) {
        return filter.field !== "topic_areas";
      });
    }

    if(this.ids.length > 0) {
      activeFilters.push({
        field: "topic_areas",
        operator: "contains",
        value: this.ids.join(",")
      });
    }

    this.grid.grid.dataSource.filter(activeFilters);
  }
}
