/** Class representing a Widget */
export default class BaseWidget {
  /**
   * @constructor
   * @param {Object} options - Widget options
   */
  constructor(options) {
    this.options = options;
    this.grid = options.grid;
    this.element = null;
    this.busy = $('<div class="busy-container"><div class="busy"><div class="loader"><div class="circle"></div><div class="circle"></div><div class="circle"></div><div class="circle"></div></div></div></div>');
    this.buildGridstackWrapper();
  }

  static get defaultParams() {
    return {
      title: '',
      show_title: true
    };
  }

  /**
   * Get widget parameters
   * It will merge the default parameters with the current parameters
   * @param {String} key
   * @return {Object} Current value
   */
  get params() {
    return Object.assign({}, this.constructor.defaultParams, this.options.params);
  }

  /**
   * Set parameters, but we only allow whitelisted parameters from defaultParams.
   * @param {Object} params
   * @return {Object} Widget parameters after update
   */
  set params(params) {
    return this.options.params = _.pick(params, Object.keys(this.constructor.defaultParams));
  }

  /**
   * Get the remote ID of the widget
   * @return {Number} Widget ID
   */
  get id() {
    let id = parseInt(this.wrapper.getAttribute('data-id'), 10);
    return isNaN(id) ? null : id;
  }

  /**
   * Get the X position in the grid
   * @return {Number} X-Position
   */
  get x() {
    return parseInt(this.wrapper.getAttribute('data-gs-x'), 10);
  }

  /**
   * Get the Y position in the grid
   * @return {Number} Y-Position
   */
  get y() {
    return parseInt(this.wrapper.getAttribute('data-gs-y'), 10);
  }

  /**
   * Get the width in the grid
   * @return {Number} Width
   */
  get width() {
    return parseInt(this.wrapper.getAttribute('data-gs-width'), 10);
  }

  /**
   * Get the height in the grid
   * @return {Number} Height
   */
  get height() {
    return parseInt(this.wrapper.getAttribute('data-gs-height'), 10);
  }

  /**
   * Set a paremter
   * @param {String} key
   * @param {Object} value
   */
  setParam(key, value) {
    this.options.params[key] = value;
  }

  /**
   * Get a parameter
   * @param {String} key
   * @return {Object} Current value
   */
  getParam(key) {
    return this.options.params[key];
  }

  /**
   * Add a parent grid stack wrapper
   */
  buildGridstackWrapper() {
    this.wrapper = document.createElement('div');
    let content = document.createElement('div');

    content.classList.add('grid-stack-item-content');
    this.wrapper.appendChild(content);
  }

  /**
   * Initialize the widget
   */
  initialize() {
    this.element = this.wrapper.querySelector('.report-widget');
  }

  /**
   * Will be called when the widget got resized
   * @param {Event} e
   */
  resized(e) {
  }

  /**
   * Will be called when the widget is destroyed
   */
  destroy() {
  }

  /**
   * Enter editing mode for the widget
   */
  edit() {
    this.grid.disable();
    this.wrapper.querySelector('.ui-resizable-handle').style.display = 'none';
    this.wrapper.classList.add('editing');

    this.grid.widgetEditor.edit(this);
  }

  /**
   * Update the widget
   * @param {Object} params - Widget parameters
   */
  update(params) {
    this.params = params;
    this.render();
    this.grid.enable();
    this.wrapper.classList.remove('editing');
  }

  /**
   * Leave editing mode without saving
   */
  discardEdit() {
    this.grid.enable();
    this.wrapper.classList.remove('editing');
  }

  /**
   * Lock widget in place
   */
  lock() {
    this.grid.gridstack.locked(this.getStackElement(), true);
  }

  /**
   * Unlock widget
   */
  unlock() {
    this.grid.gridstack.locked(this.getStackElement(), false);
  }

  /**
   * Add actions for edit/delete
   */
  makeEditable() {
    this.actionBar = document.createElement('div');
    this.actionBar.className = 'report-widget-actions';

    // Build delete button
    let deleteBtn = document.createElement('a');
    deleteBtn.setAttribute('href', '#');
    deleteBtn.className = 'material-icons';
    deleteBtn.innerHTML = 'delete';

    deleteBtn.addEventListener('click', (e) => {
      e.preventDefault();

      this.grid.remove(this);
    });

    this.actionBar.appendChild(deleteBtn);
    this.element.appendChild(this.actionBar);
  }

  /**
   * Get the parameters which we need to send remotely
   * for rendering
   * @return {Object} - Parameters
   */
  renderParameters() {
    if (this.grid.editable) {
      return { p: this.params };
    }

    return {};
  }

  /**
   * Render the widget
   */
  render() {
    $.get(this.options.endpoint, this.renderParameters(), (response) => {
      let content = this.wrapper.querySelector('.grid-stack-item-content');
      content.innerHTML = response;
      this.initialize();

      if (this.options.editable) {
        this.makeEditable();
      }
    });
  }
}
