let localization = {
  'de': {
    'add': 'Erstellen:'
  },
  'en': {
    'add': 'Add:'
  },
  'es': {
    'add': 'Add:'
  }
};

export function init() {
  generalFormSelects();
}

function generalFormSelects() {
  //Tag select field
  let tagSelect = document.querySelector('.selectize-tags');

  const selectize = $(tagSelect).selectize({
    plugins: ['remove_button', 'clear_button'],
    valueField: 'id',
    labelField: 'name',
    searchField: 'name',
    maxOptions: 50,
    render: globalRenderoptions(),
    // check for identical option
    create: function(input, callback) {
      for (var key in this.options) {
        if (this.options[key].text === input) {
          callback(this.options[key]);
          return;
        }
      }
      $.ajax({
        url: tagSelect.getAttribute('data-add-url'),
        type: 'POST',
        data: { tag: { name: input } },
        success: function(result) {
          callback({ id: result.id, name: result.name});
        },
        error: function(result) {
          callback();
        }
      });
    },
    load: function(query, callback) {
      if (!query.length) { return callback(); }

      $.ajax({
        url: this.$input.data('url'),
        type: 'GET',
        dataType: 'json',
        data: { q: query },
        error: function() {
          callback();
        },
        success: function(res) {
          callback(res);
        }
      });
    }
  });

  if(selectize[0]) {
    // trigger change for underlying select tag
    selectize[0].selectize.on('change', () => {
      tagSelect.dispatchEvent(new Event('change'));
    });
  }
}

function globalRenderoptions() {
  let options = {
    'option_create': function(data, escape) {
      return '<div class="create">' + localization[_app.info.currentLocale]['add'] + ' <strong>' + escape(data.input) + '</strong>&hellip;</div>';
    }
  };

  return options;
}
