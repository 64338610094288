import Vue from 'vue';

export default {
  INIT_URL(state, url) {
    state.initUrl = url;
  },

  RESET_STATE(state) {
    state.initUrl = '';
    state.initialized = false;
    state.videoSrc = null;
    state.questions = [];
    state.currentQuestion = null;
    state.currentTime = 0;
    state.isSaving = false;
    state.isSaved = true;
  },

  UPDATE_QUESTIONS(state, questions) {
    Vue.set(state, 'questions', questions);
  },

  UPDATE_VIDEO_SRC(state, source) {
    state.videoSrc = source;
  },

  INITIALIZED(state) {
    state.initialized = true;
  },

  EDIT_QUESTION(state, question) {
    state.currentQuestion = question;
  },

  REMOVE_QUESTION(state, question) {
    if (question.isNew) {
      state.questions.splice(state.questions.indexOf(question), 1);
    } else {
      Vue.set(question, 'deleted', true);
    }
  },

  SAVE_QUESTION(state, questionAttributes) {
    const question = Object.assign(state.currentQuestion, questionAttributes);
    const index = state.questions.findIndex(q => q.id === question.id);

    if (index >= 0) {
      // Update existing question
      state.questions.splice(index, 1, question);
    } else {
      // Create new question
      state.questions.push(question);
    }

    state.currentQuestion = null;
  },

  SET_CURRENT_TIME(state, time) {
    state.currentTime = time;
  },

  SET_DURATION(state, duration) {
    state.duration = duration;
  },

  SAVING(state, isSaving) {
    state.isSaving = isSaving;
  },

  SAVED(state, isSaved) {
    state.isSaved = isSaved;
  }
};
