export function init() {
  initSelectize();
  bindListeners();
}

function initSelectize() {
  let selects = document.querySelectorAll('.choose-link-question');
  [].forEach.call(selects, function(select) {
    if (!$(select).hasClass("selectize-control") && !$(select).hasClass("selectize-dropdown") && !$(select).hasClass("selectized")) {
      $(select).selectize({sortField: "text"});
    }
  });
}

function bindListeners() {
  $(document).on('nested:fieldAdded', function(){
      initSelectize();
  });
}
