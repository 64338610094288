function isBlankString(string) {
  return !string || string.length === 0;
}

export const validLesson = {
  getMessage(field) {
    return VERSTEHE.vueI18n.t('course.lesson_invalid');
  },
  validate(lesson) {
    return new Promise(resolve => {
      let valid = true;

      // Check for existing subject
      if (lesson.type === 'Course::Lesson::ExternalLesson') {
        valid = !lesson.subject || !isBlankString(lesson.subject.url);
      } else if (lesson.type === 'Course::Lesson::CustomContentLesson') {
        valid = !lesson.subject || !isBlankString(lesson.subject.content);
      } else {
        if (!lesson.subject || !lesson.subject.id || !lesson.subject.type) {
          valid = false;
        }
      }

      // Check lesson title
      if (isBlankString(lesson.title) || lesson.title.length > 75) {
        valid = false;
      }

      // Check lesson description
      if (lesson.description && lesson.description.length > 300) {
        valid = false;
      }

      resolve({ valid });
    });
  }
};

export const accessibleContent = {
  getMessage() {
    return VERSTEHE.vueI18n.t('course.content_not_accessible');
  },
  validate(lesson) {
    return lesson.subject?.inaccessible !== true
  }
}
