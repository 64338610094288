import * as index from "./actions/index";
/** Class handling received Actions */
export class ActionManager {
  /**
   * @constructor
   */
  constructor() {};

  /**
   * Extracts the Actions from the received message and creates the correct Action type
   * @param {Object} message - message received from ContentChannel
   **/
  static received(message) {
    let newAction = index.ACTIONCLASSES[message.data.actionType].fromJson(JSON.stringify(message.data));
    this.receivedAction(newAction);
  }

  /**
   * Decides how to handle the received action. Executes it and enqueues it.
   * @param {Action} newAction - Action received
   **/
  // eslint-disable-next-line complexity
  static receivedAction(newAction) {
    // declare which actions are supposed to be handled/executed
    if ((newAction.recipient === _app.info.user.userId || newAction.recipient === undefined) && (newAction.user !== _app.info.user.userId || newAction instanceof index.StatusAction || newAction instanceof index.TabAction)) {
      if (newAction instanceof index.QueueAction) {
        // set queueState to receive so future QueueAction can be ignored
        if (VERSTEHE.CollaborativeAuthoring.Queues.find(q => q.queueName === newAction.queueName).state === "requested") {
          VERSTEHE.CollaborativeAuthoring.Queues.find(q => q.queueName === newAction.queueName).state = "received";
          newAction.execute();
        }
      } else {
        newAction.execute();
      }
    }
    // add newAction to the Queue
    if (!["#publish", "#step-generation", "#step-screenplay", "#step-production", "#step-postprocess"].includes(newAction.tabId)) {
      VERSTEHE.CollaborativeAuthoring.Queues.find(q => q.queueName === "mainQueue").push(newAction);
    } else {
      if (VERSTEHE.CollaborativeAuthoring.Queues.find(q => q.queueName === newAction.tabId)) {
        VERSTEHE.CollaborativeAuthoring.Queues.find(q => q.queueName === newAction.tabId).push(newAction);
      }
    }
  }
}
