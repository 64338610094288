<script>
import VideoQuestionsPlayer from '../../globals/vue/video_questions_player';

export default {
  extends: VideoQuestionsPlayer,
  computed: {
    playerOptions() {
      let plugins = {
        coursePlugin: {
          questions: this.questions
        }
      };

      if (this.theatreMode) {
        plugins.theatreMode = {
          resolution: 720
        }
      }

      return {
        playbackRates: [0.5, 1, 1.25, 1.5, 2],
        plugins: plugins
      }
    }
  }
}
</script>
