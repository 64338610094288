import { Module } from 'vuex';
import { state, QuizManageState } from './state';
import { actions } from './actions';
import { mutations } from './mutations';
import { getters } from './getters';
import { RootState } from '../../../store/types';

export const QuizManageModule: Module<QuizManageState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
