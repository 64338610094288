/** Class handling received Actions */
export class ElementFinder {
  constructor() {};

  /**
   * Function to determine wether an element is going to be ignored or not
   * returns true, if element belongs to the elements that should be ignored
   * e.g. elements that are created by collaborative authoring logic or belong to the nav dropdowns (not always visible)
   * @param {HTMLElement} element
   **/
  static ignoreElement(element) {
    // todo: Listen als Konstanten auslagern
    let ignoreClassList = ["cursor", "click", "slide-preview-zoomed", "selectize-dropdown", "modal-content", "modal", "make-collaborative", "block-ui-wrapper", "loading-overlay", "media-library-wrapper"];
    let ignoreIdList = ["communication-wrapper", "svg_1", "top-navbar", "create-dropdown-menu", "manage-dropdown-menu", "locale-dropdown-menu", "notifications-dropdown-menu", "user-dropdown-menu", "notification-entries", "edit_document_form"];
    for (let i = 0; i < ignoreClassList.length; i++) {
      if ($(element).parents().addBack().hasClass(ignoreClassList[i])) {
        // console.log("element_finder: ignored class: " + ignoreClassList[i]);
        return true;
      }
    };
    for (let i = 0; i < ignoreIdList.length; i++) {
      if ($(element).parents().addBack().is("#" + ignoreIdList[i])) {
        // console.log("element_finder: ignored id: " + ignoreIdList[i]);
        return true;
      }
    };
    return false;
  }

  static skipElement(element) {
    let skipClassList = ["hover", "hover-icon", "overlay-image", "preview-overlay"];
    let skipIdList = [];
    for (let i = 0; i < skipClassList.length; i++) {
      if ($(element).parents().addBack().hasClass(skipClassList[i])) {
        return true;
      }
    };
    for (let i = 0; i < skipIdList.length; i++) {
      if ($(element).parents().addBack().is("#" + skipIdList[i])) {
        return true;
      }
    };
    return false;
  }

  /**
   * Function to get the location of the passed element
   * location means either a jquery selector or an ancestry tree
   * @param {HTMLElement} element
   **/
  static elementToLocation(element) {
    if (element == null) {
      // console.log("Got null element");
    } else {
      // get jQuery element
      if (element instanceof $) {
        element = element[0];
      }
      // ignore elements
      if (ElementFinder.ignoreElement(element)) {
        return null;
      }
      // skip elements
      if (ElementFinder.skipElement(element)) {
        return ElementFinder.elementToLocation(element.parentNode);
      }
      // element has an id
      if (element.id && !$(element).hasClass("form-group") && !$(element).hasClass("document-upload") && !$(element).hasClass("modal") && !$(element).hasClass("ideal-image-slider")) {
        return "#" + element.id;
      }
      // element is bare body
      if (element.tagName === "BODY") {
        return "body";
      }
      // element is bare head
      if (element.tagName === "HEAD") {
        return "head";
      }
      // element is document
      if (element === document) {
        return "document";
      }
      // In case the element did not have an id or wasn't body, head or document a parent element is necessary to be able to describe the elements ancestry tree
      let parent = element.parentNode;
      if ((!parent) || parent === element) {
        // console.warn("elementLocation(", element, ") has null parent");
        // throw "No locatable parent found";
      }
      // find the parent (recursive)
      let parentLocation = ElementFinder.elementToLocation(parent);
      // return null if parent is null (thats the case when the parent was ignored)
      if (parentLocation == null) {
        return null;
      }
      let children = parent.childNodes;
      let _len = children.length;
      let index = 0;
      // figure out which child the element is
      for (let i = 0; i < _len; i++) {
        if (children[i] === element) {
          break;
        }
        if (children[i].nodeType === document.ELEMENT_NODE) {
          index++;
        }
      }
      // return the whole ancestry tree (recursive)
      return parentLocation + ":nth-child(" + (index + 1) + ")";
    }
  };

  /**
   * Function to find a jquery element based on the passed location
   * @param {String} location -
   **/
  static locationToElement(location, container) {
    if (location == null) {
      return null;
    }
    container = container || document;
    let el, rest;
    if (location === "body") {
      // element is bare body
      return document.body;
    } else if (location === "head") {
      // element is bare head
      return document.head;
    } else if (location === "document") {
      // element is bare document
      return document;
    } else if (location.indexOf("body") === 0) {
      // find the parent of the ancestry tree when parent is body
      el = document.body;
      return ElementFinder.locationToElement(location.substr(("body").length), el);
    } else if (location.indexOf("head") === 0) {
      // find the parent of the ancestry tree when parent is head
      el = document.head;
      return ElementFinder.locationToElement(location.substr(("head").length), el);
    } else if (location.indexOf("#") === 0) {
      // find the parent of the ancestry tree when parent is an element with id
      let id;
      location = location.substr(1);
      if (location.indexOf(":") === -1) {
        id = location;
        rest = "";
      } else {
        id = location.substr(0, location.indexOf(":"));
        rest = location.substr(location.indexOf(":"));
      }
      el = document.getElementById(id);
      if (!el) {
        // throw "#" + id, "No element by that id " + container;
      }
      if (rest) {
        return ElementFinder.locationToElement(rest, el);
      } else {
        return el;
      }
    } else if (location.indexOf(":nth-child(") === 0) {
      // find the parent of the ancestry tree when parent is a path
      location = location.substr((":nth-child(").length);
      if (location.indexOf(")") === -1) {
        // throw "Invalid location, missing ): " + location;
      }
      let num = location.substr(0, location.indexOf(")"));
      num = parseInt(num, 10);
      let count = num;
      location = location.substr(location.indexOf(")") + 1);
      let children = container.childNodes;
      el = null;
      for (let i = 0; i < children.length; i++) {
        let child = children[i];
        if (child.nodeType === document.ELEMENT_NODE) {
          count--;
          if (count === 0) {
            // this is the element
            el = child;
            break;
          }
        }
      }
      if (!el) {
        // console.log(":nth-child(" + num + ")", "container only has " + (num - count) + " elements " + container);
      }
      if (location) {
        return ElementFinder.locationToElement(location, el);
      } else {
        return el;
      }
    } else {
      // throw location + " Malformed location " + container;
    }
  };
};
