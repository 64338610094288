<template>
<div class="lesson-type-selector-wrapper">
  <div class="head">
    <h2>{{$t('course.select_lesson_type')}}</h2>
    <a v-on:click.prevent="cancel" href="#" class="material-icons">close</a>
  </div>

  <ul class="choice-select-list lesson-type-selector">
    <li v-for="(lessonType, key) in lessonTypes" :key="key">
      <a v-on:click.prevent="addLesson(lessonType.type)" href="#" class="lesson-type-option">
        <i class="coovi-icon choice-select-icon">{{lessonType.icon}}</i>
        <div class="choice-select-label">
          <h3>{{lessonType.title}}</h3>
          <p>{{lessonType.description}}</p>
        </div>
      </a>
    </li>
  </ul>
</div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'lesson-selector',
  data() {
    return {
      lessonTypes: {
        topicLesson: {
          icon: 'topic_lesson',
          title: this.$t('course.topic_lesson'),
          description: this.$t('course.topic_lesson_description'),
          type: 'Course::Lesson::TopicLesson'
        },
        playlistLesson: {
          icon: 'playlist_outline',
          title: this.$t('course.playlist_lesson'),
          description: this.$t('course.playlist_lesson_description'),
          type: 'Course::Lesson::PlaylistLesson'
        },
        externalLesson: {
          icon: 'external_lesson',
          title: this.$t('course.external_lesson'),
          description: this.$t('course.external_lesson_description'),
          type: 'Course::Lesson::ExternalLesson'
        },
        customContentLesson: {
          icon: 'custom_content_lesson',
          title: this.$t('course.custom_content_lesson'),
          description: this.$t('course.custom_content_lesson_description'),
          type: 'Course::Lesson::CustomContentLesson'
        },
        elearningPackageLesson: {
          icon: 'elearning_package_lesson',
          title: this.$t('course.elearning_package_lesson'),
          description: this.$t('course.elearning_package_lesson_description'),
          type: 'Course::Lesson::ElearningPackageLesson'
        }
      }
    }
  },
  methods: {
    ...mapActions({
      setUiState: 'course/setUiState',
      newLesson: 'course/newLesson'
    }),
    cancel() {
      this.setUiState('lessonList');
    },
    addLesson(lessonType) {
      this.newLesson({ lessonType })
    }
  }
}
</script>

<style lang="scss" scoped>
.lesson-type-selector-wrapper {
  max-width: 800px;
  margin: 0 auto;
}

.lesson-type-selector {
  justify-content: space-between;

  > li {
    width: 400px;
  }
}

.head {
  display: flex;
  max-width: 800px;
  margin: 0 auto;
  align-items: center;

  h2 {
    flex: 1 auto;
    text-align: center;
    font-size: 24px;
    margin: 24px 0 24px 32px;
  }

  a.material-icons {
    color: $black-54-opacity;
    font-size: 32px;

    &:hover,
    &:focus,
    &:active {
      color: $black-87-opacity;
    }
  }
}
</style>
