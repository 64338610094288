import General from './components/general.vue';
import Slides from './components/slides.vue';
import Slide from './components/slide.vue';

export const routes = [
  {
    path: '/general',
    name: 'general'
  },
  {
    path: '/intro',
    name: 'intro'
  },
  {
    path: '/intersection',
    name: 'intersection'
  },
  {
    path: '/outro',
    name: 'outro'
  },
  {
    path: '*',
    redirect: to => '/intro',
  }
];

export default routes;
