import axios from 'axios';
import { ActionTree } from 'vuex';
import { VerificationMethod } from '../types';
import { MutationTypes } from './mutations';
import { TwoFactorState } from './index';

export enum ActionTypes {
  INIT = 'twoFactor/init',
  SET_VERIFICATION_METHOD = 'twoFactor/setVerificationMethod',
  ACTIVATE_TWO_FACTOR = 'twoFactor/activateTwoFactor',
  SEND_OTP_BY_EMAIL = 'twoFactor/sendOtpByEmail',
  CANCEL = 'twoFactor/cancel'
}

export const actions: ActionTree<TwoFactorState, any> = {
  init({ commit }, { otpSecret, activateUrl, sendOtpUrl, email }) {
    commit(MutationTypes.SET_OTP_SECRET, otpSecret);
    commit(MutationTypes.SET_ACTIVATE_URL, activateUrl);
    commit(MutationTypes.SET_SEND_OTP_URL, sendOtpUrl);
    commit(MutationTypes.SET_EMAIL, email);
  },
  setVerificationMethod({ commit }, verificationMethod) {
    commit(MutationTypes.SET_VERIFICATION_METHOD, verificationMethod);
  },
  cancel({ commit }) {
    commit(MutationTypes.SET_VERIFICATION_METHOD, null);
  },
  async activateTwoFactor({ commit, state }, { otp, method}: { otp: string, method: VerificationMethod }) {
    try {
      const response = await axios.post(state.activateUrl, { otp, method });
      commit(MutationTypes.SET_BACKUP_CODES, response.data.backup_codes);
      commit(MutationTypes.SET_REDIRECT_URL, response.data.redirect);
    } catch(error) {
      console.error(error);
      return false;
    }

    return true;
  },
  async sendOtpByEmail({ state }) {
    try {
      await axios.post(state.sendOtpUrl);
    } catch(error) {
      console.error(error);
      return false;
    }

    return true;
  }
}
