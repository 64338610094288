import { TopicPublishment } from './topic_publishment';

export function init() {
  this.topicPublishment = new TopicPublishment();
  this.topicForm = document.querySelector('#topic-form');
  this.newRecord = this.topicForm.getAttribute('data-newrecord') === 'true';

  if(!this.newRecord) {
    this.generatedFilmUploader = document.querySelector('.medium-select .create-generated-film');
    this.slideshowUploader = document.querySelector('.medium-select .create-slideshow');
    this.initUploader(this.generatedFilmUploader);
    this.initUploader(this.slideshowUploader);
    this.initAssignFilm();
  }
}

export function initAssignFilm() {
  const filmButton = document.querySelector('#assign-film');

  if (filmButton) {
    const url = filmButton.getAttribute('data-url');

    filmButton.addEventListener('click', () => {
      this.topicForm.action = url;
      this.topicForm.method = "POST";
      this.topicForm.querySelector('input[name="_method"]').value = "POST";
      this.topicForm.submit();
    });
  }
}

export function initUploader(wrapper) {
  if(wrapper) {
    const uploader = wrapper.querySelector('.document-upload');
    const progressbar = uploader.querySelector('.progress-bar');
    const url = wrapper.getAttribute('data-url');

    $(uploader).on('completed', (e, data) => {
      progressbar.style.width = '100%';
      this.topicForm.action = url + '/' + data.id;
      this.topicForm.method = "POST";
      this.topicForm.querySelector('input[name="_method"]').value = "POST";
      this.topicForm.submit();
    });
  }
}

export function handleProductionResult(success) {
  if(success) {
    VERSTEHE.TopicManage.topicPublishment.saveTopicButton?.setDisabledCondition('needs_production', false);
    VERSTEHE.FilmQuestions.reload();
    VERSTEHE.ContributionWorkflow.workflow.enableWorkflowStep('medium-questions');
    VERSTEHE.ContributionWorkflow.workflow.enableWorkflowStep('medium-annotations');
    VERSTEHE.ContributionWorkflow.workflow.enableWorkflowStep('medium-subtitles');
    VERSTEHE.ContributionWorkflow.workflow.enableWorkflowStep('medium-transcript');
    VERSTEHE.ContributionWorkflow.workflow.setErrorStepState('medium-production', false);
  } else {
    VERSTEHE.TopicManage.topicPublishment.saveTopicButton?.setDisabledCondition('needs_production', true);
    VERSTEHE.ContributionWorkflow.workflow.setErrorStepState('medium-production', true);
  }
  VERSTEHE.ContributionWorkflow.workflow.enableWorkflowStep('medium-screenplay');
}

export function handleSlideshowProductionResult(success) {
  if (success) {
    Promise.all([
      VERSTEHE.Screenplay.screenplayApp.$store.dispatch('screenplay/fetchScreenplay'),
      VERSTEHE.Screenplay.screenplayApp.$store.dispatch('screenplay/fetchWorkspaceMedia')
    ]).then(() => {
      VERSTEHE.Screenplay.validateScreenplay();
      VERSTEHE.ContributionWorkflow.workflow.setErrorStepState('medium-generation', false);
      VERSTEHE.ContributionWorkflow.workflow.enableWorkflowStep('medium-screenplay');
      VERSTEHE.ContributionWorkflow.workflow.setActiveSubWorkflowStep('medium-screenplay');
    });
  } else {
    VERSTEHE.ContributionWorkflow.workflow.setErrorStepState('medium-generation', true);
  }
}

export function checkReviewNeeded() {
  if (this.topicPublishment && this.topicPublishment.changeDetector) {
    this.topicPublishment.changeDetector.remoteCheck();
  }
}
