import Vue from 'vue';

export default {
  SET_BASE_URL(state, url) {
    Vue.set(state, 'baseUrl', url);
  },

  SET_SETTINGS(state, integrations) {
    Vue.set(state, 'integrations', integrations);
    Vue.set(state, 'settingsLoaded', true);
  },

  UPDATE_SETTINGS(state, { integration, settings = {} }) {
    Object.keys(settings).forEach((setting) => {
      Vue.set(integration.settings, setting, settings[setting]);
    });
  },

  INTEGRATION_CONNECTED(state, integration) {
    Vue.set(integration, 'state', 'connected');
  },

  INTEGRATION_CHECKING(state, integration) {
    Vue.set(integration, 'state', 'checking');
  },

  INTEGRATION_FAILED(state, { integration, errors = [] }) {
    Vue.set(integration, 'state', 'failed');
    Vue.set(integration, 'error', errors[0]);
  },

  INTEGRATION_DISCONNECTED(state, integration) {
    Vue.set(integration, 'state', 'disconnected');
  }
};
