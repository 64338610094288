import { ChangeAction } from "../../system_actions/change_action";

let lessonsActionWhitelist = [
  'course/createLessonGroup',
  'course/removeLessonGroup',
  'course/updateLessonGroupPosition',
  'course/changeLessonGroupTitle',
  'course/removeLesson',
  'course/updateLessonPosition',
  'course/processGroupSwitchForLesson',
  'course/addDeletedLesson',
  'course/changeLessonTitle',
  'course/saveEditorLessonCollaborative',
  'course/lockLesson',
  'course/unlockLesson',
  'course/lockLessonGroup',
  'course/unlockLessonGroup'
];

/**
 * LessonActions handles all changes that occur in the lessons tab
 */
export class LessonAction extends ChangeAction {
  /**
   * @constructor
   * @param {string} tabId  - tabId of the tab the action originates from
   * @param {number} user - userId of the chat room owner
   * @param {String} element - element thats subject of the ChangeAction
   * @param {number} data - data (state of the vue sore or other payload)
   * @param {String} type - type of the LessonAction
   */
  constructor(tabId, user, element, data, type) {
    super(tabId, user, element);
    this.data = data;
    this.type = type;
    this.actionType = "LessonAction";
  }

  execute() {
    if (lessonsActionWhitelist.includes(this.type)) {
      if (this.type === 'course/saveEditorLessonCollaborative') {
        VERSTEHE.Course.manage.app.$store.dispatch(this.type + "Silent", {...this.data} );
      } else {
        VERSTEHE.Course.manage.app.$store.dispatch(this.type, {...this.data, silent: true } );
      }
    }
  }

  static fromJson(json) {
    let action = JSON.parse(json);
    return new LessonAction(action.tabId, action.user, action.elementLocation, action.data, action.type);
  }

  static registerToElement() {
    if(VERSTEHE.Course.manage) {
      VERSTEHE.Course.manage.app.$store.subscribeAction((action) => {
        if (lessonsActionWhitelist.includes(action.type) && !action.payload.silent) {
          LessonAction.createAction(LessonAction.getTabId(), _app.info.user.userId, null, action.payload, action.type);
        }
      });
    }
  }

  static createAction(tabId, user, element, data, type) {
    let action = new LessonAction(tabId, user, element, data, type);
    VERSTEHE.content_channel.send_message(action);
  }
}
