export const datetimeFormats = {
  'en': {
    short: {
      year: 'numeric', month: '2-digit', day: '2-digit'
    },
    dateTime: {
      year: 'numeric', month: '2-digit', day: '2-digit',
      hour: 'numeric', minute: 'numeric'
    },
    long: {
      year: 'numeric', month: '2-digit', day: '2-digit',
      weekday: 'short', hour: 'numeric', minute: 'numeric'
    }
  },
  'de': {
    short: {
      year: 'numeric', month: '2-digit', day: '2-digit'
    },
    dateTime: {
      year: 'numeric', month: '2-digit', day: '2-digit',
      hour: 'numeric', minute: 'numeric'
    },
    long: {
      year: 'numeric', month: '2-digit', day: '2-digit',
      weekday: 'short', hour: 'numeric', minute: 'numeric'
    }
  },
  'es': {
    short: {
      year: 'numeric', month: '2-digit', day: '2-digit'
    },
    dateTime: {
      year: 'numeric', month: '2-digit', day: '2-digit',
      hour: 'numeric', minute: 'numeric'
    },
    long: {
      year: 'numeric', month: '2-digit', day: '2-digit',
      weekday: 'short', hour: 'numeric', minute: 'numeric'
    }
  }
}
