export class Panelbar {
  constructor() {
    this.initPanelbar();
  }

  initPanelbar() {
    $('#sidebar-panelbar').kendoPanelBar({
      expandMode: "single",
      dataBound: () => {
        console.log('data bound');
      }
    });

    this.panelBar = $('#sidebar-panelbar').data('kendoPanelBar');
    document.querySelector('.sidebar-panelbar').style.display = "";
  }

  expand(id) {
    this.panelBar.expand($(id));
  }
}
