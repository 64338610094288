import { render, staticRenderFns } from "./backup_code_list.vue?vue&type=template&id=845d7564&scoped=true&"
import script from "./backup_code_list.vue?vue&type=script&lang=ts&"
export * from "./backup_code_list.vue?vue&type=script&lang=ts&"
import style0 from "./backup_code_list.vue?vue&type=style&index=0&id=845d7564&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "845d7564",
  null
  
)

export default component.exports