import { t } from '../configurations/localization';

export function onlyMyOwnFilter (filter) {
  let column = filter.getFilterColumn('user');

  return filter.buildFieldItem({ title: t('filter_only_my_own') }, null, () => {
    let filterConstraint = {
      field: 'user',
      operator: 'eq',
      value: _app.info.user.name,
      readOnly: true
    };

    column.filterMenu.filter({ filters: [filterConstraint] });
    filter.fieldSelect.close();
  });
}

export function onlyDefaultsFilter (filter) {
  let column = filter.getFilterColumn('is_default');

  return filter.buildFieldItem({ title: t('filter_only_defaults') }, null, () => {
    let filterConstraint = {
      field: 'is_default',
      operator: 'eq',
      value: true,
      readOnly: true
    };

    column.filterMenu.filter({ filters: [filterConstraint] });
    filter.fieldSelect.close();
  });
}
