<template>
  <kendo-window
    :width="'800px'"
    :height="'524px'"
    :title="'Preview'"
    :modal="true"
    :pinned="true"
    :visible="false"
    @close="$emit('close')"
    ref="windowRef">
    <video-questions-player
      :sources="sources"
      :questions="questions"
      @loadedmetadata="$emit('ready', $event)">
    </video-questions-player>
  </kendo-window>
</template>

<script>
import VideoQuestionsPlayer from '../../../perform/components/lesson/video_questions_player';
import { Window } from '@progress/kendo-window-vue-wrapper';

export default {
  name: 'question-preview',
  data() {
    return {
      visible: false
    }
  },
  props: {
    sources: {
      type: Array,
      required: true
    },
    questions: {
      type: Array
    }
  },
  components: {
    Window,
    VideoQuestionsPlayer
  },
  mounted() {
    const window = this.$refs.windowRef.kendoWidget();
    window.center().open();
  }
}
</script>
