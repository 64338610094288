export class Busy {

  constructor () {
    this.loader = this.container();
  }

  remove () {
    if (this.loader.parentNode) {
      this.loader.parentNode.removeChild(this.loader);
      this.loader = null;
    }
  }

  container () {
    let div = document.createElement('div');
    div.classList.add('busy-container');
    div.appendChild(this.busy());

    return div;
  }

  busy () {
    let div = document.createElement('div');
    div.classList.add('busy');
    div.appendChild(this.loader());

    return div;
  }

  loader () {
    let loader = document.createElement('div');
    loader.classList.add('loader');
    for (let i = 0; i < 4; i++) {
      loader.appendChild(this.circle());
    }

    return loader;
  }

  circle () {
    let circle = document.createElement('div');
    circle.classList.add('circle');

    return circle;
  }
}
