export default {
  setTutorialVideos({ commit }, { tutorialVideos, hiddenCategories = [] }) {
    let locale = _app.info.currentLocale;

    if (!tutorialVideos[locale]) {
      // Fallback to english tutorial videos
      locale = 'en';
    }

    commit('setTutorialVideos', { tutorialVideos: tutorialVideos[locale], hiddenCategories });
  },

  setCurrentVideo({ commit }, videoId) {
    commit('setCurrentVideo', videoId);
  },

  setSearchQuery({ commit }, query) {
    commit('setSearchQuery', query);
  },

  filterCategory({ commit }, categoryId) {
    commit('filterCategory', categoryId);
  },

  filterRole({ commit }, roles = []) {
    commit('filterRole', roles);
  }
};
