<template>
  <div ref="group" class="form-group label-floating" :class="{ 'has-error': errors.has(name) }">
    <label v-if="label" class="control-label">{{ label }}</label>

    <div class="upload-wrap">
      <button :disabled="isUploading" @click="selectFile" type="button" class="btn btn-primary">
        {{ $t('course.select_package') }}
      </button>
      <div class="upload-progress" v-if="isUploading">
        <span class="upload-file"><span>{{ formattedFilesize }}</span>{{ filename }}</span>
        <div class="progress">
          <div class="progress-bar" :style="{ width: `${progress}%`}"></div>
        </div>
      </div>

      <div class="btn btn-default btn-fab btn-fab-mini" v-if="isUploading" @click="cancelUpload">
        <i class="material-icons">clear</i>
      </div>

      <div class="upload-completed text-success" v-if="isUploadCompleted">
        <i class="material-icons">check</i>
        <span>{{ $t('course.upload_completed') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Upload } from 'tus-js-client';
import UploadUtils from '../../../../common/upload_utils';

export default {
  name: 'file-upload',
  inject: ['$validator'], // inject parent validator
  props: {
    name: {
      type: String,
      required: true
    },
    label: String,
    value: String,
    validate: Object
  },
  data() {
    return {
      filename: '',
      filesize: 0,
      isUploading: false,
      isUploadCompleted: false,
      progress: 0,
      upload: null,
      acceptedTypes: [
        'application/zip',
        'application/x-zip',
        'application/x-zip-compressed'
      ]
    }
  },
  mounted() {
    if (this.validate && this.validate.max > 0) {
      this.maxLength = this.validate.max;
    }

    if (!this.value || this.value.length === 0) {
      this.$refs.group.classList.add('is-empty');
    }
  },
  computed: {
    ...mapGetters({
      apiUrl: 'course/apiUrl'
    }),
    formattedFilesize() {
      if (this.filesize < 102400) {
        // < 100KB -> Display in KB
        return `${Math.round((this.filesize / 1024) * 10) / 10} KB`;
      } else {
        // Display in MB
        return `${Math.round((this.filesize / (1024 * 1024)) * 10) / 10} MB`;
      }
    }
  },
  methods: {
    selectFile() {
      UploadUtils.selectFile(this.acceptedTypes).then(file => {
        this.filename = file.name;
        this.filesize = file.size;
        this.uploadFile(file);
      });
    },

    uploadFile(file) {
      this.upload = new Upload(file, {
        endpoint: this.apiUrl('upload'),
        retryDelays: null,
        resume: true,
        metadata: {
          uploadtype: 'file',
          filename: file.name,
          filetype: file.type,
          identifier: `${file.name}${UploadUtils.hashCode(UploadUtils.generateFingerprint(file))}`
        },
        fingerprint: (file, options) => UploadUtils.generateFingerprint(file),
        onError: (error) => this.onError(error),
        onProgress: (bytesUploaded, bytesTotal) => this.onProgress(bytesUploaded, bytesTotal),
        onSuccess: () => this.onSuccess()
      });

      this.isUploadCompleted = false;
      this.isUploading = true;
      this.progress = 0;
      this.upload.start();
    },

    onProgress(bytesUploaded, bytesTotal) {
      this.progress = Math.round((bytesUploaded / bytesTotal) * 100);
    },

    onError(error) {
      console.error(error);
      this.isUploading = false;
      this.progress = 0;
    },

    onSuccess(upload) {
      this.isUploading = false;
      this.isUploadCompleted = true;
      this.progress = 0;
      const uploadId = this.upload._xhr.getResponseHeader('X-Upload-Id');
      this.$emit('uploaded', uploadId);
    },

    cancelUpload() {
      this.upload.abort();
    }

  }
}
</script>

<style lang="scss" scoped>
.upload-wrap {
  display: flex;
  align-items: center;

  .btn-fab {
    width: 32px;
    height: 32px;
    min-width: 32px;

    > i {
      font-size: 20px;
    }
  }
}

.upload-progress {
  flex: 1 0 auto;
  margin: 0 24px;

  .upload-file > span {
    color: $black-54-opacity;
    margin-right: 10px;
  }

  .progress {
    margin: 5px 0 0 0;
  }
}

.upload-completed {
  margin: 0 24px;

  > i {
    font-size: 24px;
  }
}
</style>
