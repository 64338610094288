import Vue from 'vue';
import VideoQuestionsPlayer from '../../film_questions/components/video_questions_player';

export class Film {
  constructor(player_id) {
    const element = document.getElementById(player_id);
    this.player_id = player_id;
    
    this.app = new Vue({
      el: element,
      i18n: VERSTEHE.vueI18n,
      module: this,
      components: {
        'video-questions-player': VideoQuestionsPlayer
      }
    });

    // Reference to vjs instance
    this.player = this.app.$children[0].$refs.videoPlayer.player;
  }
}
