import Vue from 'vue';
import App from './app.vue';
import axios from 'axios';

export class NewSubtitle {
  public app: Vue = null;
  public windowElement: HTMLDivElement = null;
  public kendoWindow = null;
  public vueWrapper: HTMLDivElement = null;
  public newSubtitleButton: HTMLAnchorElement = null;
  private initUrl: string = null;

  public init() {
    this.windowElement = <HTMLDivElement> document.getElementById('new-subtitle-window');

    if (this.kendoWindow) {
      this.kendoWindow.destroy();
    }

    if (this.windowElement) {
      this.newSubtitleButton = <HTMLAnchorElement> document.getElementById('new-subtitle-language-modal');
      this.initUrl = this.windowElement.dataset.url;
      this.initKendoWindow();

      this.newSubtitleButton.addEventListener('click', (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.open();
      });
    }
  }

  private initKendoWindow() {
    this.kendoWindow = $(this.windowElement).kendoWindow({
      width: '600px',
      modal: true,
      visible: false,
      draggable: false,
      resizable: false,
      title: VERSTEHE.vueI18n.t('film_subtitles.new.modal_title')
    }).data('kendoWindow');
  }

  public open() {
    axios.get(this.initUrl)
    .then((response) => {
      this.initVueApp(response.data);
      this.kendoWindow.center().open();
    })
    .catch(e => console.error(e));
  }

  private initVueApp(data = null) {
    this.vueWrapper = <HTMLDivElement> document.getElementById('v-new-subtitle');

    this.app = new Vue({
      el: this.vueWrapper,
      i18n: VERSTEHE.vueI18n,
      provide: {
        data: data
      },
      render: h => h(App)
    });

    this.app.$root.$once('subtitle-created', (id) => this.onCreated(id));
    this.app.$root.$once('close', () => this.kendoWindow.close());
  }

  onCreated(id) {
    this.kendoWindow.close();
    VERSTEHE.subtitles.loadSubtitlesAndOpen(id);
  }
}

export default new NewSubtitle();
