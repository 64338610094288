export const locals = {
  de: {
    modal_title_auto_reassign: "Einstellungen zur automatischen Zuweisung"
  },
  en: {
    modal_title_auto_reassign: "Setting to automatically reassign contributions"
  },
  es: {
    modal_title_auto_reassign: "Configuración de la reasignación automática de contribuciones"
  }
};

export function t(key) {
  return locals[_app.info.currentLocale][key] || key;
}
