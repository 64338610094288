export function simpleContainsFilterUi (element) {
  element[0].classList.add('k-textbox');

  // Remove operator label & select 
  var operatorSelect = Array.prototype.slice.call(element[0].parentNode.childNodes, 0, 2);
  
  for (var i = 0; i < operatorSelect.length; i++) {
    operatorSelect[i].parentNode.removeChild(operatorSelect[i]);
  }
}

export function datepickerFilterUi (element) {
  element.closest('form').find('.k-filter-and option[value="or"]').remove();
  element.closest('form').find('select').kendoDropDownList();
  element.kendoDatePicker({
    parseFormats: ['yyyy/MM/dd']
  });
}

export function booleanColumnTemplate (fieldName) {
  return `<div class="grid-cell-wrapper">` +
      `<i class="material-icons"># if (${fieldName}) {#check#} else {#close#} #</i>` +
    `</div>`;
}

export function dateColumnTemplate (fieldName) {
  // For format informations check the documentation
  // https://docs.telerik.com/kendo-ui/framework/globalization/definecultureinfo
  return `# if (${fieldName}) { #` +
    `<span title="#: kendo.format('{0:F}', ${fieldName}) #">#: kendo.format('{0:d}', ${fieldName}) #</span>` +
  `# } else { #` +
    `<span>-</span>` +
  `# } #`;
}
