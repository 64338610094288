<template>
  <div class="ai-aws-integration">
    <div class="ai-aws-settings">
      <div class="checkbox">
        <label>
          <input
            type="checkbox"
            v-model="ai_aws_enable_search_index">
            {{ $t('integrations.ai_aws_search_index') }}
        </label>
      </div>

      <div class="checkbox">
        <label>
          <input
            type="checkbox"
            :disabled="!ai_aws_enable_search_index"
            v-model="ai_aws_generate_subtitles">
            {{ $t('integrations.ai_aws_generate_subtitles') }}
        </label>
      </div>

      <div class="checkbox">
        <label>
          <input
            type="checkbox"
            :disabled="!ai_aws_enable_search_index"
            v-model="ai_aws_subtitle_translation">
            {{ $t('integrations.ai_subtitle_translation') }}
        </label>
      </div>

      <slot/>
    </div>
  </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex';
  const { mapGetters, mapActions } = createNamespacedHelpers('integrations');

  export default {
    data() {
      return {
        ai_aws_enable_search_index: false,
        ai_aws_generate_subtitles: false,
        ai_aws_subtitle_translation: false
      }
    },
    computed: {
      ...mapGetters([
        'integrationById'
      ]),
      integration() {
        return this.integrationById('ai_aws');
      },
      settings() {
        return {
          ai_aws_enable_search_index: this.ai_aws_enable_search_index,
          ai_aws_generate_subtitles: this.ai_aws_generate_subtitles,
          ai_aws_subtitle_translation: this.ai_aws_subtitle_translation
        }
      },
    },
    methods: {
      load() {
        const settings = this.integration.settings;
        this.ai_aws_enable_search_index = settings.ai_aws_enable_search_index;
        this.ai_aws_generate_subtitles = settings.ai_aws_generate_subtitles;
        this.ai_aws_subtitle_translation = settings.ai_aws_subtitle_translation;
      }
    },
    mounted() {
      this.load();
      $.material.init();
    },
    watch: {
      ai_aws_enable_search_index(value) {
        if (value === false) {
          this.ai_aws_generate_subtitles = false;
          this.ai_aws_subtitle_translation = false;
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .ai-aws-integration {
    display: flex;
  }

  .ai-aws-settings {
    flex: 1 0;
    align-self: flex-start;
    min-width: 400px;
    max-width: 800px;
    background-color: $color-white;
    border-radius: 3px;
    padding: 30px;
  }
</style>
