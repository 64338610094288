import Vue from 'vue';
import App from './components/app.vue';
import VueRouter from 'vue-router';
import store from '../store';
import integrationsStore from './store';
import IntegrationSettings from './components/integration_settings.vue';
import IntegrationsList from './components/integrations_list.vue';

export let app = null;

const routes = [
  {
    path: '/settings/:id',
    name: 'settings',
    component: IntegrationSettings,
    props: true
  },
  {
    path: '*',
    component: IntegrationsList
  }
];

export function init() {
  const element = document.getElementById('app-integrations');
  store.registerModule('integrations', integrationsStore);

  store.dispatch('integrations/setBaseUrl', element.dataset.baseUrl);

  const router = new VueRouter({
    routes
  });

  app = new Vue({
    store,
    router,
    el: element,
    i18n: VERSTEHE.vueI18n,
    render: h => h(App),
    description() {
      unsyncRouter();
    }
  });
}
