export function init () {
  this.component = new NewFeatures;
}
export class NewFeatures {
  constructor () {
    this.featureBtn = document.querySelector('.feature-list');
    this.bindListeners();
  };

  getNewFeatures () {
    $.ajax({
      url: this.featureBtn.dataset.href,
      success: (response) => {
        VERSTEHE.utils.showModal('#new_features_modal', response);
      }
    })
  }

  bindListeners(){
    this.featureBtn?.addEventListener('click', e => {
      e.preventDefault();
      this.getNewFeatures();
    });
  }  
}
