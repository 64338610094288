import { ChangeAction } from "../../system_actions/change_action";

/**
 * CheckboxActions handle all changes to the state of checkboxes
 * CheckboxActions regarding the same Checkbox even out when the state is different
 */
export class CheckboxAction extends ChangeAction {
  /**
   * @constructor
   * @param {String} tabId  - TabId of the tab the action originates from
   * @param {number} user - UserId of the chat room owner
   * @param {String} element - Element thats subject of the UIAction
   * @param {boolean} checked - Whether or not the checkbox is checked
   */
  constructor(tabId, user, element, checked) {
    super(tabId, user, element);
    this.checked = checked;
    this.actionType = "CheckboxAction";
  }

  execute() {
    this.element.prop('checked', this.checked);
    // listener must be unbound to prevent loop
    $(document).off('change', '[data-collaborative-action*="CheckboxAction"]');
    // must be triggered for some logic of forms to work
    this.element.change();
    // rebinding the listener
    CheckboxAction.registerToElement();
  }

  isComparableTo(other) {
    if (this.actionType === other.actionType) {
      return this.element.is(other.element);
    } else {
      return false;
    }
  }

  reduce(other) {
    if (this.checked === other.checked) {
      return this;
    } else if (this.checked !== other.checked) {
      return null;
    }
  }

  static fromJson(json) {
    let action = JSON.parse(json);
    return new CheckboxAction(action.tabId, action.user, action.elementLocation, action.checked);
  }

  static registerToElement() {
    $(document).on('change', '[data-collaborative-action*="CheckboxAction"]', function(event) {
      CheckboxAction.createAction(CheckboxAction.getTabId(), _app.info.user.userId, event.target, event.target.checked);
    });
  }

  static createAction(tabId, user, element, checked) {
    let action = new CheckboxAction(tabId, user, element, checked);
    VERSTEHE.content_channel.send_message(action);
  }
}
