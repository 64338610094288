import { entries } from '../common/helpers';

export class TrainerGridConfiguration {
  constructor () {
    this.registerEventListeners();
    this.selectizeElements = [];
    this.selectedData = [];
    this.query.data('value', this.query.data('value') || {});
  }

  initializeData () {
    if (!_.isEmpty(JSON.parse(this.inner_value.val())[0]['filter']) && this.query.find('.badge').length === 0) {
      JSON.parse(this.inner_value.val())[0]['filter']['sets'].forEach(set => {
        let type = this.getKey(set['argument']);
        let property = this.getKey(this.getValue(set['argument']));
        let value = this.getValue(this.getValue(set['argument']));
        let currentPropertyElement = this.getValue(this.currentPropertyElement(type, property, value));

        let valueObject = currentPropertyElement.value.map(e => this.getValue(e)).filter(e => {
          return e[property === 'element_id' ? 'id' : 'title'] === value;
        })[0];
        let valueId = valueObject['id'];
        let badgeId = `${property}_${valueId}`;

        this.appendBadge(badgeId, currentPropertyElement.title, valueObject.title);
        let query = this.query.data('value');
        query[badgeId] = [type, property, property === 'element_id' ? valueId : valueObject.title];
        this.query.data('value', query);
      });
    }
  }
  currentPropertyElement (type, property, value) {
    return this.options[type].value.filter(e => {
      return e[property] && e[property]['value'].filter(v => {
        return this.getValue(v)[property === 'element_id' ? 'id' : 'title'] == value;
      }).length === 1;
    })[0];
  }

  appendBadge (badgeId, property, value) {
    this.query.append(`
          <span class="badge badge-secondary" id=${badgeId}>${property}: ${value} <a href="javascript:void(0)" class="remove" tabIndex="-1" title="Remove">×</a> </span>
        `);
    this.content.find(`#${badgeId}`).on('click', () => {
      this.removeBadge(this.content.find(`#${badgeId}`));
    });
  }

  changeIsEnabled (state) {
    this.isEnabled = state;
    state ? this.settingsContent.show('normal', function () {
      $(this).css('display', 'block');
    }) : this.settingsContent.hide();
  }

  registerEventListeners () {
    this.registerModalShown();
    this.registerModalHide();
    $('.select-contributions_anonymize_trainer_grid').on('click', () => this.content.modal('show'));
    this.addButton.on('click', () => this.addFilter());
    this.registerSelectizeFieldChange();
  }

  // Display add button if all selectize fields are selected
  registerSelectizeFieldChange () {
    this.content.find('input.selectize-field').on('change', () => {
      if (this.content.find('input.selectize-field').filter((_index, element) => $(element).val() !== '').length === 3) {
        this.addButton.show();
      } else {
        this.addButton.hide();
      }
    });
  }

  registerModalShown () {
    let currentContext = this;
    this.content.on('shown.bs.modal', () => {
      $.material.init();

      this.changeIsEnabled(this.isEnabled);
      this.initializeData();

      this.content.find('#is_enabled').on('change', function () {
        let state = !$(this).data('enabled');

        $(this).data('enabled', state);
        currentContext.changeIsEnabled(state);
        currentContext.inner_value = currentContext.submitData;
        currentContext.submitButton.removeClass('disabled');
      });

      this.values = {
        'content-type': entries(this.options).map((v) => {
          return { id: v[0], title: v[1].title || v[1] };
        })
      };

      this.initSelectize(this.content.find('#content-type'), null, 'content-property', ['content-property', 'content-value'], () => {
        this.initSelectize(this.content.find('#content-property'), 'content-type', 'content-value', ['content-value'], () => {
          this.initSelectize(this.content.find('#content-value'), 'content-property', 'last', []);
        });
      });
    });
  }

  registerModalHide () {
    this.content.on('hide.bs.modal', () => {
      this.content.find('#is_enabled').off('change');
    });
  }

  initSelectize (element, sourceElement, nextElement, resetFields, callback = () => { }) {
    this.selectizeElements[element[0].id] = element.selectize({
      persist: false,
      createOnBlur: false,
      create: false,
      maxItems: 1,
      valueField: 'id',
      labelField: 'title',
      searchField: 'title',
      options: this.values[element[0].id],
      onChange: (data) => {
        if (data === '') { return; }
        this.selectedData[element[0].id] = this.options[data] ? this.options[data]['value'] : this.selectedData[sourceElement].map(e => this.getValue(e));
        if (this.selectedData[element[0].id] && nextElement !== 'content-value') {
          this.values[nextElement] = entries(this.selectedData[element[0].id]).map(e => entries(e[1])[0]).map((v) => {
            return v !== undefined ? { id: v[1].id || v[1].title, title: v[1].title } : null;
          }).filter(e => e !== null);
        } else {
          this.values[nextElement] = this.selectedData[element[0].id].filter(e => e['title'] === data)[0]['value'].map(e => this.getValue(e));
        }
        this.content.find(`#${resetFields[0]}`).parent('.sub-field').show();
        this.resetSelects(resetFields);
        callback();
      }
    });
  }

  resetSelects (selectElements) {
    selectElements.forEach((element) => {
      $(`#${element}`).val('');
      if (this.selectizeElements[element]) {
        let selectElement = this.selectizeElements[element][0];
        selectElement.selectize.clear();
        selectElement.selectize.clearOptions();
      }
      if (this.selectedData[element]) {
        delete this.selectedData[element];
      }
    });

    if (this.selectizeElements[selectElements[0]]) {
      let element = this.selectizeElements[selectElements[0]][0];
      element.selectize.clearCache('option');
      element.selectize.clearOptions();
      element.selectize.addOption(this.values[selectElements[0]]);
    }
  }

  addFilter () {
    let propertyId = entries(this.selectedData['content-type'].filter(e => {
      return this.getValue(e)['title'] === this.selection[1];
    })[0])[0][0];
    let badgeId = `${propertyId}_${this.selection[2]}`;

    if (this.query.find(`#${badgeId}`).length === 0) {
      let propertyElement = this.options[this.selection[0]].value.filter(e => this.getValue(e)['title'] === this.selection[1]).map(e => this.getValue(e))[0];

      let value = propertyElement['value'].filter(e => this.getValue(e)['id'] == this.selection[2]).map(e => this.getValue(e))[0];

      this.appendBadge(badgeId, propertyElement['title'], value['title']);

      let query = this.query.data('value');

      query[badgeId] = [
        this.selection[0],
        propertyId,
        propertyId === 'element_id' ? value.id : value.title
      ];
      this.query.data('value', query);
    }
    this.resetSelects(entries(this.selectizeElements).map(k => k[0]));
    this.content.find('.sub-field').hide();
    this.inner_value = this.submitData;
    this.submitButton.removeClass('disabled');
  }

  removeBadge (badge) {
    let query = this.query.data('value');
    delete query[badge[0].id];
    this.query.data('value', query);
    this.inner_value = this.submitData;
    badge.remove();
    this.submitButton.removeClass('disabled');
  }

  /**
   *  Getter
   */
  get selection () {
    return [
      this.content.find('#content-type').val(),
      this.content.find('#content-property').val(),
      this.content.find('#content-value').val()
    ];
  }

  get submitButton () {
    return this.content.find('#submit-transit-content');
  }

  get settingsContent () {
    return this.content.find('.settings');
  }

  get isEnabled () {
    return this.content.find('#is_enabled').data('enabled') === true;
  }

  get content () {
    return $('#contributions_anonymize_trainer_grid-modal');
  }

  get options () {
    return this.content.data('options');
  }

  get addButton () {
    return this.content.find('.add-filter');
  }

  get query () {
    return this.content.find('.query');
  }

  get inner_value () {
    return this.content.find('#parameter-value');
  }

  get submitData () {
    return JSON.stringify([
      {
        enabled: this.isEnabled,
        filter: {
          sets: entries(this.query.data('value')).map(d => d[1])
            .map(e => {
              let data = { argument: {} };
              data['argument'][e[0]] = {};
              data['argument'][e[0]][e[1]] = e[2];
              return data;
            })
        }
      }
    ]);
  }

  /**
   *  Setter
   */
  set inner_value (data) {
    this.inner_value.val(data);
  }

  set isEnabled (state) {
    if (typeof (state) === 'boolean') {
      this.content.find('#is_enabled').data('enabled', state);
      this.content.find('#is_enabled').prop('checked', state);
    }
  }

  /**
   * Helper functions
   */
  // Return the key from a single JSON Object
  getKey (element) {
    const entriesOfElement = entries(element);
    return (entriesOfElement && entriesOfElement.length > 0) ? entriesOfElement[0][0] : "";
  }

  // Return the value from a single JSON Object
  getValue (element) {
    const entriesOfElement = entries(element);
    return (entriesOfElement && entriesOfElement.length > 0) ? entriesOfElement[0][1] : "";
  }
}
