import Vue from 'vue';
import App from './components/app.vue';
import store from '../store';
import apiTokensStore from './store';
import { Actions } from './store';

export let app = null;
export function init() {
  const element = document.getElementById('v-api-tokens');
  if (element) {
    const url = element.dataset.url;
    const documentationUrl = element.dataset.doc;
    const type = element.dataset.type;

    store.registerModule('apiTokens', apiTokensStore);
    store.dispatch(Actions.INIT, { url, documentationUrl, type });
    
    app = new Vue({
      store,
      el: element,
      i18n: VERSTEHE.vueI18n,
      render: h => h(App)
    });
  }
}
