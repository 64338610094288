import { Action } from "./action";

/** QueueActions are send to new users to get "up-to-date"
  * They contain all actions that have been performed by other users up to the point when the new user joins
  * When executes all containing actions are executed
  */
export class QueueAction extends Action {
  /**
   * @constructor
   * @param {string} tabId  - TabId of the tab the action originates from
   * @param {number} user - UserId of the chat room owner
   * @param {Array} actions - Array of actions contained by the Queue
   * @param {number} recipient - UserId of the user the QueueAction is destined for
   * @param {String} queueName - Name of the Queue the QueueAction originates from
   */
  constructor(tabId, user, actions, recipient, queueName) {
    super(tabId, user);
    this.actions = actions;
    this.recipient = recipient;
    this.queueName = queueName;
    this.actionType = "QueueAction";
  }

  static fromJson(json) {
    let action = JSON.parse(json);
    return new QueueAction(action.tabId, action.user, action.actions, action.recipient, action.queueName);
  }

  execute() {
    let thisQueueName = this.queueName;
    // console.log("------ " + thisQueueName + " Execution Start ------");
    this.actions.forEach(function(action, thisAction) {
      let newAction = VERSTEHE.CollaborativeActions.ACTIONCLASSES[action.actionType].fromJson(JSON.stringify(action));
      // console.log("executing " + newAction.actionType + " now.");
      newAction.execute();
      VERSTEHE.CollaborativeAuthoring.Queues.find(q => q.queueName === thisQueueName).push(newAction);
    });
    if (this.actions.length === 0) {
      // console.log(thisQueueName + " was empty");
    }
    // console.log("------ " + thisQueueName + " Execution Complete ------");
    VERSTEHE.CollaborativeAuthoring.Queues.find(q => q.queueName === thisQueueName).state = "up-to-date";
  }
}
