import { Take } from "./interfaces";
import {  alertDialog } from '../common/dialog';

export class TakeUploader {
  
  private currentUpload: Take = null;
  private finished: (success: boolean, failedIds?: Array<Number>) => void;
  private success: boolean = true;
  private failedAmors: Array<Number> = [];
  private _isUploading: boolean = false;

  constructor(private takes: Array<Take>, private recorder) { }

  public start(finished: (success: boolean, failedIds?: Array<Number>) => void) {
    this.finished = finished;
    if (!this.isUploading) {
      if (this.recorder.isReady()) {
        this.beginUpload();
      } else {
        this.recorder.startAndCall(() => {
          this.beginUpload();
        });
      }
    }
  }

  private subscribeUploadEvents() {
    this.recorder.on('upload-finished', this.onUploadFinished.bind(this));
    this.recorder.on('upload-failed', this.onUploadFailed.bind(this));
  }

  private unsubscribeUploadEvents() {
    this.recorder.off('upload-finished', this.onUploadFinished.bind(this));
    this.recorder.off('upload-failed', this.onUploadFailed.bind(this));
  }

  private onUploadFinished(amorId: Number) {
    if (amorId === this.currentUpload.amor_id) {
      this.triggerNext();
    }
  }

  private onUploadFailed(amorId: Number) {
    if (amorId === this.currentUpload.amor_id) {
      this.failedAmors.push(amorId);
      this.success = false;
      this.triggerNext();
    }
  }

  private triggerNext() {
    this.currentUpload = this.takes.pop();
    
    if (this.currentUpload) {
      this.recorder.upload(this.currentUpload.amor_id);
    } else {
      this._isUploading = false;
      this.unsubscribeUploadEvents();
      this.finished(this.success, this.failedAmors);
    }
  }

  private beginUpload() {
    this.currentUpload = this.takes.pop();
    
    if (this.currentUpload) {
      this.recorder.fileExists(this.currentUpload.amor_id).then(response => {
        if (response.exists === true) {
          this._isUploading = true;
          this.recorder.upload(this.currentUpload.amor_id);
          this.subscribeUploadEvents();
        } else {
          alertDialog({ content: VERSTEHE.vueI18n.t('screenplay.take_not_present') + this.currentUpload.title });
          this.finished(false, [this.currentUpload.amor_id]);
        }
      });
    } else {
      this.finished(true);
    }
  }

  get isUploading(): boolean {
    return this._isUploading;
  }
}
