import { ChangeAction } from "../../system_actions/change_action";

/**
 * SelectizeAction track the changes of elements of the SelectizeJS-Plugin
 * Multiple SelectizeAction can be reduced to one when they regard the same element and are of the same method
 */
export class SelectizeAction extends ChangeAction {
  /**
   * @constructor
   * @param {String} tabId  - TabId of the tab the action originates from
   * @param {number} user - UserId of the chat room owner
   * @param {String} element - Element thats subject of the UIAction
   * @param {number} value - id of that selectize option
   * @param {String} name - name of that selectize option
   * @param {String} method - add, remove, clear and save
   */
  constructor(tabId, user, element, value, name, method) {
    super(tabId, user, element);
    this.value = value;
    this.name = name;
    this.method = method;
    this.actionType = "SelectizeAction";
  }

  execute() {
    let selectizeElement = $(this.element);
    if (this.method === "add") {
      for (let i = 0; i < this.name.length; i++) {
        VERSTEHE.CollaborativeAuthoring.ignoreNextSelectizeAction++;
        if (selectizeElement[0].selectize.settings.create) {
          selectizeElement[0].selectize.createItem(this.name[i]);
        } else {
          selectizeElement[0].selectize.addItem(this.value[i], true);
        }
      }
      selectizeElement[0].selectize.trigger("change");
    } else if (this.method === "remove") {
      for (let i = 0; i < this.name.length; i++) {
        VERSTEHE.CollaborativeAuthoring.ignoreNextSelectizeAction++;
        selectizeElement[0].selectize.removeItem(this.value[i], true);
      }
      selectizeElement[0].selectize.trigger("change");
    } else if (this.method === "save") {
      $("#private_viewers_submit").hide();
      $("#private_publication").fadeIn(500);
    } else if (this.method === "clear") {
      VERSTEHE.CollaborativeAuthoring.ignoreNextSelectizeAction++;
      selectizeElement[0].selectize.clear();
    }
    selectizeElement[0].selectize.setCaret(selectizeElement[0].selectize.items.length);
  }

  isComparableTo(other) {
    if (this.element.is(other.element)) {
      // return (this.method === other.method || this.method === "save" || this.method === "clear");
      return (this.method === "save" || this.method === "clear");
    } else {
      return false;
    }
  }

  reduce(other, last) {
    if (this.method === "save") {
      return null;
    } else if (this.method === "clear") {
      if (last) {
        return this;
      } else {
        return null;
      }
    } else if (this.method === other.method) {
      this.value = other.value.concat(this.value);
      this.name = other.name.concat(this.name);
      return this;
    } else {
      return this;
    }
  }

  static fromJson(json) {
    let action = JSON.parse(json);
    return new SelectizeAction(action.tabId, action.user, action.elementLocation, action.value, action.name, action.method);

  }

  static registerToElement(wrapper) {
    let selector = ((!wrapper) ? '[data-collaborative-action*="SelectizeAction"]:not(#publish [data-collaborative-action*="SelectizeAction"])' : wrapper + ' [data-collaborative-action*="SelectizeAction"]');
    $(selector).each(function() {
      $(this)[0].selectize.on("item_add", function(value, item) {
        SelectizeAction.registerHelper(value, item, this.$input[0], "add");
      });
      $(this)[0].selectize.on("item_remove", function(value, item) {
        SelectizeAction.registerHelper(value, item, this.$input[0], "remove");
      });
      $(this)[0].selectize.on("clear", function(value, item) {
        SelectizeAction.registerHelper(null, null, this.$input[0], "clear");
      });
    });
    $(document).on('click', '[data-collaborative-action*="SelectizeSaveAction"]', function() {
      SelectizeAction.createAction(SelectizeAction.getTabId(), _app.info.user.userId, $(this).parent().parent().find('[data-collaborative-action*="SelectizeAction"]'), null, null, "save");
    });
  }

  static registerHelper(value, item, element, addOrRemove) {
    // prevent non user item_add events from sending actions
    if (VERSTEHE.CollaborativeAuthoring.ignoreNextSelectizeAction <= 0) {
      let name = ((item) ? item[0].textContent.slice(0, -1) : null);
      SelectizeAction.createAction(SelectizeAction.getTabId(), _app.info.user.userId, element, [value], [name], addOrRemove);
    }
    if (VERSTEHE.CollaborativeAuthoring.ignoreNextSelectizeAction > 0) {
      VERSTEHE.CollaborativeAuthoring.ignoreNextSelectizeAction--;
    }
  }

  static createAction(tabId, user, element, value, name, method) {
    let action = new SelectizeAction(tabId, user, element, value, name, method);
    action.element = null;
    VERSTEHE.content_channel.send_message(action);
  }

}
