import { GetterTree } from 'vuex';
import { WebConferenceState } from './state';
import { RootState } from '../../store/types';
import { Participant } from 'livekit-client';

export const Getters = {
  ROOM: 'webConference/room',
  PARTICIPANTS: 'webConference/participants',
  LOCAL_PARTICIPANT: 'webConference/localParticipant',
  CHAT_MESSAGES: 'webConference/chatMessages',
  ROOM_NAME: 'webConference/roomName',
  SHARED_SCREEN_PARTICIPANT: 'webConference/sharedScreenParticipant',
  WEBCAM_LOADING: 'webConference/webcamLoading',
  CURRENT_WEBCAM_ID: 'webConference/currentWebcamId',
  CURRENT_MICROPHONE_ID: 'webConference/currentMicrophoneId',
  LOCAL_VIDEO_TRACK: 'webConference/localVideoTrack',
  LOCAL_AUDIO_TRACK: 'webConference/localAudioTrack',
  RAISE_HAND_NOTIFICATIONS: 'webConference/raiseHandNotifications',
  NOTIFICATIONS: 'webConference/notification',
  USER_DETAILS: 'webConference/userDetails',
  DETAILS: 'webConference/details',
  SETTINGS: 'webConference/settings',
  IS_GUEST: 'webConference/isGuest',
  STARTING_TIME: 'webConference/startingTime',
  IS_RECORDING: 'webConference/isRecording',
  MODALS: 'webConference/modals',
  SETTINGS_OPENED: 'webConference/settingsOpened',
  CONFERENCE_LAYOUT: 'webConference/conferenceLayout',
  SELECTED_AUDIO: 'webConference/selectedAudio',
  SELECTED_MIC: 'webConference/selectedMic',
  NOISE_SUPPRESSION: 'webConference/noiseSuppression',
  PLAY_EVENT_AUDIO: 'webConference/playEventAudio',
  MIC_ACTIVATED: 'webConference/micActivated',
  SELECTED_CAM: 'webConference/selectedCam',
  CAM_ACTIVATED: 'webConference/camActivated',
  SHOW_CAMERA_ERROR: 'webConference/showCameraError',
  BREAKOUT_STARTED: 'webConference/breakoutStarted',
  IS_BREAKOUT: 'webConference/isBreakout',
  LIVEKIT_URL: 'webConference/livekitUrl',
  ON_JOIN_PAGE: 'webConference/onJoinPage',
  USER_SETTINGS: 'webConference/userSettings',
  CUSTOM_BACKGROUND_IMAGE: 'webConference/customBackgroundImage',
  CUSTOM_BACKGROUND_IMAGE_IDS: 'webConference/customBackgroundImageIds',
};

export const getters: GetterTree<WebConferenceState, RootState> = {
  room: (state) => state.room,
  participants: (state): Participant[] => state.participants,
  localParticipant: (state) => state.localParticipant,
  chatMessages: (state) => state.chatMessages,
  roomName: (state) => state.roomName,
  sharedScreenParticipant: (state) => state.sharedScreenParticipant,
  webcamLoading: (state) => state.webcamLoading,
  currentWebcamId: (state) => state.currentWebcamId,
  currentMicrophoneId: (state) => state.currentMicrophoneId,
  localVideoTrack: (state) => state.localVideoTrack,
  localAudioTrack: (state) => state.localAudioTrack,
  raiseHandNotifications: (state) => state.raiseHandNotifications,
  notifications: (state) => state.notifications,
  userDetails: (state) => state.userDetails,
  details: (state) => state.details,
  settings: (state) => state.settings,
  isGuest: (state) => state.isGuest,
  startingTime: (state) => state.startingTime,
  isRecording: (state) => state.isRecording,
  modals: (state) => state.modals,
  settingsOpened: (state) => state.settingsOpened,
  conferenceLayout: (state) => state.userSettings.conferenceLayout,
  selectedAudio: (state) => state.userSettings.selectedAudio,
  selectedMic: (state) => state.userSettings.selectedMic,
  noiseSuppression: (state) => state.userSettings.noiseSuppression,
  playEventAudio: (state) => state.userSettings.playEventAudio,
  micActivated: (state) => state.micActivated,
  selectedCam: (state) => state.userSettings.selectedCam,
  camActivated: (state) => state.camActivated,
  showCameraError: (state) => state.showCameraError,
  breakoutStarted: (state) => state.breakoutStarted,
  isBreakout: (state) => state.isBreakout,
  livekitUrl: (state) => state.livekitUrl,
  onJoinPage: (state) => state.onJoinPage,
  userSettings: (state) => state.userSettings,
  customBackgroundImage: (state) => (id: string) => state.customBackgroundImages.find((image) => image.id === id),
  customBackgroundImageIds: (state) => state.customBackgroundImages.map((background): string => background.id),
};
