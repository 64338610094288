import TaskEntry from './task_entry.vue';

const AUTHOR_TASKS = [
  'setup_profile',
  'first_video',
  'publish_video'
];

const TRAINER_TASKS = [
  'create_course',
  'create_quiz',
  'create_course_plan'
];

const PUBLISHER_TASKS = [
  'customize_topic_areas',
  'custom_branding',
  'video_template',
  'landing_page'
];

const TASKS = [
  ...AUTHOR_TASKS,
  ...TRAINER_TASKS,
  ...PUBLISHER_TASKS
];

export {
  TASKS,
  AUTHOR_TASKS,
  TRAINER_TASKS,
  PUBLISHER_TASKS,
  TaskEntry
};
