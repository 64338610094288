import { GetterTree } from 'vuex';
import { FilmProductionState } from './state';
import { RootState } from '../../store/types';
import { ProductionTemplate, ProductionTemplateSettings, Task, TaskState, TranscribeTask } from '../interfaces';

export const Getters = {
  HAS_PRODUCTION_JOB: 'filmProduction/hasProductionJob',
  IS_COMPLETED: 'filmProduction/isCompleted',
  IS_PRODUCING: 'filmProduction/isProducing',
  IS_MERGED: 'filmProduction/isMerged',
  IS_SUCCESS: 'filmProduction/isSuccess',
  IS_ERROR: 'filmProduction/isError',
  IS_ABORTED: 'filmProduction/isAborted',
  MERGED_VIDEO: 'filmProduction/mergedVideo',
  MERGE_TASK: 'filmProduction/mergeTask',
  CONVERT_TASK: 'filmProduction/convertTask',
  CONVERT_TASKS: 'filmProduction/convertTasks',
  THUMBNAIL_TASK: 'filmProduction/thumbnailTask',
  PREVIEW_THUMBNAIL_TASK: 'filmProduction/previewThumbnailTask',
  TRANSCRIBE_TASK: 'filmProduction/transcribeTask',
  CURRENT_TEMPLATE: 'filmProduction/currentTemplate',
  TEMPLATE_URLS: 'filmProduction/templateUrls',
  ERRORS: 'filmProduction/errors',
  TEMPLATE_PRIVILEGES: 'filmProduction/templatePrivileges',
  ABS_TASK: 'filmProduction/absTask',
  IS_ABS_ENABLED: 'filmProduction/isAbsEnabled',
  PROGRESS: 'filmProduction/progress'
}

export const getters: GetterTree<FilmProductionState, RootState> = {
  hasProductionJob: state => state.progress.state !== null,
  isCompleted: (state, getters) => getters.hasProductionJob && state.progress.finished,
  isProducing: (state, getters) => getters.hasProductionJob && !getters.isCompleted,
  isMerged: (state, getters) => getters.hasProductionJob && state.progress.merged.exists,
  isSuccess: (state, getters) => getters.hasProductionJob && state.progress.finished && state.progress.state === 'produced',
  isError: (state, getters) => getters.hasProductionJob && state.progress.state === 'failed',
  isAborted: (state, getters) => getters.hasProductionJob && state.progress.state === 'aborted',
  absTask: state => state.absData.progress,
  isAbsEnabled: state => state.absData.enabled,
  progress: (state) => state.progress,
  mergedVideo: (state, getters) => {
    if (getters.isMerged) {
      return state.progress.merged.source_url;
    }
  },
  mergeTask: (state, getters): Task => {
    return {
      label: 'merge',
      state: state.progress.merge_tooltip,
      duration: state.progress.merge_duration,
      eta_state: state.progress.merge_eta_state
    }
  },
  convertTasks: (state): Array<Task> => {
    let tasks: Array<Task> = [];

    state.progress.converting_jobs.forEach(convertJob => {
      tasks.push({
        label: `${convertJob.container} / ${convertJob.resolution}p`,
        state: convertJob.state_tooltip,
        duration: convertJob.duration,
        eta_state: convertJob.eta_state
      });
    });

    return tasks;
  },
  convertTask: (state, getters): Task => {
    if (state.progress.converting_jobs.length > 0) {
      let convertState: TaskState = 'pending';
      let unfinishedTasks = getters.convertTasks.filter((task: Task) => {
        return task.state === 'pending' || task.state === 'running'
      })

      if (state.progress.state === 'merged' || (state.progress.state === 'produced' && unfinishedTasks.length > 0)) {
        convertState = 'running';
      } else if (state.progress.state === 'converted' || state.progress.state === 'produced') {
        convertState = 'success';
      }

      return {
        label: 'convert',
        state: convertState,
        duration: null
      }
    }
  },
  thumbnailTask: (state): Task => {
    return {
      label: 'thumbnail',
      state: state.progress.thumbnail_state_tooltip,
      duration: state.progress.thumbnail_duration
    };
  },
  previewThumbnailTask: (state) : Task => {
    return {
      label: 'previewThumbnail',
      state: state.progress.preview_thumbnail_tooltip,
      duration: state.progress.preview_thumbnail_duration
    };
  },
  transcribeTask: (state): TranscribeTask => {
    return {
      label: 'transcribe',
      state: state.progress.transcribe_state,
      tooltip: state.progress.transcribe_tooltip,
      duration: state.progress.transcribe_duration
    };
  },
  currentTemplate: (state): ProductionTemplate => {
    return state.template.current_template;
  },
  templateUrls: (state): ProductionTemplateSettings['urls'] => {
    return state.template.urls;
  },
  errors: (state) => state.errors,
  templatePrivileges: (state) => state.template.privileges
}
