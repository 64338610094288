interface MetaData {
  id: string, 
  username: string, 
  firstname: string, 
  lastname: string, 
  scope: ScopeType,
  avatar: string,
  raisesHand: boolean,
  isGuest:  boolean,
  inWaitingRoom: boolean;
}

enum ScopeType {
  CREATOR = 'creator',
  ADMIN = 'admin',
  SPECTATOR = 'spectator',
  PRESENTATOR = 'presentator',
}

export { MetaData, ScopeType};