export class KrTool {
  /**
   * Return Date intervall
   */
  static setTillAndFrom() {
    let tillElement = document.querySelector('#dashboard_interval_till');
    let fromElement = document.querySelector('#dashboard_interval_from');
    let from;
    let till;

    if (tillElement !== null) {
      till = tillElement.getAttribute('value');
    } else {
      till = null;
    }

    if (fromElement !== null) {
      from = fromElement.getAttribute('value');
    } else {
      from = null;
    }

    return { "till": till, "from": from };
  }
}
