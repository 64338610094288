export const locals = {
  de: {
    upload_wrong_filetype: "Bitte wählen Sie nur Videodateien aus.",
    upload_too_many_files: "Bitte laden Sie Ihre Videos einzeln hoch.",
    please_wait_for_video_to_finish: "Sie müssen warten bis der aktuelle Vorgang abgeschlossen ist, bevor Sie ein neues Video hochladen können.",
    error_uploading: "Fehler beim Verarbeiten.",
    error_transcoding: "Fehler beim Verarbeiten.",
    video_invalid: "Das hochgeladene Video ist ungültig und kann nicht verarbeitet werden.",
    unsupported_browser_title: "Browser nicht unterstützt",
    unsupported_browser_body: "<p>Ihr aktueller Browser wird derzeit leider nicht unterstützt. Es kann zu Problemen mit der Aufnahme kommen.</p><p>Um einen reibungslosen Ablauf zu gewährleisten, verwenden Sie bitte einen der folgenden Browser</p><ul><li>Google Chrome</li><li>Mozilla Firefox ( ohne Privater Modus )</li><li>Microsoft Edge</li></ul>",
    bbb_title: "BigBlueButton Aufzeichnung importieren"
  },
  en: {
    upload_wrong_filetype: "Please only select video files.",
    upload_too_many_files: "Please upload videos individually.",
    please_wait_for_video_to_finish: "You must wait until the current process is complete before you can upload a new video.",
    error_uploading: "Error while processing.",
    error_transcoding: "Error while processing.",
    video_invalid: "The uploaded video is invalid and cannot be processed.",
    unsupported_browser_title: "Unsupported browser",
    unsupported_browser_body: "<p>Your current browser is not supported. There may be problems with the recording.</p><p>To ensure a smooth process, please use one of the following browsers</p><ul><li>Google Chrome</li><li>Mozilla Firefox ( without private browsing)</li><li>Microsoft Edge</li></ul>",
    bbb_title: "Import BigBlueButton recording"
  },
  es: {
    upload_wrong_filetype: "Por favor, seleccione sólo archivos de vídeo.",
    upload_too_many_files: "Por favor, sube tus vídeos individualmente.",
    please_wait_for_video_to_finish: "Debe esperar hasta que el proceso actual esté completo antes de poder subir un nuevo vídeo.",
    error_uploading: "Error durante el procesamiento.",
    error_transcoding: "Error durante el procesamiento.",
    video_invalid: "El vídeo cargado no es válido y no puede ser procesado.",
    unsupported_browser_title: "Navegador no compatible",
    unsupported_browser_body: "<p>Su navegador actual no es compatible. Puede haber problemas con la grabación.</p><p>Para garantizar un proceso fluido, utilice uno de los siguientes navegadores</p><ul><li>Google Chrome</li><li>Mozilla Firefox ( sin navegación privada )</li><li>Microsoft Edge</li></ul>",
    bbb_title: "Importar la Conferencia BigBlueButton"
  }
};

export function t(key) {
  return locals[_app.info.currentLocale][key] || key;
}
