export class LessonSerializer {
  constructor() { }

  serialize(lesson) {
    let attributes = {
      title: lesson.title,
      description: lesson.description,
      type: lesson.type
    };

    if (lesson.questions) {
      // Make sure all questions get deleted when a lesson is switched from TopicQuiz to Topic
      // due to change of topic
      attributes.questions_attributes =
        lesson.questions.filter(question => !question.isNew).map((question) => {
          return {
            id: question.id,
            _destroy: true
          };
      });
    }

    if (lesson.subject && lesson.subject.id) {
      attributes.subject_id = lesson.subject.id;
      attributes.subject_type = lesson.subject.type;
    }

    if (!lesson.isNew) {
      attributes.id = lesson.id;
    }

    if (lesson.deleted) {
      attributes._destroy = '1';
    }

    return attributes;
  }
}

export default function serializeLesson(lesson) {
  const serializer = new LessonSerializer();
  return serializer.serialize(lesson);
}
