import { Question, Answer } from "./entities";
import store from '../../store';
import { AnswerType } from "./entities/answer";
import { ImagemapAnswerSerializer } from "./serializers/imagemap_answer_serializer";
import { AnswerSerializer } from "./serializers/answer_serializer";
import { MatchingAnswerSerializer } from "./serializers/matching_answer_serializer";
import { DragAndDropAnswerSerializer } from './serializers/drag_and_drop_answer_serializer';
import { QuestionType } from "./entities/question";
import { Actions } from "./store/actions";
import { FillTheBlankSerializer } from "./serializers/fill_the_blank_answer_serializer";

export function serializeQuiz() {
  const general = store.state.quizManage.general;
  let serializedQuiz = {
    quiz: {
      title: general.title,
      description: general.description,
      success_requirement: general.successRequirement,
      max_tries: general.maxTries,
      block_time: general.blockTime,
      time_limit: general.timeLimit,
      random_question_order: general.randomQuestionOrder,
      private_feedback: general.privateFeedback,
      questions_attributes: serializeQuestions()
    }
  }

  if (general.imageUpload) {
    serializedQuiz['quiz']['image'] = general.image;
    serializedQuiz['quiz']['image_file_name'] = general.imageFileName;
  }

  return serializedQuiz;
}

export function serializeQuestions() {
  store.dispatch(Actions.REFRESH_QUESTION_POSITIONS);
  const withoutDeleted = store.state.quizManage.questions.filter(q => store.state.quizManage.entities.questions[q].deleted === false);

  return Object.keys(store.state.quizManage.entities.questions).map(questionId => {
    const question: Question = store.state.quizManage.entities.questions[questionId];
    return serializeQuestion(question, withoutDeleted.indexOf(question.id) + 1);
  });
}

export function serializeQuestion(question: Question, position: number) {
  const serializedQuestion = {
    id: typeof question.id === 'string' ? null : question.id,
    _destroy: question.deleted,
    question: question.question,
    type: questionTypeMapping[question.type],
    position: position,
    answers_attributes: question.answers.map(answerObject => {
      const answer: Answer = store.state.quizManage.entities[answerObject.schema][answerObject.id];
      return serializeAnswer(answer, question);
    })
  };

  if (question.imageUpload) {
    serializedQuestion['image'] = question.image;
    serializedQuestion['image_file_name'] = question.imageFileName;
  }

  if (question.type === QuestionType.matching) {
    serializedQuestion['distractors'] = question.distractors;
  }

  return serializedQuestion;
}

function serializeAnswer(answer: Answer, question: Question) {
  switch(answer.type) {
    case AnswerType.matching: {
      return new MatchingAnswerSerializer(answer, question).getSerialized();
    }
    case AnswerType.imagemap: {
      return new ImagemapAnswerSerializer(answer, question).getSerialized();
    }
    case AnswerType.drag_and_drop: {
      return new DragAndDropAnswerSerializer(answer, question).getSerialized();
    }
    case AnswerType.fill_the_blank: {
      return new FillTheBlankSerializer(answer, question).getSerialized();
    }
    default: {
      return new AnswerSerializer(answer, question).getSerialized();
    }
  }
}

const questionTypeMapping = {
  single_choice: 'Quiz::Question::SingleChoice',
  statement: 'Quiz::Question::Statement',
  numerical: 'Quiz::Question::Numerical',
  imagemap: 'Quiz::Question::Imagemap',
  open: 'Quiz::Question::Open',
  arrangement: 'Quiz::Question::Arrangement',
  matching: 'Quiz::Question::Matching',
  drag_and_drop: 'Quiz::Question::DragAndDrop',
  fill_the_blank: 'Quiz::Question::FillTheBlank',
}
