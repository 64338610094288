import mutations from './mutations';
import actions from './actions';
import getters from './getters';

const initialState = {
  baseUrl: null,
  settingsLoaded: false,
  integrations: []
};

export default {
  namespaced: true,
  state : initialState,
  mutations,
  actions,
  getters
};
