<template>
  <div>
    <div class="getting-started-welcome">
      <h3 v-if="user">{{ $t('getting_started.welcome_personalized', { name: user }) }}</h3>
      <h3 v-else>{{ $t('getting_started.welcome') }}</h3>
    </div>
    <div class="getting-started-body">
      <task-list />
      <task-detail />
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import TaskList from './task_list.vue';
  import TaskDetail from './task_detail.vue';

  export default {
    components: {
      TaskList,
      TaskDetail
    },
    computed: {
      ...mapGetters({
        user: 'gettingStarted/user'
      })
    },
    methods: mapActions({
      refreshCompletedTasks: 'gettingStarted/refreshCompletedTasks'
    }),
    created() {
      this.refreshCompletedTasks();
      window.addEventListener('focus', this.refreshCompletedTasks);
    },
    destroyed() {
      window.removeEventListener('focus', this.refreshCompletedTasks);
    }
  }
</script>

<style lang="scss" scoped>
  .getting-started-welcome {
    height: 70px;
    padding: 0 10px;
    background-color: $color-grey-10;
    border-bottom: 1px solid $black-12-opacity;

    h3 {
      margin: 0;
      line-height: 70px;
      font-size: 24px;
      font-weight: 400;
    }
  }

  .getting-started-body {
    display: flex;
    border-bottom: 1px solid $black-12-opacity;
  }
</style>
