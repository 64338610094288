import { SlideshowAction } from "./slideshow_action";

/**
 * The SaveSlideAction is triggered when a user saves the slideshow
 * since the slideshow is persisted when saved and Queued SlideshowActions are not necessary anymore, the SaveSlideshowAction removes all SlideshowActions from the Queue
 */
export class SaveSlidesAction extends SlideshowAction {
  /**
   * @constructor
   * @param {String} tabId  - TabId of the tab the action originates from
   * @param {number} user - UserId of the chat room owner
   * @param {String} element - Element thats subject of the UIAction
   */
  constructor(tabId, user, element) {
    super(tabId, user, element);
    this.actionType = "SaveSlidesAction";
  }

  execute() {
    $('[data-collaborative-action*="SaveSlidesAction"]#save-slideshow').addClass("disabled");
  }

  isComparableTo(other) {
    if (this.actionType === other.actionType || other.actionType === "SlideAction" || other.actionType === "AllSlidesAction") {
      return true;
    } else {
      false;
    }
  }

  reduce(other) {
    return null;
  }

  static fromJson(json) {
    let action = JSON.parse(json);
    return new SaveSlidesAction(action.tabId, action.user, action.elementLocation);
  }

  static registerToElement() {
    $(document).on('ajax:success', '[data-collaborative-action*="SaveSlidesAction"].slideshow-form', function(event) {
      SaveSlidesAction.createAction(SaveSlidesAction.getTabId(), _app.info.user.userId, null);
    });
  }

  static createAction(tabId, user, element) {
    let action = new SaveSlidesAction(tabId, user, element);
    VERSTEHE.content_channel.send_message(action);
  }
}
