import Vue from 'vue';
import VueRouter from 'vue-router';
import { sync } from 'vuex-router-sync';

import App from './components/app.vue';
import TutorialOverview from './components/tutorial_overview.vue';
import TutorialPlayer from './components/tutorial_player.vue';

import store from '../store';
import tutorialsStore from './store';

export let app = null;

const routes = [
  {
    path: '/c/:categoryId',
    name: 'overview',
    component: TutorialOverview,
    beforeEnter: (to, _from, next) => {
      const categoryId = to.params.categoryId;

      if (categoryId === 'all' || store.getters['tutorials/categoryById'](categoryId)) {
        store.dispatch('tutorials/filterCategory', categoryId);
        next();
      } else {
        next('/');
      }
    }
  },
  {
    path: '/search',
    name: 'search',
    component: TutorialOverview,
    beforeEnter: (to, _from, next) => {
      if (to.query.q) {
        store.dispatch('tutorials/setSearchQuery', to.query.q);
      }
      
      next();
    }
  },
  {
    path: '/video/:videoId',
    name: 'video',
    component: TutorialPlayer,
    beforeEnter: (to, _from, next) => {
      const video = store.getters['tutorials/videoById'](to.params.videoId);

      if (video) {
        store.dispatch('tutorials/setCurrentVideo', video.id);
        next();
      } else {
        next('/');
      }
    }
  },
  {
    path: '*',
    redirect: '/c/all'
  }
];

export function init() {
  const element = document.getElementById('tutorials');
  const tutorialVideos = JSON.parse(element.dataset.videos);
  const hiddenCategories = JSON.parse(element.dataset.hidden);

  store.registerModule('tutorials', tutorialsStore);
  store.dispatch('tutorials/setTutorialVideos', { tutorialVideos, hiddenCategories });

  // Set roles of logged in user to filter displayed videos
  store.dispatch('tutorials/filterRole', _app.info.user.roles);

  const router = new VueRouter({
    routes
  });

  const unsyncRouter = sync(store, router);

  app = new Vue({
    store,
    router,
    el: element,
    store: store,
    i18n: VERSTEHE.vueI18n,
    render: h => h(App),
    destroyed: () => unsyncRouter()
  });
}
