import { Answer, Question } from '../entities';
import store from '../../../store';
import { AnswerSerializer } from './answer_serializer';

export class FillTheBlankSerializer extends AnswerSerializer {
  protected serializedAnswer = {};

  constructor(answer: Answer, question: Question) {
    super(answer, question);
    if (answer.options) {
      this.serializedAnswer['blank_options_attributes'] = [];
      for (let i = 0; i < answer.options.length; i++) {
        this.serializedAnswer['blank_options_attributes'][i] = {
          points: answer.options[i].points,
          rightAnswer: answer.options[i].rightAnswer,
          wrongAnswers: JSON.stringify(answer.options[i].wrongAnswers),
          uuid: answer.options[i].uuid,
          id: answer.options[i].id,
        };
      }
    }
    this.serializedAnswer['text'] = answer.text;
    this.serializedAnswer['type'] = answer.type;
    this.serializedAnswer['id'] = typeof answer.id === 'string' ? null : answer.id;
  }

  public getSerialized() {
    return this.serializedAnswer;
  }
}
