import Vue from 'vue';
import App from './components/app.vue';
import store from '../store';
import filmQuestionsStore from './store';
import { Actions } from './store';

export let app = null;
export let initUrl;

export function init() {
  const element = document.getElementById('v-film-questions');

  if (element) {
    initUrl = element.dataset.initial;

    store.registerModule('filmQuestions', filmQuestionsStore);
    store.dispatch(Actions.INIT_URL, initUrl);

    app = new Vue({
      store,
      el: element,
      i18n: VERSTEHE.vueI18n,
      render: h => h(App)
    });
  }
}

export function reload() {
  store.dispatch(Actions.RESET_STATE);
}

export function serializeQuestions(questions) {
  let questionsAttributes = [];

  return questions.map((question) => {
    let questionAttributes = {
      title: question.title,
      hint: question.hint,
      time: question.time,
      randomize: question.randomize,
      type: question.type,
      options_attributes: question.options.map((option) => {
        let optionAttributes = {
          title: option.title,
          correct: option.correct,
          position: option.position
        };

        if (!option.isNew) {
          optionAttributes.id = option.id;
        }

        if (option.deleted) {
          optionAttributes = {
            id: option.id,
            _destroy: true
          };
        }

        return optionAttributes;
      })
    };

    if (!question.isNew) {
      questionAttributes.id = question.id;
    }

    if (question.deleted) {
      questionAttributes = {
        id: question.id,
        _destroy: true
      };
    }

    return questionAttributes;
  });
};
