import { state } from './state';
import { actions } from './actions';
import { mutations } from './mutations';
import { getters } from './getters';

// split store into separate files and merge them here. 
export const PlaylistManageModule = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
