import Base64 from 'crypto-js/enc-base64';
import Utf8 from 'crypto-js/enc-utf8';

let embedSize, optionRecommendation, optionInfo;

function changedEmbedOptions() {
  const textarea = document.getElementById('embed-code');
  const textfield = document.getElementById('embed-code-url');
  let { url, token, width, height } = textarea.dataset;
  let options = {};

  if (optionRecommendation) {
    options.recommendations = optionRecommendation.checked;
  }

  if (optionInfo) {
    options.info = optionInfo.checked;
  }

  if (embedSize) {
    width = embedSize.value.split('x')[0].trim();
    height = embedSize.value.split('x')[1].trim();
  }

  const embedHash = Utf8.parse(JSON.stringify({ token: token, ...options }));
  const hashUrl = `${url}/${Base64.stringify(embedHash)}`;
  const iframe = buildEmbedIframe(hashUrl, width, height);
  textarea.value = iframe.outerHTML.replace('&amp;', '&');
  textfield.value = hashUrl;
  document.getElementById('embed-preview').innerHTML = iframe.outerHTML;
}

function buildEmbedIframe(url, width, height) {
  const iframe = document.createElement('iframe');
  iframe.src = url;
  iframe.width = width;
  iframe.height = height;
  iframe.frameBorder = 0;
  iframe.allowFullscreen = true;

  return iframe;
}

export function initPreviewModal() {
  embedSize = document.getElementById('embed-size');
  optionRecommendation = document.getElementById('embed-recommendations');
  optionInfo = document.getElementById('embed-info');

  // Bind change event listeners
  embedSize && embedSize.addEventListener('change', changedEmbedOptions);
  optionRecommendation && optionRecommendation.addEventListener('change', changedEmbedOptions);
  optionInfo && optionInfo.addEventListener('change', changedEmbedOptions);

  changedEmbedOptions();
}
