
enum DataType {
  SHARESCREEN = 'shareScreen',
  CHAT = 'chat',
  RAISEDHAND = 'raisedHand',
  DELETERAISEDHAND = 'deleteRaisedHand',
  JOINEDROOM = 'joinedRoom',
  JOINENQUIRY = 'joinenquiry',
  COPYCLIPBOARD = 'copyClipboard',
  ISPRESENTATOR = 'isPresentator',
  ENDRECORDING = 'endRecording',
  STARTRECORDING = 'startRecording',
  BREAKOUTSTARTED = 'startBreakout',
  BREAKOUTSTOPED = 'stopBreakout',
  DISCONNECTED = 'disconnected',
}

class DataPayload {
  id: string;
  messageType: DataType;
  message: string;
  created_at: Date;
  participant?: string;
  participant_metadata?: string;
}
export { DataPayload, DataType};
