// Videojs Plugin for Screenplays

const Plugin = videojs.getPlugin('plugin');
const Button = videojs.getComponent('Button');
const Menu = videojs.getComponent('Menu');
const LOCALES = {
  'de': {
    'screenplay': 'Drehbuch'
  },
  'en': {
    'screenplay': 'Screenplay'
  },
  'es': {
    'screenplay': 'Screenplay'
  }
};

export class ScreenplayPlugin extends Plugin {

  constructor(player, options) {
    super(player, options);

    // Initialize Button and Menu
    videojs.registerComponent('ScreenplayBtn', ScreenplayBtn);
    videojs.registerComponent('ScreenplayMenu', ScreenplayMenu);
    this.screenplay_menu = player.addChild('ScreenplayMenu', { parent_plugin: this });
    this.screenplay_button = player.controlBar.addChild('ScreenplayBtn', { button: options.button, parent_plugin: this }, 6);
    this.screenplay = null;
    this.screenplayShown = false;
    this.current_scene = null;
    this.registerEvents();
  }

  toggleScreenplay() {
    this.screenplay_menu.toggleClass('closed');
    this.screenplayShown = (this.screenplayShown === true) ? false : true;
  }

  setScreenplay(screenplay) {
    this.screenplay = screenplay;
    this.screenplay_menu.initScreenplay(this.screenplay);
  }

  currentScene() {
    return this.current_scene;
  }

  registerEvents() {
    // Prevent controlbar from auto-hiding if screenplay is open
    this.player.on('userinactive', () => {
      if (this.screenplayShown === true) {
        this.player.userActive( true );
      }
    });

    // Update the Current Scene on timeupdate
    this.player.on('timeupdate', e => {
      let current_scene = null;

      if(this.screenplay) {
        let current_time = this.player.currentTime();

        this.screenplay.forEach(section => {
          section.scenes.forEach(scene => {
            if(current_time >= parseFloat(scene.starting_second)) {
              current_scene = scene;
            }
          });
        });
      }
      this.current_scene = current_scene;
    });
  }
};

class ScreenplayBtn extends Button {
  constructor(player, options) {
    super(player, options);
    this.plugin = options.parent_plugin;
    this.initButton(options.button);
    this.controlText(LOCALES[player.language()].screenplay);
  }

  initButton(button_opts) {
    this.el().innerHTML = button_opts.content;
    this.el().classList.add(button_opts.class);
  }

  handleClick() {
    this.plugin.toggleScreenplay();
  }
}

class ScreenplayMenu extends Menu {
  constructor (player, options) {
    super(player, options);
    this.vjsplayer = player;
    this.plugin = options.parent_plugin;
    this.addClass('vjs-screenplay-menu');
    this.addClass('closed');
    this.displaySpinner();
  }

  initScreenplay(screenplay) {
    let title = document.createElement('div');
    title.classList.add('vjs-screenplay-title');
    title.innerHTML = LOCALES[this.vjsplayer.language()].screenplay;

    this.el().innerHTML = '';
    this.el().appendChild(title);

    screenplay.forEach((section) => {
      let builtSection = this.buildSection(section);
      this.el().appendChild(builtSection);
    });

    this.registerClickEvent();
    this.registerTimeUpdate();
  }

  buildSection(section) {
    let builtSection = document.createElement('table');
    builtSection.classList.add('vjs-screenplay-section');

    let row = document.createElement('tr');
    row.classList.add('vjs-screenplay-section-element');
    row.classList.add('vjs-screenplay-element');
    row.setAttribute('data-start', section.starting_second);
    row.setAttribute('data-duration', section.duration);

    let title = document.createElement('td');
    title.classList.add('vjs-screenplay-section-title');
    title.innerHTML = section.title;

    let duration = document.createElement('td');
    duration.classList.add('vjs-screenplay-section-duration');
    duration.innerHTML = this.getTimeString(section.starting_second);

    row.appendChild(title);
    row.appendChild(duration);
    builtSection.appendChild(row);

    section.scenes.forEach((scene) => {
      let builtScene = this.buildScene(scene);
      builtSection.appendChild(builtScene);
    });

    return builtSection;
  }

  buildScene(scene) {
    let builtScene = document.createElement('tr');
    builtScene.id = `scene-${scene.id}`;
    builtScene.classList.add('vjs-screenplay-scene-element');
    builtScene.classList.add('vjs-screenplay-element');
    builtScene.setAttribute('data-start', scene.starting_second);
    builtScene.setAttribute('duration', scene.duration);

    let activeIcon = document.createElement('i');
    activeIcon.classList.add('material-icons');
    activeIcon.classList.add('active-icon');
    activeIcon.innerHTML = 'play_arrow';

    let title = document.createElement('td');
    title.classList.add('vjs-screenplay-scene-title');
    title.innerHTML = scene.title;

    let duration = document.createElement('td');
    duration.classList.add('vjs-screenplay-scene-duration');
    duration.innerHTML = this.getTimeString(scene.starting_second);

    builtScene.appendChild(activeIcon);
    builtScene.appendChild(title);
    builtScene.appendChild(duration);

    return builtScene;
  }

  // add start_time parameter in url if current contribution is type topic
  updateUrl(startTime) {
    if(window.location.href.includes('/topics/')) {
      window.history.replaceState(null, null, "?start_time=" + startTime);
    }
  }
  
  registerClickEvent() {
    let elements = document.querySelectorAll('.vjs-screenplay-element');
    [].forEach.call(elements, (element) => {
      element.onclick = () => {
        let second = element.getAttribute('data-start');
        this.updateUrl(second);
        this.vjsplayer.currentTime(second);
      };
    });
  }

  // Update current scene indicator in Screenplay
  registerTimeUpdate() {
    let scene_temp = null;
    this.vjsplayer.on('timeupdate', e => {
      let current_scene = this.plugin.currentScene();
      if (current_scene !== scene_temp) {
        scene_temp = current_scene;
        let old_scene = document.querySelector('.vjs-screenplay-scene-element.active');

        if (old_scene) {
          old_scene.classList.remove('active');
        }

        if(current_scene !== null) {
          let new_scene = document.querySelector(`#scene-${current_scene.id}.vjs-screenplay-scene-element`);
          new_scene.classList.add('active');
        }
      }
    });
  }

  // Show Spinner while Screenplay is loading
  displaySpinner() {
    this.el().innerHTML = `
      <div class="vjs-screenplay-spinner">
        <div class="spinner">
          <div class="rect1"></div>
          <div class="rect2"></div>
          <div class="rect3"></div>
          <div class="rect4"></div>
          <div class="rect5"></div>
        </div>
      </div>`;
  }

  getTimeString(seconds) {
    let min = Math.floor(seconds / 60);
    let sec = Math.floor(seconds) % 60;

    return `${min}:${("0" + sec).slice(-2)}`;
  }
}
