class SiteGeneralComponent {
  init() {
    this.initCheckboxListener();
    this.initSubmit();
  }

  initCheckboxListener() {
    // Get the checkbox
    var checkBox = document.getElementById("site_obj_password_protected");
    // Get the output text
    var field = document.querySelector(".password-field");
      // If the checkbox is checked, display the output text
      if (checkBox.checked === true){
        field.classList.remove('hidden');
      } else {
        field.classList.add('hidden');
      }
      checkBox.addEventListener( 'change', function() {
        if(this.checked) {
          document.querySelector(".password-field").classList.remove('hidden');
        } else {
          document.querySelector(".password-field").classList.add('hidden');
        }
    });
  }

  initSubmit() {
    document.querySelector('#save-site-btn').addEventListener('click', () => {
      document.querySelector('.edit-site-form').submit();
    });
  }
}

class SiteFooterComponent {
  init() {
    this.initKendoEditor();
    this.initSelectize();
    this.initSubmit();
  }

  initKendoEditor() {
    if($('#footer_content').length) {
      var baseurl = $('#footer_content').data("baseurl");
      var shouldSanitize = $('#footer_content').data("sanitize");

      $('#footer_content').kendoEditor({
        pasteCleanup: {
          custom: function(text) {
            return shouldSanitize ? VERSTEHE.Globals.Sanitizer.sanitize(text) : text;
          },
          keepNewLines: true
        },
        resizable: true,
        tools: [
          "viewHtml",
          "formatting",
          "bold",
          "italic",
          "underline",
          "cleanFormatting",
          "insertUnorderedList",
          "insertOrderedList",
          "justifyLeft",
          "justifyCenter",
          "justifyRight",
          "justifyFull",
          "createLink",
          "unlink",
          "insertImage"
        ],
        imageBrowser: {
          transport: {
            read: baseurl + '/image_browser/read',
            thumbnailUrl: baseurl + '/image_browser/thumbnail',
            uploadUrl: baseurl + '/image_browser/upload',
            imageUrl: baseurl + '/image_browser/image?path={0}',
            destroy: {
              url: baseurl + '/image_browser/destroy',
              type: "POST"
            }
          }
        },
        deserialization: {
          custom: function(html) {
            return shouldSanitize ? VERSTEHE.Globals.Sanitizer.sanitize(html) : html;
          }
        },
        serialization: {
          custom: function(html) {
            return shouldSanitize ? VERSTEHE.Globals.Sanitizer.sanitize(html) : html;
          }
        },
        stylesheets: [
          "/editor_footer.css"
        ]
      });
      this.editor = $('#footer_content').data("kendoEditor");
    }
  }

  initSelectize() {
    $('.footer-select').selectize({
      onChange: (value) => {
        this.editor.value($('#' + value).text());
      }
    });
  }

  initSubmit() {
    document.querySelector('#save-site-btn').addEventListener('click', () => {
      const footer_text = this.editor.value();
      const input = $('<input>').attr('type', 'hidden').attr('name', 'site_obj[footer_text]').val(footer_text);
      $('.edit-site-form').append($(input));
      document.querySelector('.edit-site-form').submit();
    });
  }
}

class SiteSettingsComponent {
  init() {
    this.initSubmit();
  }

  initSubmit() {
    document.querySelector('#save-site-btn').addEventListener('click', () => {
      document.querySelector('.edit-site-form').submit();
    });
  }
}

export const SiteGeneral = new SiteGeneralComponent();
export const SiteFooter = new SiteFooterComponent();
export const SiteSettings = new SiteSettingsComponent();
