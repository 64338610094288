import { Busy } from '../globals/busy';

export class Profile {
  constructor () {
    if (document.body.classList.contains('user-profile-page')) {
      this.contributionList = document.getElementById('profile_contributions');
      this.setupContributionPagination();
      this.initImageUpload();
    }
  }

  setupContributionPagination () {
    this.contributionList.querySelectorAll('.md-pagination li:not(.disabled):not(.active) > a').forEach(link => {
      link.addEventListener('click', e => {
        e.preventDefault();

        let busy = new Busy();
        this.contributionList.appendChild(busy.loader);

        $.get(link.href, response => {
          this.contributionList.innerHTML = response;
          this.setupContributionPagination();
          window.scrollTo(0, 0);
        });
      });
    });
  }

  updateUserImages (form, xhr) {
    let header = document.querySelector('.user-profile .user-profile-header');
    let avatar = document.querySelector('.user-profile .user-profile-avatar');
    let fileField = form.querySelector('input[type="file"]');
    let deleteField = form.querySelector('.delete-field');

    if (parseInt(xhr.status, 10) === 200) {
      let response = JSON.parse(xhr.responseText);

      if (deleteField.value === '1') {
        deleteField.value = null;
        form.classList.remove('has-image');
      } else {
        form.classList.add('has-image');
      }

      header.style.backgroundImage = `url(${response.profile_header_image})`;
      avatar.style.backgroundImage = `url(${response.avatar})`;
    }

    fileField.value = null;

    // remove loader
    let busyContainer = form.parentNode.querySelector('.busy-container');

    if (busyContainer) {
      form.parentNode.removeChild(busyContainer);
    }
  }

  initImageUpload () {
    document.querySelectorAll('.user-profile .user-upload-image').forEach(form => {
      let fileField = form.querySelector('input[type="file"]');
      let busy = new Busy();

      function submitForm() {
        $(form).trigger('submit.rails');
        form.parentNode.prepend(busy.loader);
      }

      form.querySelector('.user-upload-image-pick').addEventListener('click', () => {
        fileField.click();
      });

      form.querySelector('.user-upload-image-remove').addEventListener('click', () => {
        form.querySelector('.delete-field').value = '1';
        submitForm();
      });


      fileField.addEventListener('change', () => {
        submitForm();
      });

      $(form).on('ajax:complete', (e, xhr) => {
        this.updateUserImages(form, xhr);
      });
    });
  }
}
