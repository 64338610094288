export class Subscriptions {
  constructor () {
    this.subscriptionList = document.querySelector('.subscription-list');
    this.subscriptionPromote = document.querySelector('.subscriptions-promote');
    this.subscriptionListExpand = document.querySelector('.subscription-list-expand');
    this.bindListeners();
    this.createContributionSliders();
  }

  createContributionSliders () {
    document.querySelectorAll('.subscription-feed-item .item-section-grid').forEach(list => {
      $(list).owlCarousel({
        mouseDrag: false,
        dots: false,
        nav: true,
        navText: [
          '<i class="material-icons">chevron_left</i>',
          '<i class="material-icons">chevron_right</i>'
        ],
        slideBy: 2,
        responsive: {
          0: { items: 2 },
          640: { items: 3 },
          860: { items: 4 },
          1000: { items: 5 }
        }
      });
    });
  }

  checkSubscriptionPromotion () {
    let heading = this.subscriptionList.parentNode.parentNode.querySelector('.sidebar-section-heading');

    if (this.subscriptionList.childElementCount > 0) {
      this.subscriptionList.classList.remove('hide');
      this.subscriptionPromote.classList.add('hide');
      let link = document.createElement('a');
      link.href = heading.getAttribute('data-url');
      link.innerText = heading.innerText;
      heading.innerHTML = '';
      heading.appendChild(link);
    } else {
      this.subscriptionList.classList.add('hide');
      this.subscriptionPromote.classList.remove('hide');
      heading.innerText = heading.querySelector('a').innerText;
    }
  }

  addSubscription (type, id, url, title, imageUrl) {
    let subscriptionItem = document.createElement('li');
    let link = document.createElement('a');
    let image = document.createElement('div');

    subscriptionItem.setAttribute('data-id', id);
    subscriptionItem.setAttribute('data-type', type);
    subscriptionItem.className = 'subscription-item';
    image.className = 'subscription-image';
    image.style.backgroundImage = `url(${imageUrl})`;
    link.href = url;
    link.appendChild(image);
    link.appendChild(document.createTextNode(title));

    subscriptionItem.appendChild(link);
    this.subscriptionList.prepend(subscriptionItem);
    this.orderSubscriptionList();
    this.checkSubscriptionPromotion();
  }

  removeSubscription (type, id) {
    let subscriptionItem = this.subscriptionList.querySelector(`li[data-id="${id}"][data-type="${type}"]`);

    if (subscriptionItem){
      this.subscriptionList.removeChild(subscriptionItem);
      this.checkSubscriptionPromotion();
    }
  }

  orderSubscriptionList () {
    let sorted = [].slice.call(this.subscriptionList.children).sort((a, b) => {
      if (a.innerText.toLowerCase().trim() < b.innerText.toLowerCase().trim()) { return -1; }
      if (a.innerText.toLowerCase().trim() > b.innerText.toLowerCase().trim()) { return 1; }
      return 0;
    });

    sorted.forEach(element => this.subscriptionList.appendChild(element));
  }

  bindListeners () {
    $(document).on('ajax:before', '.subscription-btn', (e) => {
      e.currentTarget.classList.add('disabled');
    });

    $(document).on('ajax:success', '.subscription-btn', (e, data) => {
      let element = e.currentTarget, subscriptionList, subscriptionItem;

      // Find wrapping element, so we can replace it
      while ((element = element.parentElement) && !element.classList.contains('subscribe-control')) {}
      element.outerHTML = data.control_html;

      if (data.added) {
        this.addSubscription(data.container_type, data.container_id, data.container_url,
          data.container_title, data.container_image);
      } else {
        this.removeSubscription(data.container_type, data.container_id);
      }
    });

    this.subscriptionListExpand && this.subscriptionListExpand.addEventListener('click', (e) => {
      e.preventDefault();
      this.subscriptionListExpand.classList.add('hidden');
      this.subscriptionList.classList.add('subscription-list-expanded');
      this.orderSubscriptionList();
    });
  }
}
