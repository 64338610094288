import Vue from 'vue';
import App from './components/app.vue';
import store from '../store';
import { TwoFactorModule, ActionTypes } from './store';

export let app: Vue = null;

export function init() {
  const el: HTMLElement = document.getElementById('v-two-factor-setup');
  const otpSecret = el.dataset.otpSecret;
  const activateUrl = el.dataset.activate;
  const sendOtpUrl = el.dataset.sendOtp;
  const email = el.dataset.email;

  store.registerModule('twoFactor', TwoFactorModule);
  store.dispatch(ActionTypes.INIT, { otpSecret, activateUrl, sendOtpUrl, email });

  app = new Vue({
    el,
    store,
    i18n: VERSTEHE.vueI18n,
    render: h => h(App)
  })
}
