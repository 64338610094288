import { Take, Slideshow, SelectionMode, Clip, ResultPagination, Settings, Image } from '../interfaces';
import { v4 as uuid } from 'uuid';
import {
  CreationDateFilter,
  DurationFilter,
  MediaLibraryFilter,
  NameContainsFilter,
  ResolutionFilter,
  ResourceSourceFilter,
  ResourceTypeFilter,
} from '../filter/media_library_filter';

interface MediaState<MediaType> {
  results: Array<MediaType>;
  totalEntries: number;
  route: string;
  infinityId: string;
}

export interface LibraryState {
  brandUrl: string;
  apiBaseUrl: string;
  uploadUrl: string;
  mediaTypes: Array<'Take' | 'Document' | 'Clip' | 'Image'>;
  allMedia: MediaState<Take | Clip | Slideshow | Image>;
  privateTakes: MediaState<Take>;
  publicTakes: MediaState<Take>;
  documents: MediaState<Slideshow>;
  clips: MediaState<Clip>;
  images: MediaState<any>;
  uploadedTakes: Array<Take>;
  activeTab: string;
  selectedTakes: Array<number>;
  selectedSlides: Array<number>;
  selectedImages: Array<number>;
  inspectItem: Take | Slideshow | Image;
  selectionMode: SelectionMode;
  settings: Settings;
  showShareAndDeleteButton: boolean;
  nameFilter: NameContainsFilter;
  durationFilter: DurationFilter;
  resolutionFilter: ResolutionFilter;
  creationDateFilter: CreationDateFilter;
  resourceTypeFilter: ResourceTypeFilter;
  resourceSourceFilter: ResourceSourceFilter;
  activeFilters: MediaLibraryFilter[];
  filterTotalResults: number;
}

export const state: LibraryState = {
  brandUrl: null,
  uploadUrl: null,
  apiBaseUrl: null,
  mediaTypes: ['Take', 'Document', 'Clip', 'Image'],
  allMedia: {
    results: [],
    totalEntries: null,
    route: 'all_media',
    infinityId: uuid(),
  },
  privateTakes: {
    results: [],
    totalEntries: null,
    route: 'private_takes',
    infinityId: uuid(),
  },
  publicTakes: {
    results: [],
    totalEntries: null,
    route: 'approved_takes',
    infinityId: uuid(),
  },
  documents: {
    results: [],
    totalEntries: null,
    route: 'documents',
    infinityId: uuid(),
  },
  clips: {
    results: [],
    totalEntries: null,
    route: 'clips',
    infinityId: uuid(),
  },
  images: {
    results: [],
    totalEntries: null,
    route: 'images',
    infinityId: uuid(),
  },
  uploadedTakes: [],
  activeTab: 'privateTakes',
  selectedTakes: [],
  selectedSlides: [],
  selectedImages: [],
  inspectItem: null,
  selectionMode: SelectionMode.Multiple,
  showShareAndDeleteButton: false,
  settings: {
    releaseTakeRole: null,
    canCreateTopics: false,
    createClipUrl: null,
    reviewSharedTakes: false,
    shouldReviewSharedTakes: false,
  },
  nameFilter: new NameContainsFilter(),
  durationFilter: new DurationFilter(),
  resolutionFilter: new ResolutionFilter(),
  creationDateFilter: new CreationDateFilter(),
  resourceTypeFilter: new ResourceTypeFilter(),
  resourceSourceFilter: new ResourceSourceFilter(),
  activeFilters: [],
  filterTotalResults: 0,
};
