import { ChangeAction } from "../../system_actions/change_action";
var editor_change = function() {
  if (!this.wrapper[0].classList.contains('disable-user-input')) {
    TextAction.createAction(this.wrapper, this.value());
  }
};

/**
 * Represents a change in a text input element
 */
export class TextAction extends ChangeAction {
  /**
   * @constructor
   * @param {String} tabId  - TabId of the tab the action originates from
   * @param {number} user - UserId of the chat room owner
   * @param {String} element - Element thats subject of the UIAction
   * @param {String} newValue - New Value of the input element
   */
  constructor(tabId, user, element, newValue) {
    super(tabId, user, element);
    this.actionType = "TextAction";
    this.newValue = newValue;
  }

  execute() {
    if (this.element.is(".k-editor")) {
      let id = this.element[0].querySelector(".k-editor-toolbar").getAttribute("aria-controls");
      $(`#${id}`).data("kendoEditor").value(this.newValue);
    } else {
      $(this.element).val(this.newValue);
      $(this.element).change();
    }
  }

  isComparableTo(other) {
    if (this.actionType === other.actionType) {
      return this.element.is(other.element);
    } else {
      return false;
    }
  }

  reduce(other) {
    return this;
  }

  static fromJson(json) {
    let action = JSON.parse(json);
    return new TextAction(action.tabId, action.user, action.elementLocation, action.newValue);
  }

  static registerToElement() {
    $(document).on('input', '[data-collaborative-action*="TextAction"]', function(event) {
      TextAction.createAction(event.target, $(this).val());
    });
  }

  static registerToWYSIWYG(editor) {
    // keyup event is triggered through text changes
    editor.bind("keyup", editor_change);
    // select event is triggered after format changes
    editor.bind("select", editor_change);
  }

  static createAction(element, newValue) {
    let commonStart = 0;
    let action = new TextAction(TextAction.getTabId(), _app.info.user.userId, element, newValue);
    VERSTEHE.content_channel.send_message(action);
  }
}
