<template>
<div class="lesson-editor-form">
  <div class="lesson-details edit-custom-content-lesson">
    <text-input
      name="titleInput"
      v-model="title"
      :validate="{ required: true, max: 75 }"
      :label="$t('course.lesson_title')"></text-input>

    <label class="editor-label control-label">{{ $t('course.lesson_custom_content') }}</label>
    <textarea
      id="content-input"
      :value="content"
      name="contentInput"
      class="custom-content-editor"
      ></textarea>

    <div class="actions">
      <button v-on:click="setUiState('lessonList')" type="button" class="btn btn-primary btn-flat">{{ $t('course.cancel') }}</button>
      <button v-on:click="saveChanges" :class="{ disabled: errors.any() }" type="button" class="btn btn-primary">{{ $t('course.apply_changes') }}</button>
    </div>
  </div>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { TextInput, TextArea } from './form';

export default {
  name: 'external-lesson-form',
  inject: ['$validator'],
  data() {
    return {
      title: '',
      content: '',
      editor: null
    }
  },
  components: {
    TextInput,
    TextArea
  },
  computed: {
    ...mapGetters({
      subject: 'course/lessonEditorSubject',
      lesson: 'course/lessonEditorObject',
      lessonType: 'course/lessonEditorType',
      apiUrl: 'course/apiUrl'
    }),
    lessonAttributes() {
      return {
        title: this.title,
        type: this.lessonType,
        subject: {
          id: this.subject.id,
          type: this.subject.type,
          content: this.content
        }
      }
    }
  },
  mounted() {
    const baseUrl = this.apiUrl('baseUrl');
    const sanitizeContent = (html) => VERSTEHE.Globals.Sanitizer.sanitize(html, {
      FORBID_TAGS: ['form', 'button']
    });

    $('#content-input').kendoEditor({
      pasteCleanup: { custom: sanitizeContent },
      deserialization: { custom: sanitizeContent },
      serialization: { custom: sanitizeContent },
      resizable: true,
      tools: [
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "justifyLeft",
        "justifyCenter",
        "justifyRight",
        "justifyFull",
        "insertUnorderedList",
        "insertOrderedList",
        "indent",
        "outdent",
        "createLink",
        "unlink",
        "insertImage",
        "subscript",
        "superscript",
        "tableWizard",
        "createTable",
        "addRowAbove",
        "addRowBelow",
        "addColumnLeft",
        "addColumnRight",
        "deleteRow",
        "deleteColumn",
        "mergeCellsHorizontally",
        "mergeCellsVertically",
        "splitCellHorizontally",
        "splitCellVertically",
        "viewHtml",
        "formatting",
        "cleanFormatting",
        "fontName",
        "fontSize"
      ],
      imageBrowser: {
        transport: {
          read: baseUrl + '/image_browser/read',
          thumbnailUrl: baseUrl + '/image_browser/thumbnail',
          uploadUrl: baseUrl + '/image_browser/upload',
          imageUrl: baseUrl + '/image_browser/image?path={0}',
          destroy: {
            url: baseUrl + '/image_browser/destroy',
            type: "POST"
          }
        }
      },
      change: () => {
        this.content = $("#content-input").data('kendoEditor').value();
      }
    });
  },
  methods: {
    ...mapActions({
      setUiState: 'course/setUiState',
      saveEditorLesson: 'course/saveEditorLesson'
    }),
    saveChanges() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.saveEditorLesson(this.lessonAttributes);
        }
      });
    }
  },
  watch: {
    lesson: {
      handler: function(lesson) {
        if (lesson.title && lesson.title.length > 0) {
          this.title = lesson.title;
        }

        this.description = lesson.description || '';

        if (lesson.subject) {
          this.content = lesson.subject.content;
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.lesson-editor-form {
  max-width: 800px;
  margin: 0 auto;
}

.actions {
  text-align: right;
  margin-top: 50px;
}

.editor-label {
  margin-top: 40px;
  font-weight: initial;
}
</style>
