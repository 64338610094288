import { BaseTree } from '../../taxonomy/base_tree';
import { localization } from '../../taxonomy/locals';
/** Class representing a tree select element */
export class Tree extends BaseTree {
  /**
   * @constructor
   * @param {string} type - Type of the tree select
   */
  constructor (bar, type, title) {
    super();
    this.type = type;
    this.filterBar = bar;
    this.title = title;
    // DOM Elements
    this._dialog = document.querySelector(`#dialog_${type}`);
    this._wrapper = this.filterBar.activeFilterWrapper;
    this._filterControl = this._dialog.querySelector('.filter-tree-control');
    this._treeControl = document.querySelector(`#tree_${type}`);
    this.fetchUrl = this._treeControl.getAttribute('data-url');
    this.isMultiSelect = this._treeControl.getAttribute('data-multiple') === 'true';
    this.localization = localization[_app.info.currentLocale];
    this.typingTimeout = null;
    this.temporaryState = false;
    this._loading = false;
    this.ready = false;

    // Build tree
    this.treeView = this.initializeTree();
    this.initializeFilter();
    this.initializeTreeActions();
    this.initializeApplyTreeButton();
  }

  initialState (e) {
    super.initialState(e);
    // If node is undefined, data was bound at root level
    if (!e.node && !this.temporaryState) {

      if(!this._treeControl.getAttribute('data-close-all')) {
        this.expandAll();
      }

      // Set a flaq for temp changes in the tree
      this.ready = true;
      
      if (this.onready && typeof this.onready === 'function') {
        this.onready();
      }
    }
  }

  setSelectedElementsHelper(elements) {
    this.ids = elements.map(v => v.id);
    this.removeUncheckedLabels();
    let labels = [];

    elements.forEach(function(element) {
      if(!this._wrapper.querySelector('div[data-id="' + element.id + '"]')) {
        let label = this.filterBar.label.createLabelforTree(element, this.type);
        label.getElementsByTagName('i')[0].addEventListener('click', (e) => this.reset(e));

        labels.push(label);
      }
    }, this);

    this.filterBar.addActiveFilters(labels);
  }

  reset(e) {
    let label = e.target.parentElement.parentElement;
    let node = this.dataSource.get(label.getAttribute('data-id'));
    node.set('checked', false);
    let selectedElements = this.getChecked(null);
    this.setSelectedElementsHelper(selectedElements);
  }

  removeUncheckedLabels() {
    let labels = this._wrapper.querySelectorAll(`.analytics-filter-label.${this.type}`);
    let mustRemoved = [];
    for (let i = 0; i < labels.length; i++) {
      if (this.ids.indexOf(parseInt(labels[i].getAttribute('data-id'), 10)) === -1 ) {
        mustRemoved.push(labels[i]);
      }
    }

    if (mustRemoved.length > 0) {
      this.filterBar.removeActiveFilters(mustRemoved, false);
    }
  }

  findNodes (query, data = { items: this.data }) {
    let nodes = [];
    query = query.toLowerCase();

    if (data.items) {
      for (let i = 0; i < data.items.length; i++) {
        if (data.items[i].title.toLowerCase().indexOf(query) !== -1 &&
          !this.dataSource.get(data.items[i].id).checked) {
          nodes.push({ id: data.items[i].id, title: data.items[i].title, source: this });
        }
        nodes = nodes.concat(this.findNodes(query, data.items[i]));
      }
    }

    return nodes;
  }

  refreshCheckedIds() {
    this.ids = this.getChecked().map(v => v.id);
  }

  search(searchString) {
    return this.findNodes(searchString);
  }

  deactivateFilter (id) {
    let node = this.dataSource.get(id);
    node.set('checked', false);
  }

  removeActiveChildLabels (node) {
    this.refreshCheckedIds();
    this.removeUncheckedLabels();
  }

  bindSearchResultClickListener (element) {
    element.addEventListener('click', (e) => {
      let label = this.filterBar.label.createLabelforSearchResult(e.target, this.type, this);
      let node = this.dataSource.get(label.getAttribute('data-id'));
      node.set('checked', true);
      this.removeActiveChildLabels(node);
      this.filterBar.addActiveFilters([label]);     
      this.filterBar.element.value = "";
      this.filterBar.fieldSelect.close();
    });
  }
}
