import { KeyFigure } from './key_figure';
import { ListFigure } from './list_figure';
import { Chart } from './chart';
import { Busy } from '../../globals/busy';

const widgets = {
  KeyFigure,
  ListFigure,
  Chart
};

export class Container {
  constructor(element, dashboard) {
    this.container = element;
    this.id = this.container.getAttribute('id');
    this.widgets = {};
    this.widgetKeys = [];
    this.busy = new Busy();
    this.url = this.container.getAttribute('data-url');
    this.dashboard = dashboard;
    this.lastResults = null;
    this.chartArea = this.container.parentNode.querySelector('#chart-area');

    this.initWidgets();
    this.loadWidgets();
  }

  initWidgets() {
    this.container.querySelectorAll('.widget').forEach( e => {
      this.widgets[e.getAttribute('id')] = new widgets[e.getAttribute('data-type')](e, this);
    });

    this.widgetKeys = Object.keys(this.widgets);
  }

  loadWidgets() {
    this.container.parentNode.appendChild(this.busy.loader);
    $.get(this.url, this.buildParams(), (response) => {
      this.lastResults = response;

      if (typeof response === "object" ) {
        Object.keys(this.widgets).forEach(k => {
          this.widgets[k].load(this.lastResults[k]);
        });
      } else {
        Object.keys(this.widgets).forEach(k => {
          this.widgets[k].load(this.lastResults);
        });
      }

      this.container.parentNode.removeChild(this.busy.loader);
    }).fail( function() {
      this.container.parentNode.removeChild(this.busy.loader);
    }.bind(this));
  }

  buildParams() {
    let params = this.dashboard.filter;
    params['widgets'] = this.widgetParam();
    params['token'] = this.dashboard.contribution_token;

    return params;
  }

  widgetParam() {
    let widgetParams = {};
    this.widgetKeys.forEach(w => {
      widgetParams[w] = this.widgets[w].buildParam();
    });

    return widgetParams;
  }

  activatedWidget() {
    let activeWidget = null;
    this.widgetKeys.forEach(w => {
      if (this.widgets[w].active) {
        activeWidget = this.widgets[w];
      }
    });

    return activeWidget;
  }

  readContainerTitle() {
    let element = this.container.parentNode.querySelector('.title');
    let title = "";
    if (element) {
      title = element.innerHTML;
    } else {
      title = this.activatedWidget().element.querySelector('.key').innerHTML;
    }

    return title;
  }

}
