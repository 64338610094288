import { UIAction } from "../../system_actions/ui_action";

/**
 * MouseActions are Actions triggered by MouseEvents of Users
 * MouseActions are never queued!
 */
export class MouseAction extends UIAction {
  /**
   * @constructor
   * @param {String} tabId  - TabId of the tab the action originates from
   * @param {number} user - UserId of the chat room owner
   * @param {String} element - Element thats subject of the UIAction
   * @param {number} x - Horizontal coordinates of the MouseAction
   * @param {number} y - Vertical coordinates of the MouseAction
   */
  constructor(tabId, user, element, x, y) {
    super(tabId, user, element);
    this.x = x;
    this.y = y;
    /** Check if inheriting class implements execute() method */
    if (this.execute === undefined) {
      throw new TypeError("Must implement execute");
    }
  }
}
