import Vue from 'vue';
import App from './components/app';
import store from '../store';
import userOnboardingStore from './store';
import tutorialsStore from '../tutorials/store';
import { Actions } from './store';

export let app = null;
export let dialog = null;

export function init() {
  const el = document.getElementById('user-onboarding');

  if (el && el.dataset.tutorials !== 'null') {
    const onboardingType = el.dataset.type;

    store.registerModule('tutorials', tutorialsStore);
    store.registerModule('userOnboarding', userOnboardingStore);
    store.dispatch('tutorials/setTutorialVideos', { tutorialVideos: JSON.parse(el.dataset.tutorials) });
    store.dispatch(Actions.SET_ONBOARDING_TYPE, onboardingType);
    store.dispatch(Actions.SET_ONBOARDING_HIDE_URL, el.dataset.hide);

    app = new Vue({
      store,
      el,
      i18n: VERSTEHE.vueI18n,
      render: h => h(App)
    });

    dialog = $(app.$el).kendoDialog({
      closable: true,
      modal: true,
      width: '800px',
      title: app.$i18n.t(`user_onboarding.${onboardingType}_title`),
      actions: [],
      hide: () => app.$destroy()
    }).data('kendoDialog');
  }
}
