import { ChangeAction } from "../../system_actions/change_action";

/**
 * DatePickerActions track the changes of Kendo UI DatePicker
 * Multiple DatePickerAction can be reduced to one when they regard the same element
 */
export class DatePickerAction extends ChangeAction {
  // /**
  //  * @constructor
  //  * @param {String} tabId  - TabId of the tab the action originates from
  //  * @param {number} user - UserId of the chat room owner
  //  * @param {String} element - Element thats subject of the UIAction
  //  * @param {array} value - value of the datepicker
  //  */
  constructor(tabId, user, element,  value) {
    super(tabId, user, element);
    this.value = value;
    this.actionType = "DatePickerAction";
  }

  execute() {
    let datePickerElement = $(this.element).data("kendoDatePicker");
    datePickerElement.value(this.value);
    datePickerElement.trigger("manualChange");
  }

  isComparableTo(other) {
    return this.element.is(other.element);
  }

  reduce(other, last) {
    return this;
  }

  static fromJson(json) {
    let action = JSON.parse(json);
    return new DatePickerAction(action.tabId, action.user, action.elementLocation, action.value);
  }

  static registerToElement(wrapper) {
    let selector = ((!wrapper) ? '[data-collaborative-action*="DatePickerAction"]:not(#publish [data-collaborative-action*="DatePickerAction"])' : wrapper + ' [data-collaborative-action*="DatePickerAction"]');
    $(selector).each(function() {
      var datepicker = $(this).data("kendoDatePicker");
      datepicker.bind("change", (e) => {
        let value = e.sender.value();
        // adjusts the Date by the TimeZoneOffset or sends null when the date has been cleared
        value = !value ? value : new Date(value.getTime() - (value.getTimezoneOffset() * 60000));

        DatePickerAction.createAction(DatePickerAction.getTabId(), _app.info.user.userId, $(this), value);
      });
    });
  }

  static createAction(tabId, user, element, value) {
    let action = new DatePickerAction(tabId, user, element, value);
    action.element = null;
    VERSTEHE.content_channel.send_message(action);
  }

}
