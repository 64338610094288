<template>
  <div class="tutorials">
    <breadcrumb />

    <div class="tutorials-body">
      <navigation />
      <router-view class="tutorials-content"></router-view>
    </div>
  </div>
</template>

<script>
import Breadcrumb from './breadcrumb.vue';
import Navigation from './navigation.vue';

export default {
  components: {
    Breadcrumb,
    Navigation
  }
}
</script>

<style lang="scss" scoped>
  .tutorials {
    position: absolute;
    left: 0;
    top: 60px;
    right: 0;
    bottom: 0;
  }

  .tutorials-body {
    display: flex;
    height: calc(100% - 80px);
    overflow: hidden;
  }

  .tutorials-content {
    flex-grow: 1;
    height: 100%;
    overflow-y: auto;
  }
</style>
