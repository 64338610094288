import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export const initialState = {
  uploadUrl: null,
  settingsUrl: null,
  mappingsUrl: null,
  reportUrl: null,
  containsHeadings: false,
  columnSeperator: 'semicolon',
  importAction: 'create',
  file: null,
  status: 'new',
  step: 'upload',
  saving: false,
  id: null,
  info: {},
  columnGuesses: [],
  availableFields: [],
  mappings: []
};

export const Actions = {
  SET_UPLOAD_URL: 'userImport/setUploadUrl',
  UPDATE_FILE: 'userImport/updateFile',
  UPLOAD_FILE: 'userImport/uploadFile',
  SAVE_SETTINGS: 'userImport/saveSettings',
  UPDATE_MAPPING: 'userImport/updateMapping',
  SAVE_MAPPINGS: 'userImport/saveMappings'
};

export default {
  namespaced: true,
  state : initialState,
  mutations,
  actions,
  getters
};
