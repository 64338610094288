import DOMPurify from 'dompurify';

// Secure target="_blank" Links
// see https://web.dev/external-anchors-use-rel-noopener/
DOMPurify.addHook('afterSanitizeAttributes', node => {
  if ('target' in node && node.target) {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener');
  }
});

export default {
  sanitize(text, options = {}) {
    let sanitizedText = DOMPurify.sanitize(text, {
      ...options,
      ADD_ATTR: ['target', ...(options.ADD_ATTR || [])]
    });

    if (!sanitizedText || sanitizedText.length === 0) {
      // Kendo Editor will use the original text if an empty string
      // is returned, thus return a space.
      sanitizedText = ' ';
    }

    return sanitizedText;
  }
};
