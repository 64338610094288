/**
 * const counter = (state = 0, action) =>
 *   switchcase({
 *     'INCREMENT': () => state + 1,
 *     'DECREMENT': () => state -1
 *   })(() => state)(action.type)
 * @param {*} cases
 */
export const switchcase = cases => defaultCase => key => cases[key] ? cases[key] : defaultCase;

// Getting a parameter value from the URL
// new URLSearchParams(...) will not work in IE this is a small workaround
export const getURLParameter = (name) => {
  return decodeURIComponent(
      (
      new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search) || [null, '']
    )[1].replace(/\+/g, '%20')
  ) || 0;
};

export const toggleVisibility = (element) => {
  if(element.style.display === 'none') {
    element.style.display = 'block';
  } else {
    element.style.display = 'none';
  }
};

export const entries = (object) => {
  if (object === undefined) { return []; }
  let keys = typeof object !== "string" ? Object.keys(object) : null;
  return keys ? keys.map(e => [e, object[e]]) : [];
};
