<template>
<div class="course-lesson">
  <div class="course-lesson-title">{{ $t('course.evaluation_sheet') }}</div>
  <loading-spinner v-show="!isLoaded"></loading-spinner>

  <div class="feedback-content" ref="feedbackContent"></div>
</div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('coursePerform');

import { checkFetchStatus } from '../../utils';
import LoadingSpinner from '../../../globals/loading_spinner.vue';

export default {
  name: 'feedback',
  components: {
    LoadingSpinner
  },
  data() {
    return {
      isLoaded: false
    }
  },
  computed: {
    ...mapGetters([
      'course'
    ])
  },
  created() {
    fetch(this.course.evaluationUrl, { credentials: 'include' })
      .then(response => response.text())
      .then(response => {
        const html = response;

        this.$refs.feedbackContent.innerHTML = response;
        this.isLoaded = true;

        VERSTEHE.courses_explore.initRaty()
        VERSTEHE.courses_explore.initSlider();
      });
  }
}
</script>

<style lang="scss" scoped>
.course-lesson {
  position: relative;
}

.feedback-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 24px 0;
}
</style>
