export class BooleanFilter {
  constructor(filterBar) {
    this.filterBar = filterBar;
  }

  addQuickLink(quick, link) {
    let label = this.filterBar.label.createLabelforBool(quick.title, quick.id);

    link.addEventListener('click', () => {
      this.filterBar.addActiveFilter(label);
      this.filterBar.fieldSelect.close();
    });

    return link;
  }
}
