import Vue from 'vue';
import Component from 'vue-class-component'
import { TemplateSlide } from '../../interfaces';
import { Getters } from '../../store/getters';

@Component
export class CurrentSlideMixin extends Vue {
  get slideKey() {
    return this.$route.name;
  }

  get slide(): TemplateSlide {
    return this.$store.getters[Getters.SLIDE](this.slideKey);
  }

  get hasImage() {
    return this.slide.image !== null && typeof this.slide.image !== 'undefined';
  }
}
