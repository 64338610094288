import { Application } from './app';
import EventEmitter from '../common/event_emitter';

class RecorderService extends EventEmitter {
  
  private recorder = null;

  constructor() {
    super();
  }

  get recorderInstance() {
    if (this.recorder === null) {
      this.recorder = new Application();
    }

    return this.recorder;
  }
}

export default new RecorderService();
