const i18n = {
  'de': {
    'noResults': 'Es wurden keine passenden Daten gefunden!',
    'errorMessage': 'Uups, da ist wohl etwas schief gelaufen',
    'disabled': 'Dieses Element ist nicht Verfügbar!'
  },
  'en': {
    'noResults': 'No matching data found!',
    'errorMessage': 'Oops, there`s probably something went wrong',
    'disabled': 'This element is not available!'
  },
  'es': {
    'noResults': 'No se han encontrado datos coincidentes!',
    'errorMessage': 'Oops, probablemente algo salió mal.',
    'disabled': 'Este elemento no está disponible!'
  }
};

export class Messager {
  constructor(element) {
    this.element = element;
  }

  renderMessage(key) {
    this.element.innerHTML = this.messageTemplate(key);
  }

  messageTemplate(key) {
    return '<div class="no-results" id="noResults"><div class="message">' + i18n[_app.info.currentLocale][key] + '</div></div>';
  }

}
