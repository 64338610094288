// Videojs Plugin for Scene Markers in Timeline

const Plugin = videojs.getPlugin('plugin');

export class SceneMarkerPlugin extends Plugin {
  constructor(player, options) {
    super(player, options);

    this.onSelectCb = options.onSelect;
    this.player = player;
    this.scenes = {};

    if (options.scenes) {
      this.scenes = options.scenes;
    }

    this.player.on('loadedmetadata', () => {
      this.markers = this.createMarkersWrapper();
      this.duration = this.player.duration();
      this.insertMarkers();
    });
  }

  createMarkersWrapper() {
    if (this.markers) {
      return this.markers;
    }

    const markersWrapper = document.createElement('div');
    markersWrapper.className = 'vjs-scene-markers';

    const SceneMarkersComponent = {
      name: () => {
        return 'SceneMarker';
      },
      el: () => {
        return markersWrapper;
      }
    };

    this.player.controlBar.progressControl.addChild(SceneMarkersComponent);
    return markersWrapper;
  }

  insertMarkers() {
    for (let i = 0; i < this.scenes.length; i++ ) {
      this.markers.appendChild(this.createMarker(this.scenes[i].startingSecond, this.scenes[i].title));
    }
  }

  createMarker(start, title) {
    const marker = document.createElement('div');
    const relativePosition = (parseFloat(start) / this.duration) * 100;
    marker.classList.add('vjs-scene-marker');
    marker.style.left = `${relativePosition}%`;

    const innerMarker = document.createElement('div');
    innerMarker.classList.add('vjs-scene-marker-inner');

    const label = document.createElement('div');
    label.classList.add('vjs-scene-marker-label');
    const date = new Date(null);
    date.setSeconds(start);
    const timeString = date.toISOString().substr(11, 8);
    label.innerHTML = `(${timeString}) ${title}`;

    marker.appendChild(label);
    marker.appendChild(innerMarker);
    
    this.bindMarkerListeners(marker, start);

    return marker;
  }

  bindMarkerListeners(marker, start) {
    marker.addEventListener('mouseup', (e) => {
      e.preventDefault();
      e.stopPropagation();

      this.player.currentTime(start);
      this.player.currentTime();

      if (typeof this.onSelectCb === "function") {
        this.onSelectCb(start);
      }
    });

    marker.addEventListener('mouseenter', (e) => {
      this.player.controlBar.addClass('hovering-marker');
    });

    marker.addEventListener('mouseleave', (e) => {
      this.player.controlBar.removeClass('hovering-marker');
    });
  }
}
