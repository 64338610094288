import { t } from './localization';

export class PrivateSelect {

  static ICON_AVATAR(type, icon) { 
    return `<i class="ps-avatar ps-icon ps-${ type } material-icons">${ icon }</i>`; 
  }

  static USER_AVATAR(type, url) { 
    return `<img class="ps-avatar ps-${ type }" src="${ url }"></img>`; 
  }

  constructor (selector = '#private-select', selectedIdsContainer = '#selected-private-releases', preselectedSelector = '#initial-private-releases') {
    this.selector = selector;
    this._selectedIdsContainer = document.querySelector(selectedIdsContainer);

    this.el = document.querySelector(this.selector);

    if (this.el) {
      if (preselectedSelector != null) {
        this.preselected = JSON.parse(document.querySelector(preselectedSelector).getAttribute('data-preselected'));
      } else {
        this.preselected = [];
      }

      this.initPrivateSelect();
    }
  }

  initPrivateSelect() {
    const url = this.el.getAttribute('data-search-url');

    this.privateSelect = $(this.el).kendoMultiSelect({
      autoBind: true,
      placeholder: t('private_select_placeholder'),
      dataTextField: 'name',
      dataValueField: 'value_string',
      height: 400,
      delay: 800,
      fixedGroupTemplate: this.groupFixedTitleTemplate.bind(this),
      groupTemplate: this.groupTitleTemplate.bind(this),
      itemTemplate: data => this.itemTemplate(data, 'item'),
      tagTemplate: data => this.itemTemplate(data, 'tag'),
      change: this.onChange.bind(this),
      dataSource: {
        transport: {
          read: (options) => {
            $.get(url, options.data, (response) => {
              // add preselected values when search string is empty
              if(!(options.data.filter && options.data.filter.filters.length)){
                // merge both arrays without duplicates
                response = [...this.preselected, ...response].reduce((unique, o) => {
                  if(!unique.some(obj => obj.name === o.name)) {
                    unique.push(o);
                  }
                  return unique;
                },[]);
              }
              options.success(response);
            });
          }
        },
        schema: {
          data: (response) => {
            return response;
          }
        },
        serverFiltering: true,
        group: { field: 'type'}
      },
      value: this.preselected
    }).data('kendoMultiSelect');
    this.privateSelect.bind("manualChange", this.onChange.bind(this));

    // Catch paste event to parse users
    this.privateSelect.input[0].addEventListener('paste', this.resolvePastedUsers.bind(this));
  }

  resolvePastedUsers(event) {
    event.preventDefault();

    const url = this.el.getAttribute('data-resolve-url');
    const clipboardText = (event.clipboardData || window.clipboardData).getData('text');
    const users = clipboardText
      .split(';')
      .map(s => s.trim())
      .filter(s => s.length !== 0);

    this.privateSelect.enable(false);

    $.post(url, { usernames: users }, response => {
      let selection = this.privateSelect.value();
      for (let i = 0; i < response.length; i++) {
        // Do not add to dataSource if already exists
        if (!this.privateSelect.dataSource.get(response[i].id)) {
          this.privateSelect.dataSource.add(response[i]);
        }

        selection.push(response[i].value_string);
      }

      this.privateSelect.enable(true);
      this.privateSelect.value(selection);
    });
  }

  enable(enabled = tru) {
    if (this.privateSelect) {
      this.privateSelect.enable(enabled);
    }
  }

  getValues() {
    return this.privateSelect.value();
  }
  
  itemTemplate(data, type) {
    let image;
    const template = kendo.template('#= image #<span class="ps-label ps-#= type #">#: name #</span>');

    if (data.type === 'user') {
      image = data.avatar ? PrivateSelect.USER_AVATAR(type, data.avatar) : PrivateSelect.ICON_AVATAR(type, 'person');
    } else {
      image = PrivateSelect.ICON_AVATAR(type, 'group');
    }

    return template({
      image: image,
      type: type,
      name: data.name
    });
  }

  groupTitleTemplate(title) {
    return t(title);
  }

  groupFixedTitleTemplate(title) {
    return `<span class="fixed-head">${t(title)}</span>`;
  }

  onChange() {
    this.fillReleasesFields();
  }

  fillReleasesFields() {
    const userIdsTagName = this._selectedIdsContainer.getAttribute('data-user-field-name');
    const targetGroupIdsTagName = this._selectedIdsContainer.getAttribute('data-target-groups-field-name');

    const values = this.privateSelect.value().map(val => {
      const splitted = val.split(', ');
      const hash = {
        id: splitted[0],
        name: ((splitted[1] === 'user') ? userIdsTagName : targetGroupIdsTagName)
      };
      
      return hash;
    });

    this._selectedIdsContainer.innerHTML = '';
    
    for(let i = 0; i < values.length; i++) {
      const tag = this.buildFormTag(values[i].id, values[i].name);
      this._selectedIdsContainer.appendChild(tag);
    }
  }

  buildFormTag(id, name) {
    let input = document.createElement('input');
    input.hidden = true;
    input.name = name;
    input.value = id;

    return input;
  }
}

