export class EmbedPreview {
  constructor() {
    this.bindListener();
  }

  buildURL(key, value, content) {
    let $code = null;
    if (content) {
      $code = content;
    } else {
      let text = $('#embed-code').val();
      $code  = $(text);
    }

    let urlString = $code.attr('src'),
        url = new URL(urlString),
        params = new URLSearchParams(url.search);

    params.set(key, value);
    url.search = params.toString();
    $code.attr('src', url.toString());

    return $code;
  }

  setHeightAndWidth(value) {
    let $code  = $($('#embed-code').val()),
        width  = value.split('x')[0].trim(),
        height = value.split('x')[1].trim();

    $code.attr({ 'width': width, 'height': height });
    $code = this.buildURL('width', width, $code);
    $code = this.buildURL('height', height, $code);

    return $code;
  }

  setLang(value) {
    let $code = $($('#embed-code').val()),
        src =  $code.attr('src');
    src = src.replace(/\/de\/|\/en\/|\/es\//g, '/' + value + '/');
    $code.attr('src', src);

    return $code;
  }

  getTextAreaContent(content) {
    return content[0].outerHTML.replace(/&amp;/g,'&');
  }

  bindRangeListener() {
    $('#embed-data-range').on('change', function(e) {
      let content = this.buildURL('dim', $(e.target).val());
      
      $('#embed-code').val(this.getTextAreaContent(content));
      $('#embed-preview').html(content[0]);
    }.bind(this));
  }

  bindValueListener() {
    $('#embed-data-range-value').on('change', function(e) {
      let content = this.buildURL('value', $(e.target).val());
      
      $('#embed-code').val(this.getTextAreaContent(content));
      $('#embed-preview').html(content[0]);
    }.bind(this));
  }

  bindResizeListener() {
    $('#embed-size').on('change', function(e) {
      let content = this.setHeightAndWidth($(e.target).val());
  
      $('#embed-code').val(this.getTextAreaContent(content));
      $('#embed-preview').html(content[0]);
    }.bind(this));
  }

  bindLangListener() {
    $('#embed-lang').on('change', function(e) {
      let content = this.setLang($(e.target).val());

      $('#embed-code').val(this.getTextAreaContent(content));
      $('#embed-preview').html(content[0]);
    }.bind(this));
  }

  bindListener() {
    $('#embed_reporting_modal').on('shown.bs.modal', function() {    
      this.bindResizeListener();
      this.bindRangeListener();
      this.bindValueListener();
      this.bindLangListener();
    }.bind(this));
  }
}
