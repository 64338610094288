import { columnConfiguration} from './column_configuration';
import { BulkActions } from './bulk_actions';
import { Filter } from './filter';
import { ColumnSelect } from './column_select';
import { visibilityFilterMenu, booleanFilterMenu } from './filter_menu';

const GRID_COOKIE_KEY = `grid_options_${_app.info.siteId}_${_app.info.user.userId}`;
export class ContributionGrid {
  constructor () {
    this.gridElement = document.querySelector('.contribution-grid');

    if (this.gridElement) {
      this.endpointUrl = this.gridElement.getAttribute('data-endpoint');
      this.filterElement = this.gridElement.querySelector('.contribution-grid-filter');
      this.itemsElement = this.gridElement.querySelector('.contribution-grid-items');
      this.selected = [];
      this.bulkActions = new BulkActions(this);

      this.initDataSource();
      this.initKendoGrid();
      this.bindListeners();

      this.filter = new Filter(this);
      this.columnSelect = new ColumnSelect(this);
    }
  }

  prepareReadParams (options) {
    let params = {};

    if (options.data.page) {
      params['page'] = options.data.page;
    }

    if (options.data.pageSize) {
      params['per_page'] = options.data.pageSize;
    }

    if (options.data.sort) {
      params['sort_field'] = options.data.sort[0].field;
      params['sort_dir'] = options.data.sort[0].dir;
    }

    if (options.data.filter) {
      params['filter'] = options.data.filter.filters || [];
    }

    return params;
  }

  read (options) {
    $.ajax({
      url: this.endpointUrl,
      dataType: 'json',
      data: this.prepareReadParams(options),
      success: options.success,
      error: options.error
    });
  }

  selectAll () {
    let oldPageSize = this.dataSource.pageSize();

    let finished = (e) => {
      this.kendoGrid.select('tr');
      this.dataSource.pageSize(oldPageSize);
      this.dataSource.unbind('change', finished);
    };

    this.dataSource.bind('change', finished);
    this.dataSource.pageSize(this.dataSource.total());
  }

  getTableRow (token) {
    let uid = this.dataSource.get(token).uid;
    return this.gridElement.querySelector(`tr[data-uid="${uid}"]`)
  }

  removeFromList (token) {
    let row = this.getTableRow(token);
    this.kendoGrid.options.editable = { mode: 'incell', confirmation: false };
    this.kendoGrid.removeRow(row);
    this.kendoGrid.options.editable = { mode: 'inline', confirmation: true };
  }

  destroy (options) {
    let url = options.data.actions.delete;

    $.ajax({
      url: url,
      dataType: 'json',
      method: 'DELETE',
      success: options.success,
      error: (response) => {
        this.dataSource.cancelChanges();
        options.error(response);

        if (parseInt(response.status, 10) === 409) {
          let modal = $('#contribution_destroy_strategy');

          $.get(options.data.actions.delete_conflict, (data) => {
            modal.find('.modal-content').html(data);

            modal.find('form').on('ajax:success', () => {
              this.removeFromList(options.data.token);
              modal.modal('hide');
            });

            modal.modal('show');
          });
        }
      }
    });
  }

  initDataSource () {
    this.dataSource = new kendo.data.DataSource({
      transport: {
        read: (options) => this.read(options),
        destroy: (options) => this.destroy(options)
      },
      serverSorting: true,
      serverPaging: true,
      serverFiltering: true,
      page: 1,
      pageSize: 20,
      sort: this.gridSort(),
      filter: this.gridFilter(),
      schema: {
        data: 'contributions',
        model: {
          id: 'token',
          fields: {
            thumbnail: { type: 'string' },
            title: { type: 'string' },
            author: { type: 'string' },
            updated_at: { type: 'date' },
            created_at: { type: 'date' },
            views: { type: 'number' },
            comments: { type: 'number' },
            visibility: { type: 'string' },
            featured: { type: 'boolean' },
            is_collaborative_content: { type: 'boolean' },
            expired: { type: 'boolean' },
            expires_at: { type: 'date' },
            tags: { type: 'string' },
            topic_areas: { type: 'string' },
            topic_area_list: { type: 'array' }
          }
        },
        total: 'total'
      }
    });
  }

  updateRow (token, attributes = {}) {
    let row = this.getTableRow(token);
    let dataItem = this.kendoGrid.dataItem(row);

    if (dataItem) {
      // Update data item
      _.extend(dataItem, attributes);

      let cells = row.querySelectorAll('td[role="gridcell"]');

      for (let i = 0; i < this.kendoGrid.columns.length; i++) {
        let column = this.kendoGrid.columns[i];
        let cell = cells[i];
        let template = column.template;

        if (template) {
          cell.innerHTML = kendo.template(template)(dataItem);
        } else if (!column.selectable) {
          let value = dataItem[column.field];
          let format = column.format;

          if (format) {
            // use the format
            cell.innerHTML = kendo.format(format, value);
          } else {
            // Just dump the plain old value
            cell.innerHTML = value;
          }
        }
      }
    }
  }

  gridSort () {
    let sort = this.getGridOptions().sort;

    return sort ? sort : {
      field: 'updated_at',
      dir: 'desc'
    };
  }

  gridFilter () {
    let filters = this.getGridOptions().filter;

    if (filters) {
      return filters.reduce((filters, filter) => {
        return filters.concat(filter.filters);
      }, []);
    } else {
      return [];
    }
  }

  getGridOptions () {
    try {

      return JSON.parse(VERSTEHE.utils.readCookie(GRID_COOKIE_KEY) || '{}');
    } catch(e) {
      console.error(e);
      return {};
    }
  }

  setGridOptions (option) {
    let options = Object.assign(this.getGridOptions(), option);
    VERSTEHE.utils.createCookie( GRID_COOKIE_KEY , JSON.stringify(options), 365 * 10);

    return options;
  }

  initKendoGrid () {
    const gridOptions = this.getGridOptions();
    let visibleColumns = gridOptions.columns;

    this.kendoGrid = $(this.itemsElement).kendoGrid({
      columns: columnConfiguration.map(column => {
        if (visibleColumns && column.hideable) {
          column.hidden = visibleColumns.indexOf(column.field) === -1
        }

        if (gridOptions.filter && column.name === 'select') {
          column.hidden = gridOptions.filter.some(filter => filter.field === 'is_deleted');
        }

        return column;
      }),
      dataSource: this.dataSource,
      change: (e) => {
        this.selected = this.kendoGrid.selectedKeyNames();
        this.bulkActions.updateSelection(this.selected);
      },
      editable: {
        mode: 'inline',
        confirmation: true
      },
      pageable: {
        numeric: false,
        previousNext: false
      },
      persistSelection: true,
      scrollable: {
        endless: true
      },
      filterable: {
        extra: false
      },
      sortable: {
        mode: 'single',
        allowUnsort: false
      },
      filter: (e) => {
        this.filter.filterChange(e);
      },
      dataBound: (e) => {
        // Initialize bootstrap tooltips
        $(this.gridElement).find('[data-toggle="tooltip"]').tooltip({
          animation: true,
          placement: 'left',
          html: true,
          delay: {
            'show': 450,
            'hide': 0
          }
        });

        this.filter.updateFilters(this.dataSource.filter());
      },
      sort: (e) => {
        this.setGridOptions({ sort: e.sort });
      },
      filterMenuInit: (e) => {
        switch (e.field) {
          case 'visibility':
            visibilityFilterMenu(e);
            break;
          case 'featured':
          case 'is_collaborative_content':
          case 'expired':
            booleanFilterMenu(e);
            break;
        }
      }
    }).data('kendoGrid');
  }

  bindListeners () {
    // Delete
    $(this.gridElement).on('click', '.grid-action-delete', (e) => {
      let tableRow = e.currentTarget.parentNode.parentNode;
      this.kendoGrid.removeRow(tableRow);
    });

    this.gridElement.querySelector('th.k-header > .k-checkbox').addEventListener('click', e => {
      if (!e.target.checked) {
        this.kendoGrid._selectedIds = {};
        this.kendoGrid.clearSelection();
      }
    });
  }
}
