import { Section } from "./interfaces";
import { Take, Slide, Image } from "./interfaces";
import { v4 as uuid } from 'uuid';

export default class Utils {

  static serializeScreenplay (filmId: number, sections: Array<Section>, deletedScenes: Array<{ section: any, scene: any }>) {
    return {
      media_film: {
        sections_attributes: sections.filter(section => (!section.is_new || !section.deleted)).map(section => {
          return {
            id: typeof section.id === "string" ? null : section.id,
            film_id: filmId,
            position: section.position,
            title: section.title,
            transition_name: section.transition?.name ?? null,
            transition_duration: section.transition?.duration,
            _destroy: section.deleted,
            scenes_attributes: [
              ...section.scenes.filter(scene => (!scene.is_new || !scene.deleted)).map(scene => {
                let sceneAttributes: any = {
                  id: typeof scene.id === "string" ? null : scene.id,
                  medium_id: scene.medium_id,
                  medium_type: scene.medium_type,
                  position: scene.position,
                  title: scene.title,
                  tts_enable: scene.tts_enable,
                  tts_duration: scene.duration,
                  _destroy: scene.deleted
                }

                if (scene.tts_enable) {
                  sceneAttributes.tts_speaker = scene.tts_speaker;
                  sceneAttributes.tts_text = scene.tts_text;
                }

                return sceneAttributes;
              }),
              ...deletedScenes.filter((item) => item.section === section.id).map(item => {
                return {
                  id: item.scene,
                  _destroy: true
                }
            })]
          }
        })
      }
    }
  }

  static takeToScene(take: Take) {
    return {
      id: uuid(),
      medium_id: take.id,
      medium_type: 'Media::Take',
      title: take.title,
      duration: take.video.duration,
      position: null,
      created_at: null,
      updated_at: null,
      thumbnail_url: take.thumbnail_url,
      tts_speaker: 'james',
      tts_text: '',
      deleted: false
    }
  }

  static slideToScene(slide: Slide) {
    return {
      id: uuid(),
      medium_id: slide.id,
      medium_type: 'Media::Slide',
      title: `${slide.title} - ${slide.page}`,
      duration: 10,
      position: null,
      created_at: null,
      updated_at: null,
      thumbnail_url: slide.thumbnail_url,
      url: slide.src,
      tts_speaker: 'james',
      tts_text: '',
      tts_enable: false,
      deleted: false
    }
  }

  static imageToScene(image: Image) {
    return {
      id: uuid(),
      medium_id: image.id,
      medium_type: 'Media::Image',
      title: image.title,
      duration: 10,
      position: null,
      created_at: null,
      updated_at: null,
      thumbnail_url: image.thumbnail_url,
      url: image.src,
      tts_speaker: 'james',
      tts_text: '',
      tts_enable: false,
      deleted: false
    }
  }

  static changeXMLSpeakerValues(xmlString, speaker) {
    if (xmlString && xmlString.length > 0) {
      const parser = new DOMParser();
      let xmlDoc = parser.parseFromString(xmlString, 'text/xml');

      const voiceNodes = xmlDoc.getElementsByTagName('voice');

      if (voiceNodes.length > 0) {
        for (let i = 0; i < voiceNodes.length; i++) {
          voiceNodes[i].setAttribute('name', speaker.charAt(0).toUpperCase() + speaker.slice(1));
        }
      } else {
        const speakNode = xmlDoc.getElementsByTagName('speak')[0];
        const voiceNode = xmlDoc.createElement('voice');
        voiceNode.setAttribute('name', speaker.charAt(0).toUpperCase() + speaker.slice(1));
        voiceNode.innerHTML = speakNode.innerHTML;
        speakNode.innerHTML = '';
        speakNode.appendChild(voiceNode);
      }

      return new XMLSerializer().serializeToString(xmlDoc);
    } else {
      return null;
    }
  }
}
