import { RangeDatepicker } from '../../globals/range_datepicker';
import { t } from '../localization';
import { fireEvent } from '../../globals/custom_event';

export class DateFilter {
  constructor(dashboard) {
    this.dashboard = dashboard;
    this.element = this.dashboard.wrapper.parentElement.querySelector('.date-filter');
    this.rangePicker = null;
    this.initializeFieldSelect();
    this.initializeQuickFilter();
    this.initializeRangePicker();
    this.bindListener();
  }

  /**
   * Setup dropdown for filter field selection
   */
  initializeFieldSelect () {
    // create Dropdown div
    let fieldSelectWrapper = document.createElement('div');
    fieldSelectWrapper.className = 'analytics-date-filter';

    // create dopdown element container
    let fieldSelectInnerWrapper = document.createElement('div');
    fieldSelectInnerWrapper.classList.add('inner-wrapper');

    // create left side container
    this.leftSide = document.createElement('div');
    this.leftSide.classList.add('left-side');
    fieldSelectInnerWrapper.appendChild(this.leftSide);

    // create right side container
    this.rightSide = document.createElement('div');
    this.rightSide.classList.add('right-side');
    fieldSelectInnerWrapper.appendChild(this.rightSide);

    //initialize kendo ui dropdown
    fieldSelectWrapper.appendChild(fieldSelectInnerWrapper);
    this.fieldSelect = $(fieldSelectWrapper).kendoPopup({
      anchor: this.element,
      origin: 'bottom right',
      position: 'top right'
    }).data('kendoPopup');
  }

  initializeRangePicker () {
    // create rangepicker container
    let outerWrapper = document.createElement('div'); 
    outerWrapper.classList.add('datepicker-outerwarapper');

    // create input fields for datepickers
    let fromInput = this.pickerInputField("from");
    let tillInput = this.pickerInputField();

    // add input fields to the datepicker container
    outerWrapper.appendChild(fromInput);
    outerWrapper.appendChild(tillInput);

    // add datepicker container to the right side
    this.leftSide.appendChild(outerWrapper);

    // add Apply Button to the right side
    this.leftSide.appendChild(this.createDropDownButtons());

    // initialize rangepicker
    this.rangePicker = new RangeDatepicker($(this.leftSide));
    this.updateRangepickerValues();
  }

  /**
   * 
   * @param {String} pickerId contains the value for the html attribute id
   */
  pickerInputField(pickerType) {
    // create input container
    let wrapper = document.createElement('div');

    // create inputlabel and input
    let label = document.createElement('label');
    let input = document.createElement('input');

    // deactivate autocomplete for the input field
    input.setAttribute('autocomplete', "off");

    // set the correct labe translation and css class for till or from picker
    if (pickerType === "from") {
      wrapper.classList.add("datepicker-from-wrapper");
      label.innerHTML = t('from');
      input.setAttribute('id', 'from-datepicker');
    } else {
      wrapper.classList.add("datepicker-till-wrapper");
      label.innerHTML = t('till');
      input.setAttribute('id', 'till-datepicker');
    }

    // add the created input and label to the container
    wrapper.appendChild(label);
    wrapper.appendChild(input);
    
    return wrapper;
  }

  createDropDownButtons () {
    let wrapper = document.createElement('div');
    wrapper.classList.add('accept-filter-container');

    wrapper.appendChild(this.createFilterButton());
    
    return wrapper;
  }

  // set css class to filter button an bind click listener
  createFilterButton () {
    // create button element
    let filterButton = document.createElement('button');

    // add some css classes and field attributes
    filterButton.classList.add('k-button');
    filterButton.classList.add('k-primary');
    filterButton.classList.add('accept-filter');
    filterButton.innerHTML = t('acceptFilter');
    filterButton.setAttribute('type', 'submit');

    // add on click listener
    filterButton.addEventListener('click', (e) => {
      this.onClick(e);
    });

    return filterButton;
  }

   /**
   * Update field select dropdown
   */
  initializeQuickFilter () {
    // create quicklink container
    let outerWrapper = document.createElement('div'); 
    outerWrapper.classList.add('quick-link-wrapper');
    
    // create quick link list
    let dropdown = document.createElement('ul');
    let options = JSON.parse(this.element.getAttribute('data-filter-options'));

    // create list elements
    options.forEach((quick) => {    
      dropdown.appendChild(this.createQuickLinkElement(quick));
    });

    // add list to the container
    outerWrapper.appendChild(dropdown);

    // add quicklinks to the left side
    this.rightSide.innerHTML = '';
    this.rightSide.appendChild(outerWrapper);
  }

  createQuickLinkElement(quick) {
    let element = document.createElement('li');
    let link = document.createElement('a');
    link.href = '#';
    link.innerText = quick.title;
    element.appendChild(link);
    element.setAttribute('data-time', quick.time);
    element.setAttribute('data-subtract', quick.subtract);
    element.addEventListener('click', e => {
      this.onClick(e);
      fireEvent(this.element, 'quickFilter');
    });

    return element;
  }

  getDisplayedTimeInterval() {
    let from = moment(new Date(this.element.getAttribute('data-from'))).format(datepickerLocalization[_app.info.currentLocale]['moment'].dateFormat);
    let till = moment(new Date(this.element.getAttribute('data-till'))).format(datepickerLocalization[_app.info.currentLocale]['moment'].dateFormat);
 
    return { "from": from, "till": till }; 
  }

  onClick (e) {
    e.preventDefault();
    let target = e.target;
    if (target.tagName.toLowerCase() === 'button') {
      this.onButtonClick(e);
    } else {
      let title = target.innerHTML;
      let time = target.parentElement.getAttribute('data-time');
      let subtract = target.parentElement.getAttribute('data-subtract');
      let till = this.calcTill(subtract);
      let from = this.calcFrom(time, subtract);
      this.element.getElementsByTagName('SPAN')[0].innerHTML = title;
      this.element.setAttribute('data-from', from);
      this.element.setAttribute('data-till', till);
    }

    this.fieldSelect.close();
    this.dashboard.setIntervalFilter();
    this.dashboard.triggerFilterChanges();
    this.dashboard.triggerFilterChanges(document, 'intervalChanges');
  }

  onButtonClick (e) {
    let interval = this.rangePicker.getDisplayedTimeInterval();

    // 1.01.1970 is the kendo ui default for empty values
    if (interval.from !== "1.01.1970" && interval.till !== "1.01.1970") {
      this.element.querySelector('.title').innerHTML = interval.displayFrom + " - " + interval.displayTill;
      this.element.setAttribute('data-from', interval.from);
      this.element.setAttribute('data-till', interval.till);
    }
  }

  calcFrom(time, subtract) {
    if (subtract === "unlimited") {
      return "";
    } else {
      return moment(new Date()).subtract(time, subtract).toDate().toISOString();
    }
  }

  calcTill(subtract) {
    if (subtract === "unlimited") {
      return "";
    } else {
      return new Date().toISOString();
    }
  }

  updateRangepickerValues() {
    this.rangePicker.fromPicker.setValue(new Date(this.element.getAttribute('data-from')));
    this.rangePicker.tillPicker.setValue(new Date(this.element.getAttribute('data-till')));
  }

  disableEnableApplyButton() {
    let from = this.rangePicker.fromPicker.getValue();
    let till = this.rangePicker.tillPicker.getValue();
    let button = document.querySelector('.analytics-date-filter').querySelector('.accept-filter');
 
    if (from && till) {
      button.disabled = false;
    } else {
      button.disabled = true;
    }
  }

  bindListener() {
    if (this.element) {
      this.element.addEventListener('click', () => {
        this.fieldSelect.open();
      });

      this.element.addEventListener('quickFilter', () => {
        this.updateRangepickerValues();
      });

      this.fieldSelect.bind('open', () => {
        this.disableEnableApplyButton();
      });

      this.rangePicker.fromPicker.inputField.bind('change', () => {
        this.disableEnableApplyButton();
      });

      this.rangePicker.tillPicker.inputField.bind('change', () => {
        this.disableEnableApplyButton();
      });
    }
  }
}
