export class ChangeDetector {
  constructor(formSelector) {
    this.topicForm = document.querySelector(formSelector);
    this.needsReview = this.topicForm.getAttribute('data-needsreview') == 'true';
    this.hasChanges = this.topicForm.getAttribute('data-haschanges') == 'true';
    this.changeDetectorForm = document.querySelector('#detect-changes-form');
    this.changeCourseDetectorForm = document.querySelector('#detect-course-changes-form');
    if(this.changeDetectorForm){
      this.fieldWrapper = this.changeDetectorForm.querySelector('#relevant-attributes-wrapper');
      this.topicChangeIndicator = document.querySelector('.topic-changes-indicator');
      this.elements = [];
      this.elements.push(this.topicForm.querySelector('input[name="topic[title]"'));
      this.elements.push(this.topicForm.querySelector('textarea[name="topic[description]"'));
      this.elements.push(this.topicForm.querySelector('select[name="topic[topic_area_ids][]"]'));
      this.elements.push(this.topicForm.querySelector('select[name="topic[tag_ids][]"]'));
      this.elements.push(this.topicForm.querySelector('#topic_primary_topic_area_id'));
      this.elements.push(...this.topicForm.querySelectorAll('#metadata-fields select'));
      this.targetGroupField = this.topicForm.querySelector('select[name="topic[target_group_ids][]"]');
    }else if(this.changeCourseDetectorForm){
      this.fieldWrapper = this.changeCourseDetectorForm.querySelector('#relevant-attributes-wrapper');
      this.topicChangeIndicator = document.querySelector('.course-changes-indicator');
      this.elements = [];
      this.elements.push(this.topicForm.querySelector('input[name="course[title]"'));
      this.elements.push(this.topicForm.querySelector('textarea[name="course[description]"'));
      this.elements.push(this.topicForm.querySelector('select[name="course[topic_area_ids][]"]'));
      this.elements.push(this.topicForm.querySelector('select[name="course[tag_ids][]"]'));
      this.elements.push(this.topicForm.querySelector('#course_primary_topic_area_id'));
      this.elements.push(this.topicForm.querySelector('select[name="course[level]"'))
      this.elements.push(this.topicForm.querySelector('#add_objectives'))
      this.elements.push(this.topicForm.querySelectorAll('textarea[name^="course[objectives_attributes]"'))
      this.elements.push(this.topicForm.querySelector('input[name="course[should_handling_period]"][type="checkbox"]'))
      this.elements.push(this.topicForm.querySelector('input[name="course[is_collaborative_content]"][type="checkbox"]'))
      this.elements.push(this.topicForm.querySelector('input[name="course[allow_reset]"][type="checkbox"]'))
      this.elements.push(this.topicForm.querySelector('input[name="course[needs_confirmation]"][type="checkbox"]'))
      this.elements.push(this.topicForm.querySelector('input[name="course[certificate_offered]"][type="checkbox"]'))
      this.elements.push(this.topicForm.querySelector('input[name="course[evaluable]"][type="checkbox"]'))
      this.elements.push(this.topicForm.querySelector('input[name="course[publish_quiz_highscore]"][type="checkbox"]'))
      this.elements.push(...this.topicForm.querySelectorAll('#metadata-fields select'));
      this.targetGroupField = this.topicForm.querySelector('select[name="course[target_group_ids][]"]');
    }
    this.changeTimeout = null;
    this.bindListeners();
    this.checkComplete = Promise.resolve(true);
  }

  buildForm() {
    this.fieldWrapper.innerHTML = '';
    for(let i = 0; i < this.elements.length; i++) {
      // If no custom metadata fields are enabled, target group input
      // may be null / not present
      if (this.elements[i] && !NodeList.prototype.isPrototypeOf(this.elements[i])) {
        this.fieldWrapper.appendChild(this.elements[i].cloneNode(true));
      }
    }
    const targetGroupValues = VERSTEHE.ContributionGeneral.targetGroupSelect.values();

    if (this.targetGroupField && targetGroupValues.length > 0) {
      for(let i = 0; i < targetGroupValues.length; i++) {
        this.fieldWrapper.appendChild(this.buildTargetGroupNode(targetGroupValues[i]));
      }
    } else {
      this.fieldWrapper.appendChild(this.buildTargetGroupNode(""));
    }
  }

  bindListeners() {
    for(let i = 0; i < this.elements.length; i++) {
      if (this.elements[i] instanceof HTMLInputElement || this.elements[i] instanceof HTMLTextAreaElement) {
        this.initInputListener(this.elements[i]);
      } else if(this.elements[i]) {
        if(this.elements[i].id == "add_objectives"){
          this.elements[i].addEventListener('click', (e) => {
            this.changesDetected();
          });
        } else if(NodeList.prototype.isPrototypeOf(this.elements[i])){
          // checks without server if objectives were changed
          this.checkObjectives(this.elements[i]);
        } else {
          this.elements[i].addEventListener('change', (e) => {
          // default value is always pre selected, to get new selected value the selected attribute has to be removed, and the new value gets the selected attribute
          if(this.elements[i].id == "course_level"){
            let currentVal = $('#course_level').val();
            let preSelected = $(this.elements[i]).find("[selected='selected']");
            preSelected.removeAttr('selected');
            let newVal = $(this.elements[i]).find("[value='"+currentVal+"']");
            newVal.attr('selected', true); 
          }
          this.remoteCheck();
        });
      }
      }
    }

    if (this.targetGroupField) {
      this.targetGroupField.addEventListener('change', (e) => {
        this.remoteCheck();
      });
    }
  }

  checkObjectives(list){
    list.forEach((current)=>{
      if (current instanceof HTMLInputElement || current instanceof HTMLTextAreaElement) {
        current.addEventListener('keyup', (e) => {
          this.changesDetected();
        });
        let removeBtn = $(current).parent().find('.remove_nested_fields');
        removeBtn.on('click', ()=>{
          this.changesDetected();
        });
      }
    });
  }

  changesDetected(){
    this.hasChanged = true;
    this.updateIndicator();
  }

  remoteCheck() {
    this.buildForm();
    let url = "";
    let formData = "";
    if(this.changeDetectorForm){
      formData = $(this.changeDetectorForm).serialize();
      url = $(this.changeDetectorForm).attr('action');
    }else if(this.changeCourseDetectorForm){
      formData = $(this.changeCourseDetectorForm).serialize();
      url = $(this.changeCourseDetectorForm).attr('action');
    }

    this.checkComplete = new Promise(resolve => {
      $.ajax({
        type: 'POST',
        url: url,
        data: formData
      }).done((res) => {
        this.needsReview = res.needs_review;
        this.hasChanged = res.has_changes;
        this.updateIndicator();
        resolve();
      });
    });
  }

  initInputListener(input) {
    if(input.type == "checkbox"){
      input.addEventListener('change', (e) => {
        this.startRemoteCheck();
      });
    }
    input.addEventListener('keyup', (e) => {
      this.startRemoteCheck();
    });
  }

  startRemoteCheck(){
    clearTimeout(this.changeTimeout);

      this.changeTimeout = setTimeout( () => {
        this.remoteCheck();
      }, 1000);
  }

  updateIndicator() {
    if(this.hasChanged) {
      this.topicChangeIndicator?.classList.remove('hidden');
    } else {
      this.topicChangeIndicator?.classList.add('hidden');
    }
  }

  buildTargetGroupNode(value) {
    const node = document.createElement('input');
    node.hidden = true;
    if(this.changeCourseDetectorForm){
      node.name = "course[target_group_ids][]";
    }else{
      node.name = "topic[target_group_ids][]";
    }
   
    node.value = value;

    return node;
  }
}
