<template>
<div class="lesson-group course-explore" :class="{ 'lesson-group-completed' : isCompleted }">
  <div v-on:click="toggleLessons" class="lesson-group-head">
    <div class="lesson-group-title">
      <div class="lesson-group-number">{{ $t('course.lesson_group') }}: {{ number }}</div>
      <h2>{{ lessonGroup.title }}</h2>
    </div>

    <div class="lesson-group-progress">
      {{ completedLessonsCount }} / {{ lessonsCount }}
    </div>
  </div>

  <collapse-transition :duration="lessonsCount * 40">
    <ul v-show="lessonsExpanded" class="lesson-group-lessons">
      <lesson-item
        v-for="lesson in lessonGroup.lessons"
        :key="lesson.id"
        :lesson="lesson"></lesson-item>
    </ul>
  </collapse-transition>
</div>
</template>

<script>
import { CollapseTransition } from 'vue2-transitions'

import LessonItem from './lesson_item.vue';

export default {
  name: 'lesson-group',
  props: ['number', 'lessonGroup'],
  components: {
    LessonItem,
    CollapseTransition
  },
  data() {
    return {
      lessonsExpanded: true
    }
  },
  computed: {
    isActive() {
      return this.lessonGroup.lessons.find(lesson => lesson.id == this.$route.params.lessonId) !== undefined;
    },
    isCompleted() {
      return this.completedLessonsCount === this.lessonsCount;
    },
    lessonsCount() {
      return this.lessonGroup.lessons.length;
    },
    completedLessonsCount() {
      return this.lessonGroup.lessons.filter(lesson => lesson.progress === 1).length;
    },
  },
  methods: {
    toggleLessons() {
      this.lessonsExpanded = !this.lessonsExpanded;
    }
  },
  created() {
    if (this.isCompleted && !this.isActive) {
      this.lessonsExpanded = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.lesson-group-head {
  cursor: pointer;
  user-select: none;
}
</style>
