import { ChangeAction } from "../../system_actions/change_action";

/**
 *
 *
 */
export class PostMediumAction extends ChangeAction {
  /**
   * @constructor
   * @param {String} tabId  - TabId of the tab the action originates from
   * @param {number} user - UserId of the chat room owner
   * @param {String} element - Element thats subject of the UIAction
   * @param {number} mediumId
   * @param {String} type
   */
  constructor(tabId, user, element, mediumId, type) {
    super(tabId, user, element);
    this.mediumId = mediumId;
    this.type = type;
    this.actionType = "PostMediumAction";
  }

  execute() {
    let medium = $(".media[data-id=" + this.mediumId + "]");
    VERSTEHE.posts.mediumDrop(medium, this.element);
  }

  static fromJson(json) {
    let action = JSON.parse(json);
    return new PostMediumAction(action.tabId, action.user, action.elementLocation, action.mediumId, action.type);
  }

  static registerToElement() {
    $(document).on('postMediumDrop', '.library-drop-post', function(event, medium) {
      PostMediumAction.createAction(PostMediumAction.getTabId(), _app.info.user.userId, event.target, medium.data("id"), "postMediumDrop");
    });
  }

  static createAction(tabId, user, element, mediumId, type) {
    let action = new PostMediumAction(tabId, user, element, mediumId, type);
    VERSTEHE.content_channel.send_message(action);
  }
}
