<template>
<div class="question-option" :class="{ error: errors.has(validateName) }">
  <div class="draghandle">
    <i class="material-icons">drag_indicator</i>
  </div>
  <div class="radio">
    <label>
      <input
        type="radio"
        :name="name"
        :value="option.id"
        v-model="radioValue">
    </label>
  </div>
  <div class="question-option-title">
    <textarea
      rows="1"
      v-model="title"
      maxlength="255"
      ref="textArea"
      v-validate="'required'"
      data-vv-as="questionOptionTitle"
      :data-vv-name="validateName"
      :placeholder="placeholderText"
      @keydown="handleInput"
      @change="$emit('change', $event.target.value)"></textarea>
  </div>
  <div class="question-answer-actions">
    <a :class="{ disabled: disabled }" v-on:click.prevent="$emit('remove', option)" href="#" class="material-icons">delete</a>
  </div>
</div>
</template>

<script>
export default {
  name: 'radio-option',
  inject: ['$validator'],
  props: ['option', 'name', 'correct', 'number', 'disabled'],
  data() {
    return {
      title: this.option.title
    }
  },
  computed: {
    radioValue: {
      get() {
        return this.correct[0];
      },
      set(id) {
        this.$emit('checked', [id]);
      }
    },
    validateName() {
      return `option_title_${this.option.id}`;
    },
    placeholderText() {
      if (this.errors.has(this.validateName)) {
        return this.$t('course.question_option_required');
      } else {
        return this.$t('course.new_question_option_placeholder', { number: this.number });
      }
    }
  },
  methods: {
    handleInput(e) {
      // Prevent line break
      if (e && e.keyCode === 13) {
        e.preventDefault();
        return;
      }

      // Auto-expand textarea
      setTimeout(() => {
        this.$refs.textArea.style.height = 'auto';
        this.$refs.textArea.style.height = `${this.$refs.textArea.scrollHeight}px`;
      });
    }
  },
  mounted() {
    this.handleInput();
    $.material.init();
  }
}
</script>
