import { GetterTree } from "vuex";
import { MatManageState } from "./state";
import { RootState } from "../../../store/types";

export const Getters = {
  TEMPLATE: 'mediaAssemblingTemplate/template',
  TEMPLATE_CONFIG_ELEMENTS: 'mediaAssemblingTemplate/templateConfigElements',
  SLIDE_KEYS: 'mediaAssemblingTemplate/slideKeys',
  SLIDE: 'mediaAssemblingTemplate/slide',
  SLIDE_ELEMENTS: 'mediaAssemblingTemplate/slideElements',
  UNUSED_ELEMENTS: 'mediaAssemblingTemplate/unusedElements',
  FONTS: 'mediaAssemblingTemplate/fonts',
  CAN_SET_PUBLIC: 'mediaAssemblingTemplate/canSetPublic',
  ELEMENT_CATEGORIES: 'mediaAssemblingTemplate/elementCategories'
};

export const getters: GetterTree<MatManageState, RootState> = {
  template: (state) => state.template,
  templateConfigElements: (state) => {
    return [].concat.apply([], Object.keys(state.config).map((key) => state.config[key].map(element => {
      return { ...element, subject: key };
    })))
  },
  slideKeys: (state) => Object.keys(state.template.slides),
  slide: (state) => (key) => state.template.slides[key],
  slideElements: (state) => (slideKey) => state.template.slides[slideKey].elements,
  elementCategories: (state) => {
    return Object.keys(state.config);
  },
  unusedElements: (state, getters) => (slideKey, category) => {
    return state.config[category].filter(element => typeof state.template.slides[slideKey].elements.find((e) => e.id === element.id) === 'undefined');
  },
  fonts: (state) => state.fonts,
  canSetPublic: (state) => {
    const isPublisher = _app.info.user.roles.indexOf("publisher") >= 0;
    const isMasterEditor = _app.info.user.roles.indexOf("master_editor") >= 0;
    return isPublisher || (isMasterEditor && (state.newRecord || state.template.user_id === _app.info.user.userId));
  }
};
