import Vue from 'vue';
import VueRouter from 'vue-router';
import { sync } from 'vuex-router-sync';

import App from './components/app.vue';
import Lesson from './components/lesson/lesson.vue';
import Quiz from './components/quiz.vue';
import Feedback from './components/feedback.vue';
import store from '../../store';
import coursePerformStore from './store';

const routes = [
  {
    path: '/lesson/:lessonId',
    name: 'lesson',
    component: Lesson,
    beforeEnter: (to, from, next) => {
      const lessonId = parseInt(to.params.lessonId, 10);

      if (store.getters['coursePerform/lessonById'](lessonId) !== undefined) {
        next();
      } else {
        next('/');
      }
    }
  },
  {
    path: '/knowledge_check',
    name: 'quiz',
    component: Quiz
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: Feedback
  },
  {
    path: '*',
    redirect: to => {
      // Redirect to first lesson
      const course = store.getters['coursePerform/course'];
      return `/lesson/${course.lessonGroups[0].lessons[0].id}`;
    }
  }
];

export default class CoursePerformModule {
  constructor (appNode) {
    store.registerModule('coursePerform', coursePerformStore);

    const params = appNode.dataset;
    store.dispatch('coursePerform/setParameters', {
      logoUrl: params.logo,
      siteUrl: params.site,
      courseUrl: params.overviewUrl
    });

    store.dispatch('coursePerform/setCourse', JSON.parse(params.course));
    store.dispatch('coursePerform/checkCompleted');

    const router = new VueRouter({
      routes
    });

    const unsyncRouter = sync(store, router);

    this.app = new Vue({
      store,
      router,
      module: this,
      i18n: VERSTEHE.vueI18n,
      el: appNode,
      render: h => h(App),
      destroyed() {
        unsyncRouter();
      }
    });
  }

  setQuizCompleted() {
    store.dispatch('coursePerform/setQuizCompleted');
    store.dispatch('coursePerform/checkCompleted');
  }
}
