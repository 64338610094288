<template>
  <div class="integration-card">
    <div class="integration-state">
      <div v-if="isConnected" class="integration-state-connected">
        {{ $t('integrations.connected') }}
      </div>

      <div v-if="isChecking" class="integration-state-checking">
        {{ $t('integrations.checking') }}
      </div>

      <div v-if="isFailed" :title="errorMessage" class="integration-state-failed">
        {{ $t('integrations.failed') }}
      </div>
    </div>
    <div class="integration-card-inner">
      <img :src="require(`images/integrations/integrations_${id}.png`)">
      <h2>{{ title }}</h2>
      <p>{{ description }}</p>
    </div>
    <div class="integration-card-actions">
      <button v-if="!isConnected" @click="onConnect" :class="{ disabled: isChecking }" class="btn btn-primary btn-flat">{{ $t('integrations.connect') }}</button>
      <button v-if="isConnected" @click="onDisconnect" class="btn btn-primary btn-flat">{{ $t('integrations.disconnect') }}</button>
      <button @click="onConfigure" class="btn btn-primary btn-flat"><i class="material-icons">build</i></button>
    </div>
  </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex';
  import { confirmDialog } from '../../common/dialog';
  const { mapGetters, mapActions } = createNamespacedHelpers('integrations');

  export default {
    props: ['id'],
    computed: {
      ...mapGetters([
        'integrationById'
      ]),
      integration() {
        return this.integrationById(this.id);
      },
      title() {
        return this.$t(`integrations.${this.id}_title`)
      },
      description() {
        return this.$t(`integrations.${this.id}_description`)
      },
      isConnected() {
        return this.integration.state === 'connected';
      },
      isChecking() {
        return this.integration.state === 'checking';
      },
      isFailed() {
        return this.integration.state === 'failed';
      },
      errorMessage() {
        return this.integration.error;
      }
    },
    methods: {
      ...mapActions([
        'connect',
        'disconnect'
      ]),
      onConnect(event) {
        event.target.blur();

        if (this.id === 'ai_aws' || this.id === 'ai_azure' || this.id === 'ai_gcp') {
          confirmDialog({
            title: this.$i18n.t('integrations.confirm_dialog_costs_title'),
            content: this.$i18n.t('integrations.confirm_dialog_costs_body')
          }).then(() => this.connect(this.integration));
        } else {
          this.connect(this.integration);
        }
      },
      onDisconnect() {
        this.disconnect(this.integration);
      },
      onConfigure() {
        this.$router.push({ name: 'settings', params: { id: this.id }});
      }
    }
  }
</script>

<style lang="scss" scoped>
  .integration-card {
    display: flex;
    flex-direction: column;
    width: 240px;
    height: 260px;
    background-color: $color-white;
    border-radius: 3px;
    padding: 15px;
    justify-content: space-between;
    margin: 0 15px 15px 0;

    img {
      display: block;
      margin: 0 auto;
    }

    h2 {
      color: $black-87-opacity;
      font-size: 16px;
      font-weight: 500;
      margin: 15px 0;
      text-align: center;
    }

    p {
      color: $black-54-opacity;
      margin: 0;
      text-align: center;
    }

    button {
      margin: 0;
    }
  }

  .integration-card-actions {
    display: flex;

    button {
      display: block;
      margin: 0;

      &:first-child {
        flex: 1 0;
      }

      &:last-child {
        padding: 8px 15px;
      }
    }
  }

  .integration-state {
    height: 20px;
  }

  .integration-state-connected,
  .integration-state-checking,
  .integration-state-failed {
    line-height: 20px;

    &::before {
      content: '';
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      margin-right: 6px;
    }
  }

  .integration-state-connected::before {
    background-color: $color-green-1;
  }

  .integration-state-checking::before {
    background-color: $color-yellow-1;
  }

  .integration-state-failed::before {
    background-color: $color-error-red;
  }
</style>
