<template>
<div class="elearning-package-lesson">
  <iframe ref="iframe" @load="watchedLesson" :src="url"></iframe>

  <div class="lesson-detail">
    <p class="lesson-description">{{ currentLesson.description }}</p>
  </div>
</div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('coursePerform');

export default {
  name: 'elearning-package-lesson',
  computed: {
    ...mapGetters([
      'currentLesson'
    ]),
    url() {
      return this.currentLesson.detail.subject.url;
    }
  },
  methods: {
    watchedLesson() {
      this.$emit('progress', 1);
    }
  }
}
</script>

<style lang="scss" scoped>
.elearning-package-lesson-iframe {
  height: calc(100vh - 60px);
}

iframe {
  display: block;
  width: 100%;
  height: calc(100vh - 60px);
  border: 0;
}
</style>
