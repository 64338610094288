export function onConvertProgress (amorId, percent) {
  let take = $('.take[data-amor="' + amorId + '"]'),
  convertProgress = take.find('.convert-progress'),
  progressIndicator = take.find('.progress-indicator');

  if (!take.hasClass('converted')) {
    convertProgress.show().width(percent + '%');
    take.removeClass('uploading uploaded').addClass('converting');

    progressIndicator
      .show()
      .find('.percent').html(percent + '%');
  }
}

export function onConvertSuccess (result) {
  let take = $('.take[data-amor="' + result.amor.id + '"]'),
  convertProgress = take.find('.convert-progress'),
  uploadProgress = take.find('.upload-progress'),
  progressIndicator = take.find('.progress-indicator');

  progressIndicator.hide();
  convertProgress.width('100%').delay(200)
    .fadeTo(100, 0.5).fadeTo(100, 1.0)
    .fadeTo(100, 0.5).fadeTo(100, 1.0, function() {
      $(this).hide();
    });

  uploadProgress.show().width(0 + '%');
  take.removeClass('converting converted uploaded').addClass('uploading');

  progressIndicator
    .show()
    .find('.percent').html(0 + '%');
  take.removeClass('converting uploading uploaded').addClass('converted');
}

export function onUploadProgress (amorId, percent) {
  let take = $('.take[data-amor="' + amorId + '"]'),
  uploadProgress = take.find('.upload-progress'),
  progressIndicator = take.find('.progress-indicator');

  progressIndicator
    .show()
    .find('.percent').html(percent + '%');

  if (percent <= 99) {
    uploadProgress.show().width(percent + '%');
    take.removeClass('converting converted uploaded').addClass('uploading');
  } else {
    uploadProgress.width('100%').delay(200)
      .fadeTo(100, 0.5, function() {
        progressIndicator.hide();
      }).fadeTo(100, 1.0)
      .fadeTo(100, 0.5).fadeTo(100, 1.0, function() {
        $(this).hide();
      });
    take.removeClass('converting converted uploading').addClass('uploaded');
  }
}
