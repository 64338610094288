<template>
  <div class="tutorials-overview">
    <div v-if="searchQuery" class="tutorials-search-results tutorials-category">
      <h3>{{ $t('tutorials.search_results', { query: this.searchQuery }) }}</h3>

      <div v-if="searchResults.length > 0" class="tutorials-video-list">
        <tutorial-video
          v-for="video in searchResults"
          :key="video.id"
          :video="video"></tutorial-video>
      </div>
      <p v-else>{{ $t('tutorials.no_videos_found') }}</p>
    </div>
    <transition-group v-else name="list" tag="div">
      <tutorial-category
        class="tutorials-category"
        v-for="category in filteredCategories"
        :id="category.id"
        :key="category.id">
      </tutorial-category>

      <div class="tutorials-category" v-if="filteredCategories.length === 0" key="no-data">
        <h3>{{ $t('tutorials.failed_to_load_videos') }}</h3>
      </div>
    </transition-group>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapGetters } = createNamespacedHelpers('tutorials');

import { SlideXRightTransition } from 'vue2-transitions'
import TutorialCategory from './tutorial_category.vue';
import TutorialVideo from './tutorial_video.vue';

export default {
  components: {
    SlideXRightTransition,
    TutorialCategory,
    TutorialVideo
  },
  computed: {
    ...mapGetters([
      'categoryById',
      'filteredCategories',
      'searchQuery',
      'searchResults'
    ])
  },
  methods: {
    ...mapActions([
      'filterCategory'
    ])
  },
  beforeRouteUpdate(to, from, next) {
    const categoryId = to.params.categoryId;

    if (categoryId === 'all' || this.categoryById(categoryId)) {
      this.filterCategory(to.params.categoryId);
    }

    next();
  }
}
</script>

<style lang="scss" scoped>
  .tutorials-overview {
    padding-top: 30px;
  }

  .tutorials-category {
    padding: 0 40px;
    transition: all .2s;

    ::v-deep h3 {
      font-size: 20px;
      font-weight: 300;
      margin: 24px 0 15px 0;
    }
  }

  .tutorials-video-list {
    display: flex;
    flex-wrap: wrap;
    max-width: 1010px; /* max 5 videos per row */
  }

  .list-enter, .list-leave-to
  /* .list-complete-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateY(30px);
  }
  .list-leave-active {
    position: absolute;
  }
</style>
