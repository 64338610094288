import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export const initialState = {
  initUrl: null,
  videoSrc: null,
  saveUrl: null,
  initialized: false,
  questions: [],
  currentQuestion: null,
  currentTime: null,
  duration: null,
  isSaving: false,
  isSaved: true
};

export const Actions = {
  INIT_URL: 'filmQuestions/initUrl',
  RESET_STATE: 'filmQuestions/resetState',
  EDIT_QUESTION: 'filmQuestions/editQuestion',
  REMOVE_QUESTION: 'filmQuestions/removeQuestion',
  NEW_QUESTION: 'filmQuestions/newQuestion',
  SAVE_QUESTION: 'filmQuestions/saveQuestion',
  CANCEL_QUESTION: 'filmQuestions/cancelQuestion',
  SET_CURRENT_TIME: 'filmQuestions/setCurrentTime',
  SET_DURATION: 'filmQuestions/setDuration',
  SAVE_REMOTE: 'filmQuestions/saveRemote'
};

export default {
  namespaced: true,
  state : initialState,
  mutations,
  actions,
  getters
};
