import Vue from 'vue';

export default function() {
  // Returns format 00:00:00
  Vue.filter('secondsToTime', function(value) {
    const date = new Date(null);
    date.setSeconds(value);
    return date.toISOString().substr(11, 8);
  });

  // Returns format 00:00:00
  Vue.filter('milliSecondsToTime', function(value) {
    const date = new Date(null);
    date.setMilliseconds(value);
    return date.toISOString().substr(11, 8);
  });

  // Returns format 0min 00s
  Vue.filter('secondsToMinuteString', function(value) {
    const minutes = Math.floor(value / 60);
    const seconds = Math.floor(value % 60);
    return `${minutes}min ${("0" + seconds).slice (-2)}s`;
  });

  Vue.filter('byteToMB', function(value) {
    const mb = (Number(value) / 1024 / 1024).toFixed(2);
    return `${mb} MB`;
  });

  Vue.filter('bitsToMbits', function(bitrate) {
    const mbits = (Number(bitrate) / 1000000).toFixed(2);
    return `${mbits} Mbit/s`;
  });
}
