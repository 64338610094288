export class UppyUtils {
  static getTotalSpeed (files) {
    let totalSpeed = 0
    files.forEach((file) => {
      totalSpeed = totalSpeed + UppyUtils.getSpeed(file.progress)
    })
    return totalSpeed
  }

  static getTotalETA (files) {
    const totalSpeed = this.getTotalSpeed(files)
    if (totalSpeed === 0) {
      return 0
    }

    const totalBytesRemaining = files.reduce((total, file) => {
      return total + UppyUtils.getBytesRemaining(file.progress)
    }, 0)

    return Math.round(totalBytesRemaining / totalSpeed * 10) / 10
  }

  static getSpeed (fileProgress) {
    if (!fileProgress.bytesUploaded) return 0
  
    const timeElapsed = Date.now() - fileProgress.uploadStarted
    const uploadSpeed = fileProgress.bytesUploaded / (timeElapsed / 1000)
    return uploadSpeed
  }

  static getBytesRemaining (fileProgress) {
    return fileProgress.bytesTotal - fileProgress.bytesUploaded
  }
}
