import Vue from 'vue';
import App from './components/app.vue';
import store from '../../store';
import courseStore from './store';

import serializeLesson from './serializers/lesson_serializer';
import serializeExternalLesson from './serializers/external_lesson_serializer';
import serializeTopicQuizLesson from './serializers/topic_quiz_lesson_serializer';
import serializeCustomContentLesson from './serializers/custom_content_lesson_serializer';

import { mutationsNeedingSave } from './store/mutations';
import { validLesson, accessibleContent } from './validators/lesson_validator';
import { validLessonGroup } from './validators/lesson_group_validator';
import { createPublishField, createLocationField } from '../../contributions/publishment';
import { ChangeDetector } from '../../topics/change_detector';

export default class CourseManageModule {
  constructor (appNode) {
    const self = this;

    this.saveDraftButton = document.getElementById('save-draft');
    this.publishButton = document.getElementById('publish-contribution');
    this._courseForm = document.getElementsByClassName('course-form')[0];

    const newRecord = this._courseForm.getAttribute('data-newrecord') === 'true';
    if(!newRecord) {
      this.changeDetector = new ChangeDetector('.course-form');
    }

    store.registerModule('course', courseStore);

    const courseData = JSON.parse(appNode.dataset.course);
    store.dispatch('course/setLessonGroups', courseData.lessonGroups);
    store.dispatch('course/setApiUrls', courseData.apiUrls);
    store.dispatch('course/setIsCollaborative', courseData.isCollaborative);

    this.app = new Vue({
      store,
      i18n: VERSTEHE.vueI18n,
      el: '#course_lessons',
      render: h => h(App),
      beforeCreate() {
        // Extend custom validators
        this.$validator.extend('valid_lesson', validLesson);
        this.$validator.extend('valid_lesson_group', validLessonGroup);
        this.$validator.extend('accessible_content', accessibleContent);
      },
      mounted() {
        // Run validations on render, so it will show errors after saving
        this.$validator.validateAll();
      },
      computed: {
        isValid() {
          return !this.errors.any();
        }
      },
      watch: {
        isValid(isValid) {
          // Disable save button if fields are not valid
          if (isValid) {
            self.enableSave();
          } else {
            self.disableSave(this.$t('course.please_make_sure_lessons_are_valid'));
          }
        }
      }
    });

    store.subscribe((mutation, state) => {
      if (mutationsNeedingSave.indexOf(mutation.type) >= 0) {
        // Set state to dirty, so we can display a warning for the user
        // when trying to leave without saving
        state.course.dirty = true;

        this.setTopMessage(
          this.app.$t('course.unsaved_lessons'),
          this.app.$t('course.unsaved_lessons_subtitle'),
          'warning'
        );
      }
    });

    store.watch((state) => state.course.uiState, (uiState) => {
      if (uiState === 'lessonList') {
        this.enableSave();
      } else {
        this.disableSave(this.app.$t('course.please_finalize_course_lessons_first'));
      }
    });

    window.addEventListener('beforeunload', (e) => {
      if (store.state.course.dirty) {
        e.preventDefault();
        e.returnValue = '';
      }
    });

    this.saveDraftButton.addEventListener('click', () => {
      this.saveCourse(false);
    });

    this.publishButton.addEventListener('click', () => {
      this.saveCourse(true);
    });
  }

  saveCourse(publish = false) {
    this.app.$validator.validateAll().then((result) => {
      if (!result && publish) {
        return;
      }

      if (store.state.quizManage) {
        VERSTEHE.Course.quiz.saveQuiz().then((response) => {
          if(response.success) {
            let quizField = document.querySelector('#quiz-id-field');
            quizField.value = response.quizId;

            this.submitForm(publish);
          } else {
            return;
          }
        });
      } else {
        this.submitForm(publish);
      }
    });
  }

  submitForm(publish) {
    let form = document.querySelector('.course-form');
    let tempField = form.querySelector('.course-data');
    
      if (!tempField) {
        tempField = document.createElement('input');
        tempField.type = 'hidden';
        tempField.name = 'course[course_data]';
        tempField.className = 'course-data';
        form.appendChild(tempField);
      }

      // Append lesson group parameters to form
      tempField.value = JSON.stringify(this.serialize());

    const publishField = createPublishField(publish);
    const locationField = createLocationField();

    form.appendChild(publishField);
    form.appendChild(locationField);

    store.state.course.dirty = false;
    
    form.submit();
  }

  disableSave(reasonMessage = '') {
    this.setTopMessage(reasonMessage);
    VERSTEHE.ContributionWorkflow.workflow.setPendingStepState('lessons', reasonMessage);
    this.publishButton.classList.add('disabled');
  }

  enableSave() {
    if (store.state.course.dirty) {
      this.setTopMessage(
        this.app.$t('course.unsaved_lessons'),
        this.app.$t('course.unsaved_lessons_subtitle'),
        'warning'
      );
    } else {
      this.setTopMessage('');
    }

    VERSTEHE.ContributionWorkflow.workflow.setPendingStepState('lessons', false);

    this.saveDraftButton.classList.remove('disabled');
    this.publishButton.classList.remove('disabled');
  }

  setTopMessage(message = '', subtitle = null, severity = 'default') {
    const msgContainer = document.querySelector('.top-actions-message');
    msgContainer.innerHTML = '';
    msgContainer.dataset.severity = severity;
    msgContainer.appendChild(document.createTextNode(message));

    if (subtitle) {
      const subtitleContainer = document.createElement('div');
      subtitleContainer.innerText = subtitle;
      msgContainer.appendChild(subtitleContainer);
    }
  }

  serialize() {
    const lessonGroups = store.state.course.lessonGroups;
    const lessons = store.state.course.lessons;
    let lessonGroupsAttributes = [];

    lessonGroups.forEach(lessonGroup => {
      let lessonsAttributes = [];
      const lessonsInGroup = store.getters['course/lessonsInGroup'](lessonGroup.id).map(lesson => lesson.id);

      lessons.filter(lesson => lesson.lessonGroupId === lessonGroup.id).forEach(lesson => {
        let lessonAttributes = {};

        switch(lesson.type) {
          case 'Course::Lesson::TopicLesson':
          case 'Course::Lesson::PlaylistLesson':
          case 'Course::Lesson::ElearningPackageLesson':
            lessonAttributes = serializeLesson(lesson);
            break;
          case 'Course::Lesson::TopicQuizLesson':
            lessonAttributes = serializeTopicQuizLesson(lesson);
            break;
          case 'Course::Lesson::ExternalLesson':
            lessonAttributes = serializeExternalLesson(lesson);
            break;
          case 'Course::Lesson::CustomContentLesson':
            lessonAttributes = serializeCustomContentLesson(lesson);
            break;
        }
        lessonsAttributes.push(lessonAttributes);

        // assign final positioning
        if (!lesson.deleted && (lesson.position < lessonsInGroup.length)) {
          lessonAttributes.position = lessonsInGroup.indexOf(lesson.id) + 1;
        }
      });

      let lessonGroupAttributes = {
        lesson_ids: lessonsAttributes.map(lesson => lesson.id).filter(Number),
        title: lessonGroup.title,
        position: lessonGroup.position,
        lessons_attributes: lessonsAttributes
      };

      if (!lessonGroup.isNew) {
        lessonGroupAttributes.id = lessonGroup.id;
      }

      if (lessonGroup.deleted) {
        lessonGroupAttributes._destroy = '1';
      }

      lessonGroupsAttributes.push(lessonGroupAttributes);
    });

    return lessonGroupsAttributes;
  }

  check(){
    this.changeDetector.remoteCheck();
  }
}
