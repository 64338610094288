<template>
  <div class="progress-radial" :data-progress="progress">
    <div class="circle">
      <div class="background"></div>
      <div class="mask full">
        <div class="fill"></div>
      </div>
      <div class="mask half">
        <div class="fill"></div>
        <div class="fill fix"></div>
      </div>
    </div>

    <div class="overlay">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'radial-progress',
  props:  {
    progress: {
      type: Number,
      default: 0
    }
  }
}
</script>
