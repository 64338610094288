import SliderWidget from './slider_widget';

export default class SubscriptionFeedWidget extends SliderWidget {
  constructor(options) {
    super(options);

    this.type = 'SubscriptionFeed';
  }

  static get defaultParams() {
    return {
      title: '',
      show_title: true
    };
  }
}
