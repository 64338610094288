import { t } from '../configurations/localization';

export function openReviewStateFilterMenu (e, type) {
    let reviewValues = ['submitted', 'in_progress', 'done'];
    let manageValues = ['new','pending','done'];

    let values = valuesForType(type, { "manage": manageValues, "reviews": reviewValues });
    let operator = e.container.find('select');
    let filterInput = e.container.find('input');
    operator.css('display', 'none');
    filterInput.css('display', 'none');
    
    values.forEach(value => {
      let wrapper = document.createElement('div');
      let label = document.createElement('label');
      let radio = document.createElement('input');
      radio.type = 'radio';
      radio.name = 'filter_state';
      radio.value = value;
      wrapper.className = 'radio';
      label.appendChild(radio);
      label.appendChild(document.createTextNode(` ${t('state_' + value)}`));
      wrapper.appendChild(label);
      filterInput.after(wrapper);

      radio.addEventListener('click', () => {
        filterInput.val(radio.value).change();
      });
    });
}

function valuesForType(type, categorizedValues) {
  if (type === "open") {
    return categorizedValues["open"];
  } else if (type === "done") {
    return categorizedValues["done"];
  } else if (type === "reviews") {
    return categorizedValues["reviews"];
  } else if (type === "manage") {
    return categorizedValues["manage"];
  }
}
