import axios from 'axios';

export default {
  setBaseUrl({ commit }, url) {
    commit('SET_BASE_URL', `${url}/`);
  },

  loadSettings({ state, commit }) {
    axios.get(`${state.baseUrl}settings.json`).then(response => {
      commit('SET_SETTINGS', response.data.integrations);
    });
  },

  updateSettings({ state, commit, dispatch }, { integration, settings }) {
    return axios.patch(`${state.baseUrl}${integration.id}/update`, { settings })
      .then((response) => {
        commit('UPDATE_SETTINGS', { integration, settings: response.data.settings });

        if (integration.state === 'connected') {
          // Make sure the settings are tested (connect again)
          dispatch('connect', integration);
        }
        return true;
      })
      .catch((error) => {
        return false;
      });
  },

  connect({ state, commit }, integration ) {
    let response;
    commit('INTEGRATION_CHECKING', integration);

    axios.post(`${state.baseUrl}${integration.id}/connect`).then(() => {
      commit('INTEGRATION_CONNECTED', integration);

      // Set others as disconnected
      if (integration.category === 'ai_provider') {
        state.integrations
          .filter(e => e.id !== integration.id && e.category === integration.category)
          .forEach(element => {
            commit('INTEGRATION_DISCONNECTED', element);
          });
      }
    }).catch(error => {
      console.error(error.response);
      commit('INTEGRATION_FAILED', { integration, errors: error.response.data.errors });
    });
  },

  disconnect({ state, commit }, integration ) {
    axios.post(`${state.baseUrl}${integration.id}/disconnect`).then(() => {
      commit('INTEGRATION_DISCONNECTED', integration);
    });
  }
};
