import { GetterTree } from "vuex";
import { CoursePlanManageState, state } from "./state";
import { RootState } from "../../../store/types";
import { Chapter, ChapterItem } from "../entities";

export const Getters = {
  CHAPTERS: 'coursePlanManage/chapters',
  CHAPTER: 'coursePlanManage/chapter',
  CHAPTER_IDS: 'coursePlanManage/chapterIds',
  CHAPTER_IDS_WITH_DELTED: 'coursePlanManage/chapterIdsWithDeleted',
  CHAPTER_ITEM: 'coursePlanManage/chapterItem',
  CHAPTER_ITEM_IDS: 'coursePlanManage/chapterItemIds',
  CHAPTER_ITEM_IDS_WITH_DELETED: 'coursePlanManage/chapterItemIdsWithDeleted',
  CHAPTER_ITEM_INDEX: 'coursePlanManage/chapterItemIndex',
  ADD_TO_CHAPTER: 'coursePlanManage/addToChapter',
  SEARCH_URL: 'coursePlanManage/searchUrl',
  ALL_CHAPTER_ITEM_IDS_WITHOUT_DELETED: 'coursePlanManage/allChapterItemIdsWithoutDeleted',
  USED_COURSE_IDS: 'coursePlanManage/usedCourseIds',
  DELETED_CHAPTER_IDS: 'coursePlanManage/deletedChapterIds',
  DELETED_CHAPTER_ITEM_IDS: 'coursePlanManage/deletedChapterItemIds',
  ALL_CHAPTER_ITEMS_WITH_DELTED: 'coursePlanManage/allChapterItemsWithDeleted'
}

export const getters: GetterTree<CoursePlanManageState, RootState> = {
  chapterIdsWithDeleted: (state): Array<EntityId> => state.chapterList,

  chapterIds: (state): Array<EntityId> => state.chapterList.filter(id => !state.entities.chapters[id].deleted),

  allChapterItemIdsWithoutDeleted: (state): Array<EntityId> => Object.keys(state.entities.chapterItems)
    .filter(id => !state.entities.chapterItems[id].deleted),

  usedCourseIds: (state, getters): Array<number> => getters.allChapterItemIdsWithoutDeleted
    .map(chapterItemId => state.entities.chapterItems[chapterItemId].courseId),

  chapter: (state) => (id): Chapter => state.entities.chapters[id],

  chapterItem: (state) => (id): ChapterItem => state.entities.chapterItems[id],

  chapterItemIdsWithDeleted: (state) => (chapterId): Array<EntityId> => state.entities.chapters[chapterId].chapterItems,

  addToChapter: (state): EntityId => state.addToChapter,

  searchUrl: (state): string => state.searchUrl,

  chapters: (state): Array<Chapter> => state.chapterList
    .map(chapterId => state.entities.chapters[chapterId])
    .filter(chapter => !chapter.deleted),

  chapterItemIds: (state) => (chapterId): Array<EntityId> => {
    const chapter = state.entities.chapters[chapterId];
    return chapter.chapterItems.filter(id => !state.entities.chapterItems[id].deleted);
  },

  chapterItemIndex: (state, getters) => (id): number => {
    let orderedItemIds = [];

    for(let i = 0; i < getters.chapterIds.length; i++) {
      orderedItemIds = [...orderedItemIds, ...getters.chapterItemIds(getters.chapterIds[i])];
    }

    return (orderedItemIds.indexOf(id) + 1);
  },

  allChapterItemsWithDeleted: (state) => Object.keys(state.entities.chapterItems).map(key => state.entities.chapterItems[key])
};
