import { Busy } from '../globals/busy';

export class SideNavigation {
  constructor () {
    this.sidebar = document.querySelector('.explore-sidebar');

    if (this.sidebar) {
      this.structureItems = this.sidebar.querySelector('.structure .structure-items');
      this.fetchUrl = this.sidebar.querySelector('.taxonomy-dimension-select').getAttribute('data-url');

      $(this.sidebar).infinitypush({ offcanvas: false, spacing: 40 });
      this.loadPreferedDimension();
    }
  }

  loadPreferedDimension () {
    let prefered = this.sidebar.querySelector('.taxonomy-dimension-select [data-prefered="true"]');

    if (!prefered) {
      prefered = this.sidebar.querySelector('.taxonomy-dimension-select .dropdown-menu > a:first-child');
    }

    this.loadDimension(prefered);
  }

  loadDimension (option) {
    let url = option ? `${this.fetchUrl}/${option.getAttribute('data-id')}` : this.fetchUrl;
    

    // Update dimension select
    option.parentNode.childNodes.forEach(child => {
      child.classList.remove('active');
    });

    option.classList.add('active');
    let label = option.parentNode.previousSibling;
    let text = document.createTextNode(option.innerText + ' ');
    label.childNodes[0].replaceWith(text);
    label.setAttribute('data-value', option.getAttribute('data-value'));

    // Show loading indicator
    let busyIndicator = new Busy().loader;
    this.structureItems.style.overflowY = 'hidden';
    this.structureItems.appendChild(busyIndicator);

    this.closeDimensionNavigation();

    // Load dimension data from remote
    $.get(url, data => {
      // Remove previous list
      [...this.structureItems.childNodes].filter(n => { return n.tagName === 'LI'; }).forEach(element => {
        this.structureItems.removeChild(element);
      });

      // Append new list
      this.buildNavigationList(data).forEach(element => {
        this.structureItems.appendChild(element);
      });

      // Remove loading indicator
      this.structureItems.removeChild(busyIndicator);
      this.structureItems.style.overflowY = '';

      // Navigate to currently open path (if any)
      if (this.structureItems.parentNode.getAttribute('data-current-dimension') === (option.getAttribute('data-id') || '0')) {
        this.setActivePath();
      }
    });
  }

  closeDimensionNavigation () {
    document.querySelectorAll('.ma-infinitypush-sub-open').forEach(element => {
      element.classList.remove('ma-infinitypush-sub-open');
    });

    document.querySelectorAll('.ma-infinitypush-inactive').forEach(element => {
      element.classList.remove('ma-infinitypush-inactive');
    });
  }

  setActivePath () {
    let activePath = this.structureItems.parentNode.getAttribute('data-active-path');
    let targetNode = this.structureItems.querySelector(`.taxonomy-element[data-path="${activePath}"`);
    let node = targetNode;
    let path = [];
    
    while (node && (node = node.parentNode)) {
      if (node.classList && node.classList.contains('taxonomy-element')) {
        path.unshift(node);
      }
    }

    path.push(targetNode);

    path.forEach(item => {
      let link = item.querySelector('a:first-child > i');

      if (link) {
        link.click();
      }
    });
  }

  buildNavigationList (objects, parents = []) {
    return objects.map(object => {
      return this.createListItem(object, parents);
    });
  }

  createListItem (object, parents) {
    let listItem = document.createElement('li');
    let hasChildren = object.children.length > 0;
    let path = object.ancestor_ids.concat(object.id).join('/');

    listItem.className = `taxonomy-element taxonomy-${object.type} ${hasChildren ? 'has-children' : ''}`;
    listItem.setAttribute('data-path', path);

    let elementLink = document.createElement('a');
    elementLink.href = object.href;

    let openLink;

    if (hasChildren) {
      openLink = document.createElement('i');
      openLink.classList.add('child-icon');
      openLink.classList.add('material-icons');
      openLink.innerText = 'arrow_right';
      elementLink.appendChild(openLink);
    }

    let text = document.createElement('span');
    text.innerText = object.title;
    elementLink.appendChild(text);

    elementLink.addEventListener('click', (e) => {
      if (hasChildren && e.target !== openLink) {
        // Open the landing page, except when clicking the plus icon
        // Thus we do not pass the click event to Infinity Push component
        e.stopPropagation();
      }
    });

    listItem.appendChild(elementLink);

    if (hasChildren) {
      listItem.appendChild(this.createListItemChildren(object, parents));
    }

    return listItem;
  }

  createListItemChildren (object, parents) {
    let breadcrumb = parents.concat(object);
    let childList = document.createElement('ul');
    let header = document.createElement('h2');
    header.className = 'taxonomy-path';

    header.appendChild(this.createBreadcrumbLink(object, parents.length, true));

    for (let i = parents.length; i--;) {
      header.appendChild(this.createBreadcrumbLink(parents[i], i));
    }

    childList.appendChild(header);
    
    this.buildNavigationList(object.children, breadcrumb).forEach(element => {
      childList.appendChild(element);
    });

    return childList;
  }

  createBreadcrumbLink(object, level, current = false) {
    let link = document.createElement('a'),
        icon = document.createElement('span'),
        title = document.createElement('span');

    link.href = object.href;
    link.setAttribute('data-target', object.id);
    icon.className = 'material-icons icon';
    icon.innerText = 'arrow_left';
    title.style.paddingLeft = `${level * 5}px`;
    title.innerText = object.title;

    if (current) {
      link.className = 'taxonomy-step-back';
    } else {
      link.className = 'taxonomy-breadcrumb-jump';
    }

    link.addEventListener('click', (e) => {
      if (e.target !== icon && e.srcElement.parentElement.classList.contains('taxonomy-step-back')) {
        // workaround for VS-7411, otherwise no redirect to taxonomy element
        e.stopImmediatePropagation();
        let ahref = document.createElement('a');
        ahref.setAttribute('href', e.srcElement.parentElement.getAttribute('href'));
        ahref.click();
        return false;
      }
      
      if (e.target === icon) {
        e.preventDefault();
      }

      if (e.target === icon && e.srcElement.parentElement.classList.contains('taxonomy-breadcrumb-jump')) {
        let backLink = this.sidebar.querySelector(`.taxonomy-step-back[data-target="${object.id}"]`);
        backLink.click();
      }
    });

    link.appendChild(icon);
    link.appendChild(title);

    return link;
  }
}
