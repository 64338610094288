import Vue from 'vue';
import store from '../store';
import App from './components/app.vue';
import axios from 'axios';
import { FilmTranscriptModule } from './store';
import { Actions } from './store/actions';

export class FilmTranscript {
  baseElement: HTMLDivElement = null;
  vueApp: Vue = null;
  initUrl: string = null;

  public init() {
    this.baseElement = <HTMLDivElement>document.getElementById('v-film-transcript');
    this.initUrl = this.baseElement.getAttribute('data-initial-url');
    VERSTEHE.ContributionWorkflow.workflow.once('show-medium-transcript', () => this.initApp());
  }

  public initApp() {
    const parentElement = $(this.baseElement).parent();
    kendo.ui.progress(parentElement, true);

    axios.get(this.initUrl).then(response => {
      store.registerModule('filmTranscript', FilmTranscriptModule);
      store.dispatch(Actions.SET_INITIAL, response.data);

      this.vueApp = new Vue({
        el: this.baseElement,
        store: store,
        i18n: VERSTEHE.vueI18n,
        render: h => h(App)
      });

      kendo.ui.progress(parentElement, false);
    });
  }
}

export default new FilmTranscript();
