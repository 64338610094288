import { ChangeAction } from "../../system_actions/change_action";

/**
 * RadioAction handle all changes to the state of RadioButtons
 * RadioAction that have the same ancestor always replace each other
 */
export class RadioAction extends ChangeAction {
  /**
   * @constructor
   * @param {String} tabId  - TabId of the tab the action originates from
   * @param {number} user - UserId of the chat room owner
   * @param {String} element - Element thats subject of the UIAction
   * @param {String} ancestorClass - html class of the ancestor to compare Actions
   */
  constructor(tabId, user, element, ancestorClass) {
    super(tabId, user, element);
    this.ancestorClass = ancestorClass;
    this.actionType = "RadioAction";
  }

  execute() {
    this.element[0].checked = true;
    var event = new Event('change');
    this.element[0].dispatchEvent(event);
  }

  isComparableTo(other) {
    return this.ancestorClass === other.ancestorClass;
  }

  reduce(other) {
    return this;
  }

  static fromJson(json) {
    let action = JSON.parse(json);
    return new RadioAction(action.tabId, action.user, action.elementLocation, action.ancestorClass);
  }

  static registerToElement() {
    $(document).on('change', '[data-collaborative-action*="RadioAction"] input[type="radio"]', function(event) {
      if (event.isTrigger === undefined) {
        RadioAction.createAction(RadioAction.getTabId(), _app.info.user.userId, event.target, $(this).parents('[data-collaborative-action*="RadioAction"]').attr("class"));
      }
    });
  }

  static createAction(tabId, user, element, ancestorClass) {
    let action = new RadioAction(tabId, user, element, ancestorClass);
    VERSTEHE.content_channel.send_message(action);
  }
}
