import BaseWidget from './base_widget';

export default class UserTaskListWidget extends BaseWidget {
  constructor(options) {
    super(options);

    this.type = 'UserTaskList';
  }

  static get defaultParams() {
    return {
      title: '',
      show_title: true
    };
  }

  initialize() {
    super.initialize();
    VERSTEHE.tasks.renderTasks();
  }
}
