import { BaseDataSource } from '../grid/base_data_source';

export class DataSource extends BaseDataSource {
  constructor (options) {
    super(options);
  }

  initSchema() {
    let schema = {
      schema: {
        data: 'quizzes',
        model: {
          id: 'id',
          fields: {
            title: { type: 'string' },
            author: { type: 'string' },
            updated_at: { type: 'date' },
            created_at: { type: 'date' }
          }
        },
        total: 'total'
      }
    };

    return schema;
  }

  initGeneralOptions() {
    return {
      serverSorting: true,
      serverPaging: true,
      serverFiltering: true,
      page: 1,
      pageSize: 20,
      sort: this.grid.gridSort(),
      filter: this.grid.gridFilter()
    };
  }

  destroy(options) {
    let url = options.data.actions.delete;

    $.ajax({
      url: url,
      dataType: 'json',
      method: 'DELETE',
      success: options.success,
      error: (response) => {
        this.dataSource.cancelChanges();
        options.error(response);
      }
    });
  }
}
